import React, { useState, useCallback, useMemo } from 'react';
import Icon from '@vtblife/uikit-icons';

import classname from '@search/classname/src';
import Suggest from '@search/vtbeco-ui/src/components/controls/Suggest';
import { SizeEnum } from '@search/vtbeco-ui/src/types';

import latinToCyrillic from '@search/helpers/src/latinToCyrillic.json';

import { SubwayStationMark } from '../../../../../../../common/components/SubwayStation';
import { MetroStore, MetroStation } from '../../../../../../../../domain/geo/MetroStation';

import './styles.css';

const cn = classname.bind(null, 'MetroSuggest');

interface IProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange: (data: any) => void;
    metro: MetroStore;
    selected: Set<number>;
    className?: string;
}

const escapeRegExp = (string: string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

const filterMetro = (text: string, metro: MetroStation[]) => {
    text = text.trim().toLowerCase();

    const textRegExp = new RegExp(escapeRegExp(text).replace(/е/g, '(е|ё)'));

    const anotherTextLetters: string[] = [];

    text.split('').forEach(letter => {
        // @ts-ignore
        anotherTextLetters.push(latinToCyrillic[letter] || letter);
    });

    const anotherText = anotherTextLetters.join('');
    const anotherTextRegExp = new RegExp(escapeRegExp(anotherText).replace(/е/g, '(е|ё)'));

    return metro
        .filter(({ title }) => {
            title = title.toLowerCase();

            return textRegExp.test(title) || anotherTextRegExp.test(title);
        });
};

const metroTitleTrim = (title: string) => {
    return title
        // "Площадь Александра Невского-1" => "Площадь Александра Невского"
        .replace(/-\d$/, '')
        // "Мичуринский проспект (2021)" => "Мичуринский проспект"
        .replace(/ \(\d\d\d\d\)$/, '');
};

const MetroSuggest: React.FC<IProps> = ({ onChange, selected, metro, className }) => {
    const [ suggestion, setSuggestion ] = useState<{ label: string } | null>(null);

    // TODO Нормализация с учётом схлопывания смежных станций, будет сделано на бекэнде
    const metros = useMemo(() => {
        const ret: MetroStation[] = [];

        metro.sortedByTitle.forEach(station => {
            const { interchangedStations, route } = station;
            let isNeedToAdd = true;

            // Станции линии "Каховская"
            if (route.id === 86) {
                return;
            }

            // Если среди станций пересадок есть станция с эквивалентным именем и меньшим по значению id,
            // то такую станцию не добавляем в список
            if (interchangedStations && interchangedStations.length) {
                const { title } = station;
                const trimmedStationTitle = metroTitleTrim(title);

                interchangedStations.forEach(interchangedStation => {
                    if (
                        metroTitleTrim(interchangedStation.title) === trimmedStationTitle &&
                        interchangedStation.id < station.id
                    ) {
                        isNeedToAdd = false;
                    }
                });
            }

            if (isNeedToAdd) {
                ret.push(station);
            }
        });

        return ret;
    }, [ metro ]);

    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const handleOnSelect = useCallback(suggestion => {
        const id = suggestion.id;

        onChange({
            diff: {
                [selected.has(id) ? 'deselected' : 'selected']: new Set([ id ])
            }
        });
    }, [ onChange, selected ]);

    const handleTextChange = useCallback((text: string) => {
        setSuggestion({
            label: text
        });
    }, [ setSuggestion ]);

    const suggestions = useMemo(() => {
        return filterMetro(suggestion && suggestion.label || '', metros);
    }, [ suggestion, metros ]);

    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const checkListShowing = useCallback(({ isFocus, suggestions }) => {
        if (! isFocus) {
            setSuggestion(prev => {
                return prev && prev.label ? null : prev;
            });
        }

        return isFocus && suggestions.length > 0;
    }, [ setSuggestion ]);

    return (
        <div className={cn(null, undefined, className)}>
            <Suggest
                width='max'
                size={SizeEnum.S}
                inputSize={SizeEnum.S}
                suggestions={suggestions}
                renderList={({ suggestions: renderSuggestions }, { focusedIndex }, handlers) => (
                    <div className={cn('list')}>
                        <div className={cn('list-inner')}>
                            { renderSuggestions.map((item, index) => (
                                <div
                                    key={index}
                                    className={cn('list-item', { focused: focusedIndex === index })}
                                    onMouseEnter={e => handlers.handleItemMouseEnter(e, index)}
                                    onMouseDown={e => handlers.handleItemMouseDown(e, index)}
                                >
                                    <div className={cn('list-item-mark')}>
                                        <SubwayStationMark colors={item.allRoutesColorsList} />
                                    </div>
                                    <div className={cn('list-item-text')}>
                                        {
                                            // "Площадь Александра Невского-1" => "Площадь Александра Невского"
                                            item.title.replace(/-\d$/, '')
                                        }
                                    </div>
                                    <div className={cn('list-item-check')}>
                                        {selected.has(item.id) ? (
                                            <Icon name='check' />
                                        ) : null}
                                    </div>
                                </div>
                            )) }
                        </div>
                    </div>
                )}
                onSelect={handleOnSelect}
                suggestion={suggestion}
                placeholder='Найти станцию'
                onTextChange={handleTextChange}
                checkListShowing={checkListShowing}
                renderEmptyList={() => {}}
            />
        </div>
    );
};

export default MetroSuggest;
