import { SeoMapper } from '@search/router/src';
import { dedupeValues } from '../../dedupeValues';

export const mskOblMetroStation = {
    myakinino: 107
} as const;

export const mskMetroStation = {
    aeroport: 71,
    akademicheskaya: 184,
    'aleksandrovskii-sad': 151,
    alekseevskaya: 174,
    'alma-atinskaya': 98,
    altufevo: 265,
    aminevskaya: 169305,
    andronovka: 3890,
    anikeevka: 169290,
    annino: 298,
    arbatskaya: 14567,
    arbatskaya2: 126,
    aviamotornaya: 256,
    aviamotornaya2: 256,
    'aviamotornaya-2': 169339,
    'aviamotornaya-3': 501821,
    avtozavodskaya: 14596,
    avtozavodskaya2: 80,
    babushkinskaya: 166,
    bagrationovskaya: 143,
    bakovka: 169362,
    balakovka: 169362,
    baltiiskaya: 4695,
    barrikadnaya: 213,
    baumanskaya: 130,
    begovaya: 211,
    'begovaya-2': 169355,
    belokamennaya: 7772,
    belomorskaya: 62,
    belorusskaya: 73,
    belorusskaya2: 169379,
    'belorusskaya-3': 14576,
    belyaevo: 191,
    beskudnikovo: 169322,
    bibirevo: 267,
    'biblioteka-imeni-lenina': 25,
    bittsa: 169306,
    'bittsevskii-park': 341,
    borisovo: 328,
    borovitskaya: 281,
    'borovskoe-shosse': 240,
    'botanicheskii-sad': 14597,
    'botanicheskii-sad2': 170,
    bratislavskaya: 324,
    'bulvar-admirala-ushakova': 346,
    'bulvar-dmitriya-donskogo': 299,
    'bulvar-rokossovskogo': 14598,
    'bulvar-rokossovskogo2': 5,
    'buninskaya-alleya': 348,
    'buninskaya-alleya-2': 285747,
    butirskaya: 307,
    butovo: 169303,
    chekhovskaya: 280,
    cherkizovskaya: 9,
    chertanovskaya: 291,
    'chistie-prudi': 21,
    chkalovskaya: 314,
    davidkovo: 169285,
    degunino: 169327,
    'delovoi-tsentr': 14593,
    'delovoi-tsentr2': 253,
    'delovoi-tsentr-3': 495007,
    depo: 169294,
    dinamo: 72,
    dmitrovskaya: 275,
    dmitrovskaya2: 169333,
    dobrininskaya: 154,
    dolgoprudnaya: 169357,
    domodedovskaya: 94,
    dostoevskaya: 311,
    dubrovka: 14595,
    dubrovka2: 317,
    elektrozavodskaya: 131,
    'elektrozavodskaya-2': 169342,
    'elektrozavodskaya-3': 487162,
    'filatov-lug': 51,
    fili: 144,
    fili2: 169366,
    'filyovskii-park': 141,
    fiztekh: 169350,
    fonvizinskaya: 6478,
    frunzenskaya: 33,
    govorovo: 244,
    grazhdanskaya: 169325,
    izmailovo: 8240,
    izmailovskaya: 136,
    kakhovskaya: 339,
    'kakhovskaya-2': 169375,
    kalanchyovskaya: 169300,
    kalitniki: 169324,
    kaluzhskaya: 189,
    kantemirovskaya: 89,
    kashirskaya: 14571,
    khlebnikovo: 169376,
    khoroshyovo: 2603,
    khoroshyovskaya: 333,
    'khoroshyovskaya-2': 14587,
    khovrino: 59,
    kievskaya: 123,
    'kievskaya-2': 14569,
    'kievskaya-3': 14575,
    'kitai-gorod': 179,
    'kitai-gorod-2': 14589,
    'klenovii-bulvar': 169367,
    kolomenskaya: 84,
    kommunarka: 1842,
    komsomolskaya: 18,
    'komsomolskaya-2': 14581,
    konkovo: 194,
    koptevo: 726,
    kosino: 351,
    kotelniki: 230,
    kozhukhovskaya: 318,
    'krasnie-vorota': 19,
    'krasnii-baltiets': 169353,
    'krasnii-stroitel': 169307,
    krasnogorskaya: 169314,
    krasnogvardeiskaya: 96,
    krasnopresnenskaya: 162,
    krasnoselskaya: 14,
    'krestyanskaya-zastava': 316,
    krilatskoe: 112,
    krimskaya: 4286,
    kropotkinskaya: 28,
    kubanskaya: 169311,
    kuntsevskaya: 117,
    kuntsevskaya2: 169296,
    'kuntsevskaya-3': 14590,
    'kuntsevskaya-4': 169301,
    kurskaya: 129,
    'kurskaya-2': 169310,
    'kurskaya-3': 14574,
    kutuzovskaya: 145,
    'kutuzovskaya-2': 14592,
    kuzminki: 222,
    'kuznetskii-most': 215,
    lefortovo: 269422,
    'lefortovo-2': 169326,
    'leninskii-prospekt': 181,
    'lermontovskii-prospekt': 228,
    lesoparkovaya: 342,
    lianozovo: 169345,
    lianozovo2: 169364,
    likhobori: 2155,
    lobnya: 169358,
    lokomotiv: 8954,
    'lomonosovskii-prospekt': 250,
    lubyanka: 22,
    lukhmanovskaya: 355,
    luzhniki: 7450,
    lyublino: 322,
    'marina-roshcha': 309,
    'marina-roshcha-2': 169332,
    marino: 327,
    mark: 169343,
    marksistskaya: 254,
    mayakovskaya: 74,
    medvedkovo: 163,
    mendeleevskaya: 278,
    mezhdunarodnaya: 147,
    'michurinskii-prospekt': 248,
    'michurinskii-prospekt-2': 169317,
    minskaya: 251,
    mitino: 105,
    mnyovniki: 169316,
    molodyozhnaya: 114,
    'moskva-tovarnaya': 169368,
    'moskva-tovarnaya-2': 235038,
    moskvoreche: 169319,
    nagatinskaya: 285,
    'nagatinskii-zaton': 169304,
    nagornaya: 287,
    nakhabino: 169297,
    'nakhimovskii-prospekt': 288,
    'narodnoe-opolchenie': 169383,
    nekrasovka: 356,
    nemchinovka: 169292,
    nizhegorodskaya: 3192,
    'nizhegorodskaya-2': 169351,
    'nizhegorodskaya-3': 169335,
    novatorskaya: 169313,
    'novie-cheryomushki': 188,
    novodachnaya: 169365,
    novogireevo: 261,
    novokhokhlovskaya: 1095,
    'novokhokhlovskaya-2': 169352,
    novokosino: 262,
    novokuznetskaya: 77,
    novoperedelkino: 238,
    novoslobodskaya: 161,
    novoyasenevskaya: 200,
    odintsovo: 169374,
    'okhotnii-ryad': 24,
    okruzhnaya: 3227,
    okruzhnaya2: 169291,
    okruzhnaya3: 306,
    okskaya: 169360,
    oktyabrskaya: 152,
    'oktyabrskaya-2': 14573,
    'oktyabrskoe-pole': 210,
    olkhovaya: 56,
    opalikha: 169380,
    orekhovo: 92,
    ostafevo: 169361,
    otradnoe: 268,
    ozyornaya: 246,
    panfilovskaya: 4644,
    'park-kulturi': 31,
    'park-kulturi-2': 14582,
    'park-pobedi': 120,
    'park-pobedi-2': 14583,
    partizanskaya: 134,
    paveletskaya: 79,
    'paveletskaya-2': 14570,
    pavshino: 169315,
    pechatniki: 319,
    'pechatniki-2': 169338,
    'pechatniki-3': 169354,
    penyagino: 169308,
    pererva: 169359,
    perovo: 259,
    pervomaiskaya: 137,
    'petrovskii-park': 337,
    'petrovskii-park-2': 14586,
    'petrovsko-razumovskaya': 272,
    'petrovsko-razumovskaya-2': 14579,
    pikhtino: 169321,
    pionerskaya: 140,
    planernaya: 201,
    'ploshchad-gagarina': 944,
    'ploshchad-ilicha': 255,
    'ploshchad-revolyutsii': 128,
    podolsk: 169302,
    pokrovskoe: 169331,
    'pokrovskoe-streshnevo': 169347,
    polezhaevskaya: 1067,
    polyanka: 282,
    novomoskovskaya: 169370,
    prazhskaya: 294,
    'preobrazhenskaya-ploshchad': 11,
    profsoyuznaya: 186,
    prokshino: 54,
    proletarskaya: 216,
    'prospekt-mira': 159,
    'prospekt-mira-2': 14577,
    'prospekt-vernadskogo': 39,
    'prospekt-vernadskogo-2': 169299,
    pushkinskaya: 214,
    'pyatnitskoe-shosse': 102,
    'rabochii-posyolok': 169330,
    ramenki: 249,
    rasskazovka: 235,
    'rechnoi-vokzal': 64,
    rimskaya: 315,
    rizhskaya: 176,
    'rizhskaya-2': 169349,
    'rizhskaya-3': 169346,
    rostokino: 1899,
    rumyantsevo: 44,
    'ryazanskii-prospekt': 224,
    salarevo: 49,
    savyolovskaya: 277,
    savyolovskaya2: 169382,
    'savyolovskaya-3': 14584,
    'savyolovskaya-4': 14580,
    seligerskaya: 301,
    semyonovskaya: 133,
    serpukhovskaya: 283,
    setun: 169287,
    sevastopolskaya: 290,
    shabolovskaya: 3500,
    shcherbinka: 169340,
    shchukinskaya: 208,
    'shchukinskaya-2': 578220,
    shchyolkovskaya: 138,
    shelepikha: 332,
    'shelepikha-2': 1217,
    'shelepikha-3': 14585,
    sheremetevskaya: 169298,
    shipilovskaya: 330,
    'shosse-entuziastov': 14594,
    'shosse-entuziastov2': 258,
    silikatnaya: 169289,
    skhodnenskaya: 203,
    skolkovo: 169348,
    'slavyanskii-bulvar': 118,
    'slavyanskii-bulvar-2': 169328,
    smolenskaya: 14568,
    smolenskaya2: 124,
    sokol: 69,
    'sokolinaya-gora': 1191,
    sokolniki: 12,
    'sokolniki-2': 169295,
    solntsevo: 242,
    spartak: 207,
    sportivnaya: 34,
    'sretenskii-bulvar': 313,
    stakhanovskaya: 169377,
    streshnevo: 3483,
    'streshnevo-2': 169378,
    strogino: 110,
    studencheskaya: 146,
    sukharevskaya: 177,
    sviblovo: 168,
    taganskaya: 156,
    'taganskaya-2': 14578,
    teatralnaya: 76,
    tekhnopark: 83,
    tekstilshchiki: 220,
    'tekstilshchiki-2': 169363,
    'tekstilshchiki-3': 169356,
    terekhovo: 169334,
    testovskaya: 169336,
    timiryazevskaya: 274,
    timiryazevskaya2: 169318,
    tretyakovskaya: 180,
    'tretyakovskaya-2': 14572,
    trikotazhnaya: 169372,
    troparyovo: 43,
    trubnaya: 312,
    tsaritsino: 91,
    tsaritsino2: 169373,
    tsska: 335,
    'tsska-2': 14588,
    'tsvetnoi-bulvar': 279,
    tulskaya: 284,
    turgenevskaya: 178,
    tushinskaya: 205,
    tushinskaya2: 169309,
    tverskaya: 75,
    'tyoplii-stan': 197,
    ugreshskaya: 8431,
    'ulitsa-1905-goda': 212,
    'ulitsa-800-letiya-moskvi': 169312,
    'ulitsa-akademika-yangelya': 296,
    'ulitsa-dmitrievskogo': 352,
    'ulitsa-gorchakova': 347,
    'ulitsa-skobelevskaya': 344,
    'ulitsa-starokachalovskaya': 343,
    universitet: 36,
    varshavskaya: 169344,
    'varshavskaya-2': 338,
    vdnkh: 172,
    'verkhnie-kotli': 4633,
    'verkhnie-likhobori': 303,
    vikhino: 226,
    vistavochnaya: 150,
    vladikino: 270,
    'vladikino-2': 14591,
    vnukovo: 169341,
    'vodnii-stadion': 65,
    vodniki: 169381,
    voikovskaya: 67,
    'volgogradskii-prospekt': 217,
    volokolamskaya: 106,
    volokolamskaya2: 169371,
    volzhskaya: 321,
    'vorobyovi-gori': 35,
    voroncovskaya: 427507,
    voroncovskaya2: 408984,
    yasenevo: 199,
    'yugo-vostochnaya': 169293,
    'yugo-zapadnaya': 41,
    yuzhnaya: 293,
    zhulebino: 229,
    zil: 6474,
    zorge: 2630,
    zyablikovo: 331,
    zyuzino: 169329
} as const;

export const mskMetroMapper = new SeoMapper('mskMetroMapper', {
    ...mskOblMetroStation,
    ...mskMetroStation
} as const);

export const mskOblMetroIds = dedupeValues(mskOblMetroStation);
export const mskMetroIds = dedupeValues(mskMetroStation);
