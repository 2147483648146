/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';

import { Modifier } from '@popperjs/core';
import arrowModifier from '@popperjs/core/lib/modifiers/arrow';
import hideModifier from '@popperjs/core/lib/modifiers/hide';
import offsetModifier from '@popperjs/core/lib/modifiers/offset';
import preventOverflow from '@popperjs/core/lib/modifiers/preventOverflow';
import { defaultModifiers, popperGenerator } from '@popperjs/core/lib/popper-lite';

import { getElementSize, getWindowSize } from './getWindowSize';
import { useDomOnClickOutside } from './useDomOnClickOutside';

type TooltipDirection = 'up' | 'right' | 'down' | 'left';

type PopperDirection = 'top' | 'right' | 'bottom' | 'left';

const directions: TooltipDirection[] = [ 'up', 'right', 'down', 'left' ];

const padding = 8;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PopperModifier = Partial<Modifier<any, any>>;

const directionAlias: Record<TooltipDirection, PopperDirection> = {
    up: 'top',
    down: 'bottom',
    left: 'left',
    right: 'right',
};

const createPopper = popperGenerator({
    defaultModifiers: [ ...defaultModifiers, preventOverflow, offsetModifier, arrowModifier, hideModifier ],
});

const arrow = {
    name: 'arrow',
    options: {
        padding: 2,
    },
};

const offset = {
    name: 'offset',
    options: {
        offset: [ 0, padding ],
    },
};

const overflow = ($container: HTMLElement) => ({
    name: 'preventOverflow',
    options: {
        boundary: $container,
    },
});

const adaptiveDirection = ($container: HTMLElement | null, direction: TooltipDirection): PopperModifier => ({
    name: 'direction',
    enabled: true,
    phase: 'beforeRead',
    fn({ state }) {
        state.placement = fit({
            $container,
            $control: state.elements.reference as HTMLElement,
            $tooltip: state.elements.popper,
            direction,
        });
    },
});

// const silent = [offset, { name: 'eventListeners', enabled: false }, { name: 'computeStyles', enabled: false }];

function fit({ $container, $control, $tooltip, direction }: {
    $container: HTMLElement | null;
    $control: HTMLElement;
    $tooltip: HTMLElement;
    direction: TooltipDirection;
}) {
    const box = $container ? getElementSize($container) : getWindowSize();
    const origin = getElementSize(document.body);
    const control = getElementSize($control);
    const tooltip = getElementSize($tooltip);

    const cleft = control.left - origin.left;
    const cright = control.right - origin.left;
    const ctop = control.top - origin.top;
    const cbottom = control.bottom - origin.top;
    const xmin = box.left - origin.left;
    const xmax = box.right - origin.left;
    const ymin = box.top - origin.top;
    const ymax = box.bottom - origin.top;

    const controlWithin = ctop - ymin >= 0 && ymax - cbottom >= 0;

    const possibleDirections: Record<string, boolean> = {
        left: controlWithin && cleft - padding - tooltip.width >= xmin,
        right: controlWithin && cright + padding + tooltip.width <= xmax,
        up: ctop - padding - tooltip.height >= ymin,
        down: cbottom + padding + tooltip.height <= ymax,
    };

    const realDirection =
        [
            ...directions.slice(directions.indexOf(direction)),
            ...directions.slice(0, directions.indexOf(direction)),
        ].find(dir => possibleDirections[dir]) || direction;

    return directionAlias[realDirection];
}

const defaultContainer = { current: null };

export type UsePopperProps = {
    container?: React.RefObject<HTMLElement>;
    direction?: TooltipDirection;
    triggerElement: HTMLElement | null | undefined;
    hide(): void;
}

export function usePopper({
    container = defaultContainer,
    direction = 'up',
    triggerElement,
    hide
}: UsePopperProps) {
    const popperRefs = React.useMemo(() => ({ current: [ null as HTMLElement | null ] }), [ ]);
    const onClickOutside = React.useCallback((e: MouseEvent | TouchEvent) => {
        e.preventDefault();
        e.stopPropagation();
        hide();
    }, [ hide ]);

    useDomOnClickOutside(popperRefs, onClickOutside);

    const containerElement = container.current;

    const popper = React.useMemo(() => {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        let popper: ReturnType<typeof createPopper> | undefined;

        return {
            init(tooltipElement?: HTMLElement | null) {
                if (! triggerElement) return;

                if (! tooltipElement) {
                    popper?.destroy();
                    return;
                }

                popperRefs.current[0] = tooltipElement;
                popperRefs.current[1] = triggerElement;
                popper?.destroy();
                popper = createPopper(triggerElement, tooltipElement, {
                    placement: directionAlias[direction],
                    modifiers: [
                        arrow,
                        offset,
                        overflow(containerElement ?? document.body),
                        adaptiveDirection(containerElement, direction),
                    ],
                });
            },
            destroy() {
                popper?.destroy();
                popperRefs.current.length = 0;
                popper = undefined;
            }
        };
    }, [ triggerElement, containerElement, direction ]);

    React.useEffect(() => {
        return () => popper.destroy();
    }, [ popper ]);

    return popper.init;
}
