import React from 'react';

import { ButtonTwoState } from './ButtonTwoState';

export function FavoritesButton(props: React.ComponentProps<typeof ButtonTwoState>) {
    return (<ButtonTwoState
        {...props}
        icon='heart'
        dataTest={props.isActive ? 'offer-favorite-yes' : 'offer-favorite-no'}
        tooltipText={props.isActive ? 'В\u00A0избранном' : 'В\u00A0избранное'}
    />);
}
