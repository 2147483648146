import React, { useState, useEffect, useRef, useCallback } from 'react';
import classname from '@search/classname/src';
import Icon from '@vtblife/uikit-icons';
import { ImageAttributesTypeEnum } from '../../../../../../enums';
import GalleryPreview from './GalleryPreview';
import ThumbnailSnippet from './ThumbnailSnippet';
import NavButtons, { ArrowDirection } from './NavButtons';
import './styles.css';

const cn = classname.bind(null, 'GallerySnippet');

export interface IImage {
    id: string | number;
    originPath?: string;
    src?: string;
    src2x?: string;
    description: string;
    attributesType?: keyof typeof ImageAttributesTypeEnum | null;
}

export interface IThumbnail extends IImage {
    preview?: string;
    preview2x?: string;
}

export interface IGallerySnippet {
    className?: string;
    pictures: IImage[];
    onClick?: () => void;
    onActiveImageChange?: (index: number) => void;
    isFrozen?: boolean;
    isVisited?: boolean;
    withLazyLoad?: boolean;
    counterEnabled?: boolean;
    maxPicturesCount?: number;
    isActive?: boolean;
    isClSnippet?: boolean;
    miniImagesEnabled?: boolean;
    relativeSize?: boolean;
    imageDataset?: Record<string, string>;
    previewSize?: 'full' | 'average' | 'below-average' | 'minimum' | 'small' |'least';
}

const GallerySnippet: React.FC<IGallerySnippet> = React.memo(({
    className,
    children,
    pictures,
    onClick,
    onActiveImageChange,
    isFrozen,
    isVisited,
    withLazyLoad,
    counterEnabled = false,
    maxPicturesCount = 4,
    isActive = false,
    miniImagesEnabled = true,
    relativeSize = false,
    imageDataset,
    previewSize,
    isClSnippet
}) => {
    const mainRef = useRef<HTMLDivElement | null>(null);
    const [ activeImageIndex, setActiveImageIndex ] = useState(0);
    const hasThumbs = pictures.length > 1;
    const visiblePictures = pictures.slice(0, maxPicturesCount);
    const lastImageIndex = visiblePictures.length - 1;
    const restPicturesCount = pictures.length - visiblePictures.length;

    const handleClickArrow = (arrowDirection: ArrowDirection) => {
        if (arrowDirection === 'left') {
            // Если эта первая фотография и мы нажимаем влево –  показываем последнее фото
            const newActiveImageIndex = activeImageIndex === 0 ? lastImageIndex : activeImageIndex - 1;

            setActiveImageIndex(newActiveImageIndex);
        } else {
            // Если эта последняя фотография и мы нажимаем вправо –  показываем первое фото
            const newActiveImageIndex = activeImageIndex === lastImageIndex ? 0 : activeImageIndex + 1;

            setActiveImageIndex(newActiveImageIndex);
        }
    };

    const handleSetActiveImageIndex = useCallback((index: number) => () => setActiveImageIndex(index), []);
    const resetActiveImageIndex = useCallback(() => setActiveImageIndex(0), []);

    useEffect(() => {
        onActiveImageChange && onActiveImageChange(activeImageIndex);
    }, [ activeImageIndex, onActiveImageChange ]);

    if (! visiblePictures.length) {
        return (
            <div className={cn('empty', { relativeSize })}>
                <Icon
                    name='camera-crossed'
                    size='xl'
                />
            </div>
        );
    }

    const activeImage = visiblePictures[activeImageIndex] || null;
    const isLastImage = activeImageIndex === lastImageIndex;
    const size = previewSize ?? (hasThumbs ? 'minimum' : 'average');

    return (
        <div
            ref={mainRef}
            className={cn(null, { visited: isVisited }, className)}
            onClick={onClick}
            onMouseLeave={resetActiveImageIndex}
        >
            <div className={cn('preview', { isActive })}>
                {hasThumbs && (
                    <NavButtons
                        isActive={isActive}
                        isLastImage={isLastImage}
                        isFirstImage={activeImageIndex === 0}
                        handleClickArrow={handleClickArrow}
                        isClSnippet={isClSnippet}
                    />
                )}
                {counterEnabled && pictures.length > 0 ? (
                    <div className={cn('counter')}>
                        {activeImageIndex + 1} / {pictures.length}
                    </div>
                ) : null}
                <GalleryPreview
                    {...activeImage}
                    itemScope
                    isLast={isLastImage}
                    isFirst={activeImageIndex === 0}
                    restCount={restPicturesCount}
                    size={size}
                    isFrozen={isFrozen}
                    imageDataset={imageDataset}
                />
            </div>

            {miniImagesEnabled && visiblePictures.length > 1 && (
                <div className={cn('list')}>
                    {visiblePictures.map(
                        (thumbnail, index) => (
                            <ThumbnailSnippet
                                {...thumbnail}
                                withLazyLoad={withLazyLoad}
                                key={`${thumbnail.id}-${index}`}
                                isLast={index === maxPicturesCount - 1}
                                restCount={restPicturesCount}
                                handleSetActiveImageIndex={handleSetActiveImageIndex(index)}
                                isFrozen={isFrozen}
                            />
                        )
                    )}
                </div>
            )}

            {children}
        </div>
    );
});

export default GallerySnippet;
