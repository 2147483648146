import React, { useCallback } from 'react';
import { NumberInput } from '@vtblife/uikit';

import { useEnterBlur } from '@search/hooks/src/useEnterBlur';
import { KitchenAreaFilter } from '../../../../models/Filter';
import MobileFilter from '../../../filter/MobileFilter';

import '../MobileCommon.css';

interface IMobileKitchenAreaFilterViewProps {
    filter: KitchenAreaFilter;
    handleChange: (filter: KitchenAreaFilter) => void;
}

export const MobileKitchenAreaFilterView: React.FC<IMobileKitchenAreaFilterViewProps> = ({
    filter,
    handleChange
}) => {
    const onChange = useCallback((value: number) => {
        handleChange(new KitchenAreaFilter({ from: Number(value) || null, to: null }));
    }, [ handleChange ]);

    const onKeyPressEnterBlur = useEnterBlur();

    return (
        <MobileFilter
            filterTitle='Кухня'
        >
            <div className='container'>
                <div className={'col-xs-12'}>
                    <NumberInput
                        value={filter.value.from ?? undefined}
                        postfix={'м²'}
                        onChange={onChange}
                        onKeyPress={onKeyPressEnterBlur}
                        max={100_000}
                        name='kitchenAreaFrom'
                        placeholder='от'
                    />
                </div>
            </div>
        </MobileFilter>
    );
};
