import { arrayStrToNum } from '@search/router/src';
import { RegionIdEnum } from '@search/filter-enums/enums/Region';
import { FinishingTypeEnum, WindowsDirectionSearchOffersEnum } from '@search/graphql-typings';
import {
    AreaUnit,
    BalconyOptions,
    BankPledgeOptions,
    Bathroom,
    BathroomLocationOptions,
    CeilingHeight,
    CommissionType,
    DealType,
    DepositType,
    ElectricityOptions,
    FloorConstraints,
    FurnitureOption,
    GasOptions,
    HeatingOptions,
    HouseCategory,
    InfrastructureOptions,
    IsApartmentsOptions,
    LandStatus,
    LayoutImageOptions,
    LiftOptions,
    LivingFacilitiesOption,
    LivingTenantOption,
    NearbyOptions,
    ParkingType,
    PhotoOptions,
    PrepaymentType,
    RealtyObjectType, RenovationProgram,
    SewerageOptions,
    TimeLimit,
    TransportType,
    WallsType,
    WaterOptions,
    OnlineView,
    BuildEpoch,
    FinishingTypeOption,
    Commissioning,
    Quarter
} from '@search/filter-enums/enums';
import {
    ApartmentFilter,
    AreaFilter,
    AuthorFilter,
    BalconyFilter,
    BankPledgeOptionsFilter,
    BathroomFilter,
    BathroomLocationFilter,
    CategoryTypeFilter,
    CellHeightFilter,
    DealTypeFilter,
    Filters,
    FloorFilter,
    FloorsTotalFilter,
    FurnitureFilter,
    GeoFilter,
    HeatingFilter,
    HouseAreaFilter,
    HouseCategoryFilter,
    IFilter,
    InfrastructureFilter,
    KitchenAreaFilter,
    LandAreaFilter,
    LandStatusFilter,
    LayoutImageOptionsFilter,
    LivingAreaFilter,
    LivingFacilityFilter,
    LivingTenantOptionFilter,
    MapBoundsFilter,
    NearbySchoolFilter,
    NewBuildingDataFilter,
    NewBuildingFilter,
    ParkingFilter,
    PhotoOptionsFilter,
    PriceFilter,
    MortgagePaymentFilter,
    RegionFilter,
    RenovationFilter, RenovationProgramFilter,
    RentConditionsFilter,
    RentPrepaymentFilter,
    RoomFilter,
    RoomsInOfferFilter,
    SellTypeFilter,
    SeoMortgageAllowedFilter,
    SupplyOptionsFilter,
    TransportLimitFilter,
    WallsTypeFilter,
    WindowsDirectionFilter,
    YearBuildingFilter,
    SellerOrganizationNameFilter,
    BuildEpochFilter,
    OnlineViewFilter,
    FinishingFilter,
    ClCommisioninFilter,
    CommissioningFilter
} from '../../models/Filter';

// eslint-disable-next-line @typescript-eslint/no-explicit-any

export interface IDeserializer {
    keys: string[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handler: (...args: any) => IFilter;
}

export const filterDeserializers: Record<Filters, IDeserializer> = {
    [Filters.heating]: {
        keys: [ 'heating' ],
        handler: ({ heating }) => {
            if (heating) {
                return new HeatingFilter(
                    heating.map((item: string) => HeatingOptions[item])
                );
            }

            return new HeatingFilter();
        }
    },
    [Filters.bathroomLocation]: {
        keys: [ 'bathroomLocation' ],
        handler: ({ bathroomLocation }) => {
            if (bathroomLocation) {
                return new BathroomLocationFilter(
                    bathroomLocation.map((item: string) => BathroomLocationOptions[item])
                );
            }

            return new BathroomLocationFilter();
        }
    },
    [Filters.houseArea]: {
        keys: [ 'houseAreaMin', 'houseAreaMax' ],
        handler: ({ houseAreaMin, houseAreaMax }) => {
            return new HouseAreaFilter({
                from: houseAreaMin || null,
                to: houseAreaMax || null
            });
        }
    },
    [Filters.houseCategory]: {
        keys: [ 'houseCategory' ],
        handler: ({ houseCategory }) => {
            if (houseCategory) {
                return new HouseCategoryFilter(
                    houseCategory.map((item: string) => HouseCategory[item])
                );
            }

            return new HouseCategoryFilter();
        }
    },
    [Filters.landStatus]: {
        keys: [ 'landStatus' ],
        handler: ({ landStatus }) => {
            if (landStatus) {
                return new LandStatusFilter(
                    landStatus.map((item: string) => LandStatus[item])
                );
            }
            return new LandStatusFilter();
        }
    },
    [Filters.landArea]: {
        keys: [ 'landAreaMin', 'landAreaMax' ],
        handler: ({ landAreaMin, landAreaMax }) => {
            return new LandAreaFilter({
                from: landAreaMin || null,
                to: landAreaMax || null
            });
        }

    },
    [Filters.dealType]: {
        keys: [ 'dealType' ],
        handler: ({ dealType }) => {
            return new DealTypeFilter(dealType || DealType.SELL);
        }
    },
    [Filters.categoryType]: {
        keys: [ 'categoryType' ],
        handler: ({ categoryType }) => {
            return new CategoryTypeFilter(categoryType || RealtyObjectType.FLAT);
        }
    },
    [Filters.newBuilding]: {
        keys: [ 'newBuilding' ],
        handler: ({ newBuilding }) => {
            if (! newBuilding) {
                return new NewBuildingFilter();
            }

            return new NewBuildingFilter(Array.isArray(newBuilding) ? newBuilding : [ newBuilding ]);
        }
    },
    [Filters.totalArea]: {
        keys: [ 'totalAreaMin', 'totalAreaMax' ],
        handler: ({ totalAreaMin, totalAreaMax }) => {
            return new AreaFilter({
                from: totalAreaMin || null,
                to: totalAreaMax || null
            });
        }
    },
    [Filters.rooms]: {
        keys: [ 'rooms' ],
        handler: ({ rooms }) => {
            if (! rooms) {
                return new RoomFilter();
            }
            return new RoomFilter(Array.isArray(rooms) ? rooms : [ rooms ]);
        }
    },
    [Filters.roomsInOffer]: {
        keys: [ 'roomsInOffer' ],
        handler: ({ roomsInOffer }) => {
            if (! roomsInOffer) {
                return new RoomsInOfferFilter();
            }

            return new RoomsInOfferFilter(Array.isArray(roomsInOffer) ? roomsInOffer : [ roomsInOffer ]);
        }
    },
    [Filters.region]: {
        keys: [ 'region' ],
        handler: ({ region }) => {
            return new RegionFilter(region || RegionIdEnum.MSK);
        }
    },
    [Filters.price]: {
        keys: [ 'priceMin', 'priceMax', 'priceUnit' ],
        handler: ({ priceMin, priceMax, priceUnit }) => {
            let priceType: AreaUnit;

            if (priceUnit === 'ALL') priceType = AreaUnit.UNKNOWN;
            else priceType = AreaUnit[priceUnit];

            return new PriceFilter(priceType, {
                from: priceMin || null,
                to: priceMax || null
            });
        }
    },
    [Filters.mortgagePayment]: {
        keys: [ 'mortgagePayment' ],
        handler: ({ mortgagePayment }) => new MortgagePaymentFilter(mortgagePayment)
    },
    [Filters.sellerOrganizationName]: {
        keys: [ 'sellerOrganizationName' ],
        handler: ({ sellerOrganizationName }) => {
            return new SellerOrganizationNameFilter(sellerOrganizationName);
        }
    },
    [Filters.livingArea]: {
        keys: [ 'livingAreaMin', 'livingAreaMax' ],
        handler: ({ livingAreaMin, livingAreaMax }) => {
            return new LivingAreaFilter({
                from: livingAreaMin || null,
                to: livingAreaMax || null
            });
        }
    },
    [Filters.kitchenArea]: {
        keys: [ 'kitchenAreaMin', 'kitchenAreaMax' ],
        handler: ({ kitchenAreaMin, kitchenAreaMax }) => {
            return new KitchenAreaFilter({
                from: kitchenAreaMin || null,
                to: kitchenAreaMax || null
            });
        }
    },
    [Filters.builtYear]: {
        keys: [ 'builtYearMin', 'builtYearMax' ],
        handler: ({ builtYearMin, builtYearMax }) => {
            return new YearBuildingFilter({
                from: builtYearMin || null,
                to: builtYearMax || null
            });
        }
    },
    [Filters.floorsTotal]: {
        keys: [ 'floorsTotalMin', 'floorsTotalMax' ],
        handler: ({ floorsTotalMin, floorsTotalMax }) => {
            return new FloorsTotalFilter({
                from: floorsTotalMin || null,
                to: floorsTotalMax || null
            });
        }
    },
    [Filters.supplyOptions]: {
        keys: [
            'gas',
            'water',
            'electricity',
            'sewerage'
        ],
        handler: ({
            gas,
            water,
            electricity,
            sewerage
        }) => {
            return new SupplyOptionsFilter(
                gas?.map(item => GasOptions[item]),
                water?.map(item => WaterOptions[item]),
                electricity?.map(item => ElectricityOptions[item]),
                sewerage?.map(item => SewerageOptions[item])
            );
        }
    },
    [Filters.floor]: {
        keys: [ 'floorMin', 'floorMax', 'last', 'first' ],
        handler: ({ floorMin, floorMax, last, first }) => {
            const lastValue = last === 'ONLY' ?
                FloorConstraints.ONLY : last === 'EXCLUDE' ? FloorConstraints.EXCLUDE : undefined;
            const firstValue = first === 'ONLY' ?
                FloorConstraints.ONLY : first === 'EXCLUDE' ? FloorConstraints.EXCLUDE : undefined;

            return new FloorFilter(
                {
                    from: floorMin || null,
                    to: floorMax || null
                },
                firstValue,
                lastValue
            );
        }
    },
    [Filters.cellHeight]: {
        keys: [ 'cellHeight' ],
        handler: ({ cellHeight }) => {
            return new CellHeightFilter(CeilingHeight[cellHeight] as unknown as CeilingHeight);
        }
    },
    [Filters.renovation]: {
        keys: [ 'renovation' ],
        handler: ({ renovation }) => {
            if (! renovation) {
                return new RenovationFilter();
            }
            return new RenovationFilter(renovation);
        }
    },
    [Filters.bathroom]: {
        keys: [ 'bathroom' ],
        handler: ({ bathroom }) => {
            if (! bathroom) {
                return new BathroomFilter();
            }

            if (typeof bathroom === 'string') {
                bathroom = [ bathroom ];
            }

            const result = bathroom.map((value: string) => {
                switch (value) {
                    case 'COMBINED':
                        return Bathroom.COMBINED;
                    case 'PARTED':
                        return Bathroom.PARTED;
                    case 'SEVERAL':
                        return Bathroom.SEVERAL;
                    default:
                        return null;
                }
            });

            return new BathroomFilter(result);
        }
    },
    [Filters.transportLimit]: {
        keys: [ 'transport', 'transportTimeLimit' ],
        handler: ({ transport, transportTimeLimit }) => {
            return new TransportLimitFilter(
                TimeLimit[transportTimeLimit],
                TransportType[transport]
            );
        }
    },
    [Filters.geo]: {
        keys: [ 'metroIds', 'districtIds', 'addresses', 'newBuildingIds' ],
        handler: ({ metroIds, districtIds, addresses, newBuildingIds }) => {
            const parsedMetroIds = arrayStrToNum(metroIds);
            const parsedDistrictIds = arrayStrToNum(districtIds);
            const parsedAddressIds = arrayStrToNum(addresses);
            const parsedNewBuildingIds = arrayStrToNum(newBuildingIds);

            return new GeoFilter(
                new Set([ ...parsedMetroIds, ...parsedDistrictIds, ...parsedAddressIds ]),
                parsedMetroIds,
                parsedDistrictIds,
                parsedAddressIds,
                parsedNewBuildingIds
            );
        }
    },
    [Filters.newBuildingData]: {
        keys: [ 'newBuildingType', 'newBuildingName', 'newBuildingIds' ],
        handler: ({
            newBuildingType: type,
            newBuildingName: name,
            newBuildingIds: ids
        }) => type && name && ids.length === 1 ?
            new NewBuildingDataFilter({
                id: ids[0],
                type,
                name
            }) :
            new NewBuildingDataFilter()
    },
    [Filters.livingFacility]: {
        keys: [ 'livingFacility' ],
        handler: ({ livingFacility }) => {
            if (! livingFacility) {
                return new LivingFacilityFilter();
            }

            return new LivingFacilityFilter(
                livingFacility.map((item: keyof typeof LivingFacilitiesOption) => {
                    return LivingFacilitiesOption[item];
                })
            );
        }
    },
    [Filters.furniture]: {
        keys: [ 'furniture' ],
        handler: ({ furniture }) => {
            if (! furniture) {
                return new FurnitureFilter();
            }

            return new FurnitureFilter(
                furniture.map((item: keyof typeof FurnitureOption) => {
                    return FurnitureOption[item];
                })
            );
        }
    },
    [Filters.finishingType]: {
        keys: [ 'finishingType' ],
        handler: ({ finishingType }) => {
            if (! finishingType) {
                return new FinishingFilter();
            }

            return new FinishingFilter(
                finishingType.map((item: keyof typeof FinishingTypeOption) => {
                    return FinishingTypeOption[item];
                })
            );
        }
    },
    [Filters.livingTenantOptions]: {
        keys: [ 'livingTenantOptions' ],
        handler: ({ livingTenantOptions }) => {
            if (! livingTenantOptions) {
                return new LivingTenantOptionFilter();
            }

            return new LivingTenantOptionFilter(
                livingTenantOptions.map((item: keyof typeof LivingTenantOption) => {
                    return LivingTenantOption[item];
                })
            );
        }
    },
    [Filters.rentConditions]: {
        keys: [
            'utilitiesIncluded',
            'commisionType',
            'depositType'
        ],
        handler: ({
            utilitiesIncluded,
            depositType,
            commisionType
        }) => {
            return new RentConditionsFilter(
                utilitiesIncluded,
                DepositType[depositType],
                CommissionType[commisionType]
            );
        }
    },
    [Filters.rentPrepayment]: {
        keys: [ 'rentPrepayment' ],
        handler: ({
            rentPrepayment
        }: {
            rentPrepayment?: keyof typeof PrepaymentType;
        }) => {
            if (! rentPrepayment) {
                return new RentPrepaymentFilter();
            }

            return new RentPrepaymentFilter(
                PrepaymentType[rentPrepayment]
            );
        }
    },
    [Filters.walls]: {
        keys: [ 'walls' ],
        handler: ({
            walls
        }) => {
            if (! walls) {
                return new WallsTypeFilter();
            }

            return new WallsTypeFilter(
                walls.map((item: keyof typeof WallsType) => {
                    return WallsType[item];
                })
            );
        }
    },
    [Filters.parkings]: {
        keys: [ 'parkings' ],
        handler: ({
            parkings
        }) => {
            if (! parkings) {
                return new ParkingFilter();
            }

            return new ParkingFilter(
                parkings.map((item: keyof typeof ParkingType) => {
                    return ParkingType[item];
                })
            );
        }
    },
    [Filters.author]: {
        keys: [ 'author' ],
        handler: ({
            author
        }) => {
            return new AuthorFilter(author);
        }
    },
    [Filters.sellType]: {
        keys: [ 'sellType' ],
        handler: ({
            sellType
        }) => {
            return new SellTypeFilter(sellType);
        }
    },
    [Filters.balcony]: {
        keys: [ 'balcony' ],
        handler: ({
            balcony
        }: {
            balcony?: keyof typeof BalconyOptions;
        }) => {
            if (! balcony) {
                return new BalconyFilter();
            }

            return new BalconyFilter(
                BalconyOptions[balcony]
            );
        }
    },
    [Filters.photoOptions]: {
        keys: [ 'photoOptions' ],
        handler: ({
            photoOptions
        }: {
            photoOptions?: keyof typeof PhotoOptions;
        }) => {
            if (! photoOptions) {
                return new PhotoOptionsFilter();
            }

            return new PhotoOptionsFilter(
                PhotoOptions[photoOptions]
            );
        }
    },
    [Filters.layoutImageOptions]: {
        keys: [ 'layoutImageOptions' ],
        handler: ({
            layoutImageOptions = []
        }) => {
            return new LayoutImageOptionsFilter(
                layoutImageOptions.map((item: number) => {
                    return LayoutImageOptions[item];
                })
            );
        }
    },
    [Filters.bankPledgeOptions]: {
        keys: [ 'bankPledgeOptions' ],
        handler: ({
            bankPledgeOptions
        }: {
            bankPledgeOptions?: keyof typeof BankPledgeOptions;
        }) => {
            if (! bankPledgeOptions) {
                return new BankPledgeOptionsFilter();
            }

            return new BankPledgeOptionsFilter(
                BankPledgeOptions[bankPledgeOptions]
            );
        }
    },
    [Filters.nearbyOptions]: {
        keys: [ 'nearbyOptions' ],
        handler: ({
            nearbyOptions
        }: {
            nearbyOptions?: keyof typeof NearbyOptions;
        }) => {
            if (! nearbyOptions) {
                return new NearbySchoolFilter();
            }

            return new NearbySchoolFilter(
                NearbyOptions[nearbyOptions]
            );
        }
    },
    [Filters.renovationProgram]: {
        keys: [ 'renovationProgram' ],
        handler: ({
            renovationProgram
        }: {
            renovationProgram?: keyof typeof RenovationProgram;
        }) => {
            if (! renovationProgram) {
                return new RenovationProgramFilter();
            }

            return new RenovationProgramFilter(
                RenovationProgram[renovationProgram]
            );
        }
    },
    [Filters.seoMortgageAllowed]: {
        keys: [ 'seoMortgageAllowed' ],
        handler: ({
            seoMortgageAllowed
        }: {
            seoMortgageAllowed?: boolean;
        }) => {
            return new SeoMortgageAllowedFilter(seoMortgageAllowed);
        }
    },
    [Filters.apartments]: {
        keys: [ 'apartments' ],
        handler: ({
            apartments
        }: {
            apartments?: keyof typeof IsApartmentsOptions;
        }) => {
            if (! apartments) {
                return new ApartmentFilter();
            }

            return new ApartmentFilter(
                IsApartmentsOptions[apartments]
            );
        }
    },
    [Filters.infrastructure]: {
        keys: [ 'infrastructure', 'hasLift' ],
        handler: ({
            infrastructure = [],
            hasLift
        }: {
            infrastructure: (keyof typeof InfrastructureOptions)[];
            hasLift: keyof typeof LiftOptions;
        }) => {
            return new InfrastructureFilter(
                infrastructure.map(item => InfrastructureOptions[item]),
                LiftOptions[hasLift]
            );
        }
    },
    [Filters.mapBounds]: {
        keys: [ 'mapBounds' ],
        handler: ({ mapBounds }) => {
            return new MapBoundsFilter(mapBounds?.bounds, mapBounds?.polygon, mapBounds?.geohash);
        }
    },
    [Filters.windowsDirection]: {
        keys: [ 'windowsDirection' ],
        handler: ({ windowsDirection }) => {
            if (! windowsDirection) {
                return new WindowsDirectionFilter();
            }

            const result = windowsDirection.map((value: string) => {
                switch (value) {
                    case 'BOTH':
                        return WindowsDirectionSearchOffersEnum.Both;
                    case 'FRONT':
                        return WindowsDirectionSearchOffersEnum.Front;
                    case 'REAR':
                        return WindowsDirectionSearchOffersEnum.Rear;
                    default:
                        return null;
                }
            });

            return new WindowsDirectionFilter(result);
        }
    },
    [Filters.buildEpoch]: {
        keys: [ 'buildEpoch' ],
        handler: ({ buildEpoch }) => {
            if (! buildEpoch) return new BuildEpochFilter();

            return new BuildEpochFilter(
                buildEpoch.map((item: keyof typeof BuildEpoch) => {
                    return BuildEpoch[item];
                })
            );
        }
    },
    [Filters.onlineView]: {
        keys: [ 'onlineView' ],
        handler: ({ onlineView }: { onlineView: keyof typeof OnlineView}) => {
            if (! onlineView) return new OnlineViewFilter();

            return new OnlineViewFilter(OnlineView[onlineView]);
        }
    },
    [Filters.commissioning]: {
        keys: [ 'commissioning' ],
        handler: ({ commissioning }) => new CommissioningFilter(commissioning)
    }
};
