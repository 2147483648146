/* eslint-env browser, node */
import React from 'react';
import { Idle, NotIdle } from 'idlejs';

const sessionStorage = typeof window === 'undefined' ? undefined : window.sessionStorage;

const modalsSelector = 'div[class^=Modal], div[class^=modal-module__container], div[class^=auth], div[class^=base-modal], .CustomerFeedbackDesktop, .FSGallery, body[class*=ymaps]';

interface IUseIdleParams {
    rememberKey?: string;
    idleOnTabChange?: boolean;
    idleTimeout?: number;
}

export const useIdle = (params: IUseIdleParams = {}) => {
    const [ isIdle, setIsIdle ] = React.useState(false);
    const idle = React.useMemo(() => new Idle(), []);
    const windowIdle = React.useMemo(() => new NotIdle(), []);
    const { idleOnTabChange = false, idleTimeout = 10, rememberKey } = params;

    const isWatcherEnabled = React.useMemo(() => {
        return rememberKey !== undefined ? sessionStorage?.getItem(rememberKey) === null : true;
    }, [ ]);

    const enableIdle = React.useCallback(() => {
        const activeModals = document.querySelectorAll(modalsSelector);

        if (! document.hasFocus()) return setIsIdle(false);

        if (activeModals.length >= 1) return setIsIdle(false);

        setIsIdle(true);
    }, []);

    const disableIdle = React.useCallback(() => {
        if (rememberKey !== undefined) {
            sessionStorage?.setItem(rememberKey, rememberKey);
        }
        idle.stop();
        windowIdle.stop();
        setIsIdle(false);
    }, []);

    const resetIdle = React.useCallback(() => {
        setIsIdle(false);
    }, []);

    React.useEffect(() => {
        if (isWatcherEnabled) {
            idle
                .whenNotInteractive()
                .within(idleTimeout, 1000)
                .do(enableIdle)
                .start();
        } else {
            idle.stop();
        }

        if (isWatcherEnabled && idleOnTabChange) {
            windowIdle
                .when([ {
                    events: [ 'blur' ],
                    target: window

                } ])
                .within(1, 1000)
                .do(enableIdle)
                .start();
        } else {
            windowIdle.stop();
        }

        return () => {
            idle.stop();
            windowIdle.stop();
        };
    }, [ isWatcherEnabled, idleOnTabChange ]);

    return {
        isIdle,
        disableIdle,
        resetIdle
    };
};
