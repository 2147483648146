import { SeoMapper } from '@search/router/src';

const ekbMetroMapper = new SeoMapper('ekbMetroMapper', {
    uralmash: 169323,
    geologicheskaya: 3317,
    botanicheskaya: 6291,
    dinamo: 169337,
    'ploshchad-1905-goda': 2059,
    mashinostroitelei: 169320,
    uralskaya: 1008,
    'prospekt-kosmonavtov': 4829,
    chkalovskaya: 6510
});

const kznMetroMapper = new SeoMapper('kznMetroMapper', {
    'severnii-vokzal': 5475,
    'ploshchad-gabdulli-tukaya': 14623,
    aviastroitelnaya: 14621,
    'sukonnaya-sloboda': 14624,
    yashlek: 14622,
    ametevo: 5429,
    'kozya-sloboda': 2269,
    gorki: 2295,
    dubravnaya: 14625,
    kremlyovskaya: 2177,
    'prospekt-pobedi': 3665
});

const nskMetroMapper = new SeoMapper('nskMetroMapper', {
    'marshala-pokrishkina': 4632,
    oktyabrskaya: 4332,
    studencheskaya: 3934,
    'zolotaya-niva': 14601,
    'ploshchad-marksa': 2156,
    'beryozovaya-roshcha': 14600,
    'rechnoi-vokzal': 3809,
    'krasnii-prospekt': 4447,
    sibirskaya: 14599,
    'ploshchad-garina-mikhailovskogo': 4701,
    'ploshchad-lenina': 14602,
    gagarinskaya: 2988,
    zaeltsovskaya: 1319
});

const samaraMetroMapper = new SeoMapper('samaraMetroMapper', {
    bezimyanka: 3346,
    moskovskaya: 5732,
    kirovskaya: 14605,
    gagarinskaya: 14608,
    yungorodok: 14604,
    rossiiskaya: 14609,
    pobeda: 14606,
    sovetskaya: 14607,
    alabinskaya: 4378,
    sportivnaya: 3916
});

const nnMetroMapper = new SeoMapper('nnMetroMapper', {
    gorkovskaya: 1968,
    'dvigatel-revolyutsii': 14613,
    burevestnik: 14620,
    leninskaya: 14612,
    burnakovskaya: 2194,
    kirovskaya: 14617,
    avtozavodskaya: 14615,
    kanavinskaya: 14619,
    moskovskaya: 14610,
    chkalovskaya: 14611,
    strelka: 24659,
    'park-kulturi': 4879,
    proletarskaya: 14614,
    zarechnaya: 5452,
    komsomolskaya: 14616
});

export function regionMetroSlugMapper(region: number) {
    if (region === 1961) return nnMetroMapper;
    if (region === 3339) return samaraMetroMapper;
    if (region === 1310) return nskMetroMapper;
    if (region === 2165) return kznMetroMapper;
    if (region === 993) return ekbMetroMapper;
}
