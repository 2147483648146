import React, {
    useRef,
    useState,
    useEffect,
    RefObject,
    HTMLAttributes,
    FunctionComponent,
    MutableRefObject
} from 'react';
import { createPortal } from 'react-dom';

import classname from '@search/classname/src';
import mergeRefs from '../../../lib/mergeRefs';
import {
    Size,
    View,
    Tone,
    SizeEnum,
    ToneEnum,
    ViewEnum
} from '../../../types';

import {
    Offset,
    Direction,
    DrawingParams
} from './types';

import { PopupTail } from './PopupTail';

import { getViewportFactor } from './getViewportFactor';
import { getPopupPosition, getTailPosition } from './getPosition';
import {
    getTailDimensions,
    getPopupDimensions,
    getScopeDimensions,
    getAnchorDimensions,
    getViewportDimensions
} from './getDimensions';

import './Popup.css';

const VIEWPORT_ACCURACY_FACTOR = 0.99;

export const cnPopup = classname.bind(null, 'Popup');

export interface IPopupProps extends HTMLAttributes<HTMLDivElement> {
    /**
     * Элемент, относительно которого необходимо позиционировать попап.
     */
    anchor: RefObject<HTMLElement>;

    /**
     * Ссылка на DOM элемент попапа.
     */
    innerRef?: MutableRefObject<HTMLDivElement | undefined> | undefined;

    /**
     * Видимость попапа.
     */
    visible?: boolean;

    /**
     * Наличие хвостика у попапа.
     */
    hasTail?: boolean;

    /**
     * Направления раскрытия блока.
     *
     * @default ['bottom-left', 'bottom-center', 'bottom-right', 'top-left', 'top-center', 'top-right', 'right-top', 'right-center', 'right-bottom', 'left-top', 'left-center', 'left-bottom']
     */
    directions?: Direction[];

    /**
     * Цвет попапа
     */
    tone?: Tone;

    /**
     * Вид попапа
     */
    view?: View;

    /**
     * Рамер попапа
     */
    size?: Size;

    /**
     * Отступ попапа относительно основного направления.
     *
     * @default 0
     */
    mainOffset?: number;

    /**
     * Отступ попапа относительно второстепенного направления.
     *
     * @default 0
     */
    secondaryOffset?: number;

    /**
     * Отступ хвостика от края попапа.
     *
     * @default 0
     */
    tailOffset?: number;

    /**
     * Отступ от края окна браузера.
     *
     * @default 0
     */
    viewportOffset?: number;
}

const getDrawingParams = (
    anchor: RefObject<HTMLElement>,
    popupRef: RefObject<HTMLDivElement | undefined>,
    directions: Direction[],
    hasTail: boolean,
    tailRef: RefObject<HTMLElement>,
    offsets: Offset
): DrawingParams => {
    const anchorDimensions = getAnchorDimensions(anchor);
    const popupDimensions = getPopupDimensions(popupRef);
    const viewportDimensions = getViewportDimensions();
    const scopeDimensions = getScopeDimensions({ current: document.body });

    let viewportFactor = 0;

    const params: DrawingParams = {
        direction: 'bottom-left',
        popupPosition: { top: 0, left: 0 },
        tailPosition: { top: 0, left: 0 }
    };

    for (const nextDirection of directions) {
        const popupPosition = getPopupPosition(nextDirection, anchorDimensions, popupDimensions, offsets);
        const nextViewportFactor = getViewportFactor(popupPosition, viewportDimensions, popupDimensions, offsets);

        if (nextViewportFactor >= viewportFactor) {
            params.popupPosition = {
                top: popupPosition.top - (scopeDimensions.top + window.pageYOffset),
                left: popupPosition.left - (scopeDimensions.left + window.pageXOffset)
            };

            params.direction = nextDirection;

            viewportFactor = nextViewportFactor;
        }

        if (viewportFactor > VIEWPORT_ACCURACY_FACTOR) {
            break;
        }
    }

    if (hasTail) {
        const tailDimensions = getTailDimensions(tailRef);

        params.tailPosition = getTailPosition(
            params.direction,
            anchorDimensions,
            popupDimensions,
            tailDimensions,
            offsets
        );
    }

    return params;
};

const getOffsets = ({
    mainOffset,
    secondaryOffset,
    tailOffset,
    viewportOffset
}: {
    mainOffset: number;
    secondaryOffset: number;
    tailOffset: number;
    viewportOffset: number;
}, tailRef: RefObject<HTMLElement>): Offset => {
    const tailDimensions = getTailDimensions(tailRef);
    const computedMainOffset =
        (mainOffset >= 0 && tailDimensions.size > 0) ?
            Math.max(mainOffset, Math.round(tailDimensions.size * Math.SQRT1_2)) :
            mainOffset;

    return {
        main: computedMainOffset,
        secondary: secondaryOffset,
        tail: tailOffset,
        viewport: viewportOffset
    };
};

const Popup: FunctionComponent<IPopupProps> = ({
    anchor,
    className,
    visible = true,
    hasTail = false,
    size = SizeEnum.M,
    tone = ToneEnum.LIGHT,
    view = ViewEnum.PRIMARY,
    directions = [
        'bottom-center'
    ],
    innerRef,
    secondaryOffset = 0,
    mainOffset = 0,
    tailOffset = 0,
    viewportOffset = 0,
    ...props
}) => {
    const [ direction, setDirection ] = useState(directions[0]);

    const timerRef = useRef<number>(0);

    /**
     * Контейнер с ссылкой на DOM элемент попапа
     */
    const popupRef = useRef<HTMLDivElement>();

    /**
     * Контейнер с ссылкой на DOM элемент хвостика
     */
    const tailRef = useRef<HTMLDivElement>(null);

    const updateRefsPosition = () => {
        if (timerRef.current === 0) {
            if (popupRef.current !== null) {
                if (popupRef.current && ! popupRef.current.style.top) {
                    // При первом рендеринге попап появляется, но у него ещё нету координат,
                    popupRef.current.style.top = '-9999px';
                }
            }
        }

        window.clearTimeout(timerRef.current);

        timerRef.current = window.setTimeout(() => {
            const offsets = getOffsets({
                mainOffset,
                tailOffset,
                secondaryOffset,
                viewportOffset
            }, tailRef);
            const params = getDrawingParams(anchor, popupRef, directions, hasTail, tailRef, offsets);

            if (popupRef.current !== null && popupRef.current !== undefined) {
                popupRef.current.style.top = `${params.popupPosition!.top}px`;
                popupRef.current.style.left = `${params.popupPosition!.left}px`;
                popupRef.current.style.visibility = 'visible';
            }

            if (tailRef.current !== null) {
                tailRef.current.style.top = `${params.tailPosition!.top}px`;
                tailRef.current.style.left = `${params.tailPosition!.left}px`;
            }

            setDirection(params.direction);
        }, 0);
    };

    useEffect(() => {
        updateRefsPosition();

        if (visible) {
            window.addEventListener('scroll', updateRefsPosition);
            window.addEventListener('resize', updateRefsPosition);
        } else {
            window.removeEventListener('scroll', updateRefsPosition);
            window.removeEventListener('resize', updateRefsPosition);
        }

        return () => {
            window.clearTimeout(timerRef.current);

            window.removeEventListener('scroll', updateRefsPosition);
            window.removeEventListener('resize', updateRefsPosition);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ visible, anchor.current, directions ]);

    if (! visible) {
        return null;
    }

    return (
        createPortal(
            <div
                style={props.style}
                ref={mergeRefs(popupRef, innerRef)}
                className={cnPopup(null, { visible, view, tone, direction, size }, className)}>
                {props.children}
                {
                    hasTail ? <PopupTail innerRef={tailRef} /> : null
                }
            </div>,
            document.body
        )
    );
};

export default Popup;
