import React from 'react';
import classname from '@search/classname/src';
import { ImageLazySeo } from '../../../../../../../domain/image/lazy/ImageLazySeo';
import { ImageAttributesTypeEnum } from '../../../../../../../enums';
import './styles.css';

import { IImage } from '../index';

const cn = classname.bind(null, 'GalleryPreview');

interface IProps extends IImage {
    originPath?: string;
    /**
     * @deprecated baseUrl + originPath
     */
    src?: string;
    /**
     * @deprecated baseUrl + originPath
     */
    src2x?: string;
    size: 'full' | 'average' | 'minimum' | 'small' | 'least' | 'contain';
    isFrozen?: boolean;
    isLast?: boolean;
    isFirst?: boolean;
    attributesType?: keyof typeof ImageAttributesTypeEnum | null;
    restCount: number;
    itemScope?: boolean;
    imageDataset?: Record<string, string>;
    /**
     * @default 280
     */
    resizeImageWidth?: number;
    /**
     * @default 210
     */
    resizeImageHeight?: number;
}

function GalleryPreview({
    originPath,
    src2x,
    description,
    size,
    isFrozen,
    attributesType,
    isFirst,
    isLast,
    restCount,
    itemScope,
    imageDataset,
    resizeImageWidth = 560,
    resizeImageHeight = 420
}: IProps) {
    return (<>
        <ImageLazySeo
            itemScope={itemScope}
            dataSet={imageDataset}
            loadingLazy={isFirst ? 'native' : 'io'}
            className={cn('image', {
                size,
                frozen: isFrozen,
                layout: attributesType === ImageAttributesTypeEnum.LAYOUT
            })}
            originPath={originPath}
            srcWidth={resizeImageWidth}
            srcHeight={resizeImageHeight}
            src={src2x}
            alt={description}
            title={description}
        />
        {isLast && restCount > 0 && (
            <div
                className={cn('last-image')}
                data-test='snippet-gallery-last-photo'
            >
                Ещё {restCount} фото
            </div>
        )}
    </>);
}

export default GalleryPreview;
