import { OfferRoute } from '@search/offer-search-routes/src/offerRoute';
import { Router } from '@search/router/src/Router';
import { RouterContext } from '@search/offer-search-routes/src/RouterProvider';
import { DealType, NewBuilding, RealtyObjectType, Rooms } from '@search/filter-enums/enums';

export interface IOfferRouteParams {
    offerId: string;
    dealType: DealType;
    categoryType: RealtyObjectType;
    newBuilding: NewBuilding;
    room?: Rooms;
    region?: number;
}

export const makeOfferUrl = (
    router: Router<RouterContext>,
    routeParams: IOfferRouteParams
): string | null => {
    const { region } = routeParams;

    if (region) {
        return new OfferRoute({
            context: {
                ...router.context,
                rootRegions: undefined
                // @todo Надо грузить clasified root regions
                // и передавать его, только если в нем есть регион с id из routeParams.region
                // иначе - передавать в rootRegions undefined, но в таком случае будет редирект на урл с регионом
            },
            location: router.location
        }).url(routeParams);
    }

    return null;
};
