import { declensionByNumber } from '@search/helpers/src/declensionByNumber';

export const declensionOffers = (offersCount: number) =>
    declensionByNumber(offersCount, [ 'предложение', 'предложения', 'предложений' ]);

export const declensionYear = (year: number) => declensionByNumber(year, [ 'год', 'года', 'лет' ]);

export const declensionСonsiderBankNominative = (banksTotal: number) =>
    declensionByNumber(banksTotal, [ 'рассмотрит', 'рассмотрят', 'рассмотрят' ]);

export const declensionHour = (hours: number) => declensionByNumber(hours, [ 'час', 'часа', 'часов' ]);

export const declensionMinute = (minutes: number) => declensionByNumber(minutes, [ 'минута', 'минуты', 'минут' ]);

export const declensionBankNominative = (banksTotal: number) =>
    declensionByNumber(banksTotal, [ 'банк', 'банка', 'банков' ]);

export const declensionBankGenitive = (banksTotal: number) =>
    declensionByNumber(banksTotal, [ 'банка', 'банков', 'банков' ]);
