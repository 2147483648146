import React, { useCallback } from 'react';
import { Bathroom } from '@search/filter-enums/enums';
import { BathroomFilter } from '../../../../models/Filter';
import MobileFilter from '../../../filter/MobileFilter';
import '../MobileCommon.css';
import { MultiSelect, MultiSelectOption } from '../../common/MultiSelect';

interface IMobileBathroomFilterViewProps {
    filter: BathroomFilter;
    handleChange: (filter: BathroomFilter) => void;
    hasSeveral?: boolean;
}

const selectOptions: MultiSelectOption[] = [
    {
        value: Bathroom.COMBINED,
        label: 'Совмещённый',
        className: 'col-xs-12 filter-item-gap'
    },
    {
        value: Bathroom.PARTED,
        label: 'Раздельный',
        className: 'col-xs-12 filter-item-gap'
    }
];

const selectOptionsWithSeveral: MultiSelectOption[] = [
    ...selectOptions,
    {
        value: Bathroom.SEVERAL,
        label: 'Несколько',
        className: 'col-xs-12 filter-item-gap'
    }
];

export const MobileBathroomFilterView: React.FC<IMobileBathroomFilterViewProps> = ({
    filter,
    handleChange,
    hasSeveral = true
}) => {
    const onChange = useCallback(value => {
        handleChange(new BathroomFilter(value));
    }, [ handleChange ]);

    return (
        <MobileFilter
            filterTitle='Санузел'
        >
            <MultiSelect
                name='bathroom'
                className='container container__clamp'
                options={hasSeveral ? selectOptionsWithSeveral : selectOptions}
                value={filter.value}
                handleChange={onChange}
            />
        </MobileFilter>
    );
};
