import { Tokens } from '@search/router/src/RouterInterfaces';

import {
    NewBuildingSearchRoute,
    INewBuildingSearchSeoType,
    WLNewBuildingSearchRoute
} from './NewBuildingSearchRoute';

export class NewBuildingSearchMapRoute extends NewBuildingSearchRoute {
    displayName = 'NewBuildingSearchMapRoute';

    protected pattern(p: Tokens<INewBuildingSearchSeoType>) {
        return `${this.patternHost()}(/${
            p.region
        })/novostroyki/karta(/srok-${
            p.commissioningSeo
        })(/${
            p.roomSeo
        })(/${
            p.euroPlanningSeo
        })(/${
            p.hasFinishingSeo
        })(/otdelka-${
            p.finishingTypeSeo
        })(/klass-${
            p.buildingClassSeo
        })(/${
            p.wallsTypeSeo
        })(/${
            p.floorLastSeo
        })(/${
            p.ceilingHeightSeo
        })(/${
            p.kitchenAreaRangeMinSeo
        })(/${
            p.law214Seo
        })(/${
            p.escrowSeo
        })(/${
            p.armyMortgageSeo
        })(/${
            p.motherCapitalSeo
        })(/${
            p.installmentSeo
        })(/${
            p.closedAreaSeo
        })(/${
            p.conciergeSeo
        })(/${
            p.balconySeo
        })(/${
            p.apartmentsSeo
        })(/${
            p.parkingSeo
        })(/${
            p.flatsSpecialEventsSeo
        })(/${
            p.priceMaxSeo
        })(/${
            p.pricePerMeterMaxSeo
        })(/${
            p.cheapSeo
        })(/${
            p.closeToMetroSeo
        })(/metro-${
            p.metroSeo
        })(/rayon-${
            p.rayon
        })(/${
            p.okrug
        }-okrug)(/${
            p.geoSlugSeo
        })(/${
            p.city
        })/`;
    }
}

export class WLNewBuildingSearchMapRoute extends WLNewBuildingSearchRoute {
    displayName = 'WLNewBuildingSearchMapRoute';

    protected pattern() {
        return `${this.patternHost()}/karta/`;
    }
}
