import { SeoMapper } from '@search/router/src';
import { dedupeValues } from '../../dedupeValues';

export const lenOblMetroStation = {
    devyatkino: 894
} as const;

export const spbMetroStation = {
    avtovo: 7588,
    admiralteiskaya: 5229,
    akademicheskaya: 7502,
    baltiiskaya: 4994,
    begovaya: 2175,
    bukharestskaya: 9366,
    vasileostrovskaya: 2787,
    vladimirskaya: 7834,
    volkovskaya: 8359,
    viborgskaya: 7573,
    gorkovskaya: 8206,
    'gostinii-dvor': 14627,
    'grazhdanskii-prospekt': 6299,
    dostoevskaya: 2208,
    dunaiskaya: 1387,
    elizarovskaya: 1005,
    zvenigorodskaya: 7832,
    zvyozdnaya: 2846,
    'kirovskii-zavod': 8124,
    'komendantskii-prospekt': 5611,
    'krestovskii-ostrov': 6873,
    kupchino: 4487,
    ladozhskaya: 560,
    'leninskii-prospekt': 7587,
    lesnaya: 2125,
    'ligovskii-prospekt': 5115,
    lomonosovskaya: 3565,
    mayakovskaya: 7882,
    mezhdunarodnaya: 8418,
    moskovskaya: 4847,
    'moskovskie-vorota': 3434,
    'nevskii-prospekt': 2247,
    'obvodnii-kanal': 7968,
    narvskaya: 3954,
    // Old and new mapping for Zenit (Novokrestovskaya)
    // First map entry (novokrestovskaya) keeps the link https://m2.ru/sankt-peterburg-i-oblast/nedvizhimost/kupit-kvartiru/metro-novokrestovskaya/ working
    // Second entry is the main entry (the code is written in a such a way) - it is used for route building (when reverse mapping is needed) (e.g. build an url after selecting in geo suggest, build an url for sitemap)
    novokrestovskaya: 7589,
    zenit: 7589,
    novocherkasskaya: 4784,
    obukhovo: 3000,
    ozerki: 2221,
    parnas: 1234,
    petrogradskaya: 6665,
    pionerskaya: 3564,
    'park-pobedi': 8147,
    'ploshchad-aleksandra-nevskogo': 2845,
    // These two are kept for backward compatibility only
    'ploshchad-aleksandra-nevskogo-1': 2845,
    'ploshchad-aleksandra-nevskogo-2': 2845,
    'ploshchad-vosstaniya': 6231,
    'ploshchad-lenina': 2136,
    'ploshchad-muzhestva': 4480,
    politekhnicheskaya: 7524,
    primorskaya: 7539,
    proletarskaya: 4578,
    'prospekt-bolshevikov': 3413,
    'prospekt-veteranov': 1150,
    'prospekt-prosveshcheniya': 5096,
    pushkinskaya: 2135,
    ribatskoe: 1389,
    sadovaya: 5435,
    'sennaya-ploshchad': 9039,
    spasskaya: 5252,
    sportivnaya: 3953,
    'staraya-derevnya': 5615,
    'tekhnologicheskii-institut': 1338,
    // These two are kept for backward compatibility only
    'tekhnologicheskii-institut-1': 1338,
    'tekhnologicheskii-institut-2': 1338,
    udelnaya: 3691,
    'ulitsa-dibenko': 5309,
    frunzenskaya: 1189,
    chernishevskaya: 1354,
    chkalovskaya: 7572,
    'chyornaya-rechka': 3401,
    elektrosila: 4995,
    'prospekt-slavy': 20613,
    shushary: 67241,
    'yugo-zapadnaya': 174415,
    putylovskaya: 174417,
    teatralnaya: 257084,
    'gorniy-institut': 257085
} as const;

export const spbMetroMapper = new SeoMapper('spbMetroMapper', {
    ...lenOblMetroStation,
    ...spbMetroStation
} as const);

export const lenOblMetroIds = dedupeValues(lenOblMetroStation);
export const spbMetroIds = dedupeValues(spbMetroStation);
