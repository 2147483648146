import React from 'react';

const deferQueue: (() => void)[] = [];

let deferTick: Promise<void> | undefined;

function deferQueueRun() {
    let cb: (() => void) | undefined;

    // eslint-disable-next-line no-cond-assign
    while (cb = deferQueue.pop()) {
        cb();
    }
    deferTick = undefined;
}

export function deferRun(cb: () => void) {
    deferQueue.push(cb);
    if (! deferTick) {
        deferTick = Promise.resolve().then(deferQueueRun);
    }
}

export type IntersectionTriggerItemOptions<El extends HTMLElement> = {
    /**
     * Сработает только для не-нативной загрузки, когда картинка попадает в область видимости
     */
    onVisible(target: El): void;

    /**
     * Сработает только для не-нативной загрузки, когда картинка выходит из области видимости
     */
    onInvisible?(target: El): void;
};

export type IntersectionTriggerProps = {
    /**
     * Видимая область, относительно которой будет учитываться rootMargin и наблюдатся пересечения.
     * IO вызывает onVisible на элементе при соответствующих условиях, даже если root-элемент невидим.
     *
     * undefined - root element body
     * false - IntersectionTrigger не будет добавлять элементы в реальный IO, а будет только запоминать.
     * Когда root сменится в методе update на HTMLElement, то все запомненные элементы добавятся в IO.
     *
     * https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#Intersection_observer_options
     */
    root?: HTMLElement | false;

    /**
     * https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#Intersection_observer_options
     */
    rootMargin?: string;

    /**
     * https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#Intersection_observer_options
     */
    threshold?: number;
}

export class IntersectionTrigger {
    constructor(protected props: IntersectionTriggerProps = {}) {
        this.props = props;
    }

    protected ioCache: IntersectionObserver | undefined = undefined;

    protected get isNative() {
        return typeof IntersectionObserver !== 'undefined';
    }

    protected get io() {
        if (this.ioCache) return this.ioCache;
        if (! this.isNative) return undefined;

        /**
         * В реальный IO не добавляем, а только в this.elementOptions.
         * Когда через this.update подъедет HTMLElement в root, добавим все элементы из elementOptions в IO
         */
        if (this.props.root === false) return undefined;

        this.ioCache = new IntersectionObserver(this.observerCallback.bind(this), {
            threshold: this.props.threshold,
            rootMargin: this.props.rootMargin,
            root: this.props.root
        });

        return this.ioCache;
    }

    update(props: IntersectionTriggerProps) {
        this.props = props;
        this.ioCache?.disconnect();
        this.ioCache = undefined;

        if (this.elementOptions.size === 0) return;

        // Картинки будут складываться в elementOptions,
        // когда появится HTMLElement в root, эффект дернет update и элементы добавятся в реальный IO.
        for (const [ el, opts ] of this.elementOptions) {
            this.add(el, opts);
        }
    }

    protected elementOptions = new Map<HTMLElement, IntersectionTriggerItemOptions<HTMLElement>>();

    protected observerCallback<El extends HTMLElement>(
        entries: IntersectionObserverEntry[],
        observer: IntersectionObserver
    ) {
        for (const entry of entries) {
            const img = entry.target as El;
            const opts = this.elementOptions.get(img);

            if (! entry.isIntersecting) {
                opts?.onInvisible?.(img);
                continue;
            }

            opts?.onVisible(img);

            if (! opts?.onInvisible) observer.unobserve(img);
        }
    }

    disconnect() {
        this.ioCache?.disconnect();
        this.elementOptions.clear();
        this.ioCache = undefined;
    }

    add<El extends HTMLElement>(el: El, opts: IntersectionTriggerItemOptions<El>) {
        this.elementOptions.set(el, opts);
        const io = this.io;

        if (! this.isNative) {
            // Невозможен lazy loading без поддержки в браузере IntersectionObserver.
            deferRun(() => opts.onVisible?.(el));
            return;
        }

        io?.observe(el);
    }

    remove(el: HTMLElement) {
        this.io?.unobserve(el);
        this.elementOptions.delete(el);
    }
}

export type UseIntersectionTriggerProps = Omit<IntersectionTriggerProps, 'root' | 'isSlave'> & {
    /**
     * Родительский IntersectionTrigger
     */
     parent?: IntersectionTrigger;

     /**
     * Если rootRef передан, то через родительский IntersectionTrigger, будет мониториться наличие и видимость rootRef.current.
     * При видимом rootRef.current все элементы перенесутся в реальный IO.
     */
    rootRef?: React.RefObject<HTMLElement>;
};

export function useIntersectionTrigger(
    {
        rootRef,
        parent,
        rootMargin,
        threshold
    }: UseIntersectionTriggerProps
) {
    /**
     * см. IntersectionTriggerProps.root в случае false
     */
    const [ visibleRoot, setVisibleRoot ] = React.useState<HTMLElement | false | undefined>(
        rootRef ? false : rootRef
    );

    React.useEffect(() => {
        const root = rootRef?.current;

        if (! root || ! parent) return;

        parent.add(root, { onVisible: setVisibleRoot });

        return () => parent.remove(root);
    }, [ parent, rootRef ]);

    const itRef = React.useRef(new IntersectionTrigger());

    React.useEffect(() => {
        itRef.current.update({
            rootMargin,
            threshold,
            root: visibleRoot
        });
    }, [ visibleRoot, rootMargin, threshold ]);

    React.useEffect(() => {
        const it = itRef.current;

        return () => it.disconnect();
    }, [ ]);

    return itRef.current;
}
