import './MortgageStupidBanner.css';

import React from 'react';

import { useUserDetailsLoader } from '@search/auth/src/userDetails/userDetails';
import { ApplicationIdEnum } from '@search/graphql-typings/src';

import { getRealtyObjectTypeGql, getSubjectAppName, Mortgage2ContactsFormSendProps, normalizeCallbackUrl, useMortgage2ContactsFormSend } from '../mortgage2/mortgage2ContactsFormSend';
import { useMortgage2UserForm } from '../mortgage2/useMortgage2User';
import { SeoSearchUtm } from '../../../../../domain/seo/SeoSearchUtm';
import { useClassifiedOfferDataLayerPush } from '../../../../../domain/offer/analytics/ClassifiedOfferGtmContext';
import { useGtmOnVisible } from '../../../../../domain/google/useGtmOnVisible';

export type MortgageStupidProps = {
    gtmSendId?: string;
    gtmShowId?: string;
    regionId?: number;
    mortgageRegionId?: number;
    onClose?: (e?: React.MouseEvent | React.KeyboardEvent) => void;
    isNewBuilding: boolean;
    showPreferential?: boolean;
    isPreferentialNbCondition?: boolean;
    isMobile?: boolean;
    applicationId?: ApplicationIdEnum;
    isSendBuyDieAnalytics?: boolean;
    minimalMortgageRate?: number | null;
} & Mortgage2ContactsFormSendProps;

export const useStupidMortgage = ({
    gtmSendId,
    gtmShowId,
    regionId,
    mortgageRegionId,
    showPreferential,
    isMobile,
    applicationId,
    locationPlacement,
    realtyType,
    offerUrl,
    isSendBuyDieAnalytics,
    gtmSendData
}: MortgageStupidProps) => {
    const userInfoLoad = useUserDetailsLoader();
    const userDetails = userInfoLoad({});

    const {
        isFormSended,
        isFormSyncing,
        setFormAllTouched,
        setFormSended,
        setFormIsSending,

        name,
        isNameValid,
        isNameRequired,
        nameInputValid,
        setName,
        setNameTouched,

        phone,
        isPhoneValid,
        phoneInputValid,
        setPhone,
        setPhoneTouched
    } = useMortgage2UserForm({ userDetails: userDetails.data?.userProfile.person });

    const dataLayerPush = useClassifiedOfferDataLayerPush();
    const mortgage2ContactsFormSend = useMortgage2ContactsFormSend();
    const sendQuery = React.useCallback((e?: React.MouseEvent<Element> | React.KeyboardEvent<HTMLInputElement>) => {
        e?.stopPropagation();
        e?.preventDefault();

        // RS-3869 remove - ! isMobile &&
        if (! isPhoneValid || (isNameRequired && ! isNameValid)) {
            setFormAllTouched();
            return;
        }

        setFormIsSending();

        const utm = SeoSearchUtm.get();

        mortgage2ContactsFormSend({
            realtyType: getRealtyObjectTypeGql(realtyType),
            name,
            utm: utm ? { list: utm } : undefined,
            phone,
            regionId: mortgageRegionId ?? regionId,
            applicationId,
            locationPlacement,
            link: normalizeCallbackUrl(offerUrl),
            subject: `Заявка на ипотеку с${showPreferential ? ' гос. поддержкой с' : ''} “${
                getSubjectAppName(applicationId)}”`,
        })
            .then(() => {
                setFormSended();
                gtmSendId && dataLayerPush({ event: gtmSendId });
                gtmSendData && dataLayerPush(gtmSendData);
                if (applicationId === ApplicationIdEnum.ClassifiedDesktop || applicationId === ApplicationIdEnum.ClassifiedTouch) {
                    dataLayerPush({ event: 'up_ib_send_form' });
                }
                if (isSendBuyDieAnalytics) {
                    dataLayerPush({
                        event: 'up_action',
                        theme: 'ipoteka',
                        page_type: 'card',
                        type: 'buy_or_save_req'
                    });
                }
            }).catch(error => {
                // eslint-disable-next-line no-console
                console.error(error);
                setFormSended(error);
            });
    }, [
        showPreferential,
        isPhoneValid,
        isNameValid,
        isNameRequired,
        setFormIsSending,
        realtyType,
        name,
        phone,
        mortgageRegionId,
        regionId,
        applicationId,
        locationPlacement,
        offerUrl,
        setFormAllTouched,
        setFormSended,
        gtmSendId,
        dataLayerPush,
        isMobile,
        isSendBuyDieAnalytics
    ]);
    const sendQueryOnEnter = React.useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key !== 'Enter') {
            return;
        }

        sendQuery(e);
    }, [ sendQuery ]);

    const onVisible = React.useCallback(() => {
        if (! gtmShowId) return;
        dataLayerPush({ event: gtmShowId });
    }, [ gtmShowId, dataLayerPush ]);

    const containerRef = React.useRef<HTMLDivElement>(null);

    useGtmOnVisible({ containerRef, onVisible });

    return {
        containerRef,
        sendQueryOnEnter,
        sendQuery,

        isFormSended,
        isFormSyncing,
        nameInputValid,
        setName,
        setNameTouched,
        phoneInputValid,
        setPhone,
        setPhoneTouched,
        name,
        phone,
        loading: userDetails.loading
    };
};
