import './styles.css';

import React from 'react';

import classname from '@search/classname/src';
import { Select } from '@vtblife/uikit';

import type { useWorkingHours } from './useWorkingHours';

const cn = classname.bind(null, 'WorkingHours');

export function WorkingHours({
    mobile,
    workingHoursRange,
    setStartHoursRange,
    setEndHoursRange,
    startHourValues,
    endHourValues,
    size = 'l'
}: {
    mobile?: boolean;
    size?: 's' | 'm' | 'l';
} & Omit<ReturnType<typeof useWorkingHours>, 'rawWorkingHoursRange'>) {
    return (
        <div className={cn('flex')}>
            <Select
                onChange={setStartHoursRange}
                native={mobile}
            >
                <Select.Button
                    size={size}
                    label='Не ранее'
                >
                    {workingHoursRange.start}
                </Select.Button>
                <Select.Content height={172}>
                    {
                        startHourValues.map(({ value, label, disabled }) => (
                            <Select.Option
                                key={value}
                                disabled={disabled}
                                value={value}
                                selected={workingHoursRange.start === label}
                            >
                                {label}
                            </Select.Option>
                        ))
                    }
                </Select.Content>
            </Select>
            <Select
                onChange={setEndHoursRange}
                native={mobile}
            >
                <Select.Button
                    label='Не позднее'
                    size={size}
                >
                    {workingHoursRange.end}
                </Select.Button>
                <Select.Content height={172}>
                    {
                        endHourValues.map(({ value, label, disabled }) => (
                            <Select.Option
                                key={value}
                                disabled={disabled}
                                value={value}

                                selected={workingHoursRange.end === label}
                            >
                                {label}
                            </Select.Option>
                        ))
                    }
                </Select.Content>
            </Select>
        </div>
    );
}
