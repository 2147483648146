/* global window */
import React from 'react';
import { Tooltip, useTooltip, ShareModal } from '@vtblife/uikit';
import { useClassifiedOfferDataLayerPush } from '../offer/analytics/ClassifiedOfferGtmContext';
import { ButtonTwoState } from '../../view/common/components/FavoritesButton/ButtonTwoState';
import { useShareLinksNative } from './useShareLinks';

export function ShareButton({
    gtmShowModal = 'ClModalShareStart',
    gtmShare = 'ClModalShareChoise',
    title,
    isCircle,
    isMobile,
    children = 'Поделиться',
    textPlacement = 'none',
    preferNativeShare = true,
    onModalVisibilityChange,
}: {
    isMobile?: boolean;
    gtmShowModal?: string;
    gtmShare?: string;
    title?: string;
    children?: React.ReactNode;
    isCircle?: boolean;
    textPlacement?: 'none' | 'button' | 'tooltip';
    preferNativeShare?: boolean;
    onModalVisibilityChange?(isVisible: boolean): void;
}) {
    const dataLayerPush = useClassifiedOfferDataLayerPush();
    const [ isModal, setIsModal ] = React.useState(false);

    const { nativeShare } = useShareLinksNative({ title, gtmShare });

    React.useEffect(() => {
        onModalVisibilityChange?.(isModal);
    }, [ isModal ]);

    const showModal = React.useCallback(() => {
        if (nativeShare && preferNativeShare) {
            nativeShare();
            return;
        }

        if (gtmShowModal) dataLayerPush({ event: gtmShowModal });
        setIsModal(true);
    }, [ gtmShowModal, nativeShare, dataLayerPush, preferNativeShare ]);

    const closeModal = React.useCallback(() => {
        setIsModal(false);
    }, []);

    const tt = useTooltip();

    const currentUrl =
        typeof window === 'undefined' ? '' : window.location.href;

    const analyticsPush = React.useCallback(
        (id: string) => {
            if (! gtmShare) return;
            dataLayerPush({ event: gtmShare, soc_sources: id });
        },
        [ gtmShare, dataLayerPush ]
    );

    const variant = isMobile ? 'transparent-alt-bg' : 'secondary';

    const body = (
        <>
            <ShareModal
                url={currentUrl}
                opened={isModal}
                title={title}
                onClose={closeModal}
                onShare={analyticsPush}
            />
            <ButtonTwoState
                size='s'
                fullWidth
                variant={isCircle ? 'primary-alt-bg' : variant}
                shadow={isCircle}
                icon='share'
                dataTest='card-button-share'
                circle={isCircle}
                onClick={showModal}
            >
                {textPlacement === 'button' ? children : ''}
            </ButtonTwoState>
            {textPlacement === 'tooltip' ? (
                <Tooltip direction='up' to={tt.to}>
                    {children}
                </Tooltip>
            ) : null}
        </>
    );

    return isMobile ? body : <div ref={tt.registerControl}>{body}</div>;
}
