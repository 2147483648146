import React from 'react';
import { UseIntersectionTriggerProps, IntersectionTrigger,
    useIntersectionTrigger } from '../useIntersectionTrigger';

export type ImageLazyProps = React.ComponentProps<'img'> & {
    /**
     * url заглушки,  во время загрузки основной картинки, работает только для не-нативной ленивой загрузки
     */
    mockSrc?: string;

    /**
     * Метод ленивой загрузки
     *
     * detect - автодетект, нативная ленивая загрузка, если браузер поддерживает.
     * Для SSR, на сервере надо задать в ImageLazyObserverProvider свойство isBrowserSupportsLazy, можно детектить из user agent.
     * Иначе на сервере тег картинки сгенерится как для эмуляции на IntersectionObserver.
     * После загрузки js в браузер, будет лишний перерендер дома.
     *
     * Если браузер не поддерживает, будет эмуляция на IntersectionObserver, которая срабатывает с задержкой.
     * До загрузки js, картинки не будут видны, заметно при history.back или forward + pushState.
     * Робот, не умеющий в js, увидит вместо src - mockSrc, хотя прочтет data-src.
     *
     * io - упреждающая ленивая загрузка на IntersectionObserver. Больше рассчитана для мобильной галереи.
     * При ssr будет заметна подгрузка, пока js не прогрузился
     *
     * native - нативный lazy, даже если браузер не поддерживает.
     * Можно делать первую картинку в снипете.
     * В сафари будет жадная загрузка. В хроме, нативный lazy подгрузит заранее.
     */
    loadingLazy?: 'native' | 'io' | 'detect';
}

export type ImageLazyObserverProps = {
    children: React.ReactNode;

    /**
     * Заглушка по-умолчанию, которая будет показываться в случае не нативного lazy на IntersectionObserver.
     */
    defaultMockSrc?: string;

    /**
     * Поддерживает ли браузер lazy load для картнок.
     *
     * При SSR, тут надо по user agent детектить поддержку lazy,
     * иначе будет лишний repaint/reflow, после загрузки всех картинок на клиенте
     */
    isBrowserSupportsLazy?: boolean;
} & UseIntersectionTriggerProps;

const imageLazyOptionsDefault = {
    defaultMockSrc: 'data:image/svg+xml;charset=utf8,%3Csvg%20xmlns=\'http://www.w3.org/2000/svg\'%3E%3C/svg%3E',
    /**
     * Работает только в клиентской части, на сервере надо через user agent устанавивать ImageLazyObserverProvider.isBrowserSupportsLazy.
     */
    isBrowserSupportsLazy: typeof HTMLImageElement !== 'undefined' && 'loading' in HTMLImageElement.prototype,
    it: new IntersectionTrigger({ rootMargin: '500px 10%' })
};

export const ImageLazyObserverContext = React.createContext(imageLazyOptionsDefault);

export function useImageLazyObserver() {
    return React.useContext(ImageLazyObserverContext);
}

export function ImageLazyObserverProvider({
    children,
    rootRef,
    threshold,
    defaultMockSrc = imageLazyOptionsDefault.defaultMockSrc,
    isBrowserSupportsLazy = imageLazyOptionsDefault.isBrowserSupportsLazy,
    rootMargin = '500px 10%'
}: ImageLazyObserverProps) {
    const { it: parent } = useImageLazyObserver();
    const it = useIntersectionTrigger({ rootRef, threshold, rootMargin, parent });

    const value = React.useMemo(() => ({
        defaultMockSrc,
        isBrowserSupportsLazy,
        it
    }), [ it, isBrowserSupportsLazy, defaultMockSrc ]);

    return <ImageLazyObserverContext.Provider value={value}>{children}</ImageLazyObserverContext.Provider>;
}

export type UseImageLazyProps<El extends HTMLImageElement> = Pick<ImageLazyProps, 'loadingLazy' | 'src' | 'mockSrc'>
    & {
        ref?: React.LegacyRef<El>;
        srcSet?: string;
    }

export function useImageLazy<El extends HTMLImageElement>(
    {
        loadingLazy,
        ref,
        src,
        srcSet,
        mockSrc
    }: UseImageLazyProps<El>
) {
    const ll = useImageLazyObserver();

    const isNative = loadingLazy === 'native' || (loadingLazy === 'detect' && ll.isBrowserSupportsLazy);
    const loading = isNative ? 'lazy' : undefined;

    const isIO = loadingLazy === 'io' || (loadingLazy === 'detect' && ! ll.isBrowserSupportsLazy);
    const it = isIO ? ll.it : undefined;

    const mock = mockSrc ?? ll.defaultMockSrc;

    const realRef = React.useRef({ src: it ? mock : src, srcSet: it ? mock : srcSet });
    const [ , setCount ] = React.useState(0);
    const imgRef = React.useRef<El | null>(null);
    const extRef = React.useRef(ref);

    extRef.current = ref;

    React.useEffect(() => {
        const el = imgRef.current;

        if (! el) return;

        it?.add(el, {
            onVisible() {
                realRef.current = { src, srcSet };
                setCount(p => p + 1);
            }
        });

        const subRef = extRef.current;

        if (typeof subRef === 'function') subRef(el);

        if (subRef && typeof subRef === 'object') {
            (subRef as React.MutableRefObject<El>).current = el;
        }

        if (! it && (src !== realRef.current.src || srcSet !== realRef.current.srcSet)) {
            realRef.current = { src, srcSet };
            setCount(p => p + 1);
        }

        return () => it?.remove(el);
    }, [ it, src, srcSet ]);

    return {
        loading,
        imgRef,
        real: realRef.current
    } as const;
}

/**
 * Drop-in замена для стандартного img, с фолбэком ленивой загрузки на IntersectionObserver,
 * который, в свою очередь, с фолбэком на жадную загрузку.
 */
export const ImageLazy = React.forwardRef<Omit<HTMLImageElement, 'loading'>, ImageLazyProps>((
    {
        loadingLazy,
        mockSrc,
        src,
        srcSet,
        ...props
    },
    ref
) => {
    const { imgRef, real, loading } = useImageLazy({
        loadingLazy,
        ref,
        src,
        srcSet,
        mockSrc
    });

    return (
        <img
            {...props}
            ref={imgRef}
            crossOrigin='anonymous'
            loading={loading}
            src={real.src}
            srcSet={real.srcSet}
        />
    );
});
