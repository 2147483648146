import React, { useCallback } from 'react';

import { Checkbox } from '@vtblife/uikit';
import { OtherSpecialEvent } from '@search/filter-enums/enums/SpecialEvents';

import { OtherSpecialEventsFilter } from '../../../../models/Filter';
import { Filter2 } from '../../../new_filter/Filter2';
import { CheckboxGroup2 } from '../../../common/CheckboxGroup2';

interface IDesktopOtherSpecialEventsFilterViewProps {
    filter: OtherSpecialEventsFilter;
    handleChange: (value: OtherSpecialEventsFilter) => void;
}

const ITEMS = [
    [ OtherSpecialEvent.OTHER_DISCOUNT, 'Скидка' ],
    [ OtherSpecialEvent.OTHER_INSTALLMENT_PLAN, 'Рассрочка' ],
    [ OtherSpecialEvent.OTHER_SALES_START, 'Старт продаж' ]
];

export const DesktopOtherSpecialEventsFilterView: React.FC<IDesktopOtherSpecialEventsFilterViewProps> = ({
    filter,
    handleChange
}) => {
    const onChange = useCallback(value => {
        handleChange(new OtherSpecialEventsFilter(value));
    }, [ handleChange ]);

    return (
        <Filter2 filterTitle='Паркинг, кладовки'>
            <CheckboxGroup2
                mode='check'
                name='otherSpecialEvents'
                onChange={onChange}
                value={filter.value}
                isFilter
            >
                {
                    ITEMS.map(([ value, label ]) => (
                        <Checkbox
                            variant='filter'
                            key={value}
                            name={value}
                            block
                        >
                            {label}
                        </Checkbox>
                    ))
                }
            </CheckboxGroup2>
        </Filter2>
    );
};
