import React, { useCallback, useEffect, useMemo, useState } from 'react';
import throttle from 'lodash/throttle';

import { Button } from '@vtblife/uikit';
import classname from '@search/classname/src';

import { useSwiper } from '@search/vtbeco-frontend-core/view/common/hooks/useSwiper';

import './styles.css';

type Direction = 'left' | 'right';

const cn = classname.bind(null, 'Slider');
const SCREEN_XL = 1420;

const Slider = ({
    children,
    totalItems,
    onIndexChange,
    contentClassName,
    navClassName,
    isNavHidden = false,
    onSetMaxItems
}: {
    children: React.ReactNode[];
    totalItems?: number;
    onIndexChange?: (pageNumber: number) => void;
    contentClassName?: string;
    navClassName?: string;
    isNavHidden?: boolean;
    onSetMaxItems?: (maxItems: number) => void;
}) => {
    const [ index, setIndex ] = useState<number>(0);
    const [ maxItems, setMaxItems ] = useState<number>(3);

    const slidesTotal = useMemo(() => totalItems ?? children.length, [ children, totalItems ]);
    const maxIndex = useMemo(() => slidesTotal - maxItems, [ slidesTotal, maxItems ]);

    const handleSetMaxItems = useCallback((value: number) => {
        setMaxItems(value);
        onSetMaxItems?.(value);
    }, [ onSetMaxItems ]);

    const handleResize = useCallback(() => {
        const { clientWidth } = document.documentElement;

        if (clientWidth >= SCREEN_XL && maxItems !== 4) {
            handleSetMaxItems(4);
        } else if (clientWidth < SCREEN_XL && maxItems !== 3) {
            handleSetMaxItems(3);
        }

        if (index > maxIndex) {
            setIndex(maxIndex);
        }
    }, [ index, maxIndex, maxItems, handleSetMaxItems ]);

    useEffect(() => {
        const throttledHandleResize = throttle(handleResize, 200);

        throttledHandleResize();

        window.addEventListener('resize', throttledHandleResize);

        return () => {
            window.removeEventListener('resize', throttledHandleResize);
        };
    }, [ handleResize ]);

    const handleShift = useCallback((direction: Direction) => {
        const newIndex = Math.max(
            Math.min(
                index + (direction === 'left' ? -maxItems : maxItems),
                maxIndex
            ),
            0
        );

        setIndex(newIndex);
        onIndexChange && onIndexChange(newIndex);
    }, [ index, maxIndex, maxItems, onIndexChange ]);

    const onSwipeLeft = useCallback(() => handleShift('right'), [ handleShift ]);
    const onSwipeRight = useCallback(() => handleShift('left'), [ handleShift ]);

    const {
        handleTouchEnd,
        handleTouchMove,
        handleTouchStart
    } = useSwiper({
        onSwipeLeft,
        onSwipeRight
    });

    return (
        <div className={cn()}>
            {
                slidesTotal > maxItems && ! isNavHidden ? (
                    <div className={cn('nav', undefined, navClassName)}>
                        {
                            [
                                'left' as Direction,
                                'right' as Direction
                            ].map(direction => (
                                <div key={direction}>
                                    <Button
                                        size='s'
                                        variant='secondary'
                                        icon={`chevron-${direction}`}
                                        disabled={
                                            direction === 'left' && index === 0 ||
                                            direction === 'right' && index === maxIndex
                                        }
                                        onClick={() => handleShift(direction)}
                                    />
                                </div>
                            ))
                        }
                    </div>
                ) : null
            }
            <div
                className={cn('contentWrapper')}
                onTouchEnd={handleTouchEnd}
                onTouchMove={handleTouchMove}
                onTouchStart={handleTouchStart}
            >
                <div
                    className={cn('content', undefined, contentClassName)}
                    style={{
                        transform: `translateX(-${index * 100 / maxItems}%)`
                    }}
                >
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Slider;
