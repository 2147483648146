import React from 'react';

function imageSliderActiveItemRestoreItem<Key extends React.Key>(key: Key, el: HTMLElement) {
    // Не работает в сафари.
    el.scrollIntoView();
}

export function useImageSliderActiveItem<
    ContainerEl extends HTMLElement, ItemEl extends HTMLElement, Key extends React.Key
>({
    containerRef,
    // eslint-disable-next-line react-hooks/rules-of-hooks
    itemRefs = React.useRef(new Map<Key, ItemEl>()),
    isVertical = false,
    onActiveItem,
    restoreItemKey,
    restoreItem = imageSliderActiveItemRestoreItem
}: {
    restoreItem?: (key: Key, el: ItemEl) => void;
    restoreItemKey?: Key;
    onActiveItem?: (key: Key, el: ItemEl) => void;
    isVertical?: boolean;
    containerRef: React.RefObject<ContainerEl>;
    itemRefs?: React.RefObject<Map<Key, ItemEl>>;
}) {
    const savedRef = React.useRef<Key>();

    const onScroll = React.useCallback((e: React.UIEvent) => {
        if (! onActiveItem) return;

        const parentRect = containerRef.current?.getBoundingClientRect();

        if (! parentRect) return;

        const parentFirst = isVertical ? parentRect.top : parentRect.left;
        const parentLast = isVertical ? parentRect.bottom : parentRect.right;
        const parentCenter = Math.round(parentFirst + (parentLast - parentFirst) / 2);

        let targetKey: Key | undefined;
        let targetChild: ItemEl | undefined;

        itemRefs.current?.forEach((child, key) => {
            const childRect = child?.getBoundingClientRect();

            if (! childRect) return;

            const childFirst = isVertical ? childRect.top : childRect.left;
            const childLast = isVertical ? childRect.bottom : childRect.right;

            if (childFirst < parentCenter && childLast > parentCenter) {
                targetKey = key;
                targetChild = child;
                return;
            }
        });

        if (! targetKey || ! targetChild) return;
        if (savedRef.current === targetKey) return;
        savedRef.current = targetKey;

        // console.log('onActiveItem:save', {targetKey, targetChild });
        e.preventDefault();
        onActiveItem(targetKey, targetChild);
    }, [ containerRef, itemRefs, isVertical ]);

    React.useEffect(() => {
        itemRefs.current?.forEach((child, key) => {
            if (key !== restoreItemKey) return;
            // console.log('onActiveItem:restore', {key, child});
            restoreItem(key, child);
        });
    }, [ itemRefs, restoreItem, restoreItemKey ]);

    return {
        onScroll
    };
}
