/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import {
    LiftOptions,
    InfrastructureOptions,
    IsApartmentsOptions,
    PhotoOptions,
    BalconyOptions,
    ParkingType,
    WallsType,
    PrepaymentType,
    CommissionType,
    DepositType,
    LivingTenantOption,
    FurnitureOption,
    LivingFacilitiesOption,
    AreaUnit,
    FloorConstraints,
    CeilingHeight,
    Bathroom,
    TransportType,
    TimeLimit,
    GasOptions,
    WaterOptions,
    ElectricityOptions,
    SewerageOptions,
    LandStatus,
    HouseCategory,
    BathroomLocationOptions,
    HeatingOptions,
    BankPledgeOptions,
    LayoutImageOptions,
    NearbyOptions,
    RenovationProgram,
    OnlineView,
    BuildEpoch,
    FinishingTypeOption
} from '@search/filter-enums/enums';
import { WindowsDirectionSearchOffersEnum } from '@search/graphql-typings';
import {
    Filters,
    DealTypeFilter,
    CategoryTypeFilter,
    AreaFilter,
    RoomFilter,
    PriceFilter,
    MortgagePaymentFilter,
    LivingAreaFilter,
    KitchenAreaFilter,
    YearBuildingFilter,
    FloorFilter,
    CellHeightFilter,
    RenovationFilter,
    BathroomFilter,
    NewBuildingFilter,
    RegionFilter,
    GeoFilter,
    NewBuildingDataFilter,
    FloorsTotalFilter,
    LivingFacilityFilter,
    FurnitureFilter,
    LivingTenantOptionFilter,
    RentConditionsFilter,
    RentPrepaymentFilter,
    WallsTypeFilter,
    ParkingFilter,
    BalconyFilter,
    PhotoOptionsFilter,
    ApartmentFilter,
    InfrastructureFilter,
    AuthorFilter,
    SellTypeFilter,
    TransportLimitFilter,
    RoomsInOfferFilter,
    LandAreaFilter,
    LandStatusFilter,
    SupplyOptionsFilter,
    HouseCategoryFilter,
    HouseAreaFilter,
    HeatingFilter,
    BathroomLocationFilter,
    MapBoundsFilter,
    BankPledgeOptionsFilter,
    LayoutImageOptionsFilter,
    WindowsDirectionFilter,
    SeoMortgageAllowedFilter,
    RenovationProgramFilter,
    NearbySchoolFilter,
    SellerOrganizationNameFilter,
    BuildEpochFilter,
    OnlineViewFilter,
    FinishingFilter,
    ClCommisioninFilter,
    CommissioningFilter
} from '../../models/Filter';

export const filterSerializers: Record<Filters, Function> = {
    [Filters.heating]: (filter: HeatingFilter) => {
        return filter?.heating?.map(item => HeatingOptions[item]);
    },
    [Filters.bathroomLocation]: (filter: BathroomLocationFilter) => {
        return filter?.locations?.map(item => BathroomLocationOptions[item]);
    },
    [Filters.houseArea]: (filter: HouseAreaFilter) => {
        if (! filter) {
            return null;
        }
        const value = filter.value;

        return {
            houseAreaMin: value.from,
            houseAreaMax: value.to
        };
    },
    [Filters.houseCategory]: (filter: HouseCategoryFilter) => {
        return filter?.categories?.map(item => HouseCategory[item]);
    },
    [Filters.landStatus]: (filter: LandStatusFilter) => {
        if (! filter) {
            return null;
        }

        return filter.landStatuses.map(item => LandStatus[item]);
    },

    [Filters.supplyOptions]: (filter: SupplyOptionsFilter) => {
        if (! filter) {
            return null;
        }
        return {
            gas: filter.gas.map(item => GasOptions[item]),
            water: filter.water.map(item => WaterOptions[item]),
            electricity: filter.electricity.map(item => ElectricityOptions[item]),
            sewerage: filter.sewerage.map(item => SewerageOptions[item])
        };
    },
    [Filters.landArea]: (filter: LandAreaFilter) => {
        if (! filter) {
            return null;
        }
        const value = filter.value;

        return {
            landAreaMin: value.from,
            landAreaMax: value.to
        };
    },
    [Filters.dealType]: (filter: DealTypeFilter) => {
        return filter && filter.dealType;
    },
    [Filters.transportLimit]: (filter: TransportLimitFilter) => {
        if (filter) {
            return {
                transport: TransportType[filter.transportType!],
                transportTimeLimit: TimeLimit[filter.timeLimit!]
            };
        }

        return null;
    },
    [Filters.categoryType]: (filter: CategoryTypeFilter) => {
        return filter.categoryType;
    },
    [Filters.totalArea]: (filter: AreaFilter) => {
        if (! filter) {
            return null;
        }
        const value = filter.value;

        return {
            totalAreaMin: value.from,
            totalAreaMax: value.to
        };
    },
    [Filters.rooms]: (filter: RoomFilter) => {
        return filter && filter.rooms.map(room => {
            return room;
        });
    },
    [Filters.roomsInOffer]: (filter: RoomsInOfferFilter) => {
        if (! filter) {
            return [];
        }
        return filter.roomsInOffer.map(room => {
            return room;
        });
    },
    [Filters.region]: (filter: RegionFilter) => {
        return filter.region;
    },
    [Filters.price]: (filter: PriceFilter) => {
        const { from, to } = filter.value;
        let priceUnit: string;

        switch (filter.priceType) {
            case AreaUnit.UNKNOWN:
                priceUnit = 'ALL';
                break;
            case AreaUnit.ARE:
                priceUnit = 'ARE';
                break;
            case AreaUnit.HECTARE:
                priceUnit = 'HECTARE';
                break;
            case AreaUnit.METER:
                priceUnit = 'METER';
                break;
            case AreaUnit.MORTGAGE_PAYMENT:
                priceUnit = 'MORTGAGE_PAYMENT';
                break;
            default:
                priceUnit = 'UNKNOWN';
        }

        return {
            priceMin: from,
            priceMax: to,
            priceUnit
        };
    },
    [Filters.mortgagePayment]: (filter: MortgagePaymentFilter) => filter?.payment,
    [Filters.livingArea]: (filter: LivingAreaFilter) => {
        if (! filter) {
            return null;
        }
        const { from, to } = filter.value;

        return {
            livingAreaMin: from,
            livingAreaMax: to
        };
    },
    [Filters.kitchenArea]: (filter: KitchenAreaFilter) => {
        if (! filter) {
            return null;
        }
        const { from, to } = filter.value;

        return {
            kitchenAreaMin: from,
            kitchenAreaMax: to
        };
    },
    [Filters.builtYear]: (filter: YearBuildingFilter) => {
        if (! filter) {
            return null;
        }
        const { from, to } = filter.value;

        return {
            builtYearMin: from,
            builtYearMax: to
        };
    },
    [Filters.floor]: (filter: FloorFilter) => {
        if (! filter) {
            return null;
        }

        const { from, to } = filter.range;
        const last = filter.last === FloorConstraints.ONLY ? 'ONLY' :
            filter.last === FloorConstraints.EXCLUDE ? 'EXCLUDE' : null;
        const first = filter.first === FloorConstraints.ONLY ? 'ONLY' :
            filter.first === FloorConstraints.EXCLUDE ? 'EXCLUDE' : null;

        return {
            floorMin: from,
            floorMax: to,
            last,
            first
        };
    },
    [Filters.floorsTotal]: (filter: FloorsTotalFilter) => {
        if (! filter) {
            return null;
        }
        const { from, to } = filter.range;

        return {
            floorsTotalMin: from,
            floorsTotalMax: to
        };
    },
    [Filters.cellHeight]: (filter: CellHeightFilter) => {
        if (! filter) {
            return null;
        }

        switch (filter.value) {
            case CeilingHeight.FROM_2_5:
                return 'FROM_2_5';
            case CeilingHeight.FROM_2_7:
                return 'FROM_2_7';
            case CeilingHeight.FROM_3:
                return 'FROM_3';

            case CeilingHeight.FROM_4:
                return 'FROM_4';
            default: return null;
        }
    },
    [Filters.renovation]: (filter: RenovationFilter) => {
        return filter && filter.value;
    },
    [Filters.newBuilding]: (filter: NewBuildingFilter) => {
        if (! filter) {
            return null;
        }
        return filter.newBuilding;
    },
    [Filters.bathroom]: (filter: BathroomFilter) => {
        return filter && filter.value.map(value => {
            switch (value) {
                case Bathroom.COMBINED:
                    return 'COMBINED';
                case Bathroom.PARTED:
                    return 'PARTED';
                case Bathroom.SEVERAL:
                    return 'SEVERAL';
                default:
                    return null;
            }
        });
    },
    [Filters.geo]: (filter: GeoFilter) => ({
        metroIds: filter.metroIds.slice(),
        districtIds: filter.districtIds.slice(),
        addresses: filter.addressIds.slice(),
        newBuildingIds: filter.newBuildingIds.slice()
    }),
    [Filters.newBuildingData]: (filter: NewBuildingDataFilter) => ({
        newBuildingType: filter?.newBuilding?.type,
        newBuildingName: filter?.newBuilding?.name
    }),
    [Filters.livingFacility]: (filter: LivingFacilityFilter) => {
        if (! filter) {
            return [];
        }
        return filter.facilities.map(facility => {
            return LivingFacilitiesOption[facility];
        });
    },
    [Filters.furniture]: (filter: FurnitureFilter) => {
        if (! filter) {
            return [];
        }

        return filter.furnitures.map(furniture => {
            return FurnitureOption[furniture];
        });
    },
    [Filters.finishingType]: (filter: FinishingFilter) => {
        if (! filter) {
            return [];
        }

        return filter.finishingType.map(type => {
            return FinishingTypeOption[type];
        });
    },
    [Filters.livingTenantOptions]: (filter: LivingTenantOptionFilter) => {
        if (! filter) {
            return [];
        }

        return filter.livingTenantOptions.map(option => {
            return LivingTenantOption[option];
        });
    },
    [Filters.rentConditions]: (filter: RentConditionsFilter) => {
        if (! filter) {
            return;
        }

        return {
            utilitiesIncluded: filter.utilitiesIncluded || undefined,
            commisionType: filter.commisionType === CommissionType.UNKNOWN ? undefined : CommissionType[filter.commisionType],
            depositType: filter.depositType === DepositType.UNKNOWN ? undefined : DepositType[filter.depositType]
        };
    },
    [Filters.rentPrepayment]: (filter: RentPrepaymentFilter) => {
        if (! filter) {
            return;
        }

        return PrepaymentType[filter.prePaymentType];
    },
    [Filters.walls]: (filter: WallsTypeFilter) => {
        if (! filter) {
            return [];
        }

        return filter.walls.map(wall => {
            return WallsType[wall];
        });
    },
    [Filters.parkings]: (filter: ParkingFilter) => {
        if (! filter) {
            return [];
        }

        return filter.parkings.map(parking => {
            return ParkingType[parking];
        });
    },
    [Filters.author]: (filter: AuthorFilter) => {
        if (! filter) {
            return [];
        }

        return filter.author;
    },
    [Filters.sellType]: (filter: SellTypeFilter) => {
        if (! filter) {
            return [];
        }

        return filter.sellType;
    },
    [Filters.balcony]: (filter: BalconyFilter) => {
        if (! filter) {
            return;
        }

        return BalconyOptions[filter.hasBalcony];
    },
    [Filters.photoOptions]: (filter: PhotoOptionsFilter) => {
        if (! filter) {
            return;
        }

        return PhotoOptions[filter.photoOption];
    },
    [Filters.renovationProgram]: (filter: RenovationProgramFilter) => {
        if (! filter) return;

        return RenovationProgram[filter.willRenovation];
    },
    [Filters.nearbyOptions]: (filter: NearbySchoolFilter) => {
        if (! filter) return;

        return NearbyOptions[filter.hasNearbySchool];
    },
    [Filters.layoutImageOptions]: (filter: LayoutImageOptionsFilter) => {
        if (! filter) {
            return;
        }

        return filter.layoutImageOption.map(option => {
            return LayoutImageOptions[option];
        });
    },
    [Filters.seoMortgageAllowed]: (filter: SeoMortgageAllowedFilter) => {
        if (! filter) {
            return;
        }

        return filter.isAllowed;
    },
    [Filters.bankPledgeOptions]: (filter: BankPledgeOptionsFilter) => {
        if (! filter) {
            return;
        }

        return BankPledgeOptions[filter.bankPledgeOption];
    },
    [Filters.apartments]: (filter: ApartmentFilter) => {
        if (! filter) {
            return;
        }

        if (filter.isApartment === IsApartmentsOptions.UNKNOWN) {
            return;
        }

        return IsApartmentsOptions[filter.isApartment];
    },
    [Filters.infrastructure]: (filter: InfrastructureFilter) => {
        if (! filter) {
            return;
        }

        return {
            hasLift: LiftOptions[filter.hasLift] || undefined,
            infrastructure: filter.infrastructure.map(infra => {
                return InfrastructureOptions[infra];
            })
        };
    },
    [Filters.windowsDirection]: (filter: WindowsDirectionFilter) => {
        return filter && filter.value.map(value => {
            switch (value) {
                case WindowsDirectionSearchOffersEnum.Both:
                    return 'BOTH';
                case WindowsDirectionSearchOffersEnum.Front:
                    return 'FRONT';
                case WindowsDirectionSearchOffersEnum.Rear:
                    return 'REAR';
                default:
                    return null;
            }
        });
    },
    [Filters.sellerOrganizationName]: (filter: SellerOrganizationNameFilter) => {
        if (! filter) return null;

        return filter.sellerOrganizationName;
    },
    [Filters.mapBounds]: (filter: MapBoundsFilter) => {
        if (! filter) {
            return;
        }

        return {
            mapBounds: {
                bounds: filter.bounds,
                polygon: filter.polygon,
                geohash: filter.geohash
            }
        };
    },
    [Filters.buildEpoch]: (filter: BuildEpochFilter) => {
        if (! filter) return [];

        return filter.buildEpoch.map(epoch => {
            return BuildEpoch[epoch];
        });
    },
    [Filters.onlineView]: (filter: OnlineViewFilter) => {
        if (! filter) return;

        return OnlineView[filter.onlineView];
    },
    [Filters.commissioning]: (filter: CommissioningFilter) => {
        if (! filter) return;

        return filter.commissioning;
    }
};
