import React, { useCallback, ReactNode } from 'react';
import Grid from '@vtblife/uikit-grid';

import { SizeEnum, ModeEnum, Maybe } from '@search/vtbeco-ui/src/types';

import { ICheckboxProps } from '@search/vtbeco-ui/src/components/controls/Checkbox';
import { ICheckboxGroupProps } from '@search/vtbeco-ui/src/components/controls/CheckboxGroup';

export interface ICheckboxGroupModificationProps {
    withoutGrid?: boolean;
    classNameGroup?: string;
}

export const CheckboxGroup2: React.FunctionComponent<
    ICheckboxGroupProps & { colRestriction?: number; isFilter?: boolean } & ICheckboxGroupModificationProps
> = ({
    name,
    value,
    children,
    disabled,
    onChange,
    mode = ModeEnum.CHECK,
    size = SizeEnum.S,
    colRestriction,
    withoutGrid,
    classNameGroup,
    isFilter = false
}) => {
    const handleChildCheck = useCallback((
        checked: boolean,
        childProps: { name: Maybe<string>; value: Maybe<string | number | readonly string[]>}
    ) => {
        let newValue: string | null | string[] = [];
        const childValue: string = childProps.value as string;

        switch (true) {
            case (checked && ModeEnum.CHECK === mode):
                // @ts-ignore
                newValue = [].concat(value).filter(a => a === 0 || Boolean(a)).concat(childValue);
                break;
            case (checked && ModeEnum.RADIO_CHECK === mode):
            case (checked && ModeEnum.RADIO === mode):
                newValue = childValue;
                break;
            case (! checked && ModeEnum.RADIO === mode):
                newValue = value;
                break;
            case (! checked && ModeEnum.RADIO_CHECK === mode):
                newValue = null;
                break;
            case (! checked && ModeEnum.CHECK === mode):
                // @ts-ignore
                newValue = [].concat(value).filter(item => item !== childValue);
                break;
            default:
                newValue = [];
        }

        onChange(newValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ onChange, disabled, value ]);

    const handleChildCheckFilter = useCallback((childValue?: string) => (checked: boolean) => {
        if (! childValue) {
            return;
        }

        let newValue: string | null | string[] = [];

        switch (true) {
            case (checked && ModeEnum.CHECK === mode):
                const filteredValue = [ ...value ].filter(a => a !== childValue);

                newValue = [ ...filteredValue, childValue ];
                break;
            case (checked && ModeEnum.RADIO_CHECK === mode):
            case (checked && ModeEnum.RADIO === mode):
                newValue = [ childValue ];
                break;
            case (! checked && ModeEnum.RADIO === mode):
                newValue = value;
                break;
            case (! checked && ModeEnum.RADIO_CHECK === mode):
            case (! checked && ModeEnum.CHECK === mode):
                newValue = [ ...value ].filter(a => a !== childValue);
                break;
            default:
                newValue = [];
        }

        onChange(newValue);
    }, [ onChange, disabled, value ]);

    const renderChildren = (<>
        {
            // @ts-ignore
            React.Children.map(children, (child: React.ReactElement<ICheckboxProps>): ReactNode => {
                const checkedValue = isFilter ? child.props.name : child.props.value as string;

                // eslint-disable-next-line
                const checked = value && checkedValue !== undefined ? value.includes(checkedValue) : false;

                const elemProps = isFilter ? {
                    ...child.props,
                    value: checked,
                    disabled: disabled ?? child.props.disabled,
                    onChange: handleChildCheckFilter(child.props.name),
                    radio: mode === ModeEnum.RADIO
                } : {
                    ...child.props,
                    name: `${name ?? ''}-${String(child.props.value ?? '')}`,
                    checked,
                    disabled: disabled ?? child.props.disabled,
                    size: size ?? child.props.size,
                    onCheck: handleChildCheck
                };
                const elem = (
                    React.cloneElement(child, elemProps as ICheckboxProps)
                );

                return (
                    withoutGrid ? <>{elem}</> : <Grid.Cell>{elem}</Grid.Cell>
                );
            })
        }
    </>);

    if (! children?.length) {
        return null;
    }

    return (
        withoutGrid ? (
            <div className={classNameGroup}>
                {renderChildren}
            </div>
        ) : (
            <Grid
                cols={colRestriction ?? children?.length}
                variables={{
                    '--row-gap-xs': '20px',
                    '--row-gap-s': '20px',
                    '--row-gap-m': '20px',
                    '--row-gap-l': '20px',
                    '--row-gap-xl': '20px',
                    '--column-gap-xs': '20px',
                    '--column-gap-s': '20px',
                    '--column-gap-m': '20px',
                    '--column-gap-l': '20px',
                    '--column-gap-xl': '20px'
                }}
            >
                {renderChildren}
            </Grid>
        )
    );
};
