import React, { useCallback } from 'react';
import { quarters } from '@search/helpers/src/quarters';
import { Commissioning, Quarter } from '@search/filter-enums/enums';

import { CommissioningFilter } from '../../../../models/Filter';
import { SlimSelect } from '../../../../../common/components/SlimSelect/SlimSelect';

const defaultOptions = [
    {
        value: Commissioning.UNKNOWN,
        label: 'Любой'
    },
    {
        value: Commissioning.COMMISSIONED,
        label: 'Сдан'
    },
    {
        value: Commissioning.IN_PROGRESS,
        label: 'Строится'
    },
    ...quarters()
        .map(({ quarter, year }) => ({
            value: `${Quarter[quarter]}_${year}`,
            label: `До ${quarter} кв. ${year}`
        }))
];

interface IDesktopCommissioningFilterViewProps {
    filter: CommissioningFilter;
    onChange: (filter: CommissioningFilter) => void;
    options?: { value: string; label: string }[];
}

export const DesktopCommissioningFilterView: React.FC<IDesktopCommissioningFilterViewProps> = ({
    filter,
    onChange,
    options = defaultOptions
}) => {
    let { commissioning } = filter;

    const handleChange = useCallback(
        (val: string) => {
            onChange(new CommissioningFilter(val));
        },
        [ onChange ]
    );

    if (! options.some(({ value }) => value === commissioning)) {
        commissioning = Commissioning.UNKNOWN;
    }

    return (
        <SlimSelect
            forcePlaceholder={commissioning === Commissioning.UNKNOWN}
            placeholder='Срок сдачи'
            size='s'
            options={options}
            value={commissioning}
            onChange={handleChange}
            height={400}
            dataTest='commissioning'
            adaptivePlacement={false}
            adaptiveHeight={false}
        />
    );
};
