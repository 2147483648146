import React from 'react';
import classname from '@search/classname/src';
import './styles.css';
import { ImageLazySeo } from '../../../../../../../domain/image/lazy/ImageLazySeo';
import { IThumbnail } from '../index';

const cn = classname.bind(null, 'ThumbnailSnippet');

interface IProps extends IThumbnail {
    src?: string;
    baseUrl?: string;
    originPath?: string;
    handleSetActiveImageIndex?: () => void;
    isLast?: boolean;
    restCount: number;
    isFrozen?: boolean;
    withLazyLoad?: boolean;
}

const ThumbnailSnippet: React.FC<IProps> = ({
    src,
    originPath,
    handleSetActiveImageIndex = () => { },
    isLast = false,
    restCount,
    isFrozen,
    withLazyLoad,
    description
}) => (
    <div
        className={cn()}
        onMouseEnter={handleSetActiveImageIndex}
    >
        <ImageLazySeo
            // В хроме будет нативный lazy, который нормально предзагружается
            // В сафари - будет фолбэк до жадной загрузки
            // withLazyLoad - true только у первого снипета
            itemProp='thumbnail'
            loadingLazy={withLazyLoad ? 'native' : undefined}
            className={cn('item', { frozen: isFrozen })}
            src={src}
            srcWidth={90}
            srcHeight={60}
            originPath={originPath}
            alt={description}
            title={description}
        />
        {isLast && (restCount > 0) && (
            <div className={cn('last')}>
                +{restCount}
            </div>
        )}
    </div>
);

export default ThumbnailSnippet;
