import { GeoKind } from '@search/filter-enums/enums/GeoKind';
import { GeoObject2 } from '../GeoObject2';
import { MetroRoute } from '../MetroRoute';

export function geoProtoRoute(geo: GeoObject2) {
    if (geo.kind !== GeoKind.ROUTE) {
        throw new Error(
            `Not an route id: ${geo.id}, kind: ${geo.kind}`
        );
    }

    const id = geo.id;

    const title = geo.mainName ?? geo.displayName;

    const body = geo.body;
    const color = body?.route?.colorHex ?? 'pink';

    const route: MetroRoute = {
        kind: GeoKind.ROUTE,
        id,
        title,
        shortDisplayName: geo.shortDisplayName,
        color
    };

    return route;
}
