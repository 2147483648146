import React, { AllHTMLAttributes, useMemo } from 'react';

import cn from '@search/classname/src';

import './styles.css';

export type Level =
    'promo-h1' |
    'promo-h1-mobile' |

    'promo-h2' |
    'promo-h2-mobile' |

    'promo-h3' |
    'promo-h3-mobile' |

    'promo-h4' |
    'promo-h4-mobile' |

    'promo-h5' |

    'promo-subheader' |
    'promo-subheader-alone' |
    'promo-subheader-mobile' |

    'promo-primary' |
    'promo-primary-alone' |
    'promo-primary-mobile' |

    'h1' |
    'h2' |
    'h3' |
    'h4' |

    'primary-alone' |
    'primary' |

    'secondary-alone' |
    'secondary' |

    'small-alone' |
    'small' |

    'tiny-alone' |
    'tiny';

type UIKitColor = 'black-50' | 'black-75' | 'black-100' | 'black-200' | 'black-250' | 'black-300' | 'black-350' |
    'black-400' | 'black-450' | 'black-500' | 'black-50-alpha' | 'black-100-alpha' | 'black-200-alpha' |
    'black-300-alpha' | 'black-400-alpha' | 'black-500-alpha' | 'white-0' | 'white-500' | 'white-100-alpha' |
    'white-200-alpha' | 'white-300-alpha' | 'white-400-alpha' | 'white-500-alpha' | 'blue-50' | 'blue-100' |
    'blue-200' | 'blue-300' | 'blue-400' | 'blue-500' | 'blue-600' | 'blue-700' | 'orange-300' | 'orange-500' |
    'orange-600' | 'orange-700' | 'purple-300' | 'purple-500' | 'purple-600' | 'purple-700' | 'turquoise-300' |
    'turquoise-500' | 'turquoise-600' | 'turquoise-700' | 'yellow-300' | 'yellow-500' | 'yellow-600' | 'yellow-700' |
    'green-300' | 'green-500' | 'green-600' | 'green-700' | 'red-300' | 'red-500' | 'red-600' | 'red-700' | 'primary' |
    'secondary' | 'minor' | 'link' | 'link-minor' | 'disabled' | 'disabled-transparent' |
    'disabled-transparent-alt-bg' | 'primary-alt-bg' | 'secondary-alt-bg' | 'link-alt-bg' | 'error';

export type Color = UIKitColor;

export const cnCustomTypography = cn.bind(null, 'CustomTypography');

export interface ICustomTypographyProps extends AllHTMLAttributes<HTMLElement | HTMLLinkElement> {
    /**
     * Тип текста
     * @default 'primary'
     */
    level?: Level;

    /**
     * Конкретный тэг HTML
     **/
    tag?: React.ElementType;

    /**
     * Цвет текста
     * @default --c-text-primary (задаётся глобально, находится в vtblife/uikit-2/dist/css/global.css)
     **/
    color?: Color;

    /**
     * Жирный ли текст
     **/
    bold?: boolean;

    /**
     * перечеркнутый ли текст
     **/
    lineThrough?: boolean;

    /**
     * Запрещено ли переносить текст
     * @default 'false'
     **/
    noWrap?: boolean;

    /**
     * Выравнивание текста
     **/
    align?: 'left' | 'center' | 'right' | 'justify';
}

const CustomTypography: React.FC<ICustomTypographyProps> = React.forwardRef<HTMLElement, ICustomTypographyProps>((
    props,
    ref
) => {
    const {
        level = 'primary',
        tag,
        color,
        bold,
        lineThrough = false,
        noWrap = false,
        align,
        className: classNameProp,
        ...rest
    } = props;

    const Component = tag ||
        [ 'h1', 'h2', 'h3', 'h4' ].find(t => level.includes(t)) ||
        'div';

    const className = useMemo(() => cnCustomTypography(null, {
        level,
        color,
        bold,
        lineThrough,
        noWrap,
        align
    }, classNameProp), [
        level,
        color,
        bold,
        lineThrough,
        noWrap,
        align,
        classNameProp
    ]);

    return (
        <Component
            {...rest}
            ref={ref}
            className={className}
        />
    );
});

export default CustomTypography;
