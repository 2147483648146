/* eslint-disable @typescript-eslint/naming-convention */
import { RealtyObjectType } from '@search/filter-enums/enums/RealtyObjectType';
import { DealType } from '@search/filter-enums/enums/DealType';
import { RegionIdEnum } from '@search/filter-enums/enums/Region';

type MatchParamsBase = {
    dealType: DealType;
    categoryType: RealtyObjectType;
    priceMax?: number | null;
    priceMin?: number | null;
};

type MatchParams = MatchParamsBase & { region: number; regionTranslit?: string | null; seg?: string | null };

export class PriceSeoMatcher {
    static $ = new PriceSeoMatcher();
    protected map(): Record<string, readonly (MatchParamsBase & { region: (number | RegExp)[] })[]> {
        const msk = [ RegionIdEnum.MSK, RegionIdEnum.MSK_AND_MSK_OBL, RegionIdEnum.MSK_OBL ];
        const spb = [ RegionIdEnum.SPB, RegionIdEnum.SPB_AND_LEN_OBL, RegionIdEnum.LEN_OBL ];
        const province = [] as number[];

        return {
            deshevye: [
                { region: msk, dealType: DealType.SELL, categoryType: RealtyObjectType.FLAT, priceMax: 8500000 },
                { region: spb, dealType: DealType.SELL, categoryType: RealtyObjectType.FLAT, priceMax: 5500000 },
                { region: province, dealType: DealType.SELL, categoryType: RealtyObjectType.FLAT, priceMax: 3500000 },
            ],
            nedorogo: [
                { region: msk, dealType: DealType.RENT, categoryType: RealtyObjectType.FLAT, priceMax: 25500 },
                { region: spb, dealType: DealType.RENT, categoryType: RealtyObjectType.FLAT, priceMax: 25500 },
                { region: province, dealType: DealType.RENT, categoryType: RealtyObjectType.FLAT, priceMax: 18000 },
            ],
            elitnye: [
                { region: msk, dealType: DealType.SELL, categoryType: RealtyObjectType.FLAT, priceMin: 35000000 },
                { region: spb, dealType: DealType.SELL, categoryType: RealtyObjectType.FLAT, priceMin: 25000000 },
                { region: province, dealType: DealType.SELL, categoryType: RealtyObjectType.FLAT, priceMin: 10000000 },

                { region: msk, dealType: DealType.RENT, categoryType: RealtyObjectType.FLAT, priceMin: 150000 },
                { region: spb, dealType: DealType.RENT, categoryType: RealtyObjectType.FLAT, priceMin: 100000 },
                { region: province, dealType: DealType.RENT, categoryType: RealtyObjectType.FLAT, priceMin: 80000 },
            ],
            dachu: [
                { region: msk, dealType: DealType.SELL, categoryType: RealtyObjectType.HOUSE, priceMax: 6500000 },
                { region: spb, dealType: DealType.SELL, categoryType: RealtyObjectType.HOUSE, priceMax: 6500000 },
                { region: province, dealType: DealType.SELL, categoryType: RealtyObjectType.HOUSE, priceMax: 5200000 },

                { region: msk, dealType: DealType.RENT, categoryType: RealtyObjectType.HOUSE, priceMax: 100000 },
                { region: spb, dealType: DealType.RENT, categoryType: RealtyObjectType.HOUSE, priceMax: 100000 },
            ],
            'v-derevne': [
                {
                    region: [ RegionIdEnum.MSK_OBL, RegionIdEnum.LEN_OBL ],
                    dealType: DealType.SELL,
                    categoryType: RealtyObjectType.HOUSE,
                    priceMax: 7500000
                },
                {
                    region: [ /\-oblast$/ ],
                    dealType: DealType.SELL,
                    categoryType: RealtyObjectType.HOUSE,
                    priceMax: 5500000
                },
                {
                    region: [ RegionIdEnum.MSK_OBL, RegionIdEnum.LEN_OBL ],
                    dealType: DealType.SELL,
                    categoryType: RealtyObjectType.LAND,
                    priceMax: 7500000
                },
                {
                    region: [ /\-oblast$/ ],
                    dealType: DealType.SELL,
                    categoryType: RealtyObjectType.LAND,
                    priceMax: 5500000
                },
            ],
        };
    }

    protected mapCached = Object.entries(this.map());

    readonly chpuKeys = Object.keys(this.map());

    matchAll(p: MatchParams, _isFirstMatch = false) {
        const result = [] as { seg: string; params: MatchParamsBase }[];

        for (const [ seg, variants ] of this.mapCached) {
            if (p.seg && seg !== p.seg) continue;

            let isSegAlreadyPushed = false;

            for (const params of variants) {
                if (isSegAlreadyPushed) continue;
                if (p.categoryType !== params.categoryType) continue;
                if (p.dealType !== params.dealType) continue;
                if (p.priceMax && p.priceMax !== params.priceMax) continue;
                if (p.priceMin && p.priceMin !== params.priceMin) continue;
                if (p.priceMax === undefined && p.priceMin === undefined) continue;

                const matchedRegion = params.region.find(
                    reg => reg instanceof RegExp && p.regionTranslit ?
                        p.regionTranslit.match(reg) :
                        reg === p.region
                );

                if (! matchedRegion && params.region.length !== 0) continue;

                result.push({ seg, params });
                if (! isSegAlreadyPushed) isSegAlreadyPushed = true;
                if (_isFirstMatch) return result;
            }
        }

        return result;
    }

    match(p: MatchParams) {
        const result = this.matchAll(p, true);

        return result.length ? result[0] : undefined;
    }
}
