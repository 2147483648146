import { useCallback } from 'react';
import { formatPhone } from '@search/helpers/src/formatPhone';
import { graphql, useGqlContext } from '@search/gql-client/src';

import type {
    useCallbackRequestMutation$data as useCallbackRequestMutationResponse,
    useCallbackRequestMutation$variables as useCallbackRequestMutationVariables
} from './__generated__/useCallbackRequestMutation.graphql';

const mutation = graphql`
    mutation useCallbackRequestEmailMutation(
        $phone: String!
        $newBuildingName: String
        $address: String!
        $developerName: String
        $hours: String!
        $extra: String
        $subjectExtra: String
        $emailSender: EmailSenderEnum!
        $offerLink: String
        $isCallbackRequestCreated: Boolean
        $isNewBuilding: Boolean
        $newBuildingRanking: String
        $regionId: Int
    ) {
        createEmailCallback(
            phone: $phone
            newBuildingName: $newBuildingName
            address: $address
            developerName: $developerName
            hours: $hours
            extra: $extra
            subjectExtra: $subjectExtra
            emailSender: $emailSender
            link: $offerLink
            isCallbackRequestCreated: $isCallbackRequestCreated
            isNewBuilding: $isNewBuilding
            newBuildingRanking: $newBuildingRanking
            regionId: $regionId
        ) {
            emailMessageId
        }
    }
`;

export const useCallbackRequest = () => {
    const gql = useGqlContext();

    return useCallback(
        (vars: useCallbackRequestMutationVariables, opts?: { signal?: AbortSignal }) => {
            const phone = formatPhone(vars.phone);

            return gql.client<
                useCallbackRequestMutationVariables,
                useCallbackRequestMutationResponse
            >(
                mutation,
                {
                    ...vars,
                    phone
                },
                {
                    pathUniqSuffix: `/callback/${phone}/`,
                    ...opts
                }
            );
        },
        [ gql ]
    );
};
