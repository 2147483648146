import './MortgageFieldsBuilder.css';

import React from 'react';

import classname from '@search/classname/src';

const cn = classname.bind(null, 'MortgageFieldsBuilder');

export function MortgageFieldsBuilder({
    isMobile,
    gridFields,
    contactFields,
    actions,
    prefixNodes
} : {
    isMobile?: boolean;
    gridFields?: React.ReactNode;
    contactFields?: React.ReactNode;
    actions?: React.ReactNode;
    prefixNodes?: React.ReactNode;
}) {
    return (<>
        <div className={cn('grid-main', { isMobile })}>
            {prefixNodes}
            <div className={cn('grid-fields', { isMobile })}>
                {gridFields}
            </div>
            <div className={cn('grid-contacts')}>
                {contactFields}
            </div>
            <div className={cn('grid-actions')}>
                {actions}
            </div>
        </div>
        {isMobile ? <> <br /><br /><br /></> : null}
    </>);
}
