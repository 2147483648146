/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

import classname from '@search/classname/src';
import { useKeyPress } from '@search/hooks/src/useKeyPress';

import { IconClose } from '../../../../../icons/IconClose';
import { ESCAPE } from '../../../../../constants/keycode';
import { Gallery, IGallery } from '../Gallery/Gallery';
import './styles.css';

interface IFSGallery extends IGallery {
    onClose: () => void;
    belowModal?: boolean;
}

const cn = classname.bind(null, 'FSGallery');
const modalNode = typeof document === 'undefined' ? null : document.createElement('div');

export const FSGallery = ({
    onClose,
    belowModal,
    ...rest
}: IFSGallery) => {
    const esc = useKeyPress(ESCAPE);

    useEffect(() => {
        esc && onClose();
    }, [ esc ]);

    useEffect(() => {
        if (! modalNode) return;
        const base = cn('overlay');

        modalNode.classList?.add(base);

        if (belowModal) modalNode.classList?.add(`${base}_belowModal`);
        document.body.appendChild(modalNode);
        const classes = cn(null, { open: true }).split(' ')[1];

        document.body.classList.add(classes);

        return () => {
            document.body.removeChild(modalNode);
            document.body.classList.remove('FSGallery_open');
            modalNode.classList?.remove(base);
            if (belowModal) modalNode.classList?.remove(`${base}_belowModal`);
        };
    }, [ belowModal ]);

    return ReactDOM.createPortal(
        <div className={cn('inner')}>
            <Gallery {...rest} />

            <div className={cn('icon-close')} onClick={onClose}
                data-test='gallery-icon-close'>
                <IconClose />
            </div>
        </div>,
        // @ts-ignore
        modalNode
    );
};
