import React, { Ref } from 'react';

import { Button } from '@vtblife/uikit';

import classname from '@search/classname/src';

import type { ICustomerFeedbackOpenButton } from '../../../../../common/components/CustomerFeedback/components/OpenButton';

import './styles.css';

const cn = classname.bind(null, 'CustomerFeedbackOpenButtonBlue');

export const OpenButtonBlue = React.forwardRef(({
    handleCloseOpenBtn,
    handleOpen
}: Pick<ICustomerFeedbackOpenButton, 'handleCloseOpenBtn' | 'handleOpen'>, ref: Ref<HTMLDivElement>) => (
    <div
        ref={ref}
        className={cn()}
    >
        <Button
            size='s'
            rounded='left'
            onClick={handleOpen}
            dataTest='customer-feedback-open-btn'
        >
            Отзыв о сайте
        </Button>
        <Button
            size='s'
            icon='times'
            rounded='right'
            onClick={handleCloseOpenBtn}
            dataTest='customer-feedback-close-btn'
        />
    </div>
));
