/* eslint-disable @typescript-eslint/naming-convention */
import React, { Fragment, useState, useCallback, useRef, RefObject, useEffect } from 'react';
import classname from '@search/classname/src';
import { useOnClickOutside } from '@search/hooks/src/useOnClickOutside';
import Popup, { IPopupProps } from '../Popup';
import InlinePopup from '../InlinePopup';
import { SizeEnum, ToneEnum, ViewEnum } from '../../../types';

export type TriggerElement = HTMLDivElement | HTMLButtonElement | HTMLLinkElement;

import './styles.css';

const cnTooltip = classname.bind(null, 'Tooltip');

interface ITooltipHandlers {
    isVisible: boolean;
    handleShow: () => void;
    handleClose: () => void;
    handleToggle: () => void;
    triggerRef: RefObject<TriggerElement>;
}

export interface ITooltipProps extends Omit<IPopupProps, 'anchor'> {
    /**
     * Видимость tooltip
     */
    visible?: boolean;

    /**
     * Рендер контента
     * @param opts
     */
    renderContent: (opts: {handleClose(): void}) => React.ReactNode;

    /**
     * Рендер триггера
     * @param opts
     */
    renderTrigger: (opts: ITooltipHandlers) => React.ReactNode;

    /**
     * коллбэк закрытия тултипа
     */
    onHide?: () => void;

    /**
     * Инлайновый тултип
     */
    inline?: boolean;
}

const Tooltip = ({
    hasTail,
    directions,
    size = SizeEnum.M,
    tone = ToneEnum.LIGHT,
    view = ViewEnum.CLEAR,
    visible = false,
    renderContent,
    renderTrigger,
    className,
    mainOffset = 4,
    onHide,
    inline = false
}: ITooltipProps) => {
    const [ currentVisible, setVisible ] = useState<boolean>(visible);
    const triggerRef = useRef<TriggerElement>(null);
    const contentRef = useRef<HTMLDivElement>();

    const handleClickOutside = useCallback(() => {
        setVisible(false);

        if (onHide) {
            onHide();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useOnClickOutside([ contentRef, triggerRef ], handleClickOutside, currentVisible);

    const handleShow = useCallback(() => {
        setVisible(true);
    }, []);

    const handleClose = useCallback(() => {
        setVisible(false);

        if (onHide) {
            onHide();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleToggle = useCallback(() => setVisible((prevState: boolean) => ! prevState), []);

    useEffect(() => {
        setVisible(visible);
    }, [ visible ]);

    const popupContent = inline ? (
        <InlinePopup
            mainOffset={mainOffset}
            size={size}
            tone={tone}
            view={view}
            innerRef={contentRef}
            hasTail={hasTail}
            anchor={triggerRef}
            className={cnTooltip(null, { size, view, tone }, className)}
            direction={directions ? directions[0] : undefined}
            visible={currentVisible}
        >
            <div className={cnTooltip('content')}>
                {renderContent({ handleClose })}
            </div>
        </InlinePopup>
    ) : (
        <Popup
            mainOffset={mainOffset}
            size={size}
            tone={tone}
            view={view}
            innerRef={contentRef}
            hasTail={hasTail}
            anchor={triggerRef}
            className={cnTooltip(null, { size, view, tone }, className)}
            directions={directions}
            visible={currentVisible}
        >
            <div className={cnTooltip('content')}>
                {renderContent({ handleClose })}
            </div>
        </Popup>
    );

    return inline ? (
        <div className={cnTooltip('wrap')}>
            {
                renderTrigger({ isVisible: currentVisible, handleShow, handleClose, handleToggle, triggerRef })
            }
            {
                currentVisible ? popupContent : null
            }
        </div>
    ) : (
        <Fragment>
            {
                renderTrigger({ isVisible: currentVisible, handleShow, handleClose, handleToggle, triggerRef })
            }
            {
                currentVisible ? popupContent : null
            }
        </Fragment>
    );
};

export default Tooltip;
