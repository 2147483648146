import { RealtyObjectType } from '@search/filter-enums/enums/RealtyObjectType';
import { SeoMapper } from '@search/router/src';

export const categoryTypeMapper = new SeoMapper('categoryTypeMapper', {
    kvartiru: RealtyObjectType.FLAT,
    komnatu: RealtyObjectType.ROOM,
    unknown: RealtyObjectType.UNKNOWN,
    uchastok: RealtyObjectType.LAND,
    dom: RealtyObjectType.HOUSE,
    usadba: RealtyObjectType.COUNTRY_ESTATE,
    1: RealtyObjectType.FLAT,
    2: RealtyObjectType.ROOM
} as const);
