import { useMemo } from 'react';

type UseGovernmentMortgage = {
    regionId?: number;
    propertyCost?: number;
    isPreferentialNbCondition?: boolean;
    ownAmount?: number;
}

export const governmentMinPercent = 15;
export type useGovernmentMortgageType = ReturnType<typeof getGovernmentMortgage>;

export function getGovernmentMortgage({
    regionId,
    propertyCost,
    isPreferentialNbCondition,
    ownAmount,
}: UseGovernmentMortgage) {
    let governmentMaxDebt = 6e6;

    // backoffice regionId: is msk, spb, msk obl, len obl
    if ((regionId && [ 3, 1, 4, 2 ].includes(regionId))) {
        governmentMaxDebt = 12e6;
    }

    if (! propertyCost || ! ownAmount) {
        return {
            isPreferentialNbCondition,
            governmentMaxDebt,
            isGovernmentDebtExceeds: true,
            isGovernmentPercentPoor: true,
            // when offer base - absent (example, banners in SERP), focus on isPreferentialNbCondition(isNewBuilding)
            showPreferential: isPreferentialNbCondition,
        };
    }

    const debt = propertyCost - ownAmount;
    const percent = propertyCost ? Math.floor(100 * ownAmount / propertyCost) : 0;
    const isGovernmentDebtExceeds = debt >= governmentMaxDebt;
    const isGovernmentPercentPoor = percent < governmentMinPercent;
    const showPreferential = Boolean(isPreferentialNbCondition && ! isGovernmentDebtExceeds && ! isGovernmentPercentPoor);

    return {
        showPreferential,
        isPreferentialNbCondition,
        isGovernmentDebtExceeds,
        isGovernmentPercentPoor,
        governmentMaxDebt
    };
}

export function useGovernmentMortgage({
    regionId,
    propertyCost,
    isPreferentialNbCondition,
    ownAmount,
}: UseGovernmentMortgage) {
    return useMemo(() => getGovernmentMortgage({
        regionId,
        propertyCost,
        isPreferentialNbCondition,
        ownAmount,
    }), [
        regionId,
        propertyCost,
        isPreferentialNbCondition,
        ownAmount
    ]);
}
