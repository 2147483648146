import React from 'react';
import { UseAuthError } from '@search/auth/src/useAuth';
import { BaseError } from '@search/error/src/BaseError';

import { graphql, useGqlContext } from '@search/gql-client/src';

import { useM2AuthContext } from '@search/auth/src/M2AuthProvider';
import {
    FavoritesMutation$data as FavoritesMutationResponse, FavoritesMutation$variables as FavoritesMutationVariables
} from './__generated__/FavoritesMutation.graphql';

const favoritesMutation = graphql`
  mutation FavoritesMutation($offerId: ID!, $isFavorite: Boolean!, $noteText: String) {
    editFavoritePersonal2(isFavorite: $isFavorite, noteText: $noteText, offerId: $offerId) {
        firstError {
            __typename
            ... on BaseError {
                message
            }
            ... on AuthUserIdError {
                message
            }
            ... on FavoritesErrorLimitExceeded {
                count
            }
            ... on FavoritesErrorNoteTextSizeExceeded {
                count
            }
            ... on FavoritesErrorNoteEmpty {
                message
            }
        }
    }
  }
`;

const stateMap = new Map<string, boolean>();

export function useFavoritesMutationSafe() {
    const { authScheduler } = useM2AuthContext();
    const favoritesMutationCb = useFavoritesMutation();

    const favoritesMutationSafe = React.useMemo(
        () => {
            const register = authScheduler.register('favoritesMutation', favoritesMutationCb);

            return {
                ...register,
                argsById(id: string) {
                    return register.args().find(arg => arg.offerId === id);
                }
            };
        },
        [ favoritesMutationCb ]
    );

    return favoritesMutationSafe;
}

export function useFavoritesMutation() {
    const gql = useGqlContext();

    return React.useCallback(async (props: FavoritesMutationVariables) => {
        const data = await gql.execute<FavoritesMutationVariables, FavoritesMutationResponse>(
            favoritesMutation,
            {
                ...props
            }
        );

        const fe = data.data?.editFavoritePersonal2.firstError;

        if (fe?.__typename === 'AuthUserIdError') throw new UseAuthError('AuthUserIdError', { cause: fe });

        if (fe?.__typename === 'FavoritesErrorLimitExceeded') {
            throw new BaseError('FavoritesErrorLimitExceeded', { cause: fe });
        }

        if (fe?.__typename === 'FavoritesErrorNoteTextSizeExceeded') {
            throw new BaseError('FavoritesErrorNoteTextSizeExceeded', { cause: fe });
        }
        if (fe?.__typename === 'FavoritesErrorNoteEmpty') throw new BaseError('FavoritesErrorNoteEmpty', { cause: fe });

        return data;
    }, [ gql ]);
}
