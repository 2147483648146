import React, { useCallback } from 'react';

import { Size } from '@vtblife/uikit/legacy';
import { AreaFilter } from '../../../../models/Filter';
import MobileFilter from '../../../filter/MobileFilter';
import '../MobileCommon.css';
import { NumberInputRange } from '../../../common/NewNumberInputRange/NumberInputRange';

interface IMobileTotalAreaFilterViewProps {
    filter: AreaFilter;
    handleChange: (filter: AreaFilter) => void;
}

export const MobileTotalAreaFilterView: React.FC<IMobileTotalAreaFilterViewProps> = ({
    filter,
    handleChange
}) => {
    const onChange = useCallback(value => {
        handleChange(new AreaFilter(value));
    }, [ handleChange ]);

    return (
        <MobileFilter
            filterTitle='Площадь'
        >
            <div className='container'>
                <div className={'col-xs-12'}>
                    <NumberInputRange
                        size={Size.Small}
                        unit='м²'
                        value={filter.value}
                        onChange={onChange}
                        max={100_000}
                        nameFrom='areaFrom'
                        nameTo='areaTo'
                    />
                </div>
            </div>
        </MobileFilter>
    );
};
