import { s } from '@search/router/src/schema';
import {
    INewBuildingSearchType,
    NewBuildingSearchParams,
    NewBuildingSearchSeoParams,
    WLNewBuildingSearchRoute
} from './NewBuildingSearchRoute';

export const NewBuildingSearchMapTileType = s.rec({
    ...NewBuildingSearchParams,

    excludeLong: s.opt(s.num),
    excludeLat: s.opt(s.num)
});

export type INewBuildingSearchMapTileType = ReturnType<typeof NewBuildingSearchMapTileType>

export const NewBuildingSearchMapTileSeoType = s.rec({
    ...NewBuildingSearchSeoParams,

    excludeLong: s.opt(s.str),
    excludeLat: s.opt(s.str)
});

export type INewBuildingSearchMapTileSeoType = ReturnType<typeof NewBuildingSearchMapTileSeoType>

export class NewBuildingSearchMapTileRoute extends WLNewBuildingSearchRoute {
    displayName = 'NewBuildingSearchMapTileRoute';

    protected pattern() {
        return `${this.patternHost()}/search/nb/tile/`;
    }

    protected fromQuery(seo: INewBuildingSearchMapTileSeoType) {
        return {
            ...super.fromQuery(seo),
            excludeLat: seo.excludeLat && parseFloat(seo.excludeLat) || undefined,
            excludeLong: seo.excludeLong && parseFloat(seo.excludeLong) || undefined
        };
    }

    protected toQuery(p: INewBuildingSearchMapTileType) {
        return {
            ...super.toQuery(p as unknown as INewBuildingSearchType),
            excludeLat: p.excludeLat && String(p.excludeLat) || undefined,
            excludeLong: p.excludeLong && String(p.excludeLong) || undefined
        };
    }
}
