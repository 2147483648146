
import React from 'react';
import classname from '@search/classname/src';

const cn = classname.bind(null, 'OfferYMapDesktopSwitchContainer');

export function OfferYMapDesktopSwitchLink({
    className,
    dark,
    children,
    active,
    icon = null,
    href,
    dataTest,
    onClick: onClickRaw
}: {
    dataTest?: string;
    dark: boolean;
    onClick?: () => void;
    href: string;
    icon?: React.ReactElement | null;
    active: boolean;
    children?: React.ReactNode;
    className?: string;
}) {
    const onClick = React.useCallback((e: React.MouseEvent<Element, MouseEvent>) => {
        e.stopPropagation();
        e.preventDefault();
        if (onClickRaw) {
            onClickRaw();
        }
    }, [ onClickRaw ]);

    return (
        <a
            data-test={dataTest}
            className={cn('link', { active, dark }, className)}
            onClick={onClick}
            href={href}
        >
            {icon ? <div className={cn('icon', { active })}>{icon}</div> : null}
            {children}
        </a>
    );
}
