import { SeoMapper } from '@search/router/src';

export const mskOblCity = {
    balashikha: 511,
    dubna: 6152,
    aprelevka: 2345,
    bronnitsy: 1581,
    vidnoe: 700,
    volokolamsk: 8342,
    voskresensk: 4299,
    vysokovsk: 8022,
    golitsyno: 7628,
    dedovsk: 4124,
    dzerzhinskiy: 8923,
    dmitrov: 3280,
    dolgoprudniy: 811,
    domodedovo: 949,
    drezna: 32255,
    egorevsk: 23338,
    zhukovskiy: 778,
    zvenigorod: 971,
    ivanteevka: 482,
    istra: 2775,
    kashira: 1220,
    klin: 4068,
    kolomna: 460996,
    korolyov: 526,
    kotelniki: 232,
    krasnoarmeysk: 3986,
    krasnogorsk: 643,
    krasnozavodsk: 15489,
    krasnoznamensk: 16975,
    kubinka: 46933,
    'likino-dulyovo': 19625,
    lobnya: 498,
    'losino-petrovskiy': 6082,
    lukhovitsy: 31429,
    lytkarino: 806,
    lyubertsy: 571,
    mozhaisk: 6417,
    mytischi: 516,
    'naro-fominsk': 7784,
    noginsk: 4901,
    odintsovo: 1324,
    ozyory: 20877,
    'orekhovo-zuevo': 9142,
    'pavlovskiy-posad': 31812,
    podolsk: 1078,
    protvino: 76951,
    pushkino: 488335,
    puschino: 8204,
    ramenskoe: 629,
    reutov: 830,
    ruza: 7512,
    'sergiev-posad': 1680,
    serpukhov: 3290,
    solnechnogorsk: 1723,
    'staraya-kupavna': 1440,
    stupino: 8488,
    taldom: 499,
    fryazino: 617,
    khimki: 544,
    khotkovo: 7576,
    chekhov: 2359,
    shatura: 8461,
    schyolkovo: 601,
    elektrogorsk: 77408,
    elektrostal: 3436,
    yakhroma: 1649
};

export const mskCity = {
    'novaya-moskva': 141530
};

export const mskCityMapper = new SeoMapper('mskCityMapper', {
    ...mskOblCity,
    ...mskCity
});

export const mskOblCityIds = Object.values(mskOblCity);
export const mskCityIds = Object.values(mskCity) as number[];
