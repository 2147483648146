import React, { RefObject, useEffect, useRef } from 'react';
import classname from '@search/classname/src';

import './styles.css';

type ImageTag = React.DetailedHTMLFactory<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;
type DivTag = React.DetailedHTMLFactory<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

/**
 * Параметры для картинки.
 */
export interface IImage {
    url?: string;
    alt?: string;
    title?: string;
    width?: number;
    height?: number;
    placeholder?: boolean | string;
    type?: 'cover' | 'contain' | 'crop' | 'videoCover';
    className?: string;
    loading?: 'lazy' | 'eager';
    ref?: RefObject<ImageTag | DivTag>;
    onLoad?: () => void;
    onLoadVerticalImage?: (value: boolean) => void;
    onError?: () => void;
    itemProp?: string;
    style?: React.CSSProperties;
}

const cn = classname.bind(null, 'Image');

const Image: React.FunctionComponent<IImage> = React.forwardRef((props, ref) => {
    const refImg = useRef<HTMLImageElement>();

    let Tag = ('img' as any) as ImageTag | DivTag;
    const attrs: React.ImgHTMLAttributes<HTMLImageElement> = {
        src: '',
        alt: '',
        title: '',
        crossOrigin: 'anonymous'
    };
    const style: React.CSSProperties = { ...props.style };

    if (props.url) {
        attrs.src = props.url;
    }

    if (props.alt) attrs.alt = props.alt;
    if (props.title) attrs.title = props.title;
    if (props.width) attrs.width = props.width;
    if (props.height) attrs.height = props.height;
    if (props.itemProp) attrs.itemProp = props.itemProp;
    if (props.loading) attrs.loading = props.loading;

    if (typeof props.placeholder === 'string') {
        style.backgroundColor = props.placeholder;
    }

    if (props.type) {
        if (props.type !== 'videoCover') {
            Tag = ('div' as any) as DivTag;

            if (props.url) style.backgroundImage = 'url(' + props.url + ')';
            if (props.width) style.width = props.width + 'px';
            if (props.height) style.height = props.height + 'px';
        }

        attrs.crossOrigin = undefined;
    }

    useEffect(() => {
        if (refImg.current) {
            refImg.current.onload = null;
            refImg.current.onerror = null;
        }

        refImg.current = null;

        if (props.onLoad) {
            refImg.current = document.createElement('img');

            refImg.current.onload = () => {
                if (props?.onLoadVerticalImage) {
                    const isVertical = ((refImg.current?.width ?? 1) / (refImg.current?.height ?? 1)) <= 1;

                    props?.onLoadVerticalImage(isVertical);
                }
                props.onLoad();

                refImg.current.onload = null;
            };

            refImg.current.onerror = () => {
                props.onError && props.onError();

                refImg.current.onerror = null;
            };

            if (props.url) {
                refImg.current.src = props.url;
            }
        }
    }, [ props.url, props.onLoad, props.onError, props.onLoadVerticalImage ]);

    return (
        <Tag
            {...attrs}
            ref={ref}
            style={style}
            className={cn(null, {
                placeholder: props.placeholder,
                type: props.type
            }, props.className)}
        />
    );
});

export default Image;
