import React, { useCallback } from 'react';
import upperFirst from 'lodash/upperFirst';

import { Dropdown, Typography } from '@vtblife/uikit';
import { Checkbox, Size } from '@vtblife/uikit/legacy';

import classname from '@search/classname/src';
import { FloorConstraints } from '@search/filter-enums/enums';
import { INumberRange } from '@search/vtbeco-frontend-core/view/filters/types/IRange';

import { formatRange } from '@search/helpers/src/formatNumber';

import { FloorFilter } from '../../../../models/Filter';
import { NumberInputRange } from '../../../common/NewNumberInputRange/NumberInputRange';

import './DesktopFloorFilterView.css';

const cn = classname.bind(null, 'DesktopFloorFilterView');

const floorConstraintsPrefixes = {
    [FloorConstraints.UNKNOWN]: null,
    [FloorConstraints.ONLY]: 'только',
    [FloorConstraints.EXCLUDE]: 'не'
};

const makeTitle = (
    { from, to }: INumberRange,
    first: FloorConstraints,
    last: FloorConstraints
): string => {
    const labels = [];
    const rangeString = formatRange(from, to);

    if (rangeString) {
        labels.push(rangeString);
    }

    const lastPrefix = floorConstraintsPrefixes[last];

    if (lastPrefix) {
        labels.push(`${lastPrefix} последний`);
    }

    const firstPrefix = floorConstraintsPrefixes[first];

    if (firstPrefix) {
        labels.push(`${firstPrefix} первый`);
    }

    const title = labels.join(', ');

    if (rangeString) {
        return `Этаж ${title}`;
    }

    if (firstPrefix || lastPrefix) {
        return `${upperFirst(title)} этаж`;
    }

    return 'Этаж';
};

interface IDesktopAreaFilterViewProps {
    filter: FloorFilter;
    onChange: (filter: FloorFilter) => void;
}

export const DesktopFloorFilterView: React.FC<IDesktopAreaFilterViewProps> = ({
    filter,
    onChange
}) => {
    const { range, first, last } = filter;

    const handleRangeChange = useCallback((value: INumberRange) => onChange(
        new FloorFilter(value, first, last)
    ), [ onChange, first, last ]);

    const handleLastOnlyChange = useCallback((checked: boolean) => onChange(
        new FloorFilter(range, first, checked ? FloorConstraints.ONLY : FloorConstraints.UNKNOWN)
    ), [ onChange, range, first ]);

    const handleLastExcludeChange = useCallback((checked: boolean) => onChange(
        new FloorFilter(range, first, checked ? FloorConstraints.EXCLUDE : FloorConstraints.UNKNOWN)
    ), [ onChange, range, first ]);

    const handleFirstExcludeChange = useCallback((checked: boolean) => onChange(
        new FloorFilter(range, checked ? FloorConstraints.EXCLUDE : FloorConstraints.UNKNOWN, last)
    ), [ onChange, range, last ]);

    const handleReset = useCallback(() => onChange(
        new FloorFilter()
    ), [ onChange ]);

    return (<Dropdown>
        <Dropdown.Control
            arrow
            size='s'
        >{makeTitle(range, first, last)}</Dropdown.Control>

        <Dropdown.Content placement='local'>
            <div className={cn('popup')}>
                <div
                    onClick={handleReset}
                    className={cn('reset')}
                >
                    <Typography
                        tag='span'
                        className={cn('resetLink')}
                    >
                        Сбросить
                    </Typography>
                </div>
                <div className={cn('range')}>
                    <NumberInputRange
                        size={Size.Small}
                        value={range}
                        onChange={handleRangeChange}
                        max={1_000}
                    />
                </div>
                {[
                    {
                        title: 'Последний',
                        name: 'only-last',
                        checked: last === FloorConstraints.ONLY,
                        handleChange: handleLastOnlyChange
                    },
                    {
                        title: 'Не последний',
                        name: 'exclude-last',
                        checked: last === FloorConstraints.EXCLUDE,
                        handleChange: handleLastExcludeChange
                    },
                    {
                        title: 'Не первый',
                        name: 'exclude-first',
                        checked: first === FloorConstraints.EXCLUDE,
                        handleChange: handleFirstExcludeChange
                    }
                ].map(({ title, name, checked, handleChange }) => (
                    <div
                        key={name}
                        className={cn('constraint')}
                    >
                        <Checkbox
                            name={name}
                            checked={checked}
                            onChange={handleChange}
                        >
                            {title}
                        </Checkbox>
                    </div>
                ))}
            </div>
        </Dropdown.Content>
    </Dropdown>);
};
