/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-nested-ternary */
import React from 'react';

import { Currency } from '@search/filter-enums/enums/Currency';
import { DealType } from '@search/filter-enums/enums/DealType';
import { RealtyObjectType } from '@search/filter-enums/enums/RealtyObjectType';
import { RentPeriod } from '@search/filter-enums/enums/RentPeriod';
import { SellerType } from '@search/filter-enums/enums/SellerType';
import { TransportType } from '@search/filter-enums/enums/TransportType';
import { RegionIdEnum } from '@search/filter-enums/enums/Region';
import { SearchRoute, useRouter, OfferRoute as OfferRouteType, SearchMapRoute, IOfferType } from '@search/offer-search-routes/src';
import { useGqlContext } from '@search/gql-client/src/gqlContext';
import { useGql2Loader, Gql2CacheInfoCallback, gql2CreateCachedFetcher } from '@search/gql-client/src/useGql2';
import type { GqlClientOptions, GqlClient } from '@search/gql-client/src/GqlClient';
import { regionFromLocation } from '@search/geo/src/regionFromLocation';
import { useClGeoRootRegion } from '@search/geo/src/clGeoRootRegion/useClGeoRootRegion';
import { useOfferSessionVisited } from '@search/common-core/src/useSessionStorage';
import type { PriceHistoryPart } from '../../../../../view/common/components/Price/PriceHistory/PriceHistoryPart';
import { MetroStationBase } from '../../../../geo/MetroStation';
import { ClassifiedAnalytics } from '../../../analytics/ClassifiedAnalytics';
import type { OfferType } from '../OfferQuery';
import {
    AreaUnitEnum,
    SearchOffersQuery$data as SearchOffersQueryResponse,
    SearchOffersQuery$variables as SearchOffersQueryVariables,
    TransportTypeEnum,
    CurrencyEnum
} from './__generated__/SearchOffersQuery.graphql';
import { SearchOffersQuery } from './SearchOffersQuery';
import { areaUnitCompat } from './helpers/areaCompat';

const cacheInfo: Gql2CacheInfoCallback<
    'skipGeo' | 'skipNb',
    SearchOffersQueryVariables,
    SearchOffersQueryResponse
> = v => ({
    skipGeo: {
        responseKey: 'geo',
        cacheKey: String(
            v.regionId === RegionIdEnum.MSK_OBL ?
                RegionIdEnum.MSK_AND_MSK_OBL :
                (
                    v.regionId === RegionIdEnum.LEN_OBL ?
                        RegionIdEnum.SPB_AND_LEN_OBL :
                        v.regionId
                )
        ) + 'id-' + String(v.geos?.join('.') ?? '')
    },
    skipNb: {
        responseKey: 'newBuilding',
        cacheKey: v.filters?.newBuildingIds?.length ? v.filters.newBuildingIds.join('.') : undefined
    }
});

const cachePrefix = 'SearchOffers';

function createSearchOffersFetcher({ gql, isMobile = false }: {
    gql: GqlClient;
    isMobile?: boolean;
}) {
    return (vars: Omit<SearchOffersQueryVariables, 'geos'> & {
        geos?: readonly (number | string)[];
    }, opts?: GqlClientOptions) => {
        return gql.client<
            SearchOffersQueryVariables,
            SearchOffersQueryResponse
        >(SearchOffersQuery, {
            ...vars,
            geos: vars.geos?.map(rec => typeof rec === 'number' ?
                { smart: String(rec), regionId: undefined } :
                { smart: rec, regionId: vars.filters?.regionId }
            ) ?? [],
            touch: isMobile
        }, opts);
    };
}

function useSearchOffersFetcher(isMobile?: boolean) {
    const gql = useGqlContext();

    return React.useCallback(createSearchOffersFetcher({ gql, isMobile }), [ gql ]);
}

export function useSearchOffersLoader(isMobile?: boolean) {
    const loader = useGql2Loader(useSearchOffersFetcher(isMobile), { cacheInfo, cachePrefix });
    const rootRegions = useClGeoRootRegion();

    return React.useCallback((props: Parameters<typeof loader>[0]) => {
        const res = loader(props);
        const data = res.data;
        const regionId = props.filters?.regionId ?? 3;

        data?.geo?.districts.forEach(geo => rootRegions.slugs.add(geo, regionId));
        // data?.geo2?.items.forEach(geo => rootRegions.slugs.add(geo, regionId));
        // data?.searchOffers.geoArgs?.forEach(geo => rootRegions.slugs.add(geo, regionId));
        data?.searchOffers.items.forEach(item => item.location && rootRegions.slugs.addLocation(item.location));

        return res;
    }, [ rootRegions, loader ]);
}

export type SearchOffersLoader = ReturnType<typeof useSearchOffersLoader>;

export type SearchOffers = SearchOffersQueryResponse['searchOffers'];
export type OfferSnippetModel = SearchOffers['items'][number];
export type LastSearches = NonNullable<SearchOffersQueryResponse['getLastSearches']>['lastSearches'];
export type OfferSimilarNb = NonNullable<NonNullable<SearchOffersQueryResponse['searchOffers']['similarNb']>[number]>;

export function useOfferVisited(model: { id?: string; stats?: { isViewed?: boolean | null } | null }) {
    const visitedValue = useOfferSessionVisited();

    const isVisited = model?.id ? (model.stats?.isViewed || visitedValue(model.id)) : undefined;

    const enableIsVisited = React.useCallback(() => {
        if (model?.id) visitedValue(model.id, true);
    }, [ model?.id, visitedValue ]);

    return { isVisited, enableIsVisited };
}

export function useOfferCompat(model: OfferSnippetModel) {
    const addresses = useHandyAddressCompat({ ...model, clickableAddress: model.location.clickableAddressShort });
    const realtyObject = model.realtyObject;
    const dealType = model.dealType;

    const seller = sellerCompat(model.seller);

    const isSellFlat = realtyObject?.__typename === 'ProfOfferFlat' && dealType?.__typename === 'ProfOfferDealSell';
    const isNewBuilding = (isSellFlat && realtyObject.building?.isNewbuilding) ?? false;
    const isNewBuildingDeveloperFlat = isNewBuilding && model.seller.sellerType === 'DEVELOPER';
    const hasAnyPriority = model.status?.hasAnyPriority ?? false;
    const isPledge = (dealType?.__typename === 'ProfOfferDealSell' && dealType.hasBankPledge) ?? false;
    const isPit = model.seller.sellerType === 'PIT';
    const onlineView = model.onlineView ?? false;
    const description = model.description;
    const regionId = regionFromLocation(model.location) ?? RegionIdEnum.MSK;
    const price = priceCompat(dealType);
    const coordinates = model.location.coordinates;
    const pictures = model?.gallery?.images.map((photo, index) => ({
        id: `${index}`,
        originPath: photo.originPath,
        // src: photo.preview1 ?? photo.preview2,
        // src2x: photo.small ?? photo.medium,
        // large: photo.large,
        description: model.text?.titleCard ?? '',
        attributesType: photo.attributesType
    })) ?? [];

    const offerRoutes = useOfferRouteListCompat(model);
    const metersToCentralSquare = model.location.cityCenterInfo?.metersToCentralSquare;
    const locatedInCenter = model.location.cityCenterInfo?.locatedInCenter ?? false;
    const metersToCenter = metersToCentralSquare && metersToCentralSquare <= 30000 ?
        String(Math.round(metersToCentralSquare / 100) / 10).replace('.', ',') :
        undefined;

    return {
        formattedAddress: model.location.formattedAddressLong,
        formattedAddressShort: model.location.formattedAddressShort,
        offerSnippetClickGtmId: getOfferSnippetClickGtmId(model),
        offerPhoneClickGtmId: getOfferPhoneClickGtmId(model),
        addresses,
        realtyObject,
        dealType,
        seller,
        isNewBuilding,
        isNewBuildingDeveloperFlat,
        hasAnyPriority,
        isPledge,
        description,
        regionId,
        price,
        coordinates,
        pictures,
        offerRoutes,
        metersToCenter,
        locatedInCenter,
        isPit,
        onlineView,
        isSellFlat
    };
}

// eslint-disable-next-line no-undef
const history = typeof window === 'undefined' ? undefined : window.history;

export function useOfferCardLink(
    model?: {
        id: string;
        text?: null | {
            slug?: string;
        };
        location: {
            narrowRegion?: {
                id: number;
            } | null;
        };
    } | null
) {
    const id = model?.id;
    const router = useRouter();
    const route = router.route(OfferRouteType);

    const routeParams: IOfferType | undefined = React.useMemo(() => model ? {
        offerId: model.id,
        slug: model.text?.slug,
        region: model.location.narrowRegion?.id ?? RegionIdEnum.MSK
    } : undefined, [ id ]);

    const gql = useGqlContext();

    const offerUrl = React.useMemo(
        () => {
            try {
                return routeParams ? route.url(routeParams) : undefined;
            } catch (e) {
                e.message += ` offerId: ${id}`;
                throw e;
            }
        },
        [ routeParams, route, id ]
    );

    const gotoOffer = React.useCallback(<V>() => {
        route.push(routeParams);
    }, [ routeParams, route, gql, id ]);

    const gotoBackRaw = React.useCallback(() => {
        history?.back();
    }, [ ]);
    const gotoBack = (history?.length ?? 0) > 1 ? gotoBackRaw : undefined;

    return {
        offerUrl,
        gotoBack,
        gotoOffer
    };
}

type ClickableAddress = {
    id: number;
    name: string;
    kind: string;
}

const getKindParam = (address: ClickableAddress, regionId?: number) => {
    if (
        address.id === regionId ||
        address.id === RegionIdEnum.SPB ||
        address.id === RegionIdEnum.SPB_AND_LEN_OBL ||
        address.id === RegionIdEnum.LEN_OBL ||
        address.id === RegionIdEnum.MSK ||
        address.id === RegionIdEnum.MSK_AND_MSK_OBL ||
        address.id === RegionIdEnum.MSK_OBL
    ) {
        return { region: address.id };
    }

    switch (address.kind) {
        case 'PROVINCE':
            return { region: address.id };

        case 'DISTRICT':
            return { districtIds: [ address.id ] };

        case 'LOCALITY':
        case 'STREET':
        case 'HOUSE':
            return { addresses: [ address.id ] };

        default: return undefined;
    }
};

export const useHandyAddressCompat = ({
    dealType,
    realtyObject,
    location,
    clickableAddress,
    id
}: {
    id: string;
    dealType: Parameters<typeof dealTypeFilterCompat>[0];
    realtyObject?: Parameters<typeof realtyObjectFilterCompat>[0];
    clickableAddress?: readonly (
        NonNullable<OfferSnippetModel['location']['clickableAddress']>[number] & { isInRoute?: boolean }
    )[] | null;
    location: Parameters<typeof regionFromLocation>[0];
}) => {
    const router = useRouter();
    const searchRoute = router.route(SearchRoute);
    const params = router.paramsSafe(SearchRoute) ?? router.paramsSafe(SearchMapRoute);

    return React.useMemo(() => {
        if (! clickableAddress) return [];

        const region = regionFromLocation(location) ?? RegionIdEnum.MSK;
        const baseRouteParams = {
            region,
            dealType: dealTypeFilterCompat(dealType),
            categoryType: realtyObjectFilterCompat(realtyObject)
        };

        const geoIds = params ? new Set([
            ...params.districtIds,
            ...params.metroIds,
            ...params.addresses,
            params.region
        ]) : null;

        const addresses = clickableAddress.map(item => {
            try {
                let isAddressInRoute = item.isInRoute || (geoIds && geoIds.has(item.id));

                // if (item.kind === 'PROVINCE' && ! geoIds?.size) isAddressInRoute = true;
                // if (item.id === region && ! geoIds?.size) isAddressInRoute = true;

                const params2 = getKindParam(item, region);

                const href = searchRoute.url({
                    ...baseRouteParams,
                    ...params2
                });

                if (router.location.href.startsWith(href)) isAddressInRoute = true;

                return {
                    content: item.name,
                    href: isAddressInRoute ? undefined : href,
                    isNofollowLink: item.kind === 'HOUSE'
                };
            } catch (e) {
                e.message += ` offerId: ${id}`;
                throw e;
            }
        });

        return addresses;
    }, [ id, params ]);
};

export function dealTypeFilterCompat(
    dealType: Pick<NonNullable<OfferSnippetModel['dealType']>, '__typename'> | null | undefined
): DealType {
    switch (dealType?.__typename) {
        case 'ProfOfferDealRent': return DealType.RENT;
        default: return DealType.SELL;
    }
}

export function dealTypeCompat(dealType: OfferSnippetModel['dealType']): DealType {
    switch (dealType?.__typename) {
        case 'ProfOfferDealRent': return DealType.RENT;
        default: return DealType.SELL;
    }
}

export function dealTypePeriodCompat(dealType: OfferSnippetModel['dealType']): RentPeriod | undefined {
    if (dealType?.__typename !== 'ProfOfferDealRent') return undefined;

    switch (dealType.period) {
        case 'DAY': return RentPeriod.DAY;
        case 'MONTH': return RentPeriod.MONTH;
        case 'YEAR': return RentPeriod.YEAR;
        default: return undefined;
    }
}

export function realtyObjectFilterCompat(
    realtyObject: Pick<NonNullable<OfferSnippetModel['realtyObject']>, '__typename'> | undefined | null
): RealtyObjectType {
    switch (realtyObject?.__typename) {
        case 'ProfOfferFlat': return RealtyObjectType.FLAT;
        case 'ProfOfferRoom': return RealtyObjectType.ROOM;
        case 'ProfOfferHouse': return RealtyObjectType.HOUSE;
        case 'ProfOfferLand': return RealtyObjectType.LAND;
        default: return RealtyObjectType.FLAT;
    }
}

export function priceCompat(dealType?: OfferSnippetModel['dealType']) {
    if (dealType?.__typename !== 'ProfOfferDealSell' && dealType?.__typename !== 'ProfOfferDealRent') return;

    if (! dealType.price) return;

    return price2Compat(dealType.price);
}

export function price2Compat(
    price: {
        value: number;
        history?: (readonly PriceHistoryPart[]) | null;
        rubPrice?: number | null;
        currency: CurrencyEnum;
        areaUnit: AreaUnitEnum | null;
        formatted?: string;
        formattedWithCurrency?: string; // for Rent
    },
    divider = 100
) {
    const currency = price.currency === 'UNKNOWN' || ! price.currency ?
        Currency.RUB :
        Currency[price.currency];

    return {
        value: Math.ceil(price.value / divider),
        rubPrice: price.rubPrice ? Math.ceil(price.rubPrice / divider) : undefined,
        history: price.history,
        currency,
        formatted: price.formatted ?? price.formattedWithCurrency,
        areaUnit: areaUnitCompat(price.areaUnit)
    };
}

export interface OfferRoute {
    metro: MetroStationBase;
    url?: string;
    transportType: TransportType;
    timeMinutes: number;
}

function transportTypeCompat(transport?: TransportTypeEnum | null): TransportType {
    switch (transport) {
        case 'PUBLIC_TRANSPORT': return TransportType.PUBLIC_TRANSPORT;
        case 'FOOT': return TransportType.FOOT;
        default: return TransportType.UNKNOWN;
    }
}

export function useOfferRouteListCompat(
    offer: {
        id: string;
        dealType: OfferSnippetModel['dealType'];
        realtyObject: OfferSnippetModel['realtyObject'];
        location: {
            routesList: OfferSnippetModel['location']['routesList'];
            narrowRegion: OfferSnippetModel['location']['narrowRegion'];
        };
    }
): OfferRoute[] {
    const router = useRouter();

    return React.useMemo(() => {
        const routes = offer.location.routesList;
        const result: OfferRoute[] = [];
        const searchRoute = router.route(SearchRoute);

        for (const route of routes ?? []) {
            if (! route.timeMinutes || ! route.station) continue;

            const metro: MetroStationBase = {
                id: route.station.id,
                allRoutesColorsList: route.station.allRoutesColorsList ?? [],
                title: route.station.name
            };

            const url = searchRoute.prevParams?.metroIds.includes(metro.id) ? undefined : searchRoute.url({
                dealType: dealTypeFilterCompat(offer.dealType),
                categoryType: realtyObjectFilterCompat(offer.realtyObject),
                region: regionFromLocation(offer.location) ?? RegionIdEnum.MSK,
                metroIds: [ metro.id ],
                seoMortgageAllowed: false
            });

            result.push({
                url,
                timeMinutes: route.timeMinutes,
                transportType: transportTypeCompat(route.transportType),
                metro
            });
        }

        return result;
    }, [ offer.id, router ]);
}

export function sellerCompat(seller: OfferSnippetModel['seller'] | OfferType['seller']) {
    return {
        icon: seller.isZhekaLogo ? seller.logo?.origin : undefined,
        name: seller.name ?? '',
        organizationName: seller.organizationName ?? '',
        sellerType: SellerType[seller.sellerType]
    };
}

export function getOfferSnippetClickGtmId(
    {
        seller, realtyObject, dealType, status
    }: Pick<OfferSnippetModel, 'seller' | 'realtyObject' | 'dealType' | 'status'>
): ClassifiedAnalytics | undefined {
    if (realtyObject?.__typename === 'ProfOfferFlat' && dealType?.__typename === 'ProfOfferDealSell') {
        if (seller?.sellerType === 'DEVELOPER' && realtyObject.building?.isNewbuilding) {
            return ClassifiedAnalytics.SaleFlatOpenNewDevOffer;
        }

        if (realtyObject.building?.isNewbuilding) {
            return ClassifiedAnalytics.SaleFlatOpenNewOffer;
        }

        if (status?.hasHighlight) {
            return ClassifiedAnalytics.SaleOpenHighlightOffer;
        }

        if (status?.hasAnyPriority) {
            return ClassifiedAnalytics.SaleOpenPaidOffer;
        }

        return ClassifiedAnalytics.SaleFlatOpenSecondOffer;
    }

    if (realtyObject?.__typename === 'ProfOfferFlat' && dealType?.__typename === 'ProfOfferDealRent') {
        if (realtyObject.building?.isNewbuilding) {
            return ClassifiedAnalytics.RentFlatOpenNewOffer;
        }

        return ClassifiedAnalytics.RentFlatOpenSecondOffer;
    }

    if (status?.hasHighlight && dealType?.__typename === 'ProfOfferDealSell') {
        return ClassifiedAnalytics.SaleOpenHighlightOffer;
    }

    if (status?.hasAnyPriority && dealType?.__typename === 'ProfOfferDealSell') {
        return ClassifiedAnalytics.SaleOpenPaidOffer;
    }

    if (! status?.hasAnyPriority && dealType?.__typename === 'ProfOfferDealSell') {
        return ClassifiedAnalytics.SaleOpenSecondOffer;
    }

    return undefined;
}

export function getOfferSimilarGtmIds(
    {
        seller, realtyObject, dealType, status, isMortgageAllowed
    }: Pick<OfferSnippetModel, 'seller' | 'realtyObject' | 'dealType' | 'status' | 'isMortgageAllowed'>
): { mortgage: string; phoneShown: string; click: string } {
    const analytics = {
        mortgage: '',
        phoneShown: '',
        click: ''
    };

    if (isMortgageAllowed) {
        analytics.mortgage = 'classified_card_similar_sale_view_ipoteka_link';
    }

    // Вторичка
    analytics.click = 'classified_card_similar_click_open_second_offer';
    analytics.phoneShown = 'classified_card_similar_sale_show_tel';

    // Вторичка VAS
    if (status?.hasAnyPriority) {
        analytics.click = 'classified_card_similar_click_open_second_vas_offer';
        analytics.phoneShown = 'classified_card_similar_sale_vas_show_tel';
    }

    if (realtyObject?.__typename === 'ProfOfferFlat' && dealType?.__typename === 'ProfOfferDealSell') {
        if (realtyObject.building?.isNewbuilding) {
            analytics.click = 'classified_card_similar_click_open_new_offer';
            analytics.phoneShown = 'classified_card_similar_new_show_tel';
        }

        if (seller?.sellerType === 'DEVELOPER' && realtyObject.building?.isNewbuilding) {
            analytics.click = 'classified_card_similar_click_open_new_dev_offer';
            analytics.phoneShown = 'classified_card_similar_new_dev_show_tel';
        }
    }

    if (dealType?.__typename === 'ProfOfferDealRent') {
        analytics.click = 'classified_card_similar_click_rent_open_second_offer';
        analytics.phoneShown = 'classified_card_similar_rent_show_tel';
    }

    return analytics;
}

function getOfferPhoneClickGtmId(
    { seller, realtyObject, dealType, status, isNewBuildingPaid }: OfferSnippetModel
) {
    if (realtyObject?.__typename === 'ProfOfferFlat' && dealType?.__typename === 'ProfOfferDealSell') {
        if (isNewBuildingPaid && seller.sellerType === 'DEVELOPER' && realtyObject.building?.isNewbuilding) {
            return ClassifiedAnalytics.SearchSaleFromDevPaidShowTel;
        }
        if (seller.sellerType === 'DEVELOPER' && realtyObject.building?.isNewbuilding) {
            return ClassifiedAnalytics.SearchSaleFromDevShowTel;
        }

        if (realtyObject.building?.isNewbuilding) {
            return ClassifiedAnalytics.SearchSaleNewShowTel;
        }

        if (status?.hasHighlight) {
            return ClassifiedAnalytics.SearchSaleHighlightShowTel;
        }

        if (status?.hasAnyPriority) {
            return ClassifiedAnalytics.SearchSalePaidShowTel;
        }

        return ClassifiedAnalytics.SearchSaleShowTel;
    }

    if (realtyObject?.__typename === 'ProfOfferFlat' && dealType?.__typename === 'ProfOfferDealRent') {
        if (realtyObject.building?.isNewbuilding) {
            return ClassifiedAnalytics.SearchRentNewShowTel;
        }

        return ClassifiedAnalytics.SearchRentShowTel;
    }

    if (dealType?.__typename === 'ProfOfferDealSell') {
        if (status?.hasHighlight) {
            return ClassifiedAnalytics.SearchSaleHighlightShowTel;
        }

        if (status?.hasAnyPriority) {
            return ClassifiedAnalytics.SearchSalePaidShowTel;
        }

        return ClassifiedAnalytics.SearchSaleShowTel;
    }

    if (dealType?.__typename === 'ProfOfferDealRent') {
        return ClassifiedAnalytics.SearchRentShowTel;
    }

    return undefined;
}
