/* eslint-disable complexity */
import {
    DealType,
    RealtyObjectType
} from '@search/filter-enums/enums';
import {
    IFilter,
    Filters,
    DealTypeFilter,
    CategoryTypeFilter
} from '../../../view/filters/models/Filter';
import { ClassifiedFilterCollection } from './ClassifiedFilterCollection';
import { filterCreator, sellFlatMetadata, sellRoomMetadata, rentFlatMetadata, rentRoomMetadata, sellLandMetadata, sellHouseMetadata, rentHouseMetadata } from './metadata/ClassifiedFilterMetadata';
import { FilterCollectionMetadata } from './types/FilterCollectionMetadata';

/**
 * ClassifiedFilterFactory
 * Contains methods for create and update ClassifiedFilterCollection.
 * Do not instance ClassifiedFilterCollection directly
 */
class ClassifiedFilterFactory {
    createOrUpdateFilters(
        filters: Map<Filters, IFilter> = new Map<Filters, IFilter>(),
        filter?: IFilter
    ): ClassifiedFilterCollection {
        const { filterIds, persistIds } = this.getRelevantFilterMetadata(filters, filter);
        const isChangeSegment = (filter) ? filter.id === Filters.dealType || filter.id === Filters.categoryType : false;

        const filterList = filterIds.map(id => {
            let newFilter: IFilter;

            if (isChangeSegment) {
                if (filter && filter.id === id) {
                    newFilter = filter;
                } else {
                    const gettingFilter = filters.get(id);

                    newFilter = persistIds.has(id) && gettingFilter ?
                        gettingFilter :
                        filterCreator[id]();
                }
            } else {
                newFilter = filters.get(id) || filterCreator[id]();
            }

            return [ id, newFilter ] as const;
        });

        const filterMap = new Map<Filters, IFilter>(filterList);

        if (isChangeSegment) {
            const dealTypeFilter = filterMap.get(Filters.dealType) as DealTypeFilter;
            const realtyTypeFilter = filterMap.get(Filters.categoryType) as CategoryTypeFilter;

            if (
                realtyTypeFilter?.categoryType === RealtyObjectType.LAND &&
                dealTypeFilter?.dealType === DealType.RENT
            ) {
                filterMap.set(Filters.categoryType, new CategoryTypeFilter(RealtyObjectType.FLAT));
                filterMap.set(Filters.dealType, new DealTypeFilter(DealType.RENT));
            }
        }

        return this.create(filterMap);
    }

    create(
        filters: Map<Filters, IFilter> = new Map<Filters, IFilter>()
    ): ClassifiedFilterCollection {
        return new ClassifiedFilterCollection(filters);
    }

    getRelevantFilterMetadata(
        filters: Map<Filters, IFilter> = new Map<Filters, IFilter>(),
        filter?: IFilter
    ): FilterCollectionMetadata {
        const realtyType = ((filter && filter.id === Filters.categoryType) ?
            filter :
            filters.get(Filters.categoryType)) as CategoryTypeFilter;

        const dealTypeFilter = ((filter && filter.id === Filters.dealType) ?
            filter :
            filters.get(Filters.dealType)) as DealTypeFilter;

        if (
            realtyType?.categoryType === RealtyObjectType.LAND &&
            dealTypeFilter?.dealType === DealType.RENT
        ) {
            return rentFlatMetadata;
        }

        if (dealTypeFilter?.dealType === DealType.SELL) {
            if (realtyType?.categoryType === RealtyObjectType.FLAT) {
                return sellFlatMetadata;
            } else if (realtyType?.categoryType === RealtyObjectType.ROOM) {
                return sellRoomMetadata;
            } else if (realtyType?.categoryType === RealtyObjectType.LAND) {
                return sellLandMetadata;
            } else if (realtyType?.categoryType === RealtyObjectType.HOUSE) {
                return sellHouseMetadata;
            }

            return sellFlatMetadata;
        } else if (dealTypeFilter?.dealType === DealType.RENT) {
            if (realtyType?.categoryType === RealtyObjectType.FLAT) {
                return rentFlatMetadata;
            } else if (realtyType?.categoryType === RealtyObjectType.ROOM) {
                return rentRoomMetadata;
            } else if (realtyType?.categoryType === RealtyObjectType.HOUSE) {
                return rentHouseMetadata;
            }

            return rentFlatMetadata;
        }

        return sellFlatMetadata;
    }

    getAdditionalFilterIds(
        filters: Map<Filters, IFilter> = new Map<Filters, IFilter>(),
        exceptIds: Filters[] = []
    ): Filters[] {
        const additionalFilterIds = [ ...filters.keys() ].filter(id => {
            const categoryFilter = filters.get(Filters.categoryType) as CategoryTypeFilter;
            const isFlat = categoryFilter.categoryType === RealtyObjectType.FLAT;

            return (
                Filters.dealType !== id &&
                Filters.categoryType !== id &&
                Filters.newBuilding !== id &&
                ! (isFlat && Filters.rooms === id) &&
                Filters.price !== id &&
                Filters.region !== id &&
                ! exceptIds.includes(id)
            );
        });

        return additionalFilterIds;
    }

    clearAdditionalFilters(
        filters: Map<Filters, IFilter> = new Map<Filters, IFilter>(),
        exceptIds: Filters[] = []
    ): Map<Filters, IFilter> {
        const additionalFilterIds = this.getAdditionalFilterIds(filters, exceptIds);

        additionalFilterIds.forEach(id => {
            filters.set(id, filters.get(id)!.create());
        });

        return filters;
    }
}

export const classifiedFilterFactory = new ClassifiedFilterFactory();
