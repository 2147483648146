import React, { useCallback } from 'react';
import { CeilingHeight } from '@search/filter-enums/enums';
import { CellHeightFilter } from '../../../../models/Filter';
import MobileFilter from '../../../filter/MobileFilter';
import '../MobileCommon.css';
import { RadioSelect, RadioOption } from '../../common/RadioSelect';

interface IMobileCeilHeightFilterViewProps {
    filter: CellHeightFilter;
    handleChange: (filter: CellHeightFilter) => void;
}

const selectOptions: RadioOption[] = [ {
    value: CeilingHeight.FROM_2_5,
    label: 'от 2,5 м',
    className: 'col-xs-6 filter-item-gap'
}, {
    value: CeilingHeight.FROM_2_7,
    label: 'от 2,7 м',
    className: 'col-xs-6 filter-item-gap'
}, {
    value: CeilingHeight.FROM_3,
    label: 'от 3 м',
    className: 'col-xs-6 filter-item-gap'
}, {
    value: CeilingHeight.FROM_4,
    label: 'от 4 м',
    className: 'col-xs-6 filter-item-gap'
} ];

export const MobileCeilHeightFilterView: React.FC<IMobileCeilHeightFilterViewProps> = ({
    filter,
    handleChange
}) => {
    const onChange = useCallback(value => {
        handleChange(new CellHeightFilter(value));
    }, [ handleChange ]);

    return (
        <MobileFilter
            filterTitle='Высота потолков'
        >
            <RadioSelect
                allowNull
                name='cellHeight'
                className='container container__clamp'
                options={selectOptions}
                value={filter.value}
                handleChange={onChange}
            />
        </MobileFilter>
    );
};
