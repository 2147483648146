import type { YMapBounds } from '@search/ymap/src/bounds';
import type { GeoObject, RegionSection } from '@search/graphql-typings';
import { GroupedOptions } from '@vtblife/uikit/legacy';
import { legacySlugToNew } from '@search/router/src/legacySlug';
import { GeoBase, SlugMapper } from '@search/geo/src/SlugMapper';

export type { RegionSection } from '@search/graphql-typings';

export interface IRegion extends GeoObject {
    isParent?: boolean;
    bounds: YMapBounds;
}

type RootRegionOption = {
    isParent?: IRegion['isParent'];
    fullName: string;
    label: string;
    value: string;
};

function isRegionSection(item: RegionSection | GeoObject): item is RegionSection {
    return (item as RegionSection).rootRegion !== undefined;
}

function isGeoObject(item: RegionSection | GeoObject): item is GeoObject {
    return (item as GeoObject).id !== undefined;
}

function buildRegionMap(ret: Record<number, GeoObject>, region: RegionSection | GeoObject) {
    if (isRegionSection(region) && region.rootRegion) {
        ret[region.rootRegion.id] = region.rootRegion;
    }

    if (isRegionSection(region) && region.regions) {
        region.regions
            .forEach(item => item && buildRegionMap(ret, item));
    }

    if (isGeoObject(region)) {
        ret[region.id] = region;
    }

    return ret;
}

function buildFlatRegion(ret: Array<IRegion | GeoObject>, region: RegionSection | GeoObject) {
    if (isRegionSection(region) && region.rootRegion) {
        ret.push({ ...region.rootRegion, isParent: true });
    }

    if (isRegionSection(region) && region.regions) {
        ret.push(...region.regions.filter((item): item is GeoObject => item !== null));
    }

    return ret;
}

export type IRootRegions = {
    getById(id: number): undefined | { translit?: string | null; narrowRegion?: { id: number } };
    getByTranslitName(slug: string): undefined | { id: number };
    addGeoSlug?(geo: GeoBase, regionId: number): void;
    getGeoFromSlug?(slug: string, regionId: number): GeoBase | undefined;
    getSlugFromGeo?(regionId: number): string | null | undefined;
    getGeoSlug?(regionId: number): GeoBase | undefined;
};

export class RootRegions implements IRootRegions {
    private regions: RegionSection[];
    private regionsIdMap: Record<number, any>;
    private regionsFlat: any[];
    private slugs: SlugMapper;

    constructor(regions: RegionSection[]) {
        this.regions = regions;
        this.regionsFlat = regions.reduce(buildFlatRegion, []);
        this.regionsIdMap = regions.reduce(buildRegionMap, {} as Record<number, IRegion>);
        this.slugs = new SlugMapper();
    }

    regionsEnumeration(): Set<number> {
        return new Set<number>(Object.keys(this.regionsIdMap).map(Number));
    }

    getById(id: number) {
        return this.regionsIdMap[Number(id)];
    }

    getByTranslitName(translit: string) {
        const newSlug = legacySlugToNew(translit) ?? translit;

        return this.regionsFlat.find(item => item.translit === translit || item.translit === newSlug);
    }

    getFlatList() {
        return this.regionsFlat;
    }

    getRegions() {
        return this.regions;
    }

    getRegionsLikeOptions() {
        return this.getRegions()
            .reduce((ret, { rootRegion, regions }) => {
                const options = [];

                if (rootRegion) {
                    options.push({
                        isParent: true,
                        fullName: rootRegion.mainName,
                        label: rootRegion.displayName,
                        value: String(rootRegion.id)
                    } as RootRegionOption);
                }

                ret.push({
                    label: '',
                    options: options
                        .concat(
                            // @ts-ignore
                            (regions || [])
                                .filter((item): item is GeoObject => item !== null)
                                .map(item => ({
                                    isParent: false,
                                    fullName: item.mainName ?? '',
                                    label: String(item.displayName),
                                    value: String(item.id)
                                }))
                        )
                });

                return ret;
            }, [] as GroupedOptions<RootRegionOption>[]);
    }

    addGeoSlug(geo: GeoBase, regionId: number) {
        return this.slugs.add(geo, regionId);
    }

    getGeoFromSlug(slug: string, regionId: number) {
        return this.slugs.id(slug, regionId);
    }

    getSlugFromGeo(regionId: number) {
        return this.slugs.slug(regionId)?.translit;
    }

    getGeoSlug(regionId: number) {
        return this.slugs.slug(regionId);
    }
}
