import './styles.css';

import React from 'react';

import Icon from '@vtblife/uikit-icons';
import { Typography } from '@vtblife/uikit';
import { Size } from '@vtblife/uikit/legacy';

import classname from '@search/classname/src';
import { declensionByNumber } from '@search/helpers/src/declensionByNumber';

const cn = classname.bind(null, 'ModalHeading');

interface IProps {
    text: string;
    icon?: React.ReactNode;
    activeCount?: number;
    reset?: () => void;
    onClose?: () => void;
    dataTest?: string;
    className?: string;
    withDeclension?: boolean;
}

export const ModalHeading = ({
    icon,
    text,
    activeCount,
    reset,
    onClose,
    dataTest,
    className,
    withDeclension = true
}: IProps) => (
    <div className={cn(null, undefined, className)}>
        <ModalHeadingInner
            icon={icon}
            text={text}
            activeCount={activeCount}
            reset={reset}
            dataTest={dataTest}
            withDeclension={withDeclension}
        />
        <div
            onClick={onClose}
            className={cn('close')}
            data-test={dataTest ? `${dataTest}-close` : undefined}
        >
            <Icon
                name='times'
                size={Size.Small}
            />
        </div>
    </div>
);

export const ModalHeadingInner = ({
    icon,
    text,
    activeCount,
    reset,
    dataTest,
    withDeclension = true
}: IProps) => (
    <div className={cn('inner')}>
        {icon}
        {text}
        {
            // eslint-disable-next-line no-nested-ternary
            activeCount ? (
                reset ? (<>
                    <div className={cn('counter')}>{activeCount}</div>
                    <Typography
                        tag='span'
                        onClick={reset}
                        className={cn('reset')}
                        data-test={dataTest ? `${dataTest}-reset` : undefined}
                    >
                        Сбросить
                    </Typography>
                </>) : (
                    <Typography
                        tag='span'
                        color='black-400'
                        className={cn('text-counter')}
                    >
                        {activeCount}&nbsp;
                        {
                            withDeclension ?
                                declensionByNumber(activeCount, [ 'выбран', 'выбрано', 'выбрано' ]) :
                                'выбрано'
                        }
                    </Typography>
                )
            ) : null
        }
    </div>
);
