import React, { useCallback } from 'react';

import { FlatsSpecialEvent } from '@search/filter-enums/enums/SpecialEvents';

import { FlatsSpecialEventsFilter } from '../../../../models/Filter';
import MobileFilter from '../../../filter/MobileFilter';
import { MultiSelect } from '../../common/MultiSelect';
import type { MultiSelectOption } from '../../common/MultiSelect';

interface IMobileFlatsSpecialEventsFilterViewProps {
    filter: FlatsSpecialEventsFilter;
    onChange: (value: FlatsSpecialEventsFilter) => void;
}

const SELECT_OPTIONS: MultiSelectOption<FlatsSpecialEvent>[] = [
    {
        value: FlatsSpecialEvent.FLAT_DISCOUNT,
        label: 'Скидка',
        className: 'col-xs-12 filter-item-gap'
    },
    {
        value: FlatsSpecialEvent.FLAT_INSTALLMENT_PLAN,
        label: 'Рассрочка',
        className: 'col-xs-12 filter-item-gap'
    },
    {
        value: FlatsSpecialEvent.FLAT_SALES_START,
        label: 'Старт продаж',
        className: 'col-xs-12 filter-item-gap'
    }
];

export const MobileFlatsSpecialEventsFilterView: React.FC<IMobileFlatsSpecialEventsFilterViewProps> = ({
    filter,
    onChange
}) => {
    const handleChange = useCallback(value => {
        onChange(new FlatsSpecialEventsFilter(value));
    }, [ onChange ]);

    return (
        <MobileFilter filterTitle='Квартиры'>
            <MultiSelect
                name='flatsSpecialEvents'
                options={SELECT_OPTIONS}
                value={filter.value}
                handleChange={handleChange}
                className='container container__clamp'
            />
        </MobileFilter>
    );
};
