import React from 'react';
import { useGql2Loader } from '@search/gql-client/src/useGql2';
import { graphql, useGqlContext } from '@search/gql-client/src';

import type {
    useInitialPhoneQuery$data as useInitialPhoneQueryResponse,
    useInitialPhoneQuery$variables as useInitialPhoneQueryVariables
} from './__generated__/useInitialPhoneQuery.graphql';

const query = graphql`
    query useInitialPhoneQuery {
        userDetails {
            phone
        }
    }
`;

function useUseInfoFetcher() {
    const gql = useGqlContext();

    return React.useCallback(
        (vars: useInitialPhoneQueryVariables = {}, opts?: { signal?: AbortSignal }) => gql.client<
            useInitialPhoneQueryVariables,
            useInitialPhoneQueryResponse
        >(query, vars, opts)
            .then(response => {
                return response;
            }),
        [ gql ]
    );
}

export function useInitialPhone() {
    const load = useGql2Loader(useUseInfoFetcher(), { cachePrefix: 'UserDetailsPhone' });
    const data = load({});

    return data?.data?.userDetails?.phone ?? '';
}
