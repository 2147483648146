/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

type Image = { width: number; height: number; originPath: string };

const defaultData = {
    src: undefined as undefined | string,
    srcSet: undefined as undefined | string
};

const defaultProvider = (img: Image) => defaultData;

const ImageLazySrcSetContext = React.createContext(defaultProvider);

export function useClImageLazySrcSetUrl({ baseUrl = 'https://img.m2.ru' } = {}) {
    return React.useCallback(
        (scale: number, img: Image) => `${baseUrl}/i/s${img.width * scale}x${img.height * scale}${img.originPath}`,
        [ baseUrl ]
    );
}

const isHiDpi = typeof window === 'undefined' ?
    undefined :
    new URLSearchParams(window.location.search).get('dbg_hidpi') === '1';

export function ImageLazySrcSetProvide({
    scales = [ 1, 2 ],
    children,
    getUrl
}: {
    scales?: readonly number[];
    getUrl(scale: number, img: Image): string;
    children?: React.ReactNode;
}) {
    if (isHiDpi) scales = [ 1, 2 ];

    const getSrcSet = React.useCallback((img: Image) => {
        const data = scales.map(scale => {
            const url = getUrl(scale, img);

            return scale === 1 ? url : `${url} ${scale}x`;
        });
        const src = data.length > 0 ? data[0] : undefined;
        const srcSet = data.join(', ');

        return { src, srcSet };
    }, [ scales.join('.'), getUrl ]);

    return <ImageLazySrcSetContext.Provider value={getSrcSet} children={children} />;
}

export function useImageLazySrcSet() {
    return React.useContext(ImageLazySrcSetContext);
}
