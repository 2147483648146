/* eslint-disable max-len */
/* eslint-disable curly, complexity */
import upperFirst from 'lodash/upperFirst';
import { NewBuilding } from '@search/filter-enums/enums/NewBuilding';
import { DealType } from '@search/filter-enums/enums/DealType';
import { PriceSeoMatcher } from '@search/offer-search-routes/src/searchRoute/PriceSeoMatcher';
import { BuildEpochSeoMatcher } from '@search/offer-search-routes/src/searchRoute/BuildEpochSeoMatcher';
import { AuthorTypeOptionsWithRealtor, RealtyObjectType, Renovation, Rooms } from '@search/filter-enums/enums';
import { RegionIdEnum } from '@search/filter-enums/enums/Region';
import { seoSearchCommon, seoSearchCommonJoin, SeoSearchCommonProps } from './seoSearchCommon';

export function seoSearchFlatRoom({ params, geos, offersCount, offersSummary, region }: SeoSearchCommonProps) {
    const segment = params.segments;
    const {
        conciergeText,
        hasLiftText,
        balconyText,

        dealTypeText,
        geoText,
        regionText,
        additionalRegionText,
        offersCountText,
        nearMetroText,
        priceMaxText,
        parkingText,
        rentPaymentText,
        seoMortgage,
        generateFaqTexts,
        isNonCapitalRegion
    } = seoSearchCommon({ params, geos, offersCount, offersSummary, region });

    let roomCount = 0;
    const roomType = params.categoryType === RealtyObjectType.ROOM ? undefined : params?.segments?.rooms?.[0];

    if (roomType === Rooms.ROOM_1) roomCount = 1;
    if (roomType === Rooms.ROOM_2) roomCount = 2;
    if (roomType === Rooms.ROOM_3) roomCount = 3;
    if (roomType === Rooms.ROOM_4) roomCount = 4;

    const roomsText = getFlatRoomCategoryText(params, roomCount, roomType);
    const roomsFullText = getFlatCategoryFullText(params, roomCount);

    const noClientCommision = params?.commisionType === 'NO_CLIENT_COMMISSION' ? 'без комиссии' : '';
    let petsAllowed = {
        accusative: '',
        plural: ''
    };
    const withoutDeposit = params?.depositType === 'NO_DEPOSIT' ? 'без залога' : '';

    if (params?.livingTenantOptions && params?.livingTenantOptions.includes('PETS_ALLOWED')) {
        petsAllowed = {
            accusative: ', в которой можно жить с животными',
            plural: ', в которых можно жить с животными'
        };
    }

    if (params?.livingTenantOptions && params?.livingTenantOptions.includes('CHILDREN_ALLOWED')) {
        petsAllowed = {
            accusative: ', в которой можно жить с детьми',
            plural: ', в которых можно жить с детьми'
        };
    }

    if (! params?.livingTenantOptions || params?.livingTenantOptions?.length > 1) {
        petsAllowed = {
            accusative: '',
            plural: ''
        };
    }

    let newBuildingText = {
        accusative: '',
        plural: '',
        prepositional: ''
    };

    const nb = segment?.newBuilding?.[0];

    if (nb === NewBuilding.NEW) newBuildingText = {
        accusative: 'в\u00A0новостройке',
        plural: 'новостроек',
        prepositional: 'в\u00A0новостройках'
    };

    if (nb === NewBuilding.SECOND) newBuildingText = {
        accusative: 'на\u00A0вторичке',
        plural: 'вторичек',
        prepositional: 'на\u00A0вторичном рынке жилья'
    };

    let renovationText = '';
    const renovation = segment?.renovation?.[0];

    if (renovation === Renovation.COSMETIC) renovationText = 'с\u00A0косметическим ремонтом';
    if (renovation === Renovation.DESIGNED) renovationText = 'с\u00A0дизайнерским ремонтом';
    if (renovation === Renovation.EURO) renovationText = 'с\u00A0евроремонтом';
    if (renovation === Renovation.NEED) renovationText = 'с\u00A0ремонтом';
    if (renovation === Renovation.NONE) renovationText = 'без\u00A0ремонта';

    let securityText = '';

    if (segment?.infrastructure?.includes('CLOSED_AREA')) securityText = 'с охраняемой территорией';

    let renovationProgramText = '';

    if (segment?.renovationProgram === 'HAS_ANY') renovationProgramText = 'под реновацию';

    let pledgeText = '';

    if (segment?.bankPledgeOptions === 'IN_PLEDGE') pledgeText = 'в\u00A0залоге';

    const authorText = segment?.author?.includes(AuthorTypeOptionsWithRealtor.OWNER) ? 'от\u00A0собственника' : '';
    let bathroomText = '';
    let furnitureText = '';

    if (segment?.bathroom?.includes('PARTED')) bathroomText = 'с\u00A0раздельным санузлом';
    if (segment?.bathroom?.includes('COMBINED')) bathroomText = 'с\u00A0совмещенным санузлом';

    if (segment?.furniture?.includes('NO')) furnitureText = 'без мебели';
    if (segment?.furniture?.includes('ALL')) furnitureText = 'с\u00A0мебелью';
    if (segment?.furniture?.includes('ALL') &&
        params?.dealType === DealType.SELL &&
        params?.categoryType === RealtyObjectType.FLAT) furnitureText = 'с\u00A0ремонтом и\u00A0мебелью';

    let priceLevelText = {
        accusative: '',
        plural: '',
        nominative: '',
        genitive: ''
    };
    let priceClassText = {
        accusative: '',
        plural: ''
    };

    const priceSeo = PriceSeoMatcher.$.match({
        categoryType: params.categoryType ?? RealtyObjectType.FLAT,
        dealType: params.dealType as DealType ?? DealType.SELL,
        priceMax: segment?.priceMax,
        priceMin: segment?.priceMin,
        region: params.region,
        regionTranslit: region?.translit
    });

    if (priceSeo?.seg === 'deshevye') {
        priceClassText = {
            accusative: ' дешёвую',
            plural: ' дешёвых'
        };
        priceLevelText = {
            accusative: 'недорогую',
            plural: 'недорогих',
            nominative: 'недорогая',
            genitive: 'недорогой'
        };
    }

    if (priceSeo?.seg === 'elitnye') {
        priceClassText = {
            accusative: ' элитную',
            plural: ' элитных'
        };
        priceLevelText = {
            accusative: 'элитную',
            plural: 'дорогих',
            nominative: 'элитная',
            genitive: 'элитной'
        };
    }

    const etazhText = seoSearchCommonJoin(
        segment?.floorMax === 1 ? 'на\u00A0первом этаже' : '',
        segment?.floorMax && segment?.floorMin && segment?.floorMax === segment?.floorMin && FLOOR_NAME_MAP.has(segment.floorMax) && FLOOR_NAME_MAP.has(segment.floorMin) ?
            `на\u00A0${FLOOR_NAME_MAP.get(segment.floorMin)} этаже` : '',
        segment?.last === 'ONLY' ? 'на\u00A0последнем этаже' : '',
    );

    let buildEpochText: string;

    const buildEpochSeo = BuildEpochSeoMatcher.$.match({
        categoryType: params.categoryType ?? RealtyObjectType.FLAT,
        dealType: params.dealType as DealType ?? DealType.SELL,
        builtYearMin: segment?.builtYearMin,
        builtYearMax: segment?.builtYearMax
    });

    switch (buildEpochSeo?.seg) {
        case 'stalinka':
            buildEpochText = 'в сталинке';

            break;
        case 'khrushchevka':
            buildEpochText = 'в хрущевке';

            break;
        case 'brezhnevka':
            buildEpochText = 'в брежневке';

            break;
        default:
            buildEpochText = '';
    }

    const branch = geos.find(item => item.id === params.metroBranch);
    const isCircle = branch?.id === 169286 || branch?.id === 169288 || branch?.id === 305;
    const branchText = branch ? `${isCircle ? 'около' : 'на'} ${BRANCH_NAME_MAP.get(branch?.id)}${isCircle ? '' : ' ветке метро'}` : '';
    const fullBranchName = isCircle ? `около ${CIRCLE_FULL_MAP.get(branch?.id)}` : branch?.locative ?? '';

    const prefixCommon = seoSearchCommonJoin(
        newBuildingText.accusative,
        pledgeText,
        seoMortgage,
        authorText,
        renovationText,
        renovationProgramText,
        bathroomText,
        furnitureText,
        buildEpochText,
        conciergeText,
        hasLiftText,
        balconyText,
        parkingText,
        priceSeo?.seg ? '' : priceMaxText,
        rentPaymentText,
        securityText,
        segment?.cellHeight === 'FROM_3' ? 'с\u00A0высокими потолками' : '',
        etazhText,
        segment?.kitchenAreaMin === 15 ? 'с\u00A0большой кухней' : '',
        segment?.nearbyOptions === 'GOOD_SCHOOL' ? 'рядом со\u00A0школой' : '',
        nearMetroText,
        geoText,
        branchText
    );

    const priceLast = priceSeo?.seg === 'nedorogo' ? ' недорого' : '';
    const premiumText = priceSeo?.seg === 'elitnye' ? 'премиум' : '';

    const allCommon = `${seoSearchCommonJoin(
        prefixCommon,
        noClientCommision,
        withoutDeposit,
        regionText,
    )}${petsAllowed.accusative} ${priceLast}`;

    let pageTitle = seoSearchCommonJoin(
        upperFirst(dealTypeText.verb),
        priceClassText.accusative,
        roomsText.alternativeAccusative,
        allCommon
    );

    const descriptionTitle = seoSearchCommonJoin(
        upperFirst(dealTypeText.verb),
        priceClassText.accusative,
        roomsText.accusative,
        allCommon
    ).replace(/городском округе/gi, 'районе');

    let metaTitle = `${descriptionTitle}\u00A0— ${
        offersCountText} о\u00A0${dealTypeText.prepositional} ${
        seoSearchCommonJoin(
            premiumText,
            roomsText.alternativePlural,
            newBuildingText.prepositional,
            furnitureText,
            renovationText,
            petsAllowed.plural,
            buildEpochText,
            fullBranchName,
            premiumText ? '' : 'недорого',
        )}: планировки, цены и\u00A0фото\u00A0— m2.ru`;

    const isMalogabaritnaya = params?.segments?.totalAreaMax === 29 && roomCount < 2;
    let metaDescription = `🏢 ${pageTitle}${additionalRegionText} на\u00A0m2.ru. ➜ В\u00A0каталоге недвижимости ${
        offersCountText} о\u00A0${dealTypeText.prepositional} ${
        seoSearchCommonJoin(
            priceLevelText.plural,
            isMalogabaritnaya ? 'квартир гостиничного типа' : roomsText.plural,
            newBuildingText.prepositional,
            etazhText,
            pledgeText,
            authorText,
            renovationText,
            furnitureText,
            petsAllowed.plural,
            buildEpochText,
            fullBranchName,
            regionText
        )}. ✔ Описание и\u00A0фото. ✔ Отзывы покупателей. ✔ Расположение на\u00A0карте.`;

    if (params.categoryType === RealtyObjectType.ROOM && params.dealType === DealType.SELL && isNonCapitalRegion) {
        pageTitle = seoSearchCommonJoin(
            upperFirst(dealTypeText.noun),
            priceClassText.plural,
            roomsText.alternativePlural,
            allCommon
        );

        metaTitle = `${descriptionTitle} ${premiumText ? '' : 'недорого'}, объявления о\u00A0${dealTypeText.prepositional} ${
            seoSearchCommonJoin(
                premiumText,
                roomsText.alternativePlural,
                newBuildingText.prepositional,
                furnitureText,
                renovationText,
                petsAllowed.plural,
                buildEpochText,
                fullBranchName,
            )} в квартире, планировки, цены и\u00A0фото\u00A0— m2.ru`;

        const description = seoSearchCommonJoin(
            upperFirst(dealTypeText.proposal),
            priceClassText.accusative,
            roomsText.alternativeAccusative,
            allCommon
        );

        metaDescription = `${description}${additionalRegionText} в квартирах на вторичном рынке и в новостройках по доступной цене. В\u00A0каталоге недвижимости ${
            offersCountText} о\u00A0${dealTypeText.prepositional} ${
            seoSearchCommonJoin(
                priceLevelText.plural,
                isMalogabaritnaya ? 'квартир гостиничного типа' : roomsText.plural,
                newBuildingText.prepositional,
                etazhText,
                pledgeText,
                authorText,
                renovationText,
                furnitureText,
                petsAllowed.plural,
                buildEpochText,
                fullBranchName,
                regionText
            )} с описанием объектов, планировок, фото. ✔ Отзывы покупателей. ✔ Расположение на\u00A0карте.`;
    }

    if (isMalogabaritnaya) {
        const additionalTitle = seoSearchCommonJoin(
            upperFirst(dealTypeText.verb),
            priceClassText.accusative,
            'малосемейку или гостинку (КГТ)',
            allCommon
        ).replace(/городском округе/gi, 'районе');

        metaTitle = `${additionalTitle}\u00A0— ${
            offersCountText} о\u00A0${dealTypeText.prepositional} ${
            seoSearchCommonJoin(
                premiumText,
                roomsText.plural,
                newBuildingText.prepositional,
                renovationText,
                furnitureText,
                buildEpochText,
                premiumText ? '' : 'недорого',
            )}: планировки, цены и\u00A0фото\u00A0— m2.ru`;
    }

    const relativeTitle = seoSearchCommonJoin(
        upperFirst(dealTypeText.verb),
        'похожую',
        roomsText.accusative,
        allCommon
    );

    // Костыль для административных округов Москвы
    if (region?.id === RegionIdEnum.MSK) {
        if (/в Центральном административном округе/.test(geoText)) {
            metaTitle = metaTitle.replace(/в Центральном административном округе/gi, 'в центре').replace(/недорого: планировки/gi, 'в ЦАО недорого: планировки');
            metaDescription = metaDescription.replace(/в Центральном административном округе/gi, 'в центре (ЦАО)');
        } else
        if (/в Восточном административном округе/.test(geoText)) {
            metaTitle = metaTitle.replace(/в Восточном административном округе/gi, 'на востоке').replace(/недорого: планировки/gi, 'в ВАО недорого: планировки');
            metaDescription = metaDescription.replace(/в Восточном административном округе/gi, 'на востоке (ВАО)');
        } else
        if (/в Северо-Восточном административном округе/.test(geoText)) {
            metaTitle = metaTitle.replace(/в Северо-Восточном административном округе/gi, 'на северо-востоке').replace(/недорого: планировки/gi, 'в СВАО недорого: планировки');
            metaDescription = metaDescription.replace(/в Северо-Восточном административном округе/gi, 'на северо-востоке (СВАО)');
        } else
        if (/в Юго-Восточном административном округе/.test(geoText)) {
            metaTitle = metaTitle.replace(/в Юго-Восточном административном округе/gi, 'на юго-востоке').replace(/недорого: планировки/gi, 'в ЮВАО недорого: планировки');
            metaDescription = metaDescription.replace(/в Юго-Восточном административном округе/gi, 'на юго-востоке (ЮВАО)');
        } else
        if (/в Западном административном округе/.test(geoText)) {
            metaTitle = metaTitle.replace(/в Западном административном округе/gi, 'на западе').replace(/недорого: планировки/gi, 'в ЗАО недорого: планировки');
            metaDescription = metaDescription.replace(/в Западном административном округе/gi, 'на западе (ЗАО)');
        } else
        if (/в Северо-Западном административном округе/.test(geoText)) {
            metaTitle = metaTitle.replace(/в Северо-Западном административном округе/gi, 'на северо-западе').replace(/недорого: планировки/gi, 'в СЗАО недорого: планировки');
            metaDescription = metaDescription.replace(/в Северо-Западном административном округе/gi, 'на северо-западе (СЗАО)');
        } else
        if (/в Юго-Западном административном округе/.test(geoText)) {
            metaTitle = metaTitle.replace(/в Юго-Западном административном округе/gi, 'на юго-западе').replace(/недорого: планировки/gi, 'в ЮЗАО недорого: планировки');
            metaDescription = metaDescription.replace(/в Юго-Западном административном округе/gi, 'на юго-западе (ЮЗАО)');
        } else
        if (/в Южном административном округе/.test(geoText)) {
            metaTitle = metaTitle.replace(/в Южном административном округе/gi, 'на юге').replace(/недорого: планировки/gi, 'в ЮАО недорого: планировки');
            metaDescription = metaDescription.replace(/в Южном административном округе/gi, 'на юге (ЮАО)');
        }
        if (/Северном административном округе/.test(geoText)) {
            metaTitle = metaTitle.replace(/в Северном административном округе/gi, 'на севере').replace(/недорого: планировки/gi, 'в САО недорого: планировки');
            metaDescription = metaDescription.replace(/в Северном административном округе/gi, 'на севере (САО)');
        }
    }

    return {
        metaTitle,
        metaDescription,
        pageTitle,
        relativeTitle,
        faqTexts: generateFaqTexts({
            prefixSegmentText: priceLevelText,
            segmentText: roomsText,
            commonPostfixText: allCommon,
            segmentFullText: roomsFullText
        })
    };
}

const getFlatRoomCategoryText = (params: SeoSearchCommonProps['params'], roomCount: number, roomType?: Rooms) => {
    // eslint-disable-next-line prefer-const
    let { roomsText, small } = getCommonFlatRoomCategoryText(params, roomCount);

    if (params.categoryType === RealtyObjectType.ROOM) roomsText = {
        accusative: 'комнату',
        plural: 'комнат',
        genitive: 'комнаты',
        nominative: 'комната',
        alternativeAccusative: 'комнату',
        alternativePlural: 'комнат',
    };

    if (params?.segments?.apartments === 'APARTMENTS_ONLY') roomsText = {
        accusative: 'квартиру-апартаменты',
        plural: 'квартир-апартаментов',
        genitive: 'квартиры-апартамента',
        nominative: 'квартира-апартамент',
        alternativeAccusative: 'квартиру-апартаменты',
        alternativePlural: 'квартир-апартаментов',
    };

    if (roomCount > 0 && params.segments?.kitchenAreaMin !== 17) roomsText = {
        accusative: `${roomCount}-комнатную ${roomsText.accusative}`,
        plural: `${roomCount}-комнатных ${roomsText.plural}`,
        genitive: `${roomCount}-комнатной ${roomsText.genitive}`,
        nominative: `${roomCount}-комнатная ${roomsText.nominative}`,
        alternativeAccusative: `${ROOM_TRANSLIT[roomCount]}комнатную ${roomsText.accusative}`,
        alternativePlural: `${ROOM_TRANSLIT[roomCount]}комнатных ${roomsText.plural}`
    };
    if (roomType === Rooms.ROOM_5_AND_MORE) return {
        accusative: `многокомнатную ${roomsText.accusative}`,
        plural: `многокомнатных ${roomsText.plural}`,
        genitive: `многокомнатной ${roomsText.genitive}`,
        nominative: `многокомнатная ${roomsText.nominative}`,
        alternativeAccusative: `многокомнатную ${roomsText.accusative}`,
        alternativePlural: `многокомнатных ${roomsText.plural}`
    };
    if (roomType === Rooms.STUDIO && params?.segments?.apartments === 'APARTMENTS_ONLY') return {
        accusative: 'апартаменты-студию',
        plural: 'апартаментов-студий',
        genitive: 'апартамента-студии',
        nominative: 'апартамент-студия',
        alternativeAccusative: 'апартаменты-студию',
        alternativePlural: 'апартаментов-студий'
    };
    if (roomType === Rooms.STUDIO && params?.segments?.apartments !== 'APARTMENTS_ONLY') return {
        accusative: `${small.accusative}студию`,
        plural: `${small.plural}студий`,
        genitive: `${small.genitive}студий`,
        nominative: `${small.nominative}студия`,
        alternativeAccusative: `${small.accusative}студию`,
        alternativePlural: `${small.plural}студий`,
    };
    if (roomType === Rooms.OPEN_PLAN) return {
        accusative: `${roomsText.accusative} со\u00A0свободной планировкой`,
        plural: `${roomsText.plural} со\u00A0свободной планировкой`,
        genitive: `${roomsText.genitive} со\u00A0свободной планировкой`,
        nominative: `${roomsText.nominative} со\u00A0свободной планировкой`,
        alternativeAccusative: `${roomsText.accusative} со\u00A0свободной планировкой`,
        alternativePlural: `${roomsText.plural} со\u00A0свободной планировкой`,
    };

    return roomsText;
};

/**
 * Метод для предоставления полнотекстового варианта комнатности квартир
 * отдаёт НЕ "1-комнатная", а "однокомнатная" только для 1-4 комнтных.
 * https://jira.m2.ru/browse/RS-7098
*/

function getFlatCategoryFullText(params: SeoSearchCommonProps['params'], roomCount: number) {
    const { roomsText } = getCommonFlatRoomCategoryText(params, roomCount);

    const roomCountText = ROOM_TRANSLIT[roomCount];

    if (roomCount > 0 && params.segments?.kitchenAreaMin !== 17) return {
        accusative: `${roomCountText}комнатную ${roomsText.accusative}`,
        plural: `${roomCountText}комнатных ${roomsText.plural}`,
        genitive: `${roomCountText}комнатной ${roomsText.genitive}`,
        nominative: `${roomCountText}комнатная ${roomsText.nominative}`
    };
}

function getCommonFlatRoomCategoryText(params: SeoSearchCommonProps['params'], roomCount: number) {
    const small = { accusative: '', plural: '', genitive: '', nominative: '' };

    if (params?.segments?.totalAreaMax === 29 && roomCount < 2) {
        small.accusative = 'малогабаритную\u00A0';
        small.plural = 'малогабаритных\u00A0';
        small.genitive = 'малогабаритной\u00A0';
        small.nominative = 'малогабаритная\u00A0';
    }

    let flatText = { accusative: 'квартиру', plural: 'квартир', genitive: 'квартиры', nominative: 'квартира' };

    if (params.segments?.kitchenAreaMin === 17) {
        if (roomCount === 1) flatText = { accusative: 'еврооднушку', plural: 'еврооднушек', genitive: 'еврооднушки', nominative: 'еврооднушка' };
        if (roomCount === 2) flatText = { accusative: 'евродвушку', plural: 'евродвушек', genitive: 'евродвушки', nominative: 'евродвушка' };
        if (roomCount === 3) flatText = { accusative: 'евротрешку', plural: 'евротрешек', genitive: 'евротрешки', nominative: 'евротрешка' };
    }

    return {
        small,
        roomsText: {
            accusative: `${small.accusative}${flatText.accusative}`,
            plural: `${small.plural}${flatText.plural}`,
            genitive: `${small.genitive}${flatText.genitive}`,
            nominative: `${small.nominative}${flatText.nominative}`,
            alternativeAccusative: `${small.accusative}${flatText.accusative}`,
            alternativePlural: `${small.plural}${flatText.plural}`,
        }
    };
}

const FLOOR_NAME_MAP = new Map([
    [ 2, 'втором' ],
    [ 3, 'третьем' ],
    [ 4, 'четвёртом' ],
    [ 5, 'пятом' ],
    [ 6, 'шестом' ],
    [ 7, 'седьмом' ],
    [ 8, 'восьмом' ],
    [ 9, 'девятом' ],
    [ 10, 'десятом' ],
    [ 11, 'одиннадцатом' ],
    [ 12, 'двенадцатом' ],
    [ 13, 'тринадцатом' ],
    [ 14, 'четырнадцатом' ],
    [ 15, 'пятнадцатом' ],
    [ 16, 'шестнадцатом' ],
    [ 17, 'семнадцатом' ],
    [ 18, 'восемнадцатом' ],
    [ 19, 'девятнадцатом' ],
    [ 20, 'двадцатом' ],
]);

const ROOM_TRANSLIT: Record<number, string> = {
    1: 'одно',
    2: 'двух',
    3: 'трёх',
    4: 'четырёх'
};

const BRANCH_NAME_MAP = new Map([
    [ 58, 'зеленой' ],
    [ 4, 'красной' ],
    [ 264, 'серой' ],
    [ 158, 'оранжевой' ],
    [ 101, 'синей' ],
    [ 155, 'фиолетовой' ],
    [ 271, 'салатовой' ],
    [ 234, 'желтой' ],
    [ 305, 'МЦК' ],
    [ 169286, 'МЦД-1' ],
    [ 169288, 'МЦД-2' ]
]);

const CIRCLE_FULL_MAP = new Map([
    [ 305, 'Московского центрального кольца' ],
    [ 169286, 'Белорусско-Савёловского диаметра' ],
    [ 169288, 'Курско-Рижского диаметра' ]
]);
