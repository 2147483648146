import React, { useCallback } from 'react';
import { Select, useSelect } from '@vtblife/uikit';

import classname from '@search/classname/src';
import { TimeLimit, TransportType } from '@search/filter-enums/enums';

import { TransportLimitFilter } from '../../../../models/Filter';
import MobileFilter from '../../../filter/MobileFilter';

import '../MobileCommon.css';
import './styles.css';

interface IMobileTransportLimitFilterViewProps {
    filter: TransportLimitFilter;
    onChange: (filter: TransportLimitFilter) => void;
    isMobile?: boolean;
    isTitle?: boolean;
    containerClass?: string;
}

const cn = classname.bind(null, 'MobileTransportLimitFilterView');

const transportTypeOptions = [
    {
        value: TransportType.UNKNOWN,
        label: 'Неважно'
    },
    {
        value: TransportType.FOOT,
        label: 'Пешком'
    }, {
        value: TransportType.PUBLIC_TRANSPORT,
        label: 'На транспорте'
    }
];

const timeLimitOptions = [
    {
        value: TimeLimit.MAX_5_MINUTES,
        label: '5 мин.'
    },
    {
        value: TimeLimit.MAX_10_MINUTES,
        label: '10 мин.'
    },
    {
        value: TimeLimit.MAX_15_MINUTES,
        label: '15 мин.'
    },
    {
        value: TimeLimit.MAX_20_MINUTES,
        label: '20 мин.'
    },
    {
        value: TimeLimit.MAX_30_MINUTES,
        label: '30 мин.'
    },
    {
        value: TimeLimit.MAX_40_MINUTES,
        label: '40 мин.'
    },
    {
        value: TimeLimit.MAX_50_MINUTES,
        label: '50 мин.'
    },
    {
        value: TimeLimit.MAX_60_MINUTES,
        label: '1 ч.'
    }
];

export const MobileTransportLimitFilterView: React.FC<IMobileTransportLimitFilterViewProps> = ({
    filter,
    onChange,
    isTitle,
    isMobile,
    containerClass
}) => {
    const transportSelectChange = useCallback((value: TransportType) => {
        let timeLimit = filter.timeLimit;

        if (value === TransportType.UNKNOWN) timeLimit = TimeLimit.UNKNOWN;
        else if (timeLimit === TimeLimit.UNKNOWN) timeLimit = TimeLimit.MAX_20_MINUTES;

        onChange(new TransportLimitFilter(
            timeLimit,
            value
        ));
    }, [ filter.timeLimit, onChange ]);

    const transportSelectOptions = transportTypeOptions.map(option => ({ value: option.value, text: option.label }));

    const transportSelect = useSelect(
        transportSelectChange,
        {
            selected: filter.transportType,
            texts: transportSelectOptions
        }
    );

    const timeLimitSelectChange = useCallback((value: TimeLimit) => {
        const transportType = filter.transportType;

        if (transportType === TransportType.UNKNOWN) return;

        onChange(new TransportLimitFilter(
            value,
            transportType
        ));
    }, [ filter.transportType, onChange ]);

    const timeLimitSelectOptions = timeLimitOptions.map(option => ({
        value: option.value,
        text: option.label
    }));

    const timeLimitSelect = useSelect(
        timeLimitSelectChange,
        { selected: filter.timeLimit, texts: timeLimitSelectOptions }
    );

    return (<MobileFilter
        filterTitle={isTitle ? 'Время до метро' : undefined}
        className={cn('container', undefined, containerClass)}
    >
        <Select native={isMobile} mode='single' onChange={transportSelect.handleChange}>
            <Select.Button size='s' dataTest='transportType-trigger'>{transportSelect.selectedText}</Select.Button>
            <Select.Content>
                {transportSelectOptions.map(option => (
                    <Select.Option
                        dataTest={`transportType-${option.value}`}
                        key={option.value}
                        value={option.value}
                        selected={transportSelect.is(option.value)}
                    >
                        {option.text}
                    </Select.Option>
                ))}
            </Select.Content>
        </Select>
        <Select native={isMobile} mode='single' onChange={timeLimitSelect.handleChange}>
            <Select.Button
                dataTest='timeLimit-trigger'
                disabled={filter.transportType === TransportType.UNKNOWN}
                size='s'
            >{timeLimitSelect.selectedText}</Select.Button>
            <Select.Content>
                {timeLimitSelectOptions.map(option => (
                    <Select.Option
                        key={option.value}
                        dataTest={`timeLimit-${option.value}`}
                        value={option.value}
                        selected={timeLimitSelect.is(option.value)}
                    >
                        {option.text}
                    </Select.Option>
                ))}
            </Select.Content>
        </Select>
    </MobileFilter>);
};
