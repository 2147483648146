import {
    s,
    SusaninRoute,
    queryParamtoArray
} from '@search/router/src';
import { Tokens } from '@search/router/src/RouterInterfaces';
import { RouterContext } from '../RouterProvider';

const UnsubscribeType = s.rec({
    sid: s.list(s.str),
    uid: s.str,
    oid: s.opt(s.str)
});

type IUnsubscribeType = ReturnType<typeof UnsubscribeType>;

const UnsubscribeSeoType = s.rec({
    origin: s.nullOpt(s.str),
    sid: s.list(s.str),
    uid: s.str,
    oid: s.opt(s.str)
});

type IUnsubscribeSeoType = ReturnType<typeof UnsubscribeSeoType>;

export class UnsubscribeRoute extends SusaninRoute<IUnsubscribeSeoType, IUnsubscribeType, RouterContext> {
    displayName = 'UnsubscribeRoute';

    protected metadata = UnsubscribeSeoType.config;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected pattern(p: Tokens<IUnsubscribeSeoType>) {
        return `${this.patternHost()}/search/unsubscribe/`;
    }

    defaults() {
        return {};
    }

    protected fromQuery(seo: IUnsubscribeSeoType) {
        return UnsubscribeType({
            sid: queryParamtoArray(seo.sid),
            oid: seo.oid,
            uid: seo.uid
        });
    }

    protected toQuery({ sid, uid, oid }: IUnsubscribeType) {
        try {
            return UnsubscribeSeoType({
                sid,
                uid,
                oid
            });
        } catch (e) {
            // @ts-expect-error
            e.message += `, searchId: ${sid}`;
            throw e;
        }
    }
}
