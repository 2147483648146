import React, { useCallback, useRef } from 'react';

import { MortgagePurpose } from '@search/graphql-typings';
import { formatPrice } from '@search/helpers/src/gql/formatPrice';

import { useM2AuthContext } from '@search/auth/src/M2AuthProvider';
import { AuthInfo } from '@search/auth/src/AuthInfo';
import { UseAuthError } from '@search/auth/src/useAuth';
import { useUserDetailsFetcher } from '@search/auth/src/userDetails/userDetails';
import { userDetailsQuery$data } from '@search/auth/src/userDetails/__generated__/userDetailsQuery.graphql';

import { declensionBankGenitive } from '../declensions';
import { defaultBanksTotal } from '../contants';
import { useGtmOnVisible } from '../../../../../domain/google/useGtmOnVisible';
import { ClassifiedAnalytics } from '../../../../../domain/offer/analytics/ClassifiedAnalytics';
import { useClassifiedOfferDataLayerPush } from '../../../../../domain/offer/analytics/ClassifiedOfferGtmContext';
import { minimalBanksMortgageUrlCreate } from '../minimalBanksMortgageUrl';
import { SeoSearchUtm } from '../../../../../domain/seo/SeoSearchUtm';
import { useAppTypeById } from '../../../hooks/useAppTypeById';
import type {
    MinimalBanksMortgageOfferWithDiscount
} from '../MinimalBanksMortgageOffers/MinimalBanksMortgageOffers';
import { declensionHour, declensionMinute } from '../declensions';
import { useMortgageAppSource } from '../MortgageAppSourceContext';
import { mortgage2ParamsFromClasified } from './mortgage2ParamsFromClasified';
import { Mortgage2StarterProps, useMortgage2UserForm } from './useMortgage2User';
import { getRealtyObjectTypeGql, getSubjectAppName, Mortgage2ContactsFormSendProps, normalizeCallbackUrl, useMortgage2ContactsFormSend } from './mortgage2ContactsFormSend';
import type { Mortgage2RealtyType } from './mortgageTypes';

const openOrAuth = (authInfo?: AuthInfo) => {
    return new Promise((resolve, reject) => {
        if (authInfo?.isAuthenticated) {
            resolve(null);
        } else {
            reject();
        }
    });
};

function getOverPayment(p: {
    minimalPercent: number;
    totalMonth: number;
    amount: number;
}) {
    const monthRate = p.minimalPercent / 12 / 100;
    const commonRate = (1 + monthRate) ** p.totalMonth;
    const monthPayment = p.amount * monthRate * commonRate / (commonRate - 1);
    const overPayment = monthPayment * p.totalMonth - p.amount;

    return overPayment;
}

const textVariants = [ 'main', 'bankCount' ] as const;
const mortgageBannerTextVariantDefault = Math.round(Math.random() * (textVariants.length - 1));

const responseTimeVariants = [
    {
        value: 1,
        fieldText: 'на ответ от банков'
    },
    {
        value: 10,
        fieldText: 'на заполнение заявки'
    },
    {
        value: 180,
        fieldText: 'на одобрение от банков'
    }
] as const;

const mortgageBannerMinutesVariantDefault = Math.round(Math.random() * (responseTimeVariants.length - 1));

export function windowOpen(url: string, { isMobile }: { isMobile?: boolean }) {
    url = url.startsWith('http') ? url : location.origin + url;

    if (isMobile) {
        location.assign(url);
        return;
    }

    let form = document.getElementById('window-open-form') as HTMLFormElement;

    if (! form) {
        form = document.createElement('form');
        document.body.appendChild(form);
    }

    const o = new URL(url);

    form.method = 'get';
    form.target = '_blank';
    form.action = o.origin + o.pathname;
    form.id = 'window-open-form';

    const inputs: string[] = [];

    o.searchParams.forEach((v, k) => {
        inputs.push(`<input type="hidden" name="${k}" value=${v} />`);
    });
    form.innerHTML = inputs.join('\n');

    form.submit();
}

export function useMortgage2Request({
    regionId,
    showPreferential,
    mortgageRegionId,
    selectedMortgageOffer,
    bestMortgageOffer,
    purpose,
    term,
    propertyCost,
    ownAmount,
    gtmSendId = ClassifiedAnalytics.SearchSaleIpotekaBannerSend,
    gtmShowId,
    gtmSendData,
    gtmShowData,
    mortgageBannerMinutesVariant = mortgageBannerMinutesVariantDefault,
    mortgageBannerTextVariant = mortgageBannerTextVariantDefault,
    banksTotal = defaultBanksTotal,
    isMobile = false,
    realtyType,
    userDetails,
    locationPlacement,
    offerUrl,
    applicationId,
    withoutAuth,
    withoutForm,
    discounts,
    shouldVTBDiscountLogic
}: Mortgage2StarterProps & {
    showPreferential?: boolean;
    userDetails?: userDetailsQuery$data['userProfile']['person'];
    isMobile?: boolean;
    realtyType: Mortgage2RealtyType;
    banksTotal?: number;
    selectedMortgageOffer?: MinimalBanksMortgageOfferWithDiscount;
    bestMortgageOffer?: MinimalBanksMortgageOfferWithDiscount;
    propertyCost: number;
    ownAmount: number;
    withoutAuth?: boolean;
    withoutForm?: boolean;
    shouldVTBDiscountLogic?: boolean;
} & Mortgage2ContactsFormSendProps) {
    const mortgage2ContactsFormSend = useMortgage2ContactsFormSend();
    const {
        isFormSended,
        isFormSyncing,
        setFormAllTouched,
        setFormSended,
        setFormIsSending,
        setFormResetSendingsFields,

        name,
        isNameValid,
        isNameRequired,
        nameInputValid,
        setName,
        setNameTouched,

        phone,
        startHourValues,
        endHourValues,
        workingHoursRange,
        setStartHoursRange,
        setEndHoursRange,
        isPhoneValid,
        phoneInputValid,
        setPhone,
        setPhoneTouched
    } = useMortgage2UserForm({ userDetails });

    let realtyTypeFormattedLocative = 'недвижимости';

    switch (realtyType) {
        case 'ProfOfferFlat':
            realtyTypeFormattedLocative = 'квартиры';
            break;
        case 'ProfOfferRoom':
            realtyTypeFormattedLocative = 'комнаты';
            break;
        case 'ProfOfferHouse':
            realtyTypeFormattedLocative = 'дома';
            break;
        case 'ProfOfferLand':
            realtyTypeFormattedLocative = 'участка';
            break;
        default:
            realtyTypeFormattedLocative = 'недвижимости';
    }

    const params = mortgage2ParamsFromClasified({
        purpose,
        regionId,
        mortgageRegionId,
        term,
        propertyCost,
        ownAmount
    });

    const propertyCostFormatted = formatPrice({
        value: params.propertyCost * 100,
        currency: params.currency,
        displayCurrency: true
    });

    const ownAmountFormatted = formatPrice({
        value: params.ownAmount * 100,
        currency: params.currency,
        displayCurrency: true
    });

    const minimalPercentDefault = purpose === MortgagePurpose.Primary ? 6.1 : 7.5;

    const offerForPercent = selectedMortgageOffer ?? bestMortgageOffer;
    const minimalPercentWithDiscount = offerForPercent?.minimalPercentDiscount ?? offerForPercent?.minimalPercent;
    const minimalPercent = minimalPercentWithDiscount ?? minimalPercentDefault;

    let minimalPercentOld = 0;

    if (shouldVTBDiscountLogic) {
        if (offerForPercent?.minimalPercentDiscount) minimalPercentOld = offerForPercent?.minimalPercent ?? 0;
    } else {
        minimalPercentOld = selectedMortgageOffer ? 0 : minimalPercent; // тут должна быть скидка от сервиса, когда появится
    }

    // Вернется скидка от сервиса - можно раскомментировать
    // const amount = params.propertyCost - params.ownAmount;
    // const totalMonth = params.term * 12;
    // const overPayment = getOverPayment({ amount, totalMonth, minimalPercent });
    // const overPaymentOld = getOverPayment({ amount, totalMonth, minimalPercent: minimalPercentOld });
    // const paymentDelta = Math.round(overPaymentOld - overPayment);
    // const paymentDeltaFormatted = formatPrice({
    //     value: paymentDelta * 100,
    //     currency: params.currency,
    //     displayCurrency: true
    // });

    const { waitAuth } = useM2AuthContext();

    const userDetailsFetcher = useUserDetailsFetcher();
    const userFioPhone = useRef<ReturnType<typeof updateUserDetails> | null>();
    const updateUserDetails = useCallback((userInfo: userDetailsQuery$data) => {
        const userDetails = userInfo?.userProfile.person;
        const nameArr = [
            userDetails?.surname, userDetails?.name, userDetails?.patronymic
        ].filter(Boolean);

        userDetails?.primaryPhone?.number && setPhone(userDetails.primaryPhone.number);
        nameArr.length > 0 && setName(nameArr.join(' '));

        return {
            phone: userDetails?.primaryPhone?.number,
            name: nameArr.join(' ')
        };
    });

    const dataLayerPush = useClassifiedOfferDataLayerPush();

    const { isClApp } = useAppTypeById(applicationId);
    const sourceMortgage = useMortgageAppSource();
    const sendQuery = React.useCallback((e?: React.MouseEvent<Element> | React.KeyboardEvent<HTMLInputElement>) => {
        e?.stopPropagation();
        e?.preventDefault();

        // RS-3869 remove - ! isMobile &&
        // withoutForm for force to auth form after click btn
        if (! withoutForm && (! isPhoneValid || (isNameRequired && ! isNameValid))) {
            setFormAllTouched();
            return;
        }

        setFormIsSending();
        // windowOpen(minimalBanksMortgageUrl, { isMobile });

        const utm = SeoSearchUtm.get();

        const resolveResultPromise = () => {
            setFormSended();
            gtmSendId && dataLayerPush({ event: gtmSendId });
            gtmSendData && dataLayerPush({
                ...gtmSendData,
                place: selectedMortgageOffer ? 'bank' : gtmSendData.place
            });
            if (isClApp &&
                (selectedMortgageOffer?.type === 'PREFERENTIAL' ||
                selectedMortgageOffer?.type === 'FAMILY')
            ) {
                dataLayerPush({
                    event: 'up_action',
                    theme: 'ipoteka',
                    type: selectedMortgageOffer.type === 'PREFERENTIAL' ? 'gospr_benefit_req' : 'gospr_family_req'
                });
            } else if (isClApp) {
                dataLayerPush({ event: 'up_ib_send_form' });
            }

            const { fullUrl: minimalBanksMortgageUrl } = minimalBanksMortgageUrlCreate({
                isAuthenticated: true,
                ...params,
                mobilePhone: (userFioPhone.current?.phone ?? phone).substring(1),
                fio: userFioPhone.current?.name ?? name,
                source: sourceMortgage
            });

            ! withoutAuth && windowOpen(minimalBanksMortgageUrl, { isMobile });
        };
        const rejectedResultPromise = error => {
            // eslint-disable-next-line no-console
            console.error(error);
            setFormSended(error);
        };
        const optionalMortgage2ContactsFormSend = (userDataFromPromise?: { name?: string; phone?: string} | null) => {
            return mortgage2ContactsFormSend({
                realtyType: getRealtyObjectTypeGql(realtyType),
                name: userDataFromPromise?.name ?? name,
                utm: utm ? { list: utm } : undefined,
                phone: userDataFromPromise?.phone ?? phone,
                regionId: mortgageRegionId ?? regionId,
                applicationId,
                locationPlacement,
                link: normalizeCallbackUrl(offerUrl),
                subject: `Заявка на ипотеку с${showPreferential ? ' гос. поддержкой с' : ''} “${
                    getSubjectAppName(applicationId)}”`,
                discounts,
                startCallbackHour: workingHoursRange.start,
                endCallbackHour: workingHoursRange.end,
                message: selectedMortgageOffer ? `Банк: ${selectedMortgageOffer.name}` : '',
                withGA: isClApp && ! withoutAuth,
                withoutBackofficeEmail: isClApp && ! withoutAuth
            });
        };

        const optionalWaitAuth = withoutAuth ? undefined :
            waitAuth(
                authInfo => openOrAuth(authInfo)
                    .then(() => userDetailsFetcher())
                    .catch(() => {
                        throw new UseAuthError();
                    }),
                { phone: phone.substring(1), continueAfterLogin: true }
            );

        if (optionalWaitAuth) {
            optionalWaitAuth
                ?.then((dataUserDetails) => {
                    const preparedUserDetails = userFioPhone.current = dataUserDetails.data && updateUserDetails(dataUserDetails.data);

                    return optionalMortgage2ContactsFormSend(preparedUserDetails);
                })
                .then(resolveResultPromise)
                .catch(rejectedResultPromise);
        } else {
            optionalMortgage2ContactsFormSend()
                .then(resolveResultPromise)
                .catch(rejectedResultPromise);
        }
    }, [
        withoutForm,
        showPreferential,
        isPhoneValid,
        isNameValid,
        isNameRequired,
        setFormIsSending,
        mortgage2ContactsFormSend,
        realtyType,
        name,
        phone,
        mortgageRegionId,
        regionId,
        applicationId,
        locationPlacement,
        offerUrl,
        discounts,
        workingHoursRange.start,
        workingHoursRange.end,
        selectedMortgageOffer,
        withoutAuth,
        waitAuth,
        setFormAllTouched,
        userDetailsFetcher,
        setFormSended,
        gtmSendId,
        dataLayerPush,
        sourceMortgage,
        isMobile
    ]);

    const sendQueryOnEnter = React.useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key !== 'Enter') return;
        sendQuery(e);
    }, [ sendQuery ]);

    const textVariant = textVariants[mortgageBannerTextVariant] ?? textVariants[0];

    const minutes = responseTimeVariants[mortgageBannerMinutesVariant] ?? responseTimeVariants[0];
    const hours = Math.floor(minutes.value / 60);
    const responseTimeFormatted = hours >= 1 ?
        `${hours} ${declensionHour(hours)}` :
        `${minutes.value} ${declensionMinute(minutes.value)}`;
    const responseDescriptionFormatted = minutes.fieldText;

    const banksTotalFormattedGenitive = declensionBankGenitive(banksTotal >= 22 ? banksTotal : defaultBanksTotal);
    const timeFromBanksFormatted = 'минуты';

    const onVisible = React.useCallback(() => {
        gtmShowId && dataLayerPush({ event: gtmShowId });
        gtmShowData && dataLayerPush(gtmShowData);
    }, [ gtmShowId, dataLayerPush ]);

    const containerRef = React.useRef<HTMLDivElement>(null);

    useGtmOnVisible({ containerRef, onVisible });

    return {
        containerRef,

        timeFromBanksFormatted,
        textVariant,
        banksTotal,
        banksTotalFormattedGenitive,
        ownAmountFormatted,
        propertyCostFormatted,
        responseTimeFormatted,
        responseDescriptionFormatted,
        minimalPercent,
        minimalPercentOld,

        realtyTypeFormattedLocative,

        setPhoneTouched,
        sendQueryOnEnter,
        phoneInputValid,
        setPhone,
        phone,
        workingHoursRange,
        setStartHoursRange,
        setEndHoursRange,
        startHourValues,
        endHourValues,

        setNameTouched,
        nameInputValid,
        setName,
        name,

        isFormSyncing,
        isFormSended,
        sendQuery,
        setFormResetSendingsFields
    };
}
