import { s } from '@search/router/src/schema';
import { Tokens } from '@search/router/src/RouterInterfaces';
import { numToStr, strToNum } from '@search/router/src/schema/strToNum';

import { NewBuildingRoute } from './NewBuildingRoute';

export const DeveloperType = s.rec({
    id: s.num,
    name: s.str,
    region: s.nullOpt(s.num),
    pageNumber: s.nullOpt(s.num)
});

export type IDeveloperType = ReturnType<typeof DeveloperType>

export const DeveloperSeoType = s.rec({
    origin: s.nullOpt(s.str),
    id: s.str,
    name: s.str,
    region: s.nullOpt(s.str),
    pageNumber: s.nullOpt(s.str)
});

export type IDeveloperSeoType = ReturnType<typeof DeveloperSeoType>

export const developerDefaults = {} as const;

export class DeveloperRoute extends NewBuildingRoute<
    IDeveloperSeoType,
    IDeveloperType,
    typeof developerDefaults
> {
    displayName = 'DeveloperRoute';

    protected metadata = DeveloperSeoType.config;

    protected pattern(params: Tokens<IDeveloperSeoType>) {
        return `${this.patternHost()}(/${params.region})/novostroyki/zastroyshik-${params.name}-${params.id}/`;
    }

    protected conditions() {
        return {
            ...super.conditions(),
            id: '\\d+'
        };
    }

    defaults() {
        return developerDefaults;
    }

    protected fromQuery(seoParams: IDeveloperSeoType) {
        seoParams = DeveloperSeoType(seoParams);

        const region = this.regionId(seoParams.region);

        return DeveloperType({
            region: region === this.defaultRegion().id ? undefined : region,
            id: Number(seoParams.id),
            name: seoParams.name,
            pageNumber: strToNum(seoParams.pageNumber)
        });
    }

    protected toQuery(params: IDeveloperType) {
        const parsedParams = DeveloperType(params);

        const result = this.regionParams(parsedParams.region);
        const region = result?.region;

        return DeveloperSeoType({
            ...result,
            region: region === this.defaultRegion().translit ? undefined : region,
            name: parsedParams.name,
            id: String(parsedParams.id),
            pageNumber: numToStr(parsedParams.pageNumber)
        });
    }
}

export class WLDeveloperRoute extends DeveloperRoute {
    displayName = 'WLDeveloperRoute';

    protected pattern(params: Tokens<IDeveloperSeoType>) {
        return `${this.patternHost()}/zastroyshik-${params.name}-${params.id}/`;
    }

    protected fromQuery(seoParams: IDeveloperSeoType) {
        return {
            ...super.fromQuery(seoParams),
            region: strToNum(seoParams.region)
        };
    }

    protected toQuery(params: IDeveloperType) {
        return {
            ...super.toQuery(params),
            region: numToStr(params.region),
            origin: undefined
        };
    }
}
