import React, { useCallback } from 'react';
import CheckboxGroup from '@search/vtbeco-ui/src/components/controls/CheckboxGroup';
import Checkbox from '@search/vtbeco-ui/src/components/controls/Checkbox';

export type MultiSelectValue = string[] | number[] | null;

export interface MultiSelectOption<Value> {
    label: string;
    value: Value;
    className?: string;
    disabled?: boolean;
    description?: string;
}

export function MultiSelect<Value, Option extends MultiSelectOption<Value>>({
    value,
    name,
    className,
    dataTest,
    options,
    handleChange: onChange
}: {
    value?: Value[];
    name: string;
    dataTest?: string;
    className?: string;
    options: Option[];
    handleChange: (value: Value[], oldValue?: Value[]) => void;
}) {
    const handleChange = useCallback((newValue: Value[] | null) => {
        if (newValue === null) {
            return;
        }

        onChange(newValue, value);
    }, [ onChange, value ]);

    const component =
        options.map(({
            label,
            value: optionValue,
            className: optionClassname,
            description,
            disabled,
            ...rest
        }) => {
            return (<Checkbox
                width='max'
                disabled={disabled}
                type='button'
                view='primary'
                tone='secondary'
                key={String(optionValue)}

                // @ts-ignore
                value={optionValue}
                className={optionClassname}
                {...rest}
            >
                {label}
            </Checkbox>);
        });

    return (
        <CheckboxGroup
            className={className}
            dataTest={dataTest}
            mode='check'
            // @ts-ignore
            onChange={handleChange}
            name={name}
            // @ts-ignore
            value={value}>
            {component}
        </CheckboxGroup>
    );
}
