import { RefObject, MutableRefObject } from 'react';

export const mergeRefs = <TElement extends HTMLElement>(
    source?: MutableRefObject<HTMLElement | undefined>,
    ...targets: (MutableRefObject<HTMLElement | undefined> | undefined)[]
) => {
    if (typeof requestAnimationFrame !== 'undefined') {
        /* eslint-env browser */
        requestAnimationFrame(() => {
            targets.forEach(target => {
                if (source && target) {
                    target.current = source.current;
                }
            });
        });
    }

    return source as RefObject<TElement>;
};

export default mergeRefs;
