import { useRef, useCallback, useEffect, MutableRefObject } from 'react';

type Timeout = ReturnType<typeof setTimeout>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function useDebouncedCallback<T extends(...args: any[]) => any>(
    callback: T,
    delay: number
): [T, () => void] {
    const maxWaitArgs: { current: any[] } = useRef([]);

    const functionTimeoutHandler: MutableRefObject<Timeout|null> = useRef(null);
    const isComponentUnmounted: { current: boolean } = useRef(false);

    const debouncedFunction = useRef(callback);

    debouncedFunction.current = callback;

    const cancelDebouncedCallback: () => void = useCallback(() => {
        clearTimeout(functionTimeoutHandler.current!);
        maxWaitArgs.current = [];
        functionTimeoutHandler.current = null;
    }, []);

    useEffect(
        () => () => {
            // для вызова callPending вне hook'а
            isComponentUnmounted.current = true;
        },
        []
    );

    const debouncedCallback = useCallback(
        // @ts-ignore
        (...args) => {
            maxWaitArgs.current = args;
            clearTimeout(functionTimeoutHandler.current!);

            functionTimeoutHandler.current = setTimeout(() => {
                cancelDebouncedCallback();

                if (! isComponentUnmounted.current) {
                    debouncedFunction.current(...args);
                }
            }, delay);
        },
        [ delay, cancelDebouncedCallback, functionTimeoutHandler ]
    );

    // Вызов debouncedFunction в ручную, например при unmount не дожидаясь таймера
    const callPending = useCallback(() => {
        if (! functionTimeoutHandler.current) {
            return;
        }

        debouncedFunction.current.apply(null, maxWaitArgs.current);
        cancelDebouncedCallback();
    }, [ functionTimeoutHandler, debouncedFunction, cancelDebouncedCallback ]);

    return [ debouncedCallback as T, callPending ];
}
