export function dedupeValues(o: Record<string, number>) {
    const keys = Object.keys(o) as (keyof typeof o)[];
    const result = new Set<number>();

    for (const key of keys) {
        if (key.match(/\d$/)) continue;

        result.add(o[key]);
    }

    return Array.from(result);
}
