import React, { useCallback, useMemo, useState } from 'react';

import { Select, useSelect } from '@vtblife/uikit';

import { useGeoUserRegion } from '@search/geo/src/useGeoUserRegion';
import { RegionIdEnum } from '@search/filter-enums/enums/Region';
import classname from '@search/classname/src';

import defaultRegionSelectOptions from '../../../../../../dict/filters/regions';

import {
    getFilteredFlatRegionSelectOptions,
    getSelectedOptionLabel
} from '../../../../helpers/regionHelper';
import { RegionFilter } from '../../../../models/Filter';
import MobileFilter from '../../../filter/MobileFilter';
import {
    RenderRegionSelectUikitContent,
    MIN_OPTIONS_NUMBER_WITH_INPUT,
    OptionTypeRegionFilter, GroupedOptions,
} from '../../region/RenderRegionSelectUikitContent';

import '../MobileCommon.css';

interface IMobileRegionFilterViewProps {
    filter: RegionFilter;
    onChange: (filter: RegionFilter) => void;
    options?: OptionTypeRegionFilter[] | GroupedOptions<OptionTypeRegionFilter>[];
    isDesktop?: boolean;
    native?: boolean;
    onMap?: boolean;
}

const cn = classname.bind(null, 'MobileRegionFilterView');

const defaultOptions = defaultRegionSelectOptions.map(option => ({
    ...option,
    value: String(option.value)
}));

export const MobileRegionFilterView: React.FC<IMobileRegionFilterViewProps> = ({
    filter,
    onChange,
    isDesktop,
    options = defaultOptions,
    native,
    onMap,
}) => {
    const { setRegionId } = useGeoUserRegion();
    const handleChange = useCallback((newValue: string) => {
        const parsedValue = parseInt(newValue, 10) as RegionIdEnum;

        setRegionId(parsedValue);
        onChange(new RegionFilter(parsedValue));
    }, [ onChange, setRegionId ]);

    const [ searchInputValue, setSearchInputValue ] = useState('');
    const resetSearchInputValue = useCallback(() => setSearchInputValue(''), []);

    const value = String(filter.region || RegionIdEnum.MSK_AND_MSK_OBL);

    const title = useMemo(
        () => getSelectedOptionLabel(value, options),
        [ value, options ]
    );

    let currentOptions = options;

    if (searchInputValue) {
        currentOptions = getFilteredFlatRegionSelectOptions(searchInputValue, options);
    }

    const linearOptionsList = getFilteredFlatRegionSelectOptions(searchInputValue, options);
    const prepared = linearOptionsList.map(option => ({ value: option.value, text: option.label }));

    const { handleChange: handleSelectChange, is, selectedText } = useSelect(
        handleChange,
        { selected: value, texts: prepared }
    );

    const [ filterValue, setFilterValue ] = React.useState('');
    const updateFilterValue = React.useCallback((v: string) => {
        setFilterValue(v);
    }, []);
    const filterValueNormalized = filterValue.toLowerCase().replace('ё', 'е');

    const filtered = linearOptionsList.filter(option => ! filterValueNormalized ||
        option.label.toLowerCase().replace('ё', 'е').includes(filterValueNormalized)
    );

    if (isDesktop && ! native) {
        return (
            <Select
                mode='single'
                dataTest='region'
                onChange={handleSelectChange}
                onToggle={() => {
                    resetSearchInputValue();
                }}
            >
                <Select.Button size='s'>{selectedText}</Select.Button>
                <Select.Content placement='local' height={400}>
                    <Select.Search value={filterValue} onChange={updateFilterValue} />
                    {filtered.map((option, index, all) => (
                        <Select.Option
                            dataTest={`region-${option.value}`}
                            disabled={option.disabled}
                            key={option.value}
                            value={option.value}
                            selected={is(option.value)}
                            divider={all[index + 1]?.isParent}
                        >
                            {option.isParent ? <b>{option.label}</b> : option.label}
                        </Select.Option>
                    ))}
                </Select.Content>
            </Select>
        );
    }

    return (
        <MobileFilter className={cn()}>
            <div className='container'>
                <div className='col-xs-12'>
                    <Select
                        mode='single'
                        dataTest='region'
                        onChange={handleSelectChange}
                        onToggle={() => {
                            resetSearchInputValue();
                        }}
                    >
                        <Select.Button size='s'>{title}</Select.Button>
                        <Select.Content
                            adaptiveHeight={true}
                            adaptivePlacement={false}
                            placement='local'
                        >
                            <RenderRegionSelectUikitContent
                                is={is}
                                searchInputValue={searchInputValue}
                                setSearchInputValue={setSearchInputValue}
                                showInput={options.length >= MIN_OPTIONS_NUMBER_WITH_INPUT}
                                options={currentOptions}
                                isMobile={! onMap}
                            />
                        </Select.Content>
                    </Select>
                </div>
            </div>
        </MobileFilter>
    );
};
