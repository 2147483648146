import React, { CSSProperties, HTMLAttributes } from 'react';
import classname from '@search/classname/src';

import './Icon.css';

export interface IIconProps extends HTMLAttributes<HTMLSpanElement> {
    /**
     * Тип иконки
     */
    type?: string;

    /**
     * Размер иконки
     */
    size?: 'xs' | 's' | 'm' | 'l';

    /**
     * Стили иконки
     *
     * @default {}
     */
    style?: CSSProperties;

    /**
     * Адрес иконки
     */
    url?: string;

    /**
     * Цвет иконки
     */
    color?: string;
}

export const cnIcon = classname.bind(null, 'Icon');

const Icon = ({
    size,
    url,
    type,
    style = {},
    children,
    color,
    ...props
}: IIconProps): React.ReactElement => {
    const className = cnIcon(null, { size, type, color }, props.className);

    if (url !== undefined) {
        style.backgroundImage = `url('${url}')`;
    }

    return (
        <span
            {...props}
            aria-hidden
            className={className}
            style={style}
        >
            {children}
        </span>
    );
};

export default Icon;
