import { TouchEvent, MouseEvent, useCallback, useState } from 'react';

type SwipeStateType = {
    touchStart: number;
    touchEnd: number;
    isSwipe: boolean;
};

const MIN_SWIPE_DIST = 60;

export const useSwiper = ({
    onSwipeLeft,
    onSwipeRight
}: {
    onSwipeLeft: (e?: MouseEvent) => void;
    onSwipeRight: (e?: MouseEvent) => void;
}): {
    handleTouchStart?: (e: TouchEvent) => void;
    handleTouchMove?: (e: TouchEvent) => void;
    handleTouchEnd?: () => void;
} => {
    const [ state, setState ] = useState<SwipeStateType>({
        touchStart: 0,
        touchEnd: 0,
        isSwipe: false
    });

    const handleTouchStart = useCallback((e: TouchEvent) => {
        const { clientX } = e.targetTouches[0];

        setState(prevState => ({
            ...prevState,
            touchStart: clientX
        }));
    }, []);

    const handleTouchMove = useCallback((e: TouchEvent) => {
        const { clientX } = e.targetTouches[0];

        setState(prevState => ({
            ...prevState,
            isSwipe: true,
            touchEnd: clientX
        }));
    }, []);

    const handleTouchEnd = useCallback(() => {
        if (! state.isSwipe) {
            return;
        }

        const dist = state.touchStart - state.touchEnd;

        if (dist > MIN_SWIPE_DIST) {
            onSwipeLeft();
        }

        if (dist < -MIN_SWIPE_DIST) {
            onSwipeRight();
        }

        setState(prevState => ({
            ...prevState,
            isSwipe: false
        }));
    }, [ state, onSwipeLeft, onSwipeRight ]);

    return {
        handleTouchStart,
        handleTouchMove,
        handleTouchEnd
    };
};
