import React from 'react';
import { IntersectionTriggerProps, IntersectionTrigger, useIntersectionTrigger } from '../image/useIntersectionTrigger';

const GtmOnVisibleContext = React.createContext({
    it: new IntersectionTrigger({ threshold: 1 })
});

function useGtmOnVisibleContext() {
    return React.useContext(GtmOnVisibleContext);
}

export function useGtmOnVisible<El extends HTMLElement>({
    containerRef,
    onVisible
}: {
    containerRef?: React.RefObject<El>;
    onVisible(el: El): void;
}) {
    const { it } = useGtmOnVisibleContext();

    React.useEffect(() => {
        const el = containerRef?.current;

        if (! el) return;

        it.add(el, { onVisible });

        return () => it.remove(el);
    }, [ it, containerRef, onVisible ]);
}

export type GtmOnVisibleProviderProps = IntersectionTriggerProps & { children?: React.ReactNode };

export function GtmOnVisibleProvider({ rootMargin, rootRef, threshold, children }: GtmOnVisibleProviderProps) {
    const { it: parent } = useGtmOnVisibleContext();
    const it = useIntersectionTrigger({ rootMargin, rootRef, threshold, parent });

    const value = React.useMemo(() => ({
        it
    }), [ it ]);

    return <GtmOnVisibleContext.Provider value={value}>{children}</GtmOnVisibleContext.Provider>;
}

