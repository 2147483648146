import React from 'react';
import { forwardRef, ReactNode, memo, ReactChild, MouseEventHandler } from 'react';
import classname from '@search/classname/src';

import './styles.css';

export type FilterSelectButtonBorderSide = 'square' | 'rounded_square' | 'square_rounded';
export type FilterSelectSize = 'm' | 'l' | 'xl';

interface FilterSelectButtonProps {
    dataTest?: string;
    children: ReactChild | undefined;
    icon?: ReactNode;
    focused?: boolean;
    className?: string;
    size?: FilterSelectSize;
    side?: FilterSelectButtonBorderSide;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    style?: object;
}

const cnFilterSelectButton = classname.bind(null, 'FilterSelectButton');

export const FilterSelectButton = memo(
    forwardRef<HTMLButtonElement, FilterSelectButtonProps>(({
        focused,
        side,
        icon,
        size,
        className,
        dataTest,
        ...props
    }, ref) => {
        return (
            <button
                {...props}
                ref={ref}
                className={cnFilterSelectButton(null, {
                    side,
                    size,
                    focused
                }, className)}
                data-test={dataTest}
            >
                <span className={cnFilterSelectButton('text')}>
                    {props.children}
                </span>
                {icon}
            </button>
        );
    })
);
