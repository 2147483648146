/* eslint-disable max-len */
import React, { RefObject, useState, useRef, FormEvent, useEffect, ReactNode, MutableRefObject } from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import useHoverable from '@search/hooks/src/useHoverable';
import useFocusable from '@search/hooks/src/useFocusable';

import classname from '@search/classname/src';
import mergeRefs from '../../../lib/mergeRefs';

import {
    InputControl,
    IInputControlProps
} from './InputControl/InputControl';

import './input.css';

export type InputMode = 'number' | 'text' | 'mask';

interface IInputProps extends Omit<IInputControlProps, 'onChange' | 'size'> {

    /**
     * Ссылка на корневой DOM-элемент компонента.
     */
    innerRef?: RefObject<HTMLElement>;

    /**
     * Ссылка на DOM элемент нативного инпута.
     */
    controlRef?: MutableRefObject<HTMLInputElement>;

    onChange?: (value: string, opts: object) => void;

    /**
     * Дополнительный контент до инпута
     */
    extraBefore?: ReactNode;

    /**
     * Дополнительный контент после инпута
     */
    extraAfter?: ReactNode;

    mode?: InputMode;

    size?: string;

    width?: string;

    borderSide?: 'square' | 'rounded_square' | 'square_rounded';
    clear?: boolean;

    borderOut?: boolean;

    maskProps?: Omit<NumberFormatProps, 'value' | 'onChange'>;
}

export const cnInput = classname.bind(null, 'Input');

const Input = ({
    autoComplete = 'off',
    className,
    name,
    value = '',
    disabled,
    width,
    size = 's',
    innerRef,
    controlRef,
    extraAfter,
    extraBefore,
    mode,
    maskProps,
    borderOut = false,
    borderSide,
    clear = false,
    ...props
}: IInputProps): React.ReactElement => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const [ hovered, hoverRef ] = useHoverable();
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const [ focused, focusRef ] = useFocusable();
    const inputRef = useRef<HTMLElement>();

    const [ currentValue, setValue ] = useState(value);

    useEffect(() => {
        if (currentValue !== value) {
            setValue(value);
        }
    }, [ value, currentValue ]);

    /*
     * Изменение значения в поле
     * @param value
     */
    const changeValue = (val: string) => {
        setValue(val);
        // eslint-disable-next-line
        props.onChange && props.onChange(val, { name });
    };

    const handleClear = () => {
        changeValue('');
    };

    /*
     * Событие на изменение значения для "простого" поля
     * @param e
     */
    const handleChange = (e: FormEvent<HTMLInputElement>) => {
        changeValue(e.currentTarget.value);
    };

    /*
     * Событие на изменение значения для поля с "маской"
     * @param e
     */
    const handleChangeWithMask = (e: FormEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;
        const newValue = target.value.replace(/\s/gm, '');

        changeValue(newValue);
    };

    return (
        <span
            ref={innerRef}
            className={cnInput(null, { hovered, focused, disabled, size, width }, className)}>
            {
                // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                extraBefore ?
                    <span className={cnInput('extra-before')}>
                        {extraBefore}
                    </span> : null
            }
            {mode === 'mask' ?
                (
                    <NumberFormat
                        type='tel'
                        thousandSeparator={' '}
                        decimalSeparator={false}
                        isNumericString
                        {...maskProps}
                        onBlur={props.onBlur}
                        placeholder={props.placeholder}
                        className={cnInput('control')}
                        name={name}
                        getInputRef={mergeRefs(inputRef, controlRef, hoverRef, focusRef)}
                        disabled={disabled}
                        // @ts-ignore
                        value={currentValue}
                        onChange={handleChangeWithMask}
                        autoComplete={autoComplete}
                    />
                ) :
                (
                    <InputControl
                        {...props}
                        name={name}
                        value={currentValue}
                        type={mode}
                        ref={mergeRefs(inputRef, controlRef, hoverRef, focusRef)}
                        disabled={disabled}
                        onChange={handleChange}
                        autoComplete={autoComplete}
                    />
                )}
            {
                clear && currentValue !== '' && currentValue !== undefined && (
                    // eslint-disable-next-line
                    <span className={cnInput('clear-button')} onClick={handleClear}>
                        <svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path fillRule='evenodd' clipRule='evenodd' d='M5.00004 5.70714L9.14648 9.85359L9.85359 9.14648L5.70714 5.00004L9.85359 0.853592L9.14648 0.146485L5.00004 4.29293L0.853591 0.146484L0.146484 0.853591L4.29293 5.00004L0.146484 9.14648L0.853591 9.85359L5.00004 5.70714Z' fill='#1F212E' />
                        </svg>
                    </span>
                )
            }
            {
                // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                extraAfter &&
                    // eslint-disable-next-line
                    (<span className={cnInput('extra-after')}>
                        {extraAfter}
                    </span>)
            }
            <span className={cnInput('box', { borderSide, borderOut })} />
        </span>
    );
};

export default Input;
