/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import { useEffect, useState, useCallback, useMemo } from 'react';
import {
    TipAddress,
    TipDeveloper,
    TipNewBuilding,
    TipBuilding,
    TipMetroStation
} from '@search/graphql-typings';
import { TransportType } from '@search/filter-enums/enums/TransportType';

import { graphql, useGql } from '@search/gql-client/src';

import type {
    useQueryDisplayValuesQuery$data as useQueryDisplayValuesQueryResponse,
    useQueryDisplayValuesQuery$variables as useQueryDisplayValuesQueryVariables
} from './__generated__/useQueryDisplayValuesQuery.graphql';

export type QueryDisplayValueKey = 'developers' | 'newBuildings' | 'buildings' | 'addresses' | 'metros' | 'districts';
// eslint-disable-next-line max-len
export type QueryDisplayValue = TipAddress | TipDeveloper | TipNewBuilding | TipBuilding | TipMetroStation;
export type QueryDisplayValues = Partial<Record<QueryDisplayValueKey, Record<number, QueryDisplayValue>>>;
export type AddQueryDisplayValue = (type: QueryDisplayValueKey, data: QueryDisplayValue & { kind?: string; region?: number }) => void;

export type ExtendedTip = QueryDisplayValue | {
    __typename: 'TipRouteMetro';
    id: string;
    transportType: {
        value: TransportType;
    };
    timeLimit: {
        formatted: string;
    };
};

const query = graphql`
    query useQueryDisplayValuesQuery(
        $newBuildingIds: [Int!]
        $addressIds: [Int!]
        $geoSlugs: [String!]
        $regionId: Int
        $districtIds: [Int!]
        $metroIds: [Int!]
    ) {
        searchOffersTips(
            newBuildingIds: $newBuildingIds
            addressIds: $addressIds
            geoSlugs: $geoSlugs
            regionId: $regionId
            districtIds: $districtIds
            metroIds: $metroIds
        ) {
            newBuildings {
                id
                title
                type
            }
            addresses {
                id
                title
                translit
                type
                locative
            }
            metros {
                id
                title
                type
            }
            districts {
                id
                title
                type
                translit
                locative
            }
        }
    }
`;

interface UseQueryDisplayValuesProps {
    newBuildingIds?: readonly number[];
    addressIds?: readonly number[];
    metroIds?: readonly number[];
    districtIds?: readonly number[];
}

interface UseQueryDisplayValuesOptions {
    clientSide: boolean;
    suspense: true | undefined;
}

export type UseQueryDisplayValuesReturnType = Readonly<[
    QueryDisplayValues,
    (key: QueryDisplayValueKey, value: QueryDisplayValue) => void
]>;

const buildState = (data = {}) => {
    return Object.entries(data ?? {})
        .reduce<QueryDisplayValues>((acc, tip) => {
            const [ key, value ] = tip as [ QueryDisplayValueKey, QueryDisplayValue[] ];

            if (value) {
                acc[key] = value.reduce<Record<number, QueryDisplayValue>>((ret, item) => {
                    if (item) {
                        ret[item.id] = item;
                    }

                    return ret;
                }, {});
            }

            return acc;
        }, {} as QueryDisplayValues);
};

const defaultOptions = { suspense: undefined, clientSide: false };

export const useQueryDisplayValues = (
    params: UseQueryDisplayValuesProps = {},
    opts: UseQueryDisplayValuesOptions = defaultOptions
): UseQueryDisplayValuesReturnType => {
    const queryParams = useMemo(() => params, [ JSON.stringify(params) ]);

    const { data } = useGql<
        useQueryDisplayValuesQueryVariables,
        useQueryDisplayValuesQueryResponse
    >(query, queryParams, { suspense: opts.suspense });

    const initialState = useMemo(() => buildState(data?.searchOffersTips ?? undefined), [ data?.searchOffersTips ]);

    const [
        queryDisplayValues,
        setQueryDisplayValues
    ] = useState<QueryDisplayValues>(initialState);

    useEffect(() => {
        if (opts.clientSide) {
            setQueryDisplayValues(buildState(Object.assign({}, data?.searchOffersTips)));
        }
    }, [ data?.searchOffersTips, setQueryDisplayValues, opts.clientSide ]);

    const addDisplayValue = useCallback((key: QueryDisplayValueKey, value: QueryDisplayValue) => setQueryDisplayValues(
        prev => {
            const prevValues = prev[key];
            const { id } = value;

            if (prevValues && prevValues[id]) {
                return prev;
            }

            const next = { ...prev };

            if (! prevValues) {
                next[key] = {};
            }

            next[key]![id] = value;

            return next;
        }
    ), []);

    return [
        queryDisplayValues,
        addDisplayValue
    ] as const;
};
