import React from 'react';

export function useImageSliderItems<
    ContainerEl extends HTMLElement, ItemEl extends HTMLElement, Key extends React.Key
>() {
    const itemRefs = React.useRef(new Map<Key, ItemEl>());
    const containerRef = React.useRef<ContainerEl>(null);

    const setItem = React.useCallback((key: Key, ref?: ItemEl | null) => {
        if (ref) itemRefs.current?.set(key, ref);
    }, [ itemRefs ]);

    return {
        containerRef,
        itemRefs,
        setItem
    };
}
