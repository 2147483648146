import { GeoKind } from '@search/filter-enums/enums/GeoKind';
import { MetroStationRaw } from '../MetroStation';
import { GeoObject2 } from '../GeoObject2';

export function geoProtoMetroStationRaw(geo: GeoObject2) {
    if (geo.kind as unknown as GeoKind !== GeoKind.METRO) {
        throw new Error(
            `Not an metro station id: ${geo.id}, kind: ${geo.kind}`
        );
    }

    const id: number = geo.id;

    const routeId: number = geo.parentId ?? 0;
    const title =
        geo.displayName ?? geo?.mainName?.replace('метро ', '') ?? '';

    const body = geo.body;
    const metro = body?.metro;
    const interchangeStationsIds = metro?.interchangeStationsIdsList ?? [];
    const allRoutesColorsList = metro?.allRoutesColorsList ?? [];
    const coordinates = metro?.coordinates;

    const station: MetroStationRaw = {
        kind: GeoKind.METRO,
        id,
        title,
        routeId,
        interchangeStationsIds,
        allRoutesColorsList,
        coordinates
    };

    return station;
}
