import { SeoMapper } from '@search/router/src';

export const mskOblDistrict = {
    'istra-gorodskoi': 948,
    'bogorodskii-gorodskoi': 1438,
    'volokolamskii-gorodskoi': 1075,
    'dmitrovskii-gorodskoi': 921,
    'kolomenskii-gorodskoi': 460995,
    'leninskii-gorodskoi': 608,
    'mozhaiskii-gorodskoi': 1235,
    'naro-fominskii-gorodskoi': 1474,
    'odintsovskii-gorodskoi': 487,
    'orekhovo-zuevskii-gorodskoi': 2394,
    'pushkinskii-gorodskoi': 488334,
    'ramenskii-gorodskoi': 600,
    'ruzskii-gorodskoi': 578,
    'sergievo-posadskii-gorodskoi': 1156,
    'taldomskii-gorodskoi': 497,
    'balashikha-gorodskoi': 510,
    'bronnitsi-gorodskoi': 1580,
    'vlasikha-gorodskoi': 2764,
    'voskresensk-gorodskoi': 3011,
    'voskhod-gorodskoi': 14540,
    'dzerzhinskii-gorodskoi': 8922,
    'dolgoprudnii-gorodskoi': 809,
    'domodedovo-gorodskoi': 947,
    'dubna-gorodskoi': 6151,
    'yegorevsk-gorodskoi': 9000,
    'zhukovskii-gorodskoi': 777,
    'zaraisk-gorodskoi': 8701,
    'zvyozdnii-gorodok-gorodskoi': 14543,
    'ivanteevka-gorodskoi': 481,
    'kashira-gorodskoi': 1219,
    'klin-gorodskoi': 1596,
    'korolyov-gorodskoi': 525,
    'kotelniki-gorodskoi': 231,
    'krasnoarmeisk-gorodskoi': 3985,
    'krasnogorsk-gorodskoi': 109,
    'krasnoznamensk-gorodskoi': 4069,
    'lobnya-gorodskoi': 495,
    'losino-petrovskii-gorodskoi': 565,
    'lotoshino-gorodskoi': 4104,
    'lukhovitsi-gorodskoi': 14545,
    'litkarino-gorodskoi': 805,
    'lyubertsy-gorodskoi': 534,
    'molodyozhnii-gorodskoi': 14546,
    'mytischi-gorodskoi': 500,
    'ozyori-gorodskoi': 14547,
    'pavlovskii-posad-gorodskoi': 4049,
    'podolsk-gorodskoi': 1077,
    'protvino-gorodskoi': 14548,
    'pushchino-gorodskoi': 8203,
    'reutov-gorodskoi': 828,
    'roshal-gorodskoi': 14550,
    'serebryanie-prudi-gorodskoi': 6190,
    'serpukhov-gorodskoi': 2805,
    'solnechnogorsk-gorodskoi': 686,
    'stupino-gorodskoi': 2940,
    'fryazino-gorodskoi': 616,
    'khimki-gorodskoi': 543,
    'chernogolovka-gorodskoi': 2472,
    'chekhov-gorodskoi': 1167,
    'shatura-gorodskoi': 8460,
    'shakhovskaya-gorodskoi': 1697,
    'shchyolkovo-gorodskoi': 599,
    'elektrogorsk-gorodskoi': 14551,
    'elektrostal-gorodskoi': 3435
} as const;

export const mskSpecialDistricts = {
    'zelenogradskii-administrativnii': 381
} as const;

export const mskDistrict = {
    ...mskSpecialDistricts,
    'vostochnii-administrativnii': 7,
    'zapadnii-administrativnii': 37,
    'novomoskovskii-administrativnii': 45,
    'severnii-administrativnii': 60,
    'severo-vostochnii-administrativnii': 164,
    'severo-zapadnii-administrativnii': 103,
    'troitskii-administrativnii': 414,
    'tsentralnii-administrativnii': 15,
    'yugo-vostochnii-administrativnii': 218,
    'yugo-zapadnii-administrativnii': 182,
    'yuzhnii-administrativnii': 81
} as const;

export const mskDistrictMapper = new SeoMapper('mskDistrictMapper', {
    ...mskOblDistrict,
    ...mskDistrict
} as const);

export const mskOblDistrictIds = Object.values(mskOblDistrict);
export const mskDistrictIds = Object.values(mskDistrict);
