import React from 'react';

import { ApplicationIdEnum } from '@search/graphql-typings';

import { CustomerFeedbackModalNewBuildings as CustomerFeedbackModalNewBuildingsDesktop } from '../../../../../desktop/components/CustomerFeedback/Modal/NewBuildings';
import { CustomerFeedbackModalNewBuildings as CustomerFeedbackModalNewBuildingsMobile } from '../../../../../mobile/components/CustomerFeedback/Modal/NewBuildings';
import { useCustomerFeedbackExternalState } from '../../useCustomerFeedbackExternalState';

import { CustomerFeedbackAnketologModal } from '../CustomerFeedbackAnketologModal/CustomerFeedbackAnketologModal';

export type CustomerFeedbackModalType = Omit<ReturnType<typeof useCustomerFeedbackExternalState>, 'openBtnIsVisible' | 'handleOpenModal' | 'handleCloseOpenBtn'> & {
    applicationId?: ApplicationIdEnum;
}

export const CustomerFeedbackModal = ({
    modalIsOpen,
    handleCloseModal,
    disableFeedbackReappearance,
    handleFinishedFeedback,
    applicationId
}: CustomerFeedbackModalType) => {
    switch (applicationId) {
        case ApplicationIdEnum.NewBuildingsDesktop:
            return (
                <CustomerFeedbackModalNewBuildingsDesktop
                    modalIsOpen={modalIsOpen}
                    handleCloseModal={handleCloseModal}
                    applicationId={applicationId}
                    handleFinishedFeedback={handleFinishedFeedback}
                    disableFeedbackReappearance={disableFeedbackReappearance}
                />
            );

        case ApplicationIdEnum.NewBuildingsTouch:
            return (
                <CustomerFeedbackModalNewBuildingsMobile
                    modalIsOpen={modalIsOpen}
                    handleCloseModal={handleCloseModal}
                    applicationId={applicationId}
                    handleFinishedFeedback={handleFinishedFeedback}
                    disableFeedbackReappearance={disableFeedbackReappearance}
                />
            );

        case ApplicationIdEnum.ClassifiedTouch:
        case ApplicationIdEnum.ClassifiedDesktop:
        default: {
            return (<CustomerFeedbackAnketologModal
                modalIsOpen={modalIsOpen}
                handleCloseModal={handleCloseModal}
                disableFeedbackReappearance={disableFeedbackReappearance}
            />);
        }
    }
};
