import React from 'react';

export const IconPedestrian = ({
    color = '#B6B9C9'
}: {
    color?: string;
}) => (
    <svg width='16' height='16' viewBox='0 0 16 16' fill={color} xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M5.76127 11.1185L6.93706 12.9845L6.03002 15.4335C5.89565 15.7834 5.55971 16 5.20697 16C5.10619 16 5.00541 15.9833 4.90463 15.95C4.45111 15.7834 4.21595 15.2836 4.38392 14.8338L5.76127 11.1185Z'
        />
        <path
            d='M3.14088 6.82011L4.1151 5.40397C4.38385 5.02078 4.77018 4.7209 5.20691 4.55429C6.98739 3.87121 7.22254 3.88787 7.39051 3.88787L8.65029 3.97118C9.00302 3.98784 9.27177 4.0878 10.5987 5.75384C10.7163 5.90379 10.8843 6.00375 11.0858 6.05373L12.396 6.33696C12.8159 6.42026 13.0679 6.82011 12.9839 7.23662C12.8999 7.65313 12.48 7.90304 12.0769 7.81973L10.7667 7.53651C10.2292 7.41988 9.75889 7.12 9.40615 6.70349C9.35576 6.63684 9.30537 6.5702 9.25498 6.52022L8.0456 10.0189L10.9683 14.6672C11.2202 15.0837 11.1026 15.6168 10.6827 15.8667C10.5315 15.95 10.3804 16 10.2124 16C9.91006 16 9.62451 15.8501 9.47334 15.5835L5.84519 9.80232C5.47566 9.21921 5.37488 8.48615 5.55964 7.81973L6.14754 5.82048C6.02996 5.87046 5.89558 5.92045 5.74441 5.97043C5.59324 6.03707 5.45886 6.13703 5.35808 6.27031L4.38385 7.68645C4.23268 7.90304 3.99752 8.01966 3.76237 8.01966C3.61119 8.01966 3.46002 7.96968 3.32564 7.88637C2.9897 7.63647 2.88892 7.16998 3.14088 6.82011Z'
        />
        <path
            d='M8.10214 3.48958C7.13352 3.38501 6.43377 2.5214 6.5392 1.56066C6.64462 0.599919 7.51531 -0.094146 8.48392 0.0104232C9.45254 0.114992 10.1523 0.978597 10.0469 1.93934C9.94144 2.90008 9.07076 3.59415 8.10214 3.48958Z'
        />
    </svg>
);
