import React, { useCallback } from 'react';
import { NumberInput, Group } from '@vtblife/uikit';

import { IRange, RangeValue } from '../../../types/IRange';

export enum NumberFormats {
    /**
     * С разделителями (пробелы)
     */
    Default = 'DEFAULT',
    /**
     * Без разделителей
     */
    OrdinalNumber = 'ORDINAL_NUMBER',
}

export interface IRangeInputProps {
    size?: 's' | 'm' | 'l';
    valueFrom: number|null;
    valueTo: number|null;
    type?: 'text' | 'number';
    unit?: string;
    fromPostfix?: string;
    className?: string;
    onChange: (range: IRange<RangeValue>) => void;
    fromLabel?: string;
    toLabel?: string;
    focusFrom?: (e: React.SyntheticEvent) => void;
    focusTo?: (e: React.SyntheticEvent) => void;
    format?: NumberFormats;
    nameFrom?: string;
    nameTo?: string;
    max?: number;
    disabled?: boolean;
}

export const RangeInput: React.FC<IRangeInputProps> = ({
    size = 'm',
    valueFrom,
    valueTo,
    unit,
    fromPostfix,
    fromLabel = 'от',
    toLabel = 'до',
    focusFrom = () => {},
    focusTo = () => {},
    onChange,
    format = NumberFormats.Default,
    nameFrom = 'from',
    nameTo = 'to',
    max = 1_000_000_000_000,
    disabled = false
}) => {
    const formatOnChange = useCallback((value: string | null | number) => {
        const v = Number((value ?? 0).toString().replace(/\s/g, ''));

        return (v !== 0 && ! isNaN(v)) ? v : null;
    }, [ ]);

    const handleChangeRangeFrom = useCallback(
        (value: null | number) => {
            onChange({
                from: formatOnChange(value),
                to: formatOnChange(valueTo)
            });
        },
        [ valueTo, onChange, formatOnChange ]
    );

    const handleChangeRangeTo = useCallback(
        (value: null | number) => {
            onChange({
                from: formatOnChange(valueFrom),
                to: formatOnChange(value)
            });
        },
        [ valueFrom, onChange, formatOnChange ]
    );

    return (
        <Group size={size}>
            <NumberInput
                size={size}
                type='text'
                separator={format === NumberFormats.Default}
                name={nameFrom}
                placeholder={fromLabel}
                postfix={fromPostfix}
                onFocus={focusFrom}
                inputMode='numeric'
                value={valueFrom ?? undefined}
                onChange={handleChangeRangeFrom}
                max={max}
                disabled={disabled}
            />
            <NumberInput
                size={size}
                name={nameTo}
                type='text'
                separator={format === NumberFormats.Default}
                placeholder={toLabel}
                postfix={unit}
                onFocus={focusTo}
                inputMode='numeric'
                value={valueTo ?? undefined}
                onChange={handleChangeRangeTo}
                max={max}
                disabled={disabled}
            />
        </Group>
    );
};
