import { regionMapper } from '@search/offer-search-routes/src/mappers/regions';
import { SusaninRoute } from '@search/router/src';

export const getNewBuildingLink = (props: {
    id: number;
    type: string;
    name: string;
    regionId: number;
    region: number | string;
    subRegion?: string | null;
    relativeUrl?: boolean;
} | {
    id?: undefined;
    slug?: string;
    subRegion?: string | null;
    regionId: number;
    region: number | string;
    relativeUrl?: boolean;
}) => {
    const parts: string[] = [];

    if (props.subRegion) parts.push(props.subRegion);
    if (props.id !== undefined) parts.push(`${props.type}-${props.name}-${props.id}`);
    else {
        if (props.slug) parts.push(props.slug);
    }

    const route = new SusaninRoute({
        context: {
            preferRelativeUrl: props?.relativeUrl,
            onlyRelativeUrl: props?.relativeUrl
        }
    });
    const regionId = typeof props.region === 'number' ? props.region : props.regionId;
    const rec = route.regionParams(regionId);
    let regionSlug = typeof props.region === 'string' ? props.region : regionMapper.typesMap[
        props.region as keyof typeof regionMapper.Type
    ] ?? rec.region;

    if (route.isDomainOnlySlug(regionId)) regionSlug = '';

    return `${rec.origin ?? ''}/${regionSlug ? `${regionSlug}/` : ''
    }novostroyki/${parts.length ? `${parts.join('/')}/` : ''}`;
};
