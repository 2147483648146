import { useCallback } from 'react';

import { graphql, useGqlContext } from '@search/gql-client/src';

import type {
    useCallbackEmailMutation$variables as useCallbackEmailMutationVariables,
    useCallbackEmailMutation$data as useCallbackEmailMutationResponse
} from './__generated__/useCallbackEmailMutation.graphql';

const mutation = graphql`
    mutation useCallbackEmailMutation(
        $subject: String!
        $body: String!
        $emailRecipient: String
    ) {
        createEmailCallbackSimple(
            subject: $subject
            body: $body
            emailRecipient: $emailRecipient
        ) {
            emailMessageId
        }
    }
`;

export const useCallbackEmail = () => {
    const gql = useGqlContext();

    return useCallback(
        (
            vars: useCallbackEmailMutationVariables,
            opts?: { signal?: AbortSignal; pathUniqSuffix?: String }
        ) => gql.client<
            useCallbackEmailMutationVariables,
            useCallbackEmailMutationResponse
        >(
            mutation,
            vars,
            opts
        ),
        [ gql ]
    );
};
