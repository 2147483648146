import React, { useCallback, useMemo, useState } from 'react';

import { Select, Dropdown, useSelect } from '@vtblife/uikit';
import { RegionIdEnum } from '@search/filter-enums/enums/Region';
import { useGeoUserRegion } from '@search/geo/src/useGeoUserRegion';
import {
    getFilteredFlatRegionSelectOptions,
    getSelectedOptionLabel
} from '../../../../helpers/regionHelper';
import defaultRegionSelectOptions from '../../../../../../dict/filters/regions';
import { RegionFilter } from '../../../../../filters/models/Filter';
import {
    RenderRegionSelectUikitContent,
    OptionTypeRegionFilter, GroupedOptions,
    MIN_OPTIONS_NUMBER_WITH_INPUT
} from '../../region/RenderRegionSelectUikitContent';

interface IProps {
    filter: RegionFilter;
    options?: OptionTypeRegionFilter[] | GroupedOptions<OptionTypeRegionFilter>[];
    onChange: (filter: RegionFilter) => void;
    emptyText?: React.ReactNode;
}

const defaultOptions = defaultRegionSelectOptions.map(option => ({
    ...option,
    value: String(option.value)
}));

export const DesktopRegionFilterView: React.FC<IProps> = ({
    filter,
    options = defaultOptions,
    onChange,
    emptyText
}) => {
    const [ searchInputValue, setSearchInputValue ] = useState('');
    const resetSearchInputValue = useCallback(() => setSearchInputValue(''), []);
    const { setRegionId } = useGeoUserRegion();
    const value = String(filter.region || RegionIdEnum.MSK_AND_MSK_OBL);

    const { is, handleChange: handleSelectChange } = useSelect(
        (newValue: string) => {
            const parsedValue = parseInt(newValue, 10) as RegionIdEnum;

            setRegionId(parsedValue);
            onChange(new RegionFilter(parsedValue));
        },
        { selected: value }
    );

    const title = useMemo(
        () => getSelectedOptionLabel(value, options),
        [ value, options ]
    );

    let currentOptions = options;

    if (searchInputValue.length) {
        currentOptions = getFilteredFlatRegionSelectOptions(searchInputValue, options);
    }

    return (
        <Select
            onChange={handleSelectChange}
            dataTest='region'
            onToggle={() => {
                resetSearchInputValue();
            }}
        >
            <Dropdown.Control arrow size='s' dataTest='region-trigger'>{title}</Dropdown.Control>
            <Select.Content
                height={310}
                adaptiveHeight={false}
                adaptivePlacement={false}
                placement='local'
            >
                <RenderRegionSelectUikitContent
                    is={is}
                    searchInputValue={searchInputValue}
                    emptyText={emptyText}
                    setSearchInputValue={setSearchInputValue}
                    showInput={options.length >= MIN_OPTIONS_NUMBER_WITH_INPUT}
                    options={currentOptions}
                />
            </Select.Content>
        </Select>
    );
};
