import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Modal, Button, Typography } from '@vtblife/uikit';
import { PhoneInput, Size } from '@vtblife/uikit/legacy';
import { ModalProps } from '@vtblife/uikit/dist/components/modal/types';

import { formatPhone } from '@search/helpers/src/formatPhone';
import classname from '@search/classname/src';
import { useToggleValue } from '@search/hooks/src/useToggleValue';
import { STATUS } from '@search/gql-client/src';
import { ApplicationIdEnum } from '@search/graphql-typings';
import { useApplicationContext } from '@search/hooks/src/providers/ApplicationProvider/ApplicationProvider';
import { useClassifiedOfferDataLayerPush } from '../../../../domain/offer/analytics/ClassifiedOfferGtmContext';

import { useInitialPhone } from '../../hooks/useInitialPhone';
import { useWorkingHours } from '../WorkingHours/useWorkingHours';
import { WorkingHours } from '../WorkingHours/WorkingHours';
import { normalizeCallbackUrl } from '../MinimalBanksMortgage/mortgage2/mortgage2ContactsFormSend';
import type { useCallbackRequestEmailMutation$variables as useCallbackRequestEmailMutationVariables } from './__generated__/useCallbackRequestEmailMutation.graphql';
import { useCallbackRequest } from './useCallbackRequest';
import {
    ErrorResponse,
    sendCalltouchCallbackRequest,
    CalltouchCallbackError
} from './sendCalltouchCallbackRequest';

import './styles.css';

const cn = classname.bind(null, 'CallbackModal');

const PHONE_NUMBER_LENGTH = 11;
const START_HOUR_WORKING_DAY_SALES = 9;
const END_HOUR_WORKING_DAY_SALES = 21;
const DEFAULT_REQUEST_ERROR_MESSAGE = 'Что-то пошло не так, попробуйте позже.';
const HOST_M2_IPOTEKA = 'https://m2.ru/ipoteka';

export type CallbackDataType = Pick<
    ICallbackModalProps,
    'newBuildingName' | 'address' | 'developerName' | 'extra' | 'subjectExtra' | 'emailSender' | 'callbackUnitId'
>;

interface ICallbackModalProps extends
    Omit<ModalProps, 'opened' | 'children'>,
    Omit<useCallbackRequestEmailMutationVariables, 'phone' | 'hours'>
{
    isOpened?: boolean;
    startHour?: number;
    endHour?: number;
    gtmSendClick?: string;
    onSendEventPush?: () => void;
    gtmShowModal?: string;
    btnWrapperClass?: string;
    onClose?(): void;
    renderTrigger?: (openModalCallback: () => void) => React.ReactNode;
    callbackUnitId?: number; // Идентификатор отдела в Коллтаче (зависит от региона)
    enableInnerText360?: boolean;
    hasNoText?: boolean;
    withoutIconOnButton?: boolean;
    buttonSize?: 's' | 'm' | 'l';
    onDarkBackground?: boolean;
}

const INIT_REQUEST_DATA = {
    status: STATUS.INIT,
    errorMessage: ''
};

export const CallbackModal: React.FC<ICallbackModalProps> = ({
    size = 's',
    mobile = false,
    startHour = START_HOUR_WORKING_DAY_SALES,
    endHour = END_HOUR_WORKING_DAY_SALES,
    newBuildingName,
    address,
    developerName,
    offerLink,
    extra,
    subjectExtra,
    emailSender,
    gtmSendClick,
    onSendEventPush,
    gtmShowModal,
    renderTrigger,
    btnWrapperClass,
    callbackUnitId,
    newBuildingRanking,
    regionId,
    isNewBuilding,
    onClose,
    enableInnerText360,
    hasNoText,
    withoutIconOnButton,
    buttonSize,
    onDarkBackground = false
}) => {
    const {
        rawWorkingHoursRange,
        workingHoursRange,
        setStartHoursRange,
        setEndHoursRange,
        startHourValues,
        endHourValues
    } = useWorkingHours({ startHour, endHour });

    const initialPhone = useInitialPhone();
    const [ phoneNumber, setPhoneNumber ] = useState(initialPhone);

    const [ requestData, setRequestData ] = useState(INIT_REQUEST_DATA);

    const {
        handleToggleTogVal: handleToggleModal,
        isToggleValue: isOpenModal
    } = useToggleValue(Boolean(onClose));

    useEffect(() => {
        if (isOpenModal) {
            setPhoneNumber(initialPhone);

            setRequestData(INIT_REQUEST_DATA);
        }
    }, [ initialPhone, isOpenModal ]);

    const isFirstShowModal = useRef(true);
    const dataLayerPush = useClassifiedOfferDataLayerPush();
    const sendGtmFirstShowModal = useCallback(() => {
        if (isFirstShowModal.current) {
            gtmShowModal && dataLayerPush({ event: gtmShowModal });
            isFirstShowModal.current = false;
        }
    }, [ isFirstShowModal, gtmShowModal, dataLayerPush ]);

    const sendCallBackRequest = useCallbackRequest();

    const sendRequest = useCallback(async () => {
        const makeSendCallBackRequest = async (isCallbackRequestCreated?: boolean) => {
            const { data } = await sendCallBackRequest({
                extra,
                address,
                regionId,
                offerLink: normalizeCallbackUrl(offerLink),
                emailSender,
                subjectExtra,
                developerName,
                isNewBuilding,
                newBuildingName,
                newBuildingRanking,
                isCallbackRequestCreated,
                phone: phoneNumber,
                hours: `c ${workingHoursRange.start} до ${workingHoursRange.end}`
            });

            const errorMessage = data?.createEmailCallback.emailMessageId ? '' : DEFAULT_REQUEST_ERROR_MESSAGE;

            setRequestData({
                status: errorMessage ? STATUS.ERROR : STATUS.RESOLVE,
                errorMessage
            });
        };

        gtmSendClick && dataLayerPush({ event: gtmSendClick });
        onSendEventPush?.();
        dataLayerPush({ event: 'up_evt_call_back' });

        setRequestData(state => ({
            ...state,
            status: STATUS.PENDING
        }));

        let isCallbackRequestCreated = false;
        let isLimitExceeded = false;

        if (callbackUnitId) {
            try {
                const { callbackRequestId } = await sendCalltouchCallbackRequest({
                    phone: phoneNumber,
                    unitId: callbackUnitId,
                    hours: rawWorkingHoursRange,
                    fields: [ {
                        name: 'Название объекта',
                        value: newBuildingName ?? ''
                    } ]
                });

                isCallbackRequestCreated = Boolean(callbackRequestId);
            } catch (e) {
                const errorType = (e as ErrorResponse).type;

                if (errorType === CalltouchCallbackError.REQUEST_THROTTLE_TIMEOUT || errorType === CalltouchCallbackError.REQUEST_THROTTLE_COUNT) {
                    isLimitExceeded = true;
                    setRequestData({
                        status: STATUS.ERROR,
                        errorMessage: DEFAULT_REQUEST_ERROR_MESSAGE
                    });
                }
            }
        }

        if (! isLimitExceeded) {
            makeSendCallBackRequest(isCallbackRequestCreated).catch(() => {
                setRequestData({
                    status: STATUS.ERROR,
                    errorMessage: DEFAULT_REQUEST_ERROR_MESSAGE
                });
            });
        }
    }, [
        dataLayerPush,
        emailSender,
        phoneNumber,
        newBuildingName,
        address,
        developerName,
        offerLink,
        extra,
        subjectExtra,
        workingHoursRange,
        sendCallBackRequest,
        gtmSendClick,
        rawWorkingHoursRange,
        callbackUnitId,
        regionId,
        isNewBuilding,
        newBuildingRanking,
        onSendEventPush
    ]);

    const isResolvedData = requestData.status === STATUS.RESOLVE;

    const applicationContext = useApplicationContext();
    const isClassifiedApp = applicationContext === ApplicationIdEnum.ClassifiedDesktop || applicationContext === ApplicationIdEnum.ClassifiedTouch;

    const handleClick = useCallback(() => {
        if (isClassifiedApp) {
            window.location.href = HOST_M2_IPOTEKA;
        } else { // if (newBuildingName)
            sendGtmFirstShowModal();
            handleToggleModal();
        }
    }, [ sendGtmFirstShowModal, handleToggleModal ]);

    const buttons = renderTrigger ? renderTrigger(handleClick) : (
        <div className={cn('buttonWrapper', {
            enableInnerText360: enableInnerText360 && ! hasNoText
        }, btnWrapperClass)}>
            <Button
                icon={withoutIconOnButton || ! newBuildingName ? undefined : 'phone-incoming'}
                fullWidth={! hasNoText}
                variant={onDarkBackground ? 'secondary-alt-bg' : 'secondary'}
                onClick={handleClick}
                dataTest='callback-button-cta'
                size={buttonSize ?? 's'}
            >
                {hasNoText ? null : isClassifiedApp ? (
                    <>
                        <span className={cn('inner-text-360')}>Купить в ипотеку</span>
                        <span className={cn('inner-text')}>Купить в ипотеку</span>
                    </>
                ) : (
                    <>
                        <span className={cn('inner-text-360')}>Перезвоните</span>
                        <span className={cn('inner-text')}>Перезвоните мне</span>
                    </>
                )}
            </Button>
        </div>
    );

    return (<>
        {onClose ? null : buttons}
        <Modal
            zIndex={mobile ? 1050 : undefined} // --l-modal
            opened={isOpenModal}
            size={size}
            mobile={mobile}
            swipeToClose={! mobile}
            onClose={onClose ?? handleToggleModal}
            data-scroll-lock-ignore
        >
            {mobile && (
                <Modal.Header>
                    Обратный звонок
                </Modal.Header>
            )}
            <Modal.Content scrollable>
                {
                    mobile ? (
                        isResolvedData && (
                            <Typography
                                bold
                                variant='h3'
                                className={cn('title', { mobile })}
                            >
                                Принято
                            </Typography>
                        )
                    ) : (
                        <Typography
                            bold
                            variant='h3'
                            className={cn('title', { mobile })}
                        >
                            {isResolvedData ? 'Принято' : 'Обратный звонок'}
                        </Typography>
                    )
                }
                <Typography variant='primary' className={cn('mainMessage', { mobile })}>
                    {
                        isResolvedData ?
                            'Мы перезвоним вам в указанное время.' :
                            // eslint-disable-next-line max-len
                            'Оставьте свой номер телефона, и мы обязательно перезвоним в указанное время (МСК).'
                    }
                </Typography>
                {
                    isResolvedData ? (
                        <Typography
                            bold
                            variant='primary'
                            className={cn('result')}
                        >
                            {`${
                                formatPhone(phoneNumber, { skipParenthesis: true, separateLast: true })
                            } c ${
                                workingHoursRange.start
                            } до ${
                                workingHoursRange.end
                            } (МСК)`}
                        </Typography>
                    ) : (
                        <>
                            <div className={cn('form', { mobile })}>
                                <PhoneInput
                                    fullWidth
                                    value={phoneNumber}
                                    onChange={setPhoneNumber}
                                    hasClear
                                    inputMode='numeric'
                                    label='Номер телефона'
                                    size={Size.Large}
                                    className={cn('phone')}
                                />
                                <WorkingHours
                                    mobile={mobile}
                                    workingHoursRange={workingHoursRange}
                                    setStartHoursRange={setStartHoursRange}
                                    setEndHoursRange={setEndHoursRange}
                                    startHourValues={startHourValues}
                                    endHourValues={endHourValues}
                                />
                            </div>
                            {
                                requestData.status === STATUS.ERROR && (
                                    <Typography
                                        color='red-500'
                                        variant='primary'
                                        className={cn('errorMessage', { mobile })}
                                    >
                                        {requestData.errorMessage}
                                    </Typography>
                                )
                            }
                            <Button
                                fullWidth
                                onClick={sendRequest}
                                disabled={
                                    phoneNumber.length < PHONE_NUMBER_LENGTH ||
                                    requestData.status === STATUS.PENDING
                                }
                            >
                                Отправить
                            </Button>
                        </>
                    )
                }
            </Modal.Content>
        </Modal>
    </>);
};
