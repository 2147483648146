import React, { useCallback, useEffect } from 'react';

import { useYMapPane } from '@search/ymap/src/Pane';

import './Mobile.css';

export function YMapClickPane({
    resetSelected
}: {
    resetSelected?: () => void;
}) {
    const { map } = useYMapPane();

    const onClick = useCallback(() => {
        resetSelected && resetSelected();
    }, [ resetSelected ]);

    useEffect(() => {
        map.events.add('click', onClick);

        return () => {
            map.events.remove('click', onClick);
        };
    }, [ map, onClick ]);

    return null;
}
