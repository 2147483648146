import React, { useCallback } from 'react';

import { Checkbox } from '@vtblife/uikit';
import { FlatsSpecialEvent } from '@search/filter-enums/enums/SpecialEvents';

import { FlatsSpecialEventsFilter } from '../../../../models/Filter';
import { Filter2 } from '../../../new_filter/Filter2';
import { CheckboxGroup2 } from '../../../common/CheckboxGroup2';

interface IDesktopFlatsSpecialEventsFilterViewProps {
    filter: FlatsSpecialEventsFilter;
    handleChange: (value: FlatsSpecialEventsFilter) => void;
}

const ITEMS = [
    [ FlatsSpecialEvent.FLAT_DISCOUNT, 'Скидка' ],
    [ FlatsSpecialEvent.FLAT_INSTALLMENT_PLAN, 'Рассрочка' ],
    [ FlatsSpecialEvent.FLAT_SALES_START, 'Старт продаж' ]
];

export const DesktopFlatsSpecialEventsFilterView: React.FC<IDesktopFlatsSpecialEventsFilterViewProps> = ({
    filter,
    handleChange
}) => {
    const onChange = useCallback(value => {
        handleChange(new FlatsSpecialEventsFilter(value));
    }, [ handleChange ]);

    return (
        <Filter2 filterTitle='Квартиры'>
            <CheckboxGroup2
                mode='check'
                name='flatsSpecialEvents'
                onChange={onChange}
                value={filter.value}
                isFilter
            >
                {
                    ITEMS.map(([ value, label ]) => (
                        <Checkbox
                            variant='filter'
                            key={value}
                            name={value}
                            block
                        >
                            {label}
                        </Checkbox>
                    ))
                }
            </CheckboxGroup2>
        </Filter2>
    );
};
