import { PoiKindEnum } from '@search/graphql-typings';

export type PoiImageName = 'SchoolPoiImg' | 'UniversityPoiImg' | 'KindergartenPoiImg' | 'HospitalPoiImg' |
'PharmacyPoiImg' | 'ShopPoiImg' | 'FoodServicePoiImg' | 'SportCentrePoiImg' | '';

export const PoiKindToIconName: Record<PoiKindEnum, string> = {
    [PoiKindEnum.Unknown]: '',
    [PoiKindEnum.School]: 'SchoolPoi',
    [PoiKindEnum.University]: 'UniversityPoi',
    [PoiKindEnum.Kindergarten]: 'KindergartenPoi',
    [PoiKindEnum.Hospital]: 'HospitalPoi',
    [PoiKindEnum.Pharmacy]: 'PharmacyPoi',
    [PoiKindEnum.Shop]: 'ShopPoi',
    [PoiKindEnum.FoodService]: 'FoodServicePoi',
    [PoiKindEnum.SportCentre]: 'SportCentrePoi'
};

export const PoiKindToImageName: Record<PoiKindEnum, PoiImageName> = {
    [PoiKindEnum.Unknown]: '',
    [PoiKindEnum.School]: 'SchoolPoiImg',
    [PoiKindEnum.University]: 'UniversityPoiImg',
    [PoiKindEnum.Kindergarten]: 'KindergartenPoiImg',
    [PoiKindEnum.Hospital]: 'HospitalPoiImg',
    [PoiKindEnum.Pharmacy]: 'PharmacyPoiImg',
    [PoiKindEnum.Shop]: 'ShopPoiImg',
    [PoiKindEnum.FoodService]: 'FoodServicePoiImg',
    [PoiKindEnum.SportCentre]: 'SportCentrePoiImg'
};
