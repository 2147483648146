import { createRouterProvider, RouterProviderProps } from '@search/router/src/react/createRouterProvider';
import type { SlugMapper } from '@search/geo/src/SlugMapper';

export type IRootRegions = {
    byId(id: number): { translit?: string | null; narrowRegion?: {id: number} | null } | undefined;
    bySlug(slug: string): { id: number } | undefined;
    slugs: SlugMapper;
};

export type RouterContext = {
    urlMapper?(src: string): string | undefined;
    isMobile?: boolean;
    isServer?: boolean;
    isCanonical?: boolean;
    onlyRelativeUrl?: boolean;
    preferRelativeUrl?: boolean;
    rootRegions?: IRootRegions;
}

export type RouterProviderAppProps = RouterProviderProps<RouterContext>;

const { RouterProvider, useRouter } = createRouterProvider<RouterContext>();

export { RouterProvider, useRouter };
