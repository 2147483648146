import { AreaUnit } from '@search/filter-enums/enums/AreaUnit';
import { RealtyObjectType } from '@search/filter-enums/enums/RealtyObjectType';
import { NewBuilding } from '@search/filter-enums/enums/NewBuilding';
import { DealType } from '@search/filter-enums/enums/DealType';
import { RegionIdEnum, regionsWithMetroEnumeration } from '@search/filter-enums/enums/Region';
import { FilterCollection } from '@search/vtbeco-frontend-core/view/filters/models/FilterCollection';
import { HouseCategory } from '@search/filter-enums/enums';
import { MortgagePurpose } from '@search/graphql-typings';
import {
    CategoryTypeFilter,
    DealTypeFilter,
    Filters,
    HouseCategoryFilter,
    MapBoundsFilter,
    NewBuildingFilter,
    PriceFilter,
    RegionFilter,
    SellerOrganizationNameFilter
} from '../../../view/filters/models/Filter';

/**
 * ClassifiedFilterCollection business logic for filters in classified
 *
 * Contains methods for:
 * 1. retrieve typed filters like - getDealTypeFilter
 * 2. get current data about filter state - isRoomFilterActive
 * 3. ui info about view control state - isRoomFilterShown
 *
 * Before add new method check that there's no already defined method
 */
export class ClassifiedFilterCollection extends FilterCollection {
    rebuild() {
        return new ClassifiedFilterCollection(this._filters);
    }

    getDealTypeFilter(): DealTypeFilter {
        return this.get(Filters.dealType);
    }

    getCategoryTypeFilter(): CategoryTypeFilter {
        return this.get(Filters.categoryType);
    }

    getPriceFilter(): PriceFilter {
        return this.get(Filters.price);
    }

    getRegionFilter(): RegionFilter {
        return this.get(Filters.region);
    }

    getNewBuildingFilter(): NewBuildingFilter {
        return this.get(Filters.newBuilding);
    }

    getMapBoundsFilter(): MapBoundsFilter {
        return this.get(Filters.mapBounds);
    }

    getMortgagePurpose(): MortgagePurpose {
        if (this.isActiveFilterHouseAllowedMortgage()) {
            return MortgagePurpose.Secondary;
        } else if (this.isNewBuilding()) {
            return MortgagePurpose.Primary;
        }
        return MortgagePurpose.Secondary;
    }

    getSellerOrganizationName(): SellerOrganizationNameFilter {
        return this.get(Filters.sellerOrganizationName);
    }

    isActiveFilterHouseAllowedMortgage(): boolean {
        return (this.get(Filters.houseCategory) as HouseCategoryFilter)?.categories
            .every(category => category !== HouseCategory.DUPLEX && category !== HouseCategory.PART_OF_HOUSE) ?? false;
    }

    isNewBuilding() {
        return this.getNewBuildingFilter()?.newBuilding.includes(NewBuilding.NEW) ?? false;
    }

    isOnlySecond() {
        return (this.getNewBuildingFilter()?.newBuilding.includes(NewBuilding.SECOND) &&
            this.getNewBuildingFilter()?.newBuilding.length === 1) ?? false;
    }

    isOnlyNewBuildings() {
        return (this.getNewBuildingFilter()?.newBuilding.includes(NewBuilding.NEW) &&
            this.getNewBuildingFilter()?.newBuilding.length === 1) ?? false;
    }

    isFlatFilterActive(): boolean {
        return this.getCategoryTypeFilter().categoryType === RealtyObjectType.FLAT;
    }

    isLandFilterActive(): boolean {
        return this.getCategoryTypeFilter().categoryType === RealtyObjectType.LAND;
    }

    isHouseFilterActive(): boolean {
        return this.getCategoryTypeFilter().categoryType === RealtyObjectType.HOUSE;
    }

    isRoomFilterActive(): boolean {
        return this.getCategoryTypeFilter().categoryType === RealtyObjectType.ROOM;
    }

    isRentFilterActive(): boolean {
        return this.getDealTypeFilter().dealType === DealType.RENT;
    }

    isSellFilterActive(): boolean {
        return this.getDealTypeFilter().dealType === DealType.SELL;
    }

    isPricePerAllSquare() {
        return this.getPriceFilter().priceType === AreaUnit.UNKNOWN;
    }

    isPricePerMeterSquare() {
        return this.getPriceFilter().priceType === AreaUnit.METER;
    }

    isMortgagePayment() {
        return this.isSellFilterActive() && this.getPriceFilter().priceType === AreaUnit.MORTGAGE_PAYMENT;
    }

    isRegionMsk(): boolean {
        return this.getRegionFilter().region === RegionIdEnum.MSK;
    }

    isRegionMskMo(): boolean {
        return this.getRegionFilter().region === RegionIdEnum.MSK_AND_MSK_OBL;
    }

    isRegionMo(): boolean {
        return this.getRegionFilter().region === RegionIdEnum.MSK_OBL;
    }

    isRegionMskOrMo(): boolean {
        return this.isRegionMo() || this.isRegionMsk() || this.isRegionMskMo();
    }

    isRegionSpb(): boolean {
        return this.getRegionFilter().region === RegionIdEnum.SPB;
    }

    isRegionSpbLO(): boolean {
        return this.getRegionFilter().region === RegionIdEnum.SPB_AND_LEN_OBL;
    }

    isRegionLo(): boolean {
        return this.getRegionFilter().region === RegionIdEnum.LEN_OBL;
    }

    isRegionSpbOrLo(): boolean {
        return this.isRegionLo() || this.isRegionSpb() || this.isRegionSpbLO();
    }

    isNewBuildingShown(): boolean {
        return this.isSellFilterActive() && this.isFlatFilterActive();
    }

    isBankPledgeOptionActive(): boolean {
        return this.isSellFilterActive() && this.isFlatFilterActive();
    }

    isSellFlatFilterActive(): boolean {
        return this.isSellFilterActive() && this.isFlatFilterActive();
    }

    isFlatOrRoomFilterActive(): boolean {
        return this.isFlatFilterActive() || this.isRoomFilterActive();
    }

    isFloorTotalFilterShownNotHouse(): boolean {
        return (! this.isLandFilterActive()) && (! this.isHouseFilterActive());
    }

    isLandOrHouseFilterActive(): boolean {
        return this.isLandFilterActive() || this.isHouseFilterActive();
    }

    isSellRoomFilterActive(): boolean {
        return this.isSellFilterActive() && this.isRoomFilterActive();
    }

    isSellFlatOrRoomFilterActive(): boolean {
        return this.isSellFilterActive() && this.isFlatOrRoomFilterActive();
    }

    isWindowsDirectionFilterShownInRent(): boolean {
        return this.isFlatOrRoomFilterActive() && this.isRentFilterActive();
    }

    isRentFlatOrRoomFilterActive(): boolean {
        return this.isRentFilterActive() && this.isFlatOrRoomFilterActive();
    }

    hasMetroInRegion() {
        return regionsWithMetroEnumeration.has(this.getRegionFilter().region);
    }

    isRenovationProgramFilterActive(): boolean {
        return (this.getRegionFilter().region === RegionIdEnum.MSK ||
            this.getRegionFilter().region === RegionIdEnum.MSK_OBL ||
            this.getRegionFilter().region === RegionIdEnum.MSK_AND_MSK_OBL) &&
            this.isSellFilterActive() && this.isFlatOrRoomFilterActive() &&
            ! ((this.getNewBuildingFilter()?.newBuilding.includes(NewBuilding.NEW) &&
                this.getNewBuildingFilter()?.newBuilding.length === 1));
    }

    isNearbySchoolFilterActive(): boolean {
        return (this.getRegionFilter().region === RegionIdEnum.MSK ||
            this.getRegionFilter().region === RegionIdEnum.MSK_OBL ||
            this.getRegionFilter().region === RegionIdEnum.MSK_AND_MSK_OBL) &&
            this.isFlatOrRoomFilterActive();
    }

    isOnlineViewFilterActive(): boolean {
        return this.isFlatOrRoomFilterActive() || this.isHouseFilterActive() || this.isLandFilterActive();
    }
}
