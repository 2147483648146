import React from 'react';

import type { ArrayElement } from '../../../../../../../types/common';
import type { DistrictGroup } from '../../../../../helpers/districtHelper';

import type { DistrictSelectProps } from './DistrictSelect';

interface UseDistrictSelectProps extends Pick<DistrictSelectProps, 'checked' | 'onChange'>{
    groups: DistrictGroup[];
}

export function useDistrictSelect({
    checked: selected,
    onChange,
    groups
}: UseDistrictSelectProps) {
    const checkSet = React.useMemo(() => new Set(selected), [ selected ]);

    const isChecked = React.useCallback(
        (id: number) => checkSet.has(id),
        [ checkSet ]
    );

    const checkGroup = React.useCallback((
        checked: boolean,
        groupIndex: number,
        groupId: DistrictGroup['id']
    ) => {
        let uncheckedDiff = new Set<number>();
        let checkedDiff: number[] = [];

        const groupIds = groups[groupIndex].items.map(item => item.id);

        if (checked) {
            checkedDiff = groupId ? [ groupId ] : groupIds;
        } else {
            uncheckedDiff = new Set(groupId && isChecked(groupId) ? [ groupId ] : groupIds);
        }

        onChange(checkedDiff, uncheckedDiff);
    }, [ groups, onChange, isChecked ]);

    const checkItem = React.useCallback((
        checked: boolean,
        id: ArrayElement<DistrictGroup['items']>['id'],
        groupIndex: number,
        groupId: DistrictGroup['id']
    ) => {
        let uncheckedDiff = new Set<number>();
        let checkedDiff: number[] = [];

        if (! groupId) {
            if (checked) {
                checkedDiff.push(id);
            } else {
                uncheckedDiff.add(id);
            }

            onChange(checkedDiff, uncheckedDiff);

            return;
        }

        if (checked) {
            const isGroupChecked = groups[groupIndex].items.every(item => isChecked(item.id) || item.id === id);

            if (isGroupChecked) {
                const selectedIdsFromGroup = groups[groupIndex].items
                    .map(item => item.id)
                    .filter(value => isChecked(value));

                checkedDiff = [ groupId ];
                uncheckedDiff = new Set([ id, ...selectedIdsFromGroup ]);
            } else {
                checkedDiff = [ id ];
            }
        } else {
            if (isChecked(groupId)) {
                const otherGroupIds = groups[groupIndex].items
                    .filter(item => item.id !== id)
                    .map(item => item.id);

                checkedDiff = otherGroupIds;
                uncheckedDiff = new Set([ groupId, id ]);
            } else {
                uncheckedDiff = new Set([ id ]);
            }
        }

        onChange(checkedDiff, uncheckedDiff);
    }, [ groups, onChange, isChecked ]);

    return { checkItem, checkGroup, isChecked, checkSet };
}
