import { MortgagePurpose } from '@search/graphql-typings';

import { formatLocaleNumber, formatNumber } from '@search/helpers/src/formatNumber';

import { useM2AuthContext } from '@search/auth/src/M2AuthProvider';

import { calculateOwnAmount } from './MinimalBanksMortgageFields/useBankMortgageForm';
import {
    MinimalBanksMortgageOffersQueryVariables, useMinimalBanksMortgageOffersLoader
} from './MinimalBanksMortgageOffers/MinimalBanksMortgageOffers';
import { minimalBanksMortgageUrlCreate } from './minimalBanksMortgageUrl';
import { mortgage2BestOfferFromOffers } from './mortgage2/useMortgage2BestOffer';

export type UseMinimalBanksMortgageStateParams = {
    mortgageRegionId: number;
    propertyCost: number;
    isNewBuilding: boolean;
    term?: number;
    percent?: number;
}

export function minimalBanksMortgageParamsFromOffer({
    mortgageRegionId,
    propertyCost,
    isNewBuilding,
    term = 20,
    percent
}: UseMinimalBanksMortgageStateParams): MinimalBanksMortgageOffersQueryVariables {
    const ownAmount = calculateOwnAmount(propertyCost, percent);
    const purpose = isNewBuilding ? MortgagePurpose.Primary : MortgagePurpose.Secondary;

    return {
        purpose,
        ownAmount,
        regionId: mortgageRegionId,
        term,
        propertyCost
    };
}

export function getMinimalBanksMortgageEconomyPercent() {
    const economy = 0.3;

    const MAX_ECONOMY = 0.3;

    return {
        economy,
        MAX_ECONOMY,
        economyFormatted: `${formatLocaleNumber(economy)}%`,
        maxEconomyFormatted: `${formatLocaleNumber(MAX_ECONOMY)}%`
    };
}

export function useMinimalBanksMortgageEconomy(variables: Partial<
    MinimalBanksMortgageOffersQueryVariables & { from?: string }
>) {
    const { auth } = useM2AuthContext();
    const { baseUrl, fullUrl } =
        minimalBanksMortgageUrlCreate({ isAuthenticated: auth.isAuthenticated, ...variables });

    const { economyFormatted, economy } = getMinimalBanksMortgageEconomyPercent();

    return {
        formUrl: baseUrl,
        url: fullUrl,
        economy,
        economyFormatted
    };
}

export function useMinimalBanksMortgageOverall(variables: MinimalBanksMortgageOffersQueryVariables) {
    const minimalBanksMortgageOffersLoad = useMinimalBanksMortgageOffersLoader();
    const data = minimalBanksMortgageOffersLoad(variables);
    const minimalBanksMortgageOffers = data.data?.minimalBanksMortgageOffers;
    const bestMortgageOffer = mortgage2BestOfferFromOffers(minimalBanksMortgageOffers);

    const allMinimalPayment = minimalBanksMortgageOffers?.reduce((min, offer) => {
        const value = offer?.minimalPayment ?? min;

        return min > value ? value : min;
    }, variables.propertyCost);

    return {
        ...useMinimalBanksMortgageEconomy(variables),
        bestMortgageOffer,
        allMinimalPayment,
        allMinimalPaymentFormatted: allMinimalPayment ?
            `${formatNumber(Math.round(allMinimalPayment))}\u00A0₽/мес.` :
            undefined,
        isLoading: Boolean(data.loading),
        minimalBanksMortgageOffers
    };
}
