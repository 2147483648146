import './FilterTagListItem.css';

import React from 'react';
import Icon from '@vtblife/uikit-icons';

import classname from '@search/classname/src';

const cn = classname.bind(null, 'FilterTagListItem');

export function FilterTagListItem({
    id,
    children,
    isActive = false,
    openMetroModal,
    onDelete
}: {
    id: string;
    onDelete: () => void;
    children: React.ReactNode;
    isActive?: boolean;
    openMetroModal?: () => void;
}) {
    return (
        <div
            onClick={openMetroModal}
            className={cn('item', { active: isActive, clickable: Boolean(openMetroModal) })}
        >
            <div className={cn('text')}>{children}</div>
            <button
                className={cn('delete')}
                data-test={`${id}-delete`}
                onClick={e => {
                    e.stopPropagation();
                    onDelete();
                }}
            >
                <Icon name='times-circle' />
            </button>
        </div>
    );
}
