import React, { useCallback, useState, useMemo, useEffect, KeyboardEvent } from 'react';
import { KEY_CODE } from '../../../enums';

export interface IUseSuggestionListState {
    focusedIndex: number | null;
}

export interface IUseSuggestionListHandlers {
    handleInputKeyDown: (event: KeyboardEvent) => void;
    handleItemMouseDown: (event: React.MouseEvent, index: number) => void;
    handleItemMouseEnter: (event: React.MouseEvent, index: number) => void;
}

function useSuggestionList <Suggestion>(
    { suggestions, isListOpened }: { isListOpened: boolean; suggestions: Suggestion[] },
    onSelect: (item: Suggestion) => void
): { focusedIndex: number | null; suggestionHandlers: IUseSuggestionListHandlers } {
    const suggestionsLength = useMemo(() => suggestions.length, [ suggestions ]);

    const [ state, setFocusedItem ] = useState<IUseSuggestionListState>({ focusedIndex: null });

    const handleInputKeyDown = useCallback((event: React.KeyboardEvent) => {
        switch (event.keyCode) {
            case KEY_CODE.UP:
                event.preventDefault();
                event.stopPropagation();

                setFocusedItem(({ focusedIndex, ...rest }) => ({
                    ...rest,
                    focusedIndex: focusedIndex ? focusedIndex - 1 : suggestionsLength - 1
                }));

                break;
            case KEY_CODE.DOWN:
                event.preventDefault();
                event.stopPropagation();

                setFocusedItem(({ focusedIndex, ...rest }) => ({
                    ...rest,
                    focusedIndex: focusedIndex === null || focusedIndex === suggestionsLength - 1 ? 0 : focusedIndex + 1
                }));

                break;

            case KEY_CODE.ENTER:
                if (suggestionsLength && state.focusedIndex !== null) {
                    event.preventDefault();
                    event.stopPropagation();

                    onSelect(suggestions[state.focusedIndex]);

                    setFocusedItem(prevState => ({
                        ...prevState,
                        focusedIndex: null
                    }));
                } else {
                    setFocusedItem(prevState => ({
                        ...prevState,
                        focusedIndex: null
                    }));
                }

                break;

            default:
                break;
        }

        // onKeyDown(event);
    }, [ suggestions, state ]);

    const handleItemMouseDown = useCallback((event: React.MouseEvent, index: number) => {
        event.stopPropagation();
        event.preventDefault();

        onSelect(suggestions[index]);

        setFocusedItem(prevState => ({
            ...prevState,
            focusedIndex: null
        }));
    }, [ suggestions, state ]);

    const handleItemMouseEnter = useCallback((_: React.MouseEvent, index: number) => {
        setFocusedItem(prevState => ({
            ...prevState,
            focusedIndex: index
        }));
    }, []);

    useEffect(() => {
        if (! isListOpened) {
            setFocusedItem(prevState => ({
                ...prevState,
                focusedIndex: null
            }));
        }
    }, [ isListOpened ]);

    return {
        ...state,
        suggestionHandlers: {
            handleInputKeyDown,
            handleItemMouseDown,
            handleItemMouseEnter
        }
    };
}

export default useSuggestionList;
