import React from 'react';
import {
    YMapControlClose,
    YMapControlGeoLocation,
    YMapControlGroup,
    YMapControlRuler,
    YMapControlTraffic,
    YMapControlZoom
} from '@search/ymap/src/Control';
import { YMapPane } from '@search/ymap/src/Pane';
import { YMapPlacemarkPoint } from '@search/ymap/src/Placemark';
import { YMapBounds } from '@search/ymap/src/bounds';

export function VTBCoreYMapDesktop({
    onClose,
    center,
    children,
    noZoom,
    bounds,
    noPlacemark,
    zoom = 14,
    setBounds,
    isScrollZoomEnabled,
    useChangedMargin,
    height,
    width,
    small
}: {
    bounds?: YMapBounds;
    zoom?: number;
    noZoom?: boolean;
    noPlacemark?: boolean;
    children?: React.ReactNode | React.ReactNode[];
    center?: readonly [number, number] | undefined;
    onClose?: () => void;
    setBounds?: (bounds: YMapBounds) => void;
    isScrollZoomEnabled?: boolean;
    useChangedMargin?: boolean;
    height?: number;
    width?: number;
    small?: boolean;
}) {
    return (
        <YMapPane
            zoom={zoom}
            center={center}
            bounds={bounds}
            noZoom={noZoom}
            setBounds={setBounds}
            width={width}
            height={height}
            isScrollZoomEnabled={isScrollZoomEnabled}
        >
            {noPlacemark || (
                <YMapPlacemarkPoint point={center} />
            )}
            <YMapControlGroup
                hAlign='right'
                vAlign='middle'
                layout='vertical'
                size={useChangedMargin ? 'mChangedMargin' : 'm'}
            >
                <YMapControlZoom />
                {! small && (
                    <>
                        <YMapControlGeoLocation title='Моё местоположение' />
                        <YMapControlTraffic title='Пробки' />
                        <YMapControlRuler title='Линейка' />
                    </>
                )}
            </YMapControlGroup>
            {small && (
                <YMapControlGroup
                    hAlign='left'
                    vAlign='top'
                    layout='vertical'
                    size={useChangedMargin ? 'mChangedMargin' : 'm'}
                >
                    <YMapControlTraffic title='Пробки' />
                    <YMapControlRuler title='Линейка' />
                    <YMapControlGeoLocation title='Моё местоположение' />
                </YMapControlGroup>
            )}
            <YMapControlGroup
                hAlign='right'
                vAlign='top'
                layout='horizontal'
            >
                {
                    onClose ? (
                        <YMapControlClose onClose={onClose} />
                    ) : null
                }
            </YMapControlGroup>
            {children}
        </YMapPane>
    );
}
