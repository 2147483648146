type FieldType = {
    name: string; // Название поля (длина от 1 до 15)
    value: string; // Значение поля (длина от 1 до 15)
}

export type CreateRequestSuccessResponse = {
    callbackRequestId: number; // Идентификатор заявки на обратный звонок
}

export type ErrorResponse = {
    type: CalltouchCallbackError; // Тип ошибки
    message: string; // Текст ошибки
    details: object; // Детали ошибки
}

export enum CalltouchCallbackError {
    UNKNOWN_ERROR = 'unknown_error',
    SERVER_ERROR = 'server_error',
    REQUEST_THROTTLE_TIMEOUT= 'request_throttle_timeout',
    REQUEST_THROTTLE_COUNT = 'request_throttle_count',
    VALIDATION_ERROR = 'validation_error',
    REQUEST_PHONE_BLACKLISTED = 'request_phone_blacklisted',
    REQUEST_WIDGET_NOT_FOUND = 'request_widget_not_found'
}

declare const window: Window & {
    ctw?: {
        // Создание заявки на обратный звонок от Calltouch
        createRequest: (
            routeKey: string, // Идентификатор формы (длина от 1 до 255)
            phone: string, // Номер телефона с кодом страны и города, без плюса (11 символов)
            fields: FieldType[], // Дополнительные поля (не более 5), используются для синтеза речи
            requestCallback: (success: boolean, responseData: CreateRequestSuccessResponse | ErrorResponse) => void, // функция обратного вызова для обработки результата запроса
            scheduleTime: string | null, // Время в формате Y-m-d H:i:s (например, 2018-02-12 22:33:00), на которое нужно запланировать звонок; если null, то звонок будет выполнен сразу
            tags: string[], // Пользовательские теги (не более 10),
            unitId: number, // Идентификатор отдела
        ) => void;
    };
};

type Hours = {
    start?: number;
    end?: number;
}

interface ISendCalltouchCallbackRequest {
    phone: string;
    unitId: number;
    routeKey?: string;
    fields?: FieldType[];
    hours?: Hours | null;
    tags?: string[];
}

const ROUTE_KEY = 'm2_cb';

const getScheduleTime = (hours: Hours | null) => {
    if (! hours || ! hours.start || ! hours.end) {
        return null;
    }

    const resultDate = new Date();

    resultDate.setHours(hours.start);

    resultDate.setMinutes(0);
    resultDate.setSeconds(0);

    const untilDate = new Date();

    untilDate.setHours(hours.end);
    untilDate.setMinutes(0);
    untilDate.setSeconds(0);

    const currentDate = new Date();

    // Если текущее время позднее, чем время "до" — переносим на завтра
    if (currentDate > untilDate) {
        resultDate.setDate(resultDate.getDate() + 1);
    }

    const [ date, time ] = resultDate
        .toLocaleString('ru-RU', { timeZone: 'Europe/Moscow' })
        .split(', ');

    return `${date.split('.').reverse().join('-')} ${time}`;
};

export const sendCalltouchCallbackRequest = ({
    routeKey = ROUTE_KEY,
    phone,
    fields = [],
    hours = null,
    tags = [],
    unitId
}: ISendCalltouchCallbackRequest): Promise<CreateRequestSuccessResponse> => {
    const scheduleTime = getScheduleTime(hours);

    return new Promise((res, rej) => {
        const requestCallback = makeRequestCallback(res, rej);

        window.ctw?.createRequest?.(
            routeKey,
            phone,
            fields,
            requestCallback,
            scheduleTime,
            tags,
            unitId
        );
    });
};

const makeRequestCallback = (
    resolve: (value: CreateRequestSuccessResponse) => void,
    reject: (reason?: ErrorResponse) => void
) => (
    success: boolean,
    responseData: CreateRequestSuccessResponse | ErrorResponse
) => {
    if (success) {
        return resolve(responseData as CreateRequestSuccessResponse);
    }

    const error = responseData as ErrorResponse;

    // eslint-disable-next-line no-console
    console.error('sendCalltouchCallbackRequest error', error);

    return reject(error);
};
