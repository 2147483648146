import React, { useCallback } from 'react';
import { Size } from '@vtblife/uikit/legacy';
import { FloorConstraints } from '@search/filter-enums/enums';

import { FloorFilter } from '../../../../models/Filter';
import MobileFilter from '../../../filter/MobileFilter';
import '../MobileCommon.css';
import { NumberInputRange } from '../../../common/NewNumberInputRange/NumberInputRange';
import { MultiSelect } from '../../common/MultiSelect';

interface IMobileFloorFilterViewProps {
    filter: FloorFilter;
    handleChange: (filter: FloorFilter) => void;
}

enum Floors {
    NotFirst = 1,
    NotLast = 2,
    Last = 3
}

function getFirst(added: Floors[], removed: Floors[]) {
    if (removed.includes(Floors.NotFirst)) {
        return FloorConstraints.UNKNOWN;
    } else if (added.includes(Floors.NotFirst)) {
        return FloorConstraints.EXCLUDE;
    }
    return null;
}

function getLast(added: Floors[], removed: Floors[]) {
    if (removed.includes(Floors.NotLast) || removed.includes(Floors.Last)) {
        return FloorConstraints.UNKNOWN;
    } else if (added.includes(Floors.NotLast)) {
        return FloorConstraints.EXCLUDE;
    } else if (added.includes(Floors.Last)) {
        return FloorConstraints.ONLY;
    }

    return null;
}

const selectOptions = [
    {
        label: 'Не первый',
        value: Floors.NotFirst,
        className: 'col-xs-12 filter-item-gap'
    },
    {
        label: 'Не последний',
        value: Floors.NotLast,
        className: 'col-xs-12 filter-item-gap'
    },
    {
        label: 'Последний',
        value: Floors.Last,
        className: 'col-xs-12 filter-item-gap'
    }
];

export const MobileFloorFilterView: React.FC<IMobileFloorFilterViewProps> = ({
    filter,
    handleChange
}) => {
    const value = getValue(filter);
    const onChange = useCallback(newValue => {
        handleChange(new FloorFilter(newValue, filter.first, filter.last));
    }, [ filter, handleChange ]);

    const onChangeFloor = useCallback((newValue: Floors[], oldValue: Floors[]) => {
        const added = newValue.filter(x => ! oldValue.includes(x));
        const removed = oldValue.filter(x => ! newValue.includes(x));

        const first = getFirst(added, removed);
        const firstValue = (first === null || first === undefined) ? filter.first : first;

        const last = getLast(added, removed);
        const lastValue = (last === null || last === undefined) ? filter.last : last;

        handleChange(new FloorFilter(filter.range, firstValue, lastValue));
    }, [ value, handleChange ]);

    return (
        <MobileFilter
            filterTitle='Этаж'
        >
            <div className='container'>
                <div className={'col-xs-12 filter-item-gap'}>
                    <NumberInputRange
                        size={Size.Small}
                        value={filter.range}
                        onChange={onChange}
                        max={1_000}
                        nameFrom='floorFrom'
                        nameTo='floorTo'
                    />
                </div>
            </div>
            <MultiSelect
                className='container container__clamp'
                name='floor'
                options={selectOptions}
                value={value}
                // @ts-ignore
                handleChange={onChangeFloor}
            />
        </MobileFilter>
    );
};

function getValue(filter: FloorFilter): Floors[] {
    const result = [];

    if (FloorConstraints.EXCLUDE === filter.first) {
        result.push(Floors.NotFirst);
    }
    if (FloorConstraints.EXCLUDE === filter.last) {
        result.push(Floors.NotLast);
    }
    if (FloorConstraints.ONLY === filter.last) {
        result.push(Floors.Last);
    }

    return result;
}

