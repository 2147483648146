import React from 'react';

export const IconBus = ({
    color = '#B6B9C9'
}: {
    color?: string;
}) => (
    <svg width='16' height='16' viewBox='0 0 16 16' fill={color} xmlns='http://www.w3.org/2000/svg'>
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M14.4305 9.23685C14.4338 9.31745 14.4366 9.39787 14.4393 9.47847L14.4988 11.3131C14.5167 11.8656 14.3374 12.4028 14 12.8335V14.5C14 14.7761 13.7761 15 13.5 15H12C11.7239 15 11.5 14.7761 11.5 14.5V13.7317H4.5V14.5C4.5 14.7761 4.27614 15 4 15H2.5C2.22386 15 2 14.7761 2 14.5V12.834C1.66271 12.4034 1.48334 11.8664 1.50122 11.314L1.56073 9.47999C1.56339 9.39863 1.56624 9.31726 1.56947 9.2359L1.84971 2.10128C1.93983 1.46932 2.47996 1 3.11686 1H12.8833C13.5202 1 14.0604 1.46932 14.1505 2.10128L14.4305 9.23685ZM2.63167 10.9097C2.63167 11.5152 3.12123 12.0061 3.72544 12.0061C4.32964 12.0061 4.81939 11.5152 4.81939 10.9097C4.81939 10.3041 4.32964 9.81364 3.72544 9.81364C3.12123 9.81364 2.63167 10.3041 2.63167 10.9097ZM11.1806 10.9097C11.1806 11.5152 11.6705 12.0061 12.2746 12.0061C12.8788 12.0061 13.3683 11.5152 13.3683 10.9097C13.3683 10.3041 12.8788 9.81364 12.2746 9.81364C11.6705 9.81364 11.1806 10.3041 11.1806 10.9097ZM3.24462 8.61528C6.26696 9.11814 9.45127 9.07774 12.7394 8.59775C13.1337 8.54021 13.4233 8.1955 13.4115 7.7963C13.3712 6.43979 13.2991 3.57755 13.2991 3.57755C13.2647 3.33175 13.0546 3.14634 12.8107 3.14634C11.4481 2.88167 4.67718 2.90225 3.18968 3.14634C2.94575 3.14634 2.73567 3.33175 2.70126 3.57774C2.70126 3.57774 2.62844 6.46599 2.58794 7.81593C2.57616 8.21018 2.8564 8.55069 3.24462 8.61528ZM7 1.5C6.72386 1.5 6.5 1.72386 6.5 2C6.5 2.27614 6.72386 2.5 7 2.5H9C9.27614 2.5 9.5 2.27614 9.5 2C9.5 1.72386 9.27614 1.5 9 1.5H7Z'
        />
    </svg>
);
