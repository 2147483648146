/* eslint-disable camelcase */
import React from 'react';
import { googleOptimizeCallbackEvent } from './googleOptimizeCallbackEvent';
import { dataLayerPush } from './dataLayerPush';

/**
 * @see https://support.google.com/optimize/answer/9059383?hl=en
 */
interface GoogleOptimize {
    get<Variant>(experimentId: string): Variant | undefined;
}

declare const window: Window & {
    // eslint-disable-next-line camelcase
    google_optimize?: GoogleOptimize;
};

export type GoogleOptimizeConfig = Record<string, string | undefined>;

const googleOptimizeConfigDefault = {} as GoogleOptimizeConfig;

const GoogleOptimizeContext = React.createContext(googleOptimizeConfigDefault);

GoogleOptimizeContext.displayName = 'GoogleOptimizeContext';

export function GoogleOptimizeProvider({
    children,
    value = googleOptimizeConfigDefault
}: {
    value?: GoogleOptimizeConfig;
    children?: React.ReactChild;
}) {
    return (<GoogleOptimizeContext.Provider value={value}>
        {children}
    </GoogleOptimizeContext.Provider>);
}

let activated = false;

export function useGoogleOptimize<Variant = string>(
    {
        experimentLabel,
        checkVariantTimeout = 1000
    }: {
        checkVariantTimeout?: number;
        experimentLabel: string;
    }
) {
    const config = React.useContext(GoogleOptimizeContext);
    const experimentId = config[experimentLabel];

    if (! activated && experimentId) {
        dataLayerPush({ event: 'optimize.activate' });
        activated = true;
    }

    const win = typeof window === 'undefined' ? undefined : window;

    const getVariantId = React.useCallback((nextVariantId?: Variant | undefined) => {
        // for testing
        let overridedVariant = experimentId ?
            (win?.sessionStorage.getItem(experimentId) as unknown as Variant) ?? undefined :
            undefined;

        if (! overridedVariant && experimentId && win?.location.search) {
            overridedVariant = (new URLSearchParams(win.location.search)
                .get(`dbg_${experimentLabel}`)) as unknown as NonNullable<Variant>;
        }

        const debugApiVariant = experimentId ? win?.google_optimize?.get<Variant>(experimentId) : undefined;

        const realVariant = nextVariantId ?? debugApiVariant;

        if (overridedVariant !== undefined && overridedVariant !== null) {
            // eslint-disable-next-line no-console
            console.log(
                `Google optimize experiment \`${experimentId}\` variant \`${
                    realVariant ?? 'off'}\` overrided by \`${overridedVariant}\``
            );
        }

        return overridedVariant ?? realVariant;
    }, [ experimentId, win ]);

    const variantIdRef = React.useRef(getVariantId());

    const [ variantId, setVariantId ] = React.useState(variantIdRef.current);

    const onUpdateVariant = React.useCallback((nextVariantIdRaw?: Variant | undefined) => {
        const nextVariantId = getVariantId(nextVariantIdRaw);

        if (nextVariantId === variantIdRef.current) return;
        variantIdRef.current = nextVariantId;
        setVariantId(nextVariantId);
    }, [ setVariantId, getVariantId ]);

    React.useEffect(() => {
        if (! experimentId) return;

        googleOptimizeCallbackEvent({
            experimentId,
            onUpdateVariant
        });

        let count = 0;

        // @see https://github.com/hudovisk/react-optimize/blob/master/src/Experiment.js
        const handler = variantIdRef.current !== undefined ? undefined : setInterval(() => {
            const googleOptimize = win?.google_optimize;

            if (count > 10) {
                clearInterval(handler);
            }
            count++;

            if (! googleOptimize) return;

            clearInterval(handler);
            onUpdateVariant();
        }, checkVariantTimeout) as unknown as number;

        return () => {
            clearInterval(handler);
            googleOptimizeCallbackEvent({
                experimentId,
                onUpdateVariant,
                remove: true
            });
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ experimentId, onUpdateVariant, checkVariantTimeout, variantIdRef ]);

    return {
        variantId
    };
}
