/* eslint-disable comma-dangle, react-hooks/exhaustive-deps, no-implicit-coercion */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import classname from '@search/classname/src';
import { useKeyPress } from '@search/hooks/src/useKeyPress';
import {
    ARROW_UP,
    ARROW_RIGHT,
    ARROW_DOWN,
    ARROW_LEFT,
} from '../../../../../constants/keycode';
import { Picture, PictureWrapper } from '../Picture/Picture';
import { HeroPicture, HeroPictureWrapper } from '../HeroPicture/HeroPicture';
import {
    SizeThumbnail,
    PADDED_PREVIEW,
    WIDTH_THUMBNAIL,
    HEIGHT_THUMBNAIL,
    OFFSET_THUMBNAIL,
    FigcaptionPosition,
    FigcaptionPositionColor
} from '../constants';
import './styles.css';
import { Mortgage2IconM2 } from '../../../MinimalBanksMortgage/mortgage2/icon/Mortgage2IconM2';

export interface IGallery {
    srcImages: {
        preview: string[];
        origin: string[];
        attributesType?: ('LAYOUT' | 'UNKNOWN' | null | undefined)[];
    };
    initialIndexSrcImage: number;
    isOpenRepairForm?: boolean;
    size: SizeThumbnail;
    figcaptionPosition: FigcaptionPosition;
    figcaptionPositionColor: FigcaptionPositionColor;
    renderArrow?: boolean;
    offerTitle?: string;
    withCount?: boolean;
    urlErrorImage?: string;
    clickByCenter?: () => void;
    withDuplicateSearchButton?: boolean;
    starterBanner?: React.ReactNode;
    repairBanner?: React.ReactNode;
    hideThumbnails?: boolean;
}

const cn = classname.bind(null, 'GalleryDesktop');

const FIRST_IMAGE_INDEX = 0;

type ImgRec = ({
    t: 'img';
    origin: string;
    preview: string;
    alt: string;
    index: number;
    attributesType?: 'UNKNOWN' | 'LAYOUT' | null;
} | {
    t: 'mortgage' | 'repair';
}) & {
    id: string;
    next?: ImgRec;
    prev?: ImgRec;
};

// eslint-disable-next-line complexity
function getImgRec(
    srcImages: IGallery['srcImages'],
    {
        isMortgage = false,
        isRepair = false,
        isOpenRepairForm = false,
        initialIndexSrcImage = 0
    }: {
        isMortgage?: boolean;
        isRepair?: boolean;
        isOpenRepairForm?: boolean;
        initialIndexSrcImage?: number;
    }
) {
    const items: ImgRec[] = [];
    let firstItem: undefined | ImgRec;
    let lastItem: undefined | ImgRec;
    let initialItem: undefined | ImgRec;
    let prev: undefined | ImgRec;
    let mortgageRec: ImgRec | undefined;
    let repairRec: ImgRec | undefined;
    const indexPositionMortgageBanner = isRepair ? 7 : 5;
    const indexPositionRepairBanner = 3;

    for (let i = 0; i < srcImages.preview.length; i++) {
        if ((! isRepair || srcImages.preview.length > 5) && isMortgage && i === indexPositionMortgageBanner) {
            mortgageRec = { t: 'mortgage', prev, next: undefined, id: 'gallery-preview-mortgage' };

            if (prev) prev.next = mortgageRec;

            items.push(mortgageRec);
            prev = mortgageRec;
        }

        if (isRepair && i === indexPositionRepairBanner) {
            repairRec = { t: 'repair', prev, next: undefined, id: 'gallery-preview-repair' };

            if (prev) prev.next = repairRec;

            items.push(repairRec);
            prev = repairRec;
        }

        const imgRec: ImgRec = {
            t: 'img',
            index: i,
            id: `gallery-preview-img-${i}`,
            origin: srcImages.origin[i],
            preview: srcImages.preview[i],
            attributesType: srcImages.attributesType?.[i],
            alt: '',
            prev,
            next: undefined
        };

        if (prev) prev.next = imgRec;

        items.push(imgRec);
        prev = imgRec;

        if (i === 0) firstItem = imgRec;
        if (i === initialIndexSrcImage) {
            initialItem = imgRec;
        }
        if (isRepair && isOpenRepairForm && i === indexPositionRepairBanner) {
            initialItem = repairRec;
        }
        lastItem = imgRec;
    }

    if (isRepair && isOpenRepairForm && srcImages.preview.length < 4) {
        repairRec = { t: 'repair', prev, next: undefined, id: 'gallery-preview-repair' };
        initialItem = repairRec;
        if (prev) prev.next = repairRec;
        items.push(repairRec);
    }

    if (isMortgage && ! mortgageRec && (srcImages.preview.length > 5 || ! isRepair)) {
        mortgageRec = { t: 'mortgage', prev, next: undefined, id: 'gallery-preview-mortgage' };
        if (prev) prev.next = mortgageRec;
        items.push(mortgageRec);
    }
    if (isMortgage && ! isRepair && firstItem && ! firstItem.prev && srcImages.preview.length <= indexPositionMortgageBanner) {
        firstItem.prev = mortgageRec;
    }

    if (isRepair && ! repairRec) {
        repairRec = { t: 'repair', prev, next: undefined, id: 'gallery-preview-repair' };
        if (prev) prev.next = repairRec;
        items.push(repairRec);
    }

    return { items, totalImages: srcImages.preview.length, initialItem, firstItem, lastItem };
}

export const Gallery: React.FC<IGallery> = ({
    size,
    starterBanner,
    repairBanner,
    srcImages,
    initialIndexSrcImage = FIRST_IMAGE_INDEX,
    isOpenRepairForm = false,
    figcaptionPosition = FigcaptionPosition.TOP,
    figcaptionPositionColor = FigcaptionPositionColor.WHITE,
    offerTitle,
    withCount,
    renderArrow = true,
    urlErrorImage,
    clickByCenter,
    withDuplicateSearchButton,
    hideThumbnails,
}) => {
    const {
        items,
        totalImages,
        initialItem,
        firstItem,
        lastItem
    } = React.useMemo(
        () => getImgRec(srcImages, { isMortgage: Boolean(starterBanner), isRepair: Boolean(repairBanner), initialIndexSrcImage, isOpenRepairForm }),
        [
            starterBanner,
            repairBanner,
            initialIndexSrcImage,
            isOpenRepairForm,
            srcImages.preview.join(';')
        ]
    );

    const [ hero, setHeroRaw ] = useState(initialItem);

    const changeHero = React.useCallback((current?: ImgRec) => {
        if (! current) return;

        setHeroRaw(current);
        document.getElementById(current.id)?.focus();
    }, [ ]);

    const handlePrevClick = useCallback(() => {
        changeHero(hero?.prev ?? lastItem);
    }, [ lastItem, hero ]);

    const handleNextClick = useCallback(() => {
        changeHero(hero?.next ?? firstItem);
    }, [ firstItem, hero ]);

    const thumbnailsWrapRef = useRef<HTMLDivElement>(null);

    const upKey = useKeyPress(ARROW_UP);
    const rightKey = useKeyPress(ARROW_RIGHT);
    const downKey = useKeyPress(ARROW_DOWN);
    const leftKey = useKeyPress(ARROW_LEFT);

    useEffect(() => {
        if (hero) document.getElementById(hero.id)?.focus();
    }, []);

    useEffect(() => {
        upKey && handleNextClick();
        rightKey && handleNextClick();
        downKey && handlePrevClick();
        leftKey && handlePrevClick();
    }, [
        upKey,
        leftKey,
        rightKey,
        downKey
    ]);

    useEffect(() => {
        const wrapEl = thumbnailsWrapRef.current;
        const thumbEl = hero ? document.getElementById(hero.id) : undefined;

        if (! wrapEl || ! thumbEl) return;
        const thumbRect = thumbEl?.getBoundingClientRect() ?? { x: 0, y: 0 };
        const wrapRect = wrapEl?.getBoundingClientRect() ?? { width: 0, height: 0 };

        const thumbWidth = WIDTH_THUMBNAIL[size];
        const step = thumbWidth + OFFSET_THUMBNAIL;

        if (
            (thumbRect.x + thumbWidth * 1.5) > wrapRect.width
        ) {
            wrapEl.scrollLeft += step;
        }

        if (
            (thumbRect.x < 0) || (thumbRect.x < thumbWidth * 1.5)
        ) {
            wrapEl.scrollLeft -= step;
        }

        if (hero?.prev?.t === 'img') new Image().src = hero.prev.origin;
        if (hero?.next?.t === 'img') new Image().src = hero.next.origin;
    }, [ hero ]);

    return (
        <div className={cn(null, { size, withTopTitle: figcaptionPosition === FigcaptionPosition.TOP })}>
            {hero?.t === 'img' && <HeroPicture
                src={hero.origin}
                alt={hero.alt}
                size={size}
                handleNext={handleNextClick}
                handlePrev={handlePrevClick}
                countText={`${hero ? hero.index + 1 : 0} из ${totalImages}`}
                offerTitle={offerTitle}
                figcaptionPosition={figcaptionPosition}
                figcaptionPositionColor={figcaptionPositionColor}
                withCount={withCount}
                renderArrow={renderArrow}
                urlErrorImage={urlErrorImage}
            />}
            {hero?.t === 'repair' && repairBanner && <HeroPictureWrapper
                size={size}
                isAltView
                handleNext={handleNextClick}
                handlePrev={handlePrevClick}
                renderArrow={renderArrow}
            >
                {repairBanner}
            </HeroPictureWrapper>}

            {hero?.t === 'mortgage' && starterBanner && <HeroPictureWrapper
                size={size}
                isAltView
                handleNext={handleNextClick}
                handlePrev={handlePrevClick}
                renderArrow={renderArrow}
            >
                {starterBanner}
            </HeroPictureWrapper>}

            {withDuplicateSearchButton && hero?.t === 'img' && (
                <a
                    href={`https://yandex.ru/images/search?rpt=imageview&url=${hero.origin}`}
                    className={cn('duplicateButton')}
                    target='_blank'
                >
                    Дубль?
                </a>
            )}

            {hideThumbnails ? null : (<div
                style={{ paddingTop: PADDED_PREVIEW[size], }}
                className={cn('thumbnails')}
                data-test='galleryDesktop-thumbnails'
                ref={thumbnailsWrapRef}
            >
                {items.map(rec => (
                    <button
                        type='button'
                        key={rec.id}
                        className={cn('control')}
                        id={rec.id}
                        onClick={changeHero.bind(null, rec)}
                    >
                        {rec.t === 'img' && <Picture
                            src={rec.preview}
                            alt={rec.alt}
                            isLayout={rec.attributesType === 'LAYOUT'}
                            width={WIDTH_THUMBNAIL[size]}
                            height={HEIGHT_THUMBNAIL[size]}
                            active={rec === hero}
                        />}
                        {rec.t === 'repair' && (
                            <PictureWrapper
                                width={WIDTH_THUMBNAIL[size]}
                                height={HEIGHT_THUMBNAIL[size]}
                                active={rec === hero}
                                isAltView
                            >
                                <Mortgage2IconM2 className={cn('m2Icon', { isActive: rec === hero })} />
                            </PictureWrapper>
                        )}
                        {rec.t === 'mortgage' && (
                            <PictureWrapper
                                width={WIDTH_THUMBNAIL[size]}
                                height={HEIGHT_THUMBNAIL[size]}
                                active={rec === hero}
                                isAltView
                            >
                                <Mortgage2IconM2 className={cn('m2Icon', { isActive: rec === hero })} />
                            </PictureWrapper>
                        )}
                    </button>
                ))}
            </div>)}

            {clickByCenter && <div className={cn('place-click')} onClick={clickByCenter} />}
        </div>
    );
};
