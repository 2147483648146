/**
 * Ипотечный брокер принимает только числовые значения в get параметрах.
 */

export enum MortgagePurposeEnum {
    PRIMARY = 1,
    SECONDARY = 2,
    REFINANCE = 3,
    PHP = 4,
    FAMILY = 5,
    UNKNOWN = 0
}
