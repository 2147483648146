import { SeoMapper } from '@search/router/src';

export const lenOblDistrict = {
    boksitogorskii: 14552,
    volosovskii: 4511,
    volkhovskii: 4672,
    vsevolozhskii: 887,
    viborgskii_lo: 1060,
    gatchinskii: 3486,
    kingiseppskii: 4318,
    kirishskii: 8525,
    kirovskii_lo: 7811,
    lodeinopolskii: 3598,
    lomonosovskii: 996,
    luzhskii: 4715,
    podporozhskii: 14561,
    priozerskii: 1376,
    slantsevskii: 14564,
    sosnovoborskii: 7860,
    tikhvinskii: 7885,
    tosnenskii: 1032
} as const;

export const spbDistrict = {
    admiralteiskii: 446,
    vasileostrovskii: 448,
    viborgskii: 450,
    kalininskii: 451,
    kirovskii: 453,
    kolpinskii: 455,
    krasnogvardeiskii: 456,
    krasnoselskii: 458,
    kronshtadtskii: 460,
    kurortnii: 461,
    moskovskii: 462,
    nevskii: 464,
    petrogradskii: 466,
    petrodvortsovii: 468,
    primorskii: 471,
    pushkinskii: 473,
    frunzenskii: 474,
    tsentralnii: 476
} as const;

export const spbDistrictMapper = new SeoMapper('spbDistrictMapper', {
    ...lenOblDistrict,
    ...spbDistrict
} as const);

export const lenOblDistrictIds = Object.values(lenOblDistrict);
export const spbDistrictIds = Object.values(spbDistrict);
