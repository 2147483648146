import { ReactText, useCallback, useState } from 'react';

export interface IWorkingHour {
    value: number;
    label: string;
    disabled: boolean;
}

function format(hour?: ReactText) {
    if (hour === undefined) return;
    return `${hour.toString().padStart(2, '0')}:00`;
}

function getSelectValues(
    startHour: number,
    endHour: number,
    workingHoursRange: {
        start?: number;
        end?: number;
    },
    options?: {
        filterHours?: { start: string; end: string };
        withoutOffset?: boolean;
    }
) {
    const startHourValues: IWorkingHour[] = [];
    const endHourValues: IWorkingHour[] = [];

    for (let hour = startHour; hour <= endHour; hour++) {
        const label = format(hour) ?? '';

        if (
            (options?.withoutOffset || hour !== endHour) &&
            label.includes(options?.filterHours?.start ?? '')
        ) {
            startHourValues.push({
                label,
                value: hour,
                disabled: workingHoursRange.end !== undefined && hour >= workingHoursRange.end
            });
        }
        if (
            (options?.withoutOffset || hour !== startHour) &&
            label.includes(options?.filterHours?.end ?? '')
        ) {
            endHourValues.push({
                label,
                value: hour,
                disabled: workingHoursRange.start !== undefined && hour <= workingHoursRange.start
            });
        }
    }

    return { startHourValues, endHourValues };
}

type TUseWorkingHours = {
    startHour?: number;
    endHour?: number;
    withoutStartValues?: boolean;
    filterHours?: { start: string; end: string };
    withoutOffset?: boolean;
}

export function useWorkingHours({
    startHour = 9,
    endHour = 21,
    withoutStartValues,
    filterHours,
    withoutOffset
}: TUseWorkingHours = {}) {
    const [ workingHoursRange, setWorkingHouseRange ] = useState({
        start: withoutStartValues ? undefined : startHour,
        end: withoutStartValues ? undefined : endHour
    });
    const { startHourValues, endHourValues } = getSelectValues(
        startHour,
        endHour,
        workingHoursRange,
        {
            filterHours,
            withoutOffset
        }
    );

    const setStartHoursRange = useCallback((startValue: ReactText, checked: boolean, e?: any) => {
        e?.preventDefault();
        setWorkingHouseRange(state => ({ ...state, start: typeof startValue === 'number' ? startValue : undefined }));
    }, [ startHourValues ]);

    const setEndHoursRange = useCallback((endValue: ReactText, checked: boolean, e?: any) => {
        e?.preventDefault();
        setWorkingHouseRange(state => ({ ...state, end: typeof endValue === 'number' ? endValue : undefined }));
    }, [ endHourValues ]);

    return {
        rawWorkingHoursRange: {
            start: workingHoursRange.start,
            end: workingHoursRange.end
        },
        workingHoursRange: {
            start: format(workingHoursRange.start),
            end: format(workingHoursRange.end)
        },
        setStartHoursRange,
        setEndHoursRange,
        startHourValues,
        endHourValues
    };
}
