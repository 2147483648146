import React, { HTMLAttributes, RefObject } from 'react';

import { cnPopup } from '../Popup';
import './PopupTail.css';

export interface IPopupTailProps extends HTMLAttributes<HTMLDivElement> {
    /**
     * Ссылка на хвостик.
     */
    innerRef?: RefObject<HTMLDivElement>;
}

export const PopupTail = ({
    className,
    innerRef,
    ...props
}: IPopupTailProps): React.ReactElement => (
    <div
        {...props}
        className={cnPopup('tail', undefined, className)}
        ref={innerRef}
    />
);
