import './styles.css';

import React, { useCallback, useMemo } from 'react';
import { Button } from '@vtblife/uikit';

import classname from '@search/classname/src';

const cn = classname.bind(null, 'NewMoscowDistrict');

interface IProps {
    onChange: (data: { diff: { selected: number[]; deselected: number[] }}) => void;
    selectedDistricts?: Set<number>;
}

const NEW_MOSCOW_DISTRICT = 141530;
const EXCEPT_NEW_MOSCOW_DISTRICTS = [ 381, 103, 81, 182, 218, 15, 60, 164, 37, 7 ];

export const NewMoscowDistricts: React.FC<IProps> = ({ onChange, selectedDistricts = new Set() }) => {
    const isNewMoscowSelected = useMemo(() => {
        return selectedDistricts.size === 1 && selectedDistricts.has(NEW_MOSCOW_DISTRICT);
    }, [ selectedDistricts ]);

    const isExceptNewMoscowSelected = useMemo(() => {
        const intersectExpectNewMoscowDistricts = new Set([ ...selectedDistricts, ...EXCEPT_NEW_MOSCOW_DISTRICTS ]);

        return selectedDistricts.size !== 0 &&
            intersectExpectNewMoscowDistricts.size === EXCEPT_NEW_MOSCOW_DISTRICTS.length &&
            intersectExpectNewMoscowDistricts.size === selectedDistricts.size;
    }, [ selectedDistricts ]);

    const selectNewMoscowDistricts = useCallback(() => {
        const deselected = [ ...selectedDistricts ].filter(value => value !== NEW_MOSCOW_DISTRICT);

        onChange({
            diff: {
                selected: [ NEW_MOSCOW_DISTRICT ],
                deselected
            }
        });
    }, [ onChange, selectedDistricts ]);

    const selectExceptNewMoscowDistricts = useCallback(() => {
        const deselected = [ ...selectedDistricts ].filter(value => ! EXCEPT_NEW_MOSCOW_DISTRICTS.includes(value));

        onChange({
            diff: {
                selected: EXCEPT_NEW_MOSCOW_DISTRICTS,
                deselected
            }
        });
    }, [ onChange, selectedDistricts ]);

    return (<>
        <div className={cn('new-moscow-button')}>
            <Button
                variant='secondary'
                size='s'
                fullWidth
                selected={isNewMoscowSelected}
                onClick={selectNewMoscowDistricts}
            >
                Новая Москва
            </Button>
        </div>
        <Button
            variant='secondary'
            size='s'
            fullWidth
            selected={isExceptNewMoscowSelected}
            onClick={selectExceptNewMoscowDistricts}
        >
            Без Новой Москвы
        </Button>
    </>);
};
