import { graphql } from '@search/gql-client/src';

export const SearchOffersCountQuery = graphql`query SearchOffersCountQuery(
    $filters: ProfessionalSearchInput
) {
    searchOffersSummary(filters: $filters) {
        count
    }
}
`;
