import { PredefinedPrice } from '../types/PredefinedPrice';

export const sellFlatPricesPerAllSquareMsk: PredefinedPrice[] = [ {
    from: 1000000,
    to: 2500000
}, {
    from: 2500000,
    to: 3000000
}, {
    from: 3000000,
    to: 4000000
}, {
    from: 4000000,
    to: 5000000
}, {
    from: 5000000,
    to: 8000000
}, {
    from: 8000000,
    to: 10000000
}, {
    from: 10000000,
    to: 15000000
}, {
    from: 15000000,
    to: 20000000
}, {
    from: 20000000,
    to: 50000000
}, {
    from: 50000000,
    to: 100000000
} ];

export const sellFlatPricesPerAllSquareSpb: PredefinedPrice[] = [ {
    from: 1000000,
    to: 2000000
}, {
    from: 2000000,
    to: 3000000
}, {
    from: 3000000,
    to: 4000000
}, {
    from: 4000000,
    to: 5000000
}, {
    from: 5000000,
    to: 6000000
}, {
    from: 6000000,
    to: 8000000
}, {
    from: 8000000,
    to: 10000000
}, {
    from: 10000000,
    to: 15000000
}, {
    from: 15000000,
    to: 30000000
}, {
    from: 30000000,
    to: 50000000
} ];

export const sellFlatPricesPerAllSquare: PredefinedPrice[] = [ {
    from: 1000000,
    to: 5000000
}, {
    from: 2000000,
    to: 7500000
}, {
    from: 3000000,
    to: 10000000
}, {
    from: 4000000,
    to: 15000000
}, {
    from: 5000000,
    to: 20000000
}, {
    from: 10000000,
    to: 30000000
}, {
    from: 15000000,
    to: 40000000
}, {
    from: 20000000,
    to: 50000000
}, {
    from: 30000000,
    to: 75000000
}, {
    from: 50000000,
    to: 100000000
} ];

export const sellFlatPricesPerMeterMskMo: PredefinedPrice[] = [ {
    from: 30000,
    to: 50000
}, {
    from: 50000,
    to: 75000
}, {
    from: 75000,
    to: 100000
}, {
    from: 100000,
    to: 150000
}, {
    from: 150000,
    to: 200000
}, {
    from: 200000,
    to: 250000
}, {
    from: 250000,
    to: 300000
}, {
    from: 300000,
    to: 400000
}, {
    from: 400000,
    to: 500000
}, {
    from: 500000,
    to: 800000
} ];

export const sellFlatPricesPerMeterSpbLo: PredefinedPrice[] = [ {
    from: 20000,
    to: 40000
}, {
    from: 40000,
    to: 60000
}, {
    from: 60000,
    to: 80000
}, {
    from: 80000,
    to: 100000
}, {
    from: 100000,
    to: 120000
}, {
    from: 120000,
    to: 150000
}, {
    from: 150000,
    to: 200000
}, {
    from: 200000,
    to: 250000
}, {
    from: 250000,
    to: 350000
}, {
    from: 350000,
    to: 500000
} ];

export const rentFlatPricesMsk: PredefinedPrice[] = [ {
    from: 10000,
    to: 15000
}, {
    from: 15000,
    to: 20000
}, {
    from: 20000,
    to: 25000
}, {
    from: 25000,
    to: 30000
}, {
    from: 30000,
    to: 40000
}, {
    from: 40000,
    to: 50000
}, {
    from: 50000,
    to: 60000
}, {
    from: 60000,
    to: 80000
}, {
    from: 80000,
    to: 100000
}, {
    from: 100000,
    to: 150000
} ];

export const rentFlatPricesSpb: PredefinedPrice[] = [ {
    from: 10000,
    to: 15000
}, {
    from: 15000,
    to: 20000
}, {
    from: 20000,
    to: 25000
}, {
    from: 25000,
    to: 30000
}, {
    from: 30000,
    to: 35000
}, {
    from: 35000,
    to: 40000
}, {
    from: 40000,
    to: 50000
}, {
    from: 50000,
    to: 60000
}, {
    from: 60000,
    to: 80000
}, {
    from: 80000,
    to: 120000
} ];

export const rentFlatPrices: PredefinedPrice[] = [ {
    from: 10000,
    to: 30000
}, {
    from: 15000,
    to: 35000
}, {
    from: 20000,
    to: 40000
}, {
    from: 25000,
    to: 45000
}, {
    from: 30000,
    to: 50000
}, {
    from: 35000,
    to: 60000
}, {
    from: 40000,
    to: 70000
}, {
    from: 45000,
    to: 80000
}, {
    from: 50000,
    to: 90000
}, {
    from: 55000,
    to: 100000
} ];

export const mortgagePaymentMskAndSpb = [ 35000, 50000, 70000, 90000, 120000, 150000 ];

export const mortgagePaymentRegions = [ 25000, 35000, 50000, 70000, 90000, 120000, 150000 ];

/**
 * Вычисляет стоимость объекта по ежемесячному платежу и годовой ставке
 * Формула для вычисления: s = p * (x/y), где x = (1+r)^n - 1, y = r * (1+r)^n
 * p - ежемесячный платеж, r - ежемесячная процентная ставка, n - срок кредита в месяцах (по умолчанию 360 = 30 лет)
 * @param {number} [p] ежемесячный платеж
 * @returns {number}
 */
export const priceByMortgagePayment = (p: number, rate: number = 10.5) => {
    // процентная ставка в год
    const r = rate / 12 / 100;
    const n = 360;
    const x = Math.pow(1 + r, n) - 1;
    const y = r * Math.pow(1 + r, n);
    const s = p * (x / y);
    // Учтем первоначальный взнос 20%
    const l = s / 0.8;

    // Округлим до порядков десятков тысяч
    return Math.trunc(l / 10000) * 10000;
};

/**
 * Вычисляет приблизительный платеж объекта по стоимости и годовой ставке
 * Формула для вычисления: p = s / (x/y), где x = (1+r)^n - 1, y = r * (1+r)^n
 * Просто разворот формулы выше
 * p - ежемесячный платеж, r - ежемесячная процентная ставка, n - срок кредита в месяцах (по умолчанию 360 = 30 лет)
 * @param {number} [s] стоимость объекта
 * @returns {number}
 */
export const mortgagePaymentByPrice = (s: number, rate: number = 10.5) => {
    const r = rate / 12 / 100;
    const n = 360;
    const x = Math.pow(1 + r, n) - 1;
    const y = r * Math.pow(1 + r, n);

    // Учтем первоначальный взнос 20%
    return Math.trunc((s * 0.8) / (x / y));
};
