import React, { useCallback } from 'react';

import { Size } from '@vtblife/uikit/legacy';
import { FloorsTotalFilter } from '../../../../models/Filter';
import MobileFilter from '../../../filter/MobileFilter';
import { NumberInputRange } from '../../../common/NewNumberInputRange/NumberInputRange';
import '../MobileCommon.css';

interface IMobileFloorsTotalFilterViewProps {
    filter: FloorsTotalFilter;
    handleChange: (value: FloorsTotalFilter) => void;
}

export const MobileFloorsTotalFilterView: React.FC<IMobileFloorsTotalFilterViewProps> = ({
    filter,
    handleChange
}) => {
    const onChange = useCallback(value => {
        handleChange(new FloorsTotalFilter(value));
    }, [ handleChange ]);

    return (
        <MobileFilter
            filterTitle='Этажей в доме'
        >
            <div className='container'>
                <div className={'col-xs-12'}>
                    <NumberInputRange
                        size={Size.Small}
                        value={filter.range}
                        onChange={onChange}
                        nameFrom='floorsTotalFrom'
                        nameTo='floorsTotalTo'
                        max={1_000}
                    />
                </div>
            </div>
        </MobileFilter>
    );
};
