import React from 'react';
import classname from '@search/classname/src';
import './styles.css';

const cn = classname.bind(null, 'NavButtons');

export type ArrowDirection = 'left' | 'right';

interface IPropsButtonArrow {
    direction: ArrowDirection;
}

const Arrow = ({
    direction
}: IPropsButtonArrow) => (
    <div className={cn('arrow', { direction })}>
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                // eslint-disable-next-line max-len
                d='M14.6026 19.2746C15.0013 19.6568 15.6477 19.6568 16.0464 19.2746L16.2648 19.0652C16.6636 18.6831 16.6636 18.0635 16.2648 17.6813L10.3377 12L16.2648 6.31874C16.6635 5.93657 16.6635 5.31695 16.2648 4.93479L16.0464 4.72541C15.6477 4.34324 15.0012 4.34324 14.6025 4.72541L7.96303 11.0895C7.95981 11.0925 7.9566 11.0955 7.95341 11.0986L7.73497 11.308C7.57531 11.461 7.47958 11.6521 7.44779 11.8508C7.40014 12.1483 7.49586 12.4629 7.73494 12.6921L7.95339 12.9014C7.95712 12.905 7.96088 12.9086 7.96466 12.9121L14.6026 19.2746Z'
                fill='white'
            />
        </svg>
    </div>
);

interface IPropsNavButtons {
    handleClickArrow: (type: ArrowDirection) => void;
    isLastImage: boolean;
    isFirstImage: boolean;
    isActive?: boolean;
    isClSnippet?: boolean;
}

const NavButtons = ({ handleClickArrow, isLastImage, isFirstImage, isActive, isClSnippet }: IPropsNavButtons) => {
    const handleClickArrowByType = (arrowType: ArrowDirection) => (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        handleClickArrow(arrowType);
    };

    return (
        <div className={cn(undefined, { active: isActive })}>
            {! isFirstImage && (
                <button
                    className={cn('left-btn', { clSnippet: isClSnippet })}
                    onClick={handleClickArrowByType('left')}
                    data-test='desktop-snippet-gallery-arrow-left'
                >
                    <Arrow direction='left' />
                </button>
            )}
            {! isLastImage && (
                <button
                    className={cn('right-btn', { clSnippet: isClSnippet })}
                    onClick={handleClickArrowByType('right')}
                    data-test='desktop-snippet-gallery-arrow-right'
                >
                    <Arrow direction='right' />
                </button>
            )}
        </div>
    );
};

export default NavButtons;
