import './MortgageField.css';

import React from 'react';

import type { TypographyProps } from '@vtblife/uikit/dist/components/typography/types';
import { Typography } from '@vtblife/uikit';

import classname from '@search/classname/src';

const cn = classname.bind(null, 'MortgageField');

export function MortgageField({
    title,
    children,
    gap = 'xs',
    titleProps = {
        variant: 'h3'
    },
    descriptionProps = {
        color: 'secondary',
        variant: 'primary-alone'
    },
    className
}: {
    gap?: 'xs' | 's' | 'm' | 'l';
    titleProps?: {
        accent?: boolean;
        variant?: TypographyProps['variant'];
        resetFont?: boolean;
    };
    descriptionProps?: {
        accent?: boolean;
        variant?: TypographyProps['variant'];
        color?: TypographyProps['color'];
    };
    title: React.ReactNode;
    children: React.ReactNode;
    className?: string;
}) {
    return (<div className={cn('field', { gap }, className)}>
        {title && <Typography
            variant={titleProps?.variant}
            color={titleProps?.accent ? 'orange-500' : undefined}
            className={cn('title', { resetFont: titleProps.resetFont })}
            children={title} />}
        {children && <Typography
            variant={descriptionProps.variant}
            color={descriptionProps.accent ? 'orange-500' : (descriptionProps.color ?? 'secondary')}
            children={children} />}
    </div>);
}
