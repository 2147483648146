/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-shadow */
import React, { useMemo, useState } from 'react';

import { YMapVector } from '@search/common-core/ymap/Vector';
import { SearchMapRoute, SearchRoute } from '@search/offer-search-routes/src';

import { YMapBounds, yMapBoundsFromVectors, yMapBoundsRound } from '@search/ymap/src/bounds';
import { yMapVectorFromLatLon } from '@search/ymap/src/Vector';
import { YMapPlacemarkPriority } from '@search/ymap/src/Placemark';
import { useM2AuthContext } from '@search/auth/src/M2AuthProvider';

import { RegionIdEnum } from '@search/filter-enums/enums/Region';
import { useGeoRootRegion } from '@search/geo/src/GeoRootRegionProvider';
import { formatNumber } from '@search/helpers/src/formatNumber';
import { useQueryDisplayValues } from '../../../../view/common/hooks/useQueryDisplayValues';
import { Filters, GeoFilter, MapBoundsFilter, RegionFilter, IFilter } from '../../../../view/filters/models/Filter';
import { GeoBaseStore } from '../../../geo/GeoBase';
import { useGeoTips } from '../../../geo/useGeoTips';
import { classifiedFilterFactory } from '../../filters/ClassifiedFilterFactory';
import { ClassifiedFilterCollection } from '../../filters/ClassifiedFilterCollection';
import { offerFilterGqlFromRoute } from '../../search/page/offerFilterGqlFromRoute';
import { offerSearchPageFilterToRoute } from '../../search/page/offerSearchPageFilterToRoute';
import {
    offerSearchPageGetInitialFilters
} from '../../search/page/offerSearchPageGetInitialFilters';
import { offerYMapPresetBounds } from '../presetBounds';
import { seoSearch } from '../../../seo/seoSearch';
import { OfferYMapUnionPlacemarkItem, offerYMapUnionPlacemarkIsMulti } from '../union/UnionPlacemarkItem';
import { useOfferYMapUnionItemUrlBuilder } from '../union/useItemUrlBuilder';
import { useSearchOffersCountFetcher } from '../../search/page/SearchOffersCount/useSearchOffersCountFetcher';
import { useFavoritesMutationSafe } from '../../../../view/common/components/FavoritesButton/FavoritesMutation';
import { OfferYMapApiPlacemarkResponseItem } from '../api/placemarkResponse';
import { useSearchOffersMapLoader, pointCompat } from './SearchOffersMapQuery/useSearchOffersMapQuery';

export const favoritedIds = new Set<string>();

export function useOfferYMapPageState2(searchRoute: SearchRoute | SearchMapRoute, isMobile = false) {
    const searchParams = searchRoute.params();
    const [ filters, updateState ] = React.useState(offerSearchPageGetInitialFilters(searchParams));

    React.useEffect(() => {
        updateState(offerSearchPageGetInitialFilters(searchParams));
        loadingRef.current = false;
    }, [ searchParams ]);

    const searchOffersMapLoad = useSearchOffersMapLoader();

    const nbId = searchParams.newBuildingIds[0] ?? 0;

    const geoIds = [ ...searchParams.districtIds ];

    const { auth } = useM2AuthContext();
    const isMyOffers = auth.isAuthenticated && auth.isRealtorEmployeeEmpowered;
    const userId = isMyOffers ? auth.user?.userId : null;

    // Лучше, конечно, ориентироваться на зум, но тогда при изначальной отрисовке нам нужно его знать и идти в API уже с его учётом.
    // Чтобы не таскать/протаскивать везде с собой зум, решил подхачить вот так
    const showClusters = useMemo(() => {
        let show = false;

        if (searchParams.mapBounds?.bounds) {
            show = (searchParams.mapBounds.bounds.max.x - searchParams.mapBounds.bounds.min.x) > 0.14 ||
                (searchParams.mapBounds.bounds.max.y - searchParams.mapBounds.bounds.min.y) > 0.1;
        } else {
            return true;
        }
        return show;
    }, [ searchParams.mapBounds?.bounds ]);
    const response = searchOffersMapLoad({
        regionId: searchParams.region,
        nbId,
        geoIds: geoIds.length > 0 ? geoIds : null,
        filters: offerFilterGqlFromRoute({
            ...searchParams,
            selectedOffers: undefined,
            mapBounds: {
                ...searchParams.mapBounds,
                geohash: undefined
            },
            userId
        }),
        taglessOnly: showClusters
    });
    const dataRef = React.useRef(response.data);

    if (! response.loading) dataRef.current = response.data;

    const data = dataRef.current;

    const geoStore = React.useMemo(() => GeoBaseStore.fromGql(data?.geo), [ data?.geo ]);

    geoStore.districts.regionId = searchParams.region;

    const points = data?.searchOffersMap.points;

    const pointBounds = React.useMemo(() => yMapBoundsFromVectors([
        ...points?.map(offer => yMapVectorFromLatLon(pointCompat(offer).coordinates)) ?? [],
        ...searchParams.mapBounds?.polygon ?? []
    ]), [ points ]);

    const loadingRef = React.useRef(false);

    const handleSubmit = React.useCallback(() => {
        if (response.loading) return;
        response.clearCache(true);
    }, [ response.clearCache, response.loading ]);

    const updateFilters = React.useCallback((next: typeof filters) => {
        const prev = searchRoute.params();

        const newParams = next ? offerSearchPageFilterToRoute(next) : prev;

        searchRoute.push(newParams, { delay: 1000 });
        loadingRef.current = true;
        updateState(next);
    }, [ updateState, searchRoute ]);

    const handleReset = React.useCallback(() => {
        const initFilters = filters.reset();
        const nextFilters = classifiedFilterFactory.createOrUpdateFilters(initFilters)
            .update(new MapBoundsFilter()) as ClassifiedFilterCollection;

        updateFilters(nextFilters);
    }, [ updateFilters, filters ]);

    const updateFilter = React.useCallback((filter: MapBoundsFilter) => {
        if (loadingRef?.current) return;
        const newFilters = filters.update(filter) as ClassifiedFilterCollection;

        updateFilters(newFilters);

        const oldParams = searchRoute?.params();

        if (! oldParams || ! searchRoute) return;

        searchRoute.replace({
            ...oldParams,
            mapBounds: {
                bounds: filter.bounds,
                polygon: filter.polygon,
                geohash: filter.geohash
            }
        });
    }, [ filters, searchRoute, updateFilters ]);

    const {
        isDrawAreaVisible,
        toggleDrawArea,
        closeDrawArea,
        polygon,
        setPolygon,
        setBounds
    } = useOfferYMapDrawArea({
        filters,
        updateFilter
    });

    const handleResetInSegment = React.useCallback(() => {
        filters.remove(filters.get(Filters.price));
        filters.remove(filters.get(Filters.rooms));
        filters.remove(filters.get(Filters.newBuilding));
        const newFilters = classifiedFilterFactory.clearAdditionalFilters(filters.getFilters());
        const nextFilters = classifiedFilterFactory.createOrUpdateFilters(newFilters)
            .update(new MapBoundsFilter()) as ClassifiedFilterCollection;

        updateFilters(nextFilters);
        closeDrawArea();
    }, [ updateFilters, closeDrawArea, filters ]);

    const geoFilter = filters.get(Filters.geo) as GeoFilter;

    const geos = geoStore.getByIds(Array.from(geoFilter.geoIds))
        ?.filter(item => searchParams.segments?.districtIds.includes(item.id) ||
            searchParams.segments?.addresses.includes(item.id) ||
            searchParams.segments?.metroIds.includes(item.id)
        );

    const [ queryDisplayValues, addQueryDisplayValue ] = useQueryDisplayValues({
        newBuildingIds: geoFilter.newBuildingIds,
        addressIds: geoFilter.addressIds
    });

    const regionFilter = filters.get(Filters.region) as RegionFilter;
    const boundsFilter = filters.get(Filters.mapBounds) as MapBoundsFilter;
    const rootRegions = useGeoRootRegion();
    const rootRegion = rootRegions.byId(searchParams.region);
    const bounds = boundsFilter.bounds ?? pointBounds ??
        rootRegion?.bounds ?? offerYMapPresetBounds[regionFilter.region];
    const boundsRef = React.useRef(bounds);

    boundsRef.current = bounds;

    // forced active item even if in points not received
    const [ forcedActivePlacemark, setForcedActivePlacemark ] = useState<OfferYMapUnionPlacemarkItem>();
    const setSelected = React.useCallback((item: OfferYMapUnionPlacemarkItem, e: MouseEvent, element?: HTMLElement) => {
        e.preventDefault();
        e.stopPropagation();

        setForcedActivePlacemark(item);

        const geohash = offerYMapUnionPlacemarkIsMulti(item) ? item.geohash : undefined;
        const selectedOffer = ! offerYMapUnionPlacemarkIsMulti(item) ? item.id : undefined;
        const selectedOffers = selectedOffer ? [ selectedOffer ] : undefined;

        const prev = searchRoute.params();
        const bounds = boundsRef.current;
        const delta = bounds ? {
            x: (bounds.max.x - bounds.min.x) / 2,
            y: (bounds.max.y - bounds.min.y) / 2
        } : undefined;
        const center = item.coordinates;

        let nextBounds = prev.mapBounds?.bounds;
        const rect = element?.getBoundingClientRect();

        if (delta && rect && ! isMobile && rect.left < 360) {
            nextBounds = yMapBoundsRound({
                min: {
                    x: center.longitude - delta.x,
                    y: center.latitude - delta.y
                },
                max: {
                    x: center.longitude + delta.x,
                    y: center.latitude + delta.y
                }
            });
        }

        if (delta && rect && isMobile && rect.bottom > 310) {
            const centerY = center.latitude - (delta.y / 4);

            nextBounds = yMapBoundsRound({
                min: {
                    x: center.longitude - delta.x,
                    y: centerY - delta.y
                },
                max: {
                    x: center.longitude + delta.x,
                    y: centerY + delta.y
                }
            });
        }

        searchRoute.push({
            ...prev,
            selectedOffers,
            mapBounds: {
                ...prev.mapBounds,
                bounds: nextBounds,
                geohash
            }
        });
    }, [ searchRoute, boundsRef, isMobile ]);

    const clearSelected = React.useCallback(() => {
        const prev = searchRoute.params();

        setForcedActivePlacemark(undefined);
        searchRoute.push({
            ...prev,
            selectedOffers: undefined,
            mapBounds: {
                ...prev.mapBounds,
                geohash: undefined
            }
        });
    }, [ searchRoute ]);

    const hasSelected = Boolean(searchParams.mapBounds?.geohash || searchParams.selectedOffers?.length);

    const selectedSet = React.useMemo(() => new Set([
        ...(searchParams.mapBounds?.geohash ? [ searchParams.mapBounds.geohash ] : []),
        ...searchParams.selectedOffers ?? []
    ]), [ searchParams.mapBounds?.geohash, searchParams.selectedOffers ]);

    const getItemUrl = useOfferYMapUnionItemUrlBuilder();

    const favoritesMutationSafe = useFavoritesMutationSafe();

    const isFavorite = React.useCallback((item: OfferYMapApiPlacemarkResponseItem) => {
        const orig = favoritesMutationSafe.argsById(item.id)?.isFavorite;

        return orig ?? (favoritedIds.has(item.id) ? true : item.isFavorite);
    }, [ favoritesMutationSafe ]);
    const [ isFavoriteForce, setIsFavoriteForce ] = React.useState([]);
    const forceFavoriteUpdate = React.useCallback(() => {
        setIsFavoriteForce([]);
    }, []);
    const selected = hasSelected ? {
        geoHash: searchParams.mapBounds?.geohash,
        selectedOffers: searchParams.selectedOffers,
        forceFavoriteUpdate
    } : undefined;

    const responseItems = React.useMemo(() => {
        const idsSet = new Set<string>();
        const changedPoints = points?.map(raw => {
            const item = pointCompat(raw);

            if (item.kind === 'tagless') {
                return item;
            }
            const isMultiPlacemark = offerYMapUnionPlacemarkIsMulti(item);
            const isActive = isMultiPlacemark ?
                selectedSet.has(item.geohash) :
                selectedSet.has(item.id);

            idsSet.add(isMultiPlacemark ? item.geohash : item.id);

            const next: OfferYMapUnionPlacemarkItem = {
                ...item,
                isActive,
                isFavorite: isMultiPlacemark ? false : isFavorite(item),
                url: getItemUrl(item),
                zIndexes: item.isHighlighted ? YMapPlacemarkPriority.Middle : undefined,
                onClick: (e: MouseEvent, element?: HTMLElement) => setSelected(item, e, element)
            };

            return next;
        }) ?? [];

        if (
            forcedActivePlacemark &&
            ! idsSet.has(String(offerYMapUnionPlacemarkIsMulti(forcedActivePlacemark) ?
                forcedActivePlacemark.geohash :
                forcedActivePlacemark.id
            ))
        ) {
            return [
                ...changedPoints,
                { ...forcedActivePlacemark, isActive: true }
            ];
        }
        return changedPoints;
    }, [ points, getItemUrl, setSelected, selectedSet, isFavorite, isFavoriteForce ]);
    const clusterPoints = useMemo(() => {
        if (! showClusters) return [];
        return responseItems.map(item => {
            return [
                item.coordinates.latitude,
                item.coordinates.longitude
            ] as [ number, number ];
        });
    }, [ showClusters, responseItems ]);
    const collection = useMemo(() => showClusters ? [] : responseItems, [ responseItems, showClusters ]);

    const tips = useGeoTips(queryDisplayValues, filters, geoStore);

    const offersCount = data?.searchOffersCount?.count ?? 0;

    const isMskMOSPbLO = useMemo(() => [
        RegionIdEnum.MSK,
        RegionIdEnum.MSK_OBL,
        RegionIdEnum.MSK_AND_MSK_OBL,
        RegionIdEnum.SPB,
        RegionIdEnum.LEN_OBL,
        RegionIdEnum.SPB_AND_LEN_OBL
    ].includes(searchParams.region), [ searchParams.region ]);
    const isSellFlat = useMemo(() => filters.isSellFilterActive() && filters.isFlatFilterActive(), [ filters ]);
    const showPromoRieltors = isMskMOSPbLO && ! filters.isNewBuilding() && isSellFlat;

    const { pageTitle: headerTitle } = seoSearch({
        params: searchParams,
        geos,
        offersCount,
        region: rootRegion
    });

    return {
        showClusters,
        toggleDrawArea,
        closeDrawArea,
        isDrawAreaVisible,
        count: offersCount,
        collection,
        clusterPoints,
        loading: response.loading,
        geoStore,
        filters,
        selected,
        clearSelected,
        tileUrl: data?.searchOffersMap.tileUrl,
        bounds,
        setBounds,
        handleSubmit,
        polygon,
        setPolygon,
        handleResetInSegment,
        handleReset,
        updateFilters,
        addQueryDisplayValue,
        queryDisplayValues,
        tips,
        headerTitle: data?.newBuilding?.offersSeo.h1 ?? headerTitle,
        showPromoRieltors
    };
}

export function useOfferYMapDrawArea({
    filters,
    updateFilter: updateFilterRaw,
    submit,
    countInitial
}: {
    countInitial?: number;
    submit?(): void;
    filters: ClassifiedFilterCollection;
    updateFilter(filter: IFilter): void;
}) {
    const boundsFilterInitial = filters.get(Filters.mapBounds) as MapBoundsFilter;
    const regionFilter = filters.get(Filters.region) as RegionFilter;
    const regionId = regionFilter.region;

    const [ boundsFilterRaw, setBoundsFilterInt ] = React.useState(boundsFilterInitial);
    const boundsFilter = submit ? boundsFilterRaw : boundsFilterInitial;

    const countFetch = useSearchOffersCountFetcher();
    const [ count, setCount ] = React.useState(countInitial ?? 0);

    React.useEffect(() => {
        if (! submit) return;

        const newFilters = new ClassifiedFilterCollection(new Map(filters._filters));

        newFilters.update(boundsFilter);

        countFetch({ filters: newFilters }).then(data => {
            setCount(data.data?.searchOffersSummary?.count ?? 0);
        });
    }, [ boundsFilter, filters, countFetch, submit ]);

    const updateFilter = React.useCallback((filter: MapBoundsFilter) => {
        if (submit) {
            setBoundsFilterInt(filter);
            return;
        }

        updateFilterRaw(filter);
    }, [ updateFilterRaw, submit ]);

    const setBounds = React.useCallback((newBounds: YMapBounds) => {
        updateFilter(
            new MapBoundsFilter(newBounds, boundsFilter.polygon, filters.getMapBoundsFilter().geohash)
        );
    }, [ boundsFilter, updateFilter, filters.getMapBoundsFilter().geohash ]);

    const setPolygon = React.useCallback((newPolygon: YMapVector[]) => {
        const prevBounds = boundsFilter.bounds;
        const newBounds = newPolygon.length === 0 ? prevBounds : yMapBoundsFromVectors(newPolygon);

        updateFilter(new MapBoundsFilter(newBounds, newPolygon));
    }, [ boundsFilter, updateFilter ]);

    const [
        isDrawAreaVisible,
        setIsDrawAreaVisible
    ] = React.useState(Boolean(boundsFilter.polygon.length));

    const closeDrawArea = React.useCallback(() => {
        setIsDrawAreaVisible(false);
    }, [ setIsDrawAreaVisible ]);

    const toggleDrawArea = React.useCallback(() => {
        setIsDrawAreaVisible(prev => ! prev);

        if (isDrawAreaVisible) setPolygon([]);
    }, [ setPolygon, isDrawAreaVisible ]);

    const rootRegions = useGeoRootRegion();
    const rootRegion = regionId ? rootRegions.byId(regionId) : undefined;

    const bounds = boundsFilter.bounds ?? rootRegion?.bounds ?? undefined;
    const polygon = boundsFilter.polygon;

    const onSubmit = React.useCallback(() => {
        if (submit) {
            updateFilterRaw(new MapBoundsFilter(undefined, boundsFilter.polygon));
            submit();
        }
    }, [ submit, boundsFilter ]);

    const countStr = count > 0 ? `Показать ${formatNumber(count)}` : 'Нет объявлений';

    return {
        onSubmit,
        count,
        countStr,
        bounds,
        setBounds,
        polygon,
        setPolygon,
        isDrawAreaVisible,
        closeDrawArea,
        toggleDrawArea
    };
}
