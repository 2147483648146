import { cityIn } from 'lvovich';
import { GeoKind } from '@search/filter-enums/enums/GeoKind';
import { District } from '../District';
import { GeoObject2 } from '../GeoObject2';

export function geoProtoDistrict(geo: GeoObject2) {
    if (geo.kind !== GeoKind.DISTRICT && geo.kind !== GeoKind.LOCALITY) {
        throw new Error(
            `Not an district id: ${geo.id}, kind: ${geo.kind}`
        );
    }

    const id: number = geo.id;
    const title = geo.displayName ?? geo.mainName ?? '';
    const titleShort: string | undefined = geo.shortDisplayName;
    let locativeName = geo.locativeDisplayName;

    if (! locativeName) {
        let cityInTitle: string | undefined;
        const word = 'городской округ';
        const locativeWord = 'городском округе';

        if (title.startsWith('посёлок')) {
            cityInTitle = 'посёлке ' + title.substring('посёлке'.length);
        } else if (title.startsWith(word)) {
            cityInTitle = locativeWord + ' ' + title.substring(word.length);
        } else if (title.endsWith(word)) {
            cityInTitle = cityIn(title.substring(0, title.length - word.length)) + ' ' + locativeWord;
        } else {
            cityInTitle = cityIn(title);
            if (title === 'посёлок Белоостров') cityInTitle = 'посёлке Белоостров';
            if (title === 'Лобня') cityInTitle = 'Лобне';
        }

        locativeName = `${textPrefixIn(title)} ${cityInTitle}`;
    }

    const district: District = {
        kind: geo.kind,
        id,
        title,
        titleShort,
        locativeName,
        narrowRegionId: geo?.narrowRegion?.id,
        isIntracity: geo?.isIntracity,
        parentId: geo.parentId ?? 0
    };

    return district;
}

function textPrefixIn(word: string) {
    if (word === 'Фрунзенский') return 'во';

    return 'в';
}
