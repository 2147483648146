import React from 'react';

import { QueryDisplayValues } from '@search/vtbeco-frontend-core/view/common/hooks/useQueryDisplayValues';
import { DeveloperFilter, Filters, GeoFilter } from '@search/vtbeco-frontend-core/view/filters/models/Filter';
import { GeoBaseStore } from '@search/vtbeco-frontend-core/domain/geo/GeoBase';
import { isPresent } from '@search/vtbeco-frontend-core/domain/domainHelpers';

import { NewbuildingFilterCollection } from '../newbuilding/filters/NewbuildingFilterCollection';
import { ClassifiedFilterCollection } from '../offer/filters/ClassifiedFilterCollection';

export function useGeoTips(
    qv: QueryDisplayValues,
    filters: NewbuildingFilterCollection | ClassifiedFilterCollection,
    store: GeoBaseStore
) {
    const geoFilter = filters.get<GeoFilter>(Filters.geo);
    const developerFilter = filters.get<DeveloperFilter>(Filters.developer);

    const { addressIds, districtIds, metroIds, newBuildingIds } = geoFilter ?? {};
    const developerId = developerFilter?.developerId;

    return React.useMemo(() => [
        ...addressIds.map(id => (qv.addresses && qv.addresses[id]) ?? store.addresses.getGql(id)),
        ...districtIds.map(id => (qv.districts && qv.districts[id]) ?? store.districts.getGql(id)),
        ...metroIds.map(id => (qv.metros && qv.metros[id]) ?? store.stations.getGql(id)),
        ...newBuildingIds.map(id => qv.newBuildings && qv.newBuildings[id]),
        developerId ? qv.developers && qv.developers[developerId] : null
    ].filter(isPresent), [
        addressIds,
        districtIds,
        metroIds,
        newBuildingIds,
        developerId,
        qv.addresses,
        qv.districts,
        qv.metros,
        qv.newBuildings,
        qv.developers,
        store.addresses,
        store.districts,
        store.stations
    ]);
}
