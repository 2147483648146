/* global document */
import React from 'react';
import { Tooltip, Button, useTooltip } from '@vtblife/uikit';
import { useClassifiedOfferDataLayerPush } from '../offer/analytics/ClassifiedOfferGtmContext';

export const downloadOfferPdf = (offerId: string) => {
    let form = document.getElementById('print-form') as HTMLFormElement | undefined;

    if (! form) {
        form = document.createElement('form');
        document.body.appendChild(form);
        form.id = 'print-form';
        form.method = 'POST';
        form.target = '_blank';
    }

    form.action = `/search/print/offer-${offerId}/`;
    form.submit();

    form.remove();
    // window.open(`/search/print/offer-${p.offerId}/`);
};

export function SharePrintButton(p: {
    isButtonText?: boolean;
    gtmPrint?: string;
    offerId: string;
}) {
    const dataLayerPush = useClassifiedOfferDataLayerPush();

    const showPrintDialog = React.useCallback(() => {
        p.gtmPrint && dataLayerPush({ event: p.gtmPrint });

        downloadOfferPdf(p.offerId);
    }, [ p.gtmPrint, p.offerId, dataLayerPush ]);

    const tt = useTooltip();

    return (<div ref={tt.registerControl}>
        <Button
            size='s'
            fullWidth
            icon='printer'
            variant='secondary'
            dataTest='card-button-print'
            onClick={showPrintDialog}
        >{p.isButtonText ? 'Распечатать' : ''}</Button>
        <Tooltip
            direction='up'
            to={tt.to}
        >
            Распечатать
        </Tooltip>
    </div>);
}
