import React from 'react';

import { RegionIdEnum } from '@search/filter-enums/enums/Region';

import { SPBCenterDistricts } from './components/SPBCenterDistricts';
import { NewMoscowDistricts } from './components/NewMoscowDistrict';

interface IProps {
    regionId: RegionIdEnum;
    onChange: (data: { diff: { selected: number[]; deselected: number[] }}) => void;
    selectedDistricts?: Set<number>;
    className?: string;
}

export const DistrictControl: React.FC<IProps> = ({ regionId, onChange, className, selectedDistricts }) => {
    let districtsSelectButton;

    switch (regionId) {
        case RegionIdEnum.SPB:
            districtsSelectButton = (
                <SPBCenterDistricts
                    onChange={onChange}
                    selectedDistricts={selectedDistricts}
                />
            );

            break;
        case RegionIdEnum.MSK:
            districtsSelectButton = (
                <NewMoscowDistricts
                    onChange={onChange}
                    selectedDistricts={selectedDistricts}
                />
            );

            break;

        default: {
            districtsSelectButton = null;
        }
    }

    return (
        <div className={className}>
            {districtsSelectButton}
        </div>
    );
};
