import './MortgageStupidModal.css';

import React, { useMemo } from 'react';
import { Modal, Input, PhoneInput, Button, Typography } from '@vtblife/uikit';

import { Currency } from '@search/filter-enums/enums/Currency';
import { formatPrice } from '@search/helpers/src/formatPrice';
import { useStaticPathConfig } from '@search/common-core/src/StaticPathContext';

import { formatLocaleNumber } from '@search/helpers/src/formatNumber';
import { defaultMortgageRateNbFormatted, defaultMortgageRateSecondFormatted } from '../contants';

import { Mortgage2SuccessContent } from '../mortgage2/success/Mortgage2SuccessContent';
import { MortgageGovernmentDisclaimer } from '../MortgageGovernmentDisclaimer/MortgageGovernmentDisclaimer';
import { MortgageGovernmentDisclaimerModal } from '../MortgageGovernmentDisclaimer/MortgageGovernmentDisclaimerModal';
import { calculateOwnAmount } from '../MinimalBanksMortgageFields/useBankMortgageForm';
import { MortgageFieldsBuilder } from '../domain/MortgageFieldsBuilder';
import { MortgageField } from '../domain/MortgageField';
import Image from '../../Image';
import { useGovernmentMortgage } from '../useGovernmentMortgage';

import bankLogos from './bankLogos.url.svg';
import { MortgageStupidProps, useStupidMortgage } from './useStupidMortgage';

export function MortgageStupidModal(props: MortgageStupidProps & {
    onClose?: (e?: React.MouseEvent | React.KeyboardEvent) => void;
    propertyCost?: number;
    rate?: number;
    renderCustomFirstGrid?: React.ReactNode;
}) {
    const {
        isMobile,
        onClose,
        isNewBuilding,
        propertyCost,
        rate,
        renderCustomFirstGrid,
        minimalMortgageRate
    } = props;

    const prices = useMemo(() => {
        if (! propertyCost) return;

        const propertyCostFormatted = formatPrice({
            value: propertyCost * 100,
            currency: Currency.RUB,
            displayCurrency: true
        });
        let ownAmount = calculateOwnAmount(propertyCost, isNewBuilding ? 0.15 : 0.1);

        if (rate) ownAmount = calculateOwnAmount(propertyCost, rate);

        const ownAmountFormatted = formatPrice({
            value: ownAmount * 100,
            currency: Currency.RUB,
            displayCurrency: true
        });

        return {
            propertyCostFormatted,
            ownAmountFormatted,
            ownAmount
        };
    }, [ propertyCost, isNewBuilding ]);

    const governmentMortgage = useGovernmentMortgage({
        propertyCost,
        ownAmount: prices?.ownAmount,
        regionId: props.regionId,
        isPreferentialNbCondition: isNewBuilding
    });
    const {
        sendQueryOnEnter,
        sendQuery,

        isFormSended,
        isFormSyncing,
        nameInputValid,
        name,
        setName,
        setNameTouched,
        phoneInputValid,
        setPhone,
        phone,
        setPhoneTouched,
        loading
    } = useStupidMortgage({
        ...props,
        ...governmentMortgage
    });
    // not important all conditions government mortgage
    const title = governmentMortgage.isPreferentialNbCondition ? 'Ипотека с\u00A0господдержкой на\u00A0М2' : 'Выгодная ипотека с\u00A0М2';
    const subtitle = 'Заполните единую анкету, выберите подходящую программу и\u00A0получите одобрение от\u00A0лучших банков';

    const staticPath = useStaticPathConfig();

    const [ isModalGovernmentOpened, setIsModalGovernmentOpened ] = React.useState(false);

    if (isModalGovernmentOpened) {
        return (<MortgageGovernmentDisclaimerModal
            isMobile={isMobile}
            onSuccess={() => setIsModalGovernmentOpened(false)}
            onClose={() => setIsModalGovernmentOpened(false)}
        />);
    }

    return (<Modal
        opened
        onClose={onClose}
        mobile={isMobile}
        swipeToClose={! isMobile}
    >
        {isFormSended ? (
            <Mortgage2SuccessContent
                title='Заявка принята'
                isMobile={isMobile}
            />
        ) : (<>
            {isMobile ? (
                <Modal.Header>Заявка на&nbsp;ипотеку</Modal.Header>
            ) : (
                <Modal.Title>{title}</Modal.Title>
            )}
            <Modal.Content scrollable={isMobile}>
                <MortgageFieldsBuilder
                    isMobile={isMobile}
                    prefixNodes={<>
                        {isMobile && (
                            <Typography variant='h3'>{title}</Typography>
                        )}
                        {subtitle && (
                            <Typography>{subtitle}</Typography>
                        )}
                    </>}
                    gridFields={<>
                        {renderCustomFirstGrid ?? (
                            <MortgageField
                                titleProps={{ accent: true, variant: 'h3' }}
                                title={`от ${formatLocaleNumber(minimalMortgageRate) || (isNewBuilding ? defaultMortgageRateNbFormatted : defaultMortgageRateSecondFormatted)}%`}
                                children={(
                                    // not important all conditions government mortgage
                                    governmentMortgage.isPreferentialNbCondition ?
                                        <MortgageGovernmentDisclaimer
                                            isAccent
                                            isImportant
                                            splitOnRows
                                            isMobile={isMobile}
                                            descriptionText={<><Typography tag='span' color='secondary'>по&nbsp;</Typography>льготным программам</>}
                                            mainText='минимальная ставка'
                                            onClick={() => setIsModalGovernmentOpened(true)}
                                        /> :
                                        <>ставка по&nbsp;ипотеке <br />на&nbsp;вторичку</>
                                )}
                            />
                        )}
                        <MortgageField
                            titleProps={{ resetFont: true }}
                            title={<Image
                                height={52}
                                loading='lazy'
                                alt='логотипы банков'
                                url={`${staticPath}${bankLogos}`}
                            />}
                            children='более 20&nbsp;банков на&nbsp;выбор'
                        />
                        {prices ? (<>
                            <MortgageField
                                title={prices.propertyCostFormatted}
                                children='стоимость квартиры'
                            />
                            <MortgageField
                                title={prices.ownAmountFormatted}
                                children='первоначальный взнос'
                            />
                        </>) : null}
                    </>}
                    contactFields={<>
                        <Input
                            dataTest='mortgage-set-name'
                            label='Как вас зовут'
                            placeholder='Имя'
                            size='l'
                            onBlur={setNameTouched}
                            onKeyPress={sendQueryOnEnter}
                            error={nameInputValid === false}
                            onChange={setName}
                            value={name}
                            disabled={isFormSyncing}
                        />
                        <PhoneInput
                            dataTest='mortgage-set-phone'
                            label='Телефон'
                            size='l'
                            inputMode='numeric'
                            onBlur={setPhoneTouched}
                            onKeyPress={sendQueryOnEnter}
                            error={phoneInputValid === false}
                            onChange={setPhone}
                            value={phone || undefined}
                            disabled={isFormSyncing}
                        />
                    </>}
                    actions={
                        <Button
                            fullWidth
                            size={isMobile ? 'm' : 'l'}
                            disabled={isFormSyncing || loading}
                            dataTest='mortgage-send-query'
                            variant='accent'
                            onClick={sendQuery}
                        >
                            Подать заявку
                        </Button>
                    }
                />
            </Modal.Content>
        </>)}
    </Modal>);
}
