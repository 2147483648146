import {
    WindowsDirectionSearchOffersEnum,
    FinishingTypeEnum
} from '@search/graphql-typings';
import { YMapBounds } from '@search/ymap/src/bounds';
import { YMapVector } from '@search/ymap/src/Vector';
import {
    Accreditation,
    AreaUnit,
    AuthorTypeOptionsWithRealtor,
    BalconyOptions,
    BankPledgeOptions,
    Bathroom,
    BathroomLocationOptions,
    BuildingClass,
    CeilingHeight,
    Commissioning,
    CommissionType,
    DealType,
    DepositType,
    ElectricityOptions,
    FloorConstraints,
    FurnitureOption,
    GasOptions,
    HeatingOptions,
    HouseCategory,
    InfrastructureOptions,
    IsApartmentsOptions,
    LandStatus,
    LayoutImageOptions,
    LiftOptions,
    LivingFacilitiesOption,
    LivingTenantOption,
    NearbyOptions,
    NewBuilding,
    NewBuildingDeal,
    ParkingType,
    PaymentType,
    PhotoOptions,
    Position,
    PrepaymentType,
    RealtyObjectType,
    Renovation,
    RenovationProgram,
    Rooms,
    RoomsOfferedOptions,
    Safety,
    SellType,
    SewerageOptions,
    TimeLimit,
    TransportType,
    WallsType,
    WaterOptions,
    FlatsSpecialEvent,
    OtherSpecialEvent,
    MiscSpecialEvent,
    BuildEpoch,
    OnlineView,
    FinishingTypeOption
} from '@search/filter-enums/enums';
import { RegionIdEnum } from '@search/filter-enums/enums/Region';

import type { INumberRange } from '../types/IRange';
import type { IFinishing, FinishingParams, FinishingBuilding, FinishingBuildingParams } from '../types/IFinishing';

export enum Filters {
    dealType = 'dealType',
    categoryType = 'categoryType',
    rooms = 'rooms',
    newBuilding = 'newBuilding',
    price = 'price',
    region = 'region',
    totalArea = 'totalArea',
    livingArea = 'livingArea',
    kitchenArea = 'kitchenArea',
    floor = 'floor',
    floorsTotal = 'floorsTotal',
    builtYear = 'builtYear',
    cellHeight = 'cellHeight',
    renovation = 'renovation',
    bathroom = 'bathroom',
    transportLimit = 'transportLimit',
    windowsDirection = 'windowsDirection',
    geo = 'geo',
    livingFacility = 'livingFacility',
    furniture = 'furniture',
    livingTenantOptions = 'livingTenantOptions',
    rentConditions = 'rentConditions',
    rentPrepayment = 'rentPrepayment',
    walls = 'walls',
    parkings = 'parkings',
    author = 'author',
    userId = 'userId',
    sellType = 'sellType',
    infrastructure = 'infrastructure',
    balcony = 'balcony',
    photoOptions = 'photoOptions',
    layoutImageOptions = 'layoutImageOptions',
    apartments = 'apartments',
    position = 'position',
    commissioning = 'commissioning',
    commissioningList = 'commissioningList',
    finishingType = 'finishingType',
    finishingFlat = 'finishingFlat',
    safety = 'safety',
    developer = 'developer',
    buildingClass = 'buildingClass',
    accreditation = 'accreditation',
    newBuildingDeal = 'newBuildingDeal',
    paymentType = 'paymentType',
    roomsInOffer = 'roomsInOffer',
    landStatus = 'landStatus',
    landArea = 'landArea',
    supplyOptions = 'supplyOptions',
    houseCategory = 'houseCategory',
    houseArea = 'houseArea',
    bathroomLocation = 'bathroomLocation',
    heating = 'heating',
    mapBounds = 'mapBounds',
    bankPledgeOptions = 'bankPledgeOptions',
    seoMortgageAllowed = 'seoMortgageAllowed',
    buildings = 'buildings',
    closedSales = 'closedSales',
    newBuildingData = 'newBuildingData',
    selectedNewBuilding = 'selectedNewBuilding',
    showHighlighted = 'showHighlighted',
    flatsSpecialEvents = 'flatsSpecialEvents',
    otherSpecialEvents = 'otherSpecialEvents',
    miscSpecialEvents = 'miscSpecialEvents',
    renovationProgram = 'renovationProgram',
    nearbyOptions = 'nearbyOptions',
    sellerOrganizationName = 'sellerOrganizationName',
    buildEpoch = 'buildEpoch',
    onlineView = 'onlineView',
    notOnlyM2Pro = 'notOnlyM2Pro',
    mortgagePayment = 'mortgagePayment',
    hasLift = 'hasLift'
}

// FIXME Add correct Id generation, if there's will be two filter collections
export type FilterId = Filters

export abstract class IFilter {
    abstract id: FilterId;
    abstract isDefault(): boolean;
    create(): IFilter {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return new (this.constructor as any)();
    }
}

export class MapBoundsFilter extends IFilter {
    id: FilterId = Filters.mapBounds;

    constructor(
        readonly bounds?: YMapBounds,
        readonly polygon: readonly YMapVector[] = [],
        readonly geohash?: string
    ) {
        super();
        this.bounds = bounds;
        this.polygon = polygon;
        this.geohash = geohash;
    }

    isDefault() {
        return this.bounds === undefined && this.polygon.length === 0 && this.geohash === undefined;
    }

    hasPolygon() {
        return this.polygon.length !== 0;
    }
}

export class DealTypeFilter extends IFilter {
    id: FilterId = Filters.dealType;
    constructor(
        readonly dealType: DealType = DealType.SELL
    ) {
        super();
        this.dealType = dealType;
    }
    isDefault() {
        return this.dealType === DealType.SELL;
    }
}

export class CategoryTypeFilter extends IFilter {
    id: FilterId = Filters.categoryType;
    constructor(
        readonly categoryType: RealtyObjectType = RealtyObjectType.FLAT
    ) {
        super();
        this.categoryType = categoryType;
    }
    isDefault(): boolean {
        return RealtyObjectType.FLAT === this.categoryType;
    }
}

export class NewBuildingFilter extends IFilter {
    id: FilterId;
    newBuilding: NewBuilding[];
    constructor(
        newBuilding?: NewBuilding[]
    ) {
        super();
        this.id = Filters.newBuilding;
        this.newBuilding = ! newBuilding || newBuilding.length === 2 ? [] : newBuilding;
    }
    isDefault(): boolean {
        return this.newBuilding.length === 0 || (this.newBuilding.length === 2 &&
            this.newBuilding.includes(NewBuilding.NEW) &&
            this.newBuilding.includes(NewBuilding.SECOND));
    }
}

export class RoomFilter extends IFilter {
    id: FilterId;
    rooms: readonly Rooms[];
    constructor(rooms?: readonly Rooms[]) {
        super();
        this.id = Filters.rooms;
        this.rooms = rooms || [ ];
    }
    isDefault(): boolean {
        return this.rooms.length === 0;
    }
}

export class PriceFilter extends IFilter {
    isDefault(): boolean {
        const isDefaultValue = this.value.from === null && this.value.to === null;

        return isDefaultValue;
    }
    id: FilterId;
    priceType: AreaUnit;
    value: INumberRange
    constructor(priceType?: AreaUnit, value?: INumberRange) {
        super();
        this.id = Filters.price;
        this.priceType = priceType || AreaUnit.UNKNOWN;
        this.value = { from: value?.from || null, to: value?.to || null };
    }
}

export class RegionFilter extends IFilter {
    id: FilterId = Filters.region;
    region: RegionIdEnum;

    constructor(region?: RegionIdEnum) {
        super();
        this.region = region !== undefined ? region : RegionIdEnum.MSK;
    }

    isDefault(): boolean {
        return this.region === RegionIdEnum.MSK;
    }
}

export class AreaFilter extends IFilter {
    isDefault(): boolean {
        return this.value.from === null && this.value.to === null;
    }
    id: FilterId;
    value: INumberRange;
    constructor(value?: INumberRange) {
        super();
        this.id = Filters.totalArea;
        this.value = value || { from: null, to: null };
    }
}

export class MortgagePaymentFilter extends IFilter {
    id: FilterId = Filters.mortgagePayment;
    payment: number | null;
    isDefault(): boolean {
        return this.payment === null;
    }
    constructor(payment?: number | null) {
        super();
        this.payment = payment ?? null;
    }
}

export class LivingAreaFilter extends IFilter {
    isDefault(): boolean {
        return this.value.from === null && this.value.to === null;
    }
    id: FilterId;
    value: INumberRange;
    constructor(value?: INumberRange) {
        super();
        this.id = Filters.livingArea;
        this.value = value || { from: null, to: null };
    }
}

export class KitchenAreaFilter extends IFilter {
    isDefault(): boolean {
        return this.value.from === null && this.value.to === null;
    }
    id: FilterId;
    value: INumberRange;
    constructor(value?: INumberRange) {
        super();
        this.id = Filters.kitchenArea;
        this.value = value || { from: null, to: null };
    }
}

export class YearBuildingFilter extends IFilter {
    isDefault(): boolean {
        return this.value.from === null && this.value.to === null;
    }
    id: FilterId;
    value: INumberRange;
    constructor(value?: INumberRange) {
        super();
        this.id = Filters.builtYear;
        this.value = value || { from: null, to: null };
    }
}

export class FloorFilter extends IFilter {
    isDefault(): boolean {
        const isDefaultRange = this.range.from === null && this.range.to === null;
        const isDefaultEdge =
            this.last === FloorConstraints.UNKNOWN &&
            this.first === FloorConstraints.UNKNOWN;

        return isDefaultEdge && isDefaultRange;
    }
    id: FilterId;
    range: INumberRange;
    last: FloorConstraints;
    first: FloorConstraints;
    constructor(range?: INumberRange, first?: FloorConstraints, last?: FloorConstraints) {
        super();
        this.id = Filters.floor;
        this.range = range || { from: null, to: null };
        this.last = last || FloorConstraints.UNKNOWN;
        this.first = first || FloorConstraints.UNKNOWN;
    }
}

export class CellHeightFilter extends IFilter {
    isDefault(): boolean {
        return this.value === CeilingHeight.UNKNOWN;
    }
    id: FilterId;
    readonly value: CeilingHeight;
    constructor(value?: CeilingHeight) {
        super();
        this.id = Filters.cellHeight;
        this.value = value || CeilingHeight.UNKNOWN;
    }
}

export class FlatsSpecialEventsFilter extends IFilter {
    id: FilterId = Filters.flatsSpecialEvents;
    value: FlatsSpecialEvent[];

    constructor(value?: FlatsSpecialEvent[]) {
        super();
        this.value = value || [];
    }

    isDefault() {
        return this.value.length === 0;
    }
}

export class OtherSpecialEventsFilter extends IFilter {
    id: FilterId = Filters.otherSpecialEvents;
    value: OtherSpecialEvent[];

    constructor(value?: OtherSpecialEvent[]) {
        super();
        this.value = value || [];
    }

    isDefault() {
        return this.value.length === 0;
    }
}

export class MiscSpecialEventsFilter extends IFilter {
    id: FilterId = Filters.miscSpecialEvents;
    value: MiscSpecialEvent[];

    constructor(value?: MiscSpecialEvent[]) {
        super();
        this.value = value || [];
    }

    isDefault() {
        return this.value.length === 0;
    }
}

export class RenovationFilter extends IFilter {
    isDefault(): boolean {
        return this.value.length === 0;
    }
    id: FilterId;
    value: Renovation[];
    constructor(value?: Renovation[]) {
        super();
        this.id = Filters.renovation;
        this.value = value || [];
    }
}

export class FinishingFilter extends IFilter {
    id: FilterId = Filters.finishingType;
    finishingType: FinishingTypeOption[];

    constructor(
        finishingType: FinishingTypeOption[] = []
    ) {
        super();
        this.finishingType = finishingType;
    }

    isDefault(): boolean {
        return this.finishingType.length === 0;
    }
}

export class BathroomFilter extends IFilter {
    id: FilterId;
    value: Bathroom[];

    constructor(value?: Bathroom[]) {
        super();

        this.id = Filters.bathroom;
        this.value = value || [];
    }

    isDefault(): boolean {
        return this.value.length === 0;
    }
}

export class TransportLimitFilter extends IFilter {
    id: FilterId = Filters.transportLimit;

    timeLimit: TimeLimit;
    transportType: TransportType;

    constructor(
        timeLimit?: TimeLimit,
        transportType?: TransportType
    ) {
        super();
        this.timeLimit = timeLimit || TimeLimit.UNKNOWN;
        this.transportType = transportType || TransportType.UNKNOWN;
    }

    isDefault(): boolean {
        return this.transportType === TransportType.UNKNOWN && this.timeLimit === TimeLimit.UNKNOWN;
    }
}

export class FloorsTotalFilter extends IFilter {
    isDefault(): boolean {
        return this.range.from === null && this.range.to === null;
    }
    id: FilterId = Filters.floorsTotal;
    range: INumberRange;
    constructor(range?: INumberRange) {
        super();
        this.range = range || { from: null, to: null };
    }
}

export class GeoFilter extends IFilter {
    id: FilterId = Filters.geo;
    geoIds: Set<number>;
    metroIds: number[];
    districtIds: number[];
    addressIds: number[];
    newBuildingIds: number[];

    constructor(
        // deprecated. Used only for tags
        geoIds = new Set<number>(),
        metroIds: number[] = [],
        districtIds: number[] = [],
        addressIds: number[] = [],
        newBuildingIds: number[] = []
    ) {
        super();
        this.geoIds = geoIds;
        this.metroIds = metroIds;
        this.districtIds = districtIds;
        this.addressIds = addressIds;
        this.newBuildingIds = newBuildingIds;
    }

    add(geo: { id: number; kind: string }) {
        if (this.geoIds.has(geo.id)) return;

        this.geoIds.add(geo.id);
        if (this.districtIds.includes(geo.id)) return;
        if (this.addressIds.includes(geo.id)) return;
        if (geo.kind === 'DISTRICT') {
            this.districtIds.push(geo.id);
        } else this.addressIds.push(geo.id);
    }

    isDefault(): boolean {
        return this.metroIds.length === 0 &&
        this.districtIds.length === 0 &&
        this.addressIds.length === 0 &&
        this.newBuildingIds.length === 0;
    }
}

export class NewBuildingDataFilter extends IFilter {
    id: FilterId = Filters.newBuildingData;

    newBuilding?: {
        type: string;
        name: string;
        id: number;
    };

    constructor(
        newBuilding?: {
            type: string;
            name: string;
            id: number;
        }
    ) {
        super();

        this.newBuilding = newBuilding;
    }

    isDefault(): boolean {
        return this.newBuilding === undefined;
    }
}

export class LivingFacilityFilter extends IFilter {
    id: FilterId = Filters.livingFacility;
    facilities: LivingFacilitiesOption[];

    constructor(
        facilities: LivingFacilitiesOption[] = []
    ) {
        super();
        this.facilities = facilities;
    }

    isDefault(): boolean {
        return this.facilities.length === 0;
    }
}

export class FurnitureFilter extends IFilter {
    id: FilterId = Filters.furniture;
    furnitures: FurnitureOption[];

    constructor(
        furnitures: FurnitureOption[] = []
    ) {
        super();
        this.furnitures = furnitures;
    }

    isDefault(): boolean {
        return this.furnitures.length === 0;
    }
}

export class LivingTenantOptionFilter extends IFilter {
    id: FilterId = Filters.livingTenantOptions;
    livingTenantOptions: LivingTenantOption[]

    constructor(
        livingTenantOptions: LivingTenantOption[] = []
    ) {
        super();
        this.livingTenantOptions = livingTenantOptions;
    }

    isDefault(): boolean {
        return this.livingTenantOptions.length === 0;
    }
}

export class RentPrepaymentFilter extends IFilter {
    id: FilterId = Filters.rentPrepayment;
    prePaymentType: PrepaymentType | null;

    constructor(
        prePaymentType?: PrepaymentType
    ) {
        super();
        this.prePaymentType = prePaymentType || null;
    }

    isDefault(): boolean {
        return this.prePaymentType === null;
    }
}

export class RentConditionsFilter extends IFilter {
    id: FilterId = Filters.rentConditions;

    utilitiesIncluded: boolean;
    depositType: DepositType;
    commisionType: CommissionType;

    constructor(
        utilitiesIncluded = false,
        depositType = DepositType.UNKNOWN,
        commisionType = CommissionType.UNKNOWN
    ) {
        super();
        this.utilitiesIncluded = utilitiesIncluded;
        this.depositType = depositType;
        this.commisionType = commisionType;
    }

    isDefault(): boolean {
        return ! this.utilitiesIncluded && this.depositType === DepositType.UNKNOWN &&
            this.commisionType === CommissionType.UNKNOWN;
    }
}

export class WallsTypeFilter extends IFilter {
    id: FilterId = Filters.walls;

    walls: WallsType[];

    constructor(walls?: WallsType[]) {
        super();
        this.walls = walls || [];
    }

    isDefault(): boolean {
        return this.walls.length === 0;
    }
}

export class ParkingFilter extends IFilter {
    id: FilterId = Filters.parkings;

    parkings: ParkingType[];

    constructor(parkings?: ParkingType[]) {
        super();
        this.parkings = parkings || [];
    }

    isDefault(): boolean {
        return this.parkings.length === 0;
    }
}

export class InfrastructureFilter extends IFilter {
    id: FilterId = Filters.infrastructure;
    infrastructure: InfrastructureOptions[];

    hasLift: LiftOptions;

    constructor(
        infrastructure?: InfrastructureOptions[],
        hasLift?: LiftOptions
    ) {
        super();
        this.infrastructure = infrastructure || [];
        this.hasLift = hasLift || LiftOptions.UNKNOWN;
    }

    isDefault(): boolean {
        return this.infrastructure.length === 0 &&
        this.hasLift === LiftOptions.UNKNOWN;
    }
}

export class BalconyFilter extends IFilter {
    id: FilterId = Filters.balcony;

    hasBalcony: BalconyOptions;

    constructor(
        hasBalcony?: BalconyOptions
    ) {
        super();
        this.hasBalcony = hasBalcony || BalconyOptions.UNKNOWN;
    }

    isDefault(): boolean {
        return this.hasBalcony === BalconyOptions.UNKNOWN;
    }
}

export class RenovationProgramFilter extends IFilter {
    id: FilterId = Filters.renovationProgram;

    willRenovation: RenovationProgram

    constructor(
        willRenovation?: RenovationProgram
    ) {
        super();
        this.willRenovation = willRenovation || RenovationProgram.UNKNOWN;
    }

    isDefault() {
        return this.willRenovation === RenovationProgram.UNKNOWN;
    }
}

export class NearbySchoolFilter extends IFilter {
    id: FilterId = Filters.nearbyOptions;

    hasNearbySchool: NearbyOptions;

    constructor(
        hasNearbySchool?: NearbyOptions
    ) {
        super();
        this.hasNearbySchool = hasNearbySchool || NearbyOptions.UNKNOWN;
    }

    isDefault(): boolean {
        return this.hasNearbySchool === NearbyOptions.UNKNOWN;
    }
}

export class PhotoOptionsFilter extends IFilter {
    id: FilterId = Filters.photoOptions;

    photoOption: PhotoOptions

    constructor(
        photoOption?: PhotoOptions
    ) {
        super();
        this.photoOption = photoOption || PhotoOptions.UNKNOWN;
    }

    isDefault() {
        return this.photoOption === PhotoOptions.UNKNOWN;
    }
}

export class LayoutImageOptionsFilter extends IFilter {
    id: FilterId = Filters.layoutImageOptions;

    constructor(
        readonly layoutImageOption: LayoutImageOptions[] = []
    ) {
        super();
        this.layoutImageOption = layoutImageOption || [];
    }

    isDefault() {
        return this.layoutImageOption.length === 0;
    }
}

export class BankPledgeOptionsFilter extends IFilter {
    id: FilterId = Filters.bankPledgeOptions;

    bankPledgeOption: BankPledgeOptions | null;

    constructor(bankPledgeOption: BankPledgeOptions | null = null) {
        super();
        this.bankPledgeOption = bankPledgeOption;
    }

    isDefault() {
        return this.bankPledgeOption === null;
    }
}

export class SeoMortgageAllowedFilter extends IFilter {
    id: FilterId = Filters.seoMortgageAllowed;

    constructor(readonly isAllowed = false) {
        super();
        this.isAllowed = isAllowed;
    }

    isDefault() {
        return ! this.isAllowed;
    }
}

export class ApartmentFilter extends IFilter {
    id: FilterId = Filters.apartments;

    isApartment: IsApartmentsOptions;

    constructor(
        isApartment?: IsApartmentsOptions
    ) {
        super();
        this.isApartment = isApartment || IsApartmentsOptions.UNKNOWN;
    }

    isDefault(): boolean {
        return this.isApartment === IsApartmentsOptions.UNKNOWN;
    }
}

export class PositionFilter extends IFilter {
    id: FilterId = Filters.position;

    position: Position[];

    constructor(
        position?: Position[]
    ) {
        super();
        this.position = position || [];
    }

    isDefault(): boolean {
        return this.position.length === 0;
    }
}

export class AuthorFilter extends IFilter {
    id: FilterId = Filters.author;

    author: AuthorTypeOptionsWithRealtor[];

    constructor(author?: AuthorTypeOptionsWithRealtor[]) {
        super();
        this.author = author || [];
    }

    isDefault(): boolean {
        return this.author.length === 0;
    }
}

export class UserIdFilter extends IFilter {
    id: FilterId = Filters.userId;

    userId: string | undefined;

    constructor(userId?: string) {
        super();
        this.userId = userId || undefined;
    }

    isDefault(): boolean {
        return this.userId === undefined;
    }
}

export class SellTypeFilter extends IFilter {
    id: FilterId = Filters.sellType;

    sellType: SellType[];

    constructor(sellType?: SellType[]) {
        super();
        this.sellType = sellType || [];
    }

    isDefault(): boolean {
        return this.sellType.length === 0;
    }
}

export class CommissioningFilter extends IFilter {
    id: FilterId = Filters.commissioning;
    commissioning: Commissioning | string;

    constructor(commissioning?: Commissioning | string) {
        super();
        this.commissioning = commissioning || Commissioning.UNKNOWN;
    }
    isDefault() {
        return this.commissioning === Commissioning.UNKNOWN;
    }
}

export class CommissioningListFilter extends IFilter {
    id: FilterId = Filters.commissioningList;
    commissioningList: (Commissioning | string)[];

    constructor(commissioningList: (Commissioning | string)[] = []) {
        super();

        this.commissioningList = commissioningList;
    }

    isDefault() {
        return this.commissioningList.length === 0;
    }
}

export const allWithFinishingValues = [ FinishingTypeEnum.Fine, FinishingTypeEnum.PreFinishing, FinishingTypeEnum.WithFurniture ];
export class FinishingTypeFilter extends IFilter {
    id: FilterId = Filters.finishingType;
    value: IFinishing;

    constructor(value?: FinishingParams) {
        super();
        this.value = {
            hasFinishing: value?.hasFinishing,
            finishingType: value?.finishingType ?? []
        };
    }

    isDefault(): boolean {
        const { hasFinishing, finishingType } = this.value;

        return (
            hasFinishing === undefined && finishingType.length === 0
        );
    }
}

export class FinishingFlatFilter extends IFilter {
    id: FilterId = Filters.finishingFlat;
    value: FinishingBuilding;

    constructor(value?: FinishingBuildingParams) {
        super();
        this.value = {
            hasFinishing: value?.hasFinishing,
            finishingType: value?.finishingType ?? []
        };
    }

    isDefault(): boolean {
        const { hasFinishing, finishingType } = this.value;

        return (
            hasFinishing === undefined && finishingType.length === 0
        );
    }
}

export class SafetyFilter extends IFilter {
    id: FilterId = Filters.safety;
    safety: Safety[];

    constructor(
        safety?: Safety[]
    ) {
        super();
        this.safety = safety || [];
    }

    isDefault(): boolean {
        return this.safety.length === 0;
    }
}

export class DeveloperFilter extends IFilter {
    id: FilterId = Filters.developer;

    developerId?: number;

    constructor(
        developerId?: number
    ) {
        super();
        this.developerId = developerId;
    }

    isDefault(): boolean {
        return typeof this.developerId !== 'number';
    }
}

export class BuildingClassFilter extends IFilter {
    id: FilterId = Filters.buildingClass;
    buildingClass: BuildingClass[];

    constructor(
        buildingClass?: BuildingClass[]
    ) {
        super();
        this.buildingClass = buildingClass || [];
    }

    isDefault(): boolean {
        return this.buildingClass.length === 0;
    }
}

export class AccreditationFilter extends IFilter {
    id: FilterId = Filters.accreditation;
    accreditation: Accreditation[];

    constructor(
        accreditation?: Accreditation[]
    ) {
        super();
        this.accreditation = accreditation || [];
    }

    isDefault(): boolean {
        return this.accreditation.length === 0;
    }
}

export class NewBuildingDealFilter extends IFilter {
    id: FilterId = Filters.newBuildingDeal;
    deal: NewBuildingDeal[];

    constructor(
        deal?: NewBuildingDeal[]
    ) {
        super();
        this.deal = deal || [];
    }

    isDefault(): boolean {
        return this.deal.length === 0;
    }
}

export class PaymentTypeFilter extends IFilter {
    id: FilterId = Filters.paymentType;
    type: PaymentType[];

    constructor(
        type?: PaymentType[]
    ) {
        super();
        this.type = type || [];
    }

    isDefault(): boolean {
        return this.type.length === 0;
    }
}

export class RoomsInOfferFilter extends IFilter {
    id: FilterId = Filters.roomsInOffer;
    roomsInOffer: RoomsOfferedOptions[];

    constructor(roomsInOffer: RoomsOfferedOptions[] = []) {
        super();
        this.roomsInOffer = roomsInOffer;
    }

    isDefault(): boolean {
        return this.roomsInOffer.length === 0;
    }
}

export class LandStatusFilter extends IFilter {
    id: FilterId = Filters.landStatus;
    landStatuses: LandStatus[];

    constructor(landStatuses: LandStatus[] = []) {
        super();
        this.landStatuses = landStatuses;
    }

    isDefault(): boolean {
        return this.landStatuses.length === 0;
    }
}

export class LandAreaFilter extends IFilter {
    id: FilterId = Filters.landArea;
    isDefault(): boolean {
        return this.value.from === null && this.value.to === null;
    }
    value: INumberRange;
    constructor(value?: INumberRange) {
        super();
        this.value = value || { from: null, to: null };
    }
}

export class SupplyOptionsFilter extends IFilter {
    id: FilterId = Filters.supplyOptions;

    isDefault(): boolean {
        return this.gas.length === 0 &&
            this.water.length === 0 &&
            this.electricity.length === 0 &&
            this.sewerage.length === 0;
    }

    gas: GasOptions[];
    water: WaterOptions[];
    electricity: ElectricityOptions[];
    sewerage: SewerageOptions[];

    constructor(
        gas: GasOptions[] = [],
        water: WaterOptions[] = [],
        electricity: ElectricityOptions[] = [],
        sewerage: SewerageOptions[] = []
    ) {
        super();
        this.gas = gas;
        this.water = water;
        this.electricity = electricity;
        this.sewerage = sewerage;
    }
}

export class HouseCategoryFilter extends IFilter {
    id = Filters.houseCategory;
    categories: HouseCategory[];
    isDefault(): boolean {
        return this.categories.length === 0;
    }

    constructor(
        categories: HouseCategory[] = []
    ) {
        super();
        this.categories = categories;
    }
}

export class HouseAreaFilter extends IFilter {
    id: FilterId = Filters.houseArea;
    isDefault(): boolean {
        return this.value.from === null && this.value.to === null;
    }
    value: INumberRange;
    constructor(value?: INumberRange) {
        super();
        this.value = value || { from: null, to: null };
    }
}

export class BathroomLocationFilter extends IFilter {
    id: FilterId = Filters.bathroomLocation;

    locations: BathroomLocationOptions[];

    isDefault() {
        return this.locations.length === 0;
    }

    constructor(locations: BathroomLocationOptions[] = []) {
        super();
        this.locations = locations;
    }
}

export class HeatingFilter extends IFilter {
    id: FilterId = Filters.heating;

    heating: HeatingOptions[];

    isDefault() {
        return this.heating.length === 0;
    }

    constructor(heating: HeatingOptions[] = []) {
        super();
        this.heating = heating;
    }
}

export class BuildingsFilter extends IFilter {
    id: FilterId = Filters.buildings;
    buildings: number[];

    constructor(
        buildings?: number[]
    ) {
        super();
        this.buildings = buildings || [];
    }

    isDefault() {
        return this.buildings.length === 0;
    }
}

export class ClosedSalesFilter extends IFilter {
    id: FilterId = Filters.closedSales;
    includingClosedSales: boolean;

    constructor(includingClosedSales: boolean = false) {
        super();
        this.includingClosedSales = includingClosedSales;
    }

    isDefault() {
        return ! this.includingClosedSales;
    }
}

export class SelectedNewBuildingFilter extends IFilter {
    id: FilterId = Filters.selectedNewBuilding;
    selectedNewBuildingId?: number;

    constructor(selectedNewBuildingId?: number) {
        super();
        this.selectedNewBuildingId = selectedNewBuildingId;
    }

    isDefault(): boolean {
        return typeof this.selectedNewBuildingId !== 'number';
    }
}

export class ShowHighlightedFilter extends IFilter {
    id: FilterId = Filters.showHighlighted;
    shouldShowHighlighted: boolean;

    constructor(shouldShowHighlighted: boolean = false) {
        super();
        this.shouldShowHighlighted = shouldShowHighlighted;
    }

    isDefault(): boolean {
        return ! this.shouldShowHighlighted;
    }
}

export class WindowsDirectionFilter extends IFilter {
    id: FilterId;
    value: WindowsDirectionSearchOffersEnum[];

    constructor(value: WindowsDirectionSearchOffersEnum[] = []) {
        super();
        this.id = Filters.windowsDirection;
        this.value = value;
    }

    isDefault() {
        return this.value.length === 0;
    }
}

export class SellerOrganizationNameFilter extends IFilter {
    id: FilterId = Filters.sellerOrganizationName;

    constructor(public sellerOrganizationName?: string) {
        super();
    }

    isDefault() {
        return ! this.sellerOrganizationName;
    }
}

export class BuildEpochFilter extends IFilter {
    id: FilterId = Filters.buildEpoch;

    buildEpoch: BuildEpoch[];

    constructor(buildEpoch?: BuildEpoch[]) {
        super();
        this.buildEpoch = buildEpoch || [];
    }

    isDefault(): boolean {
        return this.buildEpoch.length === 0;
    }
}

export class OnlineViewFilter extends IFilter {
    id: FilterId = Filters.onlineView;
    onlineView: OnlineView;

    constructor(onlineView?: OnlineView) {
        super();
        this.onlineView = onlineView || OnlineView.UNKNOWN;
    }

    isDefault(): boolean {
        return this.onlineView === OnlineView.UNKNOWN;
    }
}

export class NotOnlyM2ProFilter extends IFilter {
    id: FilterId = Filters.notOnlyM2Pro;
    notOnlyM2Pro: boolean;

    constructor(notOnlyM2Pro?: boolean) {
        super();
        this.notOnlyM2Pro = notOnlyM2Pro ?? false;
    }

    isDefault(): boolean {
        return ! this.notOnlyM2Pro;
    }
}
