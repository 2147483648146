import React, { useCallback, ReactNode } from 'react';

import classname from '@search/classname/src';
import {
    Size,
    Mode,
    SizeEnum,
    ModeEnum,
    Maybe
} from '../../../types';

import { ICheckboxProps } from '../Checkbox';

import './styles.css';

// FIXME Move to string[] only value
export interface ICheckboxGroupProps {
    /**
     * Режим работы контрола
     */
    mode?: Mode;

    /**
     * Размер будет прокинут всем детям
     */
    size?: Size;

    /**
     * Значения контролы
     */
    value: string[];

    children?: ReactNode[];

    onChange: (value: string | number | string[] | null) => void;
    className?: string;

    name?: string;

    /**
     * Отключен или нет
     */
    disabled?: boolean;

    dataTest?: string;
}

export const cnCheckboxGroup = classname.bind(null, 'CheckboxGroup');

const CheckboxGroup: React.FunctionComponent<ICheckboxGroupProps> = ({
    name,
    value,
    children,
    disabled,
    onChange,
    dataTest,
    mode = ModeEnum.CHECK,
    size = SizeEnum.S,
    className
}) => {
    const handleChildCheck = useCallback((
        checked: boolean,
        childProps: { name: Maybe<string>; value: Maybe<string | number | readonly string[]>}
    ) => {
        let newValue: string | null | string[] = [];
        const childValue: string = childProps.value as string;

        switch (true) {
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            case (checked && ModeEnum.CHECK === mode):
                // @ts-ignore
                newValue = [].concat(value).filter(a => a === 0 || Boolean(a)).concat(childValue);

                break;
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            case (checked && ModeEnum.RADIO_CHECK === mode):
            // eslint-disable-next-line
            case (checked && ModeEnum.RADIO === mode):
                newValue = childValue;

                break;
            // eslint-disable-next-line
            case (! checked && ModeEnum.RADIO === mode):
                newValue = value;

                break;
            // eslint-disable-next-line
            case (! checked && ModeEnum.RADIO_CHECK === mode):
                newValue = null;

                break;
            // eslint-disable-next-line
            case (! checked && ModeEnum.CHECK === mode):
                // @ts-ignore
                newValue = [].concat(value).filter(item => item !== childValue);
                break;
            default:
                newValue = [];
        }

        // @ts-ignore
        onChange(newValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ onChange, disabled, value ]);

    return (
        <span
            data-test={dataTest ?? name}
            className={cnCheckboxGroup(null, {}, className)}>
            {
                // @ts-ignore
                React.Children.map(children, (child: React.ReactElement<ICheckboxProps>): ReactNode => {
                    // eslint-disable-next-line
                    const checked = value && value.indexOf(child.props.value as string) !== -1;

                    return React.cloneElement(child, {
                        ...child.props,
                        name: `${name ?? ''}-${String(child.props.value ?? '')}`,
                        checked,
                        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                        disabled: disabled ?? child.props.disabled,
                        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                        size: size ?? child.props.size,
                        onCheck: handleChildCheck
                    } as ICheckboxProps);
                })
            }
        </span>
    );
};

export default CheckboxGroup;
