import { useCallback } from 'react';

import { useGqlContext } from '@search/gql-client/src';

import type {
    CustomerFeedbackMutation$variables as CustomerFeedbackMutationVariables,
    CustomerFeedbackMutation$data as CustomerFeedbackMutationResponse
} from './__generated__/CustomerFeedbackMutation.graphql';
import customerFeedbackMutation from './CustomerFeedbackMutation';

const useSendCustomerFeedback = () => {
    const gql = useGqlContext();

    return useCallback(
        (vars: CustomerFeedbackMutationVariables) => gql.client<
            CustomerFeedbackMutationVariables,
            CustomerFeedbackMutationResponse
        >(customerFeedbackMutation, vars),
        [ gql ]
    );
};

export default useSendCustomerFeedback;
