import './Mortgage2SuccessContent.css';

import React from 'react';
import { Modal, Typography } from '@vtblife/uikit';
import { Size } from '@vtblife/uikit/legacy';
import Grid from '@vtblife/uikit-grid';

import classname from '@search/classname/src';
import { useStaticPathConfig } from '@search/common-core/src/StaticPathContext';

import { Mortgage2Field } from '../field/Mortgage2Field';
import { Mortgage2IconWrap } from '../icon/Mortgage2IconWrap';

import Mortgage2IconSuccess from '../icon/Mortgage2IconSuccess.url.svg';

const cn = classname.bind(null, 'Mortgage2SuccessContent');

export function Mortgage2SuccessContent({
    isMobile,
    title = 'Скоро перезвоним',
    subtitle = 'Ожидайте, совсем скоро вам перезвонит консультант.',
    withoutGridWrapper
}: {
    isMobile?: boolean;
    withoutGridWrapper?: boolean;
    title?: string;
    subtitle?: string;
}) {
    const staticPath = useStaticPathConfig();

    const upperContent = (<>
        {isMobile ? (
            <>
                <Modal.Header>
                    {title}
                </Modal.Header>
                <Typography variant='primary-alone' color='secondary' className={cn('subtitle')}>
                    {subtitle}
                </Typography>
            </>
        ) : null}
    </>);

    const gridContent = (<>
        {isMobile ? null : (
            <Grid.Cell xs={12} m={12}>
                <Mortgage2Field
                    titleSize={Size.Large}
                    space={Size.Medium}
                    descriptionAccent
                    title={title}
                    children={subtitle}
                />
            </Grid.Cell>
        )}
        <Grid.Cell m={12}>
            <Mortgage2IconWrap>
                <img
                    crossOrigin='anonymous'
                    width={265}
                    height={185}
                    src={`${staticPath}${Mortgage2IconSuccess}`}
                />
            </Mortgage2IconWrap>
        </Grid.Cell>
    </>);

    if (withoutGridWrapper) {
        return (<>
            {upperContent}
            {gridContent}
        </>);
    }

    return (
        <>
            {upperContent}
            <Grid cols={12}>
                {gridContent}
            </Grid>
        </>
    );
}
