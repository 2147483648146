import React from 'react';
import { YMapApiProvider } from '@search/ymap/src/ApiProvider';

const VTBCoreDefaultStaticApiKey = '3e563b0a-500b-4069-8350-d27bd93ab22d';

const VTBCoreDefaultApiKey = '9d69994f-afe0-41ae-b5c2-f0997212d77e';

export function VTBCoreYMapProvider({
    children,
    apiKey = VTBCoreDefaultApiKey,
    staticApiKey = VTBCoreDefaultStaticApiKey
}: {
    apiKey?: string;
    staticApiKey?: string;
    children: React.ReactChild;
}) {
    const staticApiUrl = staticApiKey ? 'https://enterprise.static-maps.yandex.ru/1.x' : undefined;

    return (
        <YMapApiProvider apiKey={apiKey} staticApiKey={staticApiKey} staticApiUrl={staticApiUrl}>
            {children}
        </YMapApiProvider>
    );
}
