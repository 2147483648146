import { GeoKind } from '@search/filter-enums/enums/GeoKind';
import { GeoId } from './GeoBase';
import { geoProtoRoute } from './proto/route';
import { GeoObject2 } from './GeoObject2';

export interface MetroRoute {
    readonly kind: GeoKind.ROUTE;
    readonly id: GeoId;
    readonly title: string;
    readonly shortDisplayName?: string;
    readonly color: string;
}

export class RouteStore {
    protected items = new Map<GeoId, MetroRoute>();

    get(id: GeoId): MetroRoute | undefined {
        return this.items.get(id);
    }

    add(geo: GeoObject2) {
        if (geo.kind !== GeoKind.ROUTE) return;

        const id: GeoId = geo.id;

        if (this.items.has(id)) return;

        const route = geoProtoRoute(geo);

        this.items.set(id, route);
    }
}
