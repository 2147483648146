import React, { useCallback, useState } from 'react';
import { Typography } from '@vtblife/uikit';
import classname from '@search/classname/src';
import { AreaUnit } from '@search/filter-enums/enums/AreaUnit';
import { abbreviateNumber, formatLocaleNumber } from '@search/helpers/src/formatNumber';

import { NumberInputRange } from '../NewNumberInputRange/NumberInputRange';
import type { INumberRange } from '../../../types/IRange';

import { priceTypeMapping } from './priceTypeMapping';
import './PriceInput.css';

const cn = classname.bind(null, 'PriceInput');

interface IPriceInputProps {
    className?: string;
    isShowPriceType?: boolean;
    isShowReset?: boolean;
    range: INumberRange;
    priceType?: AreaUnit;
    priceTypes?: AreaUnit[];
    pricePreset?: INumberRange[];
    handlePriceType: (priceType: AreaUnit) => void;
    handleChange: (value: INumberRange) => void;
    handleFrom: (value: number | null) => void;
    handleTo: (value: number | null) => void;
    handleReset?: () => void;
    mortgageInput?: React.ReactNode;
}

type FocusPosition = 'from' | 'to';

export const PriceInput: React.FC<IPriceInputProps> = ({
    className,
    isShowReset = false,
    range,
    pricePreset = [],
    priceTypes = [ AreaUnit.UNKNOWN ],
    priceType = AreaUnit.UNKNOWN,
    handlePriceType,
    handleChange,
    handleFrom,
    handleTo,
    handleReset = function() {},
    mortgageInput
}) => {
    const [ focusPosition, updateFocusPosition ] = useState<FocusPosition>('to');
    const isShowPriceType = ! (priceTypes.length === 1 && priceTypes[0] === AreaUnit.UNKNOWN);

    const handleFocusMin = useCallback(() => {
        updateFocusPosition('from');
    }, []);

    const handleFocusMax = useCallback(() => {
        updateFocusPosition('to');
    }, []);

    return (
        <div className={cn('popup-container', undefined, className)}>
            <div className={cn('toolbar')}>
                <div className={cn('price-types')}>
                    { isShowPriceType && priceTypes.map(type => (
                        <Typography
                            key={type.toString()}
                            className={cn('price-type-item', { active: type === priceType, mortgagePayment: type === AreaUnit.MORTGAGE_PAYMENT })}
                            data-test={`price-type-${type}`}
                            onClick={() => handlePriceType(type)}
                        >
                            {
                                // @ts-ignore
                                priceTypeMapping[type]
                            }
                            {type === AreaUnit.MORTGAGE_PAYMENT ? (
                                <span className={cn('circle')}>&#9679;</span>
                            ) : null}
                        </Typography>
                    ))}
                </div>
                {isShowReset && (
                    <Typography
                        tag='span'
                        onClick={handleReset}
                        className={cn('reset')}
                        data-test='price-reset'
                    >
                        Сбросить
                    </Typography>
                )}
            </div>
            {priceType === AreaUnit.MORTGAGE_PAYMENT ? mortgageInput : <>
                <NumberInputRange
                    size='s'
                    unit='₽'
                    value={range}
                    onChange={handleChange}
                    focusFrom={handleFocusMin}
                    focusTo={handleFocusMax}
                    nameFrom='priceFrom'
                    nameTo='priceTo'
                />
                <div className={cn('price-presets')}>
                    {
                        pricePreset.map(item => {
                            const { value: fromValue, type: fromType } = abbreviateNumber(item.from, { thousandsAsNumber: true });
                            const { value: toValue, type: toType } = abbreviateNumber(item.to, { thousandsAsNumber: true });

                            return (
                                <div
                                    key={item.from!.toString()}
                                    className={cn('predefined-prices')}
                                    data-test='price-preset'
                                >
                                    <div
                                        className={cn('price-item', { focus: focusPosition === 'from', active: range.from === item.from })}
                                        data-test={`price-preset-from-${item.from}`}
                                        onClick={() => {
                                            if (focusPosition === 'from') {
                                                handleFrom(item.from);
                                                updateFocusPosition('to');
                                            }
                                        }}
                                    >
                                        {
                                            focusPosition === 'from' && `${formatLocaleNumber(fromValue!)} ${fromType}`
                                        }
                                    </div>
                                    <div
                                        className={cn('price-item', { focus: focusPosition === 'to', active: range.to === item.to })}
                                        data-test={`price-preset-to-${item.to}`}
                                        onClick={() => {
                                            if (focusPosition === 'to') {
                                                handleTo(item.to);
                                            }
                                        }}
                                    >
                                        {
                                            focusPosition === 'to' && `${formatLocaleNumber(toValue!)} ${toType}`
                                        }
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </>}
        </div>
    );
};
