import { NewBuildingSortEnum, NewBuildingOffersSortEnum } from '@search/graphql-typings';

import { DeveloperWithPromoName } from '../developer-promo/types';

export const VTB_IPOTEKA_LINK = 'https://www.vtb.ru/personal/ipoteka/novostrojki/';

export const STATIC_URL = 'https://static.m2.ru';

const NO_PHOTO_SRC_2X = 'https://cdn.m2.ru/assets/file-upload-server/11ae2be505b8e791517e93536a53b29a.png';

export const NO_PHOTO_SRC = 'https://cdn.m2.ru/assets/file-upload-server/a2753e1d796eb360ead3bff35e8a3f9e.png';
export const NO_PHOTO_SRC_SET = `${NO_PHOTO_SRC} 1x, ${NO_PHOTO_SRC_2X} 2x`;

export const CURRENT_YEAR = new Date().getFullYear();

export enum M2ProEnum {
    All = 'ALL',
    M2Pro = 'M2PRO'
}

export const M2PRO_OPTIONS = {
    [M2ProEnum.All]: {
        value: M2ProEnum.All,
        label: 'Все ЖК',
        buttonLabel: 'Все ЖК'
    },
    [M2ProEnum.M2Pro]: {
        value: M2ProEnum.M2Pro,
        label: 'M2Pro Новостройки',
        buttonLabel: 'M2Pro Новостройки'
    }
};

export const SORT_OPTIONS = [
    {
        value: NewBuildingSortEnum.RelevanceDesc,
        label: 'По умолчанию'
    },
    {
        value: NewBuildingSortEnum.PriceAsc,
        label: 'Сначала дешевые'
    },
    {
        value: NewBuildingSortEnum.PriceDesc,
        label: 'Сначала дорогие'
    },
    {
        value: NewBuildingSortEnum.DateOfCommissioningAsc,
        label: 'Сдаются раньше'
    },
    {
        value: NewBuildingSortEnum.DateOfCommissioningDesc,
        label: 'Сдаются позже'
    },
    {
        value: NewBuildingSortEnum.TimeToMetroAsc,
        label: 'Сначала ближе к метро'
    },
    {
        value: NewBuildingSortEnum.SqmPriceAsc,
        label: 'Сначала дешевле за м²'
    },
    {
        value: NewBuildingSortEnum.SqmPriceDesc,
        label: 'Сначала дороже за м²'
    },
    {
        value: NewBuildingSortEnum.TotalAreaAsc,
        label: 'Сначала с меньшей площадью'
    },
    {
        value: NewBuildingSortEnum.TotalAreaDesc,
        label: 'Сначала с большей площадью'
    }
];

export const OFFERS_SORT_OPTIONS = [ {
    value: NewBuildingOffersSortEnum.PriceAsc,
    label: 'Сначала дешёвые'
}, {
    value: NewBuildingOffersSortEnum.PriceDesc,
    label: 'Сначала дорогие'
}, /* {
    value: NewBuildingOffersSortEnum.CreateDateAsc,
    label: 'Сначала новые'
}, {
    value: NewBuildingOffersSortEnum.CreateDateDesc,
    label: 'Сначала старые'
}, {
    value: NewBuildingOffersSortEnum.TimeToMetroAsc,
    label: 'Сначала ближе к метро'
}, */ {
    value: NewBuildingOffersSortEnum.PriceSqMeterAsc,
    label: 'Сначала дешевле за м²'
}, {
    value: NewBuildingOffersSortEnum.PriceSqMeterDesc,
    label: 'Сначала дороже за м²'
}, {
    value: NewBuildingOffersSortEnum.TotalAreaAsc,
    label: 'Сначала с меньшей площадью'
}, {
    value: NewBuildingOffersSortEnum.TotalAreaDesc,
    label: 'Сначала с большей площадью'
} ];

export enum DATA_GTM {
    SNIPPET_PHONE = 'snippet-phone',
    SNIPPET_MAP = 'snippet-map',
    SNIPPET_ACCREDITED = 'snippet-accredited',
    SNIPPET_FLATS = 'snippet-flats',

    MORTGAGE_BANNER_SEND = 'ipoteka_from_serp_promo_send',
    MORTGAGE_ON_SNIPPET = 'ipoteka_from_serp_snippet',
    MORTGAGE_BROKER_SEND = 'ipoteka_from_card_application',
    MORTGAGE_BROKER_SHOW_MORE = 'ipoteka_from_card_all',
    MORTGAGE_BROKER_BANK_OFFER = 'ipoteka_from_card_bank',
    MORTGAGE_RIGHT_BLOCK = 'ipoteka_from_card_developer',
    MORTGAGE_OFFER = 'ipoteka_from_card_offer',

    MORTGAGE_SEND_CLICK = 'new_building_card_sale_view_ipoteka_banks',
    MORTGAGE_SHOW_MORE_CLICK = 'new_building_card_sale_view_ipoteka_call_back',
    MORTGAGE_STARTER_MODAL_SEND_ID = 'new_building_card_sale_ipoteka_banks',

    MORTGAGE_RIGHT_BLOCK_STARTER_MODAL_SHOW_ID = 'new_building_card_right_block_sale_view_ipoteka_banks',
    MORTGAGE_RIGHT_BLOCK_STARTER_MODAL_SEND_ID = 'new_building_card_right_block_sale_ipoteka_banks',

    MORTGAGE_OFFER_STARTER_MODAL_SHOW_ID = 'new_building_card_offer_sale_view_ipoteka_banks',
    MORTGAGE_OFFER_STARTER_MODAL_SEND_ID = 'new_building_card_offer_sale_ipoteka_banks',

    MORTGAGE_SNIPPET_STARTER_MODAL_SHOW_ID = 'new_building_snippet_sale_view_ipoteka_banks',
    MORTGAGE_SNIPPET_STARTER_MODAL_SEND_ID = 'new_building_snippet_sale_ipoteka_banks',

    PROMO_BANNER = 'promo-banner',
    PROMO_SNIPPET = 'promo-snippet',
    PROMO_SNIPPET_PHONE = 'promo-snippet-phone',

    CARD_PHONE_1 = 'card-phone-1',
    CARD_PHONE_2 = 'card-phone-2',
    CARD_PHONE_3 = 'card-phone-3',
    CARD_PHONE_4 = 'card-phone-4',
    CARD_PHONE_5 = 'card-phone-5',
    CARD_PHONE_OFFER_MODAL = 'popup-flat-phone',
    CARD_PHONE_GALLERY = 'card-phone-gallery',

    CARD_SQUARE_BANNER = 'square-banner',
    CARD_SQUARE_BANNER_PHONE = 'square-banner-phone',
    CARD_BANNER = 'card-banner',

    CARD_CALL_BACK = 'call-request',
    CARD_CALL_BACK_SPECIAL_EVENT = 'call-request4',
    CARD_CALL_BACK_BUILDING = 'call-request5',
    CARD_CALL_BACK_OFFER = 'popup-flat-call-request',
    CARD_CALL_BACK_SEND = 'call-request-send',
    CARD_CALL_BACK_SEND_SPECIAL_EVENT = 'call-request-send4',
    CARD_CALL_BACK_SEND_BUILDING = 'call-request-send5',
    CARD_CALL_BACK_SEND_OFFER = 'popup-flat-call-request-send',

    OFFER_CALL_BACK = 'popup-flat-call-request',
    OFFER_CALL_BACK_SEND = 'popup-flat-call-request-send',

    POPUP_CALL_BACK = 'аb-call-me',
    POPUP_CALL_BACK_SEND = 'аb-send-call-me',
    POPUP_PHONE = 'аb-show-phone',

    FOOTER_PHONE = 'phone-footer-opg'
}

export const developerLandingDataGTM = (
    developerWithPromoName: DeveloperWithPromoName,
    event: 'slide-show' | 'slide-click' | 'snippet-show' | 'snippet-click'
) => `${developerWithPromoName}-landing-${event}`;
