import React from 'react';
import classname from '@search/classname/src';
import './styles.css';

const cn = classname.bind(null, 'GalleryDesktopLoading');

export const LoadingSpinner = ({ show = true, staticLoader = false }) => {
    if (show) {
        return null;
    }

    return (
        <div className={cn(null, { staticLoader })}>
            {staticLoader ? '...' : null}
        </div>
    );
};
