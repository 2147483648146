/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import { RealtyObjectType } from '@search/filter-enums/enums/RealtyObjectType';
import { DealType } from '@search/filter-enums/enums/DealType';

type MatchParamsBase = {
    dealType: DealType;
    categoryType: RealtyObjectType;
    builtYearMin?: number | null;
    builtYearMax?: number | null;
};

type MatchParams = MatchParamsBase & { seg?: string | null };

export class BuildEpochSeoMatcher {
    static $ = new BuildEpochSeoMatcher();
    protected map(): Record<string, readonly (MatchParamsBase)[]> {
        return {
            stalinka: [
                { dealType: DealType.SELL, categoryType: RealtyObjectType.FLAT, builtYearMin: 1933, builtYearMax: 1956 },
            ],
            khrushchevka: [
                { dealType: DealType.SELL, categoryType: RealtyObjectType.FLAT, builtYearMin: 1957, builtYearMax: 1969 },
            ],
            brezhnevka: [
                { dealType: DealType.SELL, categoryType: RealtyObjectType.FLAT, builtYearMin: 1970, builtYearMax: 1980 },
            ],
        };
    }

    protected mapCached = Object.entries(this.map());

    readonly chpuKeys = Object.keys(this.map());

    matchAll(p: MatchParams, _isFirstMatch = false) {
        const result = [] as { seg: string; params: MatchParamsBase }[];

        for (const [ seg, variants ] of this.mapCached) {
            if (p.seg && seg !== p.seg) continue;

            let isSegAlreadyPushed = false;

            for (const params of variants) {
                if (isSegAlreadyPushed) continue;
                if (p.categoryType !== params.categoryType) continue;
                if (p.dealType !== params.dealType) continue;
                if (p.builtYearMin && p.builtYearMin !== params.builtYearMin) continue;
                if (p.builtYearMax && p.builtYearMax !== params.builtYearMax) continue;
                if (p.builtYearMin === undefined || p.builtYearMax === undefined) continue;

                result.push({ seg, params });
                if (! isSegAlreadyPushed) isSegAlreadyPushed = true;
                if (_isFirstMatch) return result;
            }
        }

        return result;
    }

    match(p: MatchParams) {
        const result = this.matchAll(p, true);

        return result.length ? result[0] : undefined;
    }
}
