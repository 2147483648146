import { SeoMapper } from '@search/router/src';

export const lenOblCity = {} as const;

export const spbInnerTowns = {
    kolpino: 2258,
    'krasnoe-selo': 4848,
    kronshtadt: 7689,
    zelenogorsk: 919,
    sestroretsk: 2898,
    lomonosov: 4983,
    petergof: 469,
    pavlovsk: 8095,
    pushkin: 718
};

export const spbInnerLocalities = {
    pargolovo: 3925,
    levashovo: 69835,
    pontonniy: 41212,
    'ust-izhora': 71536,
    'petro-slavyanka': 71323,
    sapyorniy: 72330,
    metallostroi: 2273,
    beloostrov: 47020,
    komarovo: 47373,
    molodyozhnoe: 69214,
    pesochniy: 8678,
    repino: 8420,
    serovo: 74973,
    smolyachkovo: 5329,
    solnechnoe: 7879,
    ushkovo: 75561,
    strelna: 8680,
    lisiy_nos: 8093,
    shushary: 7659,
    aleksandrovskaya: 57851,
    tyarlevo: 55715
};

export const spbCity = {
    ...spbInnerTowns,
    ...spbInnerLocalities
};

export const spbCityMapper = new SeoMapper('spbCityMapper', {
    ...lenOblCity,
    ...spbCity
});

export const lenOblCityIds = Object.values(lenOblCity) as number[];
export const spbCityIds = Object.values(spbCity);
