import React from 'react';
import { Button } from '@vtblife/uikit';

import './styles.css';

interface IProps {
    handleOpenPanorama?: (e: React.MouseEvent<Element, MouseEvent>) => void;
    handleOpenLayout?: (e: React.MouseEvent<Element, MouseEvent>) => void;
}

export const GalleryButtons = ({ handleOpenPanorama, handleOpenLayout }: IProps) => (
    <div className='GalleryButtons'>
        {handleOpenPanorama && (
            <Button
                icon='panorama'
                variant='transparent'
                rounded='none'
                size='s'
                fullWidth
                onClick={handleOpenPanorama}
            >
                Панорама
            </Button>
        )}
        {handleOpenLayout && (<Button
            icon='blueprint'
            variant='transparent'
            rounded='none'
            size='s'
            fullWidth
            onClick={handleOpenLayout}
        >
            Планировка
        </Button>)}
    </div>
);
