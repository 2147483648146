import { s, SusaninRoute } from '@search/router/src';
import { Tokens } from '@search/router/src/RouterInterfaces';
import { RouterContext } from '../RouterProvider';

const PhoneCallType = s.rec({
    offerId: s.str
});

export type IPhoneCallType = ReturnType<typeof PhoneCallType>;

const PhoneCallSeoType = s.rec({
    origin: s.nullOpt(s.str),
    offerId: s.str
});

export type IPhoneCallSeoType = ReturnType<typeof PhoneCallSeoType>;

export class PhoneCallRoute extends SusaninRoute<IPhoneCallSeoType, IPhoneCallType, RouterContext> {
    displayName = 'PhoneCallRoute';

    protected metadata = PhoneCallSeoType.config;

    static middlewareUrl = '/m/search/phone-call';

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected pattern(p: Tokens<IPhoneCallSeoType>) {
        return `${this.patternHost()}/m/search/phone-call(/)`;
    }

    defaults() {
        return {};
    }

    protected fromQuery(seo: IPhoneCallSeoType) {
        return PhoneCallType({
            offerId: seo.offerId
        });
    }

    protected toQuery(p: IPhoneCallType) {
        const offerId = p.offerId;

        try {
            return PhoneCallSeoType({
                offerId
            });
        } catch (e) {
            // @ts-expect-error
            e.message += `, offerId: ${offerId}`;
            throw e;
        }
    }
}
