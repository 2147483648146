/* eslint-disable no-nested-ternary, react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import { Typography } from '@vtblife/uikit';
import classname from '@search/classname/src';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { SizeThumbnail, FigcaptionPosition, DirectionArrow, FigcaptionPositionColor } from '../constants';
import { Arrow } from '../Arrow/Arrow';

import './styles.css';

interface IHeroPicture {
    alt: string;
    src: string;
    size: SizeThumbnail;
    handleNext: () => void;
    handlePrev: () => void;
    renderArrow: boolean;
    countText?: string;
    figcaptionPosition: FigcaptionPosition;
    figcaptionPositionColor: FigcaptionPositionColor;
    offerTitle?: string;
    withCount?: boolean;
    urlErrorImage?: string;
}

const cn = classname.bind(null, 'GalleryDesktopHeroPicture');

export function HeroPictureWrapper({
    renderArrow,
    handlePrev,
    handleNext,
    size,
    renderLoader,
    children,
    isAltView
}: {
    size: SizeThumbnail;
    children?: React.ReactNode;
    renderArrow?: boolean;
    renderLoader?: boolean;
    handleNext: () => void;
    handlePrev: () => void;
    isAltView?: boolean;
}) {
    return (<div className={cn(null, { size })}>
        <div className={cn('spinner')}>
            {renderLoader ? <LoadingSpinner show /> : null}
        </div>
        <div className={cn('wrap', { alt: isAltView })}>
            {children}
            {renderArrow && (
                <>
                    <button className={cn('arrow', { left: true, alt: isAltView })} onClick={handlePrev}>
                        <Arrow direction={DirectionArrow.LEFT} />
                    </button>
                    <button className={cn('arrow', { right: true, alt: isAltView })} onClick={handleNext}>
                        <Arrow direction={DirectionArrow.RIGHT} />
                    </button>
                </>
            )}
        </div>
    </div>);
}

export const HeroPicture = ({
    alt,
    src,
    size,
    handleNext,
    handlePrev,
    renderArrow,
    countText,
    offerTitle,
    figcaptionPosition,
    figcaptionPositionColor,
    withCount,
    urlErrorImage
}: IHeroPicture) => {
    const [ cache, setCache ] = useState<string[]>([]);
    const [ renderLoader, setRenderLoader ] = useState(false);
    const [ state, setState ] = useState<{
        hero?: string;
        loading: boolean;
        error: boolean;
    }>({
        hero: src,
        loading: true,
        error: false
    });

    const onLoad = () => setTimeout(() => {
        setState({
            hero: src,
            loading: false,
            error: false
        });
    }, 10);

    const onError = useCallback(() => {
        setState(prev => ({
            ...prev,
            loading: false,
            error: true
        }));
    }, []);

    useEffect(() => {
        setRenderLoader(false);

        if (cache.includes(src)) {
            setState(({
                hero: src,
                loading: true,
                error: false
            }));
        } else {
            setCache([ ...cache, src ]);
            setState(({
                hero: undefined,
                loading: true,
                error: false
            }));
            onLoad();
        }

        const id = setInterval(() => {
            setRenderLoader(true);
        }, 1000);

        return () => clearInterval(id);
    }, [ src, cache ]);

    return (<HeroPictureWrapper
        renderLoader={renderLoader}
        renderArrow={renderArrow}
        size={size}
        handleNext={handleNext}
        handlePrev={handlePrev}
    >
        {state.error && <img
            src={urlErrorImage}
            crossOrigin='anonymous'
            alt='При загрузке фото что-то пошло не так'
            className={cn('error')}
        />}

        {! state.error && <figure className={cn('figure')}>
            <img
                alt={alt}
                src={state.hero}
                onLoad={onLoad}
                onError={onError}
                crossOrigin='anonymous'
                className={cn('image')}
            />
            {(offerTitle || alt || withCount) ? (
                <figcaption className={cn('figcaption', { position: figcaptionPosition })}>
                    {offerTitle ? (
                        <Typography
                            variant='primary'
                            color={figcaptionPositionColor}
                            className={cn('title')}
                        >
                            {offerTitle}
                        </Typography>
                    ) : null}
                    {alt ? (
                        <Typography
                            variant='primary'
                            color={figcaptionPositionColor}
                            className={cn('alt', {
                                align: offerTitle ? (withCount ? 'center' : 'right') : (withCount ? 'left' : 'center')
                            })}
                        >
                            {alt}
                        </Typography>
                    ) : null}
                    {withCount ? (
                        <Typography
                            variant='primary'
                            color={figcaptionPositionColor}
                            className={cn('total', { align: 'right' })}
                        >
                            {countText}
                        </Typography>
                    ) : null}
                </figcaption>
            ) : null}
        </figure>}
    </HeroPictureWrapper>);
};
