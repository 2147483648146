/* global window */
import React from 'react';

type KeyCodes = 'Escape';
type KeyHandlers = Record<KeyCodes, ((...args: any[]) => void) | undefined>;

function useKeyHandler(handlers: KeyHandlers) {
    return React.useCallback((event: KeyboardEvent) => {
        const handler = handlers[event.key as KeyCodes];

        handler?.();
    }, Object.keys(handlers).map(key => handlers[key as unknown as KeyCodes]));
}

export function useKeyUp(onKeyUp: (event: KeyboardEvent) => void) {
    React.useEffect(() => {
        window.addEventListener('keyup', onKeyUp);

        return () => {
            window.removeEventListener('keyup', onKeyUp);
        };
    }, [ onKeyUp ]);
}

export function useKeyCancel(onCancel?: () => void) {
    const handler = useKeyHandler({ Escape: onCancel });

    useKeyUp(handler);
}
