import React, { useCallback } from 'react';
import { Checkbox } from '@vtblife/uikit';
import { WallsType } from '@search/filter-enums/enums/WallsType';

import { WallsTypeFilter } from '../../../../../filters/models/Filter';
import { Filter2 } from '../../../new_filter/Filter2';
import { CheckboxGroup2 } from '../../../common/CheckboxGroup2';

const wallsEnumeration = [
    WallsType.MONOLITH,
    WallsType.PANEL,
    WallsType.BRICK,
    WallsType.BRICK_MONOLITH,
    WallsType.BLOCK
];

const wallsEnumerationWithWood = [
    ...wallsEnumeration,
    WallsType.WOOD
];

const wallsMapping = {
    [WallsType.MONOLITH]: 'Монолит',
    [WallsType.PANEL]: 'Панель',
    [WallsType.BRICK]: 'Кирпич',
    [WallsType.BRICK_MONOLITH]: 'Кирпич-монолит',
    [WallsType.BLOCK]: 'Блок',
    [WallsType.WOOD]: 'Дерево'
};

interface IWallsFilterProps {
    withWood?: boolean;
    filter: WallsTypeFilter;
    handleChange: (filter: WallsTypeFilter) => void;
}

export const DesktopWallsFilterView: React.FC<IWallsFilterProps> = ({
    withWood = true,
    filter,
    handleChange
}) => {
    const onChange = useCallback(val => {
        if (val === null) {
            return;
        }

        if (Array.isArray(val)) {
            handleChange(new WallsTypeFilter(val));
        }
    }, [ handleChange ]);

    const component = (withWood ? wallsEnumerationWithWood : wallsEnumeration).map(wall => {
        return (
            <Checkbox
                variant='filter'
                key={wall}
                name={String(wall)}
                block
            >
                {wallsMapping[wall]}
            </Checkbox>
        );
    });

    return (
        <Filter2 filterTitle='Материал стен'>
            <CheckboxGroup2
                mode='check'
                name='walls'
                onChange={onChange}
                value={filter.walls.map(elem => String(elem))}
                colRestriction={3}
                isFilter
            >
                {component}
            </CheckboxGroup2>
        </Filter2>
    );
};
