import React from 'react';

import classname from '@search/classname/src';

import './styles.css';

const cnList = classname.bind(null, 'List');

interface IList {
    /**
     * Вид списка
     */
    layout?: 'horizontal' | 'vertical';

    /**
     * Разделитель между элементами в режиме horizontal
     */
    separator?: string | React.ReactNode;

    children: Array<React.ReactNode>;

    className?: string;
}

export const List: React.FunctionComponent<IList> = ({
    children,
    className,
    separator,
    layout = 'horizontal'
}) => {
    const content = React.Children.toArray(children).reduce((ret: Array<React.ReactNode>, item, index) => {
        if (index > 0 && separator) {
            ret.push(
                <span
                    key={`separator_${index}`}
                    className={cnList('separator')}
                >
                    {separator}
                </span>
            );
        }

        ret.push(item);

        return ret;
    }, []);

    return (
        <ul className={cnList(null, { layout }, className)}>
            {content}
        </ul>
    );
};

interface IListItem {
    title?: string;
    className?: string;
}

export const ListItem: React.FunctionComponent<IListItem> = ({ children, className, title = '' }) => {
    return (
        <li
            title={title}
            className={cnList('item', undefined, className)}
        >
            {children}
        </li>
    );
};
