// import { Bathroom } from '../../view/enums/Bathroom';
import { SeoMapper } from '@search/router/src';

export const livingFacilityMapper = new SeoMapper('livingFacilityMapper', {
    UNKNOWN: 'UNKNOWN',
    INTERNET: 'INTERNET',
    WASHING_MACHINE: 'WASHING_MACHINE',
    FRIDGE: 'FRIDGE',
    DISHWASHER: 'DISHWASHER',
    AIR_CONDITIONER: 'AIR_CONDITIONER',
    TV: 'TV',
} as const);
