import React from 'react';

import { Link, Typography } from '@vtblife/uikit';

import classname from '@search/classname/src';
import { NativeDate, NewBuildingNews } from '@search/graphql-typings';
import { ImageLazySeo } from '../../../../domain/image/lazy/ImageLazySeo';
import { NO_PHOTO_SRC, NO_PHOTO_SRC_SET } from '../../../../domain/newbuilding/libs/constants';

import './styles.css';

const cn = classname.bind(null, 'NewsTinyCard');

// eslint-disable-next-line max-len
export type NewsTinyItem = Readonly<Omit<NewBuildingNews, 'id' | 'slug' | 'date' | 'subCategories' | 'tags' | 'viewsCount' | 'readTime'>> & { date: Pick<NativeDate, 'formattedShort'> };

export const NewsTinyCard = ({
    news,
    isMobile
}: {
    news: NewsTinyItem;
    isMobile?: boolean;
}) => (
    <article className={cn(null, { mobile: isMobile })}>
        <Link
            href={news.link}
            text={false}
            target='blank'
        >
            <div className={cn('imageWrapper')}>
                <ImageLazySeo
                    loadingLazy='native'
                    src={news.images[0] ?? NO_PHOTO_SRC}
                    srcSet={news.images[0] ? undefined : NO_PHOTO_SRC_SET}
                    className={cn('image')}
                    alt={news.title}
                />
            </div>
            <Typography
                variant='secondary-alone'
                color='secondary'
                className={cn('info')}
            >
                {news.date.formattedShort}
                {news.categories[0]?.label ? <>
                    <span className={cn('info-separator')}>•</span>
                    {news.categories[0]?.label}
                </> : null}
            </Typography>

            <Typography
                tag='h4'
                title={news.title}
                className={cn('title')}
                bold
            >
                {news.title}
            </Typography>
        </Link>
    </article>
);

