import './styles.css';

import React, { useCallback, useEffect, useState } from 'react';
import classname from '@search/classname/src';
import { Typography } from '@vtblife/uikit';

import { ApplicationIdEnum, MortgagePurpose } from '@search/graphql-typings';
import {
    getMinimalBanksMortgageEconomyPercent,
    minimalBanksMortgageParamsFromOffer,
    useMinimalBanksMortgageOverall,
    UseMinimalBanksMortgageStateParams
} from '../../../common/components/MinimalBanksMortgage/useMinimalBanksMortageState';
import { Mortgage2StarterModal } from '../../../common/components/MinimalBanksMortgage/mortgage2/starter/Mortgage2StarterModal';

const cn = classname.bind(null, 'MinimalBanksMortgageSnippetDesktop');

export function MinimalBanksMortgageSnippetDesktop(
    {
        className,
        gtm,
        ...stateParams
    }: {
        className?: string;
        gtm?: string;
        ownAmount: number;
        url: string;
    } & UseMinimalBanksMortgageStateParams
) {
    const { economyFormatted } = getMinimalBanksMortgageEconomyPercent();

    const [ isModalOpen, toggleModal ] = useState<boolean>(false);
    const openModal = useCallback(() => toggleModal(true), []);
    const closeModal = useCallback(() => toggleModal(false), []);

    return (
        <div className={cn(undefined, undefined, className)}>
            <Typography
                bold
                onClick={openModal}
                variant='primary-alone'
                className={cn('link')}
            >
                Рассчитать ипотеку
            </Typography>
            <Typography
                variant='secondary-alone'
                className={cn('promo')}
            >
                Сэкономьте до&nbsp;{economyFormatted} на&nbsp;ставке вместе с&nbsp;«Метром квадратным»
            </Typography>
            {
                isModalOpen ? (
                    <MortgageModal
                        {...stateParams}
                        onClose={closeModal}
                    />
                ) : null
            }
        </div>
    );
}

export function MortgageModal({
    url: snippetURL,
    onClose,
    ...stateParams
}: {
    ownAmount: number;
    url?: string;
    onClose: () => void;
} & UseMinimalBanksMortgageStateParams) {
    const mortgageParams = minimalBanksMortgageParamsFromOffer(stateParams);
    const {
        minimalBanksMortgageOffers,
        url: calculatorUrl
    } = useMinimalBanksMortgageOverall(mortgageParams);

    useEffect(() => {
        if (minimalBanksMortgageOffers?.length === 0) {
            window.open(calculatorUrl, '_blank');
            onClose();
        }
    }, [ calculatorUrl, minimalBanksMortgageOffers, onClose ]);

    return minimalBanksMortgageOffers?.length ? (
        <Mortgage2StarterModal
            withoutAuth
            isNewVibe
            alwaysFromWord
            purpose={MortgagePurpose.Primary}
            locationPlacement='сниппет'
            offerUrl={snippetURL}
            gtmSendData={{
                event: 'nb_ib_form',
                action: 'send',
                page_type: 'serp',
            }}
            gtmShowData={{
                event: 'nb_ib_form',
                action: 'open',
                page_type: 'serp',
            }}
            withoutShowContainer
            applicationId={ApplicationIdEnum.NewBuildingsDesktop}
            onClose={onClose}
            realtyType='ProfOfferFlat'
            mortgageRegionId={stateParams.mortgageRegionId}
            propertyCost={stateParams.propertyCost}
            ownAmount={stateParams.ownAmount}
            term={stateParams.term}
            isPreferentialNbCondition
            showMoreBankIcons
            isDefaultSelectedOffer
            mortgageOffers={minimalBanksMortgageOffers}
        />
    ) : null;
}
