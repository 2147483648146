import React, { useCallback } from 'react';

import { Checkbox } from '@vtblife/uikit';
import classname from '@search/classname/src';
import { Position } from '@search/filter-enums/enums';

import { PositionFilter } from '../../../../models/Filter';
import { Filter2 } from '../../../new_filter/Filter2';
import { CheckboxGroup2 } from '../../../common/CheckboxGroup2';

const ITEMS = [
    [ Position.STANDALONE, 'Одиночное здание' ]
];

const cn = classname.bind(null, 'DesktopPositionFilterView');

interface IProps {
    filter: PositionFilter;
    handleChange: (filter: PositionFilter) => void;
}

export const DesktopPositionFilterView: React.FC<IProps> = ({
    filter,
    handleChange
}) => {
    const onChange = useCallback((val: string | string[] | null) => {
        if (val === null) {
            return;
        }

        if (Array.isArray(val)) {
            handleChange(new PositionFilter(val as Position[]));
        }
    }, [ handleChange ]);

    return (
        <Filter2
            className='DesktopPositionFilterView'
            filterTitle='Размер ЖК'
        >
            <CheckboxGroup2
                mode='check'
                name='position'
                onChange={onChange}
                value={filter.position}
                isFilter
            >
                {
                    ITEMS.map(([ value, label ]) => (
                        <Checkbox
                            variant='filter'
                            key={value}
                            name={value}
                            block
                        >
                            {label}
                        </Checkbox>
                    ))
                }
            </CheckboxGroup2>
        </Filter2>
    );
};
