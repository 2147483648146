import './ButtonTwoState.css';

import React from 'react';
import classname from '@search/classname/src';
import { Tooltip, Button, useTooltip, ButtonProps } from '@vtblife/uikit';
import Icon from '@vtblife/uikit-icons';

const cn = classname.bind(null, 'ButtonTwoState');

export function ButtonTwoState({
    tooltipText,
    tooltipAsText,
    children = tooltipAsText ? tooltipText : null,
    tooltipDirection = 'up',
    variant,
    isActive,
    onClick,
    size = 's',
    dataAttrs,
    dataTest,
    onlyIconStyle,
    paddingSize,
    noTooltip,
    ...props
}: ButtonProps & {
    tooltipAsText?: boolean;
    isActive?: boolean;
    tooltipText?: string;
    tooltipDirection?: React.ComponentProps<typeof Tooltip>['direction'];
    onlyIconStyle?: boolean;
    noTooltip?: boolean;
    /**
     * Свойство для работы только в виде иконки. Паддинг вокруг иконки.
     * @param {string} m - 10px
     */
    paddingSize?: 'm';
}) {
    const tooltip = useTooltip();
    // const colorBlack400 = 'hsl(251, 10%, 46%)';
    const colorBlack250 = 'hsl(251, 17%, 75%)';
    const colorRed600 = 'hsl(5, 90%, 48%)';

    let normalVariant = variant;
    let iconColor = undefined as ButtonProps['iconColor'];

    if (variant === 'transparent' || variant === 'transparent-alt-bg') {
        const normalColor = children ? undefined : colorBlack250;

        iconColor = isActive ? colorRed600 : normalColor;
    }
    if (isActive && (variant !== 'transparent' && variant !== 'transparent-alt-bg')) normalVariant = 'danger';

    const button = variant === 'transparent-alt-bg' ? (
        <button
            data-test={dataTest}
            className={cn(null, { isActive, isBlack: Boolean(children), onlyIconStyle, paddingSize })}
            ref={tooltip.registerControl as React.Ref<HTMLButtonElement>}
            onClick={e => {
                e.stopPropagation();
                onClick?.(e);
            }}
        >
            <Icon
                size='m'
                name={props.icon as Extract<React.ComponentProps<typeof Icon>, { size: 'm' }>['name']}
            />
            {children && (
                <span className={cn('text')}>
                    {children}
                </span>
            )}
        </button>
    ) : (
        <div className={cn(null)}>
            <Button
                {...props}
                dataAttrs={dataAttrs}
                dataTest={dataTest}
                size={size}
                iconColor={iconColor}
                variant={normalVariant}
                ref={tooltip.registerControl as React.Ref<HTMLButtonElement>}
                onClick={e => {
                    e.stopPropagation();
                    onClick?.(e);
                }}
            >
                {children}
            </Button>
        </div>
    );

    return (<>
        {onClick ? button : null}
        {! noTooltip && tooltipText && ! tooltipAsText ? (
            <Tooltip
                placement='body'
                direction={tooltipDirection}
                to={tooltip.to}
                children={tooltipText}
            />
        ) : null}
    </>);
}
