import React, { useCallback } from 'react';

import { Size } from '@vtblife/uikit/legacy';

import { IFilterAvailableProps } from '../../new_filter/Filter2';
import { NumberInputRange } from '../../common/NewNumberInputRange/NumberInputRange';
import { AreaFilter } from '../../../models/Filter';
import { INumberRange } from '../../../types/IRange';
import { MobileFilter2 } from '../../filter/MobileFilter2';

interface IAreaFilterProps extends IFilterAvailableProps {
    filter: AreaFilter;
    handleChange: (value: AreaFilter) => void;
    isMobile?: boolean;
}

export const AreaFilterView: React.FC<IAreaFilterProps> = ({
    filter,
    handleChange,
    withoutTitle,
    isMobile
}) => {
    const handleAreaChange = useCallback((value: INumberRange) => {
        handleChange(new AreaFilter(value));
    }, [ handleChange ]);

    return (
        <MobileFilter2 desktopTitleGap={! isMobile} filterTitle={withoutTitle ? undefined : 'Площадь'} dataTestId='AreaFilterView'>
            <NumberInputRange
                size={Size.Small}
                unit='м²'
                fromPostfix='м²'
                value={filter.value}
                onChange={handleAreaChange}
                nameFrom='areaFrom'
                nameTo='areaTo'
                max={100_000}
            />
        </MobileFilter2>
    );
};
