import React, { Ref, HTMLAttributes, forwardRef } from 'react';
import cn from '@search/classname/src';
import './styles.css';
import { SizeEnum } from '../../types';

const cnPaper = cn.bind(null, 'Paper');

export interface IPaperProps extends HTMLAttributes<HTMLDivElement> {
    /**
    * Высота в Z-координаты компонента для визаулизации тени
    * @default "s"
    **/
    elevation?: 's' | 'm' | 'l' | 'xl' | 'xxl';
    /**
    * Цвет тени
    * @default "gray"
    **/
    shadowСolor?: 'gray' | 'green' | 'red' | 'blue';
    /**
    * Свойство обнуляет border-radius
    * @deprecated use borderRadius: BorderRadius.none
    * @default false
    **/
    square?: boolean;

    /**
     * Размер радиуса бордера.
     * @default SizeEnum.M
     */
    borderRadius?: BorderRadius.none | SizeEnum.M | SizeEnum.L | 'none';

    dataTest?: string;

    id?: string;
}

export enum BorderRadius {
    none = 'none'
}

/**
 * @todo forwardRef is not supported in storybook without export const: https://github.com/storybookjs/storybook/issues/7933
 */
export const Paper = forwardRef(({
    elevation = 's',
    shadowСolor = 'gray',
    square = false,
    className,
    dataTest,
    borderRadius = SizeEnum.M,
    ...rest
}: IPaperProps, ref: Ref<HTMLDivElement>) => {
    const mods = `${shadowСolor}_${elevation}`;

    return (
        <div
            {...rest}
            data-test={dataTest}
            ref={ref}
            /* eslint-disable-next-line @typescript-eslint/naming-convention */
            className={cnPaper(null, { elevation: mods, square, 'border-radius': borderRadius }, className)}
        />
    );
});

export default Paper;
