/* eslint-disable @typescript-eslint/naming-convention */
import React, {
    useRef,
    FunctionComponent,
    useLayoutEffect,
    createContext,
    useState,
    useContext,
    useCallback,
    memo,
    ReactNode,
    useMemo
} from 'react';
import { createPortal } from 'react-dom';
import classname from '@search/classname/src';

import './Modal.css';
import Paper, { BorderRadius } from '../Paper';
import { SizeEnum } from '../../types';

export enum ModalSize {
    FULL_M = 'full_m',
    FULL_S = 'full_s',
    FULL_L = 'full_l',
    S = 's',
    M = 'm',
    XL = 'xl',
    L = 'l',
}

export interface ModalProps extends React.HTMLAttributes<HTMLElement> {
    coverRef?: React.LegacyRef<HTMLDivElement> | null;

    alignLeft?: boolean;

    /**
     * Сработает при нажатии на кнопку закрытия, по клику на пространство вне модалки, по нажатию esc
     */
    onRequestClose?: (closedBy: ModalClosedBy) => void;

    /**
     * Показывать кнопку закрытия
     * @default true
     */
    showCloseButton?: boolean;
    height?: ModalSize;
    fullScreen?: boolean;
    width?: ModalSize;
    /**
     * Переводить ли фокус на последний интерактивный элемент в модалке после ее появления
     * @default false
     */
    focusLast?: boolean;

    /**
     * Размер модалки по горизонтали
     * MarginSize задает размер отступов от верха и низа видимой части окна
     *
     * @default SizeEnum.M
     **/
   size?: ModalSize;

    /**
     * Размер радиуса бордера. none - 0px, m - 4px, l - 8px
     * @default SizeEnum.L
     */
    borderRadius?: BorderRadius.none | SizeEnum.M | SizeEnum.L | 'none';

    children?: ReactNode;

    contentClassName?: string;

    dataTest?: string;

    withScroll?: boolean;

    withoutScroll?: boolean;

    /**
     * Размер иконки закрытия
     * @default { widht: 18, height: 18 }
     */
    closeIconSize?: { width: number; height: number };

    stylesCloseIcon?: React.CSSProperties;

    /**
     * Убрать белый паддинг у Modal__body элемента
     * @default false
     */
    withoutBodyPadding?: boolean;

    /**
     * Фикс для небольшого отступа снизу из-за табличных стилей.
     * Встречался в панораме, при выключении padding у элемента Modal__body (withoutBodyPadding)
     * @default false
     */
    offTableCSS?: boolean;

    /**
     * Включает z-index = 10001, чтобы стать выше, чем фуллскрин режим Я.Карт
     * @default false
     */
    hugePriorityZIndexEnabled?: boolean;

    /**
     * Включает актуальное значение z-index из uikit для модального окна --l-modal (1050)
     */
    uikitZIndexEnabled?: boolean;
}

export const cnModal = classname.bind(null, 'Modal');

export enum ModalClosedBy {
    ClickOutside = 'ClickOutside',
    CloseButton = 'CloseButton',
    Esc = 'Esc'
}

// const overflowClass = 'Modal__overflow';

// lock body scroll hook
export function useLockBodyScroll() {
    useLayoutEffect(() => {
        if (! window || ! document) return;

        const body = document.body;
        const originalOverflowX = body.style.overflowX;
        const originalOverflowY = body.style.overflowY;

        // Удалить, после перехода на общий body scroll lock.
        // https://gitlab.m2.ru/vtblife/frontend/root/-/blob/master/src/common-modules/layout-components/header/header.tsx
        const handler = setInterval(() => {
            if (body.style.overflowY === 'initial') {
                body.style.overflowY = 'hidden';
            }
        }, 500);

        body.style.overflowX = 'hidden';
        body.style.overflowY = 'hidden';

        return () => {
            clearInterval(handler);
            body.style.overflowX = originalOverflowX;
            body.style.overflowY = originalOverflowY;
        };
    }, [ ]);
}

/*
 * Modal dialog
 *
 * @example Simple demo
 *
 * ```tsx
 * function ModalDemoSimple() {
 *   const modal = useModal()
 *
 *   return <div>
 *     {modal.visible
 *         ? null
 *         : <button onClick={modal.on}>Show modal</button>
 *     }
 *     {modal.visible ? <Modal onRequestClose={modal.off}>Modal Text</Modal> : null}
 *   </div>
 * }
 * ```
 *
 * @example Contexted demo
 *
 * ```tsx
 * // ModalDemoContextedRoot.tsx
 * function ModalDemoContextedRoot() {
 *
 *     return <ModalProvider>
 *         <ModalDemoContextedFirst/>
 *     </ModalProvider>
 * }
 *
 * // ModalDemoContextedFirst.tsx
 * function ModalDemoContextedFirst() {
 *     const modal = createModal(() => (
 *         <Modal onRequestClose={modal.hide}>
 *             Controlled modal: <button onClick={modal.hide}>Close</button>
 *         </Modal>
 *     ))
 *     return <div>
 *         <button onClick={modal.hide}>Show modal</button>
 *     </div>
 * }
 * ```
 *
 * @see https://assortment.io/posts/accessible-modal-component-react-portals-part-1
 * @see https://gist.github.com/danethurber/a586dbc9097e2e5696719c390a00c683
 * @see https://github.com/mpontus/react-modal-hook
 */
export const Modal = ({
    coverRef = null,
    contentClassName,
    className,
    size = ModalSize.M,
    alignLeft = false,
    height,
    width,
    fullScreen = false,
    borderRadius = SizeEnum.L,
    showCloseButton,
    onRequestClose,
    children,
    dataTest,
    withScroll = false,
    withoutScroll = false,
    withoutBodyPadding = false,
    offTableCSS = false,
    closeIconSize = { width: 18, height: 18 },
    stylesCloseIcon,
    onScroll: handleScroll,
    title,
    hugePriorityZIndexEnabled,
    uikitZIndexEnabled
}: ModalProps) => {
    const asideRef = useRef<HTMLDivElement>(null);
    const ref = useRef<HTMLDivElement>(null);

    // @todo Переделать https://jira.cloud.vtblife.ru/browse/RS-463
    // useFocusLock(focusLast ? ref : undefined);
    useLockBodyScroll();
    // useKeyUp('Escape', onRequestClose);
    // useOnClickOutside(ref, onRequestClose);

    const onCloseButton = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        if (onRequestClose) e.preventDefault();

        onRequestClose?.(ModalClosedBy.CloseButton);
    }, [ onRequestClose ]);

    const clickOnAside = (e: React.SyntheticEvent) => {
        if (e.target !== asideRef.current) return;
        if (onRequestClose) {
            e.preventDefault();
            e.stopPropagation();
        }

        onRequestClose?.(ModalClosedBy.ClickOutside);
    };

    const sizeClass = (height && width) ? { width, height } : { size };

    return createPortal(
        <div
            ref={coverRef}
            onScroll={handleScroll}
            className={cnModal('cover', {
                'with-scroll': withScroll,
                'without-scroll': withoutScroll,
                'max-z-index': hugePriorityZIndexEnabled,
                'uikit-z-index': uikitZIndexEnabled
            })}
        >
            <div className={cnModal('table', { offTableCSS })}>
                <div className={cnModal('cell', { ...sizeClass, offTableCSS })} onClick={clickOnAside} ref={asideRef}>
                    <Paper
                        className={cnModal('content', {
                            ...sizeClass,
                            withoutBodyPadding
                        }, contentClassName)}
                        borderRadius={borderRadius}
                        ref={ref}
                    >
                        {showCloseButton ? <button
                            data-test={dataTest ? `${dataTest}-close` : undefined}
                            className={cnModal('close')}
                            onClick={onCloseButton}
                            style={stylesCloseIcon}
                        >
                            <span className='u-hide-visually'>Close</span>
                            <svg className={cnModal('icon')} {...closeIconSize} fill='none' viewBox='0 0 18 18'>
                                <path
                                    stroke='#1B2233'
                                    strokeOpacity='0.92'
                                    strokeWidth='1.5'
                                    d='M1 1.00001L17 17M17 1L1 17'
                                />
                            </svg>
                        </button> :
                            null}
                        <div className={cnModal(
                            'body',
                            { full: fullScreen, alignLeft, withoutBodyPadding },
                            className
                        )}>
                            {title ? <div className={cnModal('title')}>{title}</div> : null}
                            {children}
                        </div>
                    </Paper>
                </div>
            </div>
        </div>
        ,
        document.body
    );
};

export const useModal = (visibleDefault = false) => {
    const [ visible, setVisible ] = useState(visibleDefault);

    return {
        visible,
        on: useCallback(() => setVisible(true), []),
        off: useCallback(() => setVisible(false), []),
        setVisible
    };
};

const ModalContext = createContext({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    show: (modal: React.ReactNode) => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    hide: (modal: React.ReactNode) => {}
});

const ModalRenderer = memo(({ component }: {component: () => ReactNode}) => <>{component()}</>);

let componentId = 0;

export function createModal(createFn: () => React.ReactNode) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const id = useMemo(() => String(++componentId), []);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const component = useMemo(() => <ModalRenderer key={id} component={createFn} />, [ createFn, id ]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const context = useContext(ModalContext);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useMemo(() => ({
        show: () => context.show(component),
        hide: () => context.hide(component)
    }), [ context, component ]);
}

export const ModalProvider: FunctionComponent<{children: React.ReactNode}> = ({ children }) => {
    const [ modals, setModals ] = useState(new Set<React.ReactNode>());

    const show = useCallback((modal: React.ReactNode) => {
        if (modals.has(modal)) return;
        modals.add(modal);

        setModals(new Set(modals));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const hide = useCallback((modal: React.ReactNode) => {
        modals.delete(modal);
        setModals(new Set(modals));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const context = useMemo(() => ({ show, hide }), []);

    return (<ModalContext.Provider value={context}>
        {children}
        <>{Array.from(modals)}</>
    </ModalContext.Provider>);
};
