export enum SizeThumbnail {
    M = 'm',
    L = 'l'
}

export enum FigcaptionPosition {
    TOP = 'top',
    BOTTOM = 'bottom'
}

export enum FigcaptionPositionColor {
    WHITE = 'white',
    BLACK = 'black'
}

export enum DirectionArrow {
    LEFT = 'left',
    RIGHT = 'right'
}

export const OFFSET_THUMBNAIL = 8;

export const HEIGHT_THUMBNAIL = {
    [SizeThumbnail.M]: 45,
    [SizeThumbnail.L]: 75
};

export const WIDTH_THUMBNAIL = {
    [SizeThumbnail.M]: 60,
    [SizeThumbnail.L]: 100
};

export const PADDED_PREVIEW = {
    [SizeThumbnail.M]: 8,
    [SizeThumbnail.L]: 16
};
