import React, { useCallback, useEffect, useState } from 'react';
import throttle from 'lodash/throttle';

import { Button, Typography } from '@vtblife/uikit';
import Icon from '@vtblife/uikit-icons';

import classname from '@search/classname/src';

import type { ICustomerFeedbackOpenButton } from '../../../../../common/components/CustomerFeedback/components/OpenButton';

import './styles.css';

const cn = classname.bind(null, 'CustomerFeedbackOpenButtonBlueBanner');

export const OpenButtonBlueBanner = ({
    handleCloseOpenBtn,
    handleOpen,
}: Pick<ICustomerFeedbackOpenButton, 'handleCloseOpenBtn' | 'handleOpen'>) => {
    const [ isVisible, setVisible ] = useState<boolean>(false);
    const [ hasTimerWorked, setTimerWorked ] = useState<boolean>(false);

    const footerElement = document.getElementById('footer');
    const viewportHeight = window.innerHeight;

    const handleScroll = useCallback(() => {
        if (! hasTimerWorked) {
            return;
        }

        const footerTop = footerElement?.getBoundingClientRect()?.top ?? 0;

        setVisible(footerTop > viewportHeight);
    }, [ hasTimerWorked ]);

    useEffect(() => {
        const throttledHandleScroll = throttle(handleScroll, 100);

        window.addEventListener('scroll', throttledHandleScroll);

        return () => window.removeEventListener('scroll', throttledHandleScroll);
    }, [ handleScroll ]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setVisible(true);
            setTimerWorked(true);
        }, 30000);

        return () => clearTimeout(timeoutId);
    }, []);

    return (
        <div className={cn(null, { isVisible })}>
            <div className={cn('left')}>
                <div
                    className={cn('close')}
                    onClick={handleCloseOpenBtn}
                    data-test='customer-feedback-close-btn'
                >
                    <Icon
                        size='s'
                        name='times'
                    />
                </div>
                <Typography
                    variant='secondary'
                    color='primary-alt-bg'
                    bold
                >
                    Отзыв о сайте
                </Typography>
            </div>
            <Button
                size='xs'
                onClick={handleOpen}
                variant='secondary-alt-bg'
                dataTest='customer-feedback-open-btn'
            >
                Оценить
            </Button>
        </div>
    );
};
