import React, { useCallback } from 'react';

import { Typography } from '@vtblife/uikit';

import classname from '@search/classname/src';

import { ClosedSalesFilter } from '../../../../models/Filter';
import { RadioOption, RadioSelect } from '../../common/RadioSelect';

import './MobileClosedSalesFilterView.css';

interface IProps {
    filter: ClosedSalesFilter;
    handleChange: (filter: ClosedSalesFilter) => void;
}

const cn = classname.bind(null, 'MobileClosedSalesFilterView');

const TRUE = 'TRUE';

const selectOptions: RadioOption[] = [ {
    value: TRUE,
    label: 'С закрытыми продажами',
    className: 'col-xs-12 filter-item-gap'
} ];

export const MobileClosedSalesFilterView: React.FC<IProps> = ({
    filter,
    handleChange
}) => {
    const onChange = useCallback(value => {
        handleChange(new ClosedSalesFilter(Boolean(value)));
    }, [ handleChange ]);

    return (
        <div className={cn()}>
            <RadioSelect
                allowNull
                name='closedSales'
                className='container container__clamp'
                options={selectOptions}
                value={filter.includingClosedSales ? TRUE : null}
                handleChange={onChange}
            />
            <Typography
                variant='tiny-alone'
                color='minor'
                className={cn('explanation')}
            >
                Новостройки, в которых все квартиры от застройщика проданы или продажи временно приостановлены
            </Typography>
        </div>
    );
};
