import React, { HTMLAttributes } from 'react';

import classname from '@search/classname/src';

import './styles.css';

export interface IBadgeBoxProps extends HTMLAttributes<HTMLDivElement> {
    position?: 'top-left' | 'top-right' | 'top-right-same' | 'bottom-left' | 'bottom-right' | 'built-in';
    view?: 'desktop' | 'mobile';
}

const cn = classname.bind(null, 'BadgeBox');

export const BadgeBox = ({
    position,
    view = 'desktop',
    className,
    children,
    ...props
}: IBadgeBoxProps) => (
    <div
        {...props}
        className={cn(null, { position, view }, className)}
    >
        {children}
    </div>
);
