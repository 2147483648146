/* eslint-disable max-len */
import React, { useMemo } from 'react';
import classname from '@search/classname/src';
import { Modal, ModalSize, useModal } from '@search/vtbeco-ui/src/components/Modal';
import { BorderRadius } from '@search/vtbeco-ui/src/components/Paper';
import { RegionIdEnum } from '@search/filter-enums/enums/Region';
import { GeoFilter } from '../../../../../models/Filter';
import { GeoBaseStore } from '../../../../../../../domain/geo/GeoBase';
import { GeoTagList } from '../GeoTagList';
import { FilteredItem } from '../MobileGeoFilterView';
import { QueryDisplayValues } from '../../../../../../common/hooks/useQueryDisplayValues';
import { groupDistricts } from '../../../../../helpers/districtHelper';
import { FullGeoTagList } from './FullGeoTagList';

import './PreviewGeoTagList.css';

const cn = classname.bind(null, 'PreviewGeoTagList');

function notUndefined<T>(x: T | undefined): x is T {
    return x !== undefined;
}

interface IPreviewGeoTagListProps {
    filter: GeoFilter;
    regionId: RegionIdEnum;
    store: GeoBaseStore;
    handleRemove: (item: FilteredItem) => void;
    queryDisplayValues?: QueryDisplayValues;
}

export const PreviewGeoTagList: React.FC<IPreviewGeoTagListProps> = ({
    filter,
    store,
    handleRemove,
    queryDisplayValues,
    regionId
}) => {
    const { on: fullGeoTagOn, off: fullGeoTagOff, visible: isFullGeoTagVisible } = useModal();

    const geoObjects = useMemo(() => {
        const districtIdsSet = new Set(filter.districtIds);

        const groupedDistricts = groupDistricts({
            regionId,
            districts: store.districts.items
        }).filter(group => group.items.every(item => districtIdsSet.has(Number(item.id))));

        const nextGeoObjects: FilteredItem[] = groupedDistricts
            .map(({ items, title }) => {
                const districtIds: number[] = [];

                items.forEach(({ id }) => {
                    const numberedId = Number(id);

                    districtIds.push(numberedId);
                    districtIdsSet.delete(numberedId);
                });

                return {
                    title,
                    districtIds,
                    id: `group-${title}`,
                    kind: 'districts-group'
                };
            });

        const ids = [
            ...filter.metroIds,
            ...Array.from(districtIdsSet),
            ...filter.addressIds
        ];

        nextGeoObjects.push(
            ...ids.map(id => {
                const d = store.districts.get(id);

                if (d) {
                    return d;
                }

                const m = store.stations.get(id);

                if (m) {
                    return m;
                }

                const a = queryDisplayValues?.addresses && queryDisplayValues?.addresses[id];

                if (a) {
                    return a;
                }

                return;
            }).filter(notUndefined)
        );

        return nextGeoObjects;
    }, [
        regionId, store,
        filter.districtIds, filter.addressIds, filter.metroIds,
        queryDisplayValues
    ]);

    let slicedItems = geoObjects.slice(0, 3);
    let items = geoObjects;

    if (filter.newBuildingIds.length) {
        const newBuildingItems = filter.newBuildingIds.map(id =>
            queryDisplayValues?.newBuildings && queryDisplayValues?.newBuildings[id]
        ).filter(notUndefined);

        if (newBuildingItems.length) {
            slicedItems = [
                ...newBuildingItems,
                ...slicedItems
            ];

            items = [
                ...newBuildingItems,
                ...items
            ];
        }
    }

    return (
        <div className={cn(null, { withoutMargin: Boolean(slicedItems.length <= 0) })}>
            <GeoTagList
                items={slicedItems}
                handleRemove={handleRemove}
            />
            {slicedItems.length > 0 && <div
                className={`${cn('flex')} ${cn('item')} container`}
                onClick={fullGeoTagOn}
                data-test='filter-geo-show-all'
            >
                <div className={`${cn('flex')} ${cn('addresses')} col-xs-11`}>
                    Все выбранные адреса
                </div>
                <div
                    className={cn('icon', undefined, 'col-xs-1')}
                >
                    {items.length}
                </div>
            </div>
            }
            {isFullGeoTagVisible && (
                <Modal
                    size={ModalSize.FULL_L}
                    borderRadius={BorderRadius.none}
                    fullScreen
                >
                    <FullGeoTagList
                        items={items}
                        handleRemove={handleRemove}
                        fullGeoTagOff={fullGeoTagOff}
                    />
                </Modal>
            )}
        </div>
    );
};
