import React, { PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from '../Price/popper/usePopper';

export function SuggestPopup({
    hide,
    triggerElement,
    children
}: PropsWithChildren<Parameters<typeof usePopper>[0]>) {
    const popupElement = usePopper({ direction: 'down', triggerElement, hide });

    return createPortal(
        <div
            // zIndex from modals uikit -1
            style={{ width: triggerElement?.offsetWidth, zIndex: 1000 }}
            ref={popupElement}
        >
            {children}
        </div>,
        document.body
    );
}
