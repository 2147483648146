import { ApplicationIdEnum } from '@search/graphql-typings';
import { useMemo } from 'react';

export function useAppTypeById(appId: ApplicationIdEnum) {
    return useMemo(() => {
        const isClApp = appId === ApplicationIdEnum.ClassifiedDesktop || appId === ApplicationIdEnum.ClassifiedTouch;
        const isNbApp = appId === ApplicationIdEnum.NewBuildingsTouch || appId === ApplicationIdEnum.NewBuildingsDesktop;
        const isZbsApp = appId === ApplicationIdEnum.ZbsSearch;

        return { isClApp, isNbApp, isZbsApp };
    }, [ appId ]);
}
