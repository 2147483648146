import './MetroMark2.css';

import React from 'react';

import classname from '@search/classname/src';

const cn = classname.bind(null, 'MetroMark2');

export function MetroMark2Item({ color, children }: { color: string; children?: React.ReactNode }) {
    return (
        <span
            style={{ backgroundColor: color }}
            className={cn('line')}
        >{children ?? colorsToNameMapOut[color]}</span>
    );
}

export function MetroMark2({ colors }: {
    colors: readonly string[];
}) {
    return (
        <span className={cn()}>
            {
                colors.slice(0).reverse().map((color, index) => (
                    <MetroMark2Item
                        key={index}
                        color={color}
                    />
                ))
            }
        </span>
    );
}

const colorsToNameMapOut: Record<string, string> = {
    '#EA7D27': '6',
    '#4baf4f': '1',
    '#bac8e8': '12',
    '#943f90': '5',
    '#0572b9': '2',
    '#FBCD24': '8',
    '#d68ab1': '15',
    '#d14a43': '14',
    '#BED12E': '10',
    '#DD201C': '1',
    '#F7A600': 'D1',
    '#925233': '5',
    '#41BCEF': '4',
    '#adacac': '9',
    '#E94282': 'D2',
    '#89cdcf': '11',
    '#e42518': '1',
    '#986000': '6',
    '#f07e23': '4',
    '#f03d2f': '1',
    '#23a12c': '2',
    '#0042a5': '2'
};
