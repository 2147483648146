import './styles.css';

import React, { useCallback, useMemo, Suspense } from 'react';
import Icon from '@vtblife/uikit-icons';
import { Tabs as DSTabs, Checkbox, Button } from '@vtblife/uikit';

import classname from '@search/classname/src';
import { useKeyPress } from '@search/hooks/src/useKeyPress';
import { Modal, ModalClosedBy } from '@search/vtbeco-ui/src/components/Modal';
import MetroMapSelector from '@search/metro/src/view/components/MetroMapSelector';
import { formatNumber } from '@search/helpers/src/formatNumber';
import { declensionByNumber } from '@search/helpers/src/declensionByNumber';
import { RegionIdEnum, AdditionalRegionWithMetroIdEnum } from '@search/filter-enums/enums/Region';

import { MobileTransportLimitFilterView2 } from '../../../mobile/transportLimit/MobileTransportLimitFilterView2';
import { FilterTagListItem2 } from '../FilterTagList2/FilterTagList2';
import type { GeoFilter, TransportLimitFilter } from '../../../../../models/Filter';
import { getDistrictCount, groupDistricts } from '../../../../../helpers/districtHelper';
import type { DistrictStore } from '../../../../../../../domain/geo/District';
import type { MetroStore } from '../../../../../../../domain/geo/MetroStation';
import { ESCAPE } from '../../../../../../constants/keycode';
import { DistrictControl } from '../../../../../../common/components/DistrictControl';
import GeoErrorBoundary from './GeoErrorBoundary';

import MetroSuggest from './MetroSuggest';
import { DistrictsTab } from './DistricsTab';

const metroMapMskSvg = React.lazy(() => import('./LazySvgMap/LazySvgMapMsk'));
const metroMapSpbSvg = React.lazy(() => import('./LazySvgMap/LazySvgMapSpb'));
const metroMapKznSvg = React.lazy(() => import('./LazySvgMap/LazySvgMapKzn'));
const metroMapEkbSvg = React.lazy(() => import('./LazySvgMap/LazySvgMapEkb'));
const metroMapNskSvg = React.lazy(() => import('./LazySvgMap/LazySvgMapNsk'));
const metroMapNnvSvg = React.lazy(() => import('./LazySvgMap/LazySvgMapNnv'));
const metroMapSmrSvg = React.lazy(() => import('./LazySvgMap/LazySvgMapSmr'));

const cn = classname.bind(null, 'GeoSelector');

// TODO: Добавить соответствующий признак в данные (RS-1965)
const STATIONS_IN_RING_MSK = [
    156, 215, 214, 213, 179, 79, 77, 76, 75, 74, 73, 154, 282, 281,
    280, 279, 278, 314, 313, 312, 152, 180, 178, 177, 159, 31, 28,
    25, 24, 22, 21, 19, 18, 151, 14567, 126, 14568, 124, 123, 129, 161, 162, 254, 128
];

export enum TabsEnum {
    metro,
    district
}

interface IProps {
    count: number;
    regionId: RegionIdEnum | AdditionalRegionWithMetroIdEnum;
    activeTab: TabsEnum;
    metro: MetroStore;
    districts: DistrictStore;
    geoFilter: GeoFilter;
    onTabChange: (tab: TabsEnum) => void;
    onClose: () => void;
    onSubmit: () => void;
    onChange: (data: any) => void;
    transportLimitFilter: TransportLimitFilter;
    onChangeTransportLimitChange: (tranpostLimitFilter: TransportLimitFilter) => void;
}

export const GeoSelector: React.FC<IProps> = React.memo(({
    count,
    regionId,
    activeTab,
    metro,
    districts,
    onTabChange,
    onClose,
    onChange,
    onSubmit,
    geoFilter,
    transportLimitFilter,
    onChangeTransportLimitChange
}) => {
    const allowedTabs = [];
    const isMetroTab = activeTab === TabsEnum.metro;

    const isKeyPressed = useKeyPress(ESCAPE);

    if (isKeyPressed) {
        onClose();
    }

    if (! districts.isEmpty()) {
        allowedTabs.push(TabsEnum.district);
    }

    const value = useMemo(() => ({
        metroIds: new Set(geoFilter.metroIds),
        districtIds: new Set(geoFilter.districtIds)
    }), [ geoFilter ]);

    const districtGroups = useMemo(() => groupDistricts({
        districts: districts.items,
        regionId: regionId as RegionIdEnum
    }), [ districts, regionId ]);

    const districtCount = useMemo(
        () => getDistrictCount([ ...value.districtIds ], districtGroups),
        [ value.districtIds, districtGroups ]
    );

    const handleModalRequestClose = useCallback((closeBy: ModalClosedBy) => {
        if (closeBy !== ModalClosedBy.ClickOutside) {
            onClose();
        }
    }, [ onClose ]);

    const handleSelectedRemove = useCallback(() => {
        onChange({
            [isMetroTab ? 'metroIds' : 'districtIds']: {
                diff: {
                    deselected: new Set(isMetroTab ? value.metroIds : value.districtIds)
                }
            }
        });
    }, [ onChange, isMetroTab, value ]);

    const handleMetroChange = useCallback(data => {
        onChange({
            metroIds: data
        });
    }, [ onChange ]);

    const handleDistrictsChange = useCallback(data => {
        onChange({
            districtIds: data
        });
    }, [ onChange ]);

    const [ metroMapSvg, isMetroMapSvgVertical ] = useMemo<
        [] |
        [ typeof metroMapMskSvg ] |
        [ typeof metroMapMskSvg, boolean ]
    >(() => {
        switch (regionId) {
            case RegionIdEnum.MSK:
            case RegionIdEnum.MSK_OBL:
            case RegionIdEnum.MSK_AND_MSK_OBL:
                return [ metroMapMskSvg ];

            case RegionIdEnum.SPB:
            case RegionIdEnum.SPB_AND_LEN_OBL:
            case RegionIdEnum.LEN_OBL:
                return [ metroMapSpbSvg ];

            case AdditionalRegionWithMetroIdEnum.KZN:
                return [ metroMapKznSvg, true ];

            case AdditionalRegionWithMetroIdEnum.EKB:
                return [ metroMapEkbSvg, true ];

            case AdditionalRegionWithMetroIdEnum.NSK:
                return [ metroMapNskSvg, true ];

            case AdditionalRegionWithMetroIdEnum.NNV:
                return [ metroMapNnvSvg, true ];

            case AdditionalRegionWithMetroIdEnum.SMR:
                return [ metroMapSmrSvg, true ];

            default:
                return [];
        }
    }, [ regionId ]);

    const isMskOrObl = useMemo(() => {
        return (
            regionId === RegionIdEnum.MSK_AND_MSK_OBL ||
            regionId === RegionIdEnum.MSK_OBL ||
            regionId === RegionIdEnum.MSK
        );
    }, [ regionId ]);

    if (metroMapSvg) {
        allowedTabs.push(TabsEnum.metro);
    }

    const isMetroInRingSelected = STATIONS_IN_RING_MSK.every(id => value.metroIds.has(id));

    const handleMetroInRingCheck = useCallback(() => {
        onChange({
            metroIds: {
                diff: {
                    [isMetroInRingSelected ? 'deselected' : 'selected']: new Set(STATIONS_IN_RING_MSK)
                }
            }
        });
    }, [ onChange, isMetroInRingSelected ]);

    let selectedText;

    if (isMetroTab) {
        if (value.metroIds.size) {
            selectedText = `${value.metroIds.size} ${declensionByNumber(value.metroIds.size, [
                'станция', 'станции', 'станций'
            ])}`;
        }
    } else {
        if (districtCount) {
            selectedText = `${districtCount} ${declensionByNumber(districtCount, [
                'район', 'района', 'районов'
            ])}`;
        }
    }

    const tabs = useMemo<[TabsEnum, string, boolean][]>(() => ([
        [ TabsEnum.metro, 'Метро', value.metroIds.size > 0 ],
        [ TabsEnum.district, 'Район', value.districtIds.size > 0 ]
    ]), [ value.metroIds.size, value.districtIds.size ]);

    const MetroSvgComponent = metroMapSvg;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const showDistrictControl = regionId === RegionIdEnum.SPB || regionId === RegionIdEnum.MSK;

    return (
        <Modal
            dataTest='filter-geo-modal'
            borderRadius='none'
            onRequestClose={handleModalRequestClose}
            contentClassName={cn('modal')}
        >
            <div className={cn()}>
                <div className={cn('content', { displayedDistricts: ! isMetroTab })}>
                    <div className={cn('metro-map-selector')} style={{ display: isMetroTab ? undefined : 'none' }}>
                        {
                            MetroSvgComponent ? (
                                <GeoErrorBoundary>
                                    <Suspense fallback=''>
                                        <MetroSvgComponent>
                                            {
                                                svg =>
                                                    <MetroMapSelector
                                                        svg={svg}
                                                        vertical={isMetroMapSvgVertical}
                                                        selected={value.metroIds}
                                                        onChange={handleMetroChange}
                                                    />
                                            }
                                        </MetroSvgComponent>
                                    </Suspense>
                                </GeoErrorBoundary>
                            ) : null
                        }
                    </div>
                    {isMetroTab ? null : (
                        <DistrictsTab
                            groups={districtGroups}
                            selected={value.districtIds}
                            onChange={handleDistrictsChange}
                        />
                    )}
                </div>

                <div className={cn('filters')}>
                    <div className={cn('tabs-container')}>
                        <Tabs
                            tabs={tabs}
                            activeTab={activeTab}
                            onChange={onTabChange}
                            allowedTabs={allowedTabs}
                        />
                    </div>

                    {showDistrictControl || isMetroTab ? (
                        <div className={cn('grid-row-standart')}>
                            {isMetroTab ? (<>
                                <MetroSuggest
                                    metro={metro}
                                    selected={value.metroIds}
                                    onChange={handleMetroChange}
                                    className={cn('metroSuggest')}
                                />
                                {isMskOrObl ? (
                                    <Checkbox
                                        value={isMetroInRingSelected}
                                        onChange={handleMetroInRingCheck}
                                    >
                                        Станции внутри кольца
                                    </Checkbox>
                                ) : null}
                                <MobileTransportLimitFilterView2
                                    filter={transportLimitFilter}
                                    isTitle
                                    onChange={onChangeTransportLimitChange}
                                    containerClass={cn('grid-transportLimit')}
                                    desktopTitleGap
                                />
                            </>) : null}
                            {showDistrictControl && ! isMetroTab ? (
                                <DistrictControl
                                    regionId={regionId}
                                    onChange={handleDistrictsChange}
                                    selectedDistricts={value.districtIds}
                                />
                            ) : null}
                        </div>
                    ) : null}
                    <div className={cn('bottom-block')}>
                        {selectedText ? (
                            <FilterTagListItem2
                                view='ellipse'
                                id='geo-modal-suggest'
                                onDelete={handleSelectedRemove}
                            >
                                {selectedText}
                            </FilterTagListItem2>
                        ) : null}
                        <Button
                            fullWidth
                            onClick={onSubmit}
                        >
                            {`Показать${count ? ' ' + formatNumber(count) : ''}`}
                        </Button>
                    </div>
                </div>
                <div className={cn('close')} onClick={onClose}>
                    <Icon name='times' />
                </div>
            </div>
        </Modal>
    );
});

interface ITabsProps {
    tabs: [TabsEnum, string, boolean][];
    activeTab: TabsEnum;
    onChange: (activeTab: TabsEnum) => void;
    allowedTabs: TabsEnum[];
}

const Tabs: React.FC<ITabsProps> = React.memo(({ activeTab, onChange, allowedTabs, tabs }) => {
    return (
        <DSTabs variant='text' size='s' onChange={onChange} f>
            {
                tabs.filter(([ type ]) => allowedTabs.includes(type as TabsEnum))
                    .map((item: [TabsEnum, string, boolean]) => {
                        const isActive = item[0] === activeTab;

                        return (
                            <DSTabs.Option
                                active={item[0] === activeTab}
                                key={item[0]}
                                data-test={`filter-geo-selector-tab-${item[0]}`}
                                value={item[0]}
                            >
                                {item[1]}
                                {item[2] && ! isActive ? (
                                    <span className={cn('tab-text-mark')} />
                                ) : null}
                            </DSTabs.Option>
                        );
                    })
            }
        </DSTabs>
    );
});
