import type { ExtraFeature, FeedbackOption, InfoFullnessDetail, UsabilityDetail } from './dict';

export interface IFeedbackFormState {
    usability: {
        value?: FeedbackOption;
        details: Array<UsabilityDetail>;
        customText: string;
    };
    infoFullness: {
        value?: FeedbackOption;
        details: Array<InfoFullnessDetail>;
        customText: string;
    };
    extraFeatures: Array<ExtraFeature>;
    searchSuccess?: FeedbackOption;
    comment: string;
    contact: string;
}

export const initialFormState: IFeedbackFormState = {
    usability: {
        value: undefined,
        details: [],
        customText: ''
    },
    infoFullness: {
        value: undefined,
        details: [],
        customText: ''
    },
    extraFeatures: [],
    searchSuccess: undefined,
    comment: '',
    contact: ''
};

export enum ACTIONS {
    USABILITY_UPDATE,
    USABILITY_DETAILS_UPDATE,
    USABILITY_DETAILS_RESET,
    USABILITY_CUSTOM_TEXT_UPDATE,
    INFO_FULLNESS_UPDATE,
    INFO_FULLNESS_DETAILS_UPDATE,
    INFO_FULLNESS_DETAILS_RESET,
    INFO_FULLNESS_CUSTOM_TEXT_UPDATE,
    EXTRA_FEATURES_UPDATE,
    EXTRA_FEATURES_RESET,
    SEARCH_SUCCESS_UPDATE,
    COMMENT_UPDATE,
    CONTACT_UPDATE,
    SET_INITIAL_FORM_DATA,
}

export type Action =
    | { type: ACTIONS.USABILITY_UPDATE; payload: FeedbackOption }
    | { type: ACTIONS.USABILITY_DETAILS_UPDATE; payload: { value: UsabilityDetail; isCheck: boolean }}
    | { type: ACTIONS.USABILITY_DETAILS_RESET }
    | { type: ACTIONS.USABILITY_CUSTOM_TEXT_UPDATE; payload: string}
    | { type: ACTIONS.INFO_FULLNESS_UPDATE; payload: FeedbackOption }
    | { type: ACTIONS.INFO_FULLNESS_DETAILS_UPDATE; payload: { value: InfoFullnessDetail; isCheck: boolean }}
    | { type: ACTIONS.INFO_FULLNESS_DETAILS_RESET }
    | { type: ACTIONS.INFO_FULLNESS_CUSTOM_TEXT_UPDATE; payload: string }
    | { type: ACTIONS.EXTRA_FEATURES_UPDATE; payload: { value: ExtraFeature; isCheck: boolean } }
    | { type: ACTIONS.EXTRA_FEATURES_RESET }
    | { type: ACTIONS.SEARCH_SUCCESS_UPDATE; payload: FeedbackOption }
    | { type: ACTIONS.COMMENT_UPDATE; payload: string }
    | { type: ACTIONS.CONTACT_UPDATE; payload: string }
    | { type: ACTIONS.SET_INITIAL_FORM_DATA }

export function reducer(state: IFeedbackFormState, action: Action): IFeedbackFormState {
    switch (action.type) {
        case ACTIONS.USABILITY_UPDATE:
            return {
                ...state,
                usability: {
                    ...initialFormState.usability,
                    value: action.payload
                }
            };

        case ACTIONS.USABILITY_DETAILS_UPDATE:
            return {
                ...state,
                usability: {
                    ...state.usability,
                    details: action.payload.isCheck ?
                        [ ...state.usability.details, action.payload.value ] :
                        state.usability.details.filter(item => item !== action.payload.value)
                }
            };

        case ACTIONS.USABILITY_DETAILS_RESET:
            return {
                ...state,
                usability: {
                    ...state.usability,
                    details: initialFormState.usability.details
                }
            };

        case ACTIONS.USABILITY_CUSTOM_TEXT_UPDATE:
            return {
                ...state,
                usability: {
                    ...state.usability,
                    customText: action.payload
                }
            };

        case ACTIONS.INFO_FULLNESS_UPDATE:
            return {
                ...state,
                infoFullness: {
                    ...initialFormState.infoFullness,
                    value: action.payload
                }
            };

        case ACTIONS.INFO_FULLNESS_DETAILS_UPDATE:
            return {
                ...state,
                infoFullness: {
                    ...state.infoFullness,
                    details: action.payload.isCheck ?
                        [ ...state.infoFullness.details, action.payload.value ] :
                        state.infoFullness.details.filter(item => item !== action.payload.value)
                }
            };

        case ACTIONS.INFO_FULLNESS_DETAILS_RESET:
            return {
                ...state,
                infoFullness: {
                    ...state.infoFullness,
                    details: initialFormState.infoFullness.details
                }
            };

        case ACTIONS.INFO_FULLNESS_CUSTOM_TEXT_UPDATE:
            return {
                ...state,
                infoFullness: {
                    ...state.infoFullness,
                    customText: action.payload
                }
            };

        case ACTIONS.EXTRA_FEATURES_UPDATE:
            return {
                ...state,
                extraFeatures: action.payload.isCheck ?
                    [ ...state.extraFeatures, action.payload.value ] :
                    state.extraFeatures.filter(item => item !== action.payload.value)
            };

        case ACTIONS.EXTRA_FEATURES_RESET:
            return {
                ...state,
                extraFeatures: initialFormState.extraFeatures
            };

        case ACTIONS.SEARCH_SUCCESS_UPDATE:
            return {
                ...state,
                searchSuccess: action.payload
            };

        case ACTIONS.COMMENT_UPDATE:
            return {
                ...state,
                comment: action.payload
            };

        case ACTIONS.CONTACT_UPDATE:
            return {
                ...state,
                contact: action.payload
            };

        case ACTIONS.SET_INITIAL_FORM_DATA:
            return initialFormState;

        default:
            return state;
    }
}
