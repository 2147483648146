import { DealType } from '@search/filter-enums/enums/DealType';
import { RealtyObjectType } from '@search/filter-enums/enums/RealtyObjectType';

export const searchDefaults = {
    dealType: DealType.SELL,
    categoryType: RealtyObjectType.FLAT,
    priceUnit: 'ALL',
    rooms: [],
    layoutImageOptions: [],
    roomsInOffer: [],
    renovation: [],
    addresses: [],
    metroIds: [],
    districtIds: [],
    newBuildingIds: [],
    pageNumber: 1,
    pageSize: 20 as number,
    // sort: undefined,
    newBuilding: [],
    bathroom: [],
    livingFacility: [],
    furniture: [],
    finishingType: [],
    livingTenantOptions: [],
    walls: [],
    parkings: [],
    author: [],
    houseCategory: [],
    bathroomLocation: [],
    heating: [],
    sellType: [],
    infrastructure: [],
    landStatus: [],
    gas: [],
    water: [],
    electricity: [],
    sewerage: [],
    windowsDirection: [],
    buildEpoch: []
} as const;
