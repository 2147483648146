import React, { useCallback } from 'react';
import { FinishingBuildingEnum, SelectFinishingFlatOption } from '@search/graphql-typings';

import { FinishingFlatFilter } from '../../../../models/Filter';
import { SlimMultiSelect } from '../../../../../common/components/SlimSelect/SlimMultiSelect';

interface IProps {
    filter: FinishingFlatFilter;
    onChange: (filter: FinishingFlatFilter) => void;
    options: SelectFinishingFlatOption[];
}

/**
 * Фильтр "Отделка" в разделе "Квартиры и корпуса" на карточке ЖК
 * */
export const DesktopFinishingFlatTypeFilterView: React.FC<IProps> = ({
    filter,
    onChange,
    options
}) => {
    const handleChange = useCallback((newValue: FinishingBuildingEnum[]) => onChange(
        new FinishingFlatFilter({
            finishingType: newValue
        })
    ), [ onChange ]);

    const { finishingType } = filter.value;

    return (
        <SlimMultiSelect<FinishingBuildingEnum>
            placeholder='Отделка'
            forcePlaceholder={finishingType.length === 0}
            size='s'
            options={options}
            value={finishingType}
            onChange={handleChange}
            dataTest='finishingFlat'
            adaptivePlacement={false}
            adaptiveHeight={false}
        />
    );
};
