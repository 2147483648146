import { IFilter, FilterId, Filters, MapBoundsFilter, InfrastructureFilter } from './Filter';

export class FilterCollection {
    constructor(filters?: Map<FilterId, IFilter>) {
        this._filters = filters || new Map();
    }
    reset() {
        const values = this._filters.values();

        for (const value of values) {
            if (! value) continue;
            if (value.id === Filters.region) continue;
            if (value.id === Filters.categoryType) continue;
            if (value.id === Filters.dealType) continue;
            if (value.isDefault()) continue;

            this._filters.set(value.id, value.create());
        }

        return this.rebuild();
    }
    rebuild() {
        return new FilterCollection(this._filters);
    }
    readonly _filters: Map<FilterId, IFilter>;
    update(filter: IFilter) {
        // Need to preserve filter update order - last updated filter is last in Array.from(this._filters.values())
        this._filters.delete(filter.id);
        this._filters.set(filter.id, filter);
        return this.rebuild();
    }
    getFilters() {
        return this._filters;
    }
    get<K extends IFilter>(id: FilterId) {
        return this._filters.get(id) as K;
    }
    remove(filter?: IFilter) {
        if (! filter) return this;

        this._filters.set(filter.id, filter.create());
        return this.rebuild();
    }
    getCountActiveFilters(ids?: Filters[]): number {
        const values = this._filters.values();

        let counter = 0;

        for (const value of values) {
            if (! value) continue;
            if (value.id === Filters.categoryType) continue;
            if (value.id === Filters.dealType) continue;
            if (value.id === Filters.region) continue;
            if (value.isDefault()) continue;
            if (ids?.includes(value.id)) continue;
            if (value.id === Filters.infrastructure) {
                counter += (value as InfrastructureFilter).infrastructure.length;
                if ((value as InfrastructureFilter).hasLift) counter++;
                continue;
            }
            counter++;
        }

        return counter;
    }
}
