import React, {
    Fragment,
    useCallback,
    MouseEvent,
    RefObject,
    ReactNode,
    HTMLAttributes,
    DetailedHTMLFactory
} from 'react';
import classname from '@search/classname/src';
import useControl from '@search/hooks/src/useControl';

import {
    Tone,
    Size,
    View,
    Width,
    ToneEnum,
    SizeEnum,
    ViewEnum,
    Height
} from '../../../types';

import './Button.css';
import './ButtonSize/ButtonSize.css';

export type ContainerElement =
    | HTMLButtonElement
    | HTMLLinkElement;

type ReactButtonElement =
    DetailedHTMLFactory<HTMLAttributes<ContainerElement>, ContainerElement>;

// @ts-ignore
export interface IButtonProps extends HTMLAttributes<ContainerElement> {
    /**
     * Тип кнопки.
     * @default 'button'
     */
    type?: 'submit' | 'link' | 'button' | 'rest';

    /**
     *  Кнопка отключена | включена
     */
    disabled?: boolean;

    /**
     * Кнопка в фокусе
     */
    focused?: boolean;

    /**
     * Сотсояние натажтия
     */
    pressed?: boolean;

    /**
     * Зажатость кнопки (используется в CheckboxGroup)
     */
    checked?: boolean;

    /**
     * Ширина кнопки
     */
    width?: Width;

    height?: Height;

    /**
     * Размер кнопки
     */
    size?: Size;

    /**
     * Заглавная первая буква
     */
    capitalize?: boolean;

    /**
     * Вид кнопки
     */
    view?: View | 'filter';

    /**
     * Тон кнопки (цвет)
     */
    tone?: Tone;

    /**
     * Дополнительный контент после `children`.
     */
    contentAfter?: ReactNode;

    /**
     * Дополнительный контент перед `children`.
     */
    contentBefore?: ReactNode;

    /**
     *  Иконка слева.
     */
    iconLeft?: ReactNode;

    /**
     *  Иконка справа.
     */
    iconRight?: ReactNode;

    /**
     * Ссылка на рутовый DOM элемент компонента.
     */
    innerRef?: RefObject<ContainerElement>;

    square?: boolean;

    /**
     * Предотвращаем событие по умолчанию
     */
    prevent?: boolean;

    onClick?: (e: MouseEvent<ContainerElement>, state?: object) => void;

    href?: string;

    target?: string;

    name?: string;
}

const cnButton = classname.bind(null, 'Button');

const Button: React.FunctionComponent<IButtonProps> = ({
    type = 'button',
    width,
    height,
    checked,
    className,
    onBlur,
    onFocus,
    onMouseDown,
    onMouseUp,
    innerRef,
    children = false,
    pressed = false,
    focused = false,
    disabled = false,
    capitalize = false,
    iconLeft,
    iconRight,
    contentBefore,
    contentAfter,
    size = SizeEnum.S,
    tone = ToneEnum.SECONDARY,
    view = ViewEnum.PRIMARY,
    square = false,
    prevent,
    ...props
}) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const TagName = (type === 'link' ? 'a' : 'button' as any) as ReactButtonElement;
    const [ buttonState, controlHandlers ] = useControl({ disabled, focused, pressed }, {
        onBlur,
        onFocus,
        onMouseUp,
        onMouseDown
    }, { disabled, focused });
    const handleClick = useCallback((event: React.MouseEvent<ContainerElement>) => {
        if (! disabled) {
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            if (prevent) {
                event.preventDefault();
            }

            // @ts-ignore
            // eslint-disable-next-line
            props.onClick && props.onClick(event, { ...buttonState, ...props });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ props.onClick, disabled ]);

    return (
        // @ts-ignore
        <TagName
            {...props}
            // @ts-ignore
            type={type}
            ref={innerRef}
            role={props.role}
            onClick={handleClick}
            disabled={buttonState.disabled}
            onBlur={controlHandlers.handleBlur}
            onFocus={controlHandlers.handleFocus}
            onMouseUp={controlHandlers.handleMouseUp}
            onMouseDown={controlHandlers.handleMouseDown}
            onMouseLeave={controlHandlers.handleMouseLeave}
            onMouseEnter={controlHandlers.handleMouseEnter}
            className={cnButton(null, { ...buttonState, size, view, tone, width, height, checked, square }, className)}>
            <Fragment>
                {contentBefore}
                {iconLeft}
                {
                    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                    children ?
                        <span className={cnButton('text', { capitalize })}>
                            {children}
                        </span> : null
                }
                {iconRight}
                {contentAfter}
            </Fragment>
        </TagName>
    );
};

export default Button;
