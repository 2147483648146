import React, { useCallback } from 'react';

import { Position } from '@search/filter-enums/enums';
import { PositionFilter } from '../../../../models/Filter';
import MobileFilter from '../../../filter/MobileFilter';
import { MultiSelect, MultiSelectOption } from '../../common/MultiSelect';

const selectOptions: MultiSelectOption[] = [ {
    value: Position.STANDALONE,
    label: 'Одиночное здание',
    className: 'col-xs-12 filter-item-gap'
} ];

interface IProps {
    filter: PositionFilter;
    handleChange: (filter: PositionFilter) => void;
}

export const MobilePositionFilterView: React.FC<IProps> = ({
    filter,
    handleChange
}) => {
    const onChange = useCallback(value => {
        handleChange(new PositionFilter(value));
    }, [ handleChange ]);

    return (
        <MobileFilter
            filterTitle='Размер ЖК'
        >
            <MultiSelect
                name='position'
                className='container container__clamp'
                options={selectOptions}
                value={filter.position}
                handleChange={onChange}
            />
        </MobileFilter>
    );
};
