import React from 'react';

export const Source = {
    UNKNOWN_SOURCE: 0,
    WEB_LK: 1,
    MOBILE_APP: 2,
    BANKI_RU: 3,
    COMFORT_SEARCH_M2: 4,
    SRAVNI_RU: 5,
    WEB_LANDING: 6,
    NOVOSTROIKY: 7,
    // zbs is not defined
    ZBS: 8
};

export type SourceValue = typeof Source[keyof typeof Source];

const MortgageAppSourceContext = React.createContext<SourceValue>(Source.UNKNOWN_SOURCE);

export function useMortgageAppSource() {
    return React.useContext(MortgageAppSourceContext);
}

export const MortgageAppSourceProvider: React.FC<{ source: SourceValue }> = ({ children, source }) => {
    return <MortgageAppSourceContext.Provider value={source}>{children}</MortgageAppSourceContext.Provider>;
};
