export const QUESTION = {
    usability: 'Вам было удобно пользоваться поиском недвижимости?',
    infoFullness: 'Информация о жилых комплексах была полной?',
    extraFeatures: 'Что могло бы помочь вам в выборе квартиры?',
    searchSuccess: 'Вы нашли подходящие варианты квартир?',
    comment: 'Расскажите подробнее о своём опыте',
    contact: 'Если вы оставите контактные данные, мы поможем решить возникшие проблемы',
};

export enum FeedbackOption {
    YES = 'YES',
    NO = 'NO'
}

export const USABILITY_OPTIONS: Record<FeedbackOption, string> = {
    [FeedbackOption.YES]: 'Да, всё хорошо',
    [FeedbackOption.NO]: 'Нет, возникли сложности',
};

export const INFO_FULLNESS_OPTIONS: Record<FeedbackOption, string> = {
    [FeedbackOption.YES]: 'Да, данных достаточно',
    [FeedbackOption.NO]: 'Хотелось бы увидеть больше',
};

export const SEARCH_SUCCESS_OPTIONS: Record<FeedbackOption, string> = {
    [FeedbackOption.YES]: 'Да, всё получилось',
    [FeedbackOption.NO]: 'Пока в поиске',
};

export enum UsabilityDetail {
    LACK_FILTER = 'LACK_FILTER',
    DIFFICULTLY = 'DIFFICULTLY',
    FEW_OFFERS = 'FEW_OFFERS',
    INACCURATE_SEARCH = 'INACCURATE_SEARCH',
    SLOW_LOADING = 'SLOW_LOADING',
    OTHER = 'OTHER'
}

export enum InfoFullnessDetail {
    STREET_PANORAMA = 'STREET_PANORAMA',
    AREA_GENERAL_PLAN = 'AREA_GENERAL_PLAN',
    PARKING_SCHEME = 'PARKING_SCHEME',
    MORE_PHOTO = 'MORE_PHOTO',
    PROJECT_BOOKLETS = 'PROJECT_BOOKLETS',
    OTHER = 'OTHER'
}

export enum ExtraFeature {
    WINDOW_VIEW = 'WINDOW_VIEW',
    FLOOR_PLAN = 'FLOOR_PLAN',
    FINISHING_TYPE = 'FINISHING_TYPE',
    REPAIR_COST = 'REPAIR_COST',
    VIRTUAL_TOURS = 'VIRTUAL_TOURS',
    FURNITURE_PLAN = 'FURNITURE_PLAN'
}

export const USABILITY_DETAILS_OPTIONS: Record<UsabilityDetail, string> = {
    [UsabilityDetail.LACK_FILTER]: 'Нет нужных фильтров',
    [UsabilityDetail.DIFFICULTLY]: 'Сложно разобраться',
    [UsabilityDetail.FEW_OFFERS]: 'Мало предложений',
    [UsabilityDetail.INACCURATE_SEARCH]: 'Неточные результаты поиска',
    [UsabilityDetail.SLOW_LOADING]: 'Медленная загрузка',
    [UsabilityDetail.OTHER]: 'Другое'
};

export const INFO_FULLNESS_DETAILS_OPTIONS: Record<InfoFullnessDetail, string> = {
    [InfoFullnessDetail.STREET_PANORAMA]: 'Панораму улицы',
    [InfoFullnessDetail.AREA_GENERAL_PLAN]: 'Генплан района',
    [InfoFullnessDetail.PARKING_SCHEME]: 'Схему паркинга',
    [InfoFullnessDetail.MORE_PHOTO]: 'Больше фотографий и видео',
    [InfoFullnessDetail.PROJECT_BOOKLETS]: 'Буклеты о проектах',
    [InfoFullnessDetail.OTHER]: 'Другое'
};

export const EXTRA_FEATURES_OPTIONS: Record<ExtraFeature, string> = {
    [ExtraFeature.WINDOW_VIEW]: 'Вид из окна',
    [ExtraFeature.FLOOR_PLAN]: 'Поэтажный план',
    [ExtraFeature.FINISHING_TYPE]: 'Варианты отделки',
    [ExtraFeature.REPAIR_COST]: 'Стоимость ремонта',
    [ExtraFeature.VIRTUAL_TOURS]: 'Виртуальные туры',
    [ExtraFeature.FURNITURE_PLAN]: 'План с мебелью'
};

export const ERROR_MESSAGE = 'Что-то пошло не так, попробуйте позже.';
