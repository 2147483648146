/* eslint-disable camelcase */
import { DeveloperWithPromoName } from './types';

const DeveloperToRegionMap = {
    samolet: 'ipoteka',
    mortgage: 'ipoteka_region'
} as const;

type DeveloperWithPromoNameOrMoprtgage = DeveloperWithPromoName | 'mortgage';
type SpecProjectCityKeys = typeof DeveloperToRegionMap[keyof typeof DeveloperToRegionMap]
type DeveloperPromoEvent = {
    event: 'nb_evt_banner';
    theme: `${SpecProjectCityKeys}_special_project`;
    section: 'banner_right' | 'banner_left' | 'promo_block' | 'spec_snippet' | 'slider' | 'JK_list' | 'phone' | 'JK' | 'developer' | 'logo';
    action: 'click' | 'view' | 'open';
    pageType: 'card' | 'serp' | 'landing' | 'placeholder' | 'main';
};

export type DeveloperPromoEventResult = Omit<DeveloperPromoEvent, 'pageType'> & { page_type: DeveloperPromoEvent['pageType'] }

export const getDeveloperBannerGtmData = (
    developerWithPromoName: DeveloperWithPromoNameOrMoprtgage | undefined | string,
    data: Pick<DeveloperPromoEvent, 'section' | 'action' | 'pageType'>
): DeveloperPromoEventResult | undefined => {
    const { pageType: page_type, ...rest } = data;
    const commonFields = { ...rest, page_type, event: 'nb_evt_banner' as const };

    switch (developerWithPromoName) {
        case 'samolet':
            if (page_type === 'card') {
                return {
                    theme: `${DeveloperToRegionMap[developerWithPromoName]}_special_project`,
                    ...commonFields
                };
            }
            break;

        default: {
            const keySpecProject = DeveloperToRegionMap[developerWithPromoName as unknown as DeveloperWithPromoNameOrMoprtgage];

            if (! keySpecProject) return;
            return {
                theme: `${keySpecProject}_special_project`,
                ...commonFields
            };
        }
    }
};
