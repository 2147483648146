import React from 'react';
import { Modal, Size } from '@vtblife/uikit/legacy';
import classname from '@search/classname/src';
import { useLkLinks } from '@search/classified-common/src/SaveSearch/useLkLinks';
import './styles.css';

interface IProps {
    isOpenLimitModal: boolean;
    onClose: () => void;
    isNewBuilding?: boolean;
}

const cn = classname.bind(null, 'FavoriteLimitModal');

const FavoriteLimitModal = (props: IProps) => {
    const { favoritesUrl } = useLkLinks();

    return (
        <Modal
            title='Упс...'
            isOpen={props.isOpenLimitModal}
            size={Size.Small}
            closeOnOverlayClick
            onClose={props.onClose}
            dataTest='favorites-limit-modal'
            className={cn()}
        >
            <p>{
                `В вашем избранном много вариантов.\u00A0Выберите самые лучшие из них и удалите лишние, чтобы добавить${
                    props.isNewBuilding ? ' этот ЖК' : ' это объявление'
                }.`
            }</p>
            <a
                href={favoritesUrl}
                target='_blank'
                className={cn('favorites-link')}
            >
                Перейти в избранное
            </a>
        </Modal>
    );
};

export default FavoriteLimitModal;
