import { RegionIdEnum } from '../../enums';

export default [
    {
        fullName: 'Москва и Московская область',
        label: 'Москва и область',
        value: RegionIdEnum.MSK_AND_MSK_OBL
    },
    {
        fullName: 'Москва',
        label: 'Москва',
        value: RegionIdEnum.MSK
    },
    {
        fullName: 'Московская область',
        label: 'Московская область',
        value: RegionIdEnum.MSK_OBL
    },
    {
        fullName: 'Санкт-Петербург и Ленинградская область',
        label: 'Санкт-Петербург и область',
        value: RegionIdEnum.SPB_AND_LEN_OBL
    },
    {
        fullName: 'Санкт-Петербург',
        label: 'Санкт-Петербург',
        value: RegionIdEnum.SPB
    },
    {
        fullName: 'Ленинградская область',
        label: 'Ленинградская область',
        value: RegionIdEnum.LEN_OBL
    }
];
