import React, { InputHTMLAttributes, Ref } from 'react';

import { cnInput } from '../Input';

import './InputControl.css';

export type IInputControlProps = InputHTMLAttributes<HTMLInputElement>;

const InputControl = React.forwardRef((props: IInputControlProps, ref: Ref<HTMLInputElement>) => (
    <input
        {...props}
        ref={ref}
        autoCorrect='off'
        autoComplete='off'
        className={cnInput('control')} />
));

export { InputControl };
