import { SeoMapper } from '@search/router/src';

export const mskOblRayon = {} as const;

export const mskRayon = {
    bogorodskoe: 8,
    sokolniki: 13,
    golovinskiy: 66,
    aeroport: 70,
    brateevo: 99,
    tsaritsino: 90,
    'moskvoreche-saburovo': 88,
    'fili-davidkovo': 119,
    mitino: 104,
    filyovskiy_park: 142,
    taganskiy: 157,
    meschanskiy: 160,
    kuzminki: 223,
    solntsevo: 243,
    ostankinskiy: 173,
    cheryomushki: 187,
    zyuzino: 289,
    beskudnikovskiy: 302,
    chertanovo_severnoe: 292,
    marina_roscha: 310,
    pechatniki: 320,
    khoroshyovskiy: 334,
    veshnyaki: 364,
    vostochnoe_izmailovo: 368,
    koptevo: 379,
    savyolki: 403,
    yuzhnoe_medvedkovo: 411,
    preobrazhenskoe: 10,
    basmannyi: 20,
    khovrino: 61,
    strogino: 111,
    severnoe_izmailovo: 139,
    presnenskiy: 148,
    severnoe_tushino: 202,
    tekstilschiki: 221,
    lefortovo: 257,
    'ochakovo-matveevskoe': 247,
    severnoe_medvedkovo: 165,
    babushkinskiy: 167,
    alekseevskiy: 175,
    akademicheskiy: 185,
    rostokino: 171,
    konkovo: 192,
    chertanovo_yuzhnoe: 297,
    severnoe_butovo: 300,
    yuzhnoe_butovo: 345,
    nekrasovka: 357,
    altufevskiy: 359,
    biryulyovo_vostochnoe: 362,
    vostochnyi: 369,
    kurkino: 383,
    losinoostrovskiy: 387,
    kotlovka: 380,
    lomonosovskiy: 386,
    molzhaninovskiy: 394,
    nizhegorodskiy: 397,
    matushkino: 389,
    'khoroshyovo-mnyovniki': 410,
    staroe_kryukovo: 409,
    metrogorodok: 2546,
    krasnoselskiy: 16,
    khamovniki: 29,
    zamoskvoreche: 78,
    prospekt_vernadskogo: 40,
    zyablikovo: 97,
    kuntsevo: 115,
    sokolinaya_gora: 132,
    yakimanka: 153,
    'pokrovskoe-streshnevo': 206,
    schukino: 209,
    ryazanskiy: 225,
    gagarinskiy: 183,
    savyolovskiy: 276,
    timiryazevskiy: 273,
    novokosino: 263,
    marino: 325,
    vnukovo: 365,
    biryulyovo_zapadnoe: 363,
    golyanovo: 370,
    dmitrovskiy: 371,
    donskoi: 373,
    marfino: 388,
    lianozovo: 385,
    mozhaiskiy: 393,
    perovo: 401,
    kryukovo: 382,
    sokol: 407,
    silino: 406,
    arbat: 26,
    tverskoi: 23,
    danilovskiy: 82,
    ramenki: 38,
    'troparyovo-nikulino': 42,
    levoberezhnyi: 63,
    voikovskiy: 68,
    'orekhovo-borisovo_yuzhnoe': 95,
    'orekhovo-borisovo_severnoe': 93,
    'nagatino-sadovniki': 85,
    dorogomilovo: 121,
    krilatskoe: 113,
    izmailovo: 135,
    yuzhnoe_tushino: 204,
    'vyhino-zhulebino': 227,
    yuzhnoportovyi: 219,
    'novo-peredelkino': 239,
    sviblovo: 169,
    obruchevskiy: 190,
    tyopliy_stan: 195,
    yasenevo: 198,
    bibirevo: 266,
    novogireevo: 260,
    otradnoe: 269,
    nagornyi: 286,
    chertanovo_tsentralnoe: 295,
    zapadnoe_degunino: 304,
    lyublino: 323,
    butyrskiy: 308,
    'kosino-ukhtomskiy': 353,
    begovoi: 360,
    vostochnoe_degunino: 366,
    ivanovskoe: 375,
    kapotnya: 376,
    severnyi: 405,
    yaroslavskiy: 412
} as const;

export const mskRayonMapper = new SeoMapper('mskRayonMapper', {
    ...mskOblRayon,
    ...mskRayon
} as const);

export const mskOblRayonIds = Object.values(mskOblRayon) as number[];
export const mskRayonIds = Object.values(mskRayon);
