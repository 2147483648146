import React, { useCallback } from 'react';

import { Button } from '@vtblife/uikit';

import { FilterSelectButton } from '../../../common/FilterSelect';

interface IDesktopBuildingsFilterViewProps {
    standalone?: boolean;
    showBuildingPicker: () => void;
}

export const DesktopBuildingsFilterView: React.FC<IDesktopBuildingsFilterViewProps> = ({
    standalone: isStandalone = false,
    showBuildingPicker
}) => {
    const handleClick = useCallback(showBuildingPicker, [ showBuildingPicker ]);

    if (isStandalone) {
        return (
            <Button
                variant='secondary'
                icon='map'
                onClick={handleClick}
            >
                Выбрать корпус
            </Button>
        );
    }

    return (
        <FilterSelectButton
            size='l'
            side='square'
            onClick={handleClick}
            style={{ position: 'relative' }}
        >
            Выбрать корпус
        </FilterSelectButton>
    );
};
