import React, { useCallback } from 'react';

import { FinishingEnum, FinishingTypeEnum } from '@search/graphql-typings';

import { FinishingTypeFilter, allWithFinishingValues } from '../../../../models/Filter';
import MobileFilter from '../../../filter/MobileFilter';
import { MultiSelect, MultiSelectOption } from '../../common/MultiSelect';

const SELECT_OPTIONS: MultiSelectOption<FinishingTypeEnum>[] = [
    {
        value: FinishingTypeEnum.WithoutFinishing,
        label: 'Без отделки',
        className: 'col-xs-12 filter-item-gap'
    },
    {
        value: FinishingTypeEnum.PreFinishing,
        label: 'Предчистовая',
        className: 'col-xs-12 filter-item-gap'
    },
    {
        value: FinishingTypeEnum.Fine,
        label: 'Чистовая',
        className: 'col-xs-12 filter-item-gap'
    },
    {
        value: FinishingTypeEnum.WithFurniture,
        label: 'С мебелью',
        className: 'col-xs-12 filter-item-gap'
    },
];

interface IProps {
    filter: FinishingTypeFilter;
    onChange: (filter: FinishingTypeFilter) => void;
}

/**
 * Фильтр "Тип отделки"
 * */
export const MobileFinishingTypeFilterView: React.FC<IProps> = ({
    filter,
    onChange
}) => {
    const handleChange = useCallback(values => {
        const hasAllAvailableValues = new Set([ ...values, ...allWithFinishingValues ]).size === allWithFinishingValues.length && values.length === allWithFinishingValues.length;

        onChange(new FinishingTypeFilter({
            hasFinishing: hasAllAvailableValues ? FinishingEnum.Available : undefined,
            finishingType: values
        }));
    }, [ onChange ]
    );

    const { finishingType } = filter.value;

    return (
        <MobileFilter
            filterTitle='Тип отделки'
        >
            <MultiSelect
                name='finishingType'
                className='container container__clamp'
                options={SELECT_OPTIONS}
                value={finishingType}
                handleChange={handleChange}
            />
        </MobileFilter>
    );
};
