/* eslint-disable */
import React from 'react';

export function Mortgage2IconM2({ className } : { className?: string}) {
    return (
        <svg width='36' height='32' viewBox='0 0 36 32' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
            <path d='M36 0H11v7H0v25h25v-7h11V0Zm-8.83 15.17v1.69h-7.04v-1.54s1.46-.74 2.4-1.26c1.24-.68 2.59-1.59 2.69-2.85.08-.97-.73-1.7-1.72-1.7-1.12 0-1.73.65-2.08 1.73l-1.54-.6c.47-1.3 1.53-2.73 3.61-2.73s3.56 1.52 3.56 3.29c0 2.22-2.44 3.3-3.87 3.94l-.04.02v.01h4.03ZM7.42 23.6v-8.42h2.63l2.46 6.04 2.45-6.04h2.65v8.42h-1.83v-6.32l-2.56 6.32h-1.38l-2.6-6.32v6.32H7.42Z' fill='currentColor' />
        </svg>
    );
}
