import './Filter2.css';

import React from 'react';
import { Typography } from '@vtblife/uikit';
import classname from '@search/classname/src';

const cn = classname.bind(null, 'Filter2');

export interface IFilterAvailableProps {
    withoutTitle?: boolean;
}

interface IProps {
    className?: string;
    filterTitle?: string;
}

export const Filter2: React.FC<IProps> = ({ className, filterTitle, children }) => (
    filterTitle ? (
        <div className={className}>
            {filterTitle ? (
                <Typography
                    variant='secondary-alone'
                    color='secondary'
                    className={cn('filter-title')}
                >
                    {filterTitle}
                </Typography>
            ) : null}
            <div>
                {children}
            </div>
        </div>
    ) : <>{children}</>
);
