import React, { useCallback } from 'react';

import { Checkbox } from '@vtblife/uikit';

import { Bathroom } from '@search/filter-enums/enums/Bathroom';

import { BathroomFilter } from '../../../models/Filter';
import { Filter2 } from '../../new_filter/Filter2';
import { CheckboxGroup2 } from '../../common/CheckboxGroup2';

type BathroomEnumForUi = Exclude<Bathroom, Bathroom.UNKNOWN>;
interface IBathroomFilterProps {
    filter: BathroomFilter;
    handleChange: (value: BathroomFilter) => void;
    several?: boolean;
}

const bathroomMapping: Record<BathroomEnumForUi, string> = {
    [Bathroom.COMBINED]: 'Совмещённый',
    [Bathroom.PARTED]: 'Раздельный',
    [Bathroom.SEVERAL]: 'Несколько'
};

const bathroomEnumeration: BathroomEnumForUi[] = [
    Bathroom.COMBINED,
    Bathroom.PARTED
];

export const BathroomFilterView: React.FC<IBathroomFilterProps> = ({
    filter,
    handleChange,
    several = false
}) => {
    const onChange = useCallback(value => {
        handleChange(new BathroomFilter(value));
    }, [ handleChange ]);
    const component = bathroomEnumeration.map(bathroom => {
        return (
            <Checkbox
                variant='filter'
                key={bathroom}
                name={bathroom}
                block
            >
                {bathroomMapping[bathroom]}
            </Checkbox>
        );
    });

    several && component.push(
        <Checkbox
            variant='filter'
            key={Bathroom.SEVERAL}
            value={Bathroom.SEVERAL}
            block
        >
            {bathroomMapping[Bathroom.SEVERAL]}
        </Checkbox>
    );

    return (
        <Filter2 filterTitle='Санузел'>
            <CheckboxGroup2
                mode='check'
                onChange={onChange}
                name='bathroom'
                value={filter.value as unknown as string[]}
                isFilter
            >
                {component}
            </CheckboxGroup2>
        </Filter2>
    );
};
