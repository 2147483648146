import React from 'react';
import type { MetroStation } from '../../../../../../../domain/geo/MetroStation';

export interface UseMetroSelectProps {
    /**
     * Массив выбранных станций
     */
    checked: readonly number[];

    /**
     * Изначально группировать по линиям, а не по алфавиту
     */
    groupByLineInitial?: boolean;

    onChange(checkedDiff: number[], uncheckedDiff: Set<number>): void;
}

export function useMetroSelect({
    onChange,
    checked,
    groupByLineInitial
}: UseMetroSelectProps) {
    const checkSet = React.useMemo(() => new Set(checked), [ checked ]);

    const checkStations = React.useCallback(
        (stations: readonly MetroStation[], check: boolean) => {
            const uncheckedDiff = new Set<number>();
            const checkedDiff: number[] = [];

            for (const station of stations) {
                if (check) checkedDiff.push(station.id);
                else uncheckedDiff.add(station.id);
            }

            onChange(checkedDiff, uncheckedDiff);
        },
        [ onChange ]
    );

    const isStationChecked = React.useCallback(
        (station: MetroStation) => checkSet.has(station.id),
        [ checkSet ]
    );

    const [ isGrouppedByLine, setIsGrouupedByLine ] = React.useState(
        groupByLineInitial || false
    );

    const groupByAlphabetAction = React.useCallback(() => {
        setIsGrouupedByLine(false);
    }, []);

    const groupByLineAction = React.useCallback(() => {
        setIsGrouupedByLine(true);
    }, []);

    const stationsSelected = checkSet.size;

    const reset = React.useCallback(() => {
        onChange([], checkSet);
    }, [ checkSet ]);

    return {
        stationsSelected,
        reset,
        isStationChecked,
        checkStations,
        groupByAlphabet: groupByAlphabetAction,
        groupByLine: groupByLineAction,
        isGrouppedByLine
    };
}

export function useMetroSelectRouteGroup(
    stations: readonly MetroStation[],
    checkStations: (station: readonly MetroStation[], checked: boolean) => void,
    isStationChecked: (station: MetroStation) => boolean
) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const allChecked = stations.every(station => isStationChecked(station));
    const checkAllInGroup = React.useCallback(() => {
        checkStations(stations, ! allChecked);
    }, [ stations, checkStations, allChecked ]);

    return { allChecked, checkAllInGroup };
}
