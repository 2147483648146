import throttle from 'lodash/throttle';
import { useRef, useEffect, useState, useCallback } from 'react';

export const useGetCurrentIndexImages = (length: number) => {
    const [ indexImage, setIndex ] = useState(0);

    const galleryRef = useRef<HTMLDivElement>(null);
    const galleryImageRefs = useRef<(Element | null)[]>(Array(length).map(() => null));

    const setChildRef = (ref: Element | null, index: number) => {
        if (ref) galleryImageRefs.current[index] = ref;
    };

    // eslint-disable-next-line no-undef
    const setCurrentIndex = useCallback((e: globalThis.Event) => {
        e.stopPropagation();
        e.preventDefault();

        const childs = galleryImageRefs.current;
        const rectParent = galleryRef.current?.getBoundingClientRect();

        if (! rectParent || ! childs) return;

        childs.forEach((child, index) => {
            if (! child) return;

            const childRectRight = child?.getBoundingClientRect().right;

            const relativeChildPosRightSide = Number((childRectRight - rectParent.right).toFixed(0));

            if (relativeChildPosRightSide >= -5 && relativeChildPosRightSide <= 5) {
                setIndex(index);
            }
        });
    });

    useEffect(() => {
        const parent = galleryRef.current;

        if (! galleryImageRefs.current || ! parent) return;

        const throttleHandleScroll = throttle(setCurrentIndex, 100) as EventListener;

        parent.addEventListener('scroll', throttleHandleScroll);

        return () => parent.removeEventListener('scroll', throttleHandleScroll);
    }, [ galleryImageRefs ]);

    return { setChildRef, galleryImageRefs, galleryRef, indexImage, setIndexImage: setIndex };
};
