import React, { useCallback } from 'react';

import { NewBuildingDeal } from '@search/filter-enums/enums';

import { NewBuildingDealFilter } from '../../../../models/Filter';

import MobileFilter from '../../../filter/MobileFilter';

import { MultiSelect, MultiSelectOption } from '../../common/MultiSelect';

const selectOptions: MultiSelectOption[] = [ {
    value: NewBuildingDeal.LAW_214,
    label: '214 ФЗ',
    className: 'col-xs-12 filter-item-gap'
}, {
    value: NewBuildingDeal.ESCROW,
    label: 'Эскроу-счета',
    className: 'col-xs-12 filter-item-gap'
} ];

interface IProps {
    filter: NewBuildingDealFilter;
    handleChange: (filter: NewBuildingDealFilter) => void;
}

export const MobileNewBuildingDealFilterView: React.FC<IProps> = ({
    filter,
    handleChange
}) => {
    const onChange = useCallback(value => {
        handleChange(new NewBuildingDealFilter(value));
    }, [ handleChange ]);

    return (
        <MobileFilter
            filterTitle='Сделка'
        >
            <MultiSelect
                name='newBuildingDeal'
                className='container container__clamp'
                options={selectOptions}
                value={filter.deal}
                handleChange={onChange}
            />
        </MobileFilter>
    );
};
