import React, { useCallback } from 'react';
import { Select, useMultipleSelect } from '@vtblife/uikit';
import { SelectMode, OptionType, SelectProps } from '@vtblife/uikit/legacy';
import { Rooms } from '@search/filter-enums/enums/Rooms';
import { RoomFilter } from '../../../../models/Filter';

interface IProps {
    filter: RoomFilter;
    onChange: (filter: RoomFilter) => void;
    isLarge?: boolean;
}

const SELECT_OPTIONS: OptionType<Rooms>[] = [
    { label: 'Студия', value: Rooms.STUDIO },
    { label: '1 комната', value: Rooms.ROOM_1 },
    { label: '2 комнаты', value: Rooms.ROOM_2 },
    { label: '3 комнаты', value: Rooms.ROOM_3 },
    { label: '4 комнаты', value: Rooms.ROOM_4 },
    { label: '5+ комнат', value: Rooms.ROOM_5_AND_MORE },
    { label: 'Свободная планировка', value: Rooms.OPEN_PLAN }
];

type Mutable<T> = {
    -readonly[P in keyof T]: T[P]
};

export const DesktopRoomsTypeFilterView: React.FC<IProps> = ({
    filter,
    onChange
}) => {
    const currentValues = new Set<Rooms>(filter.rooms);

    const handleChange = useCallback<NonNullable<SelectProps<OptionType<Rooms>, SelectMode.Multiple>['onChange']>>(
        val => {
            onChange(new RoomFilter(val as Mutable<typeof val>));
        },
        [ onChange ]
    );

    const {
        handleChange: handleChangeSelect,
        is
    } = useMultipleSelect<Rooms>(
        handleChange,
        {
            selected: filter.rooms
        }
    );

    return (
        <Select mode='multiple' onChange={handleChangeSelect}>
            <Select.Button dataTest='rooms-trigger'>
                {currentValues.size > 0 ?
                    renderCustomTitle(SELECT_OPTIONS.filter(({ value }) => currentValues.has(value))) :
                    'Комнат'
                }
            </Select.Button>
            <Select.Content adaptivePlacement={false}>
                {SELECT_OPTIONS.map(({ value, label }) => (
                    <Select.Option
                        key={value}
                        selected={is(value)}
                        value={value}
                    >{label}</Select.Option>
                ))}
            </Select.Content>
        </Select>
    );
};

export const renderCustomTitle = (selectedOptions: OptionType<Rooms>[]) => {
    const parsedValues = selectedOptions.map(({ value, ...rest }) => ({
        value: Number(value) as Rooms,
        ...rest
    }));

    if (parsedValues.length === 1) {
        const [ room ] = parsedValues;

        return room.value === Rooms.OPEN_PLAN ? 'Своб. план.' : room.label;
    }

    return parsedValues
        .reduce<(Pick<OptionType, 'index'> & { customlabel: string })[]>(
            (acc, opt, index, array) => {
                let customlabel = opt.label;

                if (opt.value === Rooms.STUDIO) customlabel = 'Ст.';
                if (opt.value === Rooms.OPEN_PLAN) customlabel = 'св. пл.';
                if ([
                    Rooms.ROOM_1,
                    Rooms.ROOM_2,
                    Rooms.ROOM_3,
                    Rooms.ROOM_4,
                    Rooms.ROOM_5_AND_MORE
                ].includes(opt.value)) {
                    const [ count ] = opt.label.split(' ');
                    const postFix = array.length < 3 ? ' комн.' : ' к.';

                    customlabel = `${count}${array.length === index + 1 ? postFix : ''}`;
                }

                acc = [
                    ...acc,
                    {
                        index: opt.index,
                        customlabel
                    }
                ];

                return acc;
            }, [])
        .sort((a, b) => a.index! - b.index!)
        .map(opt => opt.customlabel)
        .join(', ');
};
