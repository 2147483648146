import React, { useCallback, useContext, useState } from 'react';
import { Typography } from '@vtblife/uikit';
import { Size } from '@vtblife/uikit/legacy';
import { RootRegionsApplicationEnum, SuggestTypeEnum, TipTypeEnum } from '@search/graphql-typings';
import { SuggestItemTypeEnum } from '@search/graphql-gateway/typings';
import Icon from '@vtblife/uikit-icons';

import classname from '@search/classname/src';
import Input from '@search/vtbeco-ui/src/components/controls/Input';

import { voidFunction } from '@search/helpers/src/voidFunction';
import type { RegionIdEnum } from '@search/filter-enums/enums/Region';
import { regionsWithMetroEnumeration } from '@search/filter-enums/enums/Region';
import type { FilterCollection } from '@search/vtbeco-frontend-core/view/filters/models/FilterCollection';
import type { UseToggleChainResult } from '@search/vtbeco-frontend-core/view/common/components/Price/popper/useToggleChain';

import type { GeoBaseStore } from '../../../../../../domain/geo/GeoBase';
import type {
    AddQueryDisplayValue,
    ExtendedTip,
    QueryDisplayValue
} from '../../../../../common/hooks/useQueryDisplayValues';
import type { SuggestionType } from '../../../../../common/components/GeoSuggest/GeoSuggest';
import { correctAndBroadenRegionId, GeoSuggest } from '../../../../../common/components/GeoSuggest/GeoSuggest';

import type { IFilter } from '../../../../models/Filter';
import {
    DeveloperFilter,
    Filters,
    GeoFilter,
    NewBuildingDataFilter,
    RegionFilter,
    TransportLimitFilter
} from '../../../../models/Filter';

import { GeoSelector, TabsEnum as GeoSelectorTabsEnum } from './GeoSelector';
import { FilterTagList2 } from './FilterTagList2/FilterTagList2';
import './DesktopGeoFiltersView.css';

const cn = classname.bind(null, 'DesktopGeoFiltersView');

export interface IDesktopGeoFilterViewProps {
    rootRegionsApp: RootRegionsApplicationEnum;
    filters: FilterCollection;
    onChange: (filters: IFilter[]) => void;
    onSubmit?: () => void;
    count: number;
    showNewBuildingsGroup?: boolean;
    startWithNewBuildings?: boolean;
    addQueryDisplayValue?: AddQueryDisplayValue;
    placeholder?: string;
    placeholderWithNewBuildings?: string;
    geoStore: GeoBaseStore;
    limit?: number;
    inputSize?: Size.Small | Size.Large;
    inputBorderSide?: 'square' | 'rounded_square' | 'square_rounded';
    isVertical?: boolean;
    filterTitle?: string;
    tips?: QueryDisplayValue[];
    removeTips?: (tips: ExtendedTip[]) => void;
    suggestTypes?: SuggestTypeEnum[];
    className?: string;
    spySuggest?: UseToggleChainResult;
    showMapArea?(): void;
    clMap?: boolean;
    isClGeoFilter?: boolean;
}

export const DesktopGeoFiltersView2: React.FC<IDesktopGeoFilterViewProps> = ({
    geoStore,
    filters,
    onChange,
    onSubmit = voidFunction,
    count,
    rootRegionsApp,
    suggestTypes,
    limit,
    showNewBuildingsGroup,
    startWithNewBuildings,
    addQueryDisplayValue,
    placeholder = 'Введите город, метро, район, адрес',
    placeholderWithNewBuildings = 'Введите город, метро, район, адрес, ЖК',
    inputSize = Size.Large,
    inputBorderSide,
    isVertical = false,
    filterTitle,
    tips,
    removeTips,
    className,
    spySuggest,
    showMapArea,
    clMap = false,
    isClGeoFilter = false
}) => {
    const geoFilter = filters.get<GeoFilter>(Filters.geo);
    const transportLimitFilter = filters.get<TransportLimitFilter>(Filters.transportLimit);
    const regionId = filters.get<RegionFilter>(Filters.region).region;

    const { stations: metro } = geoStore;

    /** District в збс, новостройки не поддерживают byRegion */
    const districts = geoStore.districts.byRegion ? geoStore.districts.byRegion() : geoStore.districts;

    // eslint-disable-next-line complexity
    const handleChange = useCallback((suggestion: SuggestionType) => {
        let newRegionId: RegionIdEnum | undefined;

        if (
            suggestion?.data?.regionId &&
            regionId !== suggestion?.data?.regionId
        ) {
            newRegionId = suggestion.data.regionId;
        }

        const filterGeoIds: Set<number> = new Set<number>(newRegionId ? [] : geoFilter.geoIds);
        const filterMetroIds: number[] = newRegionId ? [] : [ ...geoFilter.metroIds ];
        const filterDistrictIds: number[] = newRegionId ? [] : [ ...geoFilter.districtIds ];
        const filterAddressIds: number[] = newRegionId ? [] : [ ...geoFilter.addressIds ];
        const filterNewBuildingIds: number[] = newRegionId ? [] : [ ...geoFilter.newBuildingIds ];
        let newBuildingData;
        let developerId;

        switch (suggestion.__typename) {
            case SuggestItemTypeEnum.NewBuilding: {
                const { id } = suggestion.data;

                if (! filterNewBuildingIds.includes(id)) {
                    filterNewBuildingIds.push(id);
                }

                const { newBuilding } = suggestion;

                if (newBuilding) {
                    const {
                        id: newBuildingId,
                        type: newBuildingType,
                        name: newBuildingName,
                        title: newBuildingTitle
                    } = newBuilding;

                    addQueryDisplayValue && addQueryDisplayValue('newBuildings', {
                        id: newBuildingId,
                        title: newBuildingTitle?.nominative,
                        type: TipTypeEnum.NewBuilding
                    });

                    newBuildingData = {
                        type: newBuildingType,
                        name: newBuildingName,
                        id: newBuildingId
                    };
                }

                break;
            }

            case SuggestItemTypeEnum.Developer: {
                const { developer } = suggestion;

                if (developer) {
                    const { id } = developer;

                    developerId = id;

                    addQueryDisplayValue && addQueryDisplayValue('developers', {
                        id,
                        title: developer.title,
                        type: TipTypeEnum.Developer
                    });
                }

                break;
            }

            case SuggestItemTypeEnum.District: {
                const { district } = suggestion;
                const { id, regionId } = suggestion.data;

                if (! filterDistrictIds.includes(id)) {
                    filterDistrictIds.push(id);
                    filterGeoIds.add(id);

                    addQueryDisplayValue && addQueryDisplayValue('districts', {
                        id: district.id,
                        title: district.displayName,
                        type: TipTypeEnum.District,
                        region: regionId ?? undefined,
                        translit: district.translit,
                        locative: district.locative,
                    });
                }

                break;
            }

            case SuggestItemTypeEnum.Metro: {
                const {
                    label,
                    data: { id }
                } = suggestion;

                if (! filterMetroIds.includes(id)) {
                    filterMetroIds.push(id);
                    filterGeoIds.add(id);

                    addQueryDisplayValue && addQueryDisplayValue('metros', {
                        id,
                        title: label,
                        type: TipTypeEnum.Metro
                    });
                }

                break;
            }

            case SuggestItemTypeEnum.Address: {
                const {
                    address,
                    data: { id, regionId }
                } = suggestion;

                if (! filterAddressIds.includes(id)) {
                    filterAddressIds.push(id);
                    filterGeoIds.add(id);

                    addQueryDisplayValue && addQueryDisplayValue('addresses', {
                        id: address.id,
                        kind: address.kind,
                        translit: address.translit,
                        locative: address.locative,
                        title: address.displayName,
                        type: TipTypeEnum.Address,
                        region: regionId ?? undefined
                    });
                }

                break;
            }

            default:
                return;
        }

        const newFilters: IFilter[] = [
            new GeoFilter(
                filterGeoIds,
                filterMetroIds,
                filterDistrictIds,
                filterAddressIds,
                filterNewBuildingIds
            ),
            new NewBuildingDataFilter(newBuildingData)
        ];

        if (newRegionId) {
            newFilters.push(
                new RegionFilter(newRegionId)
            );
        }

        if (developerId) {
            newFilters.push(
                new DeveloperFilter(developerId)
            );
        }

        onChange(newFilters);
    }, [
        geoFilter.geoIds,
        geoFilter.districtIds,
        geoFilter.addressIds,
        geoFilter.metroIds,
        geoFilter.newBuildingIds,
        onChange,
        regionId,
        addQueryDisplayValue
    ]);

    const [ geoSelectorActiveTab, setGeoSelectorActiveTab ] = useState<GeoSelectorTabsEnum | null>(null);

    const handleGeoSelectorClose = useCallback(() => {
        setGeoSelectorActiveTab(null);
    }, [ setGeoSelectorActiveTab ]);

    const handleGeoSelectorSubmit = useCallback(() => {
        handleGeoSelectorClose();
        onSubmit();
    }, [ onSubmit, handleGeoSelectorClose ]);

    const handleMetroClick = useCallback(() => {
        setGeoSelectorActiveTab(GeoSelectorTabsEnum.metro);
    }, [ setGeoSelectorActiveTab ]);

    const handleDistrictClick = useCallback(() => {
        setGeoSelectorActiveTab(GeoSelectorTabsEnum.district);
    }, [ setGeoSelectorActiveTab ]);

    const handleGeoSelectorChange = useCallback(data => {
        const changedFilters = [];

        if (data.metroIds || data.districtIds) {
            const geoIds = new Set(geoFilter.geoIds);
            const metroIds = new Set(geoFilter.metroIds);
            const districtIds = new Set(geoFilter.districtIds);

            if (data.metroIds) {
                if (data.metroIds.diff.selected) {
                    data.metroIds.diff.selected.forEach((id: number) => {
                        metroIds.add(id);
                        geoIds.add(id);
                    });
                }

                if (data.metroIds.diff.deselected) {
                    data.metroIds.diff.deselected.forEach((id: number) => {
                        metroIds.delete(id);
                        geoIds.delete(id);
                    });
                }
            }

            if (data.districtIds) {
                if (data.districtIds.diff.selected) {
                    data.districtIds.diff.selected.forEach((id: number) => {
                        districtIds.add(id);
                        geoIds.add(id);
                    });
                }

                if (data.districtIds.diff.deselected) {
                    data.districtIds.diff.deselected.forEach((id: number) => {
                        districtIds.delete(id);
                        geoIds.delete(id);
                    });
                }
            }

            const metroIdList = Array.from(metroIds);
            const districtIdList = Array.from(districtIds);
            const ids: {
                type: SuggestItemTypeEnum;
                idList: number[];
            }[] = [
                {
                    type: SuggestItemTypeEnum.Metro,
                    idList: metroIdList
                },
                {
                    type: SuggestItemTypeEnum.District,
                    idList: districtIdList
                }
            ];

            let newRegionId: number | undefined;

            for (const { type, idList } of ids) {
                for (const id of idList) {
                    const broadenedRegionId = correctAndBroadenRegionId(id, type, regionId);

                    if (broadenedRegionId !== regionId) {
                        newRegionId = broadenedRegionId;

                        break;
                    }
                }

                if (newRegionId) {
                    changedFilters.push(new RegionFilter(newRegionId));

                    break;
                }
            }

            changedFilters.push(new GeoFilter(
                geoIds,
                metroIdList,
                districtIdList,
                geoFilter.addressIds,
                geoFilter.newBuildingIds
            ));
        }

        onChange(changedFilters);
    }, [ onChange, geoFilter, regionId ]);

    const handleTransportLimitChange = useCallback((tranpostLimitFilter: TransportLimitFilter) => {
        onChange([ tranpostLimitFilter ]);
    }, [ onChange ]);

    const hasMetro = regionsWithMetroEnumeration.has(regionId) && ! metro.isEmpty();
    const hasDistricts = districts.isEmpty ? ! districts.isEmpty() : districts?.ids?.length;

    const extraLinks = clMap ? (
        <div className={cn('extra-links-cl')}>
            {hasMetro && (
                <div
                    className={cn('metro')}
                    data-test='filter-geo-metro-popup'
                    onClick={handleMetroClick}
                >
                    <span className={cn('metro-icon')}>
                        <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'>
                            <path
                                d='M20.486 13.765 15.347 0 11 8.047 6.67 0 1.514 13.765H0v2.086h7.78v-2.086H6.617l1.126-3.427L11 16l3.256-5.662 1.126 3.427h-1.161v2.086H22v-2.086h-1.514Z'
                                fill='#DD201C'
                            />
                        </svg>
                    </span>
                    <Typography className={cn('metro-text')} variant='secondary-alone' bold>
                        Выбрать метро
                    </Typography>
                    {geoFilter.metroIds.length > 0 && (
                        <div className={cn('metro-count')}>{geoFilter.metroIds.length}</div>
                    )}
                </div>
            )}
            {hasDistricts && (
                <div
                    className={cn('district')}
                    data-test='filter-geo-district-popup'
                    onClick={handleDistrictClick}
                >
                    <span className={cn('district-icon')}>
                        <Icon name='m2-building-multiple' color='#6e6a81' size='m' />
                    </span>
                    <Typography className={cn('district-text')} variant='secondary-alone' bold>
                        Выбрать район
                    </Typography>
                </div>
            )}
        </div>
    ) : (
        <div className={cn('extra-links')}>
            <Typography tag='span' color='secondary'>Выбрать:&nbsp;</Typography>
            {hasMetro && (
                <Typography
                    tag='span'
                    color='blue'
                    className={cn('extra-link')}
                    onClick={handleMetroClick}
                    data-test='filter-geo-metro-popup'>
                    {hasDistricts ? 'метро, ' : 'метро'}
                </Typography>
            )}
            {hasDistricts && (
                <Typography
                    tag='span'
                    color='blue'
                    className={cn('extra-link')}
                    onClick={handleDistrictClick}
                    data-test='filter-geo-district-popup'
                >
                    районы
                </Typography>
            )}
        </div>
    );

    return (
        <>
            {filterTitle && (
                <Typography
                    color='black-250'
                    variant='secondary-alone'
                    className={cn('filter-title')}
                >
                    {filterTitle}
                </Typography>
            )}
            <div className={cn(null, undefined, className)}>
                <GeoSuggest
                    regionId={regionId}
                    rootRegionsApp={rootRegionsApp}
                    suggestTypes={suggestTypes}
                    limit={limit}
                    showNewBuildingsGroup={showNewBuildingsGroup && ! geoFilter.newBuildingIds.length}
                    startWithNewBuildings={startWithNewBuildings}
                    selected={geoFilter.geoIds}
                    onGeoChange={handleChange}
                    placeholder={placeholder}
                    placeholderWithNewBuildings={placeholderWithNewBuildings}
                    spySuggest={spySuggest}
                    renderInput={(props, state, handlers) => {
                        return (
                            <Input
                                size={inputSize}
                                borderSide={inputBorderSide}
                                width='max'
                                className={cn('input', { vertical: isVertical })}
                                name='filter-geo-input'
                                onBlur={handlers.handleBlur}
                                onFocus={handlers.handleFocus}
                                onChange={handlers.handleInputChange}
                                onKeyDown={handlers.handleInputKeyDown}
                                value={state.label}
                                placeholder={props.placeholder}
                                title={props.placeholder}
                                extraAfter={isVertical ? null : (<>
                                    { hasMetro ? (
                                        <span
                                            className={cn('extra')}
                                            onClick={handleMetroClick}
                                            data-test='filter-geo-metro-popup'
                                        >
                                            Метро
                                        </span>
                                    ) : null}
                                    { hasDistricts ? (
                                        <span
                                            className={cn('extra')}
                                            onClick={handleDistrictClick}
                                            data-test='filter-geo-district-popup'
                                        >
                                            Районы
                                        </span>
                                    ) : null}
                                    {isClGeoFilter ? <span
                                        className={cn('extra')}
                                        data-test='filter-geo-map-popup'
                                        onClick={showMapArea}
                                    >
                                        Область на карте
                                    </span> : null}
                                </>)}
                            />
                        );
                    }}
                />
                {
                    geoSelectorActiveTab === null ? null : (
                        <GeoSelector
                            count={count}
                            regionId={regionId}
                            geoFilter={geoFilter}
                            metro={metro}
                            districts={districts}
                            activeTab={geoSelectorActiveTab}
                            onChange={handleGeoSelectorChange}
                            transportLimitFilter={transportLimitFilter}
                            onChangeTransportLimitChange={handleTransportLimitChange}
                            onTabChange={setGeoSelectorActiveTab}
                            onClose={handleGeoSelectorClose}
                            onSubmit={handleGeoSelectorSubmit}
                        />
                    )
                }
                {
                    isVertical && (hasMetro || hasDistricts) ? extraLinks : null
                }
                {
                    tips?.length && removeTips ? (
                        <FilterTagList2
                            tips={tips}
                            filters={filters}
                            openMetroModal={handleMetroClick}
                            removeTips={removeTips}
                        />
                    ) : null
                }
            </div>
        </>
    );
};
