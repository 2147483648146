import React, { useEffect } from 'react';

import type { userDetailsQuery$data as userDetailsQueryResponse } from '@search/auth/src/userDetails/__generated__/userDetailsQuery.graphql';
import type { MortgagePurpose } from '@search/graphql-typings';
import { useWorkingHours } from '../../WorkingHours/useWorkingHours';

export type Mortgage2StarterProps = {
    gtmSendId?: string;
    gtmShowId?: string;
    // eslint-disable-next-line camelcase
    gtmSendData?: { event: string; page_type: string; place?: string; action?: string };
    // eslint-disable-next-line camelcase
    gtmShowData?: { event: string; page_type: string; place?: string; action?: string };
    term?: number;
    purpose: MortgagePurpose;
    mortgageBannerMinutesVariant?: number;
    mortgageBannerTextVariant?: number;
    regionId?: number;
    mortgageRegionId?: number;
};

export function useMortgage2UserForm({
    isNameRequired = false,
    userDetails
}: {
    userDetails?: userDetailsQueryResponse['userProfile']['person'];
    isNameRequired?: boolean;
}) {
    const [ state, setFormState ] = React.useState({
        isFormSending: false,
        isFormSended: false,
        sendingError: undefined as Error | undefined,
        phone: userDetails?.primaryPhone?.number ?? '',
        isPhoneTouched: false,
        name: '',
        isNameTouched: false
    });

    const START_HOUR_WORKING_DAY_SALES = 9;
    const END_HOUR_WORKING_DAY_SALES = 21;

    const {
        workingHoursRange,
        setStartHoursRange,
        setEndHoursRange,
        startHourValues,
        endHourValues
    } = useWorkingHours({
        startHour: START_HOUR_WORKING_DAY_SALES,
        endHour: END_HOUR_WORKING_DAY_SALES
    });

    const setPhone = React.useCallback((phone: string) => setFormState(p => ({ ...p, phone })), [ ]);
    const setPhoneTouched = React.useCallback(() => setFormState(p => ({ ...p, isPhoneTouched: true })), [ ]);
    const setName = React.useCallback((name: string) => setFormState(p => ({ ...p, name })), [ ]);
    const setNameTouched = React.useCallback(() => setFormState(p => ({ ...p, isNameTouched: true })), [ ]);

    const setFormIsSending = React.useCallback((sendingError?: Error) => setFormState(p => ({
        ...p, sendingError, isFormSending: true
    })), [ ]);
    const setFormSended = React.useCallback((sendingError?: Error) => setFormState(p => ({
        ...p, sendingError, isFormSending: false, isFormSended: true
    })), [ ]);
    const setFormResetSendingsFields = React.useCallback(() => setFormState(p => ({
        ...p, isFormSending: false, isFormSended: false, sendingError: undefined
    })), [ ]);
    const setFormAllTouched = React.useCallback(() => {
        setFormState(p => ({ ...p, isPhoneTouched: true, isNameTouched: true, isFormSending: false }));
    }, [ ]);

    useEffect(() => {
        userDetails?.primaryPhone?.number && setPhone(userDetails?.primaryPhone?.number);

        const nameArr = [
            userDetails?.surname, userDetails?.name, userDetails?.patronymic
        ].filter(Boolean);

        nameArr.length > 0 && setName(nameArr.join(' '));
    }, [ userDetails, setPhone, setName ]);

    const isNameValid = isNameRequired ? state.name.length > 1 : undefined;
    const nameInputValid = state.isNameTouched ? isNameValid : undefined;

    const isPhoneValid = state.phone.length > 10;
    const phoneInputValid = state.isPhoneTouched ? isPhoneValid : undefined;

    const result = {
        ...state,
        isFormSyncing: state.isFormSending,
        setFormSended,
        setFormIsSending,
        setFormAllTouched,
        setFormResetSendingsFields,

        name: state.name,
        isNameValid,
        isNameRequired,
        nameInputValid,
        setName,
        setNameTouched,

        phone: state.phone,
        isPhoneValid,
        phoneInputValid,
        setPhone,
        setPhoneTouched,
        workingHoursRange,
        setStartHoursRange,
        setEndHoursRange,
        startHourValues,
        endHourValues
    };

    return result as Omit<typeof result, 'isFormSending'>;
}

