export enum ToneEnum {
    GREY = 'grey',
    BLUE = 'blue',
    LIGHT = 'light',
    DARK = 'dark',
    PRIMARY = 'primary',
    SECONDARY = 'secondary'
}

export type Tone = 'grey' | 'blue' | 'dark' | 'primary' | 'secondary' | 'light';
