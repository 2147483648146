/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable complexity */
import React, {
    useEffect,
    useState,
    Fragment
} from 'react';

import { Typography } from '@vtblife/uikit';
import Icon from '@vtblife/uikit-icons';

import { GeoKind } from '@search/filter-enums/enums/GeoKind';
import classname from '@search/classname/src';
import {
    DealType,
    RoomsOfferedOptions,
    LandStatus,
    GasOptions,
    ElectricityOptions,
    SewerageOptions,
    WaterOptions,
    HeatingOptions,
    BathroomLocationOptions,
    Rooms,
    LivingFacilitiesOption,
    PrepaymentType,
    CommissionType,
    DepositType,
    FurnitureOption,
    LivingTenantOption,
    FinishingTypeOption,
    Commissioning,
    Quarter
} from '@search/filter-enums/enums';
import { RealtyObjectType } from '@search/filter-enums/enums/RealtyObjectType';
import { FilterCollection } from '@search/vtbeco-frontend-core/view/filters/models/FilterCollection';
import { WindowsDirectionSearchOffersEnum } from '@search/graphql-typings';

import type { QueryDisplayValues } from '../../common/hooks/useQueryDisplayValues';
import type { MetroStation } from '../../../domain/geo/MetroStation';
import { MetroMark } from '../../common/components/GeoSuggest/MetroMark';
import FiltersFormTips from '../../desktop/components/FiltersForm/FiltersFormTips';
import FiltersFormTip from '../../desktop/components/FiltersForm/FiltersFormTips/FiltersFormTip';
import { groupDistricts } from '../../filters/helpers/districtHelper';
import {
    AccreditationFilter,
    BathroomFilter,
    BalconyFilter,
    BuildingClassFilter,
    NewBuildingDealFilter,
    DeveloperFilter,
    ClosedSalesFilter,
    Filters,
    GeoFilter,
    IFilter,
    InfrastructureFilter,
    SafetyFilter,
    ParkingFilter,
    PositionFilter,
    RenovationFilter,
    PaymentTypeFilter,
    WallsTypeFilter,
    MapBoundsFilter,
    RoomsInOfferFilter,
    DealTypeFilter,
    LandStatusFilter,
    CategoryTypeFilter,
    RoomFilter,
    LandAreaFilter,
    SupplyOptionsFilter,
    HeatingFilter,
    BathroomLocationFilter,
    HouseAreaFilter,
    FloorsTotalFilter,
    LivingFacilityFilter,
    RentPrepaymentFilter,
    RentConditionsFilter,
    FurnitureFilter,
    LivingTenantOptionFilter,
    SellTypeFilter,
    BuildingsFilter,
    AuthorFilter,
    WindowsDirectionFilter,
    RegionFilter,
    FlatsSpecialEventsFilter,
    OtherSpecialEventsFilter,
    MiscSpecialEventsFilter,
    BuildEpochFilter,
    MortgagePaymentFilter,
    FinishingFilter,
    CommissioningFilter
} from '../../filters/models/Filter';

import { extraFiltersIds, serializeToTips, buildRangeLabel } from '../serializeToTips';
import {
    accreditationLabels,
    positionLabels,
    bathroomLabels,
    balconyLabels,
    buildingClassLabels,
    newBuildingDealLabels,
    infrastructureLabels,
    safetyLabels,
    parkingLabels,
    renovationLabels,
    paymentTypeLabels,
    wallsLabels,
    saleTypeLabels,
    authorLabels,
    flatsSpecialEventsLabels,
    otherSpecialEventsLabels,
    miscSpecialEventsLabels,
    buildEpochLabels
} from '../labels';

import './style.css';
import type { GeoBaseStore } from '../../../domain/geo/GeoBase';

type Id = typeof extraFiltersIds[number];

export type TipItemBase = {
    id: number;
    __typename?: 'TipRouteMetro';
    geoId?: number;
}

export type TipItem = {
    type?: any;
    id: Id;
    kind?: GeoKind | Filters | 'METRO';
    partial?: boolean;
    title: string | null;
    geoId?: number;
} | {
    kind: 'district-group';
    title: string;
    id: string;
    districts: TipItemBase[];
};

interface ITipProps {
    removeTip: (tip: TipItem) => void;
    tip: TipItem;
    enabled?: boolean;
    onWhiteBackground: boolean;
    openMortgagePaymentSearchModal?: () => void;
}

type QuarterType = keyof typeof Quarter;

const MIN_TIPS_COUNT = 3;
const TIP_CLASS = 'FiltersFormTip';
const cn = classname.bind(null, TIP_CLASS);
const cnButton = classname.bind(null, cn('TipButton'));

const Tip = ({
    tip,
    removeTip,
    enabled = true,
    onWhiteBackground,
    openMortgagePaymentSearchModal
}: ITipProps) => {
    let content;

    if (tip.kind === GeoKind.METRO) {
        const metroTip = tip as MetroStation;

        content = (
            <>
                {
                    metroTip.allRoutesColorsList ? (
                        <MetroMark
                            colors={metroTip.allRoutesColorsList}
                            className={cn('mark')}
                        />
                    ) : (
                        <Typography variant='secondary-alone'>{'м. '}</Typography>
                    )
                }
                <Typography
                    variant='secondary-alone'
                    className={cn('metro')}
                >
                    {metroTip.title}
                </Typography>
            </>
        );
    } else if (tip.id === Filters.mortgagePayment) {
        content = (
            <Typography variant='secondary-alone' onClick={openMortgagePaymentSearchModal}>
                {tip.title}
                <span style={{ color: '#3216B5' }}>&nbsp;Расчёт →</span>
            </Typography>
        );
    } else {
        content = (
            <Typography variant='secondary-alone'>
                {tip.title}
            </Typography>
        );
    }

    return (
        <FiltersFormTip
            key={tip.id}
            onRemove={() => enabled ? removeTip(tip) : undefined}
            enabled={enabled}
            onWhiteBackground={onWhiteBackground}
            mortgageTip={tip.id === Filters.mortgagePayment}
        >
            {content}
        </FiltersFormTip>
    );
};

type IProps = {
    filters: FilterCollection;
    updateFilter: (filter: IFilter) => void;
    removeFilter: (filter: IFilter) => void;
    expandFilter: (state: boolean) => void | undefined;
    removeGeo?: (tip: TipItemBase[]) => void;
    onUpdateLength?: (length: number) => void;
    reset?: () => void;
    customFilterBehavior?: {
        developer: {
            reset: () => void;
        };
    };
    onWhiteBackground?: boolean;
    classNamesNewFilters?: {
        firstLineTipsOnly: string;
        allTips: string;
        tipsButtons: string;
    };
    loading?: boolean;
    tipsExpanded?: boolean;
    openMortgagePaymentSearchModal?: () => void;
} & ({
    shouldIgnoreGeoIds?: boolean;
    geoStore?: any;
    tips?: undefined;
    queryDisplayValues?: QueryDisplayValues;
} | { tips: ReturnType<typeof getTipsCollection> })

function getDealForRoom(dealTypeFilter: DealTypeFilter) {
    if (dealTypeFilter.dealType === DealType.SELL) {
        return 'на продажу';
    } else if (dealTypeFilter.dealType === DealType.RENT) {
        return 'в аренду';
    }
}

const roomsInOffer = {
    [RoomsOfferedOptions.ROOM_1]: '1',
    [RoomsOfferedOptions.ROOM_2]: '2',
    [RoomsOfferedOptions.ROOM_3]: '3',
    [RoomsOfferedOptions.ROOM_4_AND_MORE]: '4+'
};

const roomInOfferWithText = {
    [RoomsOfferedOptions.ROOM_1]: '1 комната',
    [RoomsOfferedOptions.ROOM_2]: '2 комнаты',
    [RoomsOfferedOptions.ROOM_3]: '3 комнаты',
    [RoomsOfferedOptions.ROOM_4_AND_MORE]: '4+ комнат'
};

const roomForSale = {
    [Rooms.ROOM_1]: '1',
    [Rooms.ROOM_2]: '2',
    [Rooms.ROOM_3]: '3',
    [Rooms.ROOM_4]: '4',
    [Rooms.ROOM_5_AND_MORE]: '5+'
};

const roomWithText = {
    [Rooms.ROOM_1]: '1 комната',
    [Rooms.ROOM_2]: '2 комнаты',
    [Rooms.ROOM_3]: '3 комнаты',
    [Rooms.ROOM_4]: '4 комнаты',
    [Rooms.ROOM_5_AND_MORE]: '5+ комнат'
};

function getRoomsInOfferTips(
    roomsInOfferFilter: RoomsInOfferFilter | undefined,
    dealTypeFilter: DealTypeFilter
) {
    const rooms = roomsInOfferFilter?.roomsInOffer ?? [];

    if (rooms.length === 0) {
        return null;
    }

    const roomsInOfferText = rooms.map(room => roomsInOffer[room]).join(', ');
    const roomsText = rooms.length === 1 ? roomInOfferWithText[rooms[0]] : `${roomsInOfferText} комнат`;
    const dealTypeText = getDealForRoom(dealTypeFilter);

    return `${roomsText} ${dealTypeText}`;
}

function getAtRealtyType(realtyType?: RealtyObjectType) {
    if (
        realtyType === RealtyObjectType.FLAT ||
        realtyType === RealtyObjectType.ROOM
    ) {
        return 'в квартире';
    } else if (
        realtyType === RealtyObjectType.HOUSE
    ) {
        return 'в доме';
    }

    return '';
}

const livingFacilityMapping = {
    [LivingFacilitiesOption.INTERNET]: 'Интернет',
    [LivingFacilitiesOption.WASHING_MACHINE]: 'Стиральная машина',
    [LivingFacilitiesOption.FRIDGE]: 'Холодильник',
    [LivingFacilitiesOption.DISHWASHER]: 'Посудомоечная машина',
    [LivingFacilitiesOption.AIR_CONDITIONER]: 'Кондиционер',
    [LivingFacilitiesOption.TV]: 'Телевизор'
};

function getLivingFacilityTip(
    facility: LivingFacilitiesOption
): string | undefined {
    return livingFacilityMapping[facility];
}

function getRoomsTips(
    roomFilter: RoomFilter | undefined,
    realtyObjectFilter: CategoryTypeFilter | undefined
) {
    const rooms = roomFilter?.rooms ?? [];

    if (rooms.length === 0) {
        return null;
    }

    const roomsCountText = rooms.map(room => roomForSale[room]).join(', ');
    let roomsText = rooms.length === 1 ? roomWithText[rooms[0]] : `${roomsCountText} комнат`;
    const atRealtyTypeText = getAtRealtyType(realtyObjectFilter?.categoryType);

    // roomsText maybe absent, if bot/robot will come with wrong url (/moskva/nedvizhimost/snyat-dom/svobodnaya-planirovka/rayon-lianozovo/s-vodoj/)
    if (realtyObjectFilter?.categoryType === RealtyObjectType.HOUSE && roomsText) {
        roomsText = roomsText.replace('4, 5+', '4+');
    }

    return `${roomsText} ${atRealtyTypeText}`;
}

const getLandAreaLabelTip = (filter: LandAreaFilter) =>
    buildRangeLabel(filter.value, 'Площадь участка', 'сот.');

const getHouseAreaLabelTip = (filter: HouseAreaFilter) =>
    buildRangeLabel(filter.value, 'Площадь дома', 'м²');

const landStatus = {
    [LandStatus.IZHS]: 'ИЖС',
    [LandStatus.SNT]: 'СНТ',
    [LandStatus.DNP]: 'ДНП',
    [LandStatus.AGRICULTURAL]: 'ЛПХ'
};

function getLandStatusTips(filter: LandStatusFilter) {
    const statuses = filter.landStatuses
        .map(status => landStatus[status])
        .filter(a => a);

    if (statuses.length === 0) {
        return null;
    }

    return `Статус участка: ${statuses.join(', ')}`;
}

function getSupplyOptionsTip(supplyOptionsFilter: SupplyOptionsFilter, heatingFilter?: HeatingFilter) {
    const result: string[] = [];

    if (supplyOptionsFilter.gas.includes(GasOptions.HAS_ANY)) {
        result.push('газ');
    }

    if (supplyOptionsFilter.electricity.includes(ElectricityOptions.HAS_ANY)) {
        result.push('электричество');
    }

    if (supplyOptionsFilter.sewerage.includes(SewerageOptions.HAS_ANY)) {
        result.push('канализация');
    }

    if (supplyOptionsFilter.water.includes(WaterOptions.HAS_ANY)) {
        result.push('вода');
    }

    if (heatingFilter && heatingFilter.heating.includes(HeatingOptions.HAS_ANY)) {
        result.push('отопление');
    }

    if (result.length === 0) {
        return null;
    }

    const resultText = result.join(', ');

    return `Коммуникации: ${resultText}`;
}

function getWindowsDirectionLabel(filter: WindowsDirectionFilter) {
    const result: string[] = [];

    if (filter.value.includes(WindowsDirectionSearchOffersEnum.Rear)) {
        result.push('во двор');
    }

    if (filter.value.includes(WindowsDirectionSearchOffersEnum.Front)) {
        result.push('на улицу');
    }

    const resultText = result.join(' и ');

    return `Вид из ок${result.length > 1 ? 'он' : 'на'}: ${resultText}`;
}

function getHeatingTip(filter: HeatingFilter) {
    if (filter.heating.includes(HeatingOptions.HAS_ANY)) {
        return 'Отопление: есть';
    }

    return null;
}

function getBathroomLocationTip(filter: BathroomLocationFilter) {
    if (filter.locations.includes(BathroomLocationOptions.INSIDE)) {
        return 'Санузел в доме';
    }

    return null;
}

function getFloorsTotalTip(filter: FloorsTotalFilter) {
    return buildRangeLabel(filter.range, 'Этажей в доме', 'эт.');
}

function getExtraFilterIds(filters: FilterCollection) {
    const result = [ ...extraFiltersIds ];
    const realtyTypeFilter = filters.get(Filters.categoryType) as CategoryTypeFilter;
    const categoryType = realtyTypeFilter?.categoryType;

    if (
        categoryType === RealtyObjectType.ROOM
    ) {
        result.push(Filters.rooms);
        result.push(Filters.roomsInOffer);
    }
    if (categoryType === RealtyObjectType.HOUSE) {
        result.push(Filters.rooms);
        result.push(Filters.landStatus);
    }

    return result;
}

function getPrepaymentTip(filter: RentPrepaymentFilter) {
    const prepaymentType = filter.prePaymentType;

    if (prepaymentType === PrepaymentType.NO_PREPAYMENT) {
        return 'Без предоплаты';
    } else if (prepaymentType === PrepaymentType.UP_TO_1_PRICE) {
        return 'Предоплата до 1 месяца';
    } else if (prepaymentType === PrepaymentType.UP_TO_2_PRICE) {
        return 'Предоплата до 2 месяцев';
    } else if (prepaymentType === PrepaymentType.UP_TO_3_PRICE) {
        return 'Предоплата до 3 месяцев';
    }

    return null;
}

function getRentConditionsTip(filter: RentConditionsFilter) {
    const result: any[] = [];

    if (filter.commisionType === CommissionType.NO_CLIENT_COMMISSION) {
        result.push({
            id: Filters.rentConditions + filter.commisionType as Id,
            type: 'commisionType',
            kind: Filters.rentConditions,
            title: 'Без комиссии'
        });
    }

    if (filter.depositType === DepositType.NO_DEPOSIT) {
        result.push({
            id: Filters.rentConditions + 'depositType' as Id,
            type: 'depositType',
            kind: Filters.rentConditions,
            title: 'Без залога'
        });
    }

    if (filter.utilitiesIncluded === true) {
        result.push({
            id: Filters.rentConditions + 'utilitiesIncluded' as Id,
            type: 'utilitiesIncluded',
            kind: Filters.rentConditions,
            title: 'КУ Включены'
        });
    }

    return result;
}

function getFurnitureTip(filter: FurnitureFilter) {
    const result: any[] = [];
    const furnitures = filter.furnitures;

    furnitures.forEach(furniture => {
        if (furniture === FurnitureOption.ALL) {
            result.push({
                id: Filters.furniture + String(furniture) as Id,
                type: furniture,
                kind: Filters.furniture,
                title: 'С мебелью'
            });
        } else if (furniture === FurnitureOption.KITCHEN) {
            result.push({
                id: Filters.furniture + String(furniture) as Id,
                type: furniture,
                kind: Filters.furniture,
                title: 'Мебель на кухне'
            });
        } else if (furniture === FurnitureOption.NO) {
            result.push({
                id: Filters.furniture + String(furniture) as Id,
                type: furniture,
                kind: Filters.furniture,
                title: 'Без мебели'
            });
        }
    });

    return result;
}

function getFinishingTip(filter: FinishingFilter) {
    const result: any[] = [];
    const finishingTypes = filter.finishingType;

    finishingTypes.forEach(type => {
        if (type === FinishingTypeOption.WITHOUT_FINISHING) {
            result.push({
                id: Filters.finishingType + String(type) as Id,
                type,
                kind: Filters.finishingType,
                title: 'Отделка: Без отделки'
            });
        } else if (type === FinishingTypeOption.FINE) {
            result.push({
                id: Filters.finishingType + String(type) as Id,
                type,
                kind: Filters.finishingType,
                title: 'Отделка: Чистовая'
            });
        } else if (type === FinishingTypeOption.PRE_FINISHING) {
            result.push({
                id: Filters.finishingType + String(type) as Id,
                type,
                kind: Filters.finishingType,
                title: 'Отделка: Предчистовая'
            });
        } else if (type === FinishingTypeOption.WITH_FURNITURE) {
            result.push({
                id: Filters.finishingType + String(type) as Id,
                type,
                kind: Filters.finishingType,
                title: 'Отделка: C мебелью'
            });
        }
    });

    return result;
}

function getCommissioningTip(filter: CommissioningFilter) {
    const commissioning = filter.commissioning;

    let tip = 'Срок сдачи: ';

    switch (commissioning) {
        case (Commissioning.COMMISSIONED): {
            tip += 'Сдан';
            break;
        }
        case (Commissioning.IN_PROGRESS): {
            tip += 'Строится';
            break;
        }
        case (Commissioning.UNKNOWN): {
            return;
        }
        default: {
            if (! commissioning) {
                return;
            }

            const yearArr = commissioning.split('_');
            const quarter: QuarterType = yearArr[0] as QuarterType;
            const year = yearArr[1];

            tip += `До ${Quarter[quarter]} кв. ${year}`;
            break;
        }
    }

    return ({
        id: Filters.commissioning,
        type: tip,
        kind: Filters.commissioning,
        title: tip
    });
}

function getLivingTenantOptionsTip(filter: LivingTenantOptionFilter) {
    const result: any[] = [];
    const livingOptions = filter.livingTenantOptions;

    livingOptions.forEach(livingOption => {
        if (livingOption === LivingTenantOption.CHILDREN_ALLOWED) {
            result.push({
                id: Filters.livingTenantOptions + String(livingOption) as Id,
                type: livingOption,
                kind: Filters.livingTenantOptions,
                title: 'Можно с детьми'
            });
        } else if (livingOption === LivingTenantOption.PETS_ALLOWED) {
            result.push({
                id: Filters.livingTenantOptions + String(livingOption) as Id,
                type: livingOption,
                kind: Filters.livingTenantOptions,
                title: 'Можно с животными'
            });
        }
    });

    return result;
}

export function getTipsCollection({
    geoStore,
    filters,
    queryDisplayValues,
    shouldIgnoreGeoIds = false
}: {
    geoStore?: GeoBaseStore;
    filters: FilterCollection;
    queryDisplayValues?: QueryDisplayValues;
    shouldIgnoreGeoIds?: boolean;
}) {
    const filterKeys = Array.from(filters.getFilters().keys());

    const bathroomFilter = filters.get(Filters.bathroom) as BathroomFilter;
    const balconyFilter = filters.get(Filters.balcony) as BalconyFilter;
    const renovationFilter = filters.get(Filters.renovation) as RenovationFilter;
    const developerFilter = filters.get(Filters.developer) as DeveloperFilter;
    const closedSalesFilter = filters.get(Filters.closedSales) as ClosedSalesFilter;
    const buildingClassFilter = filters.get(Filters.buildingClass) as BuildingClassFilter;
    const parkingFilter = filters.get(Filters.parkings) as ParkingFilter;
    const wallsTypeFilter = filters.get(Filters.walls) as WallsTypeFilter;
    const infrastructureFilter = filters.get(Filters.infrastructure) as InfrastructureFilter;
    const safetyFilter = filters.get(Filters.safety) as SafetyFilter;
    const positionFilter = filters.get(Filters.position) as PositionFilter;
    const accreditationFilter = filters.get(Filters.accreditation) as AccreditationFilter;
    const newBuildingDealFilter = filters.get(Filters.newBuildingDeal) as NewBuildingDealFilter;
    const paymentTypeFilter = filters.get(Filters.paymentType) as PaymentTypeFilter;
    const livingTenantOptionFilter = filters.get(Filters.livingTenantOptions) as LivingTenantOptionFilter;
    const rentConditionsFilter = filters.get(Filters.rentConditions) as RentConditionsFilter;
    const furnitureFilter = filters.get(Filters.furniture) as FurnitureFilter;
    const mapBoundsFilter = filters.get(Filters.mapBounds) as MapBoundsFilter;
    const sellTypeFilter = filters.get(Filters.sellType) as SellTypeFilter;
    const buildingsFilter = filters.get(Filters.buildings) as BuildingsFilter;
    const authorFilter = filters.get(Filters.author) as AuthorFilter;
    const livingFacilityFilter = filters.get(Filters.livingFacility) as LivingFacilityFilter;
    const flatsSpecialEventsFilter = filters.get(Filters.flatsSpecialEvents) as FlatsSpecialEventsFilter;
    const otherSpecialEventsFilter = filters.get(Filters.otherSpecialEvents) as OtherSpecialEventsFilter;
    const miscSpecialEventsFilter = filters.get(Filters.miscSpecialEvents) as MiscSpecialEventsFilter;
    const buildEpochFilter = filters.get(Filters.buildEpoch) as BuildEpochFilter;
    const finishingFilter = filters.get(Filters.finishingType) as FinishingFilter;

    const tipsCollection: TipItem[] = [];

    for (const key of getExtraFilterIds(filters)) {
        if (
            key === Filters.timeLimit ||
            ! filterKeys.includes(key as any)
        ) {
            continue;
        }

        const filter = filters.get(key);

        if (! filter || filter.isDefault()) {
            continue;
        }

        const realtyTypeFilter = filters.get(Filters.categoryType) as CategoryTypeFilter;
        const dealTypeFilter = filters.get(Filters.dealType) as DealTypeFilter;

        switch (key) {
            case Filters.floorsTotal: {
                const title = getFloorsTotalTip(filter as FloorsTotalFilter);

                if (title !== null) {
                    tipsCollection.push({
                        id: Filters.floorsTotal,
                        title
                    });
                }
                break;
            }
            case Filters.rooms: {
                const title = getRoomsTips(filter as RoomFilter, realtyTypeFilter);

                const isRoomOrHome = (
                    realtyTypeFilter.categoryType === RealtyObjectType.ROOM ||
                    realtyTypeFilter.categoryType === RealtyObjectType.HOUSE
                );

                if (title !== null && isRoomOrHome) {
                    tipsCollection.push({
                        id: Filters.rooms,
                        title
                    });
                }

                break;
            }
            case Filters.roomsInOffer: {
                const roomsInOfferFilter = filters.get(Filters.roomsInOffer) as RoomsInOfferFilter;

                const title = getRoomsInOfferTips(roomsInOfferFilter, dealTypeFilter);

                if (title !== null && realtyTypeFilter.categoryType === RealtyObjectType.ROOM) {
                    tipsCollection.push({
                        id: Filters.roomsInOffer,
                        title
                    });
                }

                break;
            }
            case Filters.landStatus: {
                const title = getLandStatusTips(filter as LandStatusFilter);
                const isHome = realtyTypeFilter.categoryType === RealtyObjectType.HOUSE;

                if (title !== null && isHome) {
                    tipsCollection.push({
                        id: Filters.landStatus,
                        title
                    });
                }
                break;
            }
            case Filters.landArea: {
                const title = getLandAreaLabelTip(filter as LandAreaFilter);

                if (title !== null) {
                    tipsCollection.push({
                        id: Filters.landArea,
                        title
                    });
                }
                break;
            }
            case Filters.supplyOptions: {
                const title = getSupplyOptionsTip(filter as SupplyOptionsFilter);

                if (title !== null) {
                    tipsCollection.push({
                        id: Filters.supplyOptions,
                        title
                    });
                }
                break;
            }
            case Filters.houseArea: {
                const title = getHouseAreaLabelTip(filter as HouseAreaFilter);

                if (title !== null) {
                    tipsCollection.push({
                        title,
                        id: Filters.houseArea,
                        kind: Filters.houseArea
                    });
                }
                break;
            }
            case Filters.bathroomLocation: {
                const title = getBathroomLocationTip(filter as BathroomLocationFilter);

                if (title !== null) {
                    tipsCollection.push({
                        id: Filters.bathroomLocation,
                        title
                    });
                }
                break;
            }

            case Filters.heating: {
                const title = getHeatingTip(filter as HeatingFilter);

                if (title !== null) {
                    tipsCollection.push({
                        id: Filters.heating,
                        title
                    });
                }
                break;
            }

            case Filters.mapBounds: {
                let titleMapFilter = '';

                if (mapBoundsFilter.hasPolygon()) titleMapFilter = 'Выделенная область';
                if (mapBoundsFilter.bounds) titleMapFilter = 'Видимая область';
                if (mapBoundsFilter.geohash) titleMapFilter = 'Область на карте';

                tipsCollection.push({
                    id: Filters.mapBounds,
                    title: titleMapFilter
                });
                break;
            }

            case Filters.rentPrepayment: {
                const title = getPrepaymentTip(filter as RentPrepaymentFilter);

                if (title !== null) {
                    tipsCollection.push({
                        id: Filters.rentPrepayment,
                        title
                    });
                }
                break;
            }

            case Filters.rentConditions: {
                const options = getRentConditionsTip(filter as RentConditionsFilter);

                options.forEach(option => {
                    tipsCollection.push(option);
                });

                break;
            }

            case Filters.furniture: {
                const options = getFurnitureTip(filter as FurnitureFilter);

                options.forEach(option => {
                    tipsCollection.push(option);
                });

                break;
            }

            case Filters.finishingType: {
                const options = getFinishingTip(filter as FinishingFilter);

                options.forEach(option => {
                    tipsCollection.push(option);
                });

                break;
            }

            case Filters.commissioning: {
                const tip = getCommissioningTip(filter as CommissioningFilter);

                if (tip) {
                    tipsCollection.push(tip);
                }

                break;
            }

            case Filters.livingTenantOptions: {
                const options = getLivingTenantOptionsTip(filter as LivingTenantOptionFilter);

                options.forEach(option => {
                    tipsCollection.push(option);
                });

                break;
            }

            case Filters.author:
                for (const author of authorFilter.author) {
                    tipsCollection.push({
                        kind: Filters.author,
                        type: author,
                        id: Filters.author + String(author) as Id,
                        title: authorLabels[author]
                    });
                }

                break;
            case Filters.floor:
                let floorLabel = serializeToTips(filter.id, filter);

                if (floorLabel) {
                    const re = new RegExp(/^,/gm);

                    floorLabel = re.test(floorLabel) ?
                        floorLabel.toLowerCase().replace(re, 'Этаж: ') :
                        floorLabel.substr(0, 1) + floorLabel.substr(1).toLowerCase();

                    tipsCollection.push({
                        id: Filters.floor,
                        title: floorLabel
                    } as TipItem);
                }

                break;
            case Filters.transportLimit:
                const transportTypeLabel = serializeToTips(
                    Filters.transportLimit,
                    filters.get(Filters.transportLimit)
                );

                if (transportTypeLabel) {
                    tipsCollection.push({
                        id: Filters.transportLimit,
                        title: transportTypeLabel
                    });
                }

                break;
            case Filters.livingFacility:
                const facilities = livingFacilityFilter.facilities;

                for (const facility of facilities) {
                    const tip = getLivingFacilityTip(facility);

                    if (tip) {
                        tipsCollection.push({
                            id: Filters.livingFacility,
                            title: tip,
                            type: facility,
                            kind: Filters.livingFacility
                        });
                    }
                }

                break;
            case Filters.geo:
                const geoFilter = filters.get(Filters.geo) as GeoFilter;
                const newBuildings = queryDisplayValues?.newBuildings || {};

                for (const id of geoFilter.newBuildingIds) {
                    tipsCollection.push({
                        id: 'geo',
                        kind: 'newBuilding',
                        title: newBuildings[id] ?
                            newBuildings[id]?.title :
                            'Жилой комплекс',
                        geoId: id
                    });
                }

                [ ...new Set(geoFilter.addressIds) ].forEach(id => {
                    const geoObject = geoStore?.addresses.get(id);

                    if (geoObject) {
                        const geoName =
                            geoObject.displayName ||
                            geoObject.mainName;

                        tipsCollection.push({
                            id: 'geo',
                            kind: geoObject.kind,
                            title: geoName,
                            geoId: geoObject.id
                        });
                    } else if (queryDisplayValues?.addresses && queryDisplayValues?.addresses[id]) { // Поддержка двойственного поведения на сервисе
                        const addressObj = queryDisplayValues?.addresses[id];

                        tipsCollection.push({
                            id: 'geo',
                            kind: GeoKind.STREET,
                            title: addressObj && addressObj.title ? addressObj.title : 'Улица',
                            geoId: id
                        });
                    }
                });

                const idsSet = new Set(geoFilter.districtIds);
                const districtsGeo = geoStore?.districts.items;
                const districtGroups = districtsGeo ?
                    groupDistricts({
                        districts: districtsGeo.filter(item => item.id !== 390),
                        regionId: (filters.get(Filters.region) as RegionFilter).region
                    }).filter(group => group.items.every(item => idsSet.has(Number(item.id)))) :
                    [];

                for (const group of districtGroups) {
                    const districts: TipItemBase[] = [];

                    for (const item of group.items) {
                        const numberedId = Number(item.id);

                        districts.push({ id: numberedId });
                        idsSet.delete(numberedId);
                    }
                    tipsCollection.push({
                        id: `group-${group.title}`,
                        kind: 'district-group',
                        title: group.title,
                        districts
                    });
                }

                for (const id of Array.from(idsSet)) {
                    const geo = geoStore?.districts.get(id) ?? queryDisplayValues?.addresses?.[id];
                    let geoTitle = geo?.title ?? '';

                    // округ у Королёва оставляем, чтобы отличать от города Королёв в типсах
                    if (geoTitle.includes('Королёв') && geo?.kind === GeoKind.DISTRICT) geoTitle = geoTitle.trim();
                    else geoTitle = geoTitle.replace('городской округ', '').trim();

                    if (geo) {
                        tipsCollection.push({
                            id: 'geo',
                            kind: geo.kind,
                            title: geoTitle,
                            geoId: geo.id
                        });
                    } else if (queryDisplayValues?.districts && queryDisplayValues?.districts[id]) {
                        const districtObj = queryDisplayValues?.districts[id];

                        tipsCollection.push({
                            id: 'geo',
                            kind: GeoKind.DISTRICT,
                            title: districtObj && districtObj.title ? districtObj.title : 'Район',
                            geoId: id
                        });
                    }
                }

                const groupsAndStations = geoStore?.stations
                    .findRouteByStations([ ...new Set((filter as GeoFilter).metroIds) ]);

                if (groupsAndStations) {
                    const { groups, stations } = groupsAndStations;

                    if (shouldIgnoreGeoIds) {
                        for (const station of stations ?? []) {
                            tipsCollection.push(station);
                        }
                    }

                    if (groups.length) {
                        for (const group of groups) {
                            tipsCollection.push({
                                id: group.route.id,
                                kind: group.route.kind,
                                title: group.route.title,
                                stations: group.stations
                            });
                        }

                        if (! shouldIgnoreGeoIds) {
                            for (const station of stations ?? []) {
                                tipsCollection.push(station);
                            }
                        }
                    } else {
                        const geoTips = geoStore?.getByIds([ ...geoFilter.geoIds ]) as unknown as TipItem[];

                        for (const geoTip of geoTips) {
                            if (
                                geoTip.kind === GeoKind.METRO ||
                                geoTip.kind === 'METRO'
                            ) {
                                tipsCollection.push(geoTip);
                            }
                        }
                    }
                }

                break;
            case Filters.bathroom:
                for (const bathroomType of bathroomFilter.value) {
                    tipsCollection.push({
                        kind: Filters.bathroom,
                        type: bathroomType,
                        id: Filters.bathroom + String(bathroomType) as Id,
                        title: bathroomLabels[bathroomType]
                    });
                }

                break;
            case Filters.sellType:
                for (const sellType of sellTypeFilter.sellType) {
                    tipsCollection.push({
                        kind: Filters.sellType,
                        type: sellType,
                        id: Filters.sellType + String(sellType) as Id,
                        title: saleTypeLabels[sellType]
                    });
                }

                break;
            case Filters.balcony:
                if (balconyFilter.hasBalcony) {
                    tipsCollection.push({
                        kind: Filters.balcony,
                        type: balconyFilter.hasBalcony,
                        id: Filters.balcony + String(balconyFilter.hasBalcony) as Id,
                        title: balconyLabels[balconyFilter.hasBalcony]
                    });
                }

                break;
            case Filters.buildEpoch:
                for (const buildEpoch of buildEpochFilter.buildEpoch) {
                    tipsCollection.push({
                        kind: Filters.buildEpoch,
                        type: buildEpoch,
                        id: Filters.buildEpoch + String(buildEpoch) as Id,
                        title: buildEpochLabels[buildEpoch]
                    });
                }

                break;
            case Filters.renovation:
                for (const renovationType of renovationFilter.value) {
                    tipsCollection.push({
                        kind: Filters.renovation,
                        type: renovationType,
                        id: Filters.renovation + String(renovationType) as Id,
                        title: renovationLabels[renovationType]
                    });
                }

                break;
            case Filters.developer:
                const { developerId } = developerFilter;

                if (developerId) {
                    const developers = queryDisplayValues?.developers || {};

                    tipsCollection.push({
                        kind: Filters.developer,
                        id: Filters.developer + String(developerId) as Id,
                        title: developers[developerId] ?
                            `Застройщик ${developers[developerId].title}` :
                            'Застройщик'
                    });
                }

                break;
            case Filters.buildingClass:
                for (const buildingClass of buildingClassFilter.buildingClass) {
                    tipsCollection.push({
                        kind: Filters.buildingClass,
                        type: buildingClass,
                        id: Filters.buildingClass + String(buildingClass) as Id,
                        title: buildingClassLabels[buildingClass]
                    });
                }

                break;
            case Filters.parkings:
                for (const parking of parkingFilter.parkings) {
                    tipsCollection.push({
                        kind: Filters.parkings,
                        type: parking,
                        id: Filters.parkings + String(parking) as Id,
                        title: parkingLabels[parking]
                    });
                }

                break;
            case Filters.walls:
                for (const walls of wallsTypeFilter.walls) {
                    tipsCollection.push({
                        kind: Filters.walls,
                        type: walls,
                        id: Filters.walls + String(walls) as Id,
                        title: `Материал стен: ${wallsLabels[walls]}`
                    });
                }

                break;
            case Filters.infrastructure:
                for (const infrastructure of infrastructureFilter.infrastructure) {
                    tipsCollection.push({
                        kind: Filters.infrastructure,
                        type: infrastructure,
                        id: Filters.infrastructure + String(infrastructure) as Id,
                        title: infrastructureLabels[infrastructure]
                    });
                }

                if (infrastructureFilter.hasLift) {
                    tipsCollection.push({
                        kind: Filters.infrastructure,
                        id: 'hasLift' as Id,
                        title: 'Лифт'
                    });
                }

                break;
            case Filters.safety:
                for (const safety of safetyFilter.safety) {
                    tipsCollection.push({
                        kind: Filters.safety,
                        type: safety,
                        id: Filters.safety + String(safety) as Id,
                        title: safetyLabels[safety]
                    });
                }

                break;
            case Filters.position:
                for (const position of positionFilter.position) {
                    tipsCollection.push({
                        kind: Filters.position,
                        type: position,
                        id: Filters.position + String(positionFilter) as Id,
                        title: positionLabels[position]
                    });
                }

                break;
            case Filters.accreditation:
                for (const accreditation of accreditationFilter.accreditation) {
                    tipsCollection.push({
                        kind: Filters.accreditation,
                        type: accreditation,
                        id: Filters.accreditation + String(accreditation) as Id,
                        title: accreditationLabels[accreditation]
                    });
                }

                break;
            case Filters.newBuildingDeal:
                for (const deal of newBuildingDealFilter.deal) {
                    tipsCollection.push({
                        kind: Filters.newBuildingDeal,
                        type: deal,
                        id: Filters.newBuildingDeal + String(deal) as Id,
                        title: newBuildingDealLabels[deal]
                    });
                }

                break;
            case Filters.paymentType:
                for (const type of paymentTypeFilter.type) {
                    tipsCollection.push({
                        kind: Filters.paymentType,
                        type,
                        id: Filters.paymentType + String(type) as Id,
                        title: paymentTypeLabels[type]
                    });
                }

                break;
            case Filters.closedSales: {
                const label = serializeToTips(filter.id, filter);

                tipsCollection.push({
                    kind: Filters.closedSales,
                    type: closedSalesFilter.includingClosedSales,
                    id: Filters.closedSales,
                    title: label
                });
            }

                break;
            case Filters.buildings:
                const { buildings: buildingIds } = buildingsFilter;

                if (buildingIds.length) {
                    const buildings = queryDisplayValues?.buildings || {};

                    for (const buildingId of buildingIds) {
                        tipsCollection.push({
                            kind: Filters.buildings,
                            type: buildingId,
                            id: `${Filters.buildings}${buildingId}` as Id,
                            title: buildings[buildingId]?.title || 'Корпус'
                        });
                    }
                }

                break;
            case Filters.windowsDirection: {
                if ((filter as WindowsDirectionFilter).value.length) {
                    const title = getWindowsDirectionLabel(filter as WindowsDirectionFilter);

                    tipsCollection.push({
                        title,
                        id: Filters.windowsDirection
                    });
                }

                break;
            }
            case Filters.flatsSpecialEvents:
                for (const value of flatsSpecialEventsFilter.value) {
                    tipsCollection.push({
                        kind: Filters.flatsSpecialEvents,
                        type: value,
                        id: Filters.flatsSpecialEvents + String(value) as Id,
                        title: `Квартиры: ${flatsSpecialEventsLabels[value]}`
                    });
                }

                break;
            case Filters.otherSpecialEvents:
                for (const value of otherSpecialEventsFilter.value) {
                    tipsCollection.push({
                        kind: Filters.otherSpecialEvents,
                        type: value,
                        id: Filters.otherSpecialEvents + String(value) as Id,
                        title: `Паркинг, кладовки: ${otherSpecialEventsLabels[value]}`
                    });
                }

                break;
            case Filters.miscSpecialEvents:
                for (const value of miscSpecialEventsFilter.value) {
                    tipsCollection.push({
                        kind: Filters.miscSpecialEvents,
                        type: value,
                        id: Filters.miscSpecialEvents + String(value) as Id,
                        title: miscSpecialEventsLabels[value]
                    });
                }

                break;
            default:
                const label = serializeToTips(filter.id, filter);

                if (label) {
                    tipsCollection.push({
                        id: key,
                        kind: key,
                        title: label
                    } as TipItem);
                }
        }
    }

    return {
        tipsCollection,
        bathroomFilter,
        renovationFilter,
        buildingClassFilter,
        parkingFilter,
        wallsTypeFilter,
        infrastructureFilter,
        safetyFilter,
        positionFilter,
        accreditationFilter,
        newBuildingDealFilter,
        paymentTypeFilter,
        livingTenantOptionFilter,
        rentConditionsFilter,
        furnitureFilter,
        sellTypeFilter,
        buildingsFilter,
        authorFilter,
        livingFacilityFilter,
        flatsSpecialEventsFilter,
        otherSpecialEventsFilter,
        miscSpecialEventsFilter,
        buildEpochFilter,
        finishingFilter
    };
}

export const OffersSearchTips = ({
    reset,
    loading,
    filters,
    updateFilter,
    removeFilter,
    expandFilter,
    removeGeo,
    customFilterBehavior,
    onUpdateLength = () => {},
    onWhiteBackground = true,
    classNamesNewFilters,
    tipsExpanded,
    openMortgagePaymentSearchModal,
    ...props
}: IProps) => {
    const [ tipsIndex, setTipsIndex ] = useState(0);
    const [ expandButton, setExpandButton ] = useState(false);
    const [ expandTipsMode, setExpandTipsMode ] = useState(false);
    const {
        tipsCollection,
        bathroomFilter,
        renovationFilter,
        buildingClassFilter,
        parkingFilter,
        wallsTypeFilter,
        infrastructureFilter,
        safetyFilter,
        positionFilter,
        accreditationFilter,
        newBuildingDealFilter,
        paymentTypeFilter,
        livingTenantOptionFilter,
        rentConditionsFilter,
        furnitureFilter,
        sellTypeFilter,
        buildingsFilter,
        authorFilter,
        livingFacilityFilter,
        flatsSpecialEventsFilter,
        otherSpecialEventsFilter,
        miscSpecialEventsFilter,
        buildEpochFilter,
        finishingFilter
    } = props.tips ?? getTipsCollection({
        shouldIgnoreGeoIds: props.shouldIgnoreGeoIds,
        geoStore: props.geoStore,
        filters,
        queryDisplayValues: props.queryDisplayValues
    });

    const handleRemoveTip = (tip: TipItem) => {
        if (tip.id === 'geo') {
            removeGeo?.([ tip ]);

            return;
        }

        switch (tip.kind) {
            case GeoKind.ROUTE:
                removeGeo?.(tip.stations);

                break;
            case GeoKind.METRO:
                removeGeo?.([ tip ]);

                break;

            case 'district-group':
                removeGeo?.(tip.districts);
                break;

            case GeoKind.DISTRICT:
                removeGeo?.([ tip ]);

                break;

            case Filters.livingFacility:
                const { facilities: livingFacilities } = livingFacilityFilter;

                const index = livingFacilities.indexOf(tip.type);

                if (index > -1) {
                    livingFacilities.splice(index, 1);
                }

                updateFilter(
                    new LivingFacilityFilter(livingFacilities)
                );
                break;
            case Filters.rooms:
                updateFilter(
                    new RoomFilter()
                );
                break;
            case Filters.roomsInOffer:
                updateFilter(
                    new RoomsInOfferFilter()
                );
                break;
            case Filters.landStatus:
                updateFilter(
                    new LandStatusFilter()
                );
                break;
            case Filters.landArea:
                updateFilter(
                    new LandAreaFilter()
                );
                break;
            case Filters.supplyOptions:
                updateFilter(
                    new SupplyOptionsFilter()
                );
                break;
            case Filters.houseArea:
                updateFilter(
                    new HouseAreaFilter()
                );
                break;
            case Filters.bathroomLocation:
                updateFilter(
                    new BathroomLocationFilter()
                );
                break;
            case Filters.heating:
                updateFilter(
                    new HeatingFilter()
                );
                break;
            case Filters.bathroom:
                updateFilter(
                    new BathroomFilter(bathroomFilter.value.filter(item => item !== tip.type))
                );

                break;
            case Filters.sellType:
                updateFilter(
                    new SellTypeFilter(sellTypeFilter.sellType.filter(item => item !== tip.type))
                );

                break;
            case Filters.author:
                updateFilter(new AuthorFilter(authorFilter.author.filter(item => item !== tip.type)));

                break;

            case Filters.buildEpoch:
                updateFilter(new BuildEpochFilter(buildEpochFilter.buildEpoch?.filter(item => item !== tip.type)) ?? []);

                break;

            case Filters.balcony:
                updateFilter(new BalconyFilter());

                break;
            case Filters.livingTenantOptions:
                updateFilter(
                    new LivingTenantOptionFilter(
                        livingTenantOptionFilter.livingTenantOptions
                            .filter(item => item !== tip.type)
                    )
                );

                break;
            case Filters.rentConditions: {
                // eslint-disable-next-line max-len
                const utilitiesIncluded = tip.type === 'utilitiesIncluded' ? false : rentConditionsFilter.utilitiesIncluded;
                const depositType = tip.type === 'depositType' ? DepositType.UNKNOWN : rentConditionsFilter.depositType;
                // eslint-disable-next-line max-len
                const commisionType = tip.type === 'commisionType' ? CommissionType.UNKNOWN : rentConditionsFilter.commisionType;

                updateFilter(
                    new RentConditionsFilter(
                        utilitiesIncluded,
                        depositType,
                        commisionType
                    )
                );

                break;
            }

            case Filters.furniture: {
                updateFilter(
                    new FurnitureFilter(
                        furnitureFilter.furnitures.filter(item => item !== tip.type)
                    )
                );
                break;
            }

            case Filters.finishingType: {
                updateFilter(
                    new FinishingFilter(
                        finishingFilter.finishingType.filter(item => item !== tip.type)
                    )
                );
                break;
            }

            case Filters.commissioning: {
                updateFilter(new CommissioningFilter(Commissioning.UNKNOWN));
                break;
            }

            case Filters.renovation:
                updateFilter(
                    new RenovationFilter(renovationFilter.value.filter(item => item !== tip.type))
                );

                break;
            case Filters.developer:
                if (customFilterBehavior && customFilterBehavior.developer) {
                    customFilterBehavior.developer.reset();
                } else {
                    updateFilter(new DeveloperFilter());
                }

                break;
            case Filters.buildingClass:
                updateFilter(
                    new BuildingClassFilter(buildingClassFilter.buildingClass.filter(item => item !== tip.type))
                );

                break;
            case Filters.parkings:
                updateFilter(
                    new ParkingFilter(parkingFilter.parkings.filter(item => item !== tip.type))
                );

                break;
            case Filters.walls:
                updateFilter(
                    new WallsTypeFilter(wallsTypeFilter.walls.filter(item => item !== tip.type))
                );

                break;
            case Filters.infrastructure: {
                const newFilter = tip?.id === Filters.hasLift ?
                    new InfrastructureFilter(infrastructureFilter.infrastructure) :
                    new InfrastructureFilter(infrastructureFilter.infrastructure.filter(item => item !== tip.type), infrastructureFilter.hasLift);

                updateFilter(newFilter);

                break;
            }
            case Filters.safety:
                updateFilter(
                    new SafetyFilter(safetyFilter.safety.filter(item => item !== tip.type))
                );

                break;
            case Filters.position:
                updateFilter(
                    new PositionFilter(positionFilter.position.filter(item => item !== tip.type))
                );

                break;
            case Filters.accreditation:
                updateFilter(
                    new AccreditationFilter(accreditationFilter.accreditation.filter(item => item !== tip.type))
                );

                break;
            case Filters.newBuildingDeal:
                updateFilter(
                    new NewBuildingDealFilter(newBuildingDealFilter.deal.filter(item => item !== tip.type))
                );

                break;
            case Filters.paymentType:
                updateFilter(
                    new PaymentTypeFilter(paymentTypeFilter.type.filter(item => item !== tip.type))
                );

                break;
            case Filters.closedSales:
                updateFilter(
                    new ClosedSalesFilter()
                );

                break;
            case Filters.buildings: {
                updateFilter(
                    new BuildingsFilter(
                        buildingsFilter.buildings.filter(item => item !== tip.type)
                    )
                );

                break;
            }
            case Filters.flatsSpecialEvents:
                updateFilter(
                    new FlatsSpecialEventsFilter(flatsSpecialEventsFilter.value.filter(item => item !== tip.type))
                );

                break;
            case Filters.otherSpecialEvents: {
                updateFilter(
                    new OtherSpecialEventsFilter(otherSpecialEventsFilter.value.filter(item => item !== tip.type))
                );

                break;
            }
            case Filters.miscSpecialEvents: {
                updateFilter(
                    new MiscSpecialEventsFilter(miscSpecialEventsFilter.value.filter(item => item !== tip.type))
                );

                break;
            }
            default:
                removeFilter(filters.get(Filters[tip.id]));
        }
    };
    const expandTipsToggle = () => {
        setExpandTipsMode(prev => ! prev);
    };
    const getTipsIndexInfo = () => {
        const container = document.getElementsByClassName(TIP_CLASS); // eslint-disable-line
        const result = {
            index: 0,
            size: container.length,
            state: false,
            line: 0,
            arr: []
        };
        let position = 0;

        for (let i = 0; i < container.length; i++) {
            const x: number = container[i].getBoundingClientRect().left;

            if (x < position) {
                result.index = i;
                result.state = true;
                break;
            }
            result.line++;
            position = x;
        }

        return result;
    };

    useEffect(() => {
        const info = getTipsIndexInfo();

        setTipsIndex(info.line);
        if (info.state) {
            setExpandButton(true);
        } else {
            setExpandButton(false);
            setExpandTipsMode(false);
        }

        const value = tipsCollection.length - tipsIndex;

        if (value < 0) {
            setExpandTipsMode(false);
        }
    }, [ tipsCollection, expandButton, tipsIndex ]);

    useEffect(() => {
        if (tipsExpanded === false) setExpandTipsMode(false);
    }, [ tipsExpanded ]);

    useEffect(() => {
        onUpdateLength(tipsCollection.length);
    }, [ tipsCollection.length, onUpdateLength ]);

    useEffect(() => {
        expandFilter && expandFilter(expandTipsMode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ expandTipsMode ]);

    return (
        <Fragment>
            {
                (expandTipsMode) ? (
                    <div className={cn('all-tips', undefined, classNamesNewFilters?.allTips)}>
                        <FiltersFormTips>
                            {
                                tipsCollection.map((tip, index) => (<Tip
                                    enabled={! loading}
                                    key={index + '_' + tip.id}
                                    tip={tip}
                                    removeTip={handleRemoveTip}
                                    onWhiteBackground={onWhiteBackground}
                                    openMortgagePaymentSearchModal={openMortgagePaymentSearchModal}
                                />))
                            }
                        </FiltersFormTips>
                    </div>
                ) : null
            }
            {
                (! expandTipsMode) ? (
                    <div className={cn('first-line-tips-only', undefined, classNamesNewFilters?.firstLineTipsOnly)}>
                        <FiltersFormTips>
                            {
                                tipsCollection.map((tip, index) => (<Tip
                                    enabled={! loading}
                                    key={index + '_' + tip.id}
                                    tip={tip}
                                    removeTip={handleRemoveTip}
                                    onWhiteBackground={onWhiteBackground}
                                    openMortgagePaymentSearchModal={openMortgagePaymentSearchModal}
                                />))
                            }
                        </FiltersFormTips>
                    </div>
                ) : null
            }
            <div
                className={cn('tipButtons', undefined, classNamesNewFilters?.tipsButtons)}
                data-test='filter-tipButtons'
            >
                {
                    reset && tipsCollection.length >= MIN_TIPS_COUNT ? (
                        <TipButton
                            onClick={reset}
                            className={cn('resetTipButton', { hiddenExpandButton: ! expandButton })}
                            type='discard'
                            onWhiteBackground={onWhiteBackground}
                        >
                            <Typography
                                variant='secondary-alone'
                                className={cn('resetButton')}
                            >
                                Сбросить
                            </Typography>
                        </TipButton>
                    ) : null
                }
                {
                    expandButton ? (
                        <TipButton
                            onClick={expandTipsToggle}
                            type='expand'
                            onWhiteBackground={onWhiteBackground}
                        >
                            <Typography
                                variant='secondary-alone'
                                className={cnButton('more')}
                            >
                                {`Ещё ${
                                    tipsCollection.length - tipsIndex > 0 ?
                                        tipsCollection.length - tipsIndex :
                                        ''
                                } `}
                                <Icon
                                    name={expandTipsMode ? 'chevron-up' : 'chevron-down'}
                                    size='s'
                                />
                            </Typography>
                        </TipButton>
                    ) : null
                }
            </div>
        </Fragment>
    );
};

function TipButton({
    onClick,
    children,
    type,
    onWhiteBackground: onWhite,
    className
}: {
    onClick: () => void;
    children: React.ReactChild;
    type: string;
    onWhiteBackground: boolean;
    className?: string;
}) {
    return (
        <button
            className={cnButton(type, { onWhite }, className)}
            onClick={onClick}
        >
            {children}
        </button>
    );
}
