import { IFilter, Filters } from '../../../view/filters/models/Filter';
import { NewbuildingFilterCollection } from './NewbuildingFilterCollection';

class NewbuildingFilterFactory {
    create(
        filters: Map<Filters, IFilter> = new Map<Filters, IFilter>()
    ): NewbuildingFilterCollection {
        return new NewbuildingFilterCollection(
            new Map<Filters, IFilter>(filters.entries())
        );
    }
}

export const newbuildingFilterFactory = new NewbuildingFilterFactory();
