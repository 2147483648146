import './styles.css';

import React, { FC, PropsWithChildren } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { Typography } from '@vtblife/uikit';

import classname from '@search/classname/src';
import { NewBuildingPoi } from '@search/graphql-typings';
import { YMapPlacemarkPOI, YMapPlacemarkPriority } from '@search/ymap/src/Placemark';

import { CoreSvgSpriteIcon } from '../../CoreSvgSpriteIcon/CoreSvgSpriteIcon';
import { PoiKindToIconName, PoiKindToImageName, PoiImageName } from './dict/poi';

const cn = classname.bind(null, 'CorePoiHint');

const PoiHintWrapper = ({ children }: PropsWithChildren<{}>) => (
    <div className={cn()}>
        {children}
    </div>
);

export const CorePoiHint: FC<
    Omit<NewBuildingPoi, 'latitude' | 'longitude'>
> = ({
    name,
    kind,
    description,
    address,
    specialization,
    noctidial,
    category
}) => {
    const iconName = PoiKindToImageName[kind];

    if (! iconName) {
        return null;
    }

    return (<>
        <div className={cn('icon')}>
            <CoreSvgSpriteIcon<PoiImageName>
                height={52}
                width={52}
                name={iconName}
            />
        </div>
        {
            name && (
                <Typography
                    bold
                    variant='secondary-alone'
                    className={cn('name')}
                >
                    {name}
                </Typography>
            )
        }
        {
            category && category !== name && (
                <Typography
                    variant='small-alone'
                    color='secondary'
                    className={cn('category')}
                >
                    {category}
                </Typography>
            )
        }
        {
            address || noctidial || specialization || description ? (
                <div className={cn('separator')} />
            ) : null
        }
        {
            address && (
                <Typography
                    variant='small-alone'
                    className={cn('address')}
                >
                    {address}
                </Typography>
            )
        }
        {
            noctidial && (
                <Typography
                    variant='small-alone'
                    className={cn('noctidial')}
                >
                    Круглосуточно
                </Typography>
            )
        }
        {
            specialization && (
                <Typography
                    variant='small-alone'
                    color='secondary'
                    className={cn('specialization')}
                >
                    {specialization}
                </Typography>
            )
        }
        {
            description && (
                <Typography
                    variant='small-alone'
                    color='secondary'
                    className={cn('description')}
                >
                    {description}
                </Typography>
            )
        }
    </>);
};

export const YMapPOI: FC<NewBuildingPoi & {
    onClick?: () => void;
    isSelected?: boolean;
    layerPriority?: YMapPlacemarkPriority;
}> = ({
    latitude,
    longitude,
    name,
    kind,
    description,
    address,
    specialization,
    noctidial,
    category,
    onClick,
    isSelected,
    layerPriority
}) => {
    const iconName = PoiKindToIconName[kind];

    const hint = (
        <PoiHintWrapper>
            <CorePoiHint
                name={name}
                kind={kind}
                description={description}
                address={address}
                specialization={specialization}
                noctidial={noctidial}
                category={category}
            />
        </PoiHintWrapper>
    );

    return (
        iconName ? (
            <YMapPlacemarkPOI
                coordinates={{ y: latitude, x: longitude }}
                iconName={iconName}
                hintLayout={renderToStaticMarkup(hint)}
                onClick={onClick}
                isSelected={isSelected}
                layerPriority={layerPriority}
            />
        ) : null
    );
};
