import React, { useCallback } from 'react';
import { IsApartmentsOptions } from '@search/filter-enums/enums';
import { ApartmentFilter } from '../../../../models/Filter';
import MobileFilter from '../../../filter/MobileFilter';
import '../MobileCommon.css';
import { RadioSelect, RadioOption } from '../../common/RadioSelect';

interface IMobileApartmentFilterViewProps {
    filter: ApartmentFilter;
    handleChange: (filter: ApartmentFilter) => void;
}

const selectOptions: RadioOption[] = [ {
    value: IsApartmentsOptions.APARTMENTS_ONLY,
    label: 'Апартаменты',
    className: 'col-xs-12 filter-item-gap'
},
{
    value: IsApartmentsOptions.NO_APARTMENTS,
    label: 'Без апартаментов',
    className: 'col-xs-12 filter-item-gap'
} ];

export const MobileApartmentFilterView: React.FC<IMobileApartmentFilterViewProps> = ({
    filter,
    handleChange
}) => {
    const onChange = useCallback(value => {
        value = (value === null) ? IsApartmentsOptions.UNKNOWN : value;
        handleChange(new ApartmentFilter(value));
    }, [ handleChange ]);

    return (
        <MobileFilter
            filterTitle='Апартаменты'
        >
            <RadioSelect
                allowNull
                name='apartment'
                className='container container__clamp'
                options={selectOptions}
                value={filter.isApartment || null}
                handleChange={onChange}
            />
        </MobileFilter>
    );
};
