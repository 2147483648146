import React, { MouseEvent, useCallback } from 'react';
import {
    SuggestItemDeveloper
} from '@search/graphql-typings';
import { Input, Size } from '@vtblife/uikit/legacy';
import { useModal } from '@search/vtbeco-ui/src/components/Modal';

import MobileFilter from '../../../filter/MobileFilter';

import MobileDeveloperSuggestModal from './MobileDeveloperSuggestModal';

import './MobileDeveloperFilterView.css';

export interface IProps {
    onChange: (value?: SuggestItemDeveloper) => void;
    value?: SuggestItemDeveloper;
    placeholder?: string;
    uikitModalZIndexEnabled?: boolean;
}

export const MobileDeveloperFilterView: React.FC<IProps> = ({
    value,
    onChange,
    placeholder = 'Название компании',
    uikitModalZIndexEnabled
}) => {
    const { on: showSuggest, off: hideSuggest, visible: isSuggestVisible } = useModal();

    const handleSuggestOpen = useCallback(() => showSuggest(), [ showSuggest ]);

    const handleClear = useCallback((e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();

        onChange();
    }, [ onChange ]);

    return (
        <MobileFilter filterTitle='Застройщик'>
            <div
                className={'container'}
                onClick={handleSuggestOpen}
            >
                <div className={'col-xs-12 MobileDeveloperFilterView__input'}>
                    <Input
                        hasClear
                        fullWidth
                        placeholder={placeholder}
                        value={value?.label}
                        size={Size.Small}
                    />
                    {
                        value?.label ? (
                            <div
                                className='MobileDeveloperFilterView__clear'
                                onClick={handleClear}
                            />
                        ) : null
                    }
                </div>
            </div>
            {
                isSuggestVisible ? (
                    <MobileDeveloperSuggestModal
                        hide={hideSuggest}
                        value={value}
                        onChange={onChange}
                        placeholder={placeholder}
                        uikitZIndexEnabled={uikitModalZIndexEnabled}
                    />
                ) : null
            }
        </MobileFilter>
    );
};
