import React, { Ref } from 'react';

import { Typography } from '@vtblife/uikit';
import Icon from '@vtblife/uikit-icons';

import classname from '@search/classname/src';

import type { ICustomerFeedbackOpenButton } from '../index';

import './styles.css';

const cn = classname.bind(null, 'CustomerFeedbackOpenButtonDefault');

export const OpenButtonDefault = React.forwardRef(({
    isMobile,
    isSticky,
    handleCloseOpenBtn,
    handleOpen
}: Pick<ICustomerFeedbackOpenButton, 'isSticky' | 'handleCloseOpenBtn' | 'handleOpen'> & { isMobile: boolean }, ref: Ref<HTMLButtonElement>) => (
    <button ref={ref} className={cn(null, { isMobile, isSticky })} onClick={handleOpen}>
        <span className={cn('left-section', { isMobile })}>
            <Icon name='m2-star' color='#FAC32D' size='m' />
            <Typography
                color='blue-500'
                variant='primary'
                bold={isMobile}
            >
                Оцените нас...
            </Typography>
        </span>
        <span className={cn('close', { isMobile })} onClick={handleCloseOpenBtn}>
            <Icon name='times' color='#B6B9C9' size='s' />
        </span>
    </button>
));
