export enum ModeEnum {
    RADIO = 'radio',
    RADIO_CHECK = 'radio-check',
    CHECK = 'check'
}

export enum InputModeEnum {
    number = 'number'
}

export type Mode = 'radio' | 'radio-check' | 'check';
