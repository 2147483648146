import classname from '@search/classname/src';

import { graphql } from '@search/gql-client/src';

export type {
    NewBuildingPromoBannerQuery,
    NewBuildingPromoBannerQuery$variables as NewBuildingPromoBannerQueryVariables,
    NewBuildingPromoBannerQuery$data as NewBuildingPromoBannerQueryResponse
} from './__generated__/NewBuildingPromoBannerQuery.graphql';

export const cn = classname.bind(null, 'NewBuildingPromoBanner');

export const query = graphql`
    query NewBuildingPromoBannerQuery($id: Int!) {
        newBuilding(id: $id) {
            gallery {
                main {
                    medium
                    large
                }
                images(limit: 1) {
                    medium
                }
            }
            title {
                nominative
            }
            priceMin(promo: true)
            routeParams {
                id
                name
                type
                region
                regionId
                subRegion
            }
            routes(type: [ METRO ]) {
                ...on RouteMetro {
                    station {
                        name
                    }
                }
            }
        }
    }
`;

export interface IProps {
    view: 'offer' | 'nb';
    id: number;
    disclaimerList: ReadonlyArray<string>;
    developerName: string;
    developerLogo?: string;
    dataGTM?: {
        click?: string;
        phoneClick?: string;
    };
}
