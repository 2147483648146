import React from 'react';

import IconPercent from '@search/vtbeco-ui/src/components/controls/IconPercent';
import classname from '@search/classname/src';

import { Badge, IBadgeProps } from '..';

import './styles.css';

const cn = classname.bind(null, 'PreferentialMortgageBadge');

interface IPreferentialMortgageBadge extends IBadgeProps {
    variant: 'default' | 'vtb';
}

export const PreferentialMortgageBadge = ({
    size,
    variant,
    ...props
}: IPreferentialMortgageBadge) => {
    return (
        <Badge
            size={size}
            className={cn(null, { variant })}
            textClassName={cn('text')}
            before={<IconPercent />}
            {...props}
        >
            Ипотека 6,5%
        </Badge>
    );
};
