import React, { useCallback } from 'react';
import CheckboxGroup from '@search/vtbeco-ui/src/components/controls/CheckboxGroup';
import Checkbox from '@search/vtbeco-ui/src/components/controls/Checkbox';

export type RadioValue = string | number | null | undefined;

export interface RadioOption {
    label: string;
    value: RadioValue;
    className?: string;
    disabled?: boolean;
}

interface RadioSelectProps {
    value: RadioValue | null;
    name: string;
    className?: string;
    options: RadioOption[];
    allowNull?: boolean;
    handleChange: (value: RadioValue, oldValue: RadioValue) => void;
}

export const RadioSelect: React.FC<RadioSelectProps> = ({
    value,
    name,
    className,
    options,
    allowNull = false,
    handleChange: onChange
}) => {
    const handleChange = useCallback((newValue: string[] | string | number | null) => {
        if (! allowNull && newValue === null) {
            return;
        }

        onChange(newValue as RadioValue, value);
    }, [ onChange, value ]);
    const component =
        options.map(({
            label,
            value: optionValue,
            className: optionClassname,
            disabled
        }) => {
            return (
                <Checkbox
                    className={optionClassname}
                    width='max'
                    disabled={disabled}
                    type='button'
                    view='primary'
                    tone='secondary'
                    // @ts-ignore
                    key={optionValue}
                    // @ts-ignore
                    value={optionValue}
                >
                    {label}
                </Checkbox>
            );
        });

    return (
        <CheckboxGroup
            className={className}
            mode='radio-check'
            onChange={handleChange}
            name={name}
            // @ts-ignore
            value={[ value ]}>
            {component}
        </CheckboxGroup>
    );
};
