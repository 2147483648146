import React, { RefObject, useCallback } from 'react';

import classname from '@search/classname/src';
import useControl from '@search/hooks/src/useControl';
import Button from '../Button';
import {
    Size,
    View,
    Tone,
    SizeEnum,
    ViewEnum,
    ToneEnum,
    Maybe
} from '../../../types';

import { ICheckboxControlProps, CheckboxControl } from './CheckboxControl/CheckboxControl';

import './Checkbox.css';

export type CheckboxControlProps = Omit<ICheckboxControlProps, 'size'>;

export interface ICheckboxProps extends CheckboxControlProps {
    /**
     * Ссылка на корневой DOM-элемент компонента.
     */
    innerRef?: RefObject<HTMLLabelElement>;

    /**
     * Ссылка на DOM элемент нативного инпута.
     */
    controlRef?: RefObject<HTMLInputElement>;

    /**
     * Вид элемента
     */
    view?: View;

    /**
     * Цветовое решение
     */
    tone?: Tone;

    /**
     * Размер контрола
     */
    size?: Size;

    /**
     * Состояние переключателя: включен или выключен
     */
    checked?: boolean;

    /**
     * Тип отображения
     */
    type?: 'button' | 'checkbox';

    square?: boolean;

    onCheck?: (
        checked: boolean,
        props: { name: Maybe<string>; value: Maybe<string | number | readonly string[]>;
    }) => void;
}

export const cnCheckbox = classname.bind(null, 'Checkbox');

const Checkbox = ({
    name,
    type,
    square = false,
    className,
    value = '',
    innerRef,
    checked = false,
    disabled = false,
    autoComplete = 'off',
    size = SizeEnum.S,
    view = ViewEnum.PRIMARY,
    tone = ToneEnum.PRIMARY,
    ...props
}: ICheckboxProps): React.ReactElement => {
    const [ buttonState, controlHandlers ] = useControl({ disabled, focused: false }, props, { disabled });

    const handleChange = useCallback(() => {
        if (disabled) {
            return;
        }

        // eslint-disable-next-line
        props.onCheck && props.onCheck(! checked, { name, value });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ name, value, disabled, checked, props.onCheck ]);

    const handleButtonClick = useCallback(() => {
        // eslint-disable-next-line
        props.onCheck && props.onCheck(! checked, { name, value });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ name, value, disabled, checked, props.onCheck ]);

    if (type === 'button') {
        return (
            <label
                ref={innerRef}
                className={cnCheckbox(null, { ...buttonState, checked, tone, size, view, type }, className)}>
                {checked &&
                // eslint-disable-next-line
                <input type='hidden' name={name} value={value} disabled={disabled}/>
                }
                <Button
                    type='button'
                    width='max'
                    name={name}
                    size={size}
                    view={view}
                    tone={tone}
                    square={square}
                    disabled={disabled}
                    checked={checked}
                    onFocus={controlHandlers.handleFocus}
                    onMouseUp={controlHandlers.handleMouseUp}
                    onMouseDown={controlHandlers.handleMouseDown}
                    onMouseLeave={controlHandlers.handleMouseLeave}
                    onMouseEnter={controlHandlers.handleMouseEnter}
                    onClick={handleButtonClick}>
                    {props.children}
                </Button>
            </label>

        );
    }

    return (
        <label
            htmlFor={name}
            ref={innerRef}
            onMouseUp={controlHandlers.handleMouseUp}
            onMouseDown={controlHandlers.handleMouseDown}
            onMouseEnter={controlHandlers.handleMouseEnter}
            onMouseLeave={controlHandlers.handleMouseLeave}
            className={cnCheckbox(null, { ...buttonState, disabled, checked, tone, size, view }, className)}>
            <span className={cnCheckbox('box')}>
                <CheckboxControl
                    id={name}
                    name={name}
                    value={value}
                    controlRef={props.controlRef}
                    checked={checked}
                    disabled={disabled}
                    onChange={handleChange}
                    autoComplete={autoComplete}
                />
                <span className={cnCheckbox('tick')} />
            </span>
            <span
                role='presentation'
                className={cnCheckbox('text')}>
                {props.children}
            </span>
        </label>
    );
};

export default Checkbox;
