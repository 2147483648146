import React, { useCallback } from 'react';
import { Rooms } from '@search/filter-enums/enums';

import { RoomFilter } from '../../../../../filters/models/Filter';
import MobileFilter from '../../../filter/MobileFilter';
import '../MobileCommon.css';
import { MultiSelect, MultiSelectOption } from '../../common/MultiSelect';

interface IMobileRoomFilterViewProps {
    filter: RoomFilter;
    handleChange: (filter: RoomFilter) => void;
}

const selectOptions: MultiSelectOption[] = [ {
    value: Rooms.STUDIO,
    label: 'Студия',
    className: 'col-xs-6 filter-item-gap'
},
{
    value: Rooms.ROOM_1,
    label: '1',
    className: 'col-xs-3 filter-item-gap'
},
{
    value: Rooms.ROOM_2,
    label: '2',
    className: 'col-xs-3 filter-item-gap'
},
{
    value: Rooms.ROOM_3,
    label: '3',
    className: 'col-xs-3 filter-item-gap'
},
{
    value: Rooms.ROOM_4,
    label: '4',
    className: 'col-xs-3 filter-item-gap'
},
{
    value: Rooms.ROOM_5_AND_MORE,
    label: '5 и более',
    className: 'col-xs-6 filter-item-gap'
},
{
    value: Rooms.OPEN_PLAN,
    label: 'Свободная планировка',
    className: 'col-xs-12 filter-item-gap'
} ];

export const MobileRoomFilterView: React.FC<IMobileRoomFilterViewProps> = ({
    filter,
    handleChange
}) => {
    const onChange = useCallback(value => {
        handleChange(new RoomFilter(value));
    }, [ handleChange ]);

    return (
        <MobileFilter
            filterTitle='Количество комнат'
        >
            <MultiSelect
                name='rooms'
                className='container container__clamp'
                options={selectOptions}
                value={filter.rooms}
                handleChange={onChange}
            />
        </MobileFilter>
    );
};
