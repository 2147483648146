import React from 'react';

import { Typography } from '@vtblife/uikit';
import { Size } from '@vtblife/uikit/legacy';
import Icon from '@vtblife/uikit-icons';

import classname from '@search/classname/src';

import './styles.css';

const cn = classname.bind(null, 'FilterCollapser');

export function FilterCollapser({
    activeCount,
    collapsed,
    reset: resetRaw,
    toggle
}: {
    toggle: () => void;
    reset: () => void;
    activeCount: number;
    collapsed: boolean;
}) {
    const reset = React.useCallback((e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        resetRaw();
    }, [ resetRaw ]);

    return (
        <div
            onClick={toggle}
            className={cn()}
            data-test='filter-toggle'
        >
            <div className={cn('inner')}>
                <Typography variant='primary'>Фильтры</Typography>
                {
                    activeCount ? (
                        <>
                            <div className={cn('counter')}>{activeCount}</div>
                            <Typography
                                tag='span'
                                variant='primary'
                                onClick={reset}
                                className={cn('reset')}
                                data-test='filter-reset'
                            >
                                Сбросить
                            </Typography>
                        </>
                    ) : null
                }
            </div>
            <div className={cn('toggle')}>
                <Icon
                    name={collapsed ? 'chevron-down' : 'chevron-up'}
                    size={Size.Small}
                />
            </div>
        </div>
    );
}

export function useFilterCollapser({
    reset,
    initCollapsed = false,
    clearSelected
}: {
    clearSelected: () => void;
    initCollapsed?: boolean;
    reset?: () => void;
}) {
    const [ collapsed, setCollapsed ] = React.useState<boolean>(initCollapsed);

    const toggleCollapsed = React.useCallback(() => {
        setCollapsed(prevState => ! prevState);
        clearSelected();
    }, [ setCollapsed, clearSelected ]);

    const handleReset = React.useCallback(() => {
        setCollapsed(false);
        clearSelected();
        reset && reset();
    }, [ reset, setCollapsed, clearSelected ]);

    const close = React.useCallback(
        () => setCollapsed(true),
        [ setCollapsed ]
    );

    return {
        reset: handleReset,
        collapsed,
        close,
        toggleCollapsed
    };
}
