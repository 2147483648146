import { AreaUnitEnum, CommissioningEnum, FinishingEnum, NewBuildingSortEnum } from '@search/graphql-typings';
import { FinishingTypeEnum } from '@search/filter-enums/gql/schema-types-fix';
import { RegionIdEnum, ROSSIYA } from '@search/filter-enums/enums/Region';
import { s } from '@search/router/src/schema';
import { Tokens } from '@search/router/src/RouterInterfaces';
import { numToStr, strToNum } from '@search/router/src/schema/strToNum';
import { mskCityMapper } from '@search/offer-search-routes/src/mappers/regions/msk/mskCityMapper';
import { spbCityMapper } from '@search/offer-search-routes/src/mappers/regions/spb/spbCityMapper';
import { mskRayonMapper } from '@search/offer-search-routes/src/mappers/regions/msk/mskRayonMapper';
import {
    getCityMapperByRegion,
    getDistrictMapperByRegion,
    getMetroMapperByRegion
} from '@search/offer-search-routes/src/mappers/regions';
import {
    apartmentsMapper,
    armyMortgageMapper,
    balconyMapper,
    buildingClassMapper,
    ceilingHeightMapper,
    cheapMapper,
    closedAreaMapper,
    closeToMetroMapper,
    commissioningMapper,
    conciergeMapper,
    escrowMapper,
    euroPlanningMapper,
    finishingMapper,
    finishingTypeMapper,
    flatsSpecialEventMapper,
    floorLastMapper,
    installmentMapper,
    kitchenAreaRangeMinMapper,
    law214Mapper,
    motherCapitalMapper,
    newBuildingTypes,
    parkingMapper,
    priceMaxMapper,
    pricePerMeterMaxMapper,
    quarterMapper,
    roomMapper,
    sOtdelkoySeoValue,
    wallsTypeMapper,
    YES
} from '@search/offer-search-routes/src/mappers/newBuilding/mappers';
import { YMapVector, yMapVectorPack, yMapVectorUnpack } from '@search/common-core/ymap/Vector';
import {
    CeilingHeightString,
    FlatsSpecialEventString,
    IsApartmentsOptionsString,
    ParkingTypeString
} from '@search/filter-enums/enums';

import type { Writeable } from '../types';

import { NewBuildingRoute } from './NewBuildingRoute';

export const NewBuildingSearchParams = {
    region: s.num,

    commissioning: s.nullOpt(s.str),

    rooms: s.maybeArray(roomMapper.Type),

    addressId: s.maybeArray(s.num),
    metroId: s.maybeArray(s.num),
    districtId: s.maybeArray(s.num),
    newBuildingId: s.maybeArray(s.num),
    developerId: s.nullOpt(s.num),

    transportType: s.nullOpt(s.str),
    maxMinutes: s.nullOpt(s.str),

    wallsType: s.maybeArray(wallsTypeMapper.Type),
    parking: s.maybeArray(parkingMapper.Type),

    floorsTotalMin: s.nullOpt(s.num),
    floorsTotalMax: s.nullOpt(s.num),

    priceMin: s.nullOpt(s.num),
    priceMax: s.nullOpt(s.num),
    priceType: s.nullOpt(s.str),

    totalAreaRangeMin: s.nullOpt(s.num),
    totalAreaRangeMax: s.nullOpt(s.num),

    livingAreaRangeMin: s.nullOpt(s.num),
    livingAreaRangeMax: s.nullOpt(s.num),

    kitchenAreaRangeMin: s.nullOpt(s.num),

    floorMin: s.nullOpt(s.num),
    floorMax: s.nullOpt(s.num),
    floorFirst: s.nullOpt(s.str),
    floorLast: s.nullOpt(floorLastMapper.Type),

    ceilingHeight: s.nullOpt(s.str),

    bathroom: s.maybeArray(s.str),
    balcony: s.nullOpt(s.boolExt),

    buildingClass: s.maybeArray(buildingClassMapper.Type),

    hasFinishing: s.nullOpt(finishingMapper.Type),
    finishingType: s.nullOpt(s.list(finishingTypeMapper.Type)),

    standalone: s.nullOpt(s.boolExt),
    apartments: s.nullOpt(apartmentsMapper.Type),

    concierge: s.nullOpt(s.boolExt),
    closedArea: s.nullOpt(s.boolExt),
    guardedArea: s.nullOpt(s.boolExt),

    accreditedByVTB: s.nullOpt(s.boolExt),

    law214: s.nullOpt(s.boolExt),
    escrow: s.nullOpt(s.boolExt),

    armyMortgage: s.nullOpt(s.boolExt),
    installment: s.nullOpt(s.boolExt),
    motherCapital: s.nullOpt(s.boolExt),

    closedSales: s.nullOpt(s.bool),

    pageNumber: s.nullOpt(s.num),
    pageSize: s.nullOpt(s.num),
    sort: s.nullOpt(s.str),

    selectedNewBuildingId: s.nullOpt(s.num),

    flatsSpecialEvents: s.maybeArray(s.str),
    otherSpecialEvents: s.maybeArray(s.str),
    miscSpecialEvents: s.maybeArray(s.str),

    notOnlyM2Pro: s.nullOpt(s.bool),

    geoSlug: s.opt(s.str),

    mapBounds: s.opt(s.rec({
        bounds: s.opt(s.rec({
            min: s.rec({
                x: s.num,
                y: s.num
            }),
            max: s.rec({
                x: s.num,
                y: s.num
            })
        })),

        polygon: s.opt(s.list(s.rec({
            x: s.num,
            y: s.num
        })))
    })),

    x: s.nullOpt(s.num),
    y: s.nullOpt(s.num),
    z: s.nullOpt(s.num),

    showHighlighted: s.nullOpt(s.bool)
};

export const NewBuildingSearchType = s.rec(NewBuildingSearchParams);

export type INewBuildingSearchType = ReturnType<typeof NewBuildingSearchType>

export const NewBuildingSearchSeoParams = {
    origin: s.nullOpt(s.str),
    region: s.nullOpt(s.str),

    geoSlugSeo: s.opt(s.str),

    metroSeo: s.nullOpt(s.str),
    rayon: s.nullOpt(s.str),
    okrug: s.nullOpt(s.str),
    city: s.nullOpt(s.str),

    commissioningSeo: s.nullOpt(s.str),
    commissioning: s.nullOpt(s.str),

    roomSeo: s.nullOpt(roomMapper.SeoType),
    rooms: s.maybeArray(roomMapper.Type),
    euroPlanningSeo: s.nullOpt(euroPlanningMapper.SeoType),

    addressId: s.maybeArray(s.str),
    metroId: s.maybeArray(s.str),
    districtId: s.maybeArray(s.str),
    newBuildingId: s.maybeArray(s.str),
    developerId: s.nullOpt(s.str),

    transportType: s.nullOpt(s.str),
    maxMinutes: s.nullOpt(s.str),
    closeToMetroSeo: s.nullOpt(closeToMetroMapper.SeoType),

    wallsTypeSeo: s.nullOpt(wallsTypeMapper.SeoType),
    wallsType: s.maybeArray(wallsTypeMapper.Type),

    parking: s.maybeArray(parkingMapper.Type),
    parkingSeo: s.nullOpt(parkingMapper.SeoType),

    floorsTotalMin: s.nullOpt(s.str),
    floorsTotalMax: s.nullOpt(s.str),

    priceMin: s.nullOpt(s.str),
    priceMax: s.nullOpt(s.str),
    priceMaxSeo: s.nullOpt(priceMaxMapper.SeoType),
    cheapSeo: s.nullOpt(cheapMapper.SeoType),
    priceType: s.nullOpt(s.str),
    priceTypeSeo: s.nullOpt(s.str),
    pricePerMeterMaxSeo: s.nullOpt(pricePerMeterMaxMapper.SeoType),

    totalAreaRangeMin: s.nullOpt(s.str),
    totalAreaRangeMax: s.nullOpt(s.str),

    livingAreaRangeMin: s.nullOpt(s.str),
    livingAreaRangeMax: s.nullOpt(s.str),

    kitchenAreaRangeMin: s.nullOpt(s.str),
    kitchenAreaRangeMinSeo: s.nullOpt(kitchenAreaRangeMinMapper.SeoType),

    floorMin: s.nullOpt(s.str),
    floorMax: s.nullOpt(s.str),
    floorFirst: s.nullOpt(s.str),

    floorLastSeo: s.nullOpt(floorLastMapper.SeoType),
    floorLast: s.nullOpt(floorLastMapper.Type),

    ceilingHeight: s.nullOpt(s.str),
    ceilingHeightSeo: s.nullOpt(ceilingHeightMapper.SeoType),

    bathroom: s.maybeArray(s.str),

    balcony: s.nullOpt(balconyMapper.Type),
    balconySeo: s.nullOpt(balconyMapper.SeoType),

    buildingClassSeo: s.nullOpt(buildingClassMapper.SeoType),
    buildingClass: s.maybeArray(buildingClassMapper.Type),

    hasFinishingSeo: s.nullOpt(finishingMapper.SeoType),
    hasFinishing: s.nullOpt(finishingMapper.SeoType),

    finishingTypeSeo: s.nullOpt(finishingTypeMapper.SeoType),
    finishingType: s.maybeArray(finishingTypeMapper.Type),

    standalone: s.maybeArray(s.str),

    apartments: s.nullOpt(apartmentsMapper.Type),
    apartmentsSeo: s.nullOpt(apartmentsMapper.SeoType),

    concierge: s.nullOpt(conciergeMapper.Type),
    conciergeSeo: s.nullOpt(conciergeMapper.SeoType),

    closedArea: s.nullOpt(closedAreaMapper.Type),
    closedAreaSeo: s.nullOpt(closedAreaMapper.SeoType),

    guardedArea: s.nullOpt(s.str),

    accreditedByVTB: s.nullOpt(s.str),

    law214Seo: s.nullOpt(law214Mapper.SeoType),
    law214: s.nullOpt(law214Mapper.Type),

    escrowSeo: s.nullOpt(escrowMapper.SeoType),
    escrow: s.nullOpt(escrowMapper.Type),

    armyMortgageSeo: s.nullOpt(armyMortgageMapper.SeoType),
    armyMortgage: s.nullOpt(armyMortgageMapper.Type),

    installmentSeo: s.nullOpt(installmentMapper.SeoType),
    installment: s.nullOpt(installmentMapper.Type),

    motherCapital: s.nullOpt(motherCapitalMapper.Type),
    motherCapitalSeo: s.nullOpt(motherCapitalMapper.SeoType),

    closedSales: s.nullOpt(s.str),

    pageNumber: s.nullOpt(s.str),
    pageSize: s.nullOpt(s.str),
    sort: s.nullOpt(s.str),

    selectedNewBuildingId: s.nullOpt(s.str),

    flatsSpecialEvents: s.maybeArray(s.str),
    flatsSpecialEventsSeo: s.nullOpt(flatsSpecialEventMapper.SeoType),

    otherSpecialEvents: s.maybeArray(s.str),
    miscSpecialEvents: s.maybeArray(s.str),

    notOnlyM2Pro: s.nullOpt(s.str),

    lx: s.opt(s.str),
    ly: s.opt(s.str),
    rx: s.opt(s.str),
    ry: s.opt(s.str),

    pol: s.opt(s.str),

    x: s.nullOpt(s.str),
    y: s.nullOpt(s.str),
    z: s.nullOpt(s.str),

    showHighlighted: s.nullOpt(s.str)
};

export const NewBuildingSearchSeoType = s.rec(NewBuildingSearchSeoParams);

export type INewBuildingSearchSeoType = ReturnType<typeof NewBuildingSearchSeoType>

const fillGeoSeo = (
    result: Partial<Writeable<INewBuildingSearchSeoType>>,
    region: number,
    geoIds: number | number[],
    name: 'districtId' | 'addressId',
    isCloseToMetro: boolean
) => {
    if (
        ! isCloseToMetro &&
        ! (result.metroSeo || result.city || result.rayon || result.okrug) &&
        Array.isArray(geoIds) && geoIds.length === 1
    ) {
        const geoId = geoIds[0];

        const cityMapper = getCityMapperByRegion(region);

        result.city = cityMapper?.seoFromTypeOptional(geoId);

        if (! result.city) {
            const districtMapper = getDistrictMapperByRegion(region);

            const rayon = mskRayonMapper.seoFromTypeOptional(geoId);
            const rayonOrOkrug = districtMapper?.seoFromTypeOptional(geoId);

            if (rayon || districtMapper?.name === 'spbDistrictMapper') {
                result.rayon = rayon || rayonOrOkrug;
            }

            if (districtMapper?.name === 'mskDistrictMapper') {
                result.okrug = rayonOrOkrug;
            }
        }

        result[name] = result.city || result.rayon || result.okrug ?
            undefined :
            [ numToStr(geoId) as string ];
    } else {
        result[name] = (
            Array.isArray(geoIds) ? geoIds : [ geoIds ]
        ).map(numToStr) as string[];
    }
};

function addGeo<T>(geoArray: T | readonly T[] | undefined | null, geoId?: T) {
    if (! geoId) {
        return geoArray;
    }

    if (! geoArray) {
        return geoId;
    }

    return Array.isArray(geoArray) ? [ ...geoArray, geoId ] : [ geoArray, geoId ];
}

function getGeoNumber(geo: number | readonly number[] | null | undefined) {
    if (Array.isArray(geo)) {
        return geo.length;
    }

    return geo ? 1 : 0;
}

export const searchDefaults = {
    rooms: [],
    addressId: [],
    metroId: [],
    districtId: [],
    pageSize: 10 as number,
    pageNumber: 1 as number,
    sort: NewBuildingSortEnum.RelevanceDesc as string
} as const;

export class NewBuildingSearchRoute extends NewBuildingRoute<
    INewBuildingSearchSeoType,
    INewBuildingSearchType,
    typeof searchDefaults
> {
    displayName = 'NewBuildingSearchRoute';

    protected metadata = NewBuildingSearchSeoType.config;

    protected pattern(p: Tokens<INewBuildingSearchSeoType>) {
        return `${
            this.patternHost()
        }(/${
            p.region
        })/novostroyki(/srok-${
            p.commissioningSeo
        })(/${
            p.roomSeo
        })(/${
            p.euroPlanningSeo
        })(/${
            p.hasFinishingSeo
        })(/otdelka-${
            p.finishingTypeSeo
        })(/klass-${
            p.buildingClassSeo
        })(/${
            p.wallsTypeSeo
        })(/${
            p.floorLastSeo
        })(/${
            p.ceilingHeightSeo
        })(/${
            p.kitchenAreaRangeMinSeo
        })(/${
            p.law214Seo
        })(/${
            p.escrowSeo
        })(/${
            p.armyMortgageSeo
        })(/${
            p.motherCapitalSeo
        })(/${
            p.installmentSeo
        })(/${
            p.closedAreaSeo
        })(/${
            p.conciergeSeo
        })(/${
            p.balconySeo
        })(/${
            p.apartmentsSeo
        })(/${
            p.parkingSeo
        })(/${
            p.flatsSpecialEventsSeo
        })(/${
            p.priceMaxSeo
        })(/${
            p.pricePerMeterMaxSeo
        })(/${
            p.cheapSeo
        })(/${
            p.closeToMetroSeo
        })(/metro-${
            p.metroSeo
        })(/rayon-${
            p.rayon
        })(/${
            p.okrug
        }-okrug)(/${
            p.geoSlugSeo
        })(/${
            p.city
        })/`;
    }

    protected conditions() {
        return {
            ...super.conditions(),
            city: [ ...mskCityMapper.keys, ...spbCityMapper.keys ],
            geoSlugSeo: `(?!karta|zastroyshik|quiz|${newBuildingTypes.join('|')})[\\w\\d\\-_]+`,
            roomSeo: roomMapper.keys,
            buildingClassSeo: buildingClassMapper.keys,
            hasFinishingSeo: finishingMapper.keys,
            finishingTypeSeo: finishingTypeMapper.keys,
            wallsTypeSeo: wallsTypeMapper.keys,
            floorLastSeo: floorLastMapper.keys,
            law214Seo: law214Mapper.keys,
            escrowSeo: escrowMapper.keys,
            armyMortgageSeo: armyMortgageMapper.keys,
            installmentSeo: installmentMapper.keys,
            priceMaxSeo: priceMaxMapper.keys,
            pricePerMeterMaxSeo: pricePerMeterMaxMapper.keys,
            cheapSeo: cheapMapper.keys,
            closeToMetroSeo: closeToMetroMapper.keys,
            closedAreaSeo: closedAreaMapper.keys,
            conciergeSeo: conciergeMapper.keys,
            balconySeo: balconyMapper.keys,
            motherCapitalSeo: motherCapitalMapper.keys,
            apartmentsSeo: apartmentsMapper.keys,
            parkingSeo: parkingMapper.keys,
            ceilingHeightSeo: ceilingHeightMapper.keys,
            kitchenAreaRangeMinSeo: kitchenAreaRangeMinMapper.keys,
            euroPlanningSeo: euroPlanningMapper.keys,
            flatsSpecialEventsSeo: flatsSpecialEventMapper.keys
        };
    }

    defaults() {
        return searchDefaults;
    }

    // eslint-disable-next-line complexity
    protected fromQuery(seo: INewBuildingSearchSeoType) {
        const defaults = this.defaults();

        const result: Partial<Writeable<INewBuildingSearchType>> = {
            rooms: [],
            finishingType: []
        };

        const raw = NewBuildingSearchSeoType(seo);

        result.region = this.regionId(raw.region);

        const commissioning = raw.commissioningSeo || raw.commissioning;

        if (commissioning) {
            if (commissioning.indexOf('q') !== -1) {
                const [ year, quarter ] = commissioning.split('q');
                const quarterNumber = parseInt(quarter, 10) as 1 | 2 | 3 | 4;

                result.commissioning = `${quarterMapper.seoFromType(quarterNumber)}_${year}`;
            } else {
                if (Object.values(CommissioningEnum).includes(commissioning as CommissioningEnum)) {
                    result.commissioning = commissioning;
                } else if (commissioningMapper.hasSeo(commissioning)) {
                    result.commissioning = commissioningMapper.typeFromSeo(commissioning);
                } else {
                    const [ quarter, year ] = commissioning.split('_');

                    if (quarter && year) {
                        result.commissioning = `${quarterMapper.typeFromSeo(quarter)}_${year}`;
                    }
                }
            }
        }

        if (raw.roomSeo) {
            result.rooms = roomMapper.toEnums(raw.roomSeo);
        }

        if (raw.rooms) {
            result.rooms = Array.isArray(raw.rooms) ? raw.rooms : [ raw.rooms ];
        }

        if (raw.euroPlanningSeo) {
            const [
                rooms,
                kitchen
            ] = euroPlanningMapper.typeFromSeo(raw.euroPlanningSeo).split('|');

            // @ts-ignore
            result.rooms = [ rooms ];
            result.kitchenAreaRangeMin = strToNum(kitchen);
        }

        const hasFinishing = raw.hasFinishingSeo || raw.hasFinishing;

        if (hasFinishing) {
            let finishingTypeFromHasFinishing: typeof sOtdelkoySeoValue | [ FinishingTypeEnum.WithoutFinishing ];

            if (hasFinishing === 'bez-otdelki') {
                finishingTypeFromHasFinishing = [ FinishingTypeEnum.WithoutFinishing ];
            } else {
                finishingTypeFromHasFinishing = sOtdelkoySeoValue;
                result.hasFinishing = finishingMapper.typeFromSeo(hasFinishing);
            }

            if (result.finishingType) {
                // @ts-expect-error
                result.finishingType = result.finishingType.concat(finishingTypeFromHasFinishing);
            } else {
                // @ts-expect-error
                result.finishingType = finishingTypeFromHasFinishing;
            }
        }

        if (raw.finishingTypeSeo) {
            result.finishingType = finishingTypeMapper.toEnums(raw.finishingTypeSeo);
        }

        if (raw.finishingType && result.finishingType) {
            result.finishingType = result.finishingType.concat(raw.finishingType);
        }

        // eslint-disable-next-line no-nested-ternary
        result.buildingClass = raw.buildingClassSeo ?
            buildingClassMapper.toEnums(raw.buildingClassSeo) :
            // eslint-disable-next-line no-nested-ternary
            raw.buildingClass ?
                Array.isArray(raw.buildingClass) ?
                    raw.buildingClass :
                    [ raw.buildingClass ] :
                [];

        // eslint-disable-next-line no-nested-ternary
        result.wallsType = raw.wallsTypeSeo ? wallsTypeMapper.toEnums(raw.wallsTypeSeo) :
            // eslint-disable-next-line no-nested-ternary
            raw.wallsType ?
                Array.isArray(raw.wallsType) ?
                    raw.wallsType :
                    [ raw.wallsType ] :
                [];

        result.floorLast = raw.floorLastSeo ?
            floorLastMapper.toEnums(raw.floorLastSeo)[0] :
            raw.floorLast;

        result.law214 = (
            raw.law214Seo ?
                law214Mapper.toEnums(raw.law214Seo)[0] :
                raw.law214
        ) === YES;

        result.escrow = (
            raw.escrowSeo ?
                escrowMapper.toEnums(raw.escrowSeo)[0] :
                raw.escrow
        ) === YES;

        result.armyMortgage = (
            raw.armyMortgageSeo ?
                armyMortgageMapper.toEnums(raw.armyMortgageSeo)[0] :
                raw.armyMortgage
        ) === YES;

        result.installment = (
            raw.installmentSeo ?
                installmentMapper.toEnums(raw.installmentSeo)[0] :
                raw.installment
        ) === YES;

        result.closedArea = (
            raw.closedAreaSeo ?
                closedAreaMapper.toEnums(raw.closedAreaSeo)[0] :
                raw.closedArea
        ) === YES;

        result.concierge = (
            raw.conciergeSeo ?
                conciergeMapper.toEnums(raw.conciergeSeo)[0] :
                raw.concierge
        ) === YES;

        result.balcony = (
            raw.balconySeo ?
                balconyMapper.toEnums(raw.balconySeo)[0] :
                raw.balcony
        ) === YES;

        result.motherCapital = (
            raw.motherCapitalSeo ?
                motherCapitalMapper.toEnums(raw.motherCapitalSeo)[0] :
                raw.motherCapital
        ) === YES;

        const apartmentsOnlySeoStr = apartmentsMapper.seoFromType(IsApartmentsOptionsString.APARTMENTS_ONLY);

        result.apartments = raw.apartmentsSeo === apartmentsOnlySeoStr ?
            apartmentsMapper.toEnums(raw.apartmentsSeo)[0] :
            raw.apartments;

        if (raw.ceilingHeightSeo) {
            result.ceilingHeight = ceilingHeightMapper.typeFromSeo(raw.ceilingHeightSeo);
        }

        if (raw.kitchenAreaRangeMinSeo) {
            result.kitchenAreaRangeMin = kitchenAreaRangeMinMapper.typeFromSeo(raw.kitchenAreaRangeMinSeo);
        }

        if (raw.parkingSeo === parkingMapper.seoFromType(ParkingTypeString.UNDERGROUND)) {
            result.parking = [ ParkingTypeString.UNDERGROUND ];
        } else if (raw.parkingSeo === parkingMapper.seoFromType(ParkingTypeString.AVAILABLE)) {
            result.parking = [ ParkingTypeString.UNDERGROUND, ParkingTypeString.CLOSED, ParkingTypeString.OPEN ];
        } else {
            // eslint-disable-next-line no-nested-ternary
            result.parking = raw.parking ? (
                Array.isArray(raw.parking) ? raw.parking : [ raw.parking ]
            ) : [];
        }

        if (raw.flatsSpecialEventsSeo) {
            result.flatsSpecialEvents = [ flatsSpecialEventMapper.typeFromSeo(raw.flatsSpecialEventsSeo) ];
        }

        if (raw.priceMaxSeo) {
            result.priceMax = priceMaxMapper.toEnums(raw.priceMaxSeo)[0];
        }

        if (raw.pricePerMeterMaxSeo) {
            result.priceMax = pricePerMeterMaxMapper.toEnums(raw.pricePerMeterMaxSeo)[0];
            result.priceType = AreaUnitEnum.Meter;
        }

        if (raw.cheapSeo) {
            result.priceMax = cheapMapper.toEnums(raw.cheapSeo)[0];

            result.sort = NewBuildingSortEnum.PriceAsc;
        } else {
            result.sort = raw.sort || defaults.sort;
        }

        if (raw.city) {
            const cityMapper = result.region ? getCityMapperByRegion(result.region) : undefined;

            result.districtId = cityMapper?.toIds(raw.districtId, raw.city);
        }

        if (raw.rayon) {
            const districtMapper = result.region ? getDistrictMapperByRegion(result.region) : undefined;
            const mskDistrictIds = mskRayonMapper.hasSeo(raw.rayon) ?
                mskRayonMapper.toIds(raw.districtId, raw.rayon) :
                null;

            result.districtId = mskDistrictIds ?? districtMapper?.toIds(raw.districtId, raw.rayon);
        }

        if (raw.okrug) {
            const districtMapper = result.region ? getDistrictMapperByRegion(result.region) : undefined;

            result.districtId = districtMapper?.toIds(raw.districtId, raw.okrug);
        }

        if (! result.districtId) {
            result.districtId = raw.districtId?.length ? (
                Array.isArray(raw.districtId) ?
                    raw.districtId :
                    [ raw.districtId ]
            ).map(strToNum) as number[] : undefined;
        }

        if (raw.closedSales) {
            result.closedSales = Boolean(raw.closedSales);
        }

        if (raw.notOnlyM2Pro) {
            result.notOnlyM2Pro = Boolean(raw.notOnlyM2Pro);
        }

        const hasBounds = ! [ raw.lx, raw.ly, raw.rx, raw.ry ].some(coord => coord === undefined);

        if (hasBounds || raw.pol) {
            result.mapBounds = {
                bounds: hasBounds ? {
                    min: {
                        x: Number(raw.lx),
                        y: Number(raw.ly)
                    },
                    max: {
                        x: Number(raw.rx),
                        y: Number(raw.ry)
                    }
                } : undefined,
                polygon: raw.pol ? yMapVectorUnpack(raw.pol) : []
            };
        }

        if (raw.showHighlighted) {
            result.showHighlighted = Boolean(raw.showHighlighted);
        }

        const metroMapper = result.region ? getMetroMapperByRegion(result.region) : undefined;

        if (raw.closeToMetroSeo) {
            [
                result.transportType,
                result.maxMinutes
            ] = closeToMetroMapper.toEnums(raw.closeToMetroSeo)[0]
                .split('|');
        } else {
            // eslint-disable-next-line no-nested-ternary
            result.metroId = metroMapper ?
                metroMapper.toIds(raw.metroId, raw.metroSeo) :
                raw.metroId?.length ? (
                    Array.isArray(raw.metroId) ?
                        raw.metroId :
                        [ raw.metroId ]
                ).map(strToNum) as number[] : undefined;

            result.transportType = raw.transportType;
            result.maxMinutes = raw.maxMinutes;
        }

        if (raw.geoSlugSeo) {
            result.geoSlug = raw.geoSlugSeo;

            const geo = result.region ? this.countrySlugId(raw.geoSlugSeo, result.region) : undefined;

            if (geo?.kind === 'LOCALITY') {
                result.addressId = addGeo(result.addressId, geo?.id);
            }

            if (geo?.kind === 'DISTRICT') {
                result.districtId = addGeo(result.districtId, geo?.id);
            }
        }

        if (! result.addressId) {
            result.addressId = raw.addressId?.length ? (
                Array.isArray(raw.addressId) ?
                    raw.addressId :
                    [ raw.addressId ]).map(strToNum) as number[] : undefined;
        }

        if (! result.districtId) {
            result.districtId = raw.districtId?.length ? (
                Array.isArray(raw.districtId) ?
                    raw.districtId :
                    [ raw.districtId ]).map(strToNum) as number[] : undefined;
        }

        return NewBuildingSearchType({
            region: Number(result.region),

            priceMin: strToNum(raw.priceMin),
            priceMax: strToNum(raw.priceMax),
            priceType: raw.priceType,

            totalAreaRangeMin: strToNum(raw.totalAreaRangeMin),
            totalAreaRangeMax: strToNum(raw.totalAreaRangeMax),

            livingAreaRangeMin: strToNum(raw.livingAreaRangeMin),
            livingAreaRangeMax: strToNum(raw.livingAreaRangeMax),

            kitchenAreaRangeMin: strToNum(raw.kitchenAreaRangeMin),

            floorMin: strToNum(raw.floorMin),
            floorMax: strToNum(raw.floorMax),
            floorFirst: raw.floorFirst,

            floorsTotalMin: strToNum(raw.floorsTotalMin),
            floorsTotalMax: strToNum(raw.floorsTotalMax),

            ceilingHeight: raw.ceilingHeight,

            bathroom: raw.bathroom,

            newBuildingId: raw.newBuildingId ? (
                Array.isArray(raw.newBuildingId) ?
                    raw.newBuildingId :
                    [ raw.newBuildingId ]
            ).map(strToNum) as number[] : undefined,
            developerId: raw.developerId ? strToNum(raw.developerId) : undefined,

            standalone: raw.standalone === YES,

            accreditedByVTB: raw.accreditedByVTB === YES,

            guardedArea: raw.guardedArea === YES,

            pageNumber: strToNum(raw.pageNumber) || defaults.pageNumber,
            pageSize: strToNum(raw.pageSize) || defaults.pageSize,

            selectedNewBuildingId: strToNum(raw.selectedNewBuildingId),

            flatsSpecialEvents: raw.flatsSpecialEvents,
            otherSpecialEvents: raw.otherSpecialEvents,
            miscSpecialEvents: raw.miscSpecialEvents,

            x: strToNum(raw.x),
            y: strToNum(raw.y),
            z: strToNum(raw.z),

            ...result
        });
    }

    // eslint-disable-next-line complexity
    protected toQuery(p: INewBuildingSearchType) {
        const defaults = this.defaults();

        p = NewBuildingSearchType(p);

        let result: Partial<Writeable<INewBuildingSearchSeoType>> = {};

        // https://jira.m2.ru/browse/NB-1625
        const seoResult: Partial<Writeable<INewBuildingSearchSeoType>> = {};
        const queryResult: Partial<Writeable<INewBuildingSearchSeoType>> = {};

        let totalSegments = 0;

        if (p.commissioning) {
            let commissioning;

            if (commissioningMapper.hasType(p.commissioning)) {
                commissioning = commissioningMapper.seoFromType(p.commissioning);
            } else {
                const [ quarter, year ] = p.commissioning.split('_');

                commissioning = [ year, quarterMapper.typeFromSeo(quarter) ].join('q');
            }

            seoResult.commissioningSeo = commissioning;
            queryResult.commissioning = commissioning;

            totalSegments++;
        }

        if (
            euroPlanningMapper.hasType(`${p.rooms}|${p.kitchenAreaRangeMin}`) &&
            (! p.apartments || p.apartments !== IsApartmentsOptionsString.APARTMENTS_ONLY)
        ) {
            seoResult.euroPlanningSeo = euroPlanningMapper.seoFromType(`${p.rooms}|${p.kitchenAreaRangeMin}`);

            totalSegments++;

            queryResult.rooms = p.rooms;
            queryResult.kitchenAreaRangeMin = numToStr(p.kitchenAreaRangeMin);
        } else {
            if (p.rooms && Array.isArray(p.rooms)) {
                if (p.rooms.length === 1) {
                    seoResult.roomSeo = roomMapper.seoFromType(p.rooms[0]);

                    totalSegments++;
                } else {
                    seoResult.rooms = p.rooms;
                }

                queryResult.rooms = p.rooms;
            }

            if (p.kitchenAreaRangeMin) {
                if (kitchenAreaRangeMinMapper.hasType(p.kitchenAreaRangeMin)) {
                    seoResult.kitchenAreaRangeMinSeo = kitchenAreaRangeMinMapper.seoFromType(p.kitchenAreaRangeMin);

                    totalSegments++;
                } else {
                    seoResult.kitchenAreaRangeMin = numToStr(p.kitchenAreaRangeMin);
                }

                queryResult.kitchenAreaRangeMin = numToStr(p.kitchenAreaRangeMin);
            }
        }

        if (p.finishingType) {
            // в finishingTypeMapper под-ключ и чистовая принимают одно и тоже значение
            // чтобы убрать дубли, в случае
            // /novostroyki/otdelka-pod-klyuch/?finishingType=FINE&finishingType=PRE_FINISHING -> /novostroyki/?finishingType=FINE&finishingType=PRE_FINISHING
            const finishingTypeDeduplicated = Array.from(new Set(p.finishingType));

            if (p.finishingType.length === 1) {
                const finishingSeoTag = finishingTypeMapper.seoFromType(p.finishingType[0]);

                // не для всех есть ЧПУ (предчистовая)
                if (finishingSeoTag) {
                    // переехало в новый finishingTypeEnum, но тег нужно оставить прошлого вида
                    if (finishingSeoTag === 'bez-otdelki') seoResult.hasFinishingSeo = 'bez-otdelki';
                    else seoResult.finishingTypeSeo = finishingSeoTag;
                    // отдельного значения в Enum отправляемом на бэк более нет и теперь
                    // подключ === чистовая
                    if (finishingSeoTag === 'pod-klyuch') seoResult.finishingTypeSeo = 'chistovaya';

                    seoResult.finishingType = undefined;
                    totalSegments++;
                }
            }
            if (p.hasFinishing === FinishingEnum.Available && ! seoResult.finishingTypeSeo) {
                seoResult.hasFinishingSeo = 's-otdelkoy';
                seoResult.finishingType = undefined;
                totalSegments++;
            }

            if (! seoResult.finishingTypeSeo && ! seoResult.hasFinishingSeo) {
                seoResult.finishingType = finishingTypeDeduplicated;
            }

            queryResult.finishingType = finishingTypeDeduplicated;
        }

        if (p.buildingClass) {
            if (Array.isArray(p.buildingClass) && p.buildingClass.length === 1) {
                seoResult.buildingClassSeo = buildingClassMapper.seoFromType(p.buildingClass[0]);

                totalSegments++;
            } else {
                seoResult.buildingClass = p.buildingClass;
            }

            queryResult.buildingClass = p.buildingClass;
        }

        if (p.wallsType) {
            if (Array.isArray(p.wallsType) && p.wallsType.length === 1) {
                seoResult.wallsTypeSeo = wallsTypeMapper.seoFromType(p.wallsType[0]);

                totalSegments++;
            } else {
                seoResult.wallsType = p.wallsType;
            }

            queryResult.wallsType = p.wallsType;
        }

        if (p.floorLast) {
            seoResult.floorLastSeo = floorLastMapper.seoFromType(p.floorLast);

            totalSegments++;

            queryResult.floorLast = p.floorLast;
        }

        if (p.law214) {
            seoResult.law214Seo = law214Mapper.seoFromType(YES);

            totalSegments++;

            queryResult.law214 = YES;
        }

        if (p.escrow) {
            seoResult.escrowSeo = escrowMapper.seoFromType(YES);

            totalSegments++;

            queryResult.escrow = YES;
        }

        if (p.armyMortgage) {
            seoResult.armyMortgageSeo = armyMortgageMapper.seoFromType(YES);

            totalSegments++;

            queryResult.armyMortgage = YES;
        }

        if (p.installment) {
            seoResult.installmentSeo = installmentMapper.seoFromType(YES);

            totalSegments++;

            queryResult.installment = YES;
        }

        if (p.closedArea) {
            seoResult.closedAreaSeo = closedAreaMapper.seoFromType(YES);

            totalSegments++;

            queryResult.closedArea = YES;
        }

        if (p.concierge) {
            seoResult.conciergeSeo = conciergeMapper.seoFromType(YES);

            totalSegments++;

            queryResult.concierge = YES;
        }

        if (p.balcony) {
            seoResult.balconySeo = balconyMapper.seoFromType(YES);

            totalSegments++;

            queryResult.balcony = YES;
        }

        if (p.motherCapital) {
            seoResult.motherCapitalSeo = motherCapitalMapper.seoFromType(YES);

            totalSegments++;

            queryResult.motherCapital = YES;
        }

        if (p.apartments) {
            if (p.apartments === IsApartmentsOptionsString.APARTMENTS_ONLY) {
                seoResult.apartmentsSeo = apartmentsMapper.seoFromType(p.apartments);

                totalSegments++;
            } else {
                seoResult.apartments = p.apartments;
            }

            queryResult.apartments = p.apartments;
        }

        if (p.ceilingHeight) {
            if (ceilingHeightMapper.hasType(p.ceilingHeight)) {
                seoResult.ceilingHeightSeo = ceilingHeightMapper.seoFromType(p.ceilingHeight as CeilingHeightString);

                totalSegments++;
            } else {
                seoResult.ceilingHeight = p.ceilingHeight;
            }

            queryResult.ceilingHeight = p.ceilingHeight;
        }

        if (p.parking) {
            if (Array.isArray(p.parking) && p.parking.length === 3) {
                seoResult.parkingSeo = parkingMapper.seoFromType(ParkingTypeString.AVAILABLE);

                totalSegments++;
            } else if (p.parking.length === 1 && p.parking[0] === ParkingTypeString.UNDERGROUND) {
                seoResult.parkingSeo = parkingMapper.seoFromType(ParkingTypeString.UNDERGROUND);

                totalSegments++;
            } else {
                seoResult.parking = p.parking;
            }

            queryResult.parking = Array.isArray(p.parking) ? p.parking : [ p.parking ];
        }

        if (p.flatsSpecialEvents) {
            if (p.flatsSpecialEvents.length === 1 && flatsSpecialEventMapper.hasType(p.flatsSpecialEvents[0])) {
                seoResult.flatsSpecialEventsSeo = flatsSpecialEventMapper.seoFromType(
                    p.flatsSpecialEvents[0] as FlatsSpecialEventString
                );

                totalSegments++;
            } else {
                seoResult.flatsSpecialEvents = p.flatsSpecialEvents;
            }

            queryResult.flatsSpecialEvents = Array.isArray(p.flatsSpecialEvents) ?
                p.flatsSpecialEvents :
                [ p.flatsSpecialEvents ];
        }

        if (p.priceMax && priceMaxMapper.hasType(p.priceMax)) {
            if (cheapMapper.hasType(p.priceMax) && p.sort === NewBuildingSortEnum.PriceAsc) {
                seoResult.cheapSeo = cheapMapper.seoFromType(p.priceMax);
            } else {
                seoResult.priceMaxSeo = priceMaxMapper.seoFromType(p.priceMax);
            }

            totalSegments++;

            queryResult.priceMax = numToStr(p.priceMax);
            queryResult.priceType = p.priceType;
        } else if (p.priceMax && pricePerMeterMaxMapper.hasType(p.priceMax) && p.priceType === 'METER') {
            seoResult.pricePerMeterMaxSeo = pricePerMeterMaxMapper.seoFromType(p.priceMax);

            totalSegments++;

            queryResult.priceMax = numToStr(p.priceMax);
            queryResult.priceType = p.priceType;
        } else {
            result.priceMax = numToStr(p.priceMax);
            result.priceType = p.priceType;
        }

        const isCloseToMetro = Boolean(
            p.transportType && p.maxMinutes &&
            closeToMetroMapper.hasType(`${p.transportType}|${p.maxMinutes}`)
        );

        if (! (
            (Array.isArray(p.metroId) ? p.metroId.length : p.metroId) ||
            (Array.isArray(p.districtId) ? p.districtId.length : p.districtId) ||
            (Array.isArray(p.addressId) ? p.addressId.length : p.addressId)
        ) && isCloseToMetro) {
            seoResult.closeToMetroSeo = closeToMetroMapper.seoFromType(`${p.transportType}|${p.maxMinutes}`);

            totalSegments++;

            queryResult.transportType = p.transportType;
            queryResult.maxMinutes = p.maxMinutes;
        } else {
            result.transportType = p.transportType;
            result.maxMinutes = p.maxMinutes;
        }

        const slugNumber = getGeoNumber(p.addressId) + getGeoNumber(p.districtId);
        let slugSource;

        if (p.geoSlug) {
            result.geoSlugSeo = p.geoSlug;
        } else if (p.addressId || p.districtId) {
            if (slugNumber === 1) {
                const addressId = Array.isArray(p.addressId) ? p.addressId[0] : p.addressId;
                const districtId = Array.isArray(p.districtId) ? p.districtId[0] : p.districtId;

                if (addressId) {
                    const slug = this.countrySlugPath(addressId) ?? undefined;

                    result.geoSlugSeo = slug;
                    slugSource = slug ? 'addressId' : undefined;
                } else if (districtId) {
                    const slug = this.countrySlugPath(districtId) ?? undefined;

                    result.geoSlugSeo = slug;
                    slugSource = slug ? 'districtId' : undefined;
                }
            }
        }

        p.addressId && slugSource !== 'addressId' && fillGeoSeo(
            result,
            p.region,
            p.addressId as number | number[],
            'addressId',
            isCloseToMetro
        );

        p.districtId && slugSource !== 'districtId' && fillGeoSeo(
            result,
            p.region,
            p.districtId as number | number[],
            'districtId',
            isCloseToMetro
        );

        result = {
            ...result,
            ...(totalSegments <= 2 ? seoResult : queryResult)
        };

        if (! result.cheapSeo) {
            result.sort = p.sort && p.sort !== defaults.sort ? p.sort : undefined;
        }

        const metroMapper = getMetroMapperByRegion(p.region);
        let metroSeo: string | undefined;

        if (metroMapper && Array.isArray(p.metroId) && p.metroId.length === 1) {
            metroSeo = metroMapper.seoFromTypeOptional(p.metroId[0]);
        }

        if (metroSeo && ! isCloseToMetro) {
            result.metroSeo = metroSeo;
        } else {
            result.metroId = (
                Array.isArray(p.metroId) ?
                    p.metroId :
                    [ p.metroId ]
            ).map(numToStr) as string[];
        }

        if (p.closedSales) {
            result.closedSales = 'YES';
        }

        if (p.notOnlyM2Pro) {
            result.notOnlyM2Pro = 'YES';
        }

        const { bounds, polygon } = p.mapBounds ?? {};

        if (p.showHighlighted) {
            result.showHighlighted = 'YES';
        }

        return NewBuildingSearchSeoType({
            ...result,
            ...this.regionParams(p.region),

            priceMin: numToStr(p.priceMin),

            totalAreaRangeMin: numToStr(p.totalAreaRangeMin),
            totalAreaRangeMax: numToStr(p.totalAreaRangeMax),

            livingAreaRangeMin: numToStr(p.livingAreaRangeMin),
            livingAreaRangeMax: numToStr(p.livingAreaRangeMax),

            floorMin: numToStr(p.floorMin),
            floorMax: numToStr(p.floorMax),
            floorFirst: p.floorFirst,

            floorsTotalMin: numToStr(p.floorsTotalMin),
            floorsTotalMax: numToStr(p.floorsTotalMax),

            bathroom: p.bathroom,

            newBuildingId: p.newBuildingId ? (
                Array.isArray(p.newBuildingId) ?
                    p.newBuildingId :
                    [ p.newBuildingId ]
            ).map(numToStr) as string[] : undefined,
            developerId: p.developerId ? numToStr(p.developerId) : undefined,

            standalone: p.standalone ? YES : undefined,

            accreditedByVTB: p.accreditedByVTB ? YES : undefined,

            guardedArea: p.guardedArea ? YES : undefined,

            pageNumber: p.pageNumber && p.pageNumber !== defaults.pageNumber ? numToStr(p.pageNumber) : undefined,
            pageSize: p.pageSize && p.pageSize !== defaults.pageSize ? numToStr(p.pageSize) : undefined,

            selectedNewBuildingId: numToStr(p.selectedNewBuildingId),

            otherSpecialEvents: p.otherSpecialEvents,
            miscSpecialEvents: p.miscSpecialEvents,

            lx: bounds?.min.x.toString(),
            ly: bounds?.min.y.toString(),
            rx: bounds?.max.x.toString(),
            ry: bounds?.max.y.toString(),

            pol: polygon ? yMapVectorPack(polygon as YMapVector[]) : undefined,

            x: numToStr(p.x),
            y: numToStr(p.y),
            z: numToStr(p.z)
        });
    }
}

export class WLNewBuildingSearchRoute extends NewBuildingSearchRoute {
    displayName = 'WLNewBuildingSearchRoute';

    protected pattern() {
        return `${this.patternHost()}/`;
    }

    protected defaultRegion() {
        return {
            id: RegionIdEnum.MSK,
            translit: 'moskva'
        };
    }

    protected fromQuery(seo: INewBuildingSearchSeoType) {
        const ret = super.fromQuery(seo);
        const regionRet: { region?: number } = {};

        if (seo.region) {
            regionRet.region = strToNum(seo.region);
        }

        return {
            ...ret,
            ...regionRet,

            rooms: seo.rooms,
            commissioning: seo.commissioning,
            buildingClass: seo.buildingClass,
            wallsType: seo.wallsType,
            floorLast: seo.floorLast,

            law214: seo.law214 === YES,
            escrow: seo.escrow === YES,
            armyMortgage: seo.armyMortgage === YES,
            installment: seo.installment === YES,
            concierge: seo.concierge === YES,
            closedArea: seo.closedArea === YES,
            balcony: seo.balcony === YES,
            motherCapital: seo.motherCapital === YES,
            apartments: seo.apartments,
            parking: seo.parking
        };
    }

    protected toQuery(p: INewBuildingSearchType) {
        const ret: Partial<Writeable<INewBuildingSearchSeoType>> = super.toQuery(p);

        ret.region = String(p.region);

        ret.rooms = p.rooms;
        ret.commissioning = p.commissioning;
        ret.finishingType = p.finishingType ?? undefined;
        ret.buildingClass = p.buildingClass;
        ret.wallsType = p.wallsType;
        ret.floorLast = p.floorLast;

        ret.law214 = p.law214 ? YES : undefined;
        ret.escrow = p.escrow ? YES : undefined;
        ret.armyMortgage = p.armyMortgage ? YES : undefined;
        ret.installment = p.installment ? YES : undefined;
        ret.concierge = p.concierge ? YES : undefined;
        ret.balcony = p.balcony ? YES : undefined;
        ret.motherCapital = p.motherCapital ? YES : undefined;
        ret.closedArea = p.closedArea ? YES : undefined;
        ret.apartments = p.apartments;
        ret.parking = p.parking;
        ret.flatsSpecialEvents = p.flatsSpecialEvents;
        ret.ceilingHeight = p.ceilingHeight;
        ret.kitchenAreaRangeMin = numToStr(p.kitchenAreaRangeMin);

        ret.districtId = Array.isArray(p.districtId) ?
            p.districtId :
            [ p.districtId ];
        ret.metroId = Array.isArray(p.metroId) ?
            p.metroId :
            [ p.metroId ];

        if (ret.geoSlugSeo) {
            const geo = p.region ? this.countrySlugId(ret.geoSlugSeo, p.region) : undefined;

            if (geo?.kind === 'LOCALITY') {
                ret.addressId = addGeo(ret.addressId, geo?.id.toString());
            }

            if (geo?.kind === 'DISTRICT') {
                ret.districtId = addGeo(ret.districtId, geo?.id.toString());
            }
        }

        delete ret.metroSeo;
        delete ret.rayon;
        delete ret.okrug;
        delete ret.city;
        delete ret.roomSeo;
        delete ret.commissioningSeo;
        delete ret.finishingTypeSeo;
        delete ret.buildingClassSeo;
        delete ret.wallsTypeSeo;
        delete ret.floorLastSeo;
        delete ret.law214Seo;
        delete ret.escrowSeo;
        delete ret.armyMortgageSeo;
        delete ret.installmentSeo;
        delete ret.conciergeSeo;
        delete ret.closedAreaSeo;
        delete ret.apartmentsSeo;
        delete ret.parkingSeo;
        delete ret.ceilingHeightSeo;
        delete ret.kitchenAreaRangeMinSeo;
        delete ret.euroPlanningSeo;
        delete ret.balconySeo;
        delete ret.motherCapitalSeo;
        delete ret.flatsSpecialEventsSeo;
        delete ret.origin;
        delete ret.geoSlugSeo;

        return ret;
    }
}

export const convertParamsToGQLFilters = (params: INewBuildingSearchType) => ({
    regionId: Number(params.region ?? ROSSIYA),
    commissioning: params.commissioning,
    rooms: params.rooms,
    addressId: params.addressId,
    metroId: params.metroId,
    districtId: params.districtId,
    newBuildingId: Array.isArray(params.newBuildingId) ? params.newBuildingId[0] : undefined,
    developerId: params.developerId,
    transportType: params.transportType,
    maxMinutes: params.maxMinutes,
    wallsType: params.wallsType,
    parking: params.parking,
    floorsTotalMin: params.floorsTotalMin,
    floorsTotalMax: params.floorsTotalMax,
    priceMin: params.priceMin,
    priceMax: params.priceMax,
    floorMin: params.floorMin,
    floorMax: params.floorMax,
    floorFirst: params.floorFirst,
    floorLast: params.floorLast,
    totalAreaRangeMin: params.totalAreaRangeMin,
    totalAreaRangeMax: params.totalAreaRangeMax,
    livingAreaRangeMin: params.livingAreaRangeMin,
    livingAreaRangeMax: params.livingAreaRangeMax,
    kitchenAreaRangeMin: params.kitchenAreaRangeMin,
    ceilingHeight: params.ceilingHeight,
    bathroom: params.bathroom,
    balcony: params.balcony,
    buildingClass: params.buildingClass,
    hasFinishing: params.hasFinishing,
    finishingType: params.finishingType,
    standalone: params.standalone,
    apartments: params.apartments,
    concierge: params.concierge,
    closedArea: params.closedArea,
    guardedArea: params.guardedArea,
    accreditedByVTB: params.accreditedByVTB,
    law214: params.law214,
    escrow: params.escrow,
    armyMortgage: params.armyMortgage,
    installment: params.installment,
    motherCapital: params.motherCapital,
    priceType: params.priceType,
    closedSales: params.closedSales,
    mapBounds: params.mapBounds,
    flatsSpecialEvents: params.flatsSpecialEvents,
    otherSpecialEvents: params.otherSpecialEvents,
    miscSpecialEvents: params.miscSpecialEvents,
    notOnlyM2Pro: params.notOnlyM2Pro,
});
