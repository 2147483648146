import React, { useCallback } from 'react';

import { Checkbox } from '@vtblife/uikit';

import classname from '@search/classname/src';

import type { DistrictGroup } from '../../../../../../helpers/districtHelper';
import type { ArrayElement } from '../../../../../../../../types/common';

import './styles.css';

const cn = classname.bind(null, 'DistrictsTabGeoSelector');

interface IProps {
    groups: DistrictGroup[];
    selected: Set<number>;
    onChange: (data: any) => void;
}

export const DistrictsTab: React.FC<IProps> = ({
    groups,
    selected,
    onChange
}) => {
    const handleCheckboxChange = useCallback((
        checked: boolean,
        id: ArrayElement<DistrictGroup['items']>['id'],
        groupIndex: number,
        groupId: DistrictGroup['id']
    ) => {
        let checkedDiff = new Set<number>();
        let uncheckedDiff = new Set<number>();

        if (! groupId) {
            if (checked) {
                checkedDiff.add(id);
            } else {
                uncheckedDiff.add(id);
            }

            onChange({
                diff: {
                    selected: checkedDiff,
                    deselected: uncheckedDiff
                }
            });

            return;
        }

        if (checked) {
            const isGroupChecked = groups[groupIndex].items.every(item => selected.has(item.id) || item.id === id);

            if (isGroupChecked) {
                const selectedIdsFromGroup = groups[groupIndex].items
                    .map(item => item.id)
                    .filter(value => selected.has(value));

                checkedDiff = new Set([ groupId ]);
                uncheckedDiff = new Set([ id, ...selectedIdsFromGroup ]);
            } else {
                checkedDiff = new Set([ id ]);
            }
        } else {
            if (selected.has(groupId)) {
                const otherGroupIds = groups[groupIndex].items
                    .filter(item => item.id !== id)
                    .map(item => item.id);

                checkedDiff = new Set(otherGroupIds);
                uncheckedDiff = new Set([ groupId, id ]);
            } else {
                uncheckedDiff = new Set([ id ]);
            }
        }

        onChange({
            diff: {
                selected: checkedDiff,
                deselected: uncheckedDiff
            }
        });
    }, [ groups, onChange, selected ]);

    const handleCheckboxGroupChange = useCallback((
        checked: boolean,
        groupIndex: number,
        groupId: DistrictGroup['id']
    ) => {
        let checkedDiff = new Set<number>();
        let uncheckedDiff = new Set<number>();

        const groupIds = groups[groupIndex].items.map(item => item.id);

        if (checked) {
            checkedDiff = new Set(groupId ? [ groupId ] : groupIds);
        } else {
            uncheckedDiff = new Set(groupId && selected.has(groupId) ? [ groupId ] : groupIds);
        }

        onChange({
            diff: {
                selected: checkedDiff,
                deselected: uncheckedDiff
            }
        });
    }, [ groups, onChange, selected ]);

    return (
        <div className={cn()}>
            {groups.map((group, groupIdx) => {
                const { title, items, id: groupId } = group;

                const isGroupIdChecked = groupId && selected.has(groupId);
                const isGroupChecked = isGroupIdChecked || items.every(item => selected.has(item.id));
                const isGroupItemChecked = ! isGroupChecked ? items.some(item => selected.has(item.id)) : false;

                return (
                    <div className={cn('group')} key={title}>
                        <Checkbox
                            name='districtGroupId'
                            value={isGroupChecked || isGroupItemChecked}
                            indeterminate={isGroupItemChecked}
                            onChange={newValue => handleCheckboxGroupChange(newValue, groupIdx, groupId)}
                        >
                            <b>{ title }</b>
                        </Checkbox>
                        <div className={cn('list')}>
                            {items.map(item => (
                                <div className={cn('item', { isIntracity: item?.isIntracity })} key={item.id}>
                                    <Checkbox
                                        key={item.id}
                                        name='districtId'
                                        value={isGroupIdChecked || selected.has(item.id)}
                                        onChange={
                                            newValue => handleCheckboxChange(newValue, item.id, groupIdx, groupId)
                                        }
                                    >
                                        {item.title}
                                    </Checkbox>
                                </div>
                            ))}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
