import React, { useCallback } from 'react';

import { BuildingClass } from '@search/filter-enums/enums';
import { BuildingClassFilter } from '../../../../models/Filter';
import MobileFilter from '../../../filter/MobileFilter';
import { MultiSelect, MultiSelectOption } from '../../common/MultiSelect';

const selectOptions: MultiSelectOption[] = [ {
    value: BuildingClass.ECONOMY,
    label: 'Эконом',
    className: 'col-xs-12 filter-item-gap'
}, {
    value: BuildingClass.COMFORT,
    label: 'Комфорт',
    className: 'col-xs-12 filter-item-gap'
}, {
    value: BuildingClass.COMFORT_PLUS,
    label: 'Комфорт+',
    className: 'col-xs-12 filter-item-gap'
}, {
    value: BuildingClass.BUSINESS,
    label: 'Бизнес',
    className: 'col-xs-12 filter-item-gap'
}, {
    value: BuildingClass.ELITE,
    label: 'Элитное',
    className: 'col-xs-12 filter-item-gap'
} ];

interface IProps {
    filter: BuildingClassFilter;
    handleChange: (filter: BuildingClassFilter) => void;
}

export const MobileBuildingClassFilterView: React.FC<IProps> = ({
    filter,
    handleChange
}) => {
    const onChange = useCallback(value => {
        handleChange(new BuildingClassFilter(value));
    }, [ handleChange ]);

    return (
        <MobileFilter
            filterTitle='Класс ЖК'
        >
            <MultiSelect
                name='buildingClass'
                className='container container__clamp'
                options={selectOptions}
                value={filter.buildingClass}
                handleChange={onChange}
            />
        </MobileFilter>
    );
};
