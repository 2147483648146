import React, { useCallback } from 'react';
import { PlainSelect as Select, PlainSelectOption } from '@vtblife/uikit';
import { Commissioning, Quarter } from '@search/filter-enums/enums';

import { quarters } from '@search/helpers/src/quarters';
import { CommissioningFilter } from '../../../../models/Filter';
import MobileFilter from '../../../filter/MobileFilter';

interface IMobileCommissioningFilterViewProps {
    filter: CommissioningFilter;
    onChange: (filter: CommissioningFilter) => void;
    options?: PlainSelectOption[];
    onMap?: boolean;
}

const defaultOptions: PlainSelectOption[] = [
    {
        value: Commissioning.UNKNOWN,
        text: 'Любой срок сдачи'
    },
    {
        value: Commissioning.COMMISSIONED,
        text: 'Сдан'
    },
    {
        value: Commissioning.IN_PROGRESS,
        text: 'Строится'
    },
    ...quarters()
        .map(({ quarter, year }) => ({
            value: `${Quarter[quarter]}_${year}`,
            text: `До ${quarter} кв. ${year}`
        }))
];

export const MobileCommissioningFilterView: React.FC<IMobileCommissioningFilterViewProps> = ({
    filter,
    onChange,
    options = defaultOptions,
    onMap
}) => {
    const handleChange = useCallback(
        (val: string) => onChange(new CommissioningFilter(val)),
        [ onChange ]
    );

    let { commissioning } = filter;

    if (! options.some(({ value }) => value === commissioning)) {
        commissioning = Commissioning.UNKNOWN;
    }

    return (
        <MobileFilter>
            <div className='container'>
                <div className='col-xs-12'>
                    <Select
                        name='commissioning'
                        dataTest='commissioning'
                        size='s'
                        value={commissioning}
                        options={options}
                        onChange={v => handleChange(v as string)}
                        height={onMap ? 400 : undefined}
                        native={! onMap}
                    />
                </div>
            </div>
        </MobileFilter>
    );
};
