import React, { useEffect } from 'react';

export function useDomOnClickOutside<El extends Element>(
    refs: React.RefObject<readonly (El | undefined | null)[]>,
    handler: (e: MouseEvent | TouchEvent) => void,
    isEnabled = true,
) {
    useEffect(() => {
        const listener = (event: MouseEvent | TouchEvent) => {
            const target = event.target as El;

            if (refs.current?.some(item => ! item || item.contains(target))) {
                return;
            }

            handler(event);
        };

        if (isEnabled) {
            document.addEventListener('mousedown', listener);
            document.addEventListener('touchstart', listener);
        } else {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        }

        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ refs, handler, isEnabled ]);
}
