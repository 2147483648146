/* eslint-disable react/jsx-handler-names */
import './Mortgage2StarterModal.css';

import React, { useEffect, useMemo, useRef } from 'react';
import { Modal, Button } from '@vtblife/uikit';
import { Size } from '@vtblife/uikit/legacy';

import classname from '@search/classname/src';
import { useUserDetailsLoader } from '@search/auth/src/userDetails/userDetails';
import { MortgageOfferType } from '@search/graphql-typings/src';
import { declensionBankGenitive, declensionBankNominative } from '../../declensions';
import {
    MinimalBanksMortgageOffer, MinimalBanksMortgageOfferWithDiscount
} from '../../MinimalBanksMortgageOffers/MinimalBanksMortgageOffers';
import {
    Mortgage2Field, MortgageContactFields, Mortgage2FieldControls, Mortgage2FieldPercent
} from '../field/Mortgage2Field';
import { mortgage2ParamsFromClasified } from '../mortgage2ParamsFromClasified';
import { mortgage2BestOfferFromOffers } from '../useMortgage2BestOffer';
import { useMortgage2Request } from '../useMortgage2Request';
import { Mortgage2StarterProps } from '../useMortgage2User';
import type { Mortgage2ContactsFormSendProps } from '../mortgage2ContactsFormSend';
import { Mortgage2SuccessContent } from '../success/Mortgage2SuccessContent';
import { VTB_ID } from '../../mortgage3/useChangeDiscount';
import { MortgageGovernmentDisclaimer } from '../../MortgageGovernmentDisclaimer/MortgageGovernmentDisclaimer';
import { MortgageGovernmentDisclaimerModal } from '../../MortgageGovernmentDisclaimer/MortgageGovernmentDisclaimerModal';
import { MortgageOfferIcon } from '../../../BanksMortgageOffers/MortgageOfferIcon';
import { defaultBanksTotal } from '../../contants';
import { getGovernmentMortgage, useGovernmentMortgageType } from '../../useGovernmentMortgage';
import { dataLayerPush } from '../../../../../../domain/google/dataLayerPush';

const cn = classname.bind(null, 'Mortgage2StarterModal');
const COUNT_ICONS_BANK_SHOWING = 2;

type FieldsModal = 'rate' | 'payment' | 'cost' | 'ownAmount' | 'term' | 'all';
// eslint-disable-next-line complexity
export function Mortgage2StarterModal({
    mode = 'default',
    isDefaultSelectedOffer,
    showMoreBankIcons,
    governmentMortgage: initialGovernmentMortgage,
    fields = [ 'all' ],
    isNewVibe,
    alwaysFromWord,
    ...props
}: Mortgage2StarterProps
    & Pick<Parameters<typeof useMortgage2Request>[0], 'realtyType'>
    & { governmentMortgage?: useGovernmentMortgageType }
    & {
        isPreferentialNbCondition?: boolean;
        isMobile?: boolean;
        onClose?(e?: React.MouseEvent | React.KeyboardEvent): void;
        term?: number;
        propertyCost: number;
        ownAmount: number;
        mortgageOffers?: { minimalPercentDiscount?: number} & readonly MinimalBanksMortgageOffer[] | null;
        selectedMortgageOffer?: MinimalBanksMortgageOfferWithDiscount;
        withoutAuth?: boolean;
        shouldVTBDiscountLogic?: boolean;
        showCallbackHours?: boolean;
        mode?: 'default' | 'min-data';
        isDefaultSelectedOffer?: boolean;
        showMoreBankIcons?: boolean;
        fields?: FieldsModal[];
        isNewVibe?: boolean;
        alwaysFromWord?: boolean;
        withoutShowContainer?: boolean;
    }
    & Mortgage2ContactsFormSendProps
) {
    const { selectedMortgageOffer } = props;

    const params = mortgage2ParamsFromClasified(props);
    const banksTotal = selectedMortgageOffer ? defaultBanksTotal - 1 : defaultBanksTotal;
    const bestMortgageOffer = selectedMortgageOffer ?? mortgage2BestOfferFromOffers(props.mortgageOffers);
    const isPreferentialOffer = bestMortgageOffer?.type === MortgageOfferType.Preferential || bestMortgageOffer?.type === MortgageOfferType.Family;

    const userInfoLoad = useUserDetailsLoader();
    const userInfo = userInfoLoad({});

    const governmentMortgage = useMemo(() => {
        return initialGovernmentMortgage ?? getGovernmentMortgage({
            regionId: props.regionId,
            propertyCost: props.propertyCost,
            ownAmount: props.ownAmount,
            isPreferentialNbCondition: props.isPreferentialNbCondition,
        });
    }, [ initialGovernmentMortgage, props.regionId, props.propertyCost, props.ownAmount, props.isPreferentialNbCondition ]);
    const domain = useMortgage2Request({
        ...props,
        ...governmentMortgage,
        userDetails: userInfo.data?.userProfile.person,
        banksTotal,
        selectedMortgageOffer,
        bestMortgageOffer,
        propertyCost: params.propertyCost,
        ownAmount: params.ownAmount
    });

    const isFirstOpen = useRef(true);

    useEffect(() => {
        if (props.withoutShowContainer && isFirstOpen.current) {
            props.gtmShowId && dataLayerPush({ event: props.gtmShowId });
            props.gtmShowData && dataLayerPush(props.gtmShowData);
            isFirstOpen.current = false;
        }
    }, [ props.gtmShowId, props.withoutShowContainer, props.gtmShowData ]);

    const [ isModalOpened, setIsModalOpened ] = React.useState(false);
    const banksIcons = props.mortgageOffers?.filter(item => item.bankId !== bestMortgageOffer?.bankId).slice(0, COUNT_ICONS_BANK_SHOWING);

    const isTitlePercentAccent = useMemo(() => {
        if (domain.minimalPercent === domain.minimalPercentOld) return false;
        if (props.shouldVTBDiscountLogic) return bestMortgageOffer?.bankId === VTB_ID;
        return ! selectedMortgageOffer;
    }, [ domain.minimalPercent, domain.minimalPercentOld, props.shouldVTBDiscountLogic, bestMortgageOffer?.bankId, selectedMortgageOffer ]);

    const fromWord = useMemo(() => ({
        default: alwaysFromWord ? 'от\u00A0' : '',
        payment: selectedMortgageOffer && ! isPreferentialOffer && ! alwaysFromWord ? '' : 'от\u00A0',
        percent: alwaysFromWord || ! selectedMortgageOffer || isDefaultSelectedOffer || isPreferentialOffer,
    }), [ alwaysFromWord, selectedMortgageOffer, isDefaultSelectedOffer, isPreferentialOffer ]);

    const renderFieldNodes = useMemo(() => {
        const result: React.ReactNode[] = [];

        if (fields.includes('rate') || fields.includes('all')) {
            result.push(<Mortgage2Field
                key='rate'
                space={Size.Small}
                titleSize={Size.Small}
                titleAccent={isTitlePercentAccent}
                title={<Mortgage2FieldPercent
                    isFrom={fromWord.percent}
                    isAccent={isPreferentialOffer || isTitlePercentAccent}
                    minimalPercent={domain.minimalPercent}
                    minimalPercentOld={domain.minimalPercentOld}
                />}
                children={isPreferentialOffer ? 'ипотека с\u00A0господдержкой' : 'ставка банка'}
            />);
        }
        if (fields.includes('payment') || fields.includes('all')) {
            result.push(<Mortgage2Field
                key='payment'
                space={Size.Small}
                titleSize={Size.Small}
                titleAccent={isTitlePercentAccent}
                title={<>{fromWord.payment}{
                    bestMortgageOffer?.minimalPaymentDiscountFormatted ?? bestMortgageOffer?.minimalPaymentFormatted
                }</>}
                children='ежемесячный платёж'
            />);
        }
        if (fields.includes('cost') || fields.includes('all')) {
            result.push(<Mortgage2Field
                key='cost'
                space={Size.Small}
                titleSize={Size.Small}
                title={<>{fromWord.default}{domain.propertyCostFormatted}</>}
                children={`стоимость ${domain.realtyTypeFormattedLocative}`}
            />);
        }
        if (fields.includes('ownAmount') || fields.includes('all')) {
            result.push(<Mortgage2Field
                key='ownAmount'
                space={Size.Small}
                titleSize={Size.Small}
                title={<>{fromWord.default}{domain.ownAmountFormatted}</>}
                children='первоначальный взнос'
            />);
        }
        if ((fields.includes('term') || fields.includes('all')) && ! props.isMobile) {
            result.push(<Mortgage2Field
                key='term'
                space={Size.Small}
                titleSize={Size.Small}
                title={<>{props.term} лет</>}
                children='срок кредита'
            />);
        }

        return result;
    }, [
        fields, domain, props.term, props.isMobile, isTitlePercentAccent, selectedMortgageOffer, isDefaultSelectedOffer, isPreferentialOffer,
        domain.minimalPercent, domain.minimalPercentOld, bestMortgageOffer, domain.propertyCostFormatted, domain.realtyTypeFormattedLocative,
        domain.ownAmountFormatted
    ]);

    const banksInfo = (
        <div className={cn('descr')}>
            <div className={cn('descr-text')}>
                {selectedMortgageOffer && (<>Подайте заявку в&nbsp;{selectedMortgageOffer.name
                } и&nbsp;еще <span>{banksTotal}&nbsp;{
                    declensionBankNominative(banksTotal)}</span>.</>)}
                {! selectedMortgageOffer && (<>Получите одобрение по&nbsp;ипотеке в&nbsp;течение {domain.timeFromBanksFormatted
                } от&nbsp;<span>{banksTotal}&nbsp;{declensionBankGenitive(banksTotal)}.</span></>)}
            </div>
            {/* not important all conditions government mortgage */}
            {(governmentMortgage.isPreferentialNbCondition || isNewVibe) && (<>
                <div className={cn('icons')}>
                    {bestMortgageOffer && (
                        <MortgageOfferIcon offer={bestMortgageOffer} size='m' />
                    )}
                    {banksIcons && showMoreBankIcons && (<>
                        {banksIcons.map(item => (
                            <MortgageOfferIcon key={item.bankId} offer={item} size='m' />
                        ))}
                        <MortgageOfferIcon pseudoCount={banksTotal - COUNT_ICONS_BANK_SHOWING} size='m' />
                    </>)}
                </div>
            </>)}
        </div>
    );

    const content = (<div className={cn('main')}>
        {props.isMobile ? (
            <div className={cn('banksInfo')}>
                {banksInfo}
            </div>
        ) : (
            <Mortgage2Field
                descriptionAccent
                space={Size.Medium}
                titleSize={Size.Small}
                title={domain.textVariant === 'bankCount' ? 'Ипотека — быстро и просто!' : 'Заявка на ипотеку'}
                children={banksInfo}
            />
        )}
        <div className={cn('fields')}>
            {renderFieldNodes}
        </div>
        <MortgageContactFields
            isMobile={props.isMobile}
            domain={domain}
            showCallbackHours={props.showCallbackHours}
        />
        {/* not important all conditions government mortgage */}
        {governmentMortgage.isPreferentialNbCondition && (<div
            className={cn('disclamer')}
        >
            <MortgageGovernmentDisclaimer
                isAccent
                isMobile={props.isMobile}
                onClick={() => setIsModalOpened(true)}
            />
        </div>)}
        <Mortgage2FieldControls>
            <Button
                fullWidth
                size={props.isMobile ? 'm' : 'l'}
                disabled={domain.isFormSyncing || userInfo.loading}
                dataTest='mortgage-send-query'
                variant='accent'
                onClick={domain.sendQuery}
            >
                Подать заявку
            </Button>
            {props.isMobile ? <> <br /><br /><br /></> : null}
        </Mortgage2FieldControls>
    </div>);

    if (isModalOpened) {
        return (<MortgageGovernmentDisclaimerModal
            isMobile={props.isMobile}
            onSuccess={() => setIsModalOpened(false)}
            onClose={() => setIsModalOpened(false)}
        />);
    }

    return (<Modal
        opened
        // @ts-expect-error
        onClose={props.onClose}
        mobile={props.isMobile}
        swipeToClose={! props.isMobile}
    >
        {props.withoutAuth && domain.isFormSended ? (
            <Mortgage2SuccessContent
                title='Заявка принята'
                isMobile={props.isMobile}
            />
        ) : (<>
            {props.isMobile && (<>
                <Modal.Header>
                    {domain.textVariant === 'bankCount' ? 'Ипотека — быстро и просто!' : 'Заявка на ипотеку'}
                </Modal.Header>
                <Modal.Content scrollable>
                    {content}
                </Modal.Content>
            </>)}
            {! props.isMobile && (<>{content}</>)}
        </>)}
    </Modal>);
}
