import { SeoMapper } from '@search/router/src';

export const mskMetroBranchMapper = new SeoMapper('mskMetroLinesMapper', {
    'kaluzhsko-rizhskaya': 158,
    zamoskvoretskaya: 58,
    'serpukhovsko-timiryazevskaya': 264,
    'arbatsko-pokrovskaya': 101,
    'tagansko-krasnopresnenskaya': 155,
    'lyublinsko-dmitrovskaya': 271,
    solncevskaya: 234,
    sokolnicheskaya: 4,
    mck: 305,
    'mcd-1': 169286,
    'mcd-2': 169288
} as const);

export const stationsIdsByBranchSeo = new Map([
    [
        'zamoskvoretskaya',
        [ 59, 62, 98, 96, 94, 91, 92, 89, 84, 83, 80, 14571, 79, 77, 76, 75, 74, 73, 72, 71, 69, 67, 65, 64 ]
    ],
    [
        'sokolnicheskaya',
        [ 1842, 56, 54, 51, 49, 44, 43, 41, 39, 36, 35, 34, 33, 31, 28, 25, 24, 22, 21, 19, 18, 14, 12, 11, 9, 5 ]
    ],
    [
        'serpukhovsko-timiryazevskaya',
        [ 299, 298, 296, 294, 293, 291, 290, 288, 287, 285, 284, 283, 282,
            281, 280, 279, 278, 277, 275, 274, 272, 270, 268, 267, 265 ]
    ],
    [
        'kaluzhsko-rizhskaya',
        [ 200, 199, 197, 194, 191, 189, 188, 186, 184, 181, 3500, 152,
            180, 179, 178, 177, 159, 176, 174, 172, 170, 168, 166, 163 ]
    ],
    [
        'arbatsko-pokrovskaya',
        [ 129, 130, 131, 133, 134, 136, 137, 138, 14568, 14567, 128,
            123, 102, 105, 106, 107, 110, 112, 114, 118, 120, 117 ]
    ],
    [
        'tagansko-krasnopresnenskaya',
        [ 230, 229, 228, 226, 224, 222, 220, 217, 216, 156, 215, 214,
            213, 179, 212, 211, 1067, 210, 208, 207, 205, 203, 201 ]
    ],
    [
        'lyublinsko-dmitrovskaya',
        [ 169312, 169345, 169350, 331, 330, 328, 327, 324, 322, 321, 319, 318, 317,
            316, 315, 314, 313, 312, 311, 309, 307, 6478, 3227, 303, 301, 272 ]
    ],
    [
        'solncevskaya',
        [ 169341, 169321, 235, 238, 240, 242, 244,
            246, 248, 249, 250, 251, 120, 253 ]
    ],
    [
        'mck',
        [ 14593, 7450, 944, 4286, 4633, 6474, 14596, 14595, 8431, 1095, 3890, 14594,
            1191, 8240, 8954, 14598, 7772, 1899, 14597, 306, 2155, 726,
            4695, 3483, 4644, 2630, 2603, 145, 332, 3192, 270 ]
    ],
    [
        'mcd-1',
        [ 169374, 169362, 169348, 169292, 169287, 169330, 169296, 118,
            169366, 169336, 169379, 169382, 274, 169291, 169327, 169322, 169364,
            169343, 169365, 169357, 169381, 169376, 169298, 169358, 211 ]
    ],
    [
        'mcd-2',
        [ 169302, 169289, 169361, 169340, 169303, 169306, 169307, 169331, 169373,
            169319, 926048, 169359, 169294, 169311, 169324, 169368, 169300, 169349,
            169325, 169353, 578220, 169309, 169372, 169371, 169308, 169315, 169314,
            169380, 169290, 169297, 169333, 309, 3483, 319, 220, 1095, 129 ]
    ],
]);
