import React, { useCallback } from 'react';
import { Checkbox } from '@vtblife/uikit';
import { IsApartmentsOptions } from '@search/filter-enums/enums/IsApartmentsOptions';

import { ApartmentFilter } from '../../../models/Filter';
import { Filter2 } from '../../new_filter/Filter2';
import { CheckboxGroup2 } from '../../common/CheckboxGroup2';

interface IApartmentFilterProps {
    filter: ApartmentFilter;
    handleChange: (filter: ApartmentFilter) => void;
}

const apartmentsMapping: Record<string, string> = {
    [IsApartmentsOptions.APARTMENTS_ONLY]: 'Апартаменты',
    [IsApartmentsOptions.NO_APARTMENTS]: 'Без апартаментов'
};

const apartmentEnumeration: IsApartmentsOptions[] = [
    IsApartmentsOptions.APARTMENTS_ONLY,
    IsApartmentsOptions.NO_APARTMENTS
];

export const DesktopApartmentFilterView: React.FC<IApartmentFilterProps> = ({
    filter,
    handleChange
}) => {
    const onChange = useCallback(val => {
        handleChange(new ApartmentFilter(val));
    }, [ handleChange ]);

    const component = apartmentEnumeration.map(apartment => {
        return (
            <Checkbox
                variant='filter'
                key={apartment}
                name={String(apartment)}
                block
            >
                {apartmentsMapping[apartment]}
            </Checkbox>
        );
    });

    return (
        <Filter2 filterTitle='Апартаменты'>
            <CheckboxGroup2
                mode='radio-check'
                name='apartment'
                onChange={onChange}
                value={[ filter.isApartment ].map(elem => String(elem))}
                isFilter
            >
                {component}
            </CheckboxGroup2>
        </Filter2>
    );
};
