import React, { useCallback } from 'react';
import { Checkbox } from '@vtblife/uikit';
import { CeilingHeight } from '@search/filter-enums/enums/CeilingHeight';

import { CellHeightFilter } from '../../../models/Filter';
import { Filter2 } from '../../new_filter/Filter2';
import { CheckboxGroup2 } from '../../common/CheckboxGroup2';

interface ICeilHeightFilterProps {
    filter: CellHeightFilter;
    handleChange: (value: CellHeightFilter) => void;
}

const ceilHeightEnumeration = [
    { title: '2,5 м', value: CeilingHeight.FROM_2_5 },
    { title: '2,7 м', value: CeilingHeight.FROM_2_7 },
    { title: '3 м', value: CeilingHeight.FROM_3 },
    { title: '4 м', value: CeilingHeight.FROM_4 }
];

export const CeilHeightFilterView: React.FC<ICeilHeightFilterProps> = ({
    filter,
    handleChange
}) => {
    const onChange = useCallback(value => {
        handleChange(new CellHeightFilter(Number(value)));
    }, [ handleChange ]);

    const component = ceilHeightEnumeration.map((height, i) => {
        return (
            <Checkbox
                variant='filter'
                key={height.value}
                name={height.value}
                block
            >
                от {height.title}
            </Checkbox>
        );
    });
    const value = (filter.value) ? [ filter.value ] : [];

    return (
        <Filter2 filterTitle='Высота потолков'>
            <CheckboxGroup2
                mode='radio-check'
                onChange={onChange}
                name='ceilHeight'
                value={value}
                isFilter
            >
                {component}
            </CheckboxGroup2>
        </Filter2>
    );
};
