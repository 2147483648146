import sortBy from 'lodash/sortBy';

import { RegionIdEnum } from '@search/filter-enums/enums/Region';

import type { District } from '../../../domain/geo/District';

export type DistrictGroup = {
    id?: number;
    title: string;
    items: ({
        id: number;
        title: string;
        isIntracity?: boolean;
    })[];
}

type GroupMskDistrictsParams = {
    districts?: District[];
    regionId: RegionIdEnum;
}

const prepareTitle = (title: string) => {
    return title.replace(/(район|округ|городской|административный|посёлок)/g, '');
};

const splitListByParentId = (list: District[] | undefined, parentId: number) => {
    const ret: [ DistrictGroup['items'], DistrictGroup['items'] ] = [ [], [] ];

    list?.forEach(item => {
        ret[item.narrowRegionId === parentId ? 0 : 1].push(item);
    });

    return ret;
};

const sortDistricts = (districts: DistrictGroup['items']): DistrictGroup['items'] => {
    return districts.sort((a, b) => {
        const titleA = a.title.trim();
        const titleB = b.title.trim();

        // if (titleA === titleB) return 0;

        return titleA > titleB ? 1 : -1;
    });
};

const prepareList = (list?: DistrictGroup['items']): DistrictGroup['items'] => {
    const ret: DistrictGroup['items'] = [];

    list?.forEach(item => {
        ret.push({
            id: item.id,
            isIntracity: item.isIntracity ?? false,
            title: prepareTitle(item.title)
        });
    });

    return sortDistricts(ret);
};

const groupMskDistricts = ({ districts, regionId }: GroupMskDistrictsParams): DistrictGroup[] => {
    const districtsMap = new Map<number | string, DistrictGroup>();

    districts?.forEach(district => {
        if (district.parentId === RegionIdEnum.MSK) {
            let districtGroup = districtsMap.get(district.id);

            if (! districtGroup) {
                districtGroup = {
                    id: district.id,
                    title: district.title,
                    items: []
                };

                districtsMap.set(district.id, districtGroup);
            }
        }
    });

    if (regionId === RegionIdEnum.MSK_AND_MSK_OBL) {
        const mskOblGroup = {
            title: 'Районы Московской области',
            items: []
        };

        districtsMap.set('mskObl', mskOblGroup);
    }

    districts?.forEach(district => {
        if (district.parentId === RegionIdEnum.MSK_OBL) {
            const districtGroup = districtsMap.get('mskObl');

            districtGroup?.items.push({
                id: district.id,
                title: prepareTitle(district.title)
            });
        }

        if (district.parentId !== RegionIdEnum.MSK && district.parentId !== RegionIdEnum.MSK_OBL) {
            const districtGroup = districtsMap.get(district.parentId);

            districtGroup?.items.push({
                id: district.id,
                title: prepareTitle(district.title)
            });
        }
    });

    return sortBy(Array.from(districtsMap.values()), o => {
        const sortedNestedDistricts = { ...o, items: sortDistricts(o.items) };

        return sortedNestedDistricts.title === 'Районы Московской области' ? false : sortedNestedDistricts.title;
    });
};

export const groupDistricts = ({ districts, regionId }: GroupMskDistrictsParams): DistrictGroup[] => {
    if ((districts?.length ?? 0) <= 0) return [];

    switch (regionId) {
        case RegionIdEnum.MSK_AND_MSK_OBL: {
            return groupMskDistricts({ districts, regionId });
        }

        case RegionIdEnum.MSK_OBL: {
            return [
                {
                    title: 'Районы Московской области',
                    items: prepareList(districts)
                }
            ];
        }

        case RegionIdEnum.MSK: {
            return groupMskDistricts({ districts, regionId });
        }

        case RegionIdEnum.SPB_AND_LEN_OBL: {
            const splited = splitListByParentId(districts, 444);

            return [
                {
                    title: 'Районы Санкт-Петербурга',
                    items: prepareList(splited[0])
                },
                {
                    title: 'Районы Ленинградской области',
                    items: prepareList(splited[1])
                }
            ];
        }

        case RegionIdEnum.LEN_OBL: {
            return [
                {
                    title: 'Районы Ленинградской области',
                    items: prepareList(districts)
                }
            ];
        }

        case RegionIdEnum.SPB: {
            return [
                {
                    title: 'Районы Санкт-Петербурга',
                    items: prepareList(districts)
                }
            ];
        }

        default:
            return [
                {
                    title: 'Районы',
                    items: prepareList(districts)
                }
            ];
    }
};

export const getDistrictCount = (districtIds: Array<number>, groups: Array<DistrictGroup>) => (
    districtIds.reduce((count, districtId) => (
        count + (groups.find(group => group.id === districtId)?.items.length ?? 1)
    ), 0)
);
