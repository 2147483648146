import React, { useCallback } from 'react';
import { Size } from '@vtblife/uikit/legacy';

import { Filter2 } from '../../new_filter/Filter2';
import { NumberInputRange } from '../../common/NewNumberInputRange/NumberInputRange';
import { LivingAreaFilter } from '../../../models/Filter';
import { INumberRange } from '../../../types/IRange';

interface ILivingAreaFilterProps {
    filter: LivingAreaFilter;
    handleChange: (value: LivingAreaFilter) => void;
}

export const LivingAreaFilterView: React.FC<ILivingAreaFilterProps> = ({
    filter,
    handleChange
}) => {
    const onChange = useCallback((value: INumberRange) => {
        handleChange(new LivingAreaFilter(value));
    }, [ handleChange ]);

    return (
        <Filter2 filterTitle='Жилая'>
            <NumberInputRange
                size={Size.Small}
                unit='м²'
                value={filter.value}
                onChange={onChange}
                nameFrom='livingAreaFrom'
                nameTo='livingAreaTo'
                max={100_000}
            ></NumberInputRange>
        </Filter2>
    );
};

