import './DistrictSelect.css';

import React from 'react';

import classname from '@search/classname/src';

import { DistrictControl } from '../../../../../../common/components/DistrictControl';
import type { DistrictGroup } from '../../../../../helpers/districtHelper';

import { useDistrictSelect } from './useDistrictSelect';
import { DistrictSelectItem } from './components/DistrictSelectItem';
import { DistrictSelectGroup } from './components/DistrictSelectGroup';

const cn = classname.bind(null, 'DistrictSelect');

export interface DistrictSelectProps {
    groups: DistrictGroup[];
    checked: readonly number[];
    onChange(checkedDiff: number[], uncheckedDiff: Set<number>): void;
    regionId: number;
    limit?: Limit;
    className?: string;
}

export enum Limit {
    noLimit,
    hasLimit
}

export const DistrictSelect: React.FC<DistrictSelectProps> = ({
    groups,
    checked,
    onChange,
    limit = Limit.hasLimit,
    className,
    regionId
}) => {
    const {
        isChecked,
        checkSet: selectedDistricts,
        checkGroup,
        checkItem
    } = useDistrictSelect({
        checked,
        onChange,
        groups
    });

    return (
        <div className={cn(null, { limit: limit === Limit.hasLimit ? 'limited' : 'unlimited' }, className)}>
            <DistrictControl
                className={cn('controls')}
                regionId={regionId}
                onChange={newValues => {
                    onChange(newValues.diff.selected, new Set(newValues.diff.deselected));
                }}
                selectedDistricts={selectedDistricts}
            />
            <div className={cn((limit === Limit.hasLimit) ? 'items-limited' : 'items-unlimited')}>
                {groups.map((group, groupIdx, arr) => {
                    const { items, id: groupId } = group;

                    const isGroupIdChecked = groupId && isChecked(groupId);
                    const isGroupChecked = isGroupIdChecked || items.every(item => isChecked(item.id));

                    return (
                        <div key={group.title}>
                            <DistrictSelectGroup
                                title={group.title}
                                checked={isGroupChecked}
                                onCheck={newIsChecked => checkGroup(newIsChecked, groupIdx, groupId)}
                            />

                            {items.map(district => (
                                <DistrictSelectItem
                                    key={district.id}
                                    district={district}
                                    checked={isGroupIdChecked || isChecked(district.id)}
                                    onCheck={newIsChecked => checkItem(newIsChecked, district.id, groupIdx, groupId)}
                                />
                            ))}

                            {arr.length - 1 !== groupIdx ? (
                                <div className={cn('groupSeparator')} />
                            ) : null}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
