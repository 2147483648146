/* eslint-disable no-shadow */
import React, { useCallback } from 'react';

import { Checkbox } from '@vtblife/uikit';
import { Size } from '@vtblife/uikit/legacy';
import classname from '@search/classname/src';
import { FloorConstraints } from '@search/filter-enums/enums/FloorConstraints';

import { FloorFilter } from '../../../models/Filter';
import { NumberInputRange } from '../../common/NewNumberInputRange/NumberInputRange';
import { Filter2, IFilterAvailableProps } from '../../new_filter/Filter2';
import { CheckboxGroup2, ICheckboxGroupModificationProps } from '../../common/CheckboxGroup2';

import './FloorFilter.css';

const cn = classname.bind(null, 'FloorFilter');

interface IFloorFilterProps extends IFilterAvailableProps, ICheckboxGroupModificationProps {
    filter: FloorFilter;
    handleChange: (value: FloorFilter) => void;
    renderFloorTotalFilter?: React.ReactElement;
    classNameSubGroup?: string;
}

enum Floors {
    NotFirst = 1,
    NotLast = 2,
    Last = 3
}

const floorOptions = [ {
    label: 'Не первый',
    value: Floors.NotFirst
}, {
    label: 'Не последний',
    value: Floors.NotLast
}, {
    label: 'Последний',
    value: Floors.Last
} ];

export const FloorFilterView: React.FC<IFloorFilterProps> = ({
    filter,
    handleChange,
    renderFloorTotalFilter,
    withoutTitle,
    withoutGrid,
    classNameGroup
}) => {
    const value = getValue(filter);
    const valueSet = new Set(value);
    const onChangeCtx = { filter, handleChange, valueSet };
    const onChangeCtxRef = React.useRef(onChangeCtx);

    onChangeCtxRef.current = onChangeCtx;

    const onChange = useCallback((values: Floors[]) => {
        const { filter, handleChange, valueSet } = onChangeCtxRef.current;

        let newValue: Floors | undefined;
        let isFirstSeen = false;
        let isLastSeen = false;

        values.forEach(val => {
            const isNew = ! valueSet.has(val);

            if (isNew) newValue = val;
        });

        let last: FloorConstraints = FloorConstraints.UNKNOWN;
        let first: FloorConstraints = FloorConstraints.UNKNOWN;

        if (newValue !== undefined) {
            values.unshift(newValue);
        }

        values.forEach(newValue => {
            if (! isFirstSeen && newValue === Floors.NotFirst) {
                isFirstSeen = true;
                first = FloorConstraints.EXCLUDE;
            }
            if (! isLastSeen && (newValue === Floors.NotLast || newValue === Floors.Last)) {
                isLastSeen = true;
                if (newValue === Floors.NotLast) last = FloorConstraints.EXCLUDE;
                if (newValue === Floors.Last) last = FloorConstraints.ONLY;
            }
        });
        handleChange(new FloorFilter(filter.range, first, last));
    }, [ ]);

    const handleRangeChange = useCallback(value => {
        handleChange(new FloorFilter(value, filter.first, filter.last));
    }, [ filter, handleChange ]);

    const renderCheckboxGroup = (
        <CheckboxGroup2
            mode='check'
            onChange={onChange}
            name='floor'
            value={value}
            withoutGrid={withoutGrid}
            classNameGroup={classNameGroup}
            isFilter
        >
            {floorOptions.map(({ value: floorValue, label }) => (
                <Checkbox
                    variant='filter'
                    name={floorValue}
                    key={floorValue}
                    block
                >
                    {label}
                </Checkbox>
            ))}
        </CheckboxGroup2>
    );

    const renderFloorFilter = (
        <Filter2
            filterTitle={withoutTitle ? undefined : 'Этаж'}
        >
            <NumberInputRange
                size={Size.Small}
                fromLabel='от'
                toLabel='до'
                value={filter.range}
                onChange={handleRangeChange}
                max={1_000}
                nameFrom='floorFrom'
                nameTo='floorTo'
            />
        </Filter2>
    );

    return (<>
        <div className={cn('group')}>
            {renderFloorFilter}
            {renderFloorTotalFilter}
        </div>
        {renderCheckboxGroup}
    </>);
};

function getValue(filter: FloorFilter): Floors[] {
    const result = [];

    if (FloorConstraints.EXCLUDE === filter.first) {
        result.push(Floors.NotFirst);
    }
    if (FloorConstraints.EXCLUDE === filter.last) {
        result.push(Floors.NotLast);
    }
    if (FloorConstraints.ONLY === filter.last) {
        result.push(Floors.Last);
    }

    return result;
}
