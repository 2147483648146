import classname from '@search/classname/src';
import React from 'react';
import './MetroMark.css';

const cnMetroMark = classname.bind(null, 'MetroMark');

export const MetroMark: React.FunctionComponent<{
    className?: string;
    colors: readonly string[];
}> = ({ colors, className }) => {
    return (
        <span className={cnMetroMark(null, {}, className)}>
            {
                colors.slice(0).reverse().map(color => (
                    <span
                        key={color}
                        style={{ backgroundColor: color }}
                        className={cnMetroMark('line', { type: 'mark' })}
                    />
                ))
            }
        </span>
    );
};
