import React, { useState } from 'react';

export interface ISnippetContext {
    state: ISnippetProviderContext;
    toggleMap: () => void;
    openMap: () => void;
    closeMap: () => void;
    setState: React.Dispatch<React.SetStateAction<ISnippetProviderContext>>;
}

export interface ISnippetProviderContext {
    isOpenMap: boolean;
}

export const SnippetContext = React.createContext<ISnippetContext | undefined>(undefined);

export const SnippetProviderContext: React.FunctionComponent<{}> = props => {
    const [ state, setState ] = useState<ISnippetProviderContext>({ isOpenMap: false });
    const toggleMap = () => {
        setState(prevState => ({ ...prevState, isOpenMap: ! prevState.isOpenMap }));
    };
    const openMap = () => {
        setState(prevState => prevState.isOpenMap ? prevState : { ...prevState, isOpenMap: true });
    };
    const closeMap = () => {
        setState(prevState => ! prevState.isOpenMap ? prevState : { ...prevState, isOpenMap: false });
    };

    return (
        <SnippetContext.Provider value={{ state, toggleMap, openMap, setState, closeMap }}>
            {props.children}
        </SnippetContext.Provider>
    );
};
