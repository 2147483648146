import { SeoMapper } from '@search/router/src';
import { AuthorTypeOptionsWithRealtor } from '@search/filter-enums/enums/AuthorTypeOptions';

export const authorTypeMapper = new SeoMapper('authorTypeMapper', {
    unknown: AuthorTypeOptionsWithRealtor.UNKNOWN,
    owner: AuthorTypeOptionsWithRealtor.OWNER,
    agency: AuthorTypeOptionsWithRealtor.AGENCY,
    developer: AuthorTypeOptionsWithRealtor.DEVELOPER,
    partner: AuthorTypeOptionsWithRealtor.PARTNER,
    realtor: AuthorTypeOptionsWithRealtor.REALTOR,
    pit: AuthorTypeOptionsWithRealtor.PIT
} as const);
