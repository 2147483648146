import './DistrictSelectItem.css';

import React from 'react';
import Icon from '@vtblife/uikit-icons';
import { Typography } from '@vtblife/uikit';

import classname from '@search/classname/src';

import type { DistrictGroup } from '../../../../../../../helpers/districtHelper';
import type { ArrayElement } from '../../../../../../../../../types';

interface DistrictSelectItemProps {
    district: ArrayElement<DistrictGroup['items']>;
    checked: boolean;
    onCheck: (checked: boolean) => void;
}

const cnStation = classname.bind(null, 'DistrictSelect__Item');

export const DistrictSelectItem: React.FC<DistrictSelectItemProps> = ({
    district,
    checked,
    onCheck
}) => {
    return (
        <div
            role='button'
            className={cnStation(null, { checked })}
            onClick={() => onCheck(! checked)}
        >
            <Typography
                bold={district.isIntracity}
                variant='primary'
                color={checked ? 'blue-500' : 'primary'}
            >
                {district.title}
            </Typography>
            {checked ? <Icon name='check' /> : null}
        </div>
    );
};
