import { graphql } from '@search/gql-client/src';

export const SearchOffersMapQuery = graphql`query SearchOffersMapQuery(
    $regionId: Int!,
    $nbId: Int!,
    $skipGeo: Boolean!,
    $skipNb: Boolean!,
    $geoIds: [Int!],
    $filters: ProfessionalSearchInput,
    $taglessOnly: Boolean!,
) {
    newBuilding(
        id: $nbId
    ) @skip(if: $skipNb) {
        offersSeo {
            h1
        }
    }
    geo(regionId: $regionId, geoIds: $geoIds) @skip(if: $skipGeo) {
        metro {
            id
            title
            kind
            lines
            mainName
            interchangedStations
            lines
            shortDisplayName
            locativeDisplayName
            route {
                id
            }
        }

        lines {
            id
            title
            kind
            color
        }

        districts {
            kind
            parentId
            displayName
            mainName
            shortDisplayName
            locative
            title
            id
            isIntracity
            narrowRegion {
                id
            }
        }
    }
    searchOffersCount(filters: $filters) {
        count
    }
    searchOffersMap(filters: $filters, taglessOnly: $taglessOnly) {
        tileUrl @skip(if: $taglessOnly)
        points {
            __typename
            ... on TaglessOfferPoint {
                geoHash
                coordinates {
                    latitude
                    longitude
                }
            }
            ... on MultiOfferPointFlat {
                id
                addressId
                geoHash
                count
                coordinates {
                    latitude
                    longitude
                }
                minPriceRub
                minPrice {
                    value
                    currency
                    areaUnit
                }
                minArea {
                    unit
                    value
                }
                maxArea {
                    unit
                    value
                }
                isNewBuilding
                hasHighlight
            }
            ... on MultiOfferPointRoom {
                id
                addressId
                geoHash
                count
                coordinates {
                    latitude
                    longitude
                }
                minPriceRub
                minPrice {
                    value
                    currency
                    areaUnit
                }
                minArea {
                    unit
                    value
                }
                maxArea {
                    unit
                    value
                }
                isNewBuilding
                hasHighlight
            }
            ... on MultiOfferPointHouse {
                id
                addressId
                geoHash
                count
                coordinates {
                    latitude
                    longitude
                }
                minPriceRub
                minPrice {
                    value
                    currency
                    areaUnit
                }
                minArea {
                    unit
                    value
                }
                maxArea {
                    unit
                    value
                }
                isNewBuilding
                hasHighlight
            }
            ... on MultiOfferPointLand {
                id
                addressId
                geoHash
                count
                coordinates {
                    latitude
                    longitude
                }
                minPriceRub
                minPrice {
                    value
                    currency
                    areaUnit
                }
                minArea {
                    unit
                    value
                }
                maxArea {
                    unit
                    value
                }
                isNewBuilding
                hasHighlight
            }
            ... on SingleOfferPointFlat {
                offer {
                    id
                    isFavorite
                    stats {
                        isViewed
                    }
                    location {
                        narrowRegion {
                            id
                        }
                        coordinates {
                            latitude
                            longitude
                        }
                    }
                    dealType {
                        __typename
                        ... on ProfOfferDealSell {
                            price {
                                value
                                rubPrice
                                currency
                                areaUnit
                                history {
                                    valueRub
                                    diff {
                                        rub
                                    }
                                    date2 {
                                        iso
                                        formattedAutoDate
                                    }
                                }
                            }
                        }
                        ... on ProfOfferDealRent {
                            utilities {
                                has
                            }
                            price {
                                value
                                rubPrice
                                currency
                                areaUnit
                                history {
                                    valueRub
                                    diff {
                                        rub
                                    }
                                    date2 {
                                        iso
                                        formattedAutoDate
                                    }
                                }
                            }
                        }
                    }
                    gallery {
                        baseUrl
                        mainImage {
                            originPath
                            medium
                            preview1
                        }
                        images {
                            originPath
                            medium
                            preview1
                        }
                    }
                    realtyObject {
                        __typename
                        ... on ProfOfferFlat {
                            roomType
                            building {
                                isNewbuilding
                                floorsTotal
                            }
                            characteristics {
                                floor
                            }
                            totalArea {
                                value
                                unit
                            }
                        }
                    }
                    status {
                        hasHighlight
                    }
                }
            }

            ... on SingleOfferPointRoom {
                offer {
                    id
                    isFavorite
                    stats {
                        isViewed
                    }
                    location {
                        narrowRegion {
                            id
                        }
                        coordinates {
                            latitude
                            longitude
                        }
                    }
                    dealType {
                        __typename
                        ... on ProfOfferDealSell {
                            price {
                                value
                                rubPrice
                                currency
                                areaUnit
                                history {
                                    valueRub
                                    diff {
                                        rub
                                    }
                                    date2 {
                                        iso
                                        formattedAutoDate
                                    }
                                }
                            }
                        }
                        ... on ProfOfferDealRent {
                            utilities {
                                has
                            }
                            price {
                                value
                                rubPrice
                                currency
                                areaUnit
                                history {
                                    valueRub
                                    diff {
                                        rub
                                    }
                                    date2 {
                                        iso
                                        formattedAutoDate
                                    }
                                }
                            }
                        }
                    }
                    gallery {
                        baseUrl
                        mainImage {
                            originPath
                            preview1
                        }
                        images {
                            originPath
                            preview1
                        }
                    }
                    realtyObject {
                        __typename
                        ... on ProfOfferRoom {
                            livingArea {
                                value
                                unit
                            }
                            enclosingFlat {
                                floor
                                building {
                                    isNewbuilding
                                    floorsTotal
                                }
                            }
                        }
                    }
                    status {
                        hasHighlight
                    }
                }
            }

            ... on SingleOfferPointHouse {
                offer {
                    id
                    isFavorite
                    stats {
                        isViewed
                    }
                    location {
                        narrowRegion {
                            id
                        }
                        coordinates {
                            latitude
                            longitude
                        }
                    }
                    dealType {
                        __typename
                        ... on ProfOfferDealSell {
                            price {
                                value
                                rubPrice
                                currency
                                areaUnit
                                history {
                                    valueRub
                                    diff {
                                        rub
                                    }
                                    date2 {
                                        iso
                                        formattedAutoDate
                                    }
                                }
                            }
                        }
                        ... on ProfOfferDealRent {
                            utilities {
                                has
                            }
                            price {
                                value
                                rubPrice
                                currency
                                areaUnit
                                history {
                                    valueRub
                                    diff {
                                        rub
                                    }
                                    date2 {
                                        iso
                                        formattedAutoDate
                                    }
                                }
                            }
                        }
                    }
                    gallery {
                        baseUrl
                        mainImage {
                            originPath
                            preview1
                        }
                        images {
                            originPath
                            preview1
                        }
                    }
                    realtyObject {
                        __typename
                        ... on ProfOfferHouse {
                            totalArea {
                                value
                                unit
                            }
                            land {
                                area {
                                    value
                                    unit
                                }
                            }
                        }
                    }
                    status {
                        hasHighlight
                    }
                }
            }

            ... on SingleOfferPointLand {
                offer {
                    id
                    isFavorite
                    stats {
                        isViewed
                    }
                    location {
                        narrowRegion {
                            id
                        }
                        coordinates {
                            latitude
                            longitude
                        }
                    }
                    dealType {
                        __typename
                        ... on ProfOfferDealSell {
                            price {
                                value
                                rubPrice
                                currency
                                areaUnit
                                history {
                                    valueRub
                                    diff {
                                        rub
                                    }
                                    date2 {
                                        iso
                                        formattedAutoDate
                                    }
                                }
                            }
                        }
                        ... on ProfOfferDealRent {
                            utilities {
                                has
                            }
                            price {
                                value
                                rubPrice
                                currency
                                areaUnit
                                history {
                                    valueRub
                                    diff {
                                        rub
                                    }
                                    date2 {
                                        iso
                                        formattedAutoDate
                                    }
                                }
                            }
                        }
                    }
                    gallery {
                        baseUrl
                        mainImage {
                            originPath
                            preview1
                        }
                        images {
                            originPath
                            preview1
                        }
                    }
                    realtyObject {
                        __typename
                        ... on ProfOfferLand {
                            area {
                                value
                                unit
                            }
                        }
                    }
                    status {
                        hasHighlight
                    }
                }
            }
        }
    }
}
`;
