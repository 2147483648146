import React, { useCallback } from 'react';
import { Size } from '@vtblife/uikit/legacy';
import { LivingAreaFilter } from '../../../../models/Filter';
import MobileFilter from '../../../filter/MobileFilter';
import '../MobileCommon.css';
import { NumberInputRange } from '../../../common/NewNumberInputRange/NumberInputRange';

interface IMobileLivingAreaFilterViewProps {
    filter: LivingAreaFilter;
    handleChange: (filter: LivingAreaFilter) => void;
}

export const MobileLivingAreaFilterView: React.FC<IMobileLivingAreaFilterViewProps> = ({
    filter,
    handleChange
}) => {
    const onChange = useCallback(value => {
        handleChange(new LivingAreaFilter(value));
    }, [ filter, handleChange ]);

    return (
        <MobileFilter
            filterTitle='Жилая'
        >
            <div className='container'>
                <div className={'col-xs-12'}>
                    <NumberInputRange
                        size={Size.Small}
                        unit='м²'
                        value={filter.value}
                        onChange={onChange}
                        max={100_000}
                        nameFrom='livingAreaFrom'
                        nameTo='livingAreaTo'
                    />
                </div>
            </div>
        </MobileFilter>
    );
};
