/* global window */

const win = typeof window === 'undefined' ? undefined : window;

export class SeoSearchUtm {
    protected static storage = win?.localStorage;

    static init() {
        if (! this.storage) return;

        const params = new URLSearchParams(window.location.search);
        const utmParams = [] as ({ key: string; value: string })[];
        let hasValues = false;

        params.forEach((value, key) => {
            if (! key.startsWith('utm_')) return;
            if (value) hasValues = true;

            utmParams.push({ key, value });
        });

        if (! hasValues) return;

        this.storage?.setItem(
            this.key,
            JSON.stringify(utmParams)
        );
    }

    protected static key = 'seoSearchUtm';

    static get() {
        try {
            const data = this.storage?.getItem(this.key);

            return data ?
                (JSON.parse(data) as ({ key: string; value: string })[]) :
                undefined;
        } catch (e) {
            e.message = 'SeoSearchUtm.get ' + e.message;
            throw e;
        }
    }
}
