import { FilterCollectionMetadata } from '../types/FilterCollectionMetadata';
import {
    ApartmentFilter,
    AreaFilter,
    AuthorFilter,
    BalconyFilter,
    BankPledgeOptionsFilter,
    BathroomFilter,
    CategoryTypeFilter,
    CellHeightFilter,
    DealTypeFilter,
    Filters,
    FloorFilter,
    FloorsTotalFilter,
    FurnitureFilter,
    GeoFilter,
    HeatingFilter,
    HouseAreaFilter,
    HouseCategoryFilter,
    IFilter,
    InfrastructureFilter,
    KitchenAreaFilter,
    LandAreaFilter,
    LandStatusFilter,
    LayoutImageOptionsFilter,
    LivingAreaFilter,
    LivingFacilityFilter,
    LivingTenantOptionFilter,
    MapBoundsFilter,
    NearbySchoolFilter,
    NewBuildingDataFilter,
    NewBuildingFilter,
    ParkingFilter,
    PhotoOptionsFilter,
    PriceFilter,
    RegionFilter,
    RenovationFilter,
    RenovationProgramFilter,
    RentConditionsFilter,
    RentPrepaymentFilter,
    RoomFilter,
    RoomsInOfferFilter,
    SellerOrganizationNameFilter,
    SellTypeFilter,
    SeoMortgageAllowedFilter,
    SupplyOptionsFilter,
    TransportLimitFilter,
    WallsTypeFilter,
    WindowsDirectionFilter,
    YearBuildingFilter,
    BuildEpochFilter,
    OnlineViewFilter,
    MortgagePaymentFilter,
    FinishingFilter,
    ClCommisioninFilter,
    CommissioningFilter
} from '../../../../view/filters/models/Filter';

type FilterCreator = () => IFilter;

export const filterCreator: Record<Filters, FilterCreator> = {
    [Filters.dealType]: () => new DealTypeFilter(),
    [Filters.categoryType]: () => new CategoryTypeFilter(),
    [Filters.rooms]: () => new RoomFilter(),
    [Filters.livingArea]: () => new LivingAreaFilter(),
    [Filters.price]: () => new PriceFilter(),
    [Filters.mortgagePayment]: () => new MortgagePaymentFilter(),
    [Filters.totalArea]: () => new AreaFilter(),
    [Filters.kitchenArea]: () => new KitchenAreaFilter(),
    [Filters.builtYear]: () => new YearBuildingFilter(),
    [Filters.floor]: () => new FloorFilter(),
    [Filters.cellHeight]: () => new CellHeightFilter(),
    [Filters.renovation]: () => new RenovationFilter(),
    [Filters.bathroom]: () => new BathroomFilter(),
    [Filters.region]: () => new RegionFilter(),
    [Filters.transportLimit]: () => new TransportLimitFilter(),
    [Filters.newBuilding]: () => new NewBuildingFilter(),
    [Filters.geo]: () => new GeoFilter(),
    [Filters.newBuildingData]: () => new NewBuildingDataFilter(),
    [Filters.walls]: () => new WallsTypeFilter(),
    [Filters.parkings]: () => new ParkingFilter(),
    [Filters.author]: () => new AuthorFilter(),
    [Filters.sellType]: () => new SellTypeFilter(),
    [Filters.infrastructure]: () => new InfrastructureFilter(),
    [Filters.balcony]: () => new BalconyFilter(),
    [Filters.photoOptions]: () => new PhotoOptionsFilter(),
    [Filters.layoutImageOptions]: () => new LayoutImageOptionsFilter(),
    [Filters.apartments]: () => new ApartmentFilter(),
    [Filters.livingFacility]: () => new LivingFacilityFilter(),
    [Filters.furniture]: () => new FurnitureFilter(),
    [Filters.livingTenantOptions]: () => new LivingTenantOptionFilter(),
    [Filters.rentPrepayment]: () => new RentPrepaymentFilter(),
    [Filters.rentConditions]: () => new RentConditionsFilter(),
    [Filters.roomsInOffer]: () => new RoomsInOfferFilter(),
    [Filters.floorsTotal]: () => new FloorsTotalFilter(),
    [Filters.landStatus]: () => new LandStatusFilter(),
    [Filters.landArea]: () => new LandAreaFilter(),
    [Filters.supplyOptions]: () => new SupplyOptionsFilter(),
    [Filters.houseCategory]: () => new HouseCategoryFilter(),
    [Filters.houseArea]: () => new HouseAreaFilter(),
    [Filters.bathroomLocation]: () => new BathroomFilter(),
    [Filters.heating]: () => new HeatingFilter(),
    [Filters.mapBounds]: () => new MapBoundsFilter(),
    [Filters.bankPledgeOptions]: () => new BankPledgeOptionsFilter(),
    [Filters.seoMortgageAllowed]: () => new SeoMortgageAllowedFilter(),
    [Filters.windowsDirection]: () => new WindowsDirectionFilter(),
    [Filters.nearbyOptions]: () => new NearbySchoolFilter(),
    [Filters.renovationProgram]: () => new RenovationProgramFilter(),
    [Filters.sellerOrganizationName]: () => new SellerOrganizationNameFilter(),
    [Filters.buildEpoch]: () => new BuildEpochFilter(),
    [Filters.onlineView]: () => new OnlineViewFilter(),
    [Filters.finishingType]: () => new FinishingFilter(),
    [Filters.commissioning]: () => new CommissioningFilter()
};

export const supportedClassifiedFilters = [
    Filters.dealType,
    Filters.categoryType,
    Filters.rooms,
    Filters.livingArea,
    Filters.price,
    Filters.mortgagePayment,
    Filters.totalArea,
    Filters.kitchenArea,
    Filters.builtYear,
    Filters.floor,
    Filters.cellHeight,
    Filters.renovation,
    Filters.bathroom,
    Filters.region,
    Filters.transportLimit,
    Filters.newBuilding,
    Filters.geo,
    Filters.newBuildingData,
    Filters.walls,
    Filters.parkings,
    Filters.author,
    Filters.sellType,
    Filters.infrastructure,
    Filters.balcony,
    Filters.photoOptions,
    Filters.layoutImageOptions,
    Filters.apartments,
    Filters.livingFacility,
    Filters.furniture,
    Filters.livingTenantOptions,
    Filters.rentPrepayment,
    Filters.rentConditions,
    Filters.roomsInOffer,
    Filters.landArea,
    Filters.landStatus,
    Filters.supplyOptions,
    Filters.houseCategory,
    Filters.houseArea,
    Filters.bathroomLocation,
    Filters.heating,
    Filters.floorsTotal,
    Filters.mapBounds,
    Filters.bankPledgeOptions,
    Filters.seoMortgageAllowed,
    Filters.windowsDirection,
    Filters.nearbyOptions,
    Filters.renovationProgram,
    Filters.sellerOrganizationName,
    Filters.buildEpoch,
    Filters.onlineView,
    Filters.finishingType,
    Filters.commissioning
];

export const sellFlatMetadata: FilterCollectionMetadata = {
    filterIds: [
        Filters.dealType,
        Filters.categoryType,
        Filters.rooms,
        Filters.livingArea,
        Filters.price,
        Filters.mortgagePayment,
        Filters.totalArea,
        Filters.kitchenArea,
        Filters.builtYear,
        Filters.floor,
        Filters.floorsTotal,
        Filters.cellHeight,
        Filters.renovation,
        Filters.bathroom,
        Filters.region,
        Filters.transportLimit,
        Filters.newBuilding,
        Filters.geo,
        Filters.newBuildingData,
        Filters.walls,
        Filters.parkings,
        Filters.author,
        Filters.sellType,
        Filters.infrastructure,
        Filters.balcony,
        Filters.photoOptions,
        Filters.layoutImageOptions,
        Filters.apartments,
        Filters.mapBounds,
        Filters.bankPledgeOptions,
        Filters.seoMortgageAllowed,
        Filters.windowsDirection,
        Filters.nearbyOptions,
        Filters.renovationProgram,
        Filters.sellerOrganizationName,
        Filters.buildEpoch,
        Filters.onlineView,
        Filters.furniture,
        Filters.finishingType,
        Filters.commissioning
    ],
    persistIds: new Set([
        Filters.dealType,
        Filters.categoryType,
        Filters.region,
        Filters.mapBounds
    ])
};

export const rentFlatMetadata: FilterCollectionMetadata = {
    filterIds: [
        Filters.dealType,
        Filters.categoryType,
        Filters.rooms,
        Filters.livingArea,
        Filters.price,
        Filters.totalArea,
        Filters.kitchenArea,
        Filters.builtYear,
        Filters.floor,
        Filters.floorsTotal,
        Filters.cellHeight,
        Filters.renovation,
        Filters.bathroom,
        Filters.region,
        Filters.transportLimit,
        Filters.geo,
        Filters.newBuildingData,
        Filters.livingFacility,
        Filters.furniture,
        Filters.livingTenantOptions,
        Filters.rentPrepayment,
        Filters.rentConditions,
        Filters.walls,
        Filters.parkings,
        Filters.author,
        Filters.sellType,
        Filters.infrastructure,
        Filters.balcony,
        Filters.photoOptions,
        Filters.layoutImageOptions,
        Filters.apartments,
        Filters.mapBounds,
        Filters.bankPledgeOptions,
        Filters.windowsDirection,
        Filters.nearbyOptions,
        Filters.sellerOrganizationName,
        Filters.onlineView,
        Filters.buildEpoch
    ],
    persistIds: new Set([
        Filters.dealType,
        Filters.categoryType,
        Filters.region,
        Filters.mapBounds
    ])
};

export const sellLandMetadata: FilterCollectionMetadata = {
    filterIds: [
        Filters.dealType,
        Filters.categoryType,
        Filters.landStatus,
        Filters.price,
        Filters.mortgagePayment,
        Filters.region,
        Filters.transportLimit,
        Filters.geo,
        Filters.author,
        Filters.sellType,
        Filters.photoOptions,
        Filters.landArea,
        Filters.supplyOptions,
        Filters.mapBounds,
        Filters.bankPledgeOptions,
        Filters.sellerOrganizationName,
        Filters.onlineView
    ],
    persistIds: new Set([
        Filters.dealType,
        Filters.categoryType,
        Filters.region,
        Filters.mapBounds
    ])
};

export const sellHouseMetadata: FilterCollectionMetadata = {
    filterIds: [
        Filters.dealType,
        Filters.categoryType,
        Filters.price,
        Filters.mortgagePayment,
        Filters.region,
        Filters.transportLimit,
        Filters.geo,
        Filters.floorsTotal,
        Filters.author,
        Filters.sellType,
        Filters.photoOptions,
        Filters.houseCategory,
        Filters.houseArea,
        Filters.landArea,
        Filters.floorsTotal,
        Filters.builtYear,
        Filters.rooms,
        Filters.bathroomLocation,
        Filters.supplyOptions,
        Filters.landStatus,
        Filters.heating,
        Filters.mapBounds,
        Filters.bankPledgeOptions,
        Filters.sellerOrganizationName,
        Filters.onlineView,
        Filters.furniture
    ],
    persistIds: new Set([
        Filters.dealType,
        Filters.categoryType,
        Filters.region,
        Filters.mapBounds
    ])
};

export const sellRoomMetadata: FilterCollectionMetadata = {
    filterIds: [
        Filters.dealType,
        Filters.categoryType,
        Filters.rooms,
        Filters.livingArea,
        Filters.price,
        Filters.mortgagePayment,
        Filters.totalArea,
        Filters.kitchenArea,
        Filters.builtYear,
        Filters.floor,
        Filters.floorsTotal,
        Filters.cellHeight,
        Filters.renovation,
        Filters.bathroom,
        Filters.region,
        Filters.transportLimit,
        Filters.geo,
        Filters.newBuildingData,
        Filters.walls,
        Filters.parkings,
        Filters.author,
        Filters.sellType,
        Filters.infrastructure,
        Filters.balcony,
        Filters.photoOptions,
        Filters.layoutImageOptions,
        Filters.apartments,
        Filters.roomsInOffer,
        Filters.mapBounds,
        Filters.bankPledgeOptions,
        Filters.windowsDirection,
        Filters.nearbyOptions,
        Filters.renovationProgram,
        Filters.sellerOrganizationName,
        Filters.buildEpoch,
        Filters.onlineView
    ],
    persistIds: new Set([
        Filters.dealType,
        Filters.categoryType,
        Filters.region,
        Filters.mapBounds
    ])
};

export const rentRoomMetadata: FilterCollectionMetadata = {
    filterIds: [
        Filters.dealType,
        Filters.categoryType,
        Filters.rooms,
        Filters.livingArea,
        Filters.price,
        Filters.totalArea,
        Filters.kitchenArea,
        Filters.builtYear,
        Filters.floor,
        Filters.floorsTotal,
        Filters.cellHeight,
        Filters.renovation,
        Filters.bathroom,
        Filters.region,
        Filters.transportLimit,
        Filters.geo,
        Filters.newBuildingData,
        Filters.livingFacility,
        Filters.furniture,
        Filters.livingTenantOptions,
        Filters.rentPrepayment,
        Filters.rentConditions,
        Filters.walls,
        Filters.parkings,
        Filters.author,
        Filters.sellType,
        Filters.infrastructure,
        Filters.balcony,
        Filters.photoOptions,
        // FIXME does we need apartments?
        Filters.apartments,
        Filters.roomsInOffer,
        Filters.mapBounds,
        Filters.bankPledgeOptions,
        Filters.windowsDirection,
        Filters.nearbyOptions,
        Filters.sellerOrganizationName,
        Filters.onlineView,
        Filters.buildEpoch
    ],
    persistIds: new Set([
        Filters.dealType,
        Filters.categoryType,
        Filters.region,
        Filters.mapBounds
    ])
};

export const rentHouseMetadata: FilterCollectionMetadata = {
    filterIds: [
        Filters.dealType,
        Filters.categoryType,
        Filters.price,
        Filters.region,
        Filters.transportLimit,
        Filters.geo,
        Filters.livingFacility,
        Filters.furniture,
        Filters.livingTenantOptions,
        Filters.rentPrepayment,
        Filters.rentConditions,
        Filters.author,
        Filters.photoOptions,
        Filters.houseCategory,
        Filters.houseArea,
        Filters.landArea,
        Filters.floorsTotal,
        Filters.floorsTotal,
        Filters.builtYear,
        Filters.rooms,
        Filters.bathroomLocation,
        Filters.supplyOptions,
        Filters.landStatus,
        Filters.heating,
        Filters.mapBounds,
        Filters.bankPledgeOptions,
        Filters.sellerOrganizationName,
        Filters.onlineView,
        Filters.furniture
    ],
    persistIds: new Set([
        Filters.dealType,
        Filters.categoryType,
        Filters.region,
        Filters.mapBounds
    ])
};
