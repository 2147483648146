import { SeoMapper } from '@search/router/src';
import { Renovation } from '@search/filter-enums/enums/Renovation';

export const renovationMapper = new SeoMapper('renovation', {
    unknown: Renovation.UNKNOWN,
    euro: Renovation.EURO,
    cosmeticheskii: Renovation.COSMETIC,
    disainerskii: Renovation.DESIGNED,
    'nujen-remont': Renovation.NEED,
    'net-remonta': Renovation.NONE
} as const);
