import { useCallback, useEffect, useRef, useState } from 'react';

import { Currency } from '@search/filter-enums/enums/Currency';
import { formatPrice } from '@search/helpers/src/formatPrice';
import { useGqlContext } from '@search/gql-client/src/gqlContext';
import { graphql } from '@search/gql-client/src/useGql';
import { useGql2Loader } from '@search/gql-client/src/useGql2';

import useDebouncedCallback from '../../../hooks/useDebouncedCallback';
import { UseBankMortgageFormProps } from '../MinimalBanksMortgageFields/useBankMortgageForm';
import { useMinimalBanksMortgageEconomy } from '../useMinimalBanksMortageState';
import { mortgage2BestOfferFromOffers } from '../mortgage2/useMortgage2BestOffer';

import type {
    MinimalBanksMortgageOffersQuery$data as MinimalBanksMortgageOffersQueryResponseGql,
    MinimalBanksMortgageOffersQuery$variables as MinimalBanksMortgageOffersQueryVariablesGql
} from './__generated__/MinimalBanksMortgageOffersQuery.graphql';

export type MinimalBanksMortgageOffersQueryResponse = MinimalBanksMortgageOffersQueryResponseGql;
export type MinimalBanksMortgageOffersQueryVariables = MinimalBanksMortgageOffersQueryVariablesGql;

export type MinimalBanksMortgageOffersQueryResponseOffer = MinimalBanksMortgageOffersQueryResponse['minimalBanksMortgageOffers'][number];

const MinimalBanksMortgageOffersQuery = graphql`
    query MinimalBanksMortgageOffersQuery(
        $term: Int!
        $isPreferential: Boolean
        $propertyCost: Float!
        $ownAmount: Float!
        $regionId: Int!
        $purpose: MortgagePurpose!
    ) {
        minimalBanksMortgageOffers(
            term: $term
            isPreferential: $isPreferential
            propertyCost: $propertyCost
            ownAmount: $ownAmount
            regionId: $regionId
            purpose: $purpose
        ) {
            name
            type
            bankId
            minimalPercent
            minimalPercentFormatted
            minimalPayment
            minimalPaymentFormatted
            icon {
                origin
            }
        }
    }
`;

export function useMinimalBanksMortgageOffersFetcher() {
    const gql = useGqlContext();

    return useCallback(
        (vars: MinimalBanksMortgageOffersQueryVariables, opts?: { signal?: AbortSignal }) => gql.client<
            MinimalBanksMortgageOffersQueryVariables,
            MinimalBanksMortgageOffersQueryResponse
        >(MinimalBanksMortgageOffersQuery, vars, opts)
            .then(response => {
                return response;
            }),
        [ gql ]
    );
}

export type MinimalBanksMortgageOffer = MinimalBanksMortgageOffersQueryResponse['minimalBanksMortgageOffers'][number];

type MinimalBanksMortgageOfferDiscount = {
    disabled?: boolean;
    minimalPercentDiscount: number;
    minimalPercentDiscountFormatted: string;
    minimalPaymentDiscount: number;
    minimalPaymentDiscountFormatted: string;
};

export type MinimalBanksMortgageOfferWithDiscount = MinimalBanksMortgageOffer &
    Partial<MinimalBanksMortgageOfferDiscount>;

export function useMinimalBanksMortgageOffersLoader() {
    return useGql2Loader(
        useMinimalBanksMortgageOffersFetcher(),
        { cachePrefix: 'minimalBanksMortgageOffers' }
    );
}

export function useMinimalBanksMortgageOffersSliced({
    term,
    from,
    propertyCost,
    ownAmount,
    regionId,
    purpose,
    isPreferentialNbCondition,
    defaultVisibleOffers = 3
}: Omit<UseBankMortgageFormProps, 'onChange'> & { from?: string; defaultVisibleOffers?: number }) {
    const [ formData, setFormDataRaw ] = useState({
        term,
        purpose,
        regionId,
        ownAmount: Math.round(ownAmount),
        propertyCost: Math.round(propertyCost)
    });

    const isFormChangedRef = useRef(false);

    const wrapperSetFormDataRaw = useCallback((args: Parameters<typeof setFormDataRaw>[0]) => {
        isFormChangedRef.current = true;
        setFormDataRaw(args);
    });

    const [ setFormData ] = useDebouncedCallback(wrapperSetFormDataRaw, 500);

    const [ fakeLoadingRaw, setFakeLoading ] = useState(false);
    const minimalBanksMortgageOffersLoad = useMinimalBanksMortgageOffersLoader();

    const { data, loading, errors: error } = formData.propertyCost > 0 && formData.ownAmount > 0 ?
        minimalBanksMortgageOffersLoad({
            ...formData,
            isPreferential: isPreferentialNbCondition,
            propertyCost: Math.round(formData.propertyCost)
        }) :
        { data: undefined, loading: false, errors: [] };

    const offersFull = data?.minimalBanksMortgageOffers ?? [];
    const offers = offersFull.slice(0, defaultVisibleOffers);
    const bestMortgageOffer = mortgage2BestOfferFromOffers(offersFull);

    const fakeLoading = fakeLoadingRaw || loading;

    useEffect(() => {
        if (loading) setFakeLoading(true);
        const handle = setTimeout(() => setFakeLoading(false), 1000);

        return () => {
            clearTimeout(handle);
        };
    }, [ loading ]);

    const isEmpty = offers.length === 0 && ! fakeLoading;

    let totalCost: number | undefined = formData.propertyCost - formData.ownAmount;

    if (totalCost <= 0) totalCost = undefined;

    const totalCostFormatted = totalCost ? formatPrice({
        value: totalCost * 100,
        displayCurrency: true,
        noFractional: true,
        currency: Currency.RUB
    }) : undefined;

    let offersOrMocks: readonly (typeof offers[number] | undefined)[] = offers;

    if (fakeLoading) {
        const fakeOffers: undefined[] = [];

        for (let i = 0; i < defaultVisibleOffers; i++) {
            fakeOffers.push(undefined);
        }
        offersOrMocks = fakeOffers;
    }

    return {
        ...useMinimalBanksMortgageEconomy({ ...formData, from }),
        setFormData,
        setFormDataRaw: wrapperSetFormDataRaw,
        formData,
        offersOrMocks,
        offersFull,
        bestMortgageOffer,
        totalCost,
        totalCostFormatted,
        isEmpty,
        error,
        loading: fakeLoading,
        isFormChanged: isFormChangedRef.current
    };
}

export type UseMinimalBanksMortgageOffersSlicedResult = ReturnType<typeof useMinimalBanksMortgageOffersSliced>;
