/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import { SeoChpuMapper } from '@search/router/src';
import { Rooms } from '@search/filter-enums/enums/Rooms';
import { RealtyObjectType } from '@search/filter-enums/enums/RealtyObjectType';
import { DealType } from '@search/filter-enums/enums';

export type TSearchSeoChpuMapper = ReturnType<typeof SearchSeoChpuMapper.chpuMap>;
type SearchSeoChpuMapperKeys = (keyof TSearchSeoChpuMapper)[];

const floorArr = [ 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20 ] as const;
const areArr = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 15, 20, 25, 30, 40, 50, 100 ] as const;

type TFloorMap = { [x in `${typeof floorArr[number]}-etazh`]?: { floorMin: number; floorMax: number} };
type TAreMap = { [x in `${typeof areArr[number]}-sotok`]?: { landAreaMin?: number; landAreaMax: number} };

const generateFloorMap = ({ returnParams }: { returnParams?: boolean }) => floorArr.reduce(
    (prev, cur: typeof floorArr[number]) => {
        prev[`${cur}-etazh`] = returnParams ? { floorMax: cur, floorMin: cur } : undefined;
        return prev;
    },
    {} as TFloorMap
);

export const generateAreChpuMap = ({ returnParams }: { returnParams?: boolean }) => areArr.reduce(
    (prev, cur: typeof areArr[number]) => {
        if (cur === 1 && returnParams) prev[`${cur}-sotok`] = { landAreaMax: cur };
        prev[`${cur}-sotok`] = returnParams ? { landAreaMin: cur, landAreaMax: cur } : undefined;
        return prev;
    },
    {} as TAreMap
);

export class SearchSeoChpuMapper extends SeoChpuMapper<typeof SearchSeoChpuMapper.chpuMap> {
    static chpuKeys = Object.keys(SearchSeoChpuMapper.chpuMap()) as SearchSeoChpuMapperKeys;

    extraTagWeight = new Map([
        [ 's-gazom-i-vodoj', 2 ],
    ]);

    tagsIntersection = new Map<SearchSeoChpuMapperKeys[number], string[]>([
        [ 's-gazom-i-vodoj', [ 's-gazom', 's-vodoj' ] ],
        [ 'cottage', [ 's-otopleniem', 's-vodoj', 's-kanalizacziej', 's-elektrichestvom' ] ],
    ]);

    static euroKitchenArea = 17;

    // eslint-disable-next-line complexity
    static chpuMap(ctx: {
        categoryType?: RealtyObjectType;
        dealType?: DealType;
        apartments?: string | null;
        rooms?: readonly Rooms[];
        kitchenAreaMin?: string | null | number;
        last?: string | null | number;
        livingTenantOptions?: readonly ('UNKNOWN' | 'CHILDREN_ALLOWED' | 'PETS_ALLOWED')[] | null;
        commisionType?: string | null;
    } = {}) {
        const isFlat = ctx.categoryType === RealtyObjectType.FLAT;
        // const isSell = ctx.dealType === DealType.SELL;
        const isRent = ctx.dealType === DealType.RENT;
        const isHouse = ctx.categoryType === RealtyObjectType.HOUSE;
        const isLand = ctx.categoryType === RealtyObjectType.LAND;
        const isApartments = ctx.apartments === 'APARTMENTS_ONLY';
        const isEuroLayout = ctx.rooms?.length === 1 && [ Rooms.ROOM_1, Rooms.ROOM_2, Rooms.ROOM_3 ].includes(ctx.rooms[0]) && Number(ctx.kitchenAreaMin) === this.euroKitchenArea;
        const isLastFloor = ctx.last === 'ONLY';
        const isChpuDeposite = ctx.dealType === DealType.RENT &&
            ctx.commisionType !== 'NO_CLIENT_COMMISSION' &&
            ! ctx.livingTenantOptions?.includes('PETS_ALLOWED') &&
            ! ctx.livingTenantOptions?.includes('CHILDREN_ALLOWED');

        return {
            'vysokie-potolki': { cellHeight: 'FROM_3' },
            '1-etazh': isLastFloor ? undefined : { floorMax: 1 },
            'poslednij-etazh': { last: 'ONLY' },
            'bolshaya-kukhnya': { kitchenAreaMin: 15 },
            renovacziya: { renovationProgram: 'HAS_ANY' },
            'okhrana-territorii': { infrastructure: [ 'CLOSED_AREA' ] },
            's-konserzhem': { infrastructure: [ 'CONCIERGE' ] },
            'ryadom-shkola': { nearbyOptions: 'GOOD_SCHOOL' },
            evroodnushka: ! isApartments && isFlat ? { rooms: [ Rooms.ROOM_1 ], kitchenAreaMin: this.euroKitchenArea } : undefined,
            evrodvushka: ! isApartments && isFlat ? { rooms: [ Rooms.ROOM_2 ], kitchenAreaMin: this.euroKitchenArea } : undefined,
            evrotreshka: ! isApartments && isFlat ? { rooms: [ Rooms.ROOM_3 ], kitchenAreaMin: this.euroKitchenArea } : undefined,
            malogabaritnaya: isFlat ? { totalAreaMax: 29 } : undefined,
            's-liftom': { hasLift: 'HAS_ANY' },
            's-balkonom': { balcony: 'HAS_ANY' },
            apartamenty: isFlat && ! isEuroLayout ? { apartments: 'APARTMENTS_ONLY' } : undefined,
            's-sanuzlom': { bathroomLocation: [ 'INSIDE' ] },
            's-otopleniem': isHouse ? { heating: [ 'HAS_ANY' ] } : undefined,
            's-gazom': isHouse || isLand ? { gas: [ 'HAS_ANY' ] } : undefined,
            's-vodoj': isHouse || isLand ? { water: [ 'HAS_ANY' ] } : undefined,
            's-elektrichestvom': isHouse || isLand ? { electricity: [ 'HAS_ANY' ] } : undefined,
            's-kanalizacziej': isHouse || isLand ? { sewerage: [ 'HAS_ANY' ] } : undefined,
            's-gazom-i-vodoj': isHouse || isLand ? { water: [ 'HAS_ANY' ], gas: [ 'HAS_ANY' ] } : undefined,
            's-kommunikaciyami': isHouse || isLand ? { water: [ 'HAS_ANY' ], gas: [ 'HAS_ANY' ], electricity: [ 'HAS_ANY' ], sewerage: [ 'HAS_ANY' ] } : undefined,
            cottage: isHouse ?
                {
                    heating: [ 'HAS_ANY' ],
                    water: [ 'HAS_ANY' ],
                    sewerage: [ 'HAS_ANY' ],
                    electricity: [ 'HAS_ANY' ],
                    houseCategory: [ 'STANDALONE' ],
                    landStatus: [ 'DNP', 'IZHS', 'SNT' ]
                } :
                undefined,
            ...generateFloorMap({ returnParams: ! isLastFloor }),
            ...generateAreChpuMap({ returnParams: isLand }),
            odnoetazhnyj: isHouse ? { floorsTotalMax: 1 } : undefined,
            dvuhetazhnyj: isHouse ? { floorsTotalMax: 2, floorsTotalMin: 2 } : undefined,
            trekhetazhnyj: isHouse ? { floorsTotalMax: 3, floorsTotalMin: 3 } : undefined,
            'bez-mebeli': isFlat || isHouse ? { furniture: [ 'NO' ] as ('NO' | 'ALL' | 'UNKNOWN' | 'KITCHEN')[] } : undefined,
            's-mebelyu': isFlat || isHouse ? { furniture: [ 'ALL' ] as ('NO' | 'ALL' | 'UNKNOWN' | 'KITCHEN')[] } : undefined,
            'dlya-postoyannogo-prozhivaniya': isHouse ? { houseCategory: [ 'STANDALONE' ] } : undefined,
            'bez-komissii': isRent ? { commisionType: 'NO_CLIENT_COMMISSION' } : undefined,
            's-zhivotnymi': isRent ? { livingTenantOptions: [ 'PETS_ALLOWED' ] as ('UNKNOWN' | 'PETS_ALLOWED' | 'CHILDREN_ALLOWED')[] } : undefined,
            's-detmi': isRent ? { livingTenantOptions: [ 'CHILDREN_ALLOWED' ] as ('UNKNOWN' | 'PETS_ALLOWED' | 'CHILDREN_ALLOWED')[] } : undefined,
            'bez-zaloga': isChpuDeposite ? { depositType: 'NO_DEPOSIT' } : undefined,
        };
    }
}

// new SearchSeoChpuMapper({}).toChpu({}).chpuParams
// new SearchSeoChpuMapper({}).toChpu({
//     rooms: [] as readonly[]
// });
