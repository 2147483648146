import { SusaninRoute } from '@search/router/src/susanin/SusaninRoute';
import { ROSSIYA } from '@search/filter-enums/enums/Region';

import { INewBuildingRouterContext } from '../types';

export abstract class NewBuildingRoute<External, Internal, Defaults = unknown> extends SusaninRoute<
    External, Internal, INewBuildingRouterContext, Defaults
> {
    protected patternHost(): string {
        return '(<origin>)';
    }

    protected conditions() {
        return {
            origin: 'https?://[^\/]+'
        };
    }

    protected defaultRegion() {
        return {
            id: ROSSIYA,
            translit: 'rossiya'
        };
    }

    protected regionSlugHost(regionId: number) {
        let result = super.regionSlugHost(regionId);

        if (result) return result;

        const parentId = this.context.rootRegions?.getById(regionId)?.narrowRegion?.id;

        if (parentId) result = super.regionSlugHost(parentId);

        return result;
    }

    regionId(regionSlug?: string | null) {
        let regionId = regionSlug ? this.context.rootRegions?.getByTranslitName(regionSlug)?.id : undefined;

        if (! regionId) regionId = this.hostParts()?.regionId;

        return regionId ?? this.defaultRegion().id;
    }

    regionSlugPath(regionId: number) {
        return this.context.rootRegions?.getById(regionId)?.translit ??
            this.defaultRegion().translit;
    }

    countrySlugPath(regionId: number) {
        return this.context.rootRegions?.getSlugFromGeo?.(regionId);
    }

    countrySlugId(slug: string, regionId: number) {
        return this.context.rootRegions?.getGeoFromSlug?.(slug, regionId);
    }
}
