/* eslint-disable complexity */
import { GeoKind } from '@search/filter-enums/enums';
import { isPresent } from '../domainHelpers';
import { GeoBaseStore } from './GeoBase';

export function geoBaseStoreFromGql(geo: Geo): GeoBaseStore {
    const store = new GeoBaseStore();

    for (const metro of geo?.metro ?? []) {
        if (! metro) continue;

        store.add({
            id: metro.id,
            kind: geoKindFromGqlKind(metro.kind),
            displayName: metro.title ?? '',
            shortDisplayName: metro.shortDisplayName ?? undefined,
            parentId: metro.route.id,
            body: {
                metro: {
                    allRoutesColorsList: metro.lines ?? [],
                    interchangeStationsIdsList: metro.interchangedStations?.filter(isPresent) ?? [],
                    coordinates: metro.coordinates ?? undefined
                }
            }
        });
    }

    for (const district of geo?.districts ?? []) {
        if (! district) continue;

        store.add({
            id: district.id,
            kind: geoKindFromGqlKind(district.kind),
            isIntracity: district.isIntracity ?? false,
            parentId: district.parentId,
            narrowRegion: district.narrowRegion,
            displayName: district.displayName || district.title || '',
            mainName: district.mainName ?? undefined,
            shortDisplayName: district.shortDisplayName || district.displayName || undefined,
            locativeDisplayName: district.locative ?? undefined
        });
    }

    for (const line of geo?.lines ?? []) {
        if (! line) continue;
        store.add({
            id: line.id,
            kind: geoKindFromGqlKind(line.kind),
            displayName: line.title ?? '',
            body: {
                route: {
                    colorHex: line.color ?? 'pink'
                }
            }
        });
    }

    return store;
}

function geoKindFromGqlKind(kind: KindEnum): GeoKind {
    return GeoKind[kind];
}

type KindEnum = 'AIRPORT' | 'APARTMENT' | 'AREA' | 'COMPOSITE_OBJECT' | 'COUNTRY' | 'DISTRICT' | 'ENTRANCE'
    | 'HOUSE' | 'HYDRO' | 'LOCALITY' | 'METRO' | 'OTHER' | 'PROVINCE' | 'RAILWAY_STATION'
    | 'ROUTE' | 'STATION' | 'STREET' | 'STREET_ADDRESS' | 'VEGETATION';

type Geo = {
    readonly metro: Array<{
        readonly id: number;
        readonly title?: string | null | undefined;
        readonly kind: KindEnum;
        readonly lines: Array<string> | null | undefined;
        readonly mainName: string | null | undefined;
        readonly interchangedStations: Array<number | null | undefined> | null | undefined;
        readonly shortDisplayName: string | null | undefined;
        readonly locativeDisplayName: string | null | undefined;
        readonly route: {
            readonly id: number;
        };
        readonly coordinates: {
            readonly latitude: number;
            readonly longitude: number;
        } | null | undefined;
    } | null | undefined> | null | undefined;
    readonly lines: Array<{
        readonly id: number;
        readonly title: string | null;
        readonly kind: KindEnum;
        readonly color: string | null;
    } | null | undefined> | null | undefined;
    readonly districts: Array<{
        readonly kind: KindEnum;
        readonly parentId: number;
        readonly displayName: string | null;
        readonly mainName: string | null;
        readonly shortDisplayName: string | null;
        readonly locative: string | null;
        readonly title: string | null;
        readonly id: number;
        readonly isIntracity?: boolean | null;
        readonly narrowRegion?: {
            readonly id: number;
        };
    } | null | undefined > | null | undefined;
} | null | undefined;
