import React, { RefObject, InputHTMLAttributes } from 'react';

import { cnCheckbox } from '../';

import './styles.css';

export interface ICheckboxControlProps extends InputHTMLAttributes<HTMLInputElement> {
    /**
     * Ссылка на DOM элемент нативного инпута.
     */
    controlRef?: RefObject<HTMLInputElement>;
}

export const CheckboxControl: React.FunctionComponent<ICheckboxControlProps> = ({ controlRef, className, ...props }) =>
    <input
        {...props}
        ref={controlRef}
        type='checkbox'
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        className={cnCheckbox('control', {}, className)} />;
