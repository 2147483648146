import React, { ReactNode, RefObject, useCallback, useEffect, useState } from 'react';
import { Size } from '@vtblife/uikit/legacy';
import { Input } from '@vtblife/uikit/dist/components/input/legacy/input';
import cn from '@search/classname/src';
import useControl, { IUseControlReturnedHandlers } from '@search/hooks/src/useControl';

import useSuggestionList, { IUseSuggestionListState } from './useSuggestionList';

import './styles.css';

export interface ISuggestion {
    label: string;
    type?: string;
}

interface ICheckIsOpen <Suggestion extends ISuggestion> {
    /**
     * Признак фокуса на списке
     */
    isFocus: boolean;
    /**
     * Введенный текст
     */
    label: string;
    /**
     * Выбранные элемент из списка
     */
    suggestion?: Suggestion;

    /**
     * Набор подсказок
     */
    suggestions?: Suggestion[];
}

export interface IInitialSuggestState<Suggestion extends ISuggestion> {
    /**
     * Введенный текст
     */
    label: string;

    /**
     * Состояние списка, открыт или нет
     */
    isListOpened: boolean;

    /**
     * Обьект выбранной подсказки
     */
    suggestion?: Suggestion;
}

export interface ISuggestProps<Suggestion extends ISuggestion = ISuggestion> {
    /**
     * Ссылка на рутовый DOM элемент компонента.
     */
    innerRef?: RefObject<HTMLDivElement>;

    /**
     * Кастомный ренедер инпута
     */
    renderInput?: (
        props: {
            inputRef?: RefObject<HTMLSpanElement | HTMLInputElement>;
            borderSide?: 'square' | 'rounded_square' | 'square_rounded';
            inputSize?: Size;
            placeholder?: string;
            onInputToggleFocus?: (disabled: boolean) => void;
            label?: string;
            disabled?: boolean;
            borderOut?: boolean;
            clear?: boolean;
            isError?: boolean;
            autoFocus?: boolean;
            focused?: boolean;
        },
        state: IInitialSuggestState<Suggestion>,
        handlers: IUseControlReturnedHandlers & {
            handleInputChange: (text: string) => void;
            handleInputKeyDown: (e: React.KeyboardEvent) => void;
        }
    ) => ReactNode;

    /**
     * Кастомный ренедер списка
     */
    renderList?: (props: {
        suggestions: Suggestion[];
        renderItemLabel: (suggestion: Suggestion) => ReactNode;
    }, suggestionState: IUseSuggestionListState & { label?: string }, handlers: {
        handleItemMouseDown: (event: React.MouseEvent, index: number) => void;
        handleItemMouseEnter: (event: React.MouseEvent, index: number) => void;
    }) => ReactNode;

    /**
     * Обработчик пустого инпута
     */
    onClear?: () => void;

    /**
     * Кастомный ренедер пустого списка
     */
    renderEmptyList?: (props: {emptyText?: string}) => void;

    /**
     * Коллбэк получения данных
     */
    fetchSuggestions?: (text: string) => void;

    /**
     * Обработчик изменения текста в инпуте
     * @param text
     */
    onTextChange?: (text: string) => void;

    /**
     * Обработчик потери фокуса в input
     */
    onBlur?: (e:React.FocusEvent<HTMLInputElement>) => void;

    /**
     * Обработчик получения фокуса в input
     */
    onFocus?: (e:React.FocusEvent<HTMLInputElement>) => void;

    /**
     * Кастомный ренедер элемента списка подсказок
     */
    renderItemLabel?: (suggestion: Suggestion) => React.ReactNode;

    width?: 'max';

    borderSide?: 'square' | 'rounded_square' | 'square_rounded';

    inputSize?: Size;

    /**
     * Коллбэк выбора значения
     * @param suggestion
     */
    onSelect: (suggestion: Suggestion) => void;

    /**
     * Очищать состояние саджеста после выбора значения
     */
    clearOnSelect?: boolean;

    /**
     * Кнопка для очищения input
     */
    clear?: boolean;

    /**
     * Ошибка ?
     */
    isError?: boolean;

    /**
     * Выбранные элемент из списка
     */
    suggestion?: Suggestion;

    borderOut?: boolean;

    /**
     * Набор подсказок
     */
    suggestions?: Suggestion[];

    /**
     * Состояние саджеста
     */
    disabled?: boolean;

    /**
     * Вспомогательный текст внутри компонента. Отображается, когда значение не выбрано
     */
    placeholder?: string;

    /**
     * Label внутри компонента. Отображается всегда
     */
    label?: string;

    /**
     * Размер
     */
    size?: Size;

    /**
     * Автофокус для input
     */
    autoFocus?: boolean;

    /**
     * Текст для пустого списка
     */
    emptyText?: string;

    /**
     * Класс миксин
     */
    className?: string;

    /**
     * Обработчик проверки отрисовки списка
     */
    checkListShowing?: (state: ICheckIsOpen<Suggestion>) => boolean;

    /**
     * Обработчик фокуса и блюра на поле Suggest (дизейбл формы для фикса бага на iOS Safari)
     */
    onInputToggleFocus?: (disabled: boolean) => void;

    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

export const cnSuggest = cn.bind(null, 'Suggest');

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DEFAULT_SUGGESTIONS: any[] = [];

const renderInputDefault: ISuggestProps['renderInput'] = (suggestProps, state, handlers) => {
    const onFocus = suggestProps.onInputToggleFocus ? () => {
        suggestProps.onInputToggleFocus?.(true);
    } : handlers.handleFocus;

    const onBlur = suggestProps.onInputToggleFocus ? () => {
        suggestProps.onInputToggleFocus?.(false);
    } : handlers.handleBlur;

    return (
        <Input
            autoFocus={suggestProps.autoFocus}
            // @ts-ignore
            size={suggestProps.inputSize} // eslint-disable-line
            value={state.label}
            onBlur={onBlur}
            onFocus={onFocus}
            name='suggest-input'
            onChange={handlers.handleInputChange}
            onKeyDown={handlers.handleInputKeyDown}
            placeholder={suggestProps.placeholder}
            label={suggestProps.label}
            hasClear={suggestProps.clear}
            isError={suggestProps.isError}
            className={cnSuggest('input')}
            disabled={suggestProps.disabled}
        />
    );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const renderListDefault: ISuggestProps<any>['renderList'] = (suggestProps, state, handlers) =>
    <ul className={cnSuggest('list')}>
        {suggestProps.suggestions.map((suggestion, index) => {
            return (
                <li
                    key={index}
                    onMouseEnter={e => handlers.handleItemMouseEnter(e, index)}
                    onMouseDown={e => handlers.handleItemMouseDown(e, index)}
                    className={cnSuggest('list-item', { focused: state.focusedIndex === index })}>
                    {suggestProps.renderItemLabel(suggestion)}
                </li>
            );
        })}
    </ul>;

const renderEmptyListDefault: ISuggestProps['renderEmptyList'] = ({ emptyText }) => {
    return (
        <div className={cnSuggest('list', { empty: true })}>
            {emptyText}
        </div>
    );
};

const renderItemLabelDefault: ISuggestProps['renderItemLabel'] = suggestion => suggestion.label;

const Suggest = <Suggestion extends ISuggestion>({
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    size = Size.Medium,
    borderSide,
    borderOut = false,
    onInputToggleFocus,
    inputSize,
    width,
    className,
    innerRef,
    disabled,
    onSelect,
    renderInput = renderInputDefault,
    renderList = renderListDefault,
    renderItemLabel = renderItemLabelDefault,
    renderEmptyList = renderEmptyListDefault,
    clearOnSelect = false,
    autoFocus = false,
    suggestions = DEFAULT_SUGGESTIONS as Suggestion[],
    suggestion,
    onClear,
    onTextChange,
    onFocus,
    onBlur,
    fetchSuggestions,
    onClick,
    placeholder,
    label,
    emptyText = 'Ничего не найдено',
    clear = false,
    isError = false,
    checkListShowing
}: ISuggestProps<Suggestion>) => {
    const [ state, setState ] = useState<IInitialSuggestState<Suggestion>>({
        label: suggestion ? suggestion.label : '',
        isListOpened: false
    });

    const handleSelectItem = useCallback((selectedItem: Suggestion) => {
        if (selectedItem) {
            setState(prevState => {
                const newState = { ...prevState };

                if (clearOnSelect) {
                    newState.label = '';
                } else if (suggestion === undefined) {
                    // Устанавливаем значение, если саджест неуправляемый извне
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    newState.label = selectedItem.label;
                    newState.suggestion = selectedItem;
                }

                return ({
                    ...newState,
                    isListOpened: false
                });
            });
        }

        onSelect && onSelect(selectedItem);
    }, [ clearOnSelect, onSelect, suggestion ]);

    const { suggestionHandlers, focusedIndex } = useSuggestionList<Suggestion>({
        suggestions,
        isListOpened: state.isListOpened
    }, handleSelectItem);

    const [ controlState, controlHandlers ] = useControl({ disabled, focused: autoFocus }, {
        onBlur,
        onFocus
    }, { disabled });

    const handleInputChange = useCallback((text: string) => {
        onTextChange && onTextChange(text);

        setState(prevState => {
            if (text !== state.label) {
                fetchSuggestions && fetchSuggestions(text);
            }

            return ({
                ...prevState,
                label: text,
                suggestion: undefined,
                isListOpened: Boolean(text)
            });
        });

        if (! text) {
            onClear && onClear();
        }
    }, [ onTextChange, state.label, fetchSuggestions, onClear ]);

    useEffect(() => {
        // Если инпут потерял фокус, то закрываем список
        if (! controlState.focused) {
            const trimmedText = state?.label?.trim();

            setState(prevState => {
                return ({ ...prevState, label: trimmedText, isListOpened: false });
            });
        } else {
            // Если инпут в фокусе
            setState(prevState => {
                const isListOpened = Boolean(prevState.label && ! prevState.suggestion);

                return ({
                    ...prevState,
                    isListOpened
                });
            });
        }
    }, [ controlState.focused, suggestions, state?.label ]);

    useEffect(() => {
        if (suggestion) {
            setState(prevState => {
                return ({
                    ...prevState,
                    label: suggestion ? suggestion.label : '',
                    suggestion
                });
            });
        } else if (! suggestion) {
            setState(prevState => {
                return ({
                    ...prevState,
                    label: '',
                    suggestion: undefined
                });
            });
        }
    }, [ suggestion ]);

    const isShowEmptyList = state.isListOpened && ! suggestions.length && Boolean(state.label);
    let isShowList = suggestions.length > 0 && state.isListOpened;

    if (checkListShowing) {
        isShowList = checkListShowing({
            isFocus: Boolean(controlState.focused),
            label: state.label,
            suggestion,
            suggestions
        });
    }

    return (
        <div
            data-test='filter-geo-suggest'
            ref={innerRef}
            onClick={onClick}
            className={cnSuggest(null, { size, width }, className)}>
            {
                renderInput(
                    {
                        borderOut,
                        borderSide,
                        inputSize,
                        placeholder,
                        label,
                        disabled,
                        clear,
                        autoFocus,
                        isError,
                        focused: controlState.focused,
                        onInputToggleFocus
                    },
                    state,
                    {
                        ...controlHandlers,
                        handleInputChange,
                        handleInputKeyDown: suggestionHandlers.handleInputKeyDown
                    })
            }
            {
                isShowList && renderList({
                    suggestions,
                    renderItemLabel
                }, {
                    label: state.label,
                    focusedIndex
                }, {
                    handleItemMouseDown: suggestionHandlers.handleItemMouseDown,
                    handleItemMouseEnter: suggestionHandlers.handleItemMouseEnter
                })
            }
            {
                isShowEmptyList && renderEmptyList({ emptyText })
            }
        </div>
    );
};

export default Suggest;
