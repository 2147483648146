import React from 'react';
import classname from '@search/classname/src';
import { NewsTinyItem, NewsTinyCard } from '../../../common/components/NewsTinyCard';
import Slider from '../../../common/components/Slider';

import './styles.css';

interface INewBuildingNews {
    heading: React.ReactNode;
    news: NewsTinyItem[];
    onClickNewsItem?: () => void;
}

const cn = classname.bind(null, 'NewBuildingNews');

export const NewBuildingNews: React.FC<INewBuildingNews> = ({ heading, news, onClickNewsItem }) => (
    <div className={cn()}>
        {heading}
        <div className={cn('content')}>
            <Slider contentClassName={cn('sliderContent')}>
                {news.map(item => (
                    <div
                        className={cn('item')}
                        key={item.link}
                        onClick={onClickNewsItem}
                    >
                        <NewsTinyCard
                            news={item}
                        />
                    </div>
                ))}
            </Slider>
        </div>
    </div>
);

