import React, { useState } from 'react';
import classname from '@search/classname/src';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';

import './styles.css';

const urlErrorImage = 'http://fabiusmaximus.com/wp-content/uploads/2012/12/20121230-no-error.png?w=300';

interface IPicture {
    alt: string;
    src: string;
    width: number;
    height: number;
    active: boolean;
    isLayout?: boolean;
}

const cn = classname.bind(null, 'GalleryDesktopPicture');

export function PictureWrapper({
    width,
    height,
    children,
    loading,
    active,
    isAltView
}: {
    isAltView?: boolean;
    active?: boolean;
    loading?: boolean;
    children?: React.ReactNode;
    width: number;
    height: number;
}) {
    return (
        <div className={cn(null, { active: active && isAltView, alt: isAltView })} style={{ width, height }}>
            {loading && <div className={cn('spinner')}>
                <LoadingSpinner show={loading} staticLoader />
            </div>}
            <div className={cn('wrap')}>
                {children}
                {active && ! isAltView && <div style={{ width, height }} className={cn('active')} />}
            </div>
        </div>
    );
}

export const Picture = ({
    alt,
    src,
    height,
    active,
    width,
    isLayout
}: IPicture) => {
    const [ state, setState ] = useState({
        loading: true,
        error: false
    });

    const onLoad = () => {
        setTimeout(() => {
            setState({
                loading: false,
                error: false
            });
        }, 100);
    };

    const onError = () => {
        setState({
            loading: false,
            error: true
        });
    };

    return (
        <PictureWrapper
            width={width}
            height={height}
            loading={state.loading}
            active={active}
        >
            {state.error && (
                <img
                    crossOrigin='anonymous'
                    className={cn('image')}
                    src={urlErrorImage}
                    height={height}
                    width={width}
                />
            )}

            {! state.error && (<>
                <img
                    alt={alt}
                    src={src}
                    height={height}
                    width={width}
                    className={cn('image', { isLayout })}
                    onLoad={onLoad}
                    onError={onError}
                    crossOrigin='anonymous'
                />
            </>)}

        </PictureWrapper>
    );
};
