import { DataLayerObject } from './DataLayerObject';

declare const window: Window & {
    dataLayer?: Object[];
};

export function dataLayerPush(data: DataLayerObject) {
    if (typeof window === 'undefined') {
        return;
    }

    if (! window.dataLayer) {
        window.dataLayer = [];
    }

    window.dataLayer.push(data);
}
