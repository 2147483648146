import { SusaninRoute } from '@search/router/src/susanin/SusaninRoute';

import { regionMapper } from './mappers/regions/regionMapper';
import { RouterContext } from './RouterProvider';

export abstract class ClassifiedRoute<External, Internal, Defaults = unknown> extends SusaninRoute<
    External, Internal, RouterContext, Defaults
> {
    protected patternHost(): string {
        return '(<origin>)';
    }

    protected conditions() {
        return {
            origin: 'https?://[^\/]+'
        };
    }

    protected regionSlugHost(regionId: number) {
        let result = super.regionSlugHost(regionId);

        if (result) return result;

        const parentId = this.context.rootRegions?.byId(regionId)?.narrowRegion?.id;

        if (parentId) result = super.regionSlugHost(parentId);

        return result;
    }

    regionId(regionSlug?: string | null) {
        let regionId = regionSlug ? this.context.rootRegions?.bySlug(regionSlug)?.id : undefined;

        if (! regionId) regionId = this.hostParts()?.regionId;

        return regionId;
    }

    regionSlugPath(regionId: number) {
        const regGeo = this.context.rootRegions?.byId(regionId);

        return regGeo?.translit ??
            regionMapper.seoFromTypeOptional(regionId) ??
            regionMapper.seoFromTypeOptional(this.regionIdDefault());
    }
}
