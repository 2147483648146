import React, { useCallback } from 'react';

import { Safety } from '@search/filter-enums/enums';
import { SafetyFilter } from '../../../../models/Filter';
import MobileFilter from '../../../filter/MobileFilter';
import { MultiSelect, MultiSelectOption } from '../../common/MultiSelect';

const selectOptions: MultiSelectOption[] = [
    {
        value: Safety.GUARDED_AREA,
        label: 'Охрана',
        className: 'col-xs-12 filter-item-gap'
    },
    {
        value: Safety.CONCIERGE,
        label: 'Консьерж',
        className: 'col-xs-12 filter-item-gap'
    },
    {
        value: Safety.CLOSED_AREA,
        label: 'Закр. территория',
        className: 'col-xs-12 filter-item-gap'
    }
];

interface IProps {
    filter: SafetyFilter;
    handleChange: (filter: SafetyFilter) => void;
}

export const MobileSafetyFilterView: React.FC<IProps> = ({
    filter,
    handleChange
}) => {
    const onChange = useCallback(value => {
        handleChange(new SafetyFilter(value));
    }, [ handleChange ]);

    return (
        <MobileFilter
            filterTitle='Безопасность'
        >
            <MultiSelect
                name='safety'
                className='container container__clamp'
                options={selectOptions}
                value={filter.safety}
                handleChange={onChange}
            />
        </MobileFilter>
    );
};

