import React from 'react';
import { Typography } from '@vtblife/uikit';
import classname from '@search/classname/src';
import './MobileFilter.css';

const cn = classname.bind(null, 'MobileFilter');

interface IProps {
    filterTitle?: string;
    className?: string;
}

function MobileFilterTitle({ children }: { children: React.ReactChild }) {
    return (
        <Typography
            variant='secondary-alone'
            color='secondary'
            className={`col-xs-12 ${cn('filter-title')}`}
        >
            {children}
        </Typography>
    );
}

const MobileFilter: React.FC<IProps> = ({ filterTitle, children, className }) => {
    return (
        <>
            {filterTitle && (<MobileFilterTitle>{filterTitle}</MobileFilterTitle>)}
            <div className={className}>
                {children}
            </div>
        </>
    );
};

export default MobileFilter;
