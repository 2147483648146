import React from 'react';
import classname from '@search/classname/src';
import { DirectionArrow } from '../constants';
import './styles.css';

interface IArrow {
    direction: DirectionArrow;
}

const cn = classname.bind(null, 'GalleryDesktopArrow');

export const Arrow = ({ direction }: IArrow) => {
    return (
        <div className={cn(null, { direction })} data-test={`galleryArrow-${direction}`}>
            <svg width='21' height='57' fill='none'>
                <path
                    fill='currentColor'
                    fillRule='evenodd'
                    clipRule='evenodd'
                    /* eslint-disable-next-line max-len */
                    d='M2.09566 0.483599C0.153183 1.53577 -0.56855 3.96342 0.483625 5.9059L12.4517 28.0008L0.483625 50.0956C-0.56855 52.0381 0.153183 54.4658 2.09566 55.5179C4.03814 56.5701 6.46579 55.8484 7.51796 53.9059L20.518 29.9059C21.1617 28.7174 21.1617 27.2841 20.518 26.0956L7.51796 2.09563C6.46579 0.153156 4.03814 -0.568577 2.09566 0.483599Z'
                />
            </svg>
        </div>
    );
};
