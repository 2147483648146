import React, { useState, useCallback, useEffect } from 'react';
import { ApplicationIdEnum } from '@search/graphql-typings';

import { dataLayerPush } from '../../../../domain/google/dataLayerPush';
import { useKeyCancel } from '../../hooks/useKey';

const CL_LOCAL_STORAGE_KEY = 'cl_customer_feedback_interaction';
const NB_LOCAL_STORAGE_KEY = 'nb_customer_feedback_interaction';

const LOCAL_STORAGE_KEY_MAP: Record<ApplicationIdEnum, string> = {
    [ApplicationIdEnum.ClassifiedDesktop]: CL_LOCAL_STORAGE_KEY,
    [ApplicationIdEnum.ClassifiedTouch]: CL_LOCAL_STORAGE_KEY,
    [ApplicationIdEnum.NewBuildingsDesktop]: NB_LOCAL_STORAGE_KEY,
    [ApplicationIdEnum.NewBuildingsTouch]: NB_LOCAL_STORAGE_KEY,
};

export const useCustomerFeedbackExternalState = ({
    applicationId,
    modalOpenEvent
}: {
    applicationId: ApplicationIdEnum;
    modalOpenEvent?: string;
}) => {
    const [ modalIsOpen, setModalIsOpen ] = useState(false);
    const [ openBtnIsVisible, setOpenBtnIsVisible ] = useState(false);

    const handleOpenModal = useCallback(() => {
        setModalIsOpen(true);
        modalOpenEvent && dataLayerPush({ event: modalOpenEvent });
    }, [ modalOpenEvent ]);

    const handleCloseModal = useCallback(() => {
        setModalIsOpen(false);
        setOpenBtnIsVisible(true);
    }, []);

    const handleFinishedFeedback = useCallback(() => {
        setModalIsOpen(false);
        setOpenBtnIsVisible(false);
    }, []);

    const disableFeedbackReappearance = useCallback((e?: React.MouseEvent<Element, MouseEvent>) => {
        const currentDate = new Date();

        e?.stopPropagation();
        window.localStorage.setItem(LOCAL_STORAGE_KEY_MAP[applicationId], currentDate.toString());
    }, [ applicationId ]);

    const handleCloseOpenBtn = useCallback((e?: React.MouseEvent<Element, MouseEvent>) => {
        e?.stopPropagation();
        setOpenBtnIsVisible(false);
        disableFeedbackReappearance();
    }, []);

    useKeyCancel(handleCloseModal);

    useEffect(() => {
        const lastOpening = window.localStorage.getItem(LOCAL_STORAGE_KEY_MAP[applicationId]);

        if (! lastOpening) {
            setOpenBtnIsVisible(true);
            return;
        }

        const currentDate = new Date();
        const dateToShowBtn = new Date(lastOpening);

        dateToShowBtn.setDate(dateToShowBtn.getDate() + 45);

        setOpenBtnIsVisible(currentDate > dateToShowBtn);
    }, [ applicationId ]);

    return {
        openBtnIsVisible,
        modalIsOpen,
        handleOpenModal,
        handleCloseModal,
        handleFinishedFeedback,
        handleCloseOpenBtn,
        disableFeedbackReappearance
    };
};
