import React from 'react';
import { Typography } from '@vtblife/uikit';
import classname from '@search/classname/src';
import './Filter.css';

const cn = classname.bind(null, 'Filter');

interface IProps {
    className?: string;
    filterTitle?: string;
}

export const Filter: React.FC<IProps> = ({ className, filterTitle, children }) => (
    <div className={className}>
        <div className={cn('filter-title')}>
            <Typography
                variant='secondary-alone'
                color='secondary'
            >
                {filterTitle}
            </Typography>
        </div>
        <div>
            {children}
        </div>
    </div>
);
