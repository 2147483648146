import React, { Ref, useCallback, useEffect, useMemo, useRef } from 'react';
import throttle from 'lodash/throttle';

import { ApplicationIdEnum } from '@search/graphql-typings';

import { OpenButtonBlue } from '../../../../../desktop/components/CustomerFeedback/OpenButton/Blue';
import { OpenButtonBlueBanner } from '../../../../../mobile/components/CustomerFeedback/OpenButton/BlueBanner';

import { OpenButtonDefault } from './Default';

const BOTTOM_GAP = 16;
const SCROLL_HEIGHT = 20; // Примерная высота горизонтального скрола

export interface ICustomerFeedbackOpenButton {
    handleOpen: () => void;
    handleCloseOpenBtn: (e?: React.MouseEvent<Element, MouseEvent>) => void;
    applicationId: ApplicationIdEnum;
    isSticky?: boolean;
}

export const CustomerFeedbackOpenButton = ({
    handleOpen,
    handleCloseOpenBtn,
    isSticky = false,
    applicationId
}: ICustomerFeedbackOpenButton) => {
    const ref = useRef<HTMLButtonElement | HTMLDivElement>(null);

    const isMobile = useMemo(() => [
        ApplicationIdEnum.NewBuildingsTouch,
        ApplicationIdEnum.ClassifiedTouch
    ].includes(applicationId), [ applicationId ]);

    const handleScroll = useCallback(() => {
        if (ref.current) {
            const rect = ref.current.getBoundingClientRect();

            // если стики кнопка достигла своего местоположения в разметке – скрыть
            // сравниваем со SCROLL_HEIGHT, потому что если появится горизонтальный скролл,
            // rect.bottom будет меньше и логика сломается
            if (window.innerHeight - rect.bottom - BOTTOM_GAP > SCROLL_HEIGHT) {
                ref.current.style.visibility = 'hidden';
            } else {
                ref.current.style.visibility = 'visible';
            }
        }
    }, [ ref ]);

    useEffect(() => {
        if (! isMobile) {
            const throttleHandleScroll = throttle(handleScroll, 100) as EventListener;

            window.addEventListener('scroll', throttleHandleScroll);

            return () => {
                window.removeEventListener('scroll', throttleHandleScroll);
            };
        }

        return;
    }, [ isMobile, handleScroll ]);

    switch (applicationId) {
        case ApplicationIdEnum.NewBuildingsDesktop:
            return (
                <OpenButtonBlue
                    handleOpen={handleOpen}
                    handleCloseOpenBtn={handleCloseOpenBtn}
                    ref={ref as Ref<HTMLDivElement>}
                />
            );

        case ApplicationIdEnum.NewBuildingsTouch:
            return (
                <OpenButtonBlueBanner
                    handleOpen={handleOpen}
                    handleCloseOpenBtn={handleCloseOpenBtn}
                />
            );

        case ApplicationIdEnum.ClassifiedDesktop:
        case ApplicationIdEnum.ClassifiedTouch:
        default:
            return (
                <OpenButtonDefault
                    handleOpen={handleOpen}
                    handleCloseOpenBtn={handleCloseOpenBtn}
                    isMobile={isMobile}
                    isSticky={isSticky}
                    ref={ref as Ref<HTMLButtonElement>}
                />
            );
    }
};
