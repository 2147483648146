import React, { FunctionComponent } from 'react';
import { Typography, Link } from '@vtblife/uikit';

import type { RouteMetro } from '@search/graphql-typings';

import classname from '@search/classname/src';

import IconTransport from '../IconTransport';
import type { IconTransportProps } from '../IconTransport';

import './styles.css';

export interface IMetroRouteProps {
    metro: RouteMetro;
    view?: 'desktop' | 'mobile';
    onDarkBackground?: boolean;
    className?: string;
    withoutTransport?: boolean;
    searchUrl?: string;
}

const cn = classname.bind(null, 'MetroRoute');

const MetroRoute: FunctionComponent<IMetroRouteProps> = ({
    view = 'desktop',
    metro,
    onDarkBackground = false,
    className,
    withoutTransport,
    searchUrl
}) => {
    const isMobile = view === 'mobile';
    const level = isMobile ? 'secondary-alone' : undefined;
    const {
        station,
        timeMinutes,
        transportType
    } = metro;

    const { name, lines } = station || {};
    const hasSearchUrl = Boolean(searchUrl);

    return (
        <div className={cn(null, undefined, className)}>
            <div>
                {
                    lines?.map(color => (
                        <div
                            key={color}
                            className={cn('line')}
                            style={{ backgroundColor: color }}
                        />
                    ))
                }
            </div>
            {
                hasSearchUrl ? (
                    <div>
                        <Link
                            href={searchUrl}
                            text={false}
                            target='blank'
                        >
                            <Typography
                                tag='span'
                                variant={level}
                                color={onDarkBackground ? 'white-500' : 'primary'}
                                className={cn('name', { isUrl: true })}
                            >
                                {name}
                            </Typography>
                        </Link>
                    </div>
                ) : (
                    <Typography
                        variant={level}
                        color={onDarkBackground ? 'white-500' : 'primary'}
                    >
                        {name}
                    </Typography>
                )
            }
            {
                withoutTransport ? null : (
                    <Typography
                        variant={level}
                        // eslint-disable-next-line no-nested-ternary
                        color={onDarkBackground ? 'white-500' : 'secondary'}
                    >
                        <IconTransport
                            transportType={transportType as IconTransportProps['transportType']}
                            // eslint-disable-next-line no-nested-ternary
                            color={onDarkBackground ? '#fff' : '#a4a1b5'}
                        />
                        {`${timeMinutes} мин.`}
                    </Typography>
                )
            }
        </div>
    );
};

export default MetroRoute;
