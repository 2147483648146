import React, { useCallback } from 'react';
import classname from '@search/classname/src';

import { M2PRO_OPTIONS, M2ProEnum } from '../../../../../../domain/newbuilding/libs/constants';
import { NotOnlyM2ProFilter } from '../../../../models/Filter';
import { RadioSelect, RadioValue } from '../../common/RadioSelect';
import MobileFilter from '../../../filter/MobileFilter';

import '../MobileCommon.css';
import './MobileNotOnlyM2ProFilterView.css';

const cn = classname.bind(null, 'MobileNotOnlyM2ProFilterView');

const M2PRO_OPTIONS_MOBILE = [
    M2PRO_OPTIONS[M2ProEnum.M2Pro],
    {
        ...M2PRO_OPTIONS[M2ProEnum.All],
        className: cn('option')
    }
];

export const MobileNotOnlyM2ProFilterView = ({
    filter,
    handleChange
}: {
    filter: NotOnlyM2ProFilter;
    handleChange: (filter: NotOnlyM2ProFilter) => void;
}) => {
    const { notOnlyM2Pro } = filter;
    const m2ProSelectedOption = M2PRO_OPTIONS_MOBILE.find(item => item.value === (
        notOnlyM2Pro ? M2ProEnum.All : M2ProEnum.M2Pro
    ))!;
    const handleM2ProChange = useCallback((m2ProValue: RadioValue) => handleChange(
        new NotOnlyM2ProFilter(m2ProValue === M2ProEnum.All)
    ), [ handleChange ]);

    return (
        <MobileFilter filterTitle='Сортировка ЖК'>
            <div className='container'>
                <RadioSelect
                    name='M2Pro'
                    options={M2PRO_OPTIONS_MOBILE}
                    value={m2ProSelectedOption.value}
                    handleChange={handleM2ProChange}
                    className={cn('checkboxGroup')}
                />
            </div>
        </MobileFilter>
    );
};
