import React, { useCallback } from 'react';
import { BalconyOptions } from '@search/filter-enums/enums';
import { BalconyFilter } from '../../../../models/Filter';
import MobileFilter from '../../../filter/MobileFilter';
import '../MobileCommon.css';
import { RadioSelect, RadioOption } from '../../common/RadioSelect';

interface IMobileBalconyFilterViewProps {
    filter: BalconyFilter;
    handleChange: (filter: BalconyFilter) => void;
}

const selectOptions: RadioOption[] = [ {
    value: BalconyOptions.HAS_ANY,
    label: 'Балкон',
    className: 'col-xs-12 filter-item-gap'
} ];

export const MobileBalconyFilterView: React.FC<IMobileBalconyFilterViewProps> = ({
    filter,
    handleChange
}) => {
    const onChange = useCallback(value => {
        handleChange(new BalconyFilter(value));
    }, [ handleChange ]);

    return (
        <MobileFilter
            filterTitle='Балкон/лоджия'
        >
            <RadioSelect
                allowNull
                name='balcony'
                className='container container__clamp'
                options={selectOptions}
                value={filter.hasBalcony}
                handleChange={onChange}
            />
        </MobileFilter>
    );
};
