import React from 'react';

import { EditFavoriteNewBuildingsResponse } from '@search/graphql-typings';

import { FavoritesMutation$data as FavoritesMutationResponse } from './__generated__/FavoritesMutation.graphql';
import { BasicError, useBasicError } from './BasicError';
import FavoriteLimitModal from './FavoriteLimitModal';

export type FavoritesError = BasicError | FavoritesMutationResponse['editFavoritePersonal2']['firstError'];
type NewBuildingFavoritesError = BasicError | EditFavoriteNewBuildingsResponse['firstError'];

const FavoriteModalsContext = React.createContext({
    setError(error: FavoritesError | NewBuildingFavoritesError | undefined | null) {}
});

export function useFavoritesModal() {
    return React.useContext(FavoriteModalsContext);
}

export function FavoritesModalProvider({
    children,
    isMobile = false
}: {
    isMobile?: boolean;
    children?: React.ReactNode;
}) {
    const { setError: setParentError } = useBasicError();
    const [ error, setChildError ] = React.useState<FavoritesError | NewBuildingFavoritesError | undefined | null>();

    const setError = React.useCallback((err: FavoritesError | NewBuildingFavoritesError | undefined | null) => {
        if (! err) {
            setChildError(undefined);
            setParentError(undefined);

            return;
        }

        const code = typeof err === 'object' && err ?
            (err.__typename ?? err.cause?.__typename ?? err.message) :
            undefined;

        switch (code) {
            case 'FavoritesErrorLimitExceeded':
            case 'FavoriteNewBuildingsLimitExceededError':
                return setChildError(err);

            default:
                return setParentError(err);
        }
    }, [ setParentError ]);

    const closeModal = React.useCallback(() => {
        setError(undefined);
    }, [ setError ]);

    let modal: React.ReactNode | null = null;
    const code = typeof error === 'object' && error ?
        (error.__typename ?? error.cause?.__typename ?? error.message) :
        undefined;

    switch (code) {
        case 'FavoritesErrorLimitExceeded': {
            modal = (
                <FavoriteLimitModal
                    onClose={closeModal}
                    isOpenLimitModal
                />
            );

            break;
        }

        case 'FavoriteNewBuildingsLimitExceededError': {
            modal = (
                <FavoriteLimitModal
                    onClose={closeModal}
                    isOpenLimitModal
                    isNewBuilding
                />
            );

            break;
        }

        default:
            modal = null;
    }

    const value = React.useMemo(() => ({ setError }), [ setError ]);

    return (
        <FavoriteModalsContext.Provider value={value}>
            {modal}
            {children}
        </FavoriteModalsContext.Provider>
    );
}
