/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useEffect, useState, useRef } from 'react';
import {
    addCallTouchScript, CalltouchType,
    DynamicReplacementErrorType,
    DynamicReplacementErrorEnum,
    CalltouchSubpoolType,
} from '@search/classified-common/src/scripts/useCalltouch';
import { formatPhone } from '@search/helpers/src/formatPhone';
declare const window: Window & {
    ct?: CalltouchType;
};

interface IUseCalltouchReplacement {
    originalPhone: string;
    ctId?: string;
}

const convertDynamicReplacementError = (v: DynamicReplacementErrorEnum) => {
    switch (v) {
        case DynamicReplacementErrorEnum.CALLTRACKING_BY_CLICK_IS_OFF:
            return 'CALLTOUCH:CALLTRACKING_BY_CLICK_IS_OFF';
        case DynamicReplacementErrorEnum.REPLACEMENT_CONDITIONS_NOT_COMPLETED:
            return 'CALLTOUCH:REPLACEMENT_CONDITIONS_NOT_COMPLETED';
        case DynamicReplacementErrorEnum.SUBPOOLNAME_NOT_FOUND:
            return 'CALLTOUCH:SUBPOOLNAME_NOT_FOUND';
        case DynamicReplacementErrorEnum.SERVER_ERROR:
            return 'CALLTOUCH:SERVER';
        case DynamicReplacementErrorEnum.SCRIPT:
            return 'SCRIPT';
        case DynamicReplacementErrorEnum.TIMEOUT:
            return 'TIMEOUT';
        case DynamicReplacementErrorEnum.UNKNOWN_ERROR:
            return 'CALLTOUCH:UNKNOWN';
        default:
            return 'UNKNOWN';
    }
};

export type ReplaceError = 'NONE' | ReturnType<typeof convertDynamicReplacementError>;

interface IReplacementData {
    calltouchPhone: string;
    hasTriedReplace: boolean;
    replaceError: ReplaceError;
}

export function useCalltouchReplacement({
    originalPhone,
    ctId
}: IUseCalltouchReplacement): IReplacementData {
    const [ replacementData, setReplacementData ] = useState<IReplacementData>({
        calltouchPhone: '',
        hasTriedReplace: false,
        replaceError: 'NONE'
    });
    const [ longRequestTimeout, setLongRequestTimeout ] = useState<ReturnType<typeof setTimeout> | null>(null);
    const replacementDataRef = useRef(replacementData);

    replacementDataRef.current = replacementData;

    if (! ctId) {
        return replacementData;
    }

    const setFailedReplacementData = useCallback((replaceError: ReplaceError) => setReplacementData(prevState => ({
        ...prevState,
        hasTriedReplace: true,
        replaceError
    })), []);

    const launchDynamicReplacement = useCallback((modId: string, subpoolName: string) => {
        try {
            setLongRequestTimeout(setTimeout(() => {
                if (! replacementDataRef.current.hasTriedReplace) {
                    setFailedReplacementData('TIMEOUT');
                }
            }, 2000));

            if (! window.ct) {
                setFailedReplacementData('SCRIPT');

                return;
            }

            window.ct('goal', 'phone_click');

            window.ct(modId, 'dynamic_replacement', {
                callback(success: boolean, result: CalltouchSubpoolType[] | DynamicReplacementErrorType) {
                    const calltouchPhone = success ? (result as CalltouchSubpoolType[])[0].phoneNumber.slice(1) : '';
                    const errorType = success ? undefined : (result as DynamicReplacementErrorType).type;

                    setReplacementData(prevState => prevState.hasTriedReplace ? prevState : ({
                        ...prevState,
                        calltouchPhone,
                        hasTriedReplace: true,
                        replaceError: errorType ? convertDynamicReplacementError(errorType) : 'NONE'
                    }));
                },
                subPoolNames: [ subpoolName ]
            });
        } catch (e) {
            setFailedReplacementData('UNKNOWN');
        }
    }, []);

    useEffect(() => {
        if (ctId && originalPhone && ! replacementData.calltouchPhone) {
            launchDynamicReplacement(ctId, originalPhone);
        }
    }, [ ctId, originalPhone, replacementData.calltouchPhone ]);

    useEffect(() => {
        if (replacementData.hasTriedReplace && longRequestTimeout !== null) {
            clearTimeout(longRequestTimeout);
            setLongRequestTimeout(null);
        }
    }, [ replacementData.hasTriedReplace, longRequestTimeout ]);

    return replacementData;
}

export class CalltouchError extends Error {
    protected cause: unknown;

    constructor(
        readonly props: {
            code: ReplaceError;
            callTouchId?: string;
            phone?: string;
        },
        p?: {
            cause?: unknown;
        }
    ) {
        // @ts-ignore
        super(`calltouchReplacementFetch error: ${JSON.stringify(props)}`, p);
        this.props = props;
        this.cause = p?.cause;
    }

    get callTouchId() { return this.props.callTouchId; }
    get code() { return this.props.code; }
    get phone() { return this.props.phone; }

    toJSON() { return this.props; }
}

export async function calltouchReplacementFetch({
    callTouchId,
    originalPhone: phone
}: {
    callTouchId: string;
    originalPhone: string;
}) {
    const originalPhone = phone.replace('+7', '').replace(/ /g, '');

    const ct = await addCallTouchScript(callTouchId);

    return new Promise<string | undefined>((resolve, reject) => {
        const timer = setTimeout(() => reject(
            new CalltouchError({ code: 'TIMEOUT', callTouchId, phone: originalPhone })
        ), 2000);

        ct('goal', 'phone_click');

        ct(callTouchId, 'dynamic_replacement', {
            callback(success: boolean, result: CalltouchSubpoolType[] | DynamicReplacementErrorType) {
                clearTimeout(timer);
                if (success) {
                    const newPhone = Array.isArray(result) ? result[0]?.phoneNumber : undefined;

                    return resolve(newPhone ? formatPhone(newPhone, { skipSymbols: true }) : undefined);
                }

                const errorType = Array.isArray(result) || ! result.type ?
                    'UNKNOWN' :
                    convertDynamicReplacementError(result.type);

                reject(new CalltouchError({ code: errorType, callTouchId, phone: originalPhone }, { cause: result }));
            },
            subPoolNames: [ originalPhone ]
        });
    });
}
