import React, { useCallback } from 'react';
import { Checkbox } from '@vtblife/uikit';

import { ParkingFilter } from '../../../../models/Filter';
import { Filter2 } from '../../../new_filter/Filter2';
import { CheckboxGroup2 } from '../../../common/CheckboxGroup2';

interface IParkingFilterProps {
    filter: ParkingFilter;
    handleChange: (filter: ParkingFilter) => void;
}

enum Parking {
    Closed = 1,
    Underground = 2,
    Opened = 3
}

const parkingEnumeration = [
    Parking.Closed,
    Parking.Underground,
    Parking.Opened
];

const parkingMapping = {
    [Parking.Closed]: 'Закрытая',
    [Parking.Underground]: 'Подземная',
    [Parking.Opened]: 'Открытая'
} as const;

export const DesktopParkingFilterView: React.FC<IParkingFilterProps> = ({
    filter,
    handleChange
}) => {
    const onChange = useCallback(val => {
        if (val === null) {
            return;
        }

        if (Array.isArray(val)) {
            handleChange(new ParkingFilter(val.map(elem => Number(elem))));
        }
    }, [ handleChange ]);

    const component = parkingEnumeration.map(parking => {
        return (
            <Checkbox
                variant='filter'
                key={parking}
                name={String(parking)}
                block
            >
                {parkingMapping[parking]}
            </Checkbox>
        );
    });

    return (
        <Filter2 filterTitle='Парковка'>
            <CheckboxGroup2
                mode='check'
                name='parkingEnumeration'
                onChange={onChange}
                value={filter.parkings.map(elem => String(elem))}
                isFilter
            >
                {component}
            </CheckboxGroup2>
        </Filter2>
    );
};
