import React, { useCallback } from 'react';
import upperFirst from 'lodash/upperFirst';

import { NumberInput, Typography } from '@vtblife/uikit';
import { Dropdown, Size } from '@vtblife/uikit/legacy';
import Icon from '@vtblife/uikit-icons';

import classname from '@search/classname/src';
import { INumberRange } from '@search/vtbeco-frontend-core/view/filters/types/IRange';
import { formatRange } from '@search/helpers/src/formatNumber';

import {
    IFilter, AreaFilter, LivingAreaFilter, KitchenAreaFilter
} from '../../../../models/Filter';

import { FilterSelectButton } from '../../../common/FilterSelect';
import { NumberInputRange } from '../../../common/NewNumberInputRange/NumberInputRange';

import './DesktopAreaFilterView.css';

const cn = classname.bind(null, 'DesktopAreaFilterView');

const formatAreaRange = (
    { from, to }: INumberRange,
    prefix: string = ''
) => {
    const range = formatRange(from, to);

    return range ? `${prefix}${range} м²` : range;
};

const makeTitle = (
    totalArea: INumberRange,
    livingArea: INumberRange,
    kitchenArea: INumberRange
) => {
    const labels = [];
    const totalAreaString = formatAreaRange(totalArea);

    if (totalAreaString) {
        labels.push(totalAreaString);
    }

    const livingAreaString = formatAreaRange(livingArea, 'жилая ');

    if (livingAreaString) {
        labels.push(livingAreaString);
    }

    const kitchenAreaString = formatAreaRange(kitchenArea, 'кухня ');

    if (kitchenAreaString) {
        labels.push(kitchenAreaString);
    }

    const title = labels.join(', ');

    if (totalAreaString) {
        return title;
    }

    if (livingAreaString || kitchenAreaString) {
        return upperFirst(title);
    }

    return 'Площадь';
};

interface IDesktopAreaFilterViewProps {
    totalAreaFilter: AreaFilter;
    livingAreaFilter: LivingAreaFilter;
    kitchenAreaFilter: KitchenAreaFilter;
    onChange: (filter: IFilter[]) => void;
}

export const DesktopAreaFilterView: React.FC<IDesktopAreaFilterViewProps> = ({
    totalAreaFilter,
    livingAreaFilter,
    kitchenAreaFilter,
    onChange
}) => {
    const { value: totalArea } = totalAreaFilter;
    const { value: livingArea } = livingAreaFilter;
    const { value: kitchenArea } = kitchenAreaFilter;

    const handleTotalAreaChange = useCallback((total: INumberRange) => onChange([
        new AreaFilter(total)
    ]), [ onChange ]);

    const handleLivingAreaChange = useCallback((living: INumberRange) => onChange([
        new LivingAreaFilter(living)
    ]), [ onChange ]);

    const handleKitchenAreaChange = useCallback((kitchenFrom: number) => onChange([
        new KitchenAreaFilter({
            from: kitchenFrom,
            to: null
        })
    ]), [ onChange ]);

    const handleReset = useCallback(() => onChange([
        new AreaFilter(),
        new LivingAreaFilter(),
        new KitchenAreaFilter()
    ]), [ onChange ]);

    return (
        <Dropdown renderTrigger={
            ({ isOpen, handleToggle, triggerRef }) => (
                <FilterSelectButton
                    size='l'
                    side='square'
                    ref={triggerRef}
                    onClick={handleToggle}
                    focused={isOpen}
                    icon={<Icon name={isOpen ? 'chevron-up' : 'chevron-down'} size='s' />}
                    style={{ position: 'relative' }}
                >
                    <div className={cn('selectTitle')}>
                        {makeTitle(totalArea, livingArea, kitchenArea)}
                    </div>
                </FilterSelectButton>
            )
        }>
            <div className={cn('popup')}>
                <div
                    onClick={handleReset}
                    className={cn('reset')}
                >
                    <Typography
                        tag='span'
                        className={cn('resetLink')}
                    >
                        Сбросить
                    </Typography>
                </div>
                <Typography
                    variant='primary'
                    className={cn('title', { name: 'total' })}
                >
                    Площадь
                </Typography>
                <div className={cn('filter', { name: 'total' })}>
                    <NumberInputRange
                        size={Size.Small}
                        unit='м²'
                        value={totalArea}
                        onChange={handleTotalAreaChange}
                        max={100_000}
                    />
                </div>
                <Typography
                    variant='primary'
                    className={cn('title', { name: 'living' })}
                >
                    Жилая
                </Typography>
                <div className={cn('filter', { name: 'living' })}>
                    <NumberInputRange
                        size={Size.Small}
                        unit='м²'
                        value={livingArea}
                        onChange={handleLivingAreaChange}
                        max={100_000}
                    />
                </div>
                <Typography
                    variant='primary'
                    className={cn('title', { name: 'kitchen' })}
                >
                    Кухня
                </Typography>
                <div className={cn('filter', { name: 'kitchen' })}>
                    <NumberInput
                        value={kitchenArea.from ?? undefined}
                        postfix='м²'
                        onChange={handleKitchenAreaChange}
                        size={Size.Small}
                        name='kitchenAreaFrom'
                        max={100_000}
                        type='text'
                        placeholder='от'
                    />
                </div>
            </div>
        </Dropdown>
    );
};
