import React, { useMemo } from 'react';
import { Typography } from '@vtblife/uikit';

import classname from '@search/classname/src';

import './styles.css';

const cnBreadcrumb = classname.bind(null, 'Breadcrumb');

export interface BreadcrumbSection {
    /** Уникальный ключ */
    key?: string;
    /** Контент в отображении */
    content: React.ReactNode;
    /** Ссылка ведущая к секции */
    href: string;
    /** свойство target у тега a
     * @default "_self"
    */
    target?: '_blank' | '_self' | '_parent' | '_top';
    /** Активная секция
     * @default "false"
    */
    active?: boolean;
    /** Контент в отображении, может быть с иконками/смайлами */
    contentSeo?: React.ReactNode;
}

export default function Breadcrumb(
    {
        allClickable = false,
        sections,
        onClick,
        regionModalSelect,
        className: classNameProp,
        withoutSeoMarkup = false
    }: {
        allClickable?: boolean;
        regionModalSelect?: boolean;
        sections: readonly BreadcrumbSection[];
        onClick?(
            event: React.MouseEvent<HTMLAnchorElement>,
            href: string,
            section: BreadcrumbSection
        ): void;
        className?: string;
        withoutSeoMarkup?: boolean;
    }
) {
    const schema = useMemo(() => {
        return {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            '@context': 'https://schema.org',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            '@type': 'BreadcrumbList',
            itemListElement: sections.map((section, idx) => ({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                '@type': 'ListItem',
                position: idx + 1,
                item: {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    '@id': section.href,
                    name: section.contentSeo ?? section.content
                }
            }))
        };
    }, [ sections ]);

    return (<>
        <script
            type='application/ld+json'
            dangerouslySetInnerHTML={! withoutSeoMarkup ? {
                /* eslint-disable-next-line @typescript-eslint/naming-convention */
                __html: JSON.stringify(schema)
            } : undefined}
        />
        <ol className={cnBreadcrumb(null, { regionModalSelect }, classNameProp)}>
            {sections.map((section, index) => {
                const isLastElement = allClickable ? false : index === sections.length - 1;
                const {
                    content: text,
                    href,
                    key = href ?? index,
                    target = '_self'
                } = section;

                return (
                    <React.Fragment key={key}>
                        {index > 0 ?
                            <li
                                aria-hidden='true'
                                className={cnBreadcrumb('section', { separator: true })}
                            >
                                {'›'}
                            </li> :
                            null
                        }
                        {isLastElement ? (
                            <li
                                className={cnBreadcrumb('section', { active: true })}
                            >
                                <span className={cnBreadcrumb('anchor', { active: true })}>
                                    <Typography
                                        variant='small'
                                        className={cnBreadcrumb('text')}
                                    >{text}</Typography>
                                </span>
                            </li>
                        ) : (
                            <li className={cnBreadcrumb('section')}>
                                <a
                                    className={cnBreadcrumb('anchor')}
                                    target={target}
                                    href={href}
                                    onClick={e => onClick?.(e, href, section)}
                                >
                                    <Typography
                                        variant='small'
                                        className={cnBreadcrumb('text')}
                                    >{text}</Typography>
                                </a>
                            </li>
                        )}
                    </React.Fragment>
                );
            })}
        </ol>
    </>);
}
