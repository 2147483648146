import React from 'react';
import Icon, { IIconProps } from '../Icon';

import './styles.css';

interface IIconClose extends IIconProps {
    type?: 'close';
}

export default (props: IIconClose): React.ReactElement => React.cloneElement(<Icon />, { type: 'close', ...props });
