import React from 'react';
import { graphql, useGqlContext } from '@search/gql-client/src';
import type { LocationPlacementIBType } from '@search/graphql-gateway/server/enums/LocationPlacementIBType';
import { ApplicationIdEnum, RealtyObjectEnum } from '@search/graphql-typings';

import type {
    mortgage2ContactsFormSendMutation$variables as mortgage2ContactsFormSendMutationVariables,
    mortgage2ContactsFormSendMutation$data as mortgage2ContactsFormSendMutationResponse
} from './__generated__/mortgage2ContactsFormSendMutation.graphql';
import type { Mortgage2RealtyType } from './mortgageTypes';

const mortgage2ContactsFormSendMutation = graphql`
    mutation mortgage2ContactsFormSendMutation(
        $phone: String!,
        $name: String!,
        $link: String,
        $locationPlacement: String,
        $applicationId: ApplicationIdEnum!,
        $regionId: Int,
        $subject: String!,
        $message: String,
        $utm: RecordInput,
        $realtyType: RealtyObjectEnum,
        $discounts: [DiscountCalcEnum!],
        $startCallbackHour: String,
        $endCallbackHour: String,
        $withGA: Boolean,
        $withoutBackofficeEmail: Boolean,
    ) {
        classifiedSendMortgageContactsForm(
            utm: $utm,
            phone: $phone,
            name: $name,
            link: $link,
            locationPlacement: $locationPlacement,
            applicationId: $applicationId,
            regionId: $regionId,
            subject: $subject,
            message: $message,
            realtyType: $realtyType,
            discounts: $discounts,
            startCallbackHour: $startCallbackHour,
            endCallbackHour: $endCallbackHour,
            withGA: $withGA,
            withoutBackofficeEmail: $withoutBackofficeEmail
        ) {
            emailMessageId
        }
    }
`;

// eslint-disable-next-line max-len
export type Mortgage2ContactsFormSendProps =
    Omit<
        mortgage2ContactsFormSendMutationVariables,
        'phone' | 'subject' | 'name' | 'link' | 'locationPlacement' | 'realtyType' | 'applicationId'
    > & {
    offerUrl?: string;
    locationPlacement: LocationPlacementIBType;
    realtyType: Mortgage2RealtyType;
    applicationId: ApplicationIdEnum;
    // eslint-disable-next-line camelcase
    gtmSendData?: { event: string; page_type: string; place?: string; action?: string };
    // eslint-disable-next-line camelcase
    gtmShowData?: { event: string; page_type: string; place?: string; action?: string };
};

type UseMortgage2ContactsFormSendProps = Omit<mortgage2ContactsFormSendMutationVariables, 'locationPlacement'> & {
    locationPlacement: LocationPlacementIBType;
    startCallbackHour?: string;
    endCallbackHour?: string;
};

export function useMortgage2ContactsFormSend() {
    const gql = useGqlContext();

    return React.useCallback((vars: UseMortgage2ContactsFormSendProps, opts?: { signal?: AbortSignal }) => {
        return gql.client<mortgage2ContactsFormSendMutationVariables, mortgage2ContactsFormSendMutationResponse>(
            mortgage2ContactsFormSendMutation,
            vars,
            {
                pathUniqSuffix: `/callback-mortgage/${vars.phone}/`,
                ...opts
            }
        ).then();
    }, [ gql ]);
}

export function getSubjectAppName(appId?: ApplicationIdEnum | null) {
    switch (appId) {
        case ApplicationIdEnum.ClassifiedDesktop:
        case ApplicationIdEnum.ClassifiedTouch:
            return 'Уютного поиска';
        case ApplicationIdEnum.NewBuildingsDesktop:
        case ApplicationIdEnum.NewBuildingsTouch:
            return 'Новостроек';
        default:
            return 'СПб команды';
    }
}

export function getRealtyObjectTypeGql(realtyType?: Mortgage2RealtyType | null) {
    switch (realtyType) {
        case 'ProfOfferRoom': return RealtyObjectEnum.Room;
        case 'ProfOfferHouse': return RealtyObjectEnum.House;
        case 'ProfOfferLand': return RealtyObjectEnum.Land;
        case 'ProfOfferFlat':
        default:
            return RealtyObjectEnum.Flat;
    }
}

export function normalizeCallbackUrl(offerUrl?: string | null) {
    let link;

    if (offerUrl) {
        link = offerUrl.includes('http') ? offerUrl : `${location.origin}${offerUrl}`;
    } else {
        link = location.href;
    }

    return link;
}
