export const checkMainDirection = (
    direction: string,
    mainFirstDirection: string,
    mainSecondDirection?: string
) => (
    direction.indexOf(mainFirstDirection) === 0 || (mainSecondDirection && direction.indexOf(mainSecondDirection) === 0)
);

export const checkSecondaryDirection = (
    direction: string,
    secondaryDirection: string
) => (
    direction.indexOf('-' + secondaryDirection) > 0
);
