import {
    Filters,
    IFilter,
    CommissioningFilter,
    FinishingTypeFilter,
    RegionFilter,
    RoomFilter,
    PriceFilter,
    TransportLimitFilter,
    GeoFilter,
    AreaFilter,
    LivingAreaFilter,
    KitchenAreaFilter,
    FloorFilter,
    CellHeightFilter,
    BathroomFilter,
    BalconyFilter,
    ParkingFilter,
    WallsTypeFilter,
    ApartmentFilter,
    PositionFilter,
    SafetyFilter,
    DeveloperFilter,
    FloorsTotalFilter,
    BuildingClassFilter,
    AccreditationFilter,
    NewBuildingDealFilter,
    PaymentTypeFilter,
    CommissioningListFilter,
    BuildingsFilter,
    FinishingFlatFilter,
    ClosedSalesFilter,
    ShowHighlightedFilter,
    MapBoundsFilter,
    SelectedNewBuildingFilter,
    FlatsSpecialEventsFilter,
    OtherSpecialEventsFilter,
    MiscSpecialEventsFilter,
    NotOnlyM2ProFilter
} from '../../../view/filters/models/Filter';

import {
    Area,
    FloorConstraints,
    Rooms,
    TransportType,
    TimeLimit,
    CeilingHeight,
    Bathroom,
    BalconyOptions,
    ParkingType,
    WallsType,
    IsApartments,
    Position,
    Safety,
    BuildingClass,
    Accreditation,
    NewBuildingDeal,
    PaymentType,
    FlatsSpecialEvents,
    OtherSpecialEvents,
    MiscSpecialEvents
} from './converters';

const serializers: Record<Filters, Function> = {
    [Filters.commissioning]: (filter: CommissioningFilter) => filter.commissioning,
    [Filters.finishingType]: (filter: FinishingTypeFilter) => ({
        hasFinishing: filter.value.hasFinishing,
        finishingType: filter.value.finishingType
    }),
    [Filters.region]: (filter: RegionFilter) => ({
        region: filter.region
    }),
    [Filters.rooms]: (filter: RoomFilter) => filter.rooms.map(val => Rooms.stringValue[val]),
    [Filters.price]: (filter: PriceFilter) => ({
        priceMin: filter.value.from,
        priceMax: filter.value.to,
        priceType: Area.stringValue[filter.priceType]
    }),
    [Filters.transportLimit]: (filter: TransportLimitFilter) => {
        return {
            transportType: TransportType.stringValue[filter.transportType],
            maxMinutes: TimeLimit.stringValue[filter.timeLimit]
        };
    },
    [Filters.geo]: (filter: GeoFilter) => ({
        metroId: filter.metroIds,
        districtId: filter.districtIds,
        addressId: filter.addressIds,
        newBuildingId: filter.newBuildingIds
    }),
    [Filters.totalArea]: (filter: AreaFilter) => ({
        totalAreaRangeMin: filter.value.from,
        totalAreaRangeMax: filter.value.to
    }),
    [Filters.livingArea]: (filter: LivingAreaFilter) => ({
        livingAreaRangeMin: filter.value.from,
        livingAreaRangeMax: filter.value.to
    }),
    [Filters.kitchenArea]: (filter: KitchenAreaFilter) => ({
        kitchenAreaRangeMin: filter.value.from
    }),
    [Filters.floor]: (filter: FloorFilter) => ({
        floorMin: filter.range.from,
        floorMax: filter.range.to,
        floorFirst: FloorConstraints.stringValue[filter.first],
        floorLast: FloorConstraints.stringValue[filter.last]
    }),
    [Filters.cellHeight]: (filter: CellHeightFilter) => ({
        ceilingHeight: CeilingHeight.stringValue[filter.value]
    }),
    [Filters.bathroom]: (filter: BathroomFilter) => filter.value
        .map(value => Bathroom.stringValue[value])
        .filter(Boolean),
    [Filters.balcony]: (filter: BalconyFilter) => BalconyOptions.stringValue[filter.hasBalcony] ? 'YES' : undefined,
    [Filters.parkings]: (filter: ParkingFilter) => ({
        parking: filter.parkings.map(val => ParkingType.stringValue[val])
    }),
    [Filters.walls]: (filter: WallsTypeFilter) => ({
        wallsType: filter.walls.map(val => WallsType.stringValue[val])
    }),
    [Filters.apartments]: (filter: ApartmentFilter) => IsApartments.stringValue[filter.isApartment],
    [Filters.position]: (filter: PositionFilter) => (filter.position.reduce((params, val) => ({
        ...params,
        [Position.stringValue[val]]: 'YES'
    }), {})),
    [Filters.safety]: (filter: SafetyFilter) => (filter.safety.reduce((params, val) => ({
        ...params,
        [Safety.stringValue[val]]: 'YES'
    }), {})),
    [Filters.developer]: (filter: DeveloperFilter) => ({
        developerId: filter.developerId
    }),
    [Filters.floorsTotal]: (filter: FloorsTotalFilter) => ({
        floorsTotalMin: filter.range.from,
        floorsTotalMax: filter.range.to
    }),
    [Filters.buildingClass]: (filter: BuildingClassFilter) => ({
        buildingClass: filter.buildingClass.map(val => BuildingClass.stringValue[val])
    }),
    [Filters.accreditation]: (filter: AccreditationFilter) => (filter.accreditation.reduce((params, val) => ({
        ...params,
        [Accreditation.stringValue[val]]: 'YES'
    }), {})),
    [Filters.newBuildingDeal]: (filter: NewBuildingDealFilter) => (filter.deal.reduce((params, val) => ({
        ...params,
        [NewBuildingDeal.stringValue[val]]: 'YES'
    }), {})),
    [Filters.paymentType]: (filter: PaymentTypeFilter) => (filter.type.reduce((params, val) => ({
        ...params,
        [PaymentType.stringValue[val]]: 'YES'
    }), {})),
    [Filters.commissioningList]: (filter: CommissioningListFilter) => ({
        commissioningDate: filter.commissioningList
    }),
    [Filters.buildings]: (filter: BuildingsFilter) => ({
        buildingId: filter.buildings
    }),
    [Filters.finishingFlat]: (filter: FinishingFlatFilter) => filter.value.finishingType,
    [Filters.closedSales]: (filter: ClosedSalesFilter) => filter.includingClosedSales,
    [Filters.showHighlighted]: (filter: ShowHighlightedFilter) => filter.shouldShowHighlighted,
    [Filters.mapBounds]: (filter: MapBoundsFilter) => ({
        mapBounds: {
            bounds: filter.bounds,
            polygon: filter.polygon
        }
    }),
    [Filters.selectedNewBuilding]: (filter: SelectedNewBuildingFilter) => ({
        selectedNewBuildingId: filter.selectedNewBuildingId
    }),
    [Filters.flatsSpecialEvents]: (filter: FlatsSpecialEventsFilter) => ({
        flatsSpecialEvents: filter.value.map(val => FlatsSpecialEvents.stringValue[val])
    }),
    [Filters.otherSpecialEvents]: (filter: OtherSpecialEventsFilter) => ({
        otherSpecialEvents: filter.value.map(val => OtherSpecialEvents.stringValue[val])
    }),
    [Filters.miscSpecialEvents]: (filter: MiscSpecialEventsFilter) => ({
        miscSpecialEvents: filter.value.map(val => MiscSpecialEvents.stringValue[val])
    }),
    [Filters.notOnlyM2Pro]: (filter: NotOnlyM2ProFilter) => filter.notOnlyM2Pro
};

const addValue: any = (
    values: Record<string, any>,
    key: string,
    value: any,
    destructObjects: boolean = true
) => {
    if (
        value === null ||
        typeof value === 'undefined' ||
        value === 'UNKNOWN'
    ) {
        return values;
    }

    if (Array.isArray(value)) {
        const filteredValue = value.filter(Boolean);

        if (filteredValue.length === 0) {
            return values;
        }

        return {
            ...values,
            [key]: filteredValue
        };
    }

    if (destructObjects && typeof value === 'object') {
        return Object.keys(value)
            .reduce(
                (subValues, subKey) => addValue(subValues, subKey, value[subKey], false),
                values
            );
    }

    return {
        ...values,
        [key]: value
    };
};

class NewbuildingFilterSerializer {
    serialize(
        filters: Map<Filters, IFilter>
    ) {
        return Object.keys(serializers)
            .reduce((values, filterName) => {
                const filter = filters.get(filterName as Filters);

                return filter ? addValue(
                    values,
                    filterName,
                    serializers[filterName as Filters](filter)
                ) : values;
            }, {});
    }
}

export const newbuildingFilterSerializer = new NewbuildingFilterSerializer();
