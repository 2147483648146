import React from 'react';

import { BaseError } from '@search/error/src/BaseError';
import { graphql, useGqlContext } from '@search/gql-client/src';
import { useM2AuthContext } from '@search/auth/src/M2AuthProvider';

import { HiddenMutation$variables, HiddenMutation$data } from './__generated__/HiddenMutation.graphql';

const hiddenMutation = graphql`
    mutation HiddenMutation($offerId: ID!, $isHidden: Boolean!) {
        editHiddenPersonal(offerId: $offerId, isHidden: $isHidden) {
            firstError {
                __typename
                ... on BaseError{
                    message
                }
                ... on HiddenErrorInvalidOfferId {
                    message
                }
                ... on HiddenErrorLimitExceeded {
                    message
                }
                ... on HiddenErrorUnknown {
                    message
                }
                ... on InternalError {
                    message
                }
            }
        }
    }
`;

export function useHiddenMutation() {
    const gql = useGqlContext();

    return React.useCallback(async (props: HiddenMutation$variables) => {
        const data = await gql.execute<HiddenMutation$variables, HiddenMutation$data>(
            hiddenMutation,
            {
                ...props
            }
        );

        const firstError = data.data?.editHiddenPersonal.firstError;

        if (firstError?.__typename === 'HiddenErrorLimitExceeded') {
            throw new BaseError('HiddenErrorLimitExceeded', { cause: firstError });
        }

        if (firstError?.__typename === 'HiddenErrorInvalidOfferId') {
            throw new BaseError('HiddenErrorInvalidOfferId', { cause: firstError });
        }

        return data;
    }, [ gql ]);
}

export function useHiddenMutationSafe() {
    const { authScheduler } = useM2AuthContext();
    const hiddenMutationCb = useHiddenMutation();

    const hiddenMutationSafe = React.useMemo(() => {
        const register = authScheduler.register('hiddenMutation', hiddenMutationCb);

        return {
            ...register,
            argsById(id: string) {
                return register.args().find(arg => arg.offerId === id);
            }
        };
    }, [ hiddenMutationCb ]);

    return hiddenMutationSafe;
}
