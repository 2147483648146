/* eslint-disable @typescript-eslint/naming-convention */
import { TipMetroStation, TipTypeEnum } from '@search/graphql-typings';
import { GeoKind } from '@search/filter-enums/enums/GeoKind';
import { titleSorter } from '../domainHelpers';
import { GeoId } from './GeoBase';
import { MetroRoute, RouteStore } from './MetroRoute';
import { geoProtoMetroStationRaw } from './proto/metroStaionRaw';
import { GeoObject2 } from './GeoObject2';

export interface MetroStationBase {
    readonly id: GeoId;
    readonly title: string;
    allRoutesColorsList: readonly string[];
}

export interface MetroStation extends MetroStationBase {
    readonly __typename?: undefined;
    readonly id: GeoId;
    readonly kind: GeoKind.METRO;
    readonly title: string;
    readonly route: MetroRoute;
    interchangedStations: readonly MetroStation[];
    readonly coordinates?: {
        latitude: number;
        longitude: number;
    };
}

export interface MetroAlphabetGroup {
    readonly letter?: string;
    readonly stations: MetroStation[];
}

export interface MetroRouteGroup {
    id: string;
    route: MetroRoute;
    __typename?: undefined;
    kind: 'metro-route';
    readonly stations: MetroStation[];
}

export interface MetroStationRaw extends MetroStationBase {
    readonly id: GeoId;
    readonly kind: GeoKind.METRO;
    readonly title: string;
    readonly routeId: GeoId;
    readonly interchangeStationsIds: readonly GeoId[];
    readonly coordinates?: {
        latitude: number;
        longitude: number;
    };
}

export class MetroStore {
    protected items = new Map<GeoId, MetroStationRaw>();
    protected ids: GeoId[] = [];
    protected routes: RouteStore;

    constructor(routes: RouteStore) {
        this.routes = routes;
    }

    has(id: GeoId): boolean {
        return this.items.has(id);
    }

    get(id: GeoId): MetroStation | undefined {
        return this.entities.get(id);
    }

    getGql(id: GeoId): TipMetroStation | undefined {
        const geo = this.get(id);

        if (! geo) return undefined;

        return {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            __typename: 'TipMetroStation',
            id: geo.id,
            allRoutesColors: geo.allRoutesColorsList as string[],
            title: geo.title,
            type: TipTypeEnum.Metro
        };
    }

    isEmpty() {
        return this.ids.length === 0;
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    protected _entities: Map<GeoId, MetroStation> | null = null;

    protected get entities(): ReadonlyMap<GeoId, MetroStation> {
        if (this._entities) return this._entities;
        const entities: Map<GeoId, MetroStation> = this._entities = new Map();
        const items = this.sortedByTitle;

        for (const item of items) {
            if (item) {
                entities.set(item.id, item);
            }
        }

        return entities;
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    protected _sorted: MetroStation[] | null = null;

    get sortedByTitle(): readonly MetroStation[] {
        if (this._sorted) return this._sorted;
        const sorted: MetroStation[] = this._sorted = [];

        for (const stationId of this.ids) {
            const station = createMetroStation(
                stationId,
                this.items,
                this.routes
            );

            if (station) {
                sorted.push(station);
            }
        }
        sorted.sort(titleSorter);

        return sorted;
    }

    findRouteByStations(stationIds: GeoId[]): {groups: MetroRouteGroup[]; stations: MetroStation[]} {
        const groups = this.groupedByRoute;
        const idSet = new Set(stationIds);
        const fullGroups: MetroRouteGroup[] = [];

        for (const group of groups) {
            let equalStations = 0;

            for (const station of group.stations) {
                if (idSet.has(station.id)) equalStations++;
            }
            if (equalStations === group.stations.length) {
                for (const station of group.stations) idSet.delete(station.id);
                fullGroups.push(group);
            }
        }
        const stations: MetroStation[] = [];

        for (const stationId of stationIds) {
            const station = this.get(stationId);

            if (idSet.has(stationId) && station) stations.push(station);
        }

        return { groups: fullGroups, stations };
    }

    get groupedByRoute(): readonly MetroRouteGroup[] {
        const stations = this.sortedByTitle;
        const stationsMap = new Map<GeoId, MetroRouteGroup>();

        for (const station of stations) {
            const route = station.route;
            const key = route.id;
            let stationGroup = stationsMap.get(key);

            if (! stationGroup) {
                stationGroup = {
                    id: String(route.id),
                    route,
                    kind: 'metro-route',
                    stations: []
                };
                stationsMap.set(key, stationGroup);
            }
            stationGroup.stations.push(station);
        }

        return Array.from(stationsMap.values()).sort(
            (a: MetroRouteGroup, b: MetroRouteGroup) => (a.route.title > b.route.title ? 1 : -1)
        );
    }

    get groupedByAlphabet(): readonly MetroAlphabetGroup[] {
        const stationsMap = new Map<string, MetroAlphabetGroup>();
        const distinctStations = this.noInterchanged;

        for (const station of distinctStations) {
            const letter = station.title.substring(0, 1);
            let stationGroup = stationsMap.get(letter);

            if (! stationGroup) {
                stationGroup = {
                    letter,
                    stations: []
                };
                stationsMap.set(letter, stationGroup);
            }
            stationGroup.stations.push(station);
        }

        const aggregate = Array.from(stationsMap.values());

        aggregate.sort((a, b) => {
            return a.letter!.charCodeAt(0) - b.letter!.charCodeAt(0);
        });

        aggregate.forEach(stationGroup => {
            stationGroup.stations.sort((a, b) => {
                // eslint-disable-next-line
                return a.title < b.title ? -1 : a.title > b.title ? 1 : 0;
            });
        });

        return aggregate;
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    protected _noInterchanged: readonly MetroStation[] | null = null;
    /**
     * Если у станции есть пересадочная с таким же названием - вторая будет отброшена
     */
    get noInterchanged(): readonly MetroStation[] {
        if (this._noInterchanged) return this._noInterchanged;
        this._noInterchanged = metroStationNoIntercharged(this.sortedByTitle);

        return this._noInterchanged;
    }

    add(geo: GeoObject2) {
        if (geo.kind !== GeoKind.METRO) return;
        const id = geo.id;

        if (this.items.has(id)) return;
        const station = geoProtoMetroStationRaw(geo);

        this.ids.push(id);
        this.items.set(id, station);
    }
}

export function metroStationNoIntercharged(stations: readonly MetroStation[]): readonly MetroStation[] {
    const processed = new Set<number>();
    const result: MetroStation[] = [];

    for (const station of stations) {
        if (processed.has(station.id)) continue;
        for (const interchanged of station.interchangedStations) {
            if (interchanged.title === station.title) {
                processed.add(interchanged.id);
            }
        }
        result.push(station);
    }

    return result;
}

function createMetroStation(
    stationId: GeoId,
    stationMap: Map<GeoId, MetroStationRaw>,
    routeMap: RouteStore,
    processedStations = new Map<GeoId, MetroStation>()
): MetroStation | undefined {
    let station = processedStations.get(stationId);

    if (station) return station;

    const stationRaw = stationMap.get(stationId);

    if (! stationRaw) return; // throw new GeoBaseError(stationId, `, parent: ${JSON.stringify(parent)}`);
    const route = routeMap.get(stationRaw.routeId);

    if (! route) return;

    station = {
        id: stationRaw.id,
        kind: GeoKind.METRO,
        title: stationRaw.title,
        interchangedStations: [],
        allRoutesColorsList: [],
        route,
        coordinates: stationRaw.coordinates
    };
    processedStations.set(stationRaw.id, station);

    const interchangedStations: MetroStation[] = [];

    for (const interchangedStationId of stationRaw.interchangeStationsIds) {
        const interchangedStation = createMetroStation(
            interchangedStationId,
            stationMap,
            routeMap,
            processedStations
        );

        if (interchangedStation) {
            interchangedStations.push(interchangedStation);
        }
    }

    station.interchangedStations = interchangedStations;
    station.allRoutesColorsList = stationRaw.allRoutesColorsList || [];

    return station;
}
