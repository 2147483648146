import React from 'react';

import { ApplicationIdEnum } from '@search/graphql-typings';

import { CustomerFeedbackOpenButton } from './components/OpenButton';
import { CustomerFeedbackModal } from './components/Modal';
import { useCustomerFeedbackExternalState } from './useCustomerFeedbackExternalState';

export interface ICustomerFeedback {
    applicationId: ApplicationIdEnum;
    isSticky?: boolean;
    modalOpenEvent?: string;
}

export const CustomerFeedback = ({
    applicationId,
    isSticky = false,
    modalOpenEvent
}: ICustomerFeedback) => {
    const {
        openBtnIsVisible,
        modalIsOpen,
        handleOpenModal,
        handleCloseModal,
        handleCloseOpenBtn,
        handleFinishedFeedback,
        disableFeedbackReappearance
    } = useCustomerFeedbackExternalState({
        applicationId,
        modalOpenEvent
    });

    if (! openBtnIsVisible) {
        return null;
    }

    return (
        <>
            <CustomerFeedbackOpenButton
                applicationId={applicationId}
                isSticky={isSticky}
                handleOpen={handleOpenModal}
                handleCloseOpenBtn={handleCloseOpenBtn}
            />
            <CustomerFeedbackModal
                applicationId={applicationId}
                modalIsOpen={modalIsOpen}
                handleCloseModal={handleCloseModal}
                disableFeedbackReappearance={disableFeedbackReappearance}
                handleFinishedFeedback={handleFinishedFeedback}
            />
        </>
    );
};
