import React, { useCallback } from 'react';

import { FinishingEnum, FinishingTypeEnum } from '@search/graphql-typings';

import { FinishingTypeFilter, allWithFinishingValues } from '../../../../models/Filter';
import { SlimMultiSelect } from '../../../../../common/components/SlimSelect/SlimMultiSelect';

const SELECT_OPTIONS = [
    {
        value: FinishingTypeEnum.WithoutFinishing,
        label: 'Без отделки'
    },
    {
        value: FinishingTypeEnum.PreFinishing,
        label: 'Предчистовая'
    },
    {
        value: FinishingTypeEnum.Fine,
        label: 'Чистовая'
    },
    {
        value: FinishingTypeEnum.WithFurniture,
        label: 'С мебелью'
    },
];

interface IProps {
    filter: FinishingTypeFilter;
    onChange: (filter: FinishingTypeFilter) => void;
}

/**
 * Фильтр "Тип отделки"
* */
export const DesktopFinishingTypeFilterView: React.FC<IProps> = ({
    filter,
    onChange
}) => {
    const { finishingType } = filter.value;

    const handleChange = useCallback((values: FinishingTypeEnum[]) => {
        const hasAllAvailableValues = new Set([ ...values, ...allWithFinishingValues ]).size === allWithFinishingValues.length && values.length === allWithFinishingValues.length;

        onChange(new FinishingTypeFilter({
            hasFinishing: hasAllAvailableValues ? FinishingEnum.Available : undefined,
            finishingType: values
        }));
    }, [ onChange ]);

    return (
        <SlimMultiSelect
            placeholder='Тип отделки'
            dataTest='finishingType'
            size='s'
            options={SELECT_OPTIONS}
            value={finishingType}
            onChange={handleChange}
            adaptivePlacement={false}
            adaptiveHeight={false}
        />
    );
};
