/* eslint-disable complexity */
import upperFirst from 'lodash/upperFirst';
import { AuthorTypeOptions } from '@search/filter-enums/enums';
import { declensionByNumber } from '@search/helpers/src/declensionByNumber';
import { seoSearchCommon, seoSearchCommonJoin, SeoSearchCommonProps } from './seoSearchCommon';

export function seoSearchLand({ params, geos, offersCount, offersSummary, region }: SeoSearchCommonProps) {
    const segment = params.segments;
    const {
        dealTypeText,
        offersCountText,
        geoText,
        regionText,
        nearMetroText,
        priceMaxText,
        generateFaqTexts,
        minPriceText,
        isNonCapitalRegion,
        priceSeo
    } = seoSearchCommon({ params, geos, offersCount, offersSummary, region });

    const landText = {
        accusative: 'участок',
        plural: 'земельных участков',
        genitive: 'участков земли',
        nominative: 'земельный участок',
        shortGenitive: 'участков'
    };

    const areText = segment?.landAreaMax && segment?.landAreaMin && segment?.landAreaMax === segment?.landAreaMin &&
        areSeoChpuSet.has(segment.landAreaMax) && areSeoChpuSet.has(segment.landAreaMin) ?
        `${segment.landAreaMax}\u00A0${declensionByNumber(segment.landAreaMax, [ 'сотка', 'сотки', 'соток' ])}` :
        '';

    let landStatusText = '';
    const ls = new Set(segment?.landStatus ?? []);

    if (ls.has('IZHS')) landStatusText = 'ИЖС';
    if (ls.has('DNP')) landStatusText = 'СНТ, ДНП';
    if (ls.has('AGRICULTURAL')) landStatusText = 'ЛПХ';
    if (ls.has('IZHS') && ls.has('DNP') && ls.has('SNT')) landStatusText = 'для\u00A0ПМЖ';

    let authorText = '';
    const authorFirst = segment?.author?.[0];

    // @ts-ignore
    if (authorFirst === AuthorTypeOptions.AGENCY) authorText = 'от\u00A0агентства';
    // @ts-ignore
    if (authorFirst === AuthorTypeOptions.OWNER) authorText = 'от\u00A0собственника';

    let communicationsText = '';

    if (segment?.gas?.[0] && segment?.water?.[0]) {
        communicationsText = 'с\u00A0газом и\u00A0водой';
    } else {
        if (segment?.water?.[0]) communicationsText = 'с\u00A0водой';
        if (segment?.gas?.[0]) communicationsText = 'с\u00A0газом';
    }
    if (segment?.water?.[0]) communicationsText = 'с\u00A0водой';
    if (segment?.electricity?.[0]) communicationsText = 'с\u00A0электричеством';
    if (segment?.heating?.[0]) communicationsText = 'с\u00A0отоплением';
    if (segment?.sewerage?.[0]) communicationsText = 'с\u00A0канализацией';
    if (segment?.communications) communicationsText = 'с\u00A0коммуникациями';

    const villageText = priceSeo?.seg === 'v-derevne' ? 'в\u00A0 деревне' : undefined;

    const prefixCommon = seoSearchCommonJoin(
        landStatusText,
        areText,
        authorText,
        communicationsText,
        villageText,
        villageText ? undefined : priceMaxText,
        nearMetroText,
        geoText
    );
    const allCommon = `${prefixCommon}${regionText ? ' ' : ''}${regionText}`;

    let pageTitle = seoSearchCommonJoin(upperFirst(dealTypeText.verb), landText.nominative, allCommon);

    let metaTitle = `${pageTitle}\u00A0— ${
        offersCountText} о\u00A0${dealTypeText.prepositional} ${
        seoSearchCommonJoin(
            landText.plural,
            landStatusText,
            areText
        )} недорого: планировки, цены и\u00A0фото\u00A0— m2.ru`;

    let metaDescription = `🏡 ${pageTitle} по доступной цене. ➜ В\u00A0каталоге недвижимости ${
        offersCountText} о\u00A0${dealTypeText.prepositional} ${
        seoSearchCommonJoin(
            landText.genitive,
            landStatusText,
            areText,
            regionText
        )}. ✔ Описание и\u00A0фото. ✔ Отзывы покупателей. ✔ Расположение на\u00A0карте.`;

    if (isNonCapitalRegion) {
        pageTitle = seoSearchCommonJoin(upperFirst(dealTypeText.noun), landText.plural, allCommon);

        const title = seoSearchCommonJoin(upperFirst(dealTypeText.verb), landText.nominative, allCommon);

        metaTitle = `${title}, ${seoSearchCommonJoin(
            dealTypeText.noun,
            landText.plural,
            landStatusText,
            areText
        )} недорого. Найдено ${offersCountText} о\u00A0${dealTypeText.prepositional} ${landText.shortGenitive} ${allCommon}
                `;
        const description = seoSearchCommonJoin(upperFirst(dealTypeText.proposal), landText.nominative, allCommon);

        metaDescription = `${description} по доступной цене. ➜ ${seoSearchCommonJoin(
            pageTitle,
            minPriceText
        )} с описанием объектов, планировок и фото. ✔ Отзывы покупателей. ✔ Расположение на\u00A0карте.`;
    }

    if (ls.has('IZHS')) {
        metaTitle = metaTitle.replace(/земельных участков ИЖС/gi, 'земельных участков под строительство дома');
        // eslint-disable-next-line max-len
        metaDescription = `🏡 ${pageTitle.replace(/ИЖС/gi, 'под строительство дома')} по доступной цене. ➜ В\u00A0каталоге недвижимости ${
            offersCountText} о\u00A0${dealTypeText.prepositional} ${
            seoSearchCommonJoin(
                landText.genitive,
                landStatusText,
                areText,
                regionText
            )}. ✔ Описание и\u00A0фото. ✔ Отзывы покупателей. ✔ Расположение на\u00A0карте.`;
    }

    const relativeTitle = seoSearchCommonJoin(upperFirst(dealTypeText.verb), 'похожий', landText.accusative, allCommon);

    return {
        metaTitle,
        metaDescription,
        pageTitle,
        relativeTitle,
        faqTexts: generateFaqTexts({ segmentText: landText, commonPostfixText: allCommon })
    };
}

const areSeoChpuSet = new Set([ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 15, 20, 25, 30, 40, 50, 100 ]);
