import React from 'react';

import './styles.css';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FiltersFormTipsProps {

}

const FiltersFormTips: React.FunctionComponent<FiltersFormTipsProps> = ({ children }) => {
    return (<div className='FiltersFormTips'>
        {children}
    </div>);
};

export default FiltersFormTips;
