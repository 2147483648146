import React, { useCallback } from 'react';

import { CommissioningListFilter } from '../../../../models/Filter';
import { SlimMultiSelect } from '../../../../../common/components/SlimSelect/SlimMultiSelect';

interface IDesktopCommissioningListFilterViewProps {
    filter: CommissioningListFilter;
    onChange: (filter: CommissioningListFilter) => void;
    options: { value: string; label: string }[];
}

export const DesktopCommissioningListFilterView: React.FC<IDesktopCommissioningListFilterViewProps> = ({
    filter,
    onChange,
    options
}) => {
    const { commissioningList } = filter;

    const handleChange = useCallback(
        (val: string[]) => {
            onChange(new CommissioningListFilter(val));
        },
        [ onChange ]
    );

    return (
        <SlimMultiSelect
            dataTest='commissioning'
            placeholder='Срок сдачи'
            forcePlaceholder={commissioningList.length === 0}
            size='s'
            options={options}
            value={commissioningList}
            onChange={handleChange}
            height={400}
            adaptivePlacement={false}
            adaptiveHeight={false}
        />
    );
};
