import { graphql } from '@search/gql-client/src';

export const SearchOffersQuery = graphql`
    fragment SearchOffersQueryNBInfoItemFields on InfoItem {
        weight
        label
        snippetLabel
        value
        snippetValue
    }

    query SearchOffersQuery(
        $regionId: Int!,
        $paging: PagingInput!,
        $sort: [SortInput!],
        $skipGeo: Boolean!,
        $skipNb: Boolean!,
        $nbId: Int!,
        $geos: [GeoSlugInput!]!,
        $filters: ProfessionalSearchInput,
        $realtyObject: RealtyObjectEnum!,
        $pathName: String,
        $touch: Boolean!,
        $skipSeoCloudBack: Boolean!,
        $limit: Int!,
        $onlyUnique: Boolean!,
        $skipLastSearch: Boolean!
    ) {
    mortgageBannerMinutesVariant: randomNumber(from: 0, to: 2) {
        value
    }
    mortgageBannerTextVariant: randomNumber(from: 0, to: 1) {
        value
    }
    userDetails {
        userId
        phone
        hasOfferWithoutVas
    }
    geo(regionId: $regionId) @skip(if: $skipGeo) {
        metro {
            id
            title
            kind
            lines
            mainName
            interchangedStations
            lines
            shortDisplayName
            locativeDisplayName
            route {
                id
            }
        }

        lines {
           id
           title
           kind
           shortDisplayName
           color
        }

        districts {
            kind
            parentId
            displayName
            mainName
            translit
            shortDisplayName
            locative
            title
            id
            isIntracity,
            narrowRegion {
                id
            }
        }
    }
    geo2(geos: $geos) {
        items {
            __typename
            ... on BaseLoaderItemError {
                itemId
            }
            ... on GeoObject {
                kind
                parentId
                displayName
                mainName
                translit
                shortDisplayName
                locative
                title
                id
                isIntracity
                locality {
                    id
                    title
                    locative
                }
                narrowRegion {
                    id
                }
            }
        }
    }
    searchOffersSummary(filters: $filters) {
        formattedRealty(realtyObject: $realtyObject)
        count
        priceRange {
            from
            to
        }
        formattedPriceRange
        formattedPriceFrom
        formattedPricePerUnitRange
        formattedAreaRange
        formattedAveragePrice
        ratingValue
        bestRating
        reviewCount
        avgPricePerUnit
    }

    searchOffersSeo(filters: $filters, pathName: $pathName) {
        h1
        metaTitle
        metaDescription
        autotext
    }

    newBuilding(
        id: $nbId
    ) @skip(if: $skipNb) {
        id
        gallery {
            main {
                large
                originPath
            }
        }
        hasPreferentialMortgage
        buildingClass {
            label
            value
            fullLabel
        }
        title {
            nominative
        }
        buildingStatus {
            title
            isFrozen
        }
        routes(type: [ METRO ]) {
            ...on RouteMetro {
                station {
                    id
                    name
                    lines
                }
                timeMinutes
                transportType
            }
        }
        address
        snippetDescription
        developerCards {
            developer {
                id
                title
                icon {
                    origin
                }
                translitTitle
            }
        }
        coordinates
        routeParams {
            region
            regionId
            subRegion
            type
            name
            id
        }
        offersSeo {
            h1
        }
        info {
            commissioningDate {
                ...SearchOffersQueryNBInfoItemFields
            }
            buildingClass {
                ...SearchOffersQueryNBInfoItemFields
            }
            totalCount {
                ...SearchOffersQueryNBInfoItemFields
            }
            apartments {
                ...SearchOffersQueryNBInfoItemFields
            }
        }
        isFavorite
        ranking
        priceMin
    }

    searchOffers(paging: $paging, sort: $sort, filters: $filters) {
        paging {
            total
            pageNumber
            pageSize
            hasNext
        }

        seoTags {
            watchAlso @skip(if: $skipSeoCloudBack) {
                title
                url
            }
            recommended {
                title
                url
                count
            }
        }

        items {
            id
            description
            noteText
            isFavorite
            isHidden
            isFavoriteVisible
            isMortgageAllowed
            onlineView
            fromCian
            mortgagePurpose
            hasSubscription
            text {
                titleSnippet
                titleCard
                slug
            }
            stats {
                isViewed
            }
            dealType {
                __typename
                ... on ProfOfferDealSell {
                    price {
                        value
                        currency
                        rubPrice
                        areaUnit
                        history {
                            valueRub
                            diff {
                                rub
                            }
                            date2 {
                                iso
                                formattedFull
                            }
                        }
                    }
                    hasBankPledge
                    sellType
                    bargainAllowed {
                        formatted
                    }
                    mortgageAllowed
                }
                ... on ProfOfferDealRent {
                    commission {
                        formatted
                    }
                    bargainAllowed {
                        formatted
                    }
                    price {
                        value
                        rubPrice
                        currency
                        areaUnit
                        history {
                            valueRub
                            diff {
                                rub
                            }
                            date2 {
                                iso
                                formattedFull
                            }
                        }
                    }
                    period
                    utilities {
                        formatted
                    }
                    livingTenantRequirements {
                        childrenAllowed {
                            formatted
                        }
                        petsAllowed {
                            formatted
                        }
                    }
                    hasPrepayment {
                        formatted
                    }
                    hasDeposit {
                        formatted
                    }
                }
            }
            gallery {
                baseUrl
                mainImage {
                    originPath
                    preview1
                    preview2
                    attributesType
                }
                images {
                    originPath
                    preview1
                    preview2
                    medium
                    small
                    large
                    attributesType
                }
            }
            status {
                isDeleted
                createdActualizeFormatted
                createdAt {
                    formattedAutoDate
                }
                actualizedAt {
                    formattedAutoDate
                }
                visibility
                cluster {
                    id
                    tailSize
                }
                hasAnyPriority
                hasTop
                hasHighlight
                vas {
                    valueCase
                }
            }
            infrastructure {
                parking {
                    type
                }
            }
            realtyObject {
                __typename
                ... on ProfOfferFlat {
                    flatType
                    totalArea {
                        formatted
                        value
                        unit
                    }
                    kitchenArea {
                        value
                        unit
                    }
                    livingArea {
                        formatted
                    }
                    roomsCount
                    roomType
                    floor
                    livingFacilities {
                        hasAirConditioner
                        hasDishwasher
                        hasFridge
                        hasFurniture
                        hasInternet
                        hasInternet
                        hasKitchenFurniture
                        hasTv
                        hasWashingMachine
                    }
                    characteristics {
                        hasBalcony
                        ceilingHeightM
                        bathroomType
                        renovationType
                    }
                    building {
                        buildingId
                        newBuildingId
                        isNewbuilding
                        floorsTotal
                        wallsType
                        newBuildingBasicInfo {
                            title
                            slug @skip(if: $touch)
                        }
                        newBuildingStatus {
                            builtYear
                            formattedInProgress
                        }
                    }
                }
                ... on ProfOfferRoom {
                    roomCount
                    livingArea {
                        formatted
                        value
                        unit
                    }
                    enclosingFlat {
                        flatType
                        totalArea {
                            formatted
                            value
                            unit
                        }
                        kitchenArea {
                            value
                            unit
                        }
                        livingArea {
                            formatted
                        }
                        roomsCount
                        roomType
                        floor
                        livingFacilities {
                            hasAirConditioner
                            hasDishwasher
                            hasFridge
                            hasFurniture
                            hasInternet
                            hasInternet
                            hasKitchenFurniture
                            hasTv
                            hasWashingMachine
                        }
                        characteristics {
                                hasBalcony
                            ceilingHeightM
                            bathroomType
                            renovationType
                        }
                        building {
                            buildingId
                            isNewbuilding
                            floorsTotal
                            wallsType
                            newBuildingStatus {
                                builtYear
                            }
                        }
                    }
                }
                ... on ProfOfferHouse {
                    category2 {
                        formatted
                        value
                    }
                    totalArea {
                        value
                        unit
                        formatted
                    }
                    livingFacilities {
                        hasAirConditioner
                        hasDishwasher
                        hasFridge
                        hasFurniture
                        hasInternet
                        hasInternet
                        hasKitchenFurniture
                        hasTv
                        hasWashingMachine
                    }
                    floorsTotal
                    builtYear
                    hasBathroom
                    bathroomLocationType
                    hasHeating
                    heatingType
                    land {
                        area {
                            formatted
                        }
                        supply {
                            hasGas
                            hasWater
                            hasElectricity
                            hasSewerage
                        }
                        status
                    }
                    roomsCount
                }
                ... on ProfOfferLand {
                    area {
                        value
                        unit
                        formatted
                    }
                    supply {
                        hasGas
                        hasWater
                        hasElectricity
                        hasSewerage
                    }
                    status
                }
            }
            badges {
                isRgrMember
            }
            location {
                formattedAddressLong
                cityCenterInfo {
                    metersToCentralSquare
                    locatedInCenter
                }
                formattedAddressShort
                clickableAddress {
                    id
                    translit
                    name
                    kind
                }
                clickableAddressShort {
                    id
                    translit
                    name
                    kind
                }
                narrowRegion {
                    id
                    name
                }
                wideDistrict {
                    id
                    translit
                    name
                }
                narrowDistrict {
                    id
                    translit
                    name
                }
                locality {
                    id
                    translit
                    name
                }
                street {
                    id
                    translit
                    name
                }
                house {
                    id
                    translit
                    name
                }
                coordinates {
                    latitude
                    longitude
                }
                routesList {
                    ... on RouteMetro {
                        timeMinutes
                        transportType
                        station {
                            id
                            name
                            allRoutesColorsList
                        }
                    }
                }
                isMskMO
                isSPbLO
            }
            seller {
                ownerId
                name
                organizationName
                sellerType
                logo {
                    preview2
                    origin
                }
                placementUserId
                isZhekaLogo
                mainPhone {
                    countryCode
                    number
                    hiddenSuffixLength
                    formattedNumber
                }
                phoneList {
                    countryCode
                    number
                    hiddenSuffixLength
                    formattedNumber
                }
            }
            isNewBuildingPaid
            newBuildingCard {
                ranking
                title {
                    nominative
                    genitive
                }
                developerCards {
                    developer {
                        isSamolet
                        title
                        url
                    }
                }
                buildings {
                    id
                    address
                }
            }
        }
        similarNb {
            id
            description
            text {
                titleSnippet
                slug
            }
            gallery {
                mainImage {
                    originPath
                    preview1
                    small
                    attributesType
                }
            }
            dealType {
                __typename
                ... on ProfOfferDealSell {
                    price {
                        value
                        rubPrice
                        formatted
                        currency
                        areaUnit
                    }
                }
            }
            location {
                formattedAddressLong
                formattedAddressShort
                routesList {
                    ... on RouteMetro {
                        timeMinutes
                        transportType
                        station {
                            id
                            name
                            allRoutesColorsList
                        }
                    }
                }
                isMskMO
                isSPbLO
            }
            realtyObject {
                __typename
                ... on ProfOfferFlat {
                    building {
                        buildingId
                        newBuildingBasicInfo {
                            title
                        }
                        newBuildingStatus {
                            formattedInProgress
                            isKeysComingSoonBadge
                            isDoneBadge
                        }
                    }
                }
            }
        }
        emptyResultTitle
    }
    getLastSearches(limit: $limit, onlyUnique: $onlyUnique) @skip(if: $skipLastSearch) {
        lastSearches {
            searchText
            searchUrl
        }
    }
}
`;
