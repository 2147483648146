import { AreaUnit } from '@search/filter-enums/enums';

export const priceTypeShortMapping = {
    [AreaUnit.UNKNOWN]: 'за всё',
    [AreaUnit.METER]: 'за м²',
    [AreaUnit.ARE]: 'за сот.',
    [AreaUnit.MORTGAGE_PAYMENT]: 'Платёж до'
} as const;

export const priceTypeMapping = {
    [AreaUnit.UNKNOWN]: 'За всё',
    [AreaUnit.METER]: 'За м²',
    [AreaUnit.ARE]: 'За сотку',
    [AreaUnit.MORTGAGE_PAYMENT]: 'Платёж по ипотеке'
} as const;

