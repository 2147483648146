import React, { useCallback } from 'react';
import { Button } from '@vtblife/uikit';

interface IProps {
    onChange: (data: { diff: { selected: number[]; deselected: number[] }}) => void;
    selectedDistricts?: Set<number>;
}

const SPB_CENTER_DISTRICTS = [ 466, 448, 446, 476 ];

export const SPBCenterDistricts: React.FC<IProps> = ({ onChange, selectedDistricts = new Set() }) => {
    const selected = SPB_CENTER_DISTRICTS.every(id => selectedDistricts.has(id)) &&
        selectedDistricts.size === SPB_CENTER_DISTRICTS.length;

    const selectSPBCenterDistricts = useCallback(() => {
        onChange({
            diff: {
                selected: SPB_CENTER_DISTRICTS,
                deselected: Array.from(selectedDistricts).filter(id => ! SPB_CENTER_DISTRICTS.includes(id))
            }
        });
    }, [ onChange ]);

    return (
        <Button
            variant='secondary'
            size='s'
            fullWidth
            selected={selected}
            onClick={selectSPBCenterDistricts}
        >
            Центр
        </Button>
    );
};
