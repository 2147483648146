/* eslint-disable padding-line-between-statements */
/* eslint-disable complexity */
import { DealType } from '@search/filter-enums/enums/DealType';
import { NewBuilding } from '@search/filter-enums/enums/NewBuilding';
// @ts-nocheck
import { RealtyObjectType } from '@search/filter-enums/enums/RealtyObjectType';
import { Rooms } from '@search/filter-enums/enums/Rooms';
import { s, SchemaTypeError, Tokens } from '@search/router/src';

import { ClassifiedRoute } from '../ClassifiedRoute';
import { categoryTypeMapper, dealTypeMapper, newBuildingMapper, roomMapper } from '../mappers';

const OfferType = s.rec({
    offerId: s.str,
    slug: s.opt(s.str),
    region: s.opt(s.num),
    dealType: s.opt(dealTypeMapper.Type),
    categoryType: s.opt(categoryTypeMapper.Type),
    room: s.opt(roomMapper.Type),
    newBuilding: s.opt(newBuildingMapper.Type),
    mapBounds: s.opt(s.rec({
        lx: s.num,
        ly: s.num,
        rx: s.num,
        ry: s.num
    }))
});

export type IOfferType = ReturnType<typeof OfferType>;

const OfferSeoType = s.rec({
    origin: s.nullOpt(s.str),
    // offerId: s.str,
    region: s.opt(s.str),
    dealType: s.opt(dealTypeMapper.SeoType),
    categoryType: s.opt(categoryTypeMapper.SeoType),
    room: s.opt(roomMapper.SeoType),
    newBuilding: s.opt(newBuildingMapper.SeoType),
    lx: s.opt(s.str),
    ly: s.opt(s.str),
    rx: s.opt(s.str),
    ry: s.opt(s.str),
    slugAndId: s.str
});

export type IOfferSeoType = ReturnType<typeof OfferSeoType>;

const offerDefaults = {
    dealType: DealType.SELL,
    categoryType: RealtyObjectType.FLAT,
    newBuilding: NewBuilding.UNKNOWN
} as const;

const dealReg = '(?<deal>prodazha|arenda)';

const realtyReg = '(?:(?<flat>kvartiri)|(?<room>komnat(?:i|a|noi)?)|(?<house>usadbi|doma)|(?<land>uchastka))';

const roomReg = '(?:(?<studio>studii)|(?:(?<rooms>[1-4])(-komnatnoi)?)' +
    '|(?<multiroom>mnogokomnatnoi)|(?<openplan>so-svobodnoi-planirovkoi))';

const offerIdReg = '(?<offerId>[a-z,0-9]+(-[a-z,0-9]+){4,4})';

const slugRegExp = new RegExp(`(?:(?<slug>${dealReg}(?:-${roomReg})?-${realtyReg}(?:-.+)?)|(?:offer))?-${offerIdReg}$`);

export class OfferRoute extends ClassifiedRoute<IOfferSeoType, IOfferType> {
    displayName = 'OfferRoute';

    protected metadata = OfferSeoType.config;

    protected pattern(p: Tokens<IOfferSeoType>) {
        return `${this.patternHost()}(/${p.region})/nedvizhimost(/${p.dealType}-${p.categoryType})(/${p.room
        })(/${p.newBuilding})/${p.slugAndId}/`;
    }

    protected extraPatterns(p: Tokens<IOfferSeoType>) {
        return [
            `${this.patternHost()}/search/${p.slugAndId}/`
        ];
    }

    protected conditions() {
        return {
            ...super.conditions(),
            room: roomMapper.keys,
            categoryType: categoryTypeMapper.keys,
            slugAndId: '(?:prodazha|arenda|offer)-.*',
            dealType: dealTypeMapper.keys,
            newBuilding: newBuildingMapper.keys
        };
    }

    defaults() {
        return offerDefaults;
    }

    protected fromQuery(seo: IOfferSeoType) {
        const raw = OfferSeoType(seo);
        const regionSlug = raw.region === 'search' ? 'moskva' : raw.region;

        const region = regionSlug ? this.regionId(regionSlug) : undefined;

        const p = raw.slugAndId.match(slugRegExp)?.groups;

        if (! p?.offerId) {
            throw new SchemaTypeError(`offer id not found, slugAndId: ${raw.slugAndId}`);
        }

        let dealType = DealType.SELL;

        if (p.deal === 'arenda') dealType = DealType.RENT;

        let categoryType = RealtyObjectType.FLAT;

        if (p.room) categoryType = RealtyObjectType.ROOM;
        if (p.house) categoryType = RealtyObjectType.HOUSE;
        if (p.land) categoryType = RealtyObjectType.LAND;

        let room: undefined | Rooms;

        if (p.studio) room = Rooms.STUDIO;
        if (p.rooms === '1') room = Rooms.ROOM_1;
        if (p.rooms === '2') room = Rooms.ROOM_2;
        if (p.rooms === '3') room = Rooms.ROOM_3;
        if (p.rooms === '4') room = Rooms.ROOM_4;
        if (p.multiroom) room = Rooms.ROOM_5_AND_MORE;
        if (p.openplan) room = Rooms.OPEN_PLAN;

        return ({
            offerId: p.offerId,
            region,
            slug: p.slug,
            dealType,
            categoryType,
            room,
            mapBounds: raw.lx && raw.ly && raw.rx && raw.ry ? {
                lx: Number(raw.lx),
                ly: Number(raw.ly),
                rx: Number(raw.rx),
                ry: Number(raw.ry)
            } : undefined
        });
    }

    protected toQuery(p: IOfferType) {
        // params = OfferType(params);
        const offerId = p.offerId;
        const slug = p.slug ?? 'offer';

        try {
            return OfferSeoType({
                ...this.regionParams(p.region),
                slugAndId: `${slug}-${offerId}`,
                lx: p.mapBounds?.lx ? String(p.mapBounds.lx) : undefined,
                ly: p.mapBounds?.ly ? String(p.mapBounds.ly) : undefined,
                rx: p.mapBounds?.rx ? String(p.mapBounds.rx) : undefined,
                ry: p.mapBounds?.ry ? String(p.mapBounds.ry) : undefined
            });
        } catch (e) {
            // @ts-expect-error
            e.message += `, offer: ${offerId}`;
            throw e;
        }
    }
}
