import React, { HTMLAttributes, ReactNode, Ref } from 'react';

import { Typography } from '@vtblife/uikit';
import type { TypographyColor, TypographyVariant } from '@vtblife/uikit/dist/components/typography/types';

import classname from '@search/classname/src';

import './styles.css';

export type BadgeIndent = 'xxs' | 'xs' | 's' | 'm' | 'l';

export interface IBadgeProps extends HTMLAttributes<HTMLDivElement> {
    size?: 'xs' | 'm';
    color?: TypographyColor;
    level?: TypographyVariant;
    bold?: boolean;
    backgroundColor?: string;
    before?: ReactNode;
    rightIndent?: BadgeIndent | null;
    noBottomMargin?: boolean;
    textClassName?: string;
    anchorRef?: Ref<HTMLDivElement | null>;
}

const cn = classname.bind(null, 'Badge');

export const Badge = ({
    className,
    size = 'xs',
    level = 'small-alone',
    color = 'white-500',
    backgroundColor,
    before,
    children,
    rightIndent = 'xxs',
    noBottomMargin = false,
    textClassName,
    anchorRef,
    bold = false,
    ...props
}: IBadgeProps) => {
    return (
        <div
            {...props}
            ref={anchorRef}
            className={cn(null, {
                size,
                rightIndent: rightIndent === null ? undefined : rightIndent,
                noBottomMargin
            }, className)}
            style={{ backgroundColor }}
        >
            {before && (
                <div className={cn('before')}>
                    {before}
                </div>
            )}
            <Typography
                color={color}
                className={textClassName}
                variant={level}
                bold={bold}
            >
                {children}
            </Typography>
        </div>
    );
};
