import React from 'react';

import Icon, { IIconProps } from '../Icon';

import './styles.css';

interface IIconPercent extends IIconProps {
    type?: 'percent';
}

export default ({
    type = 'percent',
    ...props
}: IIconPercent): React.ReactElement => React.cloneElement(<Icon />, {
    type,
    ...props
});
