import React, { useCallback } from 'react';

import { PaymentType } from '@search/filter-enums/enums';

import { PaymentTypeFilter } from '../../../../models/Filter';

import MobileFilter from '../../../filter/MobileFilter';

import { MultiSelect, MultiSelectOption } from '../../common/MultiSelect';

const selectOptions: MultiSelectOption[] = [
    {
        value: PaymentType.ARMY_MORTGAGE,
        label: 'Военная ипотека',
        className: 'col-xs-12 filter-item-gap'
    },
    {
        value: PaymentType.INSTALLMENT,
        label: 'Рассрочка',
        className: 'col-xs-12 filter-item-gap'
    },
    {
        value: PaymentType.MOTHER_CAPITAL,
        label: 'Материнский капитал',
        className: 'col-xs-12 filter-item-gap'
    }
];

interface IProps {
    filter: PaymentTypeFilter;
    handleChange: (filter: PaymentTypeFilter) => void;
}

export const MobilePaymentTypeFilterView: React.FC<IProps> = ({
    filter,
    handleChange
}) => {
    const onChange = useCallback(value => {
        handleChange(new PaymentTypeFilter(value));
    }, [ handleChange ]);

    return (
        <MobileFilter
            filterTitle='Покупка'
        >
            <MultiSelect
                name='paymentType'
                className='container container__clamp'
                options={selectOptions}
                value={filter.type}
                handleChange={onChange}
            />
        </MobileFilter>
    );
};

