/* eslint-disable camelcase */
import { RegionIdEnum } from '@search/filter-enums/enums/Region';
import React from 'react';

import { dataLayerPush } from '../../google/dataLayerPush';
import type { OfferType } from '../search/page/OfferQuery';
import { RankingEnum, OfferQuery$data } from '../search/page/OfferQuery/__generated__/OfferQuery.graphql';
import { OfferSnippetModel } from '../search/page/SearchOffersQuery';

const contextDefault: TOfferGtm2 & {
    ownerId: undefined | number;
    offerId: undefined | string;
} = {
    ownerId: undefined,
    offerId: undefined
};

type Offer = Pick<
    NonNullable<Extract<OfferQuery$data['offer'], {__typename: 'ProfOfferSnippet'}>['related']>[number] |
    OfferType |
    OfferSnippetModel,
    'dealType' | 'status' | 'seller' | 'realtyObject' | 'newBuildingCard' | 'location' | 'id'
>;

type OfferRealtyType = NonNullable<Offer['realtyObject']>['__typename'];

function getRealtyType(realtyType?: OfferRealtyType) {
    switch (realtyType) {
        case 'ProfOfferRoom': return 'room';
        case 'ProfOfferHouse': return 'house';
        case 'ProfOfferLand': return 'land';
        case 'ProfOfferFlat':
        case '%other':
        default: return 'flat';
    }
}

export const ClassifiedOfferGtmContext = React.createContext(contextDefault);

export function useClassifiedOfferDataLayerPush() {
    const ctx = React.useContext(ClassifiedOfferGtmContext);

    return React.useCallback(
        <O extends {event: string}>(p: O) => dataLayerPush(
            ctx === contextDefault || ! ctx.offerId ? p : {
                ...ctx,
                ...p
            }
        ),
        [ ctx ]
    );
}

type Page = 'serp' | 'card' | 'favorites' | 'map' | 'similar' | 'buildingRelated' | 'other' | 'hidden-offers';
type TOfferGtm2 = {
    section: 'sell' | 'rent';
    type: 'second' | 'new_owner' | 'new_dev';
    vas: boolean;
    offerId: string;
    partnerVas: string | 'unknown';
    nbPaid: Lowercase<RankingEnum>;
    vasType: 'highlight' | 'top' | 'highlight_top' | 'unknown';
    pageType: Page; // old
    page_type: Page; // new api
    region: number;
    ownerId: number | undefined;
    realtyObjectType: 'flat' | 'house' | 'land' | 'room';
}

// может пора сделать его первым?
export const createOfferGtm2Context = ({ place, offer }: {
    place: TOfferGtm2['pageType'];
    offer: Offer;
}) => {
    const gtmData: TOfferGtm2 = {
        section: 'sell',
        type: 'second',
        vas: offer.status?.hasAnyPriority ?? false,
        offerId: offer.id,
        partnerVas: 'unknown',
        nbPaid: (offer.newBuildingCard?.ranking ?? 'UNKNOWN').toLocaleLowerCase() as Lowercase<RankingEnum>,
        vasType: 'unknown',
        pageType: place,
        page_type: place,
        region: offer.location.narrowRegion?.id ?? RegionIdEnum.MSK,
        ownerId: offer.seller.ownerId ?? undefined,
        realtyObjectType: getRealtyType(offer.realtyObject?.__typename)
    };

    if (offer.dealType?.__typename === 'ProfOfferDealRent') {
        gtmData.section = 'rent';
    }

    if (offer.realtyObject?.__typename === 'ProfOfferFlat' &&
        offer.realtyObject.building?.isNewbuilding
    ) {
        gtmData.type = 'new_owner';

        if (offer.seller.sellerType === 'DEVELOPER') {
            gtmData.type = 'new_dev';
        }
    }

    if (offer.status?.hasAnyPriority && offer.seller.organizationName) {
        gtmData.partnerVas = offer.seller.organizationName;
    }

    if (offer.status?.hasHighlight) {
        gtmData.vasType = 'highlight';
    }
    if (offer.status?.hasTop) {
        gtmData.vasType = 'top';
    }
    if (offer.status?.hasHighlight && offer.status?.hasTop) {
        gtmData.vasType = 'highlight_top';
    }

    return gtmData;
};
