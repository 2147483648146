import React, { useCallback } from 'react';
import { IRangeInputProps, RangeInput } from '../NewRangeInput/RangeInput';
import { INumberRange } from '../../../types/IRange';

interface INumberInputRangeProps extends Omit<IRangeInputProps, 'value' | 'onChange' | 'valueFrom' | 'valueTo'> {
    value?: INumberRange;
    onChange: (value: INumberRange) => void;
    disabled?: boolean;
}

export const NumberInputRange: React.FC<INumberInputRangeProps> = ({
    value,
    onChange,
    disabled = false,
    ...rangeInputProps
}) => {
    const handleChange = useCallback(({ from, to }: INumberRange) => {
        onChange({
            from,
            to
        });
    }, [ onChange ]);

    return (
        <RangeInput
            valueFrom={value ? value.from : null}
            valueTo={value ? value.to : null}
            onChange={handleChange}
            disabled={disabled}
            {...rangeInputProps}
        />
    );
};
