/* eslint-disable @typescript-eslint/naming-convention */
import { GeoKind } from '@search/filter-enums/enums/GeoKind';
import { TipTypeEnum } from '@search/graphql-typings';
import { RegionIdEnum } from '@search/filter-enums/enums/Region';
import { titleSorter, isPresent } from '../domainHelpers';
import { geoProtoDistrict } from './proto/district';
import { GeoObject2 } from './GeoObject2';
import { GeoId } from './GeoBase';

export interface District {
    readonly __typename?: undefined;
    readonly kind: GeoKind.DISTRICT;
    readonly id: GeoId;
    readonly parentId: GeoId;
    readonly title: string;
    readonly translit: string;
    readonly locativeName?: string;
    readonly titleShort?: string;
    readonly isIntracity?: boolean;
    readonly narrowRegionId?: number;
}

export class DistrictStore {
    protected readonly map = new Map<GeoId, District>();
    protected readonly ids: GeoId[] = [];
    regionId: number | undefined;

    get items() {
        return Array.from(this.map.values());
    }

    get(id: GeoId): District | undefined {
        return this.map.get(id);
    }

    getGql(id: GeoId) {
        const geo = this.get(id);

        if (! geo) return undefined;

        return {
            __typename: 'TipDistrict',
            id: geo.id,
            translit: geo.translit,
            title: geo.title ?? geo.titleShort,
            type: TipTypeEnum.District
        };
    }

    has(id: GeoId): boolean {
        return this.map.has(id);
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    protected _sorted: District[] | null = null;

    get sortedByTitle(): readonly District[] {
        if (! this._sorted) {
            this._sorted = this.ids
                .map(id => this.get(id))
                .filter(isPresent)
                .sort(titleSorter);
        }
        return this._sorted;
    }

    protected byRegionDistricts: DistrictStore | undefined = undefined;

    byRegion() {
        const regionId = this.regionId;

        if (regionId === RegionIdEnum.LEN_OBL || regionId === RegionIdEnum.MSK_OBL) {
            if (this.byRegionDistricts) {
                return this.byRegionDistricts;
            }
            const filtered = new DistrictStore();

            for (const id of this.ids) {
                const item = this.get(id);

                if (item?.parentId === regionId) {
                    filtered.addDistrict(item);
                }
            }

            this.byRegionDistricts = filtered;

            return filtered;
        }

        return this;
    }

    addDistrict(district: District) {
        const id = district.id;

        this.ids.push(id);
        this.map.set(id, district);
    }

    isEmpty() {
        return this.ids.length === 0;
    }

    add(geo: GeoObject2) {
        if (geo.kind !== GeoKind.DISTRICT && geo.kind !== GeoKind.LOCALITY) return;

        const id = geo.id;

        if (this.map.has(id)) return;

        const district = geoProtoDistrict(geo);

        this.addDistrict(district);
    }
}
