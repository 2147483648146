import './FilterTagList.css';

import React from 'react';

import classname from '@search/classname/src';
import { TransportType } from '@search/filter-enums/enums/TransportType';

import { ClassifiedFilterCollection } from '../../../../../../../domain/offer/filters/ClassifiedFilterCollection';
import { NewbuildingFilterCollection } from '../../../../../../../domain/newbuilding/filters/NewbuildingFilterCollection';
import { MetroMark } from '../../../../../../common/components/GeoSuggest/MetroMark';
import { IconPedestrian } from '../../../../../../common/components/OfferRouteCard/IconPedestrian';
import { IconBus } from '../../../../../../common/components/OfferRouteCard/IconBus';
import { ExtendedTip, QueryDisplayValue } from '../../../../../../common/hooks/useQueryDisplayValues';
import { timeLimitLabel } from '../../../../../../tips/labels';
import { Filters, TransportLimitFilter } from '../../../../../models/Filter';
import { FilterTagListItem } from './FilterTagListItem';

const cn = classname.bind(null, 'FilterTagList');

export function FilterTagList({
    filters,
    tips,
    removeTips,
    openMetroModal
}: {
    removeTips(tips: ExtendedTip[]): void;
    filters: ClassifiedFilterCollection | NewbuildingFilterCollection;
    tips: QueryDisplayValue[];
    openMetroModal: () => void;
}) {
    const transportType = filters.get<TransportLimitFilter>(Filters.transportLimit).transportType;
    const timeLimit = filters.get<TransportLimitFilter>(Filters.transportLimit).timeLimit;

    const [ expanded, setExpanded ] = React.useState(false);
    const toggleExpanded = React.useCallback(() => {
        setExpanded(prevState => ! prevState);
    }, [ setExpanded ]);

    const allTips: ExtendedTip[] = transportType && timeLimit ?
        [
            {
                __typename: 'TipRouteMetro',
                id: 'TipRouteMetro',
                transportType: {
                    value: transportType
                },
                timeLimit: {
                    formatted: timeLimitLabel[timeLimit]!
                }
            },
            ...tips
        ] :
        tips;

    const maxVisible = 3;
    const visibleTips = expanded ? allTips : allTips.slice(0, maxVisible);
    const toggleExpandedDisabled = allTips.length <= maxVisible;

    const deleteTip = React.useCallback((tip: ExtendedTip) => {
        removeTips([ tip ]);
    }, [ removeTips ]);

    const resetAll = React.useCallback(() => {
        removeTips(allTips);
    }, [ removeTips, allTips ]);

    return (
        <div className={cn()}>
            {visibleTips.map(tip => (
                <FilterTagListItem
                    id={`filter-tag-${tip.id}`}
                    key={tip.id}
                    onDelete={() => deleteTip(tip)}
                    children={<TipContent tip={tip} />}
                    openMetroModal={tip.__typename === 'TipRouteMetro' ? openMetroModal : undefined}
                />
            ))}
            {
                allTips.length === 0 ? null : (
                    <div className={cn('links')}>
                        <button
                            data-test='filter-tag-more'
                            className={cn('more', { disabled: toggleExpandedDisabled })}
                            disabled={toggleExpandedDisabled}
                            onClick={toggleExpanded}
                        >
                            {/* eslint-disable-next-line no-nested-ternary */}
                            {toggleExpandedDisabled ? '' : (expanded ? 'Свернуть' : 'Показать еще')}
                        </button>
                        <button
                            data-test='filter-tag-reset'
                            className={cn('reset')}
                            onClick={resetAll}
                        >
                            Сбросить все
                        </button>
                    </div>
                )
            }
        </div>
    );
}

function TransportTypeIcon({
    transportType
}: {
    transportType: TransportType | null;
}) {
    switch (transportType) {
        case TransportType.FOOT:
            return <IconPedestrian color='currentColor' />;
        default:
            return <IconBus color='currentColor' />;
    }
}

function TipContent({
    tip
}: {
    tip: ExtendedTip;
}) {
    switch (tip.__typename) {
        case 'TipRouteMetro':
            return (
                <>
                    До метро&nbsp;
                    <TransportTypeIcon transportType={tip.transportType.value} />
                    &nbsp;{tip.timeLimit.formatted} мин.
                </>
            );
        case 'TipMetroStation':
            return (
                <>
                    {tip.allRoutesColors ? <MetroMark colors={tip.allRoutesColors} /> : 'м.'}
                    {tip.title}
                </>
            );
        default:
            return <>{tip.title}</>;
    }
}
