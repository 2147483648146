import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { Select, Typography } from '@vtblife/uikit';

import classname from '@search/classname/src';

import './RenderRegionSelectUikitContent.css';

export const MIN_OPTIONS_NUMBER_WITH_INPUT = 10;

function isGroupedOption<Option extends OptionType>(
    option: SelectOption<Option>,
) {
    return option.options !== undefined;
}

type OptionType<Value extends string | number = string> = {
    options?: undefined;
    value: Value;
    label: string;
    disabled?: boolean;
    index?: number;
}

export interface OptionTypeRegionFilter extends OptionType<string> {
    fullName: string;
    isParent?: boolean;
}

type SelectOption<Option extends OptionType> = GroupedOptions<Option> | Option;

export type GroupedOptions<Option extends OptionType> = {
    label?: string;
    options: Option[];
};

const cn = classname.bind(null, 'RegionSelectUikitContent');

const FlatOptionList = ({ options, isBoldParentOption, is, lastIndex }: {
    options: OptionTypeRegionFilter[];
    isBoldParentOption?: boolean;
    is: (check: string) => boolean;
    lastIndex?: number;
}) => (
    <Select.Group>
        {options.map((option, i) => {
            return (
                <Select.Option
                    value={option.value}
                    key={`${option.value}`}
                    selected={is(option.value)}
                >
                    {
                        ! isGroupedOption(option) && option.isParent && isBoldParentOption ?
                            <b>{option.label}</b> :
                            <>{option.label}</>
                    }
                </Select.Option>
            );
        })}
    </Select.Group>
);

const FilteredOptionList = ({
    options,
    emptyText = 'Нет похожих регионов с новостройками',
    is,
}: {
    emptyText?: React.ReactNode;
    options: OptionTypeRegionFilter[];
    is: (check: string) => boolean;
}) => (
    options.length ? (
        <FlatOptionList is={is} options={options} />
    ) : (
        <Typography
            color='minor'
            className={cn('not-found')}
        >
            {emptyText}
        </Typography>
    )
);

const GroupedOptionList = ({ options, is }: {
    options: GroupedOptions<OptionTypeRegionFilter>[];
    is: (check: string) => boolean;
}) => (
    <>
        {options.map((option, index) => (
            <FlatOptionList
                key={index}
                is={is}
                options={option.options}
                isBoldParentOption
                lastIndex={option.options.length - 1}
            />
        ))}
    </>
);

interface IRenderRegionSelectCustomMenu {
    options: SelectOption<OptionTypeRegionFilter>[];
    is: (check: string) => boolean;

    searchInputValue: string;
    setSearchInputValue: Dispatch<SetStateAction<string>>;
    isMobile?: boolean;
    isNative?: boolean;
    showInput?: boolean;
    emptyText?: React.ReactNode;
}

export const RenderRegionSelectUikitContent: React.FC<IRenderRegionSelectCustomMenu> = ({
    options,
    is,
    searchInputValue,
    setSearchInputValue,
    isMobile,
    isNative,
    showInput,
    emptyText,
}: IRenderRegionSelectCustomMenu) => {
    if (isNative) {
        return (
            <GroupedOptionList
                options={options as GroupedOptions<OptionTypeRegionFilter>[]}
                is={is}
            />
        );
    }

    return (
        <div className={cn(null, { full: isMobile })}>
            {showInput && (
                <Select.Search
                    placeholder='Введите регион'
                    size='s'
                    name='region'
                    value={searchInputValue}
                    onChange={setSearchInputValue}
                />
            )}
            <>
                {options.length && isGroupedOption(options[0]) ? (
                    <GroupedOptionList
                        options={options as GroupedOptions<OptionTypeRegionFilter>[]}
                        is={is}
                    />
                ) : (
                    <FilteredOptionList
                        options={options as OptionTypeRegionFilter[]}
                        emptyText={emptyText}
                        is={is}
                    />
                )}
            </>
        </div>
    );
};

