import React, { useCallback } from 'react';
import { Size } from '@vtblife/uikit/legacy';
import { AreaUnit } from '@search/filter-enums/enums';

import { RadioSelect, RadioOption } from '../../common/RadioSelect';
import { PriceFilter } from '../../../../models/Filter';
import MobileFilter from '../../../filter/MobileFilter';
import '../MobileCommon.css';
import { NumberInputRange } from '../../../common/NewNumberInputRange/NumberInputRange';

interface IMobilePriceFilterViewProps {
    filter: PriceFilter;
    priceTypes?: AreaUnit[];
    handleChange: (filter: PriceFilter) => void;
}

const selectOptions: RadioOption[] = [ {
    value: AreaUnit.UNKNOWN,
    label: 'За всё',
    className: 'col-xs-6'
}, {
    value: AreaUnit.METER,
    label: 'За м²',
    className: 'col-xs-6'
}, {
    value: AreaUnit.ARE,
    label: 'За сотку',
    className: 'col-xs-6'
} ];

export const MobilePriceFilterView: React.FC<IMobilePriceFilterViewProps> = ({
    filter,
    priceTypes = [ AreaUnit.UNKNOWN, AreaUnit.METER ],
    handleChange
}) => {
    const onChange = useCallback(value => {
        handleChange(new PriceFilter(filter.priceType, value));
    }, [ filter, handleChange ]);

    const onChangePriceType = useCallback(value => {
        handleChange(new PriceFilter(value, filter.value));
    }, [ filter, handleChange ]);

    const filteredOptions = selectOptions.filter(item => {
        // @ts-ignore
        return priceTypes.includes(item.value);
    });

    return (
        <MobileFilter
            filterTitle='Цена'
        >
            <div className='container'>
                <div className={'col-xs-12 filter-item-gap'}>
                    <NumberInputRange
                        size={Size.Small}
                        unit='₽'
                        value={filter.value}
                        onChange={onChange}
                        nameFrom='priceFrom'
                        nameTo='priceTo'
                    />
                </div>
                {
                    filteredOptions.length > 1 ? (
                        <RadioSelect
                            name='Price'
                            options={filteredOptions}
                            value={filter.priceType}
                            handleChange={onChangePriceType}
                        />
                    ) : null
                }
            </div>
        </MobileFilter>
    );
};
