import React from 'react';

import { GeoTagItem } from './taglist/GeoTagItem';
import { FilteredItem } from './MobileGeoFilterView';

interface IGeoTagListProps {
    items: FilteredItem[];
    handleRemove: (item: FilteredItem) => void;
}

export const GeoTagList: React.FC<IGeoTagListProps> = ({
    items,
    handleRemove
}) => {
    return (
        <>
            {
                items.map(item => (
                    <GeoTagItem
                        key={item.id}
                        item={item}
                        handleRemove={handleRemove}
                    />
                ))
            }
        </>
    );
};
