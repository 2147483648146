import React, { useCallback, useEffect, useReducer, useState } from 'react';

import { Checkbox, Modal, Input, Button, Typography } from '@vtblife/uikit';
import { Textarea } from '@vtblife/uikit/legacy';

import { STATUS } from '@search/gql-client/src';
import { ApplicationIdEnum } from '@search/graphql-typings';
import { useStaticPathConfig } from '@search/common-core/src/StaticPathContext';
import classname from '@search/classname/src';

import useSendCustomerFeedback from '../../../../../common/components/CustomerFeedback/useSendCustomerFeedback';
import type { CustomerFeedbackModalType } from '../../../../../common/components/CustomerFeedback/components/Modal';
import { Action, ACTIONS, IFeedbackFormState, initialFormState, reducer } from '../../../../../common/components/CustomerFeedback/components/Modal/NewBuildings/state';
import {
    ERROR_MESSAGE,
    EXTRA_FEATURES_OPTIONS,
    ExtraFeature,
    FeedbackOption,
    INFO_FULLNESS_DETAILS_OPTIONS,
    INFO_FULLNESS_OPTIONS,
    InfoFullnessDetail,
    QUESTION,
    SEARCH_SUCCESS_OPTIONS,
    USABILITY_DETAILS_OPTIONS,
    USABILITY_OPTIONS,
    UsabilityDetail
} from '../../../../../common/components/CustomerFeedback/components/Modal/NewBuildings/dict';
import { buildCustomerFeedbackVars } from '../../../../../common/components/CustomerFeedback/components/Modal/NewBuildings/buildCustomerFeedbackVars';
import FeedbackSuccessIcon from '../../../../../common/components/CustomerFeedback/components/Modal/successImage.url.svg';

import './styles.css';

const cn = classname.bind(null, 'CustomerFeedbackModalNewBuildingsDesktop');

type ICustomerFeedbackModalNewBuildings = Pick<
    CustomerFeedbackModalType,
    'modalIsOpen' | 'handleCloseModal' | 'handleFinishedFeedback' | 'applicationId' | 'disableFeedbackReappearance'
>

export const CustomerFeedbackModalNewBuildings = ({
    modalIsOpen,
    handleCloseModal: handleCloseModalExternal,
    handleFinishedFeedback,
    disableFeedbackReappearance,
    applicationId = ApplicationIdEnum.NewBuildingsDesktop,
}: ICustomerFeedbackModalNewBuildings) => {
    const [ state, dispatch ] = useReducer(reducer, initialFormState);
    const [ requestStatus, setRequestStatus ] = useState<STATUS>(STATUS.INIT);

    const handleCloseModal = useCallback(() => {
        dispatch({ type: ACTIONS.SET_INITIAL_FORM_DATA });
        setRequestStatus(STATUS.INIT);

        if (requestStatus === STATUS.RESOLVE) {
            handleFinishedFeedback();
            disableFeedbackReappearance();

            return;
        }

        handleCloseModalExternal();
    }, [ requestStatus, handleFinishedFeedback, handleCloseModalExternal, disableFeedbackReappearance ]);

    const sendCustomerFeedback = useSendCustomerFeedback();

    const handleSendCustomerFeedback = useCallback(() => {
        const vars = buildCustomerFeedbackVars(state, applicationId);

        setRequestStatus(STATUS.PENDING);

        sendCustomerFeedback(vars)
            .then(() => {
                setRequestStatus(STATUS.RESOLVE);
            })
            .catch(() => {
                setRequestStatus(STATUS.ERROR);
            });
    }, [ state, applicationId ]);

    useEffect(() => {
        if (requestStatus === STATUS.ERROR) {
            // eslint-disable-next-line no-alert
            window.alert(ERROR_MESSAGE);
        }
    }, [ requestStatus ]);

    return (
        <Modal
            overflow={false}
            swipeToClose={false}
            opened={modalIsOpen}
            onClose={handleCloseModal}
        >
            {
                requestStatus === STATUS.RESOLVE ? (
                    <SuccessModalContent handleCloseModal={handleCloseModal} />
                ) : (
                    <>
                        <Modal.Title>Оцените удобство сервиса m2.ru</Modal.Title>
                        <PrimaryModalContent
                            state={state}
                            dispatch={dispatch}
                            isLoading={requestStatus === STATUS.PENDING}
                            handleSendCustomerFeedback={handleSendCustomerFeedback}
                        />
                    </>
                )
            }
        </Modal>
    );
};

const PrimaryModalContent = ({
    state,
    dispatch,
    isLoading,
    handleSendCustomerFeedback
}: {
    state: IFeedbackFormState;
    dispatch: React.Dispatch<Action>;
    handleSendCustomerFeedback: () => void;
    isLoading?: boolean;
}) => {
    return (
        <div>
            <Typography
                bold
                variant='primary-alone'
                className={cn('question')}
            >
                {QUESTION.usability}
            </Typography>
            <div className={cn('basic')}>
                {Object.entries(USABILITY_OPTIONS).map(([ key, value ]) => (
                    <div
                        key={key}
                        className={cn('basicOption')}
                    >
                        <Checkbox
                            radio
                            value={state.usability.value === key}
                            onChange={() => dispatch({
                                type: ACTIONS.USABILITY_UPDATE,
                                payload: key as FeedbackOption
                            })}
                        >
                            {value}
                        </Checkbox>
                    </div>
                ))}
            </div>
            {
                state.usability.value === FeedbackOption.NO && (
                    <div className={cn('details')}>
                        {Object.entries(USABILITY_DETAILS_OPTIONS).map(([ key, value ]) => (
                            <div
                                key={key}
                                className={cn('detailsOption')}
                            >
                                <Checkbox
                                    variant='filter'
                                    value={state.usability.details.includes(key as UsabilityDetail)}
                                    onChange={isCheck => dispatch({
                                        type: ACTIONS.USABILITY_DETAILS_UPDATE,
                                        payload: { isCheck, value: key as UsabilityDetail }
                                    })}
                                >
                                    {value}
                                </Checkbox>
                            </div>
                        ))}
                    </div>
                )
            }
            {
                state.usability.details.includes(UsabilityDetail.OTHER) && (
                    <div className={cn('other')}>
                        <Input
                            value={state.usability.customText}
                            placeholder='Ваш вариант'
                            onChange={value => dispatch({
                                type: ACTIONS.USABILITY_CUSTOM_TEXT_UPDATE,
                                payload: value
                            })}
                        />
                    </div>
                )
            }
            <Typography
                bold
                variant='primary-alone'
                className={cn('question')}
            >
                {QUESTION.infoFullness}
            </Typography>
            <div className={cn('basic')}>
                {Object.entries(INFO_FULLNESS_OPTIONS).map(([ key, value ]) => (
                    <div
                        key={key}
                        className={cn('basicOption')}
                    >
                        <Checkbox
                            radio
                            value={state.infoFullness.value === key}
                            onChange={() => dispatch({
                                type: ACTIONS.INFO_FULLNESS_UPDATE,
                                payload: key as FeedbackOption
                            })}
                        >
                            {value}
                        </Checkbox>
                    </div>
                ))}
            </div>
            {
                state.infoFullness.value === FeedbackOption.NO && (
                    <div className={cn('details')}>
                        {Object.entries(INFO_FULLNESS_DETAILS_OPTIONS).map(([ key, value ]) => (
                            <div
                                key={key}
                                className={cn('detailsOption')}
                            >
                                <Checkbox
                                    variant='filter'
                                    value={state.infoFullness.details.includes(key as InfoFullnessDetail)}
                                    onChange={isCheck => dispatch({
                                        type: ACTIONS.INFO_FULLNESS_DETAILS_UPDATE,
                                        payload: { isCheck, value: key as InfoFullnessDetail }
                                    })}
                                >
                                    {value}
                                </Checkbox>
                            </div>
                        ))}
                    </div>
                )
            }
            {
                state.infoFullness.details.includes(InfoFullnessDetail.OTHER) && (
                    <div className={cn('other')}>
                        <Input
                            value={state.infoFullness.customText}
                            placeholder='Ваш вариант'
                            onChange={value => dispatch({
                                type: ACTIONS.INFO_FULLNESS_CUSTOM_TEXT_UPDATE,
                                payload: value
                            })}
                        />
                    </div>
                )
            }
            <Typography
                bold
                variant='primary-alone'
                className={cn('question')}
            >
                {QUESTION.extraFeatures}
            </Typography>
            <div className={cn('details')}>
                {Object.entries(EXTRA_FEATURES_OPTIONS).map(([ key, value ]) => (
                    <div
                        key={key}
                        className={cn('detailsOption')}
                    >
                        <Checkbox
                            variant='filter'
                            value={state.extraFeatures.includes(key as ExtraFeature)}
                            onChange={isCheck => dispatch({
                                type: ACTIONS.EXTRA_FEATURES_UPDATE,
                                payload: { isCheck, value: key as ExtraFeature }
                            })}
                        >
                            {value}
                        </Checkbox>
                    </div>
                ))}
            </div>
            <Typography
                bold
                variant='primary-alone'
                className={cn('question')}
            >
                {QUESTION.searchSuccess}
            </Typography>
            <div className={cn('basic')}>
                {Object.entries(SEARCH_SUCCESS_OPTIONS).map(([ key, value ]) => (
                    <div
                        key={key}
                        className={cn('basicOption')}
                    >
                        <Checkbox
                            radio
                            value={state.searchSuccess === key}
                            onChange={() => dispatch({
                                type: ACTIONS.SEARCH_SUCCESS_UPDATE,
                                payload: key as FeedbackOption
                            })}
                        >
                            {value}
                        </Checkbox>
                    </div>
                ))}
            </div>
            <Typography
                bold
                variant='primary-alone'
                className={cn('question')}
            >
                {QUESTION.comment}
            </Typography>
            <div className={cn('comment')}>
                <Textarea
                    rows={3}
                    fullWidth
                    value={state.comment}
                    placeholder='Если у вас есть предложения по улучшению сервиса, или возникла проблема — расскажите нам'
                    onChange={value => dispatch({
                        type: ACTIONS.COMMENT_UPDATE,
                        payload: value
                    })}
                />
            </div>
            <Typography
                bold
                variant='primary-alone'
                className={cn('question')}
            >
                {QUESTION.contact}
            </Typography>
            <div className={cn('contacts')}>
                <Input
                    size='m'
                    value={state.contact}
                    placeholder='Телефон или email'
                    onChange={value => dispatch({
                        type: ACTIONS.CONTACT_UPDATE,
                        payload: value
                    })}
                />
            </div>
            <Modal.StickyActions>
                <Button
                    fullWidth
                    loading={isLoading}
                    disabled={isLoading}
                    onClick={handleSendCustomerFeedback}
                >
                    Отправить отзыв
                </Button>
            </Modal.StickyActions>
        </div>
    );
};

const SuccessModalContent = ({ handleCloseModal }: { handleCloseModal: () => void }) => {
    const staticPath = useStaticPathConfig();

    return (
        <div className={cn('success')}>
            <img
                crossOrigin='anonymous'
                alt='Спасибо за оценку!'
                width={208}
                height={156}
                src={`${staticPath}${FeedbackSuccessIcon}`}
            />
            <Typography
                bold
                variant='h3'
                className={cn('success-feedback-sent')}
            >
                Отзыв отправлен!
            </Typography>
            <Typography
                variant='primary'
                className={cn('success-thanks-text')}
            >
                Спасибо, что помогаете нам становиться лучше
            </Typography>
            <Modal.Actions>
                <Button
                    fullWidth
                    size='l'
                    variant='secondary'
                    onClick={handleCloseModal}
                >
                    Закрыть
                </Button>
            </Modal.Actions>
        </div>
    );
};
