import React, { ReactElement, RefObject } from 'react';
import QRCode from 'react-qr-code';
import { Dropdown, Button, ButtonProps, useDropdownHover } from '@vtblife/uikit';

import classname from '@search/classname/src';
import CustomTypography from '@search/vtbeco-frontend-core/domain/newbuilding/components/common/CustomTypography';

import './styles.css';

type QRPhoneButtonPropsType = ButtonProps & {
    qrCodeValue?: string;
    className?: string;
    qrFgColor?: string;
    renderButton?: ReactElement | null;
    href?: string;
    buttonVariant?: 'primary' | 'secondary';
    dropdownContainer?: RefObject<HTMLElement>;
}

const cn = classname.bind(null, 'QRCodePhoneButton');
const DROPDOWN_OPENING_DELAY = 250;

const QRCodeTrigger: React.FC<{ qrCodeValue: string; qrFgColor?: string }> = ({ qrFgColor, qrCodeValue }) => {
    const { control } = useDropdownHover({ open: DROPDOWN_OPENING_DELAY });

    return (
        <div
            ref={control as RefObject<HTMLDivElement>}
            className={cn('trigger')}
        >
            <QRCode
                value={qrCodeValue}
                fgColor={qrFgColor}
                size={52}
            />
        </div>
    );
};

export const QRCodePhoneButton: React.FunctionComponent<QRPhoneButtonPropsType> = ({
    qrCodeValue,
    className,
    qrFgColor = '#221F2E',
    children,
    href,
    renderButton,
    fullWidth = true,
    buttonVariant,
    dropdownContainer,
    ...buttonProps
}) => {
    return (
        <div className={cn(null, undefined, className)}>
            {renderButton ?? (
                <a className={cn('link', { fullWidth })} href={href}>
                    <Button
                        variant={buttonVariant}
                        fullWidth={fullWidth}
                        {...buttonProps}
                    >
                        {children}
                    </Button>
                </a>
            )}
            {
                qrCodeValue ? (
                    <Dropdown>
                        <QRCodeTrigger
                            qrCodeValue={qrCodeValue}
                            qrFgColor={qrFgColor}
                        />
                        <Dropdown.Content container={dropdownContainer}>
                            <div className={cn('qrCodePopup')}>
                                <CustomTypography
                                    level='secondary-alone'
                                    className={cn('qrCodePopupText')}
                                >
                                    Наведите камеру телефона на код, чтобы быстро набрать номер
                                </CustomTypography>
                                <QRCode
                                    value={qrCodeValue}
                                    fgColor={qrFgColor}
                                    size={240}
                                />
                            </div>
                        </Dropdown.Content>
                    </Dropdown>
                ) : null
            }
        </div>
    );
};
