import React, { useCallback } from 'react';

import { MiscSpecialEvent } from '@search/filter-enums/enums/SpecialEvents';

import { MiscSpecialEventsFilter } from '../../../../models/Filter';
import MobileFilter from '../../../filter/MobileFilter';
import { MultiSelect } from '../../common/MultiSelect';
import type { MultiSelectOption } from '../../common/MultiSelect';

interface IMobileMiscSpecialEventsFilterViewProps {
    filter: MiscSpecialEventsFilter;
    onChange: (value: MiscSpecialEventsFilter) => void;
}

const SELECT_OPTIONS: MultiSelectOption<MiscSpecialEvent>[] = [
    {
        value: MiscSpecialEvent.PROMO,
        label: 'Акция',
        className: 'col-xs-12 filter-item-gap'
    },
    {
        value: MiscSpecialEvent.GIFT,
        label: 'Подарок',
        className: 'col-xs-12 filter-item-gap'
    }
];

export const MobileMiscSpecialEventsFilterView: React.FC<IMobileMiscSpecialEventsFilterViewProps> = ({
    filter,
    onChange
}) => {
    const handleChange = useCallback(value => {
        onChange(new MiscSpecialEventsFilter(value));
    }, [ onChange ]);

    return (
        <MobileFilter filterTitle='Другое'>
            <MultiSelect
                name='miscSpecialEvents'
                options={SELECT_OPTIONS}
                value={filter.value}
                handleChange={handleChange}
                className='container container__clamp'
            />
        </MobileFilter>
    );
};
