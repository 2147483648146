/**
 * @see https://gitlab.m2.ru/vtblife/frontend/mortgage/-/blob/master/src/common/dictionaries/src/form.ts
 */
export const PROPERTY_COST_DEFAULT = 4000000;
export const PROPERTY_COST_MIN = 500000;
export const PROPERTY_COST_MAX = 200000000;
export const PROPERTY_COST_STEP = 100000;

export const OWN_AMOUNT_DEFAULT = 1500000;
export const OWN_AMOUNT_MIN_FACTOR = 0.1;
export const OWN_AMOUNT_MAX_FACTOR = 0.9;
export const OWN_AMOUNT_STEP = 5000;

export const REMAINING_AMOUNT_DEFAULT = 1500000;
export const REMAINING_AMOUNT_MIN_FACTOR = 0.1;
export const REMAINING_AMOUNT_MAX_FACTOR = 0.9;
export const REMAINING_AMOUNT_STEP = 5000;

export const ADDITIONAL_AMOUNT_DEFAULT = 0;
export const ADDITIONAL_AMOUNT_MIN = 0;

export const TERM_DEFAULT = 20;
export const TERM_MIN = 1;
export const TERM_MAX = 30;

/**
 * @see https://gitlab.m2.ru/vtblife/frontend/mortgage/-/blob/master/src/common/utils/src/calculate-factored-range.ts
 */
export const calculateFactoredRange = (
    relativeValue: number,
    minFactor = OWN_AMOUNT_MIN_FACTOR,
    maxFactor = OWN_AMOUNT_MAX_FACTOR,
    minRelativeValue = PROPERTY_COST_MIN,
    maxRelativeValue = PROPERTY_COST_MAX
): [number, number] => {
    const correctRelativeValue = getRangedValue(relativeValue, minRelativeValue, maxRelativeValue);

    return [ Math.round(correctRelativeValue * minFactor), Math.round(correctRelativeValue * maxFactor) ];
};

/**
 * @see https://gitlab.m2.ru/vtblife/frontend/mortgage/-/blob/master/src/common/utils/src/get-ranged-value.ts
 */
export const getRangedValue = (value: number, min: number, max: number): number => Math.min(Math.max(value, min), max);
