import React, { useCallback } from 'react';

import { OtherSpecialEvent } from '@search/filter-enums/enums/SpecialEvents';

import { OtherSpecialEventsFilter } from '../../../../models/Filter';
import MobileFilter from '../../../filter/MobileFilter';
import { MultiSelect } from '../../common/MultiSelect';
import type { MultiSelectOption } from '../../common/MultiSelect';

interface IMobileOtherSpecialEventsFilterViewProps {
    filter: OtherSpecialEventsFilter;
    onChange: (value: OtherSpecialEventsFilter) => void;
}

const SELECT_OPTIONS: MultiSelectOption<OtherSpecialEvent>[] = [
    {
        value: OtherSpecialEvent.OTHER_DISCOUNT,
        label: 'Скидка',
        className: 'col-xs-12 filter-item-gap'
    },
    {
        value: OtherSpecialEvent.OTHER_INSTALLMENT_PLAN,
        label: 'Рассрочка',
        className: 'col-xs-12 filter-item-gap'
    },
    {
        value: OtherSpecialEvent.OTHER_SALES_START,
        label: 'Старт продаж',
        className: 'col-xs-12 filter-item-gap'
    }
];

export const MobileOtherSpecialEventsFilterView: React.FC<IMobileOtherSpecialEventsFilterViewProps> = ({
    filter,
    onChange
}) => {
    const handleChange = useCallback(value => {
        onChange(new OtherSpecialEventsFilter(value));
    }, [ onChange ]);

    return (
        <MobileFilter filterTitle='Паркинг, кладовки'>
            <MultiSelect
                name='otherSpecialEvents'
                options={SELECT_OPTIONS}
                value={filter.value}
                handleChange={handleChange}
                className='container container__clamp'
            />
        </MobileFilter>
    );
};
