import React, { useCallback } from 'react';
import Grid from '@vtblife/uikit-grid';
import { Checkbox, Typography } from '@vtblife/uikit';

import classname from '@search/classname/src';

import { ClosedSalesFilter } from '../../../../models/Filter';
import { CheckboxGroup2 } from '../../../common/CheckboxGroup2';

import './DesktopClosedSalesFilterView.css';

interface IProps {
    filter: ClosedSalesFilter;
    handleChange: (filter: ClosedSalesFilter) => void;
}

const cn = classname.bind(null, 'DesktopClosedSalesFilterView');

const TRUE = 'TRUE';

export const DesktopClosedSalesFilterView: React.FC<IProps> = ({
    filter,
    handleChange
}) => {
    const onChange = useCallback(val => {
        if (val === null) {
            return;
        }

        if (Array.isArray(val)) {
            handleChange(new ClosedSalesFilter(
                val.includes(TRUE)
            ));
        }
    }, [ handleChange ]);

    return (
        <div className={cn()}>
            <Grid
                cols={2}
                variables={{
                    '--row-gap-xs': '20px',
                    '--row-gap-s': '20px',
                    '--row-gap-m': '20px',
                    '--row-gap-l': '20px',
                    '--row-gap-xl': '20px',
                    '--column-gap-xs': '20px',
                    '--column-gap-s': '20px',
                    '--column-gap-m': '20px',
                    '--column-gap-l': '20px',
                    '--column-gap-xl': '20px'
                }}
            >
                <Grid.Cell>
                    <CheckboxGroup2
                        mode='check'
                        name='closedSales'
                        onChange={onChange}
                        value={filter.includingClosedSales ? [ TRUE ] : []}
                        isFilter
                    >
                        {[
                            <Checkbox
                                variant='filter'
                                key='closedSales-checkbox'
                                name={TRUE}
                                block
                            >
                                С закрытыми продажами
                            </Checkbox>
                        ]}
                    </CheckboxGroup2>
                </Grid.Cell>
                <Grid.Cell>
                    <Typography
                        variant='tiny-alone'
                        color='minor'
                        className={cn('explanation')}
                    >
                        Новостройки, в которых все квартиры от застройщика проданы или продажи временно приостановлены
                    </Typography>
                </Grid.Cell>
            </Grid>
        </div>
    );
};
