import React, { useCallback } from 'react';

import classname from '@search/classname/src';
import { Checkbox } from '@vtblife/uikit';
import { BalconyOptions } from '@search/filter-enums/enums/BalconyOptions';

import { Filter2 } from '../../../new_filter/Filter2';
import { BalconyFilter } from '../../../../models/Filter';
import { CheckboxGroup2 } from '../../../common/CheckboxGroup2';

const cn = classname.bind(null, 'DesktopBalconyFilterView');

interface IBalconyFilterProps {
    filter: BalconyFilter;
    handleChange: (filter: BalconyFilter) => void;
}

export const DesktopBalconyFilterView: React.FC<IBalconyFilterProps> = ({
    filter,
    handleChange
}) => {
    const onChange = useCallback(val => {
        if (val === null) {
            handleChange(new BalconyFilter());
        }
        if (Array.isArray(val)) {
            const value = val.includes(BalconyOptions.HAS_ANY);

            if (value) {
                handleChange(new BalconyFilter(BalconyOptions.HAS_ANY));
            } else {
                handleChange(new BalconyFilter());
            }
        }
    }, [ handleChange ]);

    return (
        <Filter2 filterTitle='Балкон/лоджия'>
            <CheckboxGroup2
                mode='check'
                onChange={onChange}
                name='balcony'
                className={cn('checkbox-group')}
                value={[ filter.hasBalcony ]}
                isFilter
            >
                {
                    [ <Checkbox
                        variant='filter'
                        key={'balcony'}
                        name={BalconyOptions.HAS_ANY}
                        block
                    >
                        Балкон
                    </Checkbox> ]

                }
            </CheckboxGroup2>
        </Filter2>
    );
};
