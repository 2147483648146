import {
    FloorConstraints,
    TimeLimit,
    TransportType as TransportTypeEnum,
    BalconyOptions as BalconyOptionsEnum,
    Bathroom as BathroomEnum,
    Renovation as RenovationEnum,
    CeilingHeight as CeilingHeightEnum
} from '@search/filter-enums/enums';

import {
    BuildingClass,
    ParkingType,
    IsApartmentsOptions,
    WallsType,
    InfrastructureOptions,
    Safety,
    NewBuildingDeal,
    PaymentType,
    Accreditation,
    Position,
    SellType,
    AuthorTypeOptionsWithRealtor,
    FlatsSpecialEvent,
    OtherSpecialEvent,
    MiscSpecialEvent,
    BuildEpoch
} from '@search/filter-enums/enums';

export const ceilingHeightLabels: Record<CeilingHeightEnum, string | null> = {
    [CeilingHeightEnum.UNKNOWN]: null,
    [CeilingHeightEnum.FROM_2_5]: 'Высота потолков от 2,5 м',
    [CeilingHeightEnum.FROM_2_7]: 'Высота потолков от 2,7 м',
    [CeilingHeightEnum.FROM_3]: 'Высота потолков от 3 м',
    [CeilingHeightEnum.FROM_4]: 'Высота потолков от 4 м'
};

export const renovationLabels: Record<RenovationEnum, string | null> = {
    [RenovationEnum.UNKNOWN]: null,
    [RenovationEnum.EURO]: 'Евроремонт',
    [RenovationEnum.COSMETIC]: 'Косметический ремонт',
    [RenovationEnum.DESIGNED]: 'Дизайнерский ремонт',
    [RenovationEnum.NEED]: 'Нужен ремонт',
    [RenovationEnum.NONE]: 'Без ремонта'
};

export const bathroomLabels: Record<BathroomEnum, string | null> = {
    [BathroomEnum.UNKNOWN]: null,
    [BathroomEnum.COMBINED]: 'Совмещённый санузел',
    [BathroomEnum.PARTED]: 'Раздельный санузел',
    [BathroomEnum.SEVERAL]: 'Несколько санузлов'
};

export const balconyLabels: Record<BalconyOptionsEnum, string | null> = {
    [BalconyOptionsEnum.UNKNOWN]: null,
    [BalconyOptionsEnum.HAS_ANY]: 'Балкон/Лоджия'
};

export const floorConstraintsLabels: Record<FloorConstraints, string | null> = {
    [FloorConstraints.UNKNOWN]: null,
    [FloorConstraints.ONLY]: 'Только',
    [FloorConstraints.EXCLUDE]: 'Не'
};

export const timeLimitLabel: Record<TimeLimit, string | null> = {
    [TimeLimit.UNKNOWN]: null,
    [TimeLimit.MAX_5_MINUTES]: '5',
    [TimeLimit.MAX_10_MINUTES]: '10',
    [TimeLimit.MAX_15_MINUTES]: '15',
    [TimeLimit.MAX_20_MINUTES]: '20',
    [TimeLimit.MAX_25_MINUTES]: '25',
    [TimeLimit.MAX_30_MINUTES]: '30',
    [TimeLimit.MAX_40_MINUTES]: '40',
    [TimeLimit.MAX_50_MINUTES]: '50',
    [TimeLimit.MAX_60_MINUTES]: '60'
};

export const transportTypeLabel: Record<TransportTypeEnum, string | null> = {
    [TransportTypeEnum.UNKNOWN]: null,
    [TransportTypeEnum.PUBLIC_TRANSPORT]: 'на\u00A0транспорте',
    [TransportTypeEnum.FOOT]: 'пешком'
};

export const buildingClassLabels: Record<BuildingClass, string> = {
    [BuildingClass.ECONOMY]: 'Эконом',
    [BuildingClass.COMFORT]: 'Комфорт',
    [BuildingClass.COMFORT_PLUS]: 'Комфорт+',
    [BuildingClass.BUSINESS]: 'Бизнес',
    [BuildingClass.ELITE]: 'Элитный'
};

export const parkingLabels: Record<ParkingType, string | null> = {
    [ParkingType.UNKNOWN]: null,
    [ParkingType.CLOSED]: 'Закрытая парковка',
    [ParkingType.UNDERGROUND]: 'Подземная парковка',
    [ParkingType.OPEN]: 'Открытая парковка'
};

export const wallsLabels: Record<WallsType, string | null> = {
    [WallsType.UNKNOWN]: null,
    [WallsType.MONOLITH]: 'монолит',
    [WallsType.PANEL]: 'панель',
    [WallsType.BRICK]: 'кирпич',
    [WallsType.BRICK_MONOLITH]: 'кирпич-монолит',
    [WallsType.BLOCK]: 'блок',
    [WallsType.WOOD]: 'дерево'
};

export const apartmentsLabels: Record<IsApartmentsOptions, string | null> = {
    [IsApartmentsOptions.UNKNOWN]: null,
    [IsApartmentsOptions.APARTMENTS_ONLY]: 'Апартаменты',
    [IsApartmentsOptions.NO_APARTMENTS]: 'Без апартаментов'
};

export const infrastructureLabels: Record<InfrastructureOptions, string | null> = {
    [InfrastructureOptions.UNKNOWN]: null,
    [InfrastructureOptions.CONCIERGE]: 'Консьерж',
    [InfrastructureOptions.CLOSED_AREA]: 'Закрытая территория'
};

export const safetyLabels: Record<Safety, string> = {
    [Safety.CONCIERGE]: 'Консьерж',
    [Safety.CLOSED_AREA]: 'Закрытая территория',
    [Safety.GUARDED_AREA]: 'Охрана'
};

export const positionLabels: Record<Position, string> = {
    [Position.STANDALONE]: 'Одиночное здание'
};

export const accreditationLabels: Record<Accreditation, string> = {
    [Accreditation.ACCREDITED_BY_VTB]: 'Аккредитовано ВТБ'
};

export const newBuildingDealLabels: Record<NewBuildingDeal, string> = {
    [NewBuildingDeal.LAW_214]: '214 ФЗ',
    [NewBuildingDeal.ESCROW]: 'Эскроу-счета'
};

export const paymentTypeLabels: Record<PaymentType, string> = {
    [PaymentType.ARMY_MORTGAGE]: 'Военная ипотека',
    [PaymentType.INSTALLMENT]: 'Рассрочка',
    [PaymentType.MOTHER_CAPITAL]: 'Материнский капитал'
};

export const authorLabels = {
    [AuthorTypeOptionsWithRealtor.UNKNOWN]: null,
    [AuthorTypeOptionsWithRealtor.OWNER]: 'Собственник',
    [AuthorTypeOptionsWithRealtor.DEVELOPER]: 'Застройщик',
    [AuthorTypeOptionsWithRealtor.AGENCY]: 'Агентство',
    [AuthorTypeOptionsWithRealtor.PARTNER]: 'Офф. Партнёр',
    [AuthorTypeOptionsWithRealtor.REALTOR]: 'Мои объявления',
    [AuthorTypeOptionsWithRealtor.PIT]: 'Имущественные торги'
};

export const saleTypeLabels = {
    [SellType.UNKNOWN]: null,
    [SellType.SECONDARY]: 'Прямая продажа',
    [SellType.ALTERNATIVE]: 'Альтернативная продажа',
    [SellType.PRIMARY]: 'От застройщика',
    [SellType.SECONDARY_PRIMARY]: 'Прямая продажа',
    [SellType.PRIMARY_REASSIGNMENT]: 'Переуступка'
};

export const flatsSpecialEventsLabels: Record<FlatsSpecialEvent, string> = {
    [FlatsSpecialEvent.FLAT_DISCOUNT]: 'скидка',
    [FlatsSpecialEvent.FLAT_INSTALLMENT_PLAN]: 'рассрочка',
    [FlatsSpecialEvent.FLAT_SALES_START]: 'старт продаж'
};

export const otherSpecialEventsLabels: Record<OtherSpecialEvent, string> = {
    [OtherSpecialEvent.OTHER_DISCOUNT]: 'скидка',
    [OtherSpecialEvent.OTHER_INSTALLMENT_PLAN]: 'рассрочка',
    [OtherSpecialEvent.OTHER_SALES_START]: 'старт продаж'
};

export const miscSpecialEventsLabels: Record<MiscSpecialEvent, string> = {
    [MiscSpecialEvent.PROMO]: 'Акция',
    [MiscSpecialEvent.GIFT]: 'Подарок'
};

export const buildEpochLabels: Record<BuildEpoch, string | null> = {
    [BuildEpoch.UNKNOWN]: null,
    [BuildEpoch.STALIN]: 'Сталинка',
    [BuildEpoch.BREZHNEV]: 'Брежневка',
    [BuildEpoch.KHRUSHCHEV]: 'Хрущевка'
};
