import './Container.css';

import React from 'react';
import classname from '@search/classname/src';

const cn = classname.bind(null, 'OfferYMapDesktopSwitchContainer');

export function OfferYMapDesktopSwitchContainer({
    className,
    children,
    dark
}: {
    dark?: boolean;
    children: React.ReactNode;
    className?: string;
}) {
    return (
        <div className={cn('container', { dark }, className)}>{children}</div>
    );
}
