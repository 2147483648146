import './Desktop.css';

import React, { useState, useRef } from 'react';
import { Button } from '@vtblife/uikit';
import { Checkbox } from '@vtblife/uikit/legacy';

import {
    YMapControlGeoLocation,
    YMapControlGroup,
    YMapControlRuler,
    YMapControlTraffic,
    YMapControlZoom
} from '@search/ymap/src/Control';
import { YMapMargin, YMapBounds } from '@search/ymap/src/bounds';
import { YMapLayer } from '@search/ymap/src/Layer';
import { YMapPane } from '@search/ymap/src/Pane';
import { YMapVector } from '@search/ymap/src/Vector';
import { YMapDrawAreaPane } from '@search/ymap/src/DrawArea/Pane';
import classname from '@search/classname/src';

import { errorCatcherWrap } from '@search/error/src/catcher';
import { OfferYMapDesktopSwitch } from './Switch/Switch';

const cn = classname.bind(null, 'OfferYMapDesktop');

interface OfferYMapDesktopProps {
    tileUrl?: string | undefined;
    bounds?: YMapBounds;
    handleClickList?: () => void;
    setBounds: (bounds: YMapBounds) => void;
    children?: React.ReactNode | null;
    listUrl?: string;
    mapUrl?: string;
    setPolygon: (coordinates: YMapVector[]) => void;
    selectedArea?: readonly YMapVector[];
    tipsVisible?: boolean;
    tipsExpanded?: boolean;
    displaySwitcher?: boolean;
    displayArea?: boolean;
    isDrawAreaVisible?: boolean;
    toggleDrawArea?: () => void;
    controlAlign?: 'right' | 'left';
    altLayout?: boolean;
    hasSidebar?: boolean;
    saveSearch?: () => void;
    showInfrastructure?: boolean;
    setShowInfrastructure?: (isShowInfrastructure: boolean) => void;
    renderCustomerFeedback?: React.ReactNode;
    renderMortgageButton?: React.ReactNode;
}

export const OfferYMapDesktop = errorCatcherWrap(function OfferYMapDesktop({
    bounds,
    tileUrl,
    tipsVisible = false,
    tipsExpanded = false,
    listUrl,
    mapUrl,
    handleClickList,
    setBounds,
    setPolygon,
    children,
    selectedArea,
    displaySwitcher = true,
    displayArea = true,
    isDrawAreaVisible = false,
    controlAlign = 'right',
    toggleDrawArea = () => {},
    altLayout = false,
    hasSidebar,
    saveSearch,
    showInfrastructure,
    setShowInfrastructure,
    renderCustomerFeedback,
    renderMortgageButton
}: OfferYMapDesktopProps) {
    let margin: YMapVector | YMapMargin = { x: 75, y: 10 };
    // Во время движения по карте пин скрываем
    const [ moving, setMoving ] = useState(false);
    // Пока карту не трогали - пин скрыт
    const trigger = useRef(false);

    const onMouseUp = () => {
        setMoving(false);
    };
    const onMouseDown = () => {
        setMoving(true);
        trigger.current = true;
    };

    if (hasSidebar) {
        margin = {
            topLeft: {
                x: isDrawAreaVisible ? 385 : 120,
                y: 10
            },

            bottomRight: {
                x: 75,
                y: 10
            }
        };
    }

    return (<YMapPane
        bounds={bounds}
        margin={margin}
        setBounds={setBounds}
        isScrollZoomEnabled
        onMouseUp={onMouseUp}
        onMouseDown={onMouseDown}
    >
        <YMapControlGroup hAlign={controlAlign} vAlign='middle' layout='vertical'>
            <YMapControlZoom />
            <YMapControlGeoLocation />
            <YMapControlTraffic />
            <YMapControlRuler />
        </YMapControlGroup>

        {tileUrl && <YMapLayer tileUrl={tileUrl} />}

        {saveSearch && <div className={cn('save-search', { alt: altLayout })}>
            <Button
                variant='primary-alt-bg'
                icon='magnifier-heart'
                dataTest='save-search-map'
                onClick={saveSearch}
                shadow
            >
                Сохранить поиск
            </Button>
        </div>}

        {
            (displayArea || setShowInfrastructure) && <div className={cn('toggle', {
                tipsVisible,
                tipsExpanded,
                alt: altLayout,
                hasSave: saveSearch !== undefined
            })}>
                {
                    setShowInfrastructure && <Checkbox
                        checked={showInfrastructure}
                        onChange={setShowInfrastructure}
                        className={cn('infrastructure')}
                    >
                        Инфраструктура
                    </Checkbox>
                }

                {
                    displayArea && <Button
                        variant={isDrawAreaVisible ? 'danger' : 'primary-alt-bg'}
                        onClick={toggleDrawArea}
                        shadow
                        icon='finger-draw'
                    >{isDrawAreaVisible ? 'Удалить область' : 'Нарисовать область'}</Button>
                }

                {isDrawAreaVisible ? <YMapDrawAreaPane handleDrawEnd={setPolygon} selectedArea={selectedArea} /> : null}
            </div>
        }

        {children}

        {
            displaySwitcher && mapUrl && listUrl && handleClickList && <OfferYMapDesktopSwitch
                className={cn(altLayout ? 'switch-alt' : 'switch')}
                listUrl={listUrl}
                mapUrl={mapUrl}
                active='map'
                onClick={(id: string) => id === 'list' ? handleClickList() : undefined}
                dark
            />
        }

        {renderMortgageButton && trigger.current && ! moving && (
            <div className={cn('mortgageButton')}>
                {renderMortgageButton}
            </div>
        )}

        {renderCustomerFeedback && (
            <div className={cn('rateUs')}>
                {renderCustomerFeedback}
            </div>
        )}
    </YMapPane>);
});
