/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable comma-dangle, no-shadow, react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import classname from '@search/classname/src';
import Icon from '@vtblife/uikit-icons';
import { Button } from '@vtblife/uikit';
import { Dots } from '@vtblife/uikit/legacy';

import { ImageLazySeo } from '@search/vtbeco-frontend-core/domain/image/lazy/ImageLazySeo';
import { ImageLazyObserverProvider } from '@search/vtbeco-frontend-core/domain/image/lazy/ImageLazy';
import { ShareButton } from '@search/vtbeco-frontend-core/domain/share/ShareButton';
import NavButtons, { ArrowDirection } from '@search/vtbeco-frontend-core/view/desktop/components/snippet/parts/GallerySnippet/NavButtons';

import { NO_PHOTO_SRC, NO_PHOTO_SRC_SET } from '../../../../../domain/newbuilding/libs/constants';
import { useGetCurrentIndexImages } from '../useGetCurrentIndexImages';
import { FavoritesButton } from '../../FavoritesButton/FavoritesButton';

import { GalleryButtons } from './GalleryButtons/GalleryButtons';
import './GalleryMobile2.css';

const cn = classname.bind(null, 'GalleryMobile2');

export type PictureType = {
    src?: string;
    originPath?: string;
    description?: string;
}

interface IGalleryMobile2Props {
    pictures?: PictureType[];

    layoutImageSrc?: string;
    layoutImageIndex?: number;

    onClick?: (imgIndex: number) => void;
    handleOpenPanorama?: () => void;
    imageDataset?: Record<string, string>;
    gotoBack?: () => void;

    anchorHidingCircleButtons?: React.MutableRefObject<HTMLDivElement | null>;

    isFavoriteActive?: boolean;
    onFavoriteClick?(e: React.MouseEvent): void;
    renderFavorite?: React.ReactNode;

    noTooltip?: boolean;

    renderBadges?: React.ReactNode;
    renderSpecialEvents?: React.ReactNode;
    renderVases?: React.ReactNode;
    noSlider?: boolean;
    showShareButton?: boolean;
    onShareModalVisibilityChange?(isVisible: boolean): void;
    maxPicturesCount?: number;
    isNewBuilding?: boolean;
    isOfferCard?: boolean;
    hovered?: boolean;
    withDots?: boolean;
    withSpace?: boolean;
    withNavButtons?: boolean;
    relativeHeight?: boolean;
    hideActionsButtons?: boolean;
    isVisited?: boolean | null;
    /**
     * Для уменьшения LCP видимым на первом экране картинкам лучше НЕ задавать lazy.
     * Больше инфы тут https://web.dev/lcp-lazy-loading/
     */
    isFirstOffer?: boolean;
}

// eslint-disable-next-line complexity
export const GalleryMobile2: React.FC<IGalleryMobile2Props> = ({
    pictures = [],

    isVisited = false,
    layoutImageSrc,
    layoutImageIndex,

    onClick = () => { },
    imageDataset,

    renderBadges,
    renderSpecialEvents,
    renderVases,

    anchorHidingCircleButtons,

    isOfferCard,
    withDots = true,
    withSpace: withSpaceProp = false,
    noSlider: noSliderProps = false,
    isNewBuilding,
    maxPicturesCount,
    hovered,
    withNavButtons,
    relativeHeight,
    hideActionsButtons,
    isFirstOffer,

    // maybe drill through children
    isFavoriteActive,
    onFavoriteClick,
    renderFavorite: renderFavoriteProp,

    showShareButton,
    onShareModalVisibilityChange,
    handleOpenPanorama,
    gotoBack,
    noTooltip = false
}) => {
    const { images, noSlider, restCountImages } = useMemo(() => {
        let images = pictures;
        let restCountImages = 0;
        let noSlider = noSliderProps;

        if (maxPicturesCount) {
            images = pictures.slice(0, maxPicturesCount);
            restCountImages = pictures.length - images.length;
        }

        if (images.length === 1) {
            noSlider = true;
        }
        if (noSlider) {
            images = images.slice(0, 1);
        }

        return {
            images,
            restCountImages,
            noSlider
        };
    }, [ pictures, noSliderProps ]);

    const { setChildRef, indexImage, galleryRef, setIndexImage } = useGetCurrentIndexImages(images.length);

    const layoutImageRef = React.useRef<HTMLSpanElement | null>(null);
    const setRefProps = (el: HTMLSpanElement | null, index: number, imageSrc: string) => {
        setChildRef(el, index);
        if (imageSrc === layoutImageSrc) {
            layoutImageRef.current = el;
        }
    };
    const handleOpenLayout = React.useCallback((e: React.MouseEvent<Element, MouseEvent>) => {
        e.stopPropagation();
        layoutImageRef.current?.scrollIntoView(false);
        if (typeof layoutImageIndex === 'number' && layoutImageIndex >= 0) {
            setIndexImage(layoutImageIndex);
        }
    }, [ layoutImageRef ]);

    const circleButtonsRef = useRef<HTMLDivElement>(null);
    const circleRightButtonsRef = useRef<HTMLDivElement>(null);
    const circleButtonsWrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isOfferCard) {
            const viewportHeight = window.innerHeight;
            const observerCircleButtonsSticky = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (
                        (! entry.isIntersecting && entry.intersectionRatio === 0) ||
                        (entry.intersectionRatio < 1 && entry.intersectionRatio > 0.5)
                    ) {
                        circleButtonsRef.current?.classList.add('GalleryMobile2__circle-buttons_sticky');
                    }
                    if (entry.isIntersecting && entry.intersectionRatio === 1) {
                        circleButtonsRef.current?.classList.remove('GalleryMobile2__circle-buttons_sticky');
                    }
                });
            }, {
                rootMargin: '0px 5px',
                threshold: [ 0, 0.5, 1, 0.99 ]
            });

            const observerCircleButtonsShow = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (circleRightButtonsRef.current) {
                        const isAnchorVisibleOrScrollThrough = entry.boundingClientRect.top <= viewportHeight;

                        if (isAnchorVisibleOrScrollThrough) {
                            circleRightButtonsRef.current.style.display = 'none';
                        } else {
                            circleRightButtonsRef.current.style.display = 'grid';
                        }
                    }
                });
            }, {
                rootMargin: '0px 5px',
                threshold: [ 0, 0.05, 0.5, 0.95, 1 ]
            });

            circleButtonsWrapperRef.current && observerCircleButtonsSticky.observe(circleButtonsWrapperRef.current);
            anchorHidingCircleButtons?.current && observerCircleButtonsShow.observe(anchorHidingCircleButtons.current);

            return () => {
                observerCircleButtonsShow.disconnect();
                observerCircleButtonsSticky.disconnect();
            };
        }
    }, [ ]);

    const galleryWidth = galleryRef.current?.clientWidth ?? 0;
    const handleClickArrow = useCallback((arrowDirection: ArrowDirection) => {
        if (arrowDirection === 'left') {
            galleryRef.current?.scrollTo({ left: (galleryRef.current?.scrollLeft ?? 0) - galleryWidth });
            setIndexImage(state => state - 1);
        } else {
            galleryRef.current?.scrollTo({ left: (galleryRef.current?.scrollLeft ?? 0) + galleryWidth });
            setIndexImage(state => state + 1);
        }
    }, [ galleryWidth ]);

    const lastImageIndex = images.length - 1;
    const withSpace = withDots && images.length > 1 || withSpaceProp;
    const showNavButtons = withNavButtons && images.length > 1;
    const showActionButtons = ! hideActionsButtons && isOfferCard && (layoutImageSrc || handleOpenPanorama);

    const renderFavoriteInner = onFavoriteClick ? (<FavoritesButton
        isActive={isFavoriteActive}
        onClick={onFavoriteClick}
        variant='primary-alt-bg'
        noTooltip={noTooltip}
        circle
        block
        shadow
    />) : undefined;

    const renderFavorite = renderFavoriteInner ?? renderFavoriteProp;
    const mainSrc = images?.[0]?.src ?? images?.[0]?.originPath;

    return (
        <div className={cn()}>
            <link itemProp='image' href={mainSrc} />
            <div
                className={cn('galleryWrapper', {
                    relativeHeight,
                    withSpace,
                    visited: isVisited,
                    withActionButtons: showActionButtons
                })}
            >
                {showNavButtons && (
                    <NavButtons
                        isActive={hovered}
                        isLastImage={indexImage === lastImageIndex}
                        isFirstImage={indexImage === 0}
                        handleClickArrow={handleClickArrow}
                    />
                )}

                <div
                    ref={galleryRef}
                    className={cn('gallery', { noSlider, withNavButtons, relativeHeight })}
                >
                    <ImageLazyObserverProvider
                        rootRef={galleryRef}
                        rootMargin='0px 50% 0px 0px'
                    >
                        {images.length === 0 && <NoPhoto isNewBuilding={isNewBuilding} />}
                        {images.map(({ src, originPath, description }, index) => {
                            const id = src ?? originPath ?? '';

                            return src || originPath ? (
                                <ImageLazySeo
                                    dataSet={imageDataset}
                                    onClick={() => onClick(index)}
                                    src={src}
                                    originPath={originPath}
                                    srcWidth={280}
                                    srcHeight={210}
                                    key={id}
                                    data-index={index}
                                    className={cn('imageWrapper', {
                                        noSlider, isLayout: id === layoutImageSrc, withSpace })}
                                    imgClassName={cn('image', {
                                        noSlider, isLayout: id === layoutImageSrc, withSpace })}
                                    ref={ref => setRefProps(ref, index, id)}
                                    // Для первой картинки принудительно нативный lazy, в сафари будет всегда загружаться, в хроме - нативно лениво.
                                    // eslint-disable-next-line no-nested-ternary
                                    loadingLazy={isFirstOffer && index === 0 ?
                                        'eager' :
                                        index === 0 ? 'native' : 'io'}
                                    alt={description}
                                    title={description}
                                >
                                    {lastImageIndex === index && restCountImages > 0 && (
                                        <div
                                            className={cn('last-image-preview')}
                                            data-test='snippet-gallery-last-photo'
                                        >
                                            Ещё {restCountImages} фото</div>
                                    )}
                                </ImageLazySeo>
                            ) : (
                                <NoPhoto isNewBuilding={isNewBuilding} key={index} />
                            );
                        })}
                    </ImageLazyObserverProvider>
                </div>

                <div className={cn('badges')}>
                    {renderBadges}
                </div>

                <div className={cn('special-events')}>
                    {renderSpecialEvents}
                </div>

                <div className={cn('priorities')}>
                    {renderVases}
                </div>
            </div>

            {withSpace && (
                <div className={cn('space', { borderBottom: showActionButtons })}>
                    {images.length > 1 ? (
                        <Dots
                            size='s'
                            length={images.length}
                            carousel={7}
                            selected={indexImage}
                        />
                    ) : null}
                </div>
            )}

            {showActionButtons && (
                <GalleryButtons
                    handleOpenLayout={layoutImageSrc ? handleOpenLayout : undefined}
                    handleOpenPanorama={handleOpenPanorama}
                />
            )}

            {
                (gotoBack || showShareButton || renderFavorite) ? (
                    <div className={cn('circle-buttons-wrapper')} ref={circleButtonsWrapperRef}>
                        <div className={cn('circle-buttons')} ref={circleButtonsRef}>
                            {gotoBack && (
                                <Button
                                    circle
                                    variant='primary-alt-bg'
                                    size='s'
                                    icon='chevron-left'
                                    dataTest='offer-back'
                                    shadow
                                    onClick={gotoBack}
                                />
                            )}

                            <div
                                ref={circleRightButtonsRef}
                                className={cn('circle-buttons-group', {
                                    showBothButtons: showShareButton && Boolean(renderFavorite) })}
                            >
                                {showShareButton && <ShareButton isCircle onModalVisibilityChange={onShareModalVisibilityChange} />}
                                {renderFavorite}
                            </div>
                        </div>
                    </div>
                ) : null
            }
        </div>
    );
};

const NoPhoto = ({ isNewBuilding }: { isNewBuilding?: boolean }) => {
    return (<>
        {isNewBuilding && (
            <picture itemType='http://schema.org/ImageObject'>
                <source
                    srcSet={NO_PHOTO_SRC_SET}
                />
                <img
                    className={cn('empty-nb')}
                    crossOrigin='anonymous'
                    alt='нет фотографии ЖК, заглушка'
                    src={NO_PHOTO_SRC}
                    loading='lazy'
                />
            </picture>
        )}
        {! isNewBuilding && (
            <span className={cn('empty')}>
                <Icon name='camera-crossed' size='xl' />
            </span>
        )}
    </>);
};
