import { IDeserializer } from '../../../view/filters/services/codecs/FilterDeserializer';
import {
    Filters,
    IFilter,
    RoomFilter,
    RegionFilter,
    PriceFilter,
    TransportLimitFilter,
    GeoFilter,
    CommissioningFilter,
    FinishingTypeFilter,
    AreaFilter,
    LivingAreaFilter,
    KitchenAreaFilter,
    FloorFilter,
    CellHeightFilter,
    BathroomFilter,
    BalconyFilter,
    ParkingFilter,
    WallsTypeFilter,
    ApartmentFilter,
    PositionFilter,
    DeveloperFilter,
    FloorsTotalFilter,
    BuildingClassFilter,
    SafetyFilter,
    AccreditationFilter,
    NewBuildingDealFilter,
    PaymentTypeFilter,
    CommissioningListFilter,
    BuildingsFilter,
    FinishingFlatFilter,
    ClosedSalesFilter,
    ShowHighlightedFilter,
    MapBoundsFilter,
    SelectedNewBuildingFilter,
    FlatsSpecialEventsFilter,
    OtherSpecialEventsFilter,
    MiscSpecialEventsFilter,
    NotOnlyM2ProFilter,
} from '../../../view/filters/models/Filter';

import {
    Area,
    FloorConstraints,
    Rooms,
    TransportType,
    TimeLimit,
    CeilingHeight,
    BalconyOptions,
    ParkingType,
    WallsType,
    IsApartments,
    Position,
    Safety,
    BuildingClass,
    Accreditation,
    NewBuildingDeal,
    PaymentType,
    Bathroom,
    FlatsSpecialEvents,
    OtherSpecialEvents,
    MiscSpecialEvents
} from './converters';

const ensureArray = (value: any): any[] => value ? [].concat(value) : [];
const ensureNumericArray = (value: any): number[] => ensureArray(value).map(Number);

const deserializers: Record<Filters, IDeserializer> = {
    [Filters.commissioning]: {
        keys: [ 'commissioning' ],
        handler: ({ commissioning }) => new CommissioningFilter(commissioning)
    },
    [Filters.finishingType]: {
        keys: [ 'hasFinishing', 'finishingType' ],
        handler: ({ hasFinishing, finishingType }) => new FinishingTypeFilter({
            hasFinishing,
            finishingType
        })
    },
    [Filters.region]: {
        keys: [ 'region' ],
        handler: ({ region }) => new RegionFilter(region && parseInt(region, 10))
    },
    [Filters.rooms]: {
        keys: [ 'rooms' ],
        handler: ({ rooms }) => new RoomFilter(
            ensureArray(rooms).map(value => Rooms.enumValue[value as keyof typeof Rooms.enumValue])
        )
    },
    [Filters.price]: {
        keys: [ 'priceMin', 'priceMax', 'priceType' ],
        handler: ({ priceMin, priceMax, priceType }) => new PriceFilter(
            Area.enumValue[priceType as keyof typeof Area.enumValue],
            {
                from: priceMin || null,
                to: priceMax || null
            }
        )
    },
    [Filters.transportLimit]: {
        keys: [ 'transportType', 'maxMinutes' ],
        handler: ({ transportType, maxMinutes }) => new TransportLimitFilter(
            TimeLimit.enumValue[maxMinutes],
            TransportType.enumValue[transportType]
        )
    },
    [Filters.geo]: {
        keys: [ 'metroId', 'districtId', 'addressId', 'newBuildingId' ],
        handler: ({ metroId, districtId, addressId, newBuildingId }) => {
            const parsedMetroIds = ensureNumericArray(metroId);
            const parsedDistrictIds = ensureNumericArray(districtId);
            const parsedAddressIds = ensureNumericArray(addressId);
            const parsedNewBuildingIds = ensureNumericArray(newBuildingId);

            return new GeoFilter(
                new Set([ ...parsedMetroIds, ...parsedDistrictIds, ...parsedAddressIds ]),
                parsedMetroIds,
                parsedDistrictIds,
                parsedAddressIds,
                parsedNewBuildingIds
            );
        }
    },
    [Filters.totalArea]: {
        keys: [ 'totalAreaRangeMin', 'totalAreaRangeMax' ],
        handler: ({ totalAreaRangeMin, totalAreaRangeMax }) => new AreaFilter({
            from: totalAreaRangeMin || null,
            to: totalAreaRangeMax || null
        })
    },
    [Filters.livingArea]: {
        keys: [ 'livingAreaRangeMin', 'livingAreaRangeMax' ],
        handler: ({ livingAreaRangeMin, livingAreaRangeMax }) => new LivingAreaFilter({
            from: livingAreaRangeMin || null,
            to: livingAreaRangeMax || null
        })
    },
    [Filters.kitchenArea]: {
        keys: [ 'kitchenAreaRangeMin' ],
        handler: ({ kitchenAreaRangeMin }) => new KitchenAreaFilter({
            from: kitchenAreaRangeMin || null,
            to: null
        })
    },
    [Filters.floor]: {
        keys: [ 'floorMin', 'floorMax', 'floorFirst', 'floorLast' ],
        handler: ({ floorMin, floorMax, floorFirst, floorLast }) => new FloorFilter(
            {
                from: floorMin || null,
                to: floorMax || null
            },
            FloorConstraints.enumValue[floorFirst] || null,
            FloorConstraints.enumValue[floorLast] || null
        )
    },
    [Filters.cellHeight]: {
        keys: [ 'ceilingHeight' ],
        handler: ({ ceilingHeight }) => new CellHeightFilter(
            CeilingHeight.enumValue[ceilingHeight as keyof typeof CeilingHeight.enumValue]
        )
    },
    [Filters.bathroom]: {
        keys: [ 'bathroom' ],
        handler: ({ bathroom }) => {
            if (! bathroom) {
                return new BathroomFilter();
            }

            return new BathroomFilter(
                [].concat(bathroom)
                    .map((value: string) => Bathroom.enumValue[value])
                    .filter(Boolean)
            );
        }
    },
    [Filters.balcony]: {
        keys: [ 'balcony' ],
        handler: ({ balcony }) => new BalconyFilter(balcony ? BalconyOptions.enumValue.HAS_ANY : undefined)
    },
    [Filters.parkings]: {
        keys: [ 'parking' ],
        handler: ({ parking }) => new ParkingFilter(
            ensureArray(parking)
                .map(item => ParkingType.enumValue[item as keyof typeof ParkingType.enumValue])
        )
    },
    [Filters.walls]: {
        keys: [ 'wallsType' ],
        handler: ({ wallsType }) => new WallsTypeFilter(
            ensureArray(wallsType)
                .map(item => WallsType.enumValue[item as keyof typeof WallsType.enumValue])
        )
    },
    [Filters.apartments]: {
        keys: [ 'apartments' ],
        handler: ({ apartments }) => new ApartmentFilter(
            IsApartments.enumValue[apartments as keyof typeof IsApartments.enumValue]
        )
    },
    [Filters.position]: {
        keys: [ 'standalone' ],
        handler: ({ standalone }) => new PositionFilter(
            [ standalone && Position.enumValue.standalone ]
                .filter(Boolean)
        )
    },
    [Filters.safety]: {
        keys: [ 'concierge', 'closedArea', 'guardedArea' ],
        handler: ({ concierge, closedArea, guardedArea }) => new SafetyFilter(
            [
                concierge && Safety.enumValue.concierge,
                closedArea && Safety.enumValue.closedArea,
                guardedArea && Safety.enumValue.guardedArea
            ]
                .filter(Boolean)
        )
    },
    [Filters.developer]: {
        keys: [ 'developerId' ],
        handler: ({ developerId }) => new DeveloperFilter(developerId && Number(developerId))
    },
    [Filters.floorsTotal]: {
        keys: [ 'floorsTotalMin', 'floorsTotalMax' ],
        handler: ({ floorsTotalMin, floorsTotalMax }) => new FloorsTotalFilter({
            from: floorsTotalMin || null,
            to: floorsTotalMax || null
        })
    },
    [Filters.buildingClass]: {
        keys: [ 'buildingClass' ],
        handler: ({ buildingClass }) => new BuildingClassFilter(
            ensureArray(buildingClass)
                .map(item => BuildingClass.enumValue[item as keyof typeof BuildingClass.enumValue])
        )
    },
    [Filters.accreditation]: {
        keys: [ 'accreditedByVTB' ],
        handler: ({ accreditedByVTB }) => new AccreditationFilter(
            [ accreditedByVTB && Accreditation.enumValue.accreditedByVTB ]
                .filter(Boolean)
        )
    },
    [Filters.newBuildingDeal]: {
        keys: [ 'law214', 'escrow' ],
        handler: ({ law214, escrow }) => new NewBuildingDealFilter(
            [
                law214 && NewBuildingDeal.enumValue.law214,
                escrow && NewBuildingDeal.enumValue.escrow
            ]
                .filter(Boolean)
        )
    },
    [Filters.paymentType]: {
        keys: [ 'armyMortgage', 'installment', 'motherCapital' ],
        handler: ({ armyMortgage, installment, motherCapital }) => new PaymentTypeFilter(
            [
                armyMortgage && PaymentType.enumValue.armyMortgage,
                installment && PaymentType.enumValue.installment,
                motherCapital && PaymentType.enumValue.motherCapital
            ]
                .filter(Boolean)
        )
    },
    [Filters.commissioningList]: {
        keys: [ 'commissioningDate' ],
        handler: ({ commissioningDate }) => new CommissioningListFilter(ensureArray(commissioningDate))
    },
    [Filters.buildings]: {
        keys: [ 'buildingId' ],
        handler: ({ buildingId }) => new BuildingsFilter(ensureNumericArray(buildingId))
    },
    [Filters.finishingFlat]: {
        keys: [ 'finishingFlat' ],
        handler: ({ finishingFlat }) => new FinishingFlatFilter({ finishingType: finishingFlat })
    },
    [Filters.closedSales]: {
        keys: [ 'closedSales' ],
        handler: ({ closedSales }) => new ClosedSalesFilter(closedSales)
    },
    [Filters.showHighlighted]: {
        keys: [ 'showHighlighted' ],
        handler: ({ showHighlighted }) => new ShowHighlightedFilter(showHighlighted)
    },
    [Filters.mapBounds]: {
        keys: [ 'mapBounds' ],
        handler: ({ mapBounds }) => {
            return new MapBoundsFilter(mapBounds?.bounds, mapBounds?.polygon);
        }
    },
    [Filters.selectedNewBuilding]: {
        keys: [ 'selectedNewBuildingId' ],
        handler: ({ selectedNewBuildingId }) => new SelectedNewBuildingFilter(
            selectedNewBuildingId && Number(selectedNewBuildingId)
        )
    },
    [Filters.flatsSpecialEvents]: {
        keys: [ 'flatsSpecialEvents' ],
        handler: ({ flatsSpecialEvents }) => new FlatsSpecialEventsFilter(
            ensureArray(flatsSpecialEvents)
                .map(item => FlatsSpecialEvents.enumValue[item as keyof typeof FlatsSpecialEvents.enumValue])
        )
    },
    [Filters.otherSpecialEvents]: {
        keys: [ 'otherSpecialEvents' ],
        handler: ({ otherSpecialEvents }) => new OtherSpecialEventsFilter(
            ensureArray(otherSpecialEvents)
                .map(item => OtherSpecialEvents.enumValue[item as keyof typeof OtherSpecialEvents.enumValue])
        )
    },
    [Filters.miscSpecialEvents]: {
        keys: [ 'miscSpecialEvents' ],
        handler: ({ miscSpecialEvents }) => new MiscSpecialEventsFilter(
            ensureArray(miscSpecialEvents)
                .map(item => MiscSpecialEvents.enumValue[item as keyof typeof MiscSpecialEvents.enumValue])
        )
    },
    [Filters.notOnlyM2Pro]: {
        keys: [ 'notOnlyM2Pro' ],
        handler: ({ notOnlyM2Pro }) => new NotOnlyM2ProFilter(notOnlyM2Pro)
    }
};

class NewbuildingFilterDeserializer {
    deserialize(parsedQuery: any): Map<Filters, IFilter> {
        const result: Map<Filters, IFilter> = new Map<Filters, IFilter>();

        (Object.keys(deserializers) as Filters[]).forEach((filterName: Filters) => {
            const keys = deserializers[filterName].keys;

            const hasFilter = keys.some(key => {
                // @ts-ignore
                return parsedQuery[key] !== undefined || parsedQuery[key] !== null;
            });

            const data = {};

            for (const key of keys) {
                // @ts-ignore
                const parsedObject = parsedQuery[key];

                if (parsedObject) {
                    // @ts-ignore
                    data[key] = parsedObject;
                }
            }

            if (hasFilter) {
                result.set(filterName, deserializers[filterName].handler(data));
            }
        });

        return result;
    }
}

export const newbuildingFilterDeserializer = new NewbuildingFilterDeserializer();
