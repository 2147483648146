import React from 'react';
import { RootRegionsApplicationEnum, SuggestTypeEnum } from '@search/graphql-typings';
import classname from '@search/classname/src';
import { Modal, ModalProps, ModalSize } from '@search/vtbeco-ui/src/components/Modal';
import { BorderRadius } from '@search/vtbeco-ui/src/components/Paper';

import { ModalHeading } from '../../../../../mobile/components/ModalHeading';
import { SuggestionType } from '../../../../../common/components/GeoSuggest/GeoSuggest';
import { GeoSuggest } from '../../../../../common/components/GeoSuggest/GeoSuggest';

import './SuggestModal.css';

const cn = classname.bind(null, 'SuggestModal');

interface ISuggestModalProps extends Pick<ModalProps, 'uikitZIndexEnabled'> {
    regionId: number;
    rootRegionsApp: RootRegionsApplicationEnum;
    hide: () => void;
    handleChange: (ids: SuggestionType) => void;
    onInputToggleFocus?: (disabled: boolean) => void;
    showNewBuildingsGroup?: boolean;
    startWithNewBuildings?: boolean;
    placeholder: string;
    placeholderWithNewBuildings: string;
    limit?: number;
    suggestTypes?: SuggestTypeEnum[];
    selected?: Set<number>;
}

export const SuggestModal: React.FC<ISuggestModalProps> = ({
    hide,
    regionId,
    handleChange,
    onInputToggleFocus,
    rootRegionsApp,
    suggestTypes,
    limit,
    showNewBuildingsGroup,
    startWithNewBuildings,
    placeholder,
    placeholderWithNewBuildings,
    uikitZIndexEnabled,
    selected
}) => {
    return (
        <Modal
            size={ModalSize.FULL_L}
            borderRadius={BorderRadius.none}
            uikitZIndexEnabled={uikitZIndexEnabled}
            fullScreen
        >
            <ModalHeading
                text='Поиск ЖК или адреса'
                onClose={hide}
            />
            <div className={cn('select')}>
                <GeoSuggest
                    autoFocus
                    clear
                    listBorderOut
                    regionId={regionId}
                    rootRegionsApp={rootRegionsApp}
                    suggestTypes={suggestTypes}
                    limit={limit}
                    borderOut={false}
                    onGeoChange={handleChange}
                    onInputToggleFocus={onInputToggleFocus}
                    showNewBuildingsGroup={showNewBuildingsGroup}
                    startWithNewBuildings={startWithNewBuildings}
                    placeholder={placeholder}
                    placeholderWithNewBuildings={placeholderWithNewBuildings}
                    selected={selected}
                />
            </div>
        </Modal>
    );
};
