import {
    NewBuildingOffersSortEnum,
} from '@search/graphql-typings';
import {
    newBuildingTypeMapper,
    roomMapper,
} from '@search/offer-search-routes/src/mappers/newBuilding/mappers';
import { s } from '@search/router/src/schema';
import { Tokens } from '@search/router/src/RouterInterfaces';
import { numToStr, strToNum } from '@search/router/src/schema/strToNum';
import { SusaninRoute } from '@search/router/src/susanin';

import type { INewBuildingRouterContext as IRouterContext } from '../types';

import { NewBuildingRoute } from './NewBuildingRoute';

const NewBuildingCardCommonParams = {
    id: s.nullOpt(s.num),
    commissioningDate: s.maybeArray(s.str),

    finishingFlat: s.maybeArray(s.str),

    floorMin: s.nullOpt(s.num),
    floorMax: s.nullOpt(s.num),
    floorFirst: s.nullOpt(s.str),
    floorLast: s.nullOpt(s.str),

    bathroom: s.maybeArray(s.str),

    rooms: s.maybeArray(roomMapper.Type),

    priceMin: s.nullOpt(s.num),
    priceMax: s.nullOpt(s.num),
    priceType: s.nullOpt(s.str),

    totalAreaRangeMin: s.nullOpt(s.num),
    totalAreaRangeMax: s.nullOpt(s.num),
    livingAreaRangeMin: s.nullOpt(s.num),
    livingAreaRangeMax: s.nullOpt(s.num),
    kitchenAreaRangeMin: s.nullOpt(s.num),

    buildingId: s.maybeArray(s.num),

    pageNumber: s.nullOpt(s.num),
    pageSize: s.nullOpt(s.num),
    sort: s.nullOpt(s.str),

    from: s.nullOpt(s.str)
};

const NewBuildingCardCommonType = s.rec(NewBuildingCardCommonParams);

type INewBuildingCardCommonType = ReturnType<typeof NewBuildingCardCommonType>

const NewBuildingCardCommonSeoParams = {
    origin: s.nullOpt(s.str),
    id: s.nullOpt(s.str),
    commissioningDate: s.maybeArray(s.str),

    finishingFlat: s.maybeArray(s.str),

    floorMin: s.nullOpt(s.str),
    floorMax: s.nullOpt(s.str),
    floorFirst: s.nullOpt(s.str),
    floorLast: s.nullOpt(s.str),

    bathroom: s.maybeArray(s.str),

    rooms: s.maybeArray(roomMapper.Type),

    priceMin: s.nullOpt(s.str),
    priceMax: s.nullOpt(s.str),
    priceType: s.nullOpt(s.str),

    totalAreaRangeMin: s.nullOpt(s.str),
    totalAreaRangeMax: s.nullOpt(s.str),
    livingAreaRangeMin: s.nullOpt(s.str),
    livingAreaRangeMax: s.nullOpt(s.str),
    kitchenAreaRangeMin: s.nullOpt(s.str),

    buildingId: s.maybeArray(s.str),

    pageNumber: s.nullOpt(s.str),
    pageSize: s.nullOpt(s.str),
    sort: s.nullOpt(s.str),

    from: s.nullOpt(s.str)
};

const NewBuildingCardCommonSeoType = s.rec(NewBuildingCardCommonSeoParams);

type INewBuildingCardCommonSeoType = ReturnType<typeof NewBuildingCardCommonSeoType>

const newBuildingCardCommonDefaults = {
    pageSize: 20 as number,
    pageNumber: 1 as number,
    sort: NewBuildingOffersSortEnum.PriceAsc as string
};

const fromCommonQuery = (
    seoParams: INewBuildingCardCommonSeoType,
    defaults: typeof newBuildingCardCommonDefaults
) => ({
    commissioningDate: seoParams.commissioningDate,

    finishingFlat: seoParams.finishingFlat,

    floorMin: strToNum(seoParams.floorMin),
    floorMax: strToNum(seoParams.floorMax),
    floorFirst: seoParams.floorFirst,
    floorLast: seoParams.floorLast,

    bathroom: seoParams.bathroom,

    rooms: seoParams.rooms,

    priceMin: strToNum(seoParams.priceMin),
    priceMax: strToNum(seoParams.priceMax),
    priceType: seoParams.priceType,

    totalAreaRangeMin: strToNum(seoParams.totalAreaRangeMin),
    totalAreaRangeMax: strToNum(seoParams.totalAreaRangeMax),
    livingAreaRangeMin: strToNum(seoParams.livingAreaRangeMin),
    livingAreaRangeMax: strToNum(seoParams.livingAreaRangeMax),
    kitchenAreaRangeMin: strToNum(seoParams.kitchenAreaRangeMin),

    buildingId: seoParams.buildingId ? (
        Array.isArray(seoParams.buildingId) ? seoParams.buildingId : [ seoParams.buildingId ]
    ).map(strToNum) as number[] : undefined,

    pageNumber: strToNum(seoParams.pageNumber) || defaults.pageNumber,
    pageSize: strToNum(seoParams.pageSize) ?? defaults.pageSize,
    sort: seoParams.sort || defaults.sort,

    from: seoParams.from
});

const toCommonQuery = (
    params: INewBuildingCardCommonType,
    defaults: typeof newBuildingCardCommonDefaults
) => ({
    commissioningDate: params.commissioningDate,

    finishingFlat: params.finishingFlat,

    floorMin: numToStr(params.floorMin),
    floorMax: numToStr(params.floorMax),
    floorFirst: params.floorFirst,
    floorLast: params.floorLast,

    bathroom: params.bathroom,

    rooms: params.rooms,

    priceMin: numToStr(params.priceMin),
    priceMax: numToStr(params.priceMax),
    priceType: params.priceType,

    totalAreaRangeMin: numToStr(params.totalAreaRangeMin),
    totalAreaRangeMax: numToStr(params.totalAreaRangeMax),
    livingAreaRangeMin: numToStr(params.livingAreaRangeMin),
    livingAreaRangeMax: numToStr(params.livingAreaRangeMax),
    kitchenAreaRangeMin: numToStr(params.kitchenAreaRangeMin),

    buildingId: params.buildingId ? (
        Array.isArray(params.buildingId) ? params.buildingId : [ params.buildingId ]
    ).map(numToStr) as string[] : undefined,

    pageNumber: params.pageNumber && params.pageNumber !== defaults.pageNumber ?
        numToStr(params.pageNumber) :
        undefined,
    pageSize: params.pageSize && params.pageSize !== defaults.pageSize ?
        numToStr(params.pageSize) :
        undefined,
    sort: params.sort && params.sort !== defaults.sort ?
        params.sort :
        undefined,

    from: params.from
});

export const NewBuildingCardType = s.rec({
    region: s.str,
    subRegion: s.nullOpt(s.str),
    type: s.opt(newBuildingTypeMapper.Type),
    name: s.str,

    ...NewBuildingCardCommonParams
});

export type INewBuildingCardType = ReturnType<typeof NewBuildingCardType>

export const NewBuildingCardSeoType = s.rec({
    region: s.nullOpt(s.str),
    subRegion: s.nullOpt(s.str),
    type: s.opt(newBuildingTypeMapper.SeoType),
    name: s.str,

    ...NewBuildingCardCommonSeoParams
});

export type INewBuildingCardSeoType = ReturnType<typeof NewBuildingCardSeoType>

export const newBuildingCardDefaults = {
    region: 'rossiya',

    ...newBuildingCardCommonDefaults
} as const;

export class NewBuildingCardRoute extends NewBuildingRoute<
    INewBuildingCardSeoType,
    INewBuildingCardType,
    typeof newBuildingCardDefaults
> {
    displayName = 'NewBuildingCardRoute';

    protected metadata = NewBuildingCardSeoType.config;

    protected pattern(params: Tokens<INewBuildingCardSeoType>) {
        return `${this.patternHost()}(/${
            params.region
        })/novostroyki(/${
            params.subRegion
        })/${
            params.type
        }-${
            params.name
        }-${
            params.id
        }/`;
    }

    protected conditions() {
        return {
            ...super.conditions(),
            id: '\\d+',
            type: newBuildingTypeMapper.keys
        };
    }

    defaults() {
        return newBuildingCardDefaults;
    }

    regionSlugSimple(regionId: number) {
        return this.context.rootRegions?.getById(regionId)?.translit ??
            this.defaultRegion().translit;
    }

    protected fromQuery(seoParams: INewBuildingCardSeoType) {
        seoParams = NewBuildingCardSeoType(seoParams);

        return NewBuildingCardType({
            region: this.regionSlugSimple(this.regionId(seoParams.region)),
            subRegion: seoParams.subRegion,
            type: seoParams.type,
            name: seoParams.name,
            id: strToNum(seoParams.id),

            ...fromCommonQuery(seoParams, this.defaults())
        });
    }

    protected toQuery(params: INewBuildingCardType) {
        params = NewBuildingCardType(params);

        return NewBuildingCardSeoType({
            ...this.regionParams(this.regionId(params.region)),
            subRegion: params.subRegion,
            type: params.type as INewBuildingCardSeoType['type'],
            name: params.name,
            id: numToStr(params.id),

            ...toCommonQuery(params, this.defaults())
        });
    }
}

export const WlNewBuildingCardType = s.rec({
    ...NewBuildingCardCommonParams
});

export type IWlNewBuildingCardType = ReturnType<typeof WlNewBuildingCardType>

export const WLNewBuildingCardSeoType = s.rec({
    ...NewBuildingCardCommonSeoParams
});

export type IWLNewBuildingCardSeoType = ReturnType<typeof WLNewBuildingCardSeoType>

export const wlNewBuildingCardDefaults = {
    ...newBuildingCardCommonDefaults
} as const;

export class WLNewBuildingCardRoute extends SusaninRoute<
    IWLNewBuildingCardSeoType,
    IWlNewBuildingCardType,
    IRouterContext,
    typeof wlNewBuildingCardDefaults
> {
    displayName = 'WLNewBuildingCardRoute';

    protected metadata = WLNewBuildingCardSeoType.config;

    protected pattern(params: Tokens<IWLNewBuildingCardSeoType>) {
        return `${this.patternHost()}/novostroyki/${params.id}/`;
    }

    protected conditions() {
        return {
            ...super.conditions(),
            id: '\\d+'
        };
    }

    defaults() {
        return wlNewBuildingCardDefaults;
    }

    protected fromQuery(seoParams: IWLNewBuildingCardSeoType) {
        seoParams = WLNewBuildingCardSeoType(seoParams);

        return WlNewBuildingCardType({
            id: strToNum(seoParams.id),

            ...fromCommonQuery(seoParams, this.defaults())
        });
    }

    protected toQuery(params: IWlNewBuildingCardType) {
        params = WlNewBuildingCardType(params);

        return WLNewBuildingCardSeoType({
            id: numToStr(params.id),

            ...toCommonQuery(params, this.defaults()),

            origin: undefined
        });
    }
}
