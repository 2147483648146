import { checkMainDirection, checkSecondaryDirection } from './utils';
import { AnchorDimensions, PopupDimensions, TailDimensions } from './types';

/*
 * Вычисляет координаты попапа для заданного направления.
 *
 * @param direction Текущее направление в котором раскрыт попап
 * @param anchorDimensions Размеры анкора
 * @param popupDimensions Размеры попапа
 * @param offsets Отступы
 */
export const getPopupPosition = (
    direction: string,
    anchorDimensions: AnchorDimensions,
    popupDimensions: PopupDimensions,
    offsets = {
        secondary: 0,
        main: 0
    }
): {
    left: number;
    top: number;
} => {
    const position = { left: 0, top: 0 };

    if (checkMainDirection(direction, 'bottom')) {
        position.top = anchorDimensions.top + anchorDimensions.height + offsets.main;
    } else if (checkMainDirection(direction, 'top')) {
        position.top = anchorDimensions.top - popupDimensions.height - offsets.main;
    } else if (checkMainDirection(direction, 'left')) {
        position.left = anchorDimensions.left - popupDimensions.width - offsets.main;
    } else if (checkMainDirection(direction, 'right')) {
        position.left = anchorDimensions.left + anchorDimensions.width + offsets.main;
    }

    if (checkSecondaryDirection(direction, 'right')) {
        position.left = anchorDimensions.left + anchorDimensions.width - popupDimensions.width - offsets.secondary;
    } else if (checkSecondaryDirection(direction, 'left')) {
        position.left = anchorDimensions.left + offsets.secondary;
    } else if (checkSecondaryDirection(direction, 'bottom')) {
        position.top = anchorDimensions.top + anchorDimensions.height - popupDimensions.height - offsets.secondary;
    } else if (checkSecondaryDirection(direction, 'top')) {
        position.top = anchorDimensions.top + offsets.secondary;
    } else if (checkSecondaryDirection(direction, 'center')) {
        if (checkMainDirection(direction, 'top', 'bottom')) {
            position.left = anchorDimensions.left + (anchorDimensions.width / 2) - (popupDimensions.width / 2);
        } else if (checkMainDirection(direction, 'left', 'right')) {
            position.top = anchorDimensions.top + (anchorDimensions.height / 2) - (popupDimensions.height / 2);
        }
    }

    return position;
};

/*
 * Вычисляет координаты хвостика для заданного направления.
 *
 * @param direction Текущее направление в котором раскрыт попап
 * @param anchorDimensions Размеры анкора
 * @param popupDimensions Размеры попапа
 * @param tailDimensions Размеры хвостика
 * @param offsets Отступы
 */
export const getTailPosition = (
    direction: string,
    anchorDimensions: AnchorDimensions,
    popupDimensions: PopupDimensions,
    tailDimensions: TailDimensions,
    offsets = {
        tail: 0
    }
): {
    left: number;
    top: number;
} => {
    const position = { left: 0, top: 0 };
    const halfSizeOfTail = tailDimensions.size / 2;

    if (checkMainDirection(direction, 'bottom')) {
        position.top = -halfSizeOfTail;
    } else if (checkMainDirection(direction, 'top')) {
        position.top = Math.floor(popupDimensions.popupHeight - halfSizeOfTail);
    } else if (checkMainDirection(direction, 'left')) {
        position.left = Math.floor(popupDimensions.popupWidth - halfSizeOfTail);
    } else if (checkMainDirection(direction, 'right')) {
        position.left = -halfSizeOfTail;
    }

    if (checkSecondaryDirection(direction, 'right')) {
        position.left = (
            popupDimensions.popupWidth -
            Math.ceil(Math.min(popupDimensions.popupWidth, anchorDimensions.width) / 2) -
            halfSizeOfTail - offsets.tail
        );
    } else if (checkSecondaryDirection(direction, 'left')) {
        position.left = Math.ceil(Math.min(popupDimensions.popupWidth, anchorDimensions.width) / 2) -
            halfSizeOfTail + offsets.tail;
    } else if (checkSecondaryDirection(direction, 'bottom')) {
        position.top = (
            popupDimensions.popupHeight -
            Math.ceil(Math.min(popupDimensions.popupHeight, anchorDimensions.height) / 2) -
            halfSizeOfTail - offsets.tail);
    } else if (checkSecondaryDirection(direction, 'top')) {
        position.top = Math.ceil(Math.min(popupDimensions.popupHeight, anchorDimensions.height) / 2) -
        halfSizeOfTail + offsets.tail;
    } else if (checkSecondaryDirection(direction, 'center')) {
        if (checkMainDirection(direction, 'top', 'bottom')) {
            position.left = Math.ceil(popupDimensions.popupWidth / 2) - halfSizeOfTail + offsets.tail;
        } else {
            position.top = Math.ceil(popupDimensions.popupHeight / 2) - halfSizeOfTail + offsets.tail;
        }
    }

    return position;
};
