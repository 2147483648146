import './MortgageGovernmentDisclaimer.css';

import React from 'react';
import classname from '@search/classname/src';
import { Modal, Button, Typography } from '@vtblife/uikit';

const cn = classname.bind(null, 'MortgageGovernmentDisclaimer');

export function MortgageGovernmentDisclaimerModal({
    isMobile,
    onSuccess,
    onClose,
    additionalPrograms,
    withoutAccent,
    btnText = 'Подать заявку на ипотеку'
}: {
    isMobile?: boolean;
    onSuccess?(e: React.MouseEvent<Element, MouseEvent>): void;
    onClose?(): void;
    additionalPrograms?: {title: string; desc: string; inner?: string}[];
    withoutAccent?: boolean;
    btnText?: string;
}) {
    const title = 'Одобрят ли вам ипотеку с\u00A0господдержкой?';

    return (<Modal
        onClose={onClose}
        closable
        mobile={isMobile}
        swipeToClose={! isMobile}
        opened
        size='m'
    >
        {isMobile ? <Modal.Header>{title}</Modal.Header> : <Modal.Title>{title}</Modal.Title>}

        <Modal.Content scrollable={isMobile}>
            <div className={cn('block-main')}>
                <div className={cn('block')}>
                    <Typography variant='h3'>Семейная ипотека до&nbsp;6%</Typography>
                    <div>
                        Подходит семьям с детьми, которые родились, были усыновлены или удочерены не ранее 2018&nbsp;года,
                        и семьям в которых есть ребёнок с&nbsp;инвалидностью. <span className={cn('inner', { withoutAccent })}>
                            Первоначальный взнос от&nbsp;15%, максимальный срок кредита – 30&nbsp;лет,
                            а размер кредита до 12&nbsp;млн&nbsp;₽ (в&nbsp;регионах до 6&nbsp;млн&nbsp;₽).
                        </span>
                    </div>
                </div>

                <div className={cn('block')}>
                    <Typography variant='h3'>Льготная ипотека до&nbsp;12%</Typography>
                    <div>
                        Подходит для всех категорий граждан РФ, приобретающих жилье в&nbsp;новостройках от&nbsp;застройщика. <span className={cn('inner', { withoutAccent })}>
                            Первоначальный взнос от&nbsp;15%, максимальный срок кредита&nbsp;&mdash; 30&nbsp;лет, размер кредита до&nbsp;12&nbsp;млн&nbsp;₽ (в&nbsp;регионах до&nbsp;6&nbsp;млн&nbsp;₽).
                        </span>
                    </div>
                </div>

                <div className={cn('block')}>
                    <Typography variant='h3'>ИТ&nbsp;ипотека от&nbsp;5%</Typography>
                    <div>
                        Подходит официально оформленных сотрудников IT-компаний, аккредитованных Минцифры РФ&nbsp;с&nbsp;зарплатой от&nbsp;150&nbsp;тыс.&nbsp;₽&nbsp;до&nbsp;вычета налогов. <span className={cn('inner', { withoutAccent })}>
                            Первоначальный взнос от&nbsp;15%, максимальный срок кредита&nbsp;&mdash; 30&nbsp;лет, размер кредита до&nbsp;18&nbsp;млн&nbsp;₽ (в&nbsp;регионах до&nbsp;6&nbsp;млн&nbsp;₽).
                        </span>
                    </div>
                </div>

                {additionalPrograms?.map(({ inner, desc, title }) => (
                    <div className={cn('block')} key={title}>
                        <Typography variant='h3'>{title}</Typography>
                        <div>
                            {desc} {inner ? <span className={cn('inner', { withoutAccent })}>{inner}</span> : null}
                        </div>
                    </div>
                ))}
                <Modal.Actions>
                    <Button
                        variant='accent'
                        onClick={e => {
                            e.preventDefault();
                            onSuccess?.(e);
                        }}
                        dataTest='mortgage-send'
                    >{btnText}</Button>
                </Modal.Actions>
            </div>
        </Modal.Content>
    </Modal>);
}
