import React, { ReactNode } from 'react';
import { TipTypeEnum } from '@search/graphql-typings';

import classname from '@search/classname/src';

import { GeoKind } from '@search/filter-enums/enums/GeoKind';

import { MetroStation } from '../../../../../../../domain/geo/MetroStation';
import { District } from '../../../../../../../domain/geo/District';
import { Address } from '../../../../../../../domain/geo/Address';

import { MetroMark } from '../../../../../../common/components/GeoSuggest/MetroMark';

import { FilteredItem, isQueryDisplayValue, isGeoDistrictsGroupItem } from '../MobileGeoFilterView';

import './GeoTagItem.css';

const cn = classname.bind(null, 'GeoTagItem');

interface IGeoTagItemProps {
    item: FilteredItem;
    handleRemove: (item: FilteredItem) => void;
}

export const GeoTagItem: React.FC<IGeoTagItemProps> = ({
    item,
    handleRemove
}) => {
    let component: ReactNode | null = null;

    if (isGeoDistrictsGroupItem(item)) {
        component = item.title;
    } else if (isQueryDisplayValue(item)) {
        if (item.type === TipTypeEnum.NewBuilding) {
            component = item.title || 'Жилой комплекс';
        }

        if (item.type === TipTypeEnum.Address) {
            component = item.title;
        }
    } else {
        if (item.kind === GeoKind.METRO) {
            const metroItem = item as MetroStation;

            component = (
                <>
                    <span className={cn('marks')}>
                        <MetroMark
                            colors={metroItem.allRoutesColorsList}
                        />
                    </span>
                    <span className={cn('title')}>
                        {metroItem.title}
                    </span>
                </>
            );
        } else if (item.kind === GeoKind.DISTRICT || item.kind === GeoKind.LOCALITY) {
            const districtItem = item as District;

            component = districtItem.title;
        } else {
            const addressItem = item as Address;

            component = addressItem.displayName || addressItem.mainName;
        }
    }

    return (
        <div
            className={`${cn('flex')} ${cn('item')} container`}
            key={item.id}
        >
            <div className={cn('flex', undefined, 'col-xs-11')}>
                {component}
            </div>
            <div
                className={cn('icon', undefined, 'col-xs-1')}
                onClick={() => handleRemove(item)}
            >
                <svg width='12' height='14' viewBox='0 0 12 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    {/* eslint-disable-next-line max-len */}
                    <path fillRule='evenodd' clipRule='evenodd' d='M4.52778 1C4.31317 1 4.1225 1.13695 4.05397 1.34032L3.93422 1.69565H8.06578L7.94603 1.34032C7.8775 1.13695 7.68683 1 7.47222 1H4.52778ZM9.12103 1.69565L8.89367 1.02097C8.68806 0.41086 8.11604 0 7.47222 0H4.52778C3.88396 0 3.31194 0.410859 3.10633 1.02097L2.87897 1.69565H0V2.69565H0.679334L1.43134 12.6134C1.49065 13.3956 2.14261 14 2.92705 14H8.81029C9.58029 14 10.2252 13.417 10.3027 12.6509L11.3091 2.69565H12V1.69565H9.12103ZM10.304 2.69565H1.6822L2.42848 12.5378C2.44825 12.7985 2.66557 13 2.92705 13H8.81029C9.06695 13 9.28193 12.8057 9.30775 12.5503L10.304 2.69565ZM3.5 11.5V4H4.5V11.5H3.5ZM5.5 11.5V4H6.5V11.5H5.5ZM7.5 11.5V4H8.5V11.5H7.5Z' fill='#B6B9C9' />
                </svg>
            </div>
        </div>
    );
};
