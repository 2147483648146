import './MetroModal.css';

import React from 'react';
import { Button } from '@vtblife/uikit';

import classname from '@search/classname/src';
import { Modal, ModalProps, ModalSize } from '@search/vtbeco-ui/src/components/Modal';
import { BorderRadius } from '@search/vtbeco-ui/src/components/Paper';
import { declensionByNumber } from '@search/helpers/src/declensionByNumber';
import { ModalHeading } from '../../../../../mobile/components/ModalHeading';
import { MetroStore } from '../../../../../../domain/geo/MetroStation';

import { MetroSelect } from './MetroSelect/MetroSelect';

const cn = classname.bind(null, 'MetroModal');

interface IMetroModalProps extends Pick<ModalProps, 'uikitZIndexEnabled'> {
    hide: () => void;
    checkedIds: number[];
    store: MetroStore;
    handleChange(checkedDiff: number[], uncheckedDiff: Set<number>): void;
    onReset: () => void;
    onSubmit: () => void;
}

export const MetroModal: React.FC<IMetroModalProps> = ({
    hide: metroOff,
    checkedIds: checkedMetroIds,
    store: stations,
    handleChange,
    onReset,
    onSubmit: onMetroSubmit,
    uikitZIndexEnabled
}) => {
    return (
        <Modal
            size={ModalSize.FULL_L}
            borderRadius={BorderRadius.none}
            uikitZIndexEnabled={uikitZIndexEnabled}
            fullScreen
        >
            <ModalHeading
                text='Метро'
                reset={onReset}
                activeCount={checkedMetroIds.length}
                onClose={metroOff}
                withDeclension={false}
            />
            <div className={cn('select')}>
                <MetroSelect
                    metros={stations}
                    checked={checkedMetroIds}
                    onChange={handleChange}
                />
            </div>
            <div className={cn('submit')}>
                <Button
                    fullWidth
                    size='s'
                    onClick={onMetroSubmit}
                >
                    {checkedMetroIds.length ? `Выбрать ${checkedMetroIds.length} ${
                        declensionByNumber(checkedMetroIds.length, [ 'станцию', 'станции', 'станций' ])
                    }` : 'Сохранить'}
                </Button>
            </div>
        </Modal>

    );
};
