import React, { useCallback } from 'react';

import { NumberInput } from '@vtblife/uikit';

import { IFilterAvailableProps } from '../../new_filter/Filter2';
import { KitchenAreaFilter } from '../../../models/Filter';
import { MobileFilter2 } from '../../filter/MobileFilter2';

interface IKitchenAreaFilterProps extends IFilterAvailableProps {
    filter: KitchenAreaFilter;
    handleChange: (value: KitchenAreaFilter) => void;
    withPlaceholder?: boolean;
    isMobile?: boolean;
}

export const KitchenAreaFromFilterView: React.FC<IKitchenAreaFilterProps> = ({
    filter,
    handleChange,
    withoutTitle,
    withPlaceholder,
    isMobile
}) => {
    const onChange = useCallback((value: number | null | string) => {
        const minValue = value === null || value === undefined || isNaN(value) || value === '' ?
            null :
            Number(value.toString().replace(/\s/, ''));

        handleChange(new KitchenAreaFilter({ from: minValue, to: null }));
    }, [ handleChange ]);

    return (
        <MobileFilter2 desktopTitleGap={! isMobile} filterTitle={withoutTitle ? undefined : 'Площадь кухни'}>
            <NumberInput
                value={filter.value.from ?? undefined}
                postfix='м²'
                placeholder={withPlaceholder ? 'Кухня от' : 'от'}
                onChange={onChange}
                max={100_000}
                name='kitchenAreaFrom'
                size='s'
            />
        </MobileFilter2>
    );
};

