import './ImageLazySeo.css';

import React from 'react';
import classname from '@search/classname/src';
import { useImageLazy, ImageLazyProps } from './ImageLazy';
import { useImageLazySrcSet } from './ImageLazySrcSet';

const cn = classname.bind(null, 'ImageLazySeo');

export const ImageLazySeo = React.forwardRef<Omit<HTMLImageElement, 'loading'>, ImageLazyProps & {
    imgClassName?: string;
    noLocalItemScope?: boolean;
    metaName?: string;
    itemProp?: string;
    itemScope?: boolean;
    originPath?: string;
    srcWidth?: number;
    srcHeight?: number;
    dataSet?: Record<string, string | number | boolean>;
}>((
    {
        dataSet,
        noLocalItemScope,
        children,
        itemProp,
        itemScope,
        width,
        height,
        srcWidth = typeof width === 'number' ? width : undefined,
        srcHeight = typeof height === 'number' ? height : undefined,
        originPath,
        metaName,
        loadingLazy,
        src,
        srcSet,
        mockSrc,
        className,
        imgClassName,
        ...imgProps
    },
    ref
) => {
    const name = metaName ?? imgProps.title;

    // eslint-disable-next-line guard-for-in
    for (const key in dataSet) {
        const newKey = 'data-' + key.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();

        (imgProps as unknown as typeof dataSet)[newKey] = dataSet[key];
    }

    const getSrcSet = useImageLazySrcSet();

    const calc = srcWidth && srcHeight && originPath ?
        getSrcSet({ width: srcWidth, height: srcHeight, originPath }) :
        undefined;

    const { imgRef, real, loading } = useImageLazy({
        loadingLazy,
        ref,
        src: src ?? calc?.src,
        srcSet: calc?.srcSet,
        mockSrc
    });

    return (
        <picture
            itemProp={itemProp}
            itemScope={itemScope}
            itemType={itemScope || itemProp ? 'http://schema.org/ImageObject' : undefined}
            className={cn(null, undefined, className)}
        >
            {calc?.srcSet && <source srcSet={real.srcSet} />}
            <img
                {...imgProps}
                className={cn('img', undefined, imgClassName)}
                itemProp='contentUrl'
                crossOrigin='anonymous'
                src={real.src}
                loading={loading}
                ref={imgRef}
            />
            {name && itemProp && (<meta itemProp='name' content={name} />)}
            {children}
        </picture>
    );
});
