import {
    Filters,
    IFilter,
    AreaFilter,
    LivingAreaFilter,
    KitchenAreaFilter,
    FloorFilter,
    CellHeightFilter,
    BathroomFilter,
    BalconyFilter,
    GeoFilter,
    TransportLimitFilter,
    SafetyFilter,
    ParkingFilter,
    WallsTypeFilter,
    ApartmentFilter,
    PositionFilter,
    DeveloperFilter,
    FloorsTotalFilter,
    BuildingClassFilter,
    AccreditationFilter,
    NewBuildingDealFilter,
    PaymentTypeFilter,
    ClosedSalesFilter,
    ShowHighlightedFilter,
    BuildingsFilter,
    SelectedNewBuildingFilter,
    CommissioningListFilter,
    RoomFilter,
    PriceFilter,
    FinishingFlatFilter,
    FlatsSpecialEventsFilter,
    OtherSpecialEventsFilter,
    MiscSpecialEventsFilter,
    NotOnlyM2ProFilter
} from '../../../view/filters/models/Filter';

import { NewbuildingFilterCollection } from './NewbuildingFilterCollection';
import { newbuildingFilterDeserializer } from './NewbuildingFilterDeserializer';
import { newbuildingFilterFactory } from './NewbuildingFilterFactory';

export function getInitialFilters(params?: Object) {
    let initialFilters;

    if (params) {
        initialFilters = newbuildingFilterDeserializer.deserialize(params);
    }

    return newbuildingFilterFactory.create(initialFilters);
}

export function getInitialAdditionalFilters(prev: NewbuildingFilterCollection, resetGeo = true) {
    return newbuildingFilterFactory.create(new Map<Filters, IFilter>([
        ...prev.getFilters(),
        [ Filters.totalArea, new AreaFilter() ],
        [ Filters.livingArea, new LivingAreaFilter() ],
        [ Filters.kitchenArea, new KitchenAreaFilter() ],
        [ Filters.floor, new FloorFilter() ],
        [ Filters.cellHeight, new CellHeightFilter() ],
        [ Filters.bathroom, new BathroomFilter() ],
        [ Filters.balcony, new BalconyFilter() ],
        [ Filters.developer, new DeveloperFilter() ],
        [ Filters.floorsTotal, new FloorsTotalFilter() ],
        [ Filters.buildingClass, new BuildingClassFilter() ],
        [ Filters.parkings, new ParkingFilter() ],
        [ Filters.walls, new WallsTypeFilter() ],
        [ Filters.apartments, new ApartmentFilter() ],
        [ Filters.position, new PositionFilter() ],
        [ Filters.safety, new SafetyFilter() ],
        [ Filters.accreditation, new AccreditationFilter() ],
        [ Filters.newBuildingDeal, new NewBuildingDealFilter() ],
        [ Filters.paymentType, new PaymentTypeFilter() ],
        [ Filters.transportLimit, new TransportLimitFilter() ],
        [ Filters.closedSales, new ClosedSalesFilter() ],
        [ Filters.selectedNewBuilding, new SelectedNewBuildingFilter() ],
        [ Filters.showHighlighted, new ShowHighlightedFilter() ],
        [ Filters.flatsSpecialEvents, new FlatsSpecialEventsFilter() ],
        [ Filters.otherSpecialEvents, new OtherSpecialEventsFilter() ],
        [ Filters.miscSpecialEvents, new MiscSpecialEventsFilter() ],
        [ Filters.notOnlyM2Pro, new NotOnlyM2ProFilter() ],
        [ Filters.geo, resetGeo ? new GeoFilter() : prev.get(Filters.geo) ]
    ]));
}

export function getInitialOfferBuildingsFilters(prev: NewbuildingFilterCollection) {
    return newbuildingFilterFactory.create(new Map<Filters, IFilter>([
        ...prev.getFilters(),
        [ Filters.buildings, new BuildingsFilter() ]
    ]));
}

export function getInitialOfferFilters(prev: NewbuildingFilterCollection) {
    return newbuildingFilterFactory.create(new Map<Filters, IFilter>([
        ...prev.getFilters(),
        [ Filters.commissioningList, new CommissioningListFilter() ],
        [ Filters.rooms, new RoomFilter() ],
        [ Filters.price, new PriceFilter() ],
        [ Filters.totalArea, new AreaFilter() ],
        [ Filters.livingArea, new LivingAreaFilter() ],
        [ Filters.kitchenArea, new KitchenAreaFilter() ],
        [ Filters.floor, new FloorFilter() ],
        [ Filters.finishingFlat, new FinishingFlatFilter() ]
    ]));
}
