import { YMapBounds } from '@search/ymap/src/bounds';
import { RegionIdEnum } from '@search/filter-enums/enums/Region';

export const offerYMapPresetBounds: Record<number, YMapBounds> = {
    [RegionIdEnum.SPB]: {
        min: { x: 29.812084, y: 59.808254 },
        max: { x: 30.828319, y: 60.059460 }
    },
    [RegionIdEnum.SPB_AND_LEN_OBL]: {
        min: { x: 29.812084, y: 59.808254 },
        max: { x: 30.828319, y: 60.059460 }
    },
    [RegionIdEnum.LEN_OBL]: {
        min: { x: 29.812084, y: 59.808254 },
        max: { x: 30.828319, y: 60.059460 }
    },
    [RegionIdEnum.MSK]: {
        min: { x: 37.488248, y: 55.585993 },
        max: { x: 37.74574, y: 55.875427 }
    },
    [RegionIdEnum.MSK_AND_MSK_OBL]: {
        min: { x: 35.710134, y: 55.124008 },
        max: { x: 39.341116, y: 56.287049 }
    },
    [RegionIdEnum.MSK_OBL]: {
        min: { x: 35.710134, y: 55.124008 },
        max: { x: 39.341116, y: 56.287049 }
    }
};
