import './Mortgage2IconWrap.css';

import React from 'react';

import classname from '@search/classname/src';
import { Size } from '@vtblife/uikit/legacy';

const cn = classname.bind(null, 'Mortgage2IconWrap');

export function Mortgage2IconWrap({ children, size, alt, accent, isLandscapeOrientation }: {
    children?: React.ReactNode;
    size?: Size;
    alt?: boolean;
    accent?: boolean;
    isLandscapeOrientation?: boolean;
}) {
    return (<div className={cn(undefined, { alt, size, accent, isLandscapeOrientation })}>
        {children}
    </div>);
}
