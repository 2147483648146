import {
    TipAddress, TipTypeEnum
} from '@search/graphql-typings';

import { GeoId } from './GeoBase';
import { Address } from './Address';

export class AddressStore {
    protected items = new Map<GeoId, Address>();

    get(id: GeoId): Address | undefined {
        return this.items.get(id);
    }

    getGql(id: GeoId): TipAddress | undefined {
        const geo = this.get(id);

        if (! geo) return undefined;

        return {
            __typename: 'TipAddress',
            id: geo.id,
            title: geo.displayName ?? geo.mainName,
            translit: geo.translit,
            type: TipTypeEnum.Address
        };
    }

    add(item: Address): void {
        this.items.set(item.id, item);
    }

    remove(id: GeoId): void {
        this.items.delete(id);
    }
}
