import './Mortgage2Field.css';

import React from 'react';

import { formatLocaleNumber } from '@search/helpers/src/formatNumber';
import classname from '@search/classname/src';
import { Select, Input, PhoneInput } from '@vtblife/uikit';
import { Size } from '@vtblife/uikit/legacy';
import { useMortgage2UserForm } from '../useMortgage2User';
import { useMortgage2Request } from '../useMortgage2Request';

const cn = classname.bind(null, 'Mortgage2Field');

export function Mortgage2FieldControls({ children }: {
    children?: React.ReactNode;
}) {
    return (<div className={cn('controls')}>
        {children}
    </div>);
}

export function Mortgage2FieldDescription({ children, accent }: {
    children?: React.ReactNode;
    accent?: boolean;
}) {
    return (<div className={cn('description', { accent })}>
        {children}
    </div>);
}

export function Mortgage2FieldTitle({
    children,
    size,
    accent
}: {
    children?: React.ReactNode;
    accent?: boolean;
    size: Size;
}) {
    return <div className={cn('title', { size, accent })}>{children}</div>;
}

export function Mortgage2Field({
    title,
    children,
    space,
    titleSize,
    titleAccent,
    descriptionAccent,
    className
}: {
    space: Size;
    descriptionAccent?: boolean;
    titleSize: Size;
    titleAccent?: boolean;
    title?: React.ReactNode;
    children: React.ReactNode;
    className?: string;
}) {
    return (<div className={cn('field', { space }, className)}>
        {title && <Mortgage2FieldTitle size={titleSize} accent={titleAccent} children={title} />}
        {children && <Mortgage2FieldDescription accent={descriptionAccent} children={children} />}
    </div>);
}

export function Mortgage2FieldPercent({
    minimalPercentOld,
    minimalPercent,
    isAccent,
    isFrom
}: {
    isFrom?: boolean;
    isAccent?: boolean;
    description?: string;
    minimalPercent?: number;
    minimalPercentOld?: number;
}) {
    return (<span className={cn('economy', { accent: isAccent })}>
        {isFrom ? 'от ' : ''}<span>
            {minimalPercent ? `${formatLocaleNumber(minimalPercent)} %` : ' '}
        </span>
        {minimalPercentOld && minimalPercentOld !== minimalPercent ? (
            <span className={cn('economy', { prev: true })}>
                {formatLocaleNumber(minimalPercentOld)} %
            </span>
        ) : null}
    </span>);
}

export function MortgageContactFields({
    isMobile,
    domain,
    showCallbackHours,
    sendQueryOnEnter
} : {
    isMobile?: boolean;
    showCallbackHours?: boolean;
    domain:ReturnType<typeof useMortgage2Request> | ReturnType<typeof useMortgage2UserForm>;
    sendQueryOnEnter?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}) {
    return (
        <div className={cn('fields')}>
            <Input
                dataTest='mortgage-set-name'
                label='Как вас зовут'
                placeholder='Имя'
                size={Size.Medium}
                onBlur={domain.setNameTouched}
                onKeyPress={sendQueryOnEnter}
                error={domain.nameInputValid === false}
                onChange={domain.setName}
                value={domain.name}
                disabled={domain.isFormSyncing}
            />
            <PhoneInput
                dataTest='mortgage-set-phone'
                label='Телефон'
                size={Size.Medium}
                inputMode='numeric'
                onBlur={domain.setPhoneTouched}
                onKeyPress={sendQueryOnEnter}
                error={domain.phoneInputValid === false}
                onChange={domain.setPhone}
                value={domain.phone || undefined}
                disabled={domain.isFormSyncing}
            />
            {showCallbackHours ? (
                <>
                    <Select
                        onChange={domain.setStartHoursRange}
                        native={isMobile}
                    >
                        <Select.Button
                            size='m'
                            label='Не ранее'
                        >
                            {domain.workingHoursRange.start}
                        </Select.Button>
                        <Select.Content height={172}>
                            {
                                domain.startHourValues.map(({ value, label, disabled }) => (
                                    <Select.Option
                                        key={value}
                                        disabled={disabled}
                                        value={value}
                                        selected={domain.workingHoursRange.start === label}
                                    >
                                        {label}
                                    </Select.Option>
                                ))
                            }
                        </Select.Content>
                    </Select>
                    <Select
                        onChange={domain.setEndHoursRange}
                        native={isMobile}
                    >
                        <Select.Button
                            label='Не позднее'
                            size='m'
                        >
                            {domain.workingHoursRange.end}
                        </Select.Button>
                        <Select.Content height={172}>
                            {
                                domain.endHourValues.map(({ value, label, disabled }) => (
                                    <Select.Option
                                        key={value}
                                        disabled={disabled}
                                        value={value}
                                        selected={domain.workingHoursRange.end === label}
                                    >
                                        {label}
                                    </Select.Option>
                                ))
                            }
                        </Select.Content>
                    </Select>
                </>
            ) : null}
        </div>
    );
}
