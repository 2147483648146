import './ImageSlider.css';

import React from 'react';
import classname from '@search/classname/src';

import { useImageSliderActiveItem } from './useImageSliderActiveItem';
import { ImageSliderBaseProps, useImageSliderCross } from './useImageSliderCross';
import { useImageSliderItems } from './useImageSliderItems';

const cn = classname.bind(null, 'ImageSlider');

export function ImageSlider<Key extends React.Key>(
    {
        crossSwipeAnimate,
        className,
        itemClassName,
        items,
        containerCrossSwipeEnabled = false,
        children,
        onCrossSwipeFwd,
        onCrossSwipeBack,
        isVertical = false,
        restoreItemKey,
        onActiveItem,
        noOverflow = false
    }: {
        className?: string;
        itemClassName?: string;
        items: readonly React.ReactElement<{ key: Key }>[];

        /**
         * True, если кросс-свайп должен анимировать весь контейнер, а не только конкретный айтем
         */
        containerCrossSwipeEnabled?: boolean;
        children?: React.ReactNode;
        restoreItemKey?: Key;
        onActiveItem?: (key: Key, el: HTMLElement) => void;
        noOverflow?: boolean;
    } & ImageSliderBaseProps<Key>
) {
    const { containerRef, itemRefs, setItem } = useImageSliderItems<HTMLDivElement, HTMLDivElement, Key>();

    const { onScroll } = useImageSliderActiveItem({
        containerRef,
        itemRefs,
        isVertical,
        onActiveItem,
        restoreItemKey
    });

    const { onTouchStart, onTouchMove, onTouchEnd } = useImageSliderCross({
        containerRef: containerCrossSwipeEnabled ? containerRef : undefined,
        itemRefs,
        onCrossSwipeFwd,
        onCrossSwipeBack,
        isVertical,
        crossSwipeAnimate
    });

    return (
        <div
            ref={containerRef}
            onScroll={onScroll}
            className={cn(null, {
                vertical: isVertical,
                overflow: ! noOverflow
            }, className)}
        >
            {items.map((item, index) => {
                const key = (item.key ?? index) as unknown as Key;

                return (<div
                    key={`${key}-item`}
                    onTouchStart={onTouchStart}
                    ref={setItem.bind(null, key)}
                    onTouchEnd={onTouchEnd.bind(null, key)}
                    onTouchMove={onTouchMove.bind(null, key)}
                    className={cn('slide', { vertical: isVertical }, itemClassName)}
                >
                    {item}
                </div>);
            })}
            {children}
        </div>
    );
}

