import React from 'react';

import classname from '@search/classname/src';

import type { DeveloperPromoEventResult } from '../../../developer-promo/analytics';
import { useGtmOnVisible } from '../../../../google/useGtmOnVisible';
import { useClassifiedOfferDataLayerPush } from '../../../../offer/analytics/ClassifiedOfferGtmContext';

import './styles.css';

const cn = classname.bind(null, 'ImageBanner');

interface IImageBannerProps {
    targetLink: string;
    image: string;
    view: 'square' | 'horizontal';
    gtm?: string;
    gtmClickData?: { event: string } | DeveloperPromoEventResult;
    gtmAdditionalClickData?: { event: string };
    gtmShowData?: { event: string } | DeveloperPromoEventResult;
    className?: string;
    loading?: 'lazy' | 'eager';
}

const ImageBanner: React.FC<IImageBannerProps> = ({
    targetLink,
    image,
    view,
    gtm,
    gtmClickData,
    gtmShowData,
    gtmAdditionalClickData,
    className,
    loading
}) => {
    const dataLayerPush = useClassifiedOfferDataLayerPush();

    const containerRef = React.useRef<HTMLAnchorElement>(null);

    const handleClick = React.useCallback(() => {
        gtmClickData && dataLayerPush(gtmClickData);
        gtmAdditionalClickData && dataLayerPush(gtmAdditionalClickData);
    }, [ gtmClickData, gtmAdditionalClickData, dataLayerPush ]);
    const onVisible = React.useCallback(() => {
        gtmShowData && dataLayerPush(gtmShowData);
    }, [ gtmShowData, dataLayerPush ]);

    useGtmOnVisible({ containerRef, onVisible });

    return (
        <a
            ref={containerRef}
            href={targetLink}
            target='_blank'
            className={cn(null, { view }, className)}
            data-gtm={gtm}
            onClick={handleClick}
        >
            <img
                src={image}
                className={cn('image')}
                crossOrigin='anonymous'
                alt=''
                loading={loading}
            />
        </a>
    );
};

export default ImageBanner;

