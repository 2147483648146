import { s } from '@search/router/src/schema/schema';

import {
    authorTypeMapper, bankPledgeMapper, bathroomMapper, categoryTypeMapper, dealTypeMapper,
    furnitureMapper, layoutImageMapper, livingFacilityMapper, newBuildingMapper,
    renovationMapper, roomMapper, sellTypeMapper, wallsMapper, buildEpochMapper,
    finishingMapper
} from '../mappers';
import { newBuildingTypeMapper } from '../mappers/newBuilding/mappers';
import { windowsDirectionMapper } from '../mappers/windowsDirectionMapper';
import { sortOffersMapper } from '../mappers/sortOffersMapper';
import { parkingsMapper } from '../mappers/parkingsMapper';
import { livingTenantMapper } from '../mappers/livingTenantMapper';

export const SearchSeoType = s.rec({
    seoSeg1: s.nullOpt(s.str),
    seoSeg2: s.nullOpt(s.str),
    seoSegPrice: s.nullOpt(s.str),
    geoSlug1: s.nullOpt(s.str),
    geoSlug2: s.nullOpt(s.str),
    origin: s.nullOpt(s.str),
    region: s.nullOpt(s.str),

    renovation: s.maybeArray(renovationMapper.SeoType),
    finishingType: s.maybeArray(finishingMapper.SeoType),
    commissioning: s.nullOpt(s.str),
    renovationSegment: s.nullOpt(renovationMapper.SeoType),
    metroIds: s.maybeArray(s.str),
    metroSegment: s.nullOpt(s.str),
    metroBranch: s.nullOpt(s.str),
    newSegment: s.nullOpt(newBuildingMapper.SeoType),
    newBuilding: s.maybeArray(newBuildingMapper.SeoType),
    bankPledgeOptionsSegment: s.nullOpt(bankPledgeMapper.SeoType),
    bankPledgeOptions: s.nullOpt(bankPledgeMapper.SeoType),
    apartments: s.nullOpt(s.str),
    houseCategory: s.maybeArray(s.str),
    houseCategorySegment: s.nullOpt(s.str),
    cotedjSegment: s.nullOpt(s.str),
    rayon: s.nullOpt(s.str),
    okrug: s.nullOpt(s.str),

    room: s.nullOpt(s.str),
    rooms: s.maybeArray(roomMapper.SeoType),
    roomsInOffer: s.maybeArray(s.str),
    addresses: s.maybeArray(s.str),
    geohash: s.nullOpt(s.str),
    districtIds: s.maybeArray(s.str),
    newBuildingIds: s.maybeArray(s.str),

    newBuildingId: s.nullOpt(s.str),
    newBuildingType: s.nullOpt(newBuildingTypeMapper.SeoType),
    newBuildingName: s.nullOpt(s.str),

    dealType: s.nullOpt(dealTypeMapper.SeoType),
    categoryType: s.nullOpt(categoryTypeMapper.SeoType),

    totalAreaMin: s.nullOpt(s.str),
    totalAreaMax: s.nullOpt(s.str),

    landAreaMin: s.nullOpt(s.str),
    landAreaMax: s.nullOpt(s.str),

    houseAreaMin: s.nullOpt(s.str),
    houseAreaMax: s.nullOpt(s.str),
    bathroomLocation: s.maybeArray(s.str),

    priceMin: s.nullOpt(s.str),
    priceMax: s.nullOpt(s.str),
    mortgagePayment: s.nullOpt(s.str),
    priceMaxSegment: s.nullOpt(s.str),
    priceUnit: s.nullOpt(s.str),

    livingAreaMin: s.nullOpt(s.str),
    livingAreaMax: s.nullOpt(s.str),

    kitchenAreaMin: s.nullOpt(s.str),
    kitchenAreaMax: s.nullOpt(s.str),

    seoSegBuildEpoch: s.nullOpt(s.str),
    builtYearMin: s.nullOpt(s.str),
    builtYearMax: s.nullOpt(s.str),

    floorMin: s.nullOpt(s.str),
    floorMax: s.nullOpt(s.str),

    last: s.nullOpt(s.str),
    first: s.nullOpt(s.str),

    floorsTotalMin: s.nullOpt(s.str),
    floorsTotalMax: s.nullOpt(s.str),

    cellHeight: s.nullOpt(s.str),

    bathroom: s.maybeArray(bathroomMapper.SeoType),
    bathroomSegment: s.nullOpt(bathroomMapper.SeoType),
    landStatus: s.maybeArray(s.str),
    landStatusSegment: s.nullOpt(s.str),

    heating: s.maybeArray(s.str),
    gas: s.maybeArray(s.str),
    water: s.maybeArray(s.str),
    electricity: s.maybeArray(s.str),
    sewerage: s.maybeArray(s.str),
    communications: s.nullOpt(s.str),

    // @deprecated use transport & transportTimeLimit
    transportType: s.nullOpt(s.str),
    // @deprecated use transport & transportTimeLimit
    timeLimit: s.nullOpt(s.str),

    transport: s.nullOpt(s.str),
    transportTimeLimit: s.nullOpt(s.str),
    nearMetroSegment: s.nullOpt(s.str),

    livingFacility: s.maybeArray(livingFacilityMapper.SeoType),
    furniture: s.maybeArray(furnitureMapper.SeoType),
    livingTenantOptions: s.maybeArray(livingTenantMapper.SeoType),
    utilitiesIncluded: s.nullOpt(s.str),
    depositType: s.nullOpt(s.str),
    commisionType: s.nullOpt(s.str),
    rentPrepayment: s.nullOpt(s.str),
    rentPrepaymentSegment: s.nullOpt(s.str),
    walls: s.maybeArray(wallsMapper.SeoType),
    parkings: s.maybeArray(parkingsMapper.SeoType),
    parkingsSegment: s.nullOpt(s.str),
    author: s.maybeArray(authorTypeMapper.SeoType),
    userId: s.nullOpt(s.str),
    authorSegment: s.nullOpt(s.str),
    sellType: s.maybeArray(sellTypeMapper.SeoType),
    balcony: s.nullOpt(s.str),
    photoOptions: s.nullOpt(s.str),
    layoutImageOptions: s.maybeArray(layoutImageMapper.SeoType),
    renovationProgram: s.nullOpt(s.str),
    nearbyOptions: s.nullOpt(s.str),
    sellerOrganizationName: s.nullOpt(s.str),

    infrastructure: s.maybeArray(s.str),

    hasLift: s.nullOpt(s.str),
    seoMortgageAllowed: s.nullOpt(s.str),
    seoMortgageAllowedSegment: s.nullOpt(s.str),

    windowsDirection: s.maybeArray(windowsDirectionMapper.SeoType),

    buildEpoch: s.maybeArray(buildEpochMapper.SeoType),

    onlineView: s.nullOpt(s.str),

    selectedOffers: s.maybeArray(s.str),
    pageNumber: s.nullOpt(s.str),
    pageSize: s.nullOpt(s.str),
    sort: s.nullOpt(sortOffersMapper.SeoType),

    x: s.opt(s.str),
    y: s.opt(s.str),
    z: s.opt(s.str),

    lx: s.opt(s.str),
    ly: s.opt(s.str),
    rx: s.opt(s.str),
    ry: s.opt(s.str),
    pol: s.opt(s.str),

    citySegment: s.opt(s.str)
});
