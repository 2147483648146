
export enum LandStatus {
    IZHS = 'IZHS',
    SNTDNP = 'SNTDNP',
    LPH = 'LPH'
}

export enum HouseType {
    STANDALONE = 'STANDALONE',
    PART_OF_HOUSE = 'PART_OF_HOUSE',
    DUPLEX = 'DUPLEX',
    TOWNHOUSE = 'TOWNHOUSE'
}

export enum RegionEnum {
    MSK = 'MSK',
    MSK_AND_MSK_OBL = 'MSK_AND_MSK_OBL',
    SPB = 'SPB',
    SPB_AND_LEN_OBL = 'SPB_AND_LEN_OBL',
    LEN_OBL = 'LEN_OBL',
    MSK_OBL = 'MSK_OBL',
}

export enum RegionIdEnum {
    MSK = 3,
    MSK_AND_MSK_OBL = 9513,
    MSK_OBL = 108,
    SPB = 444,
    SPB_AND_LEN_OBL = 9514,
    LEN_OBL = 886
}

export enum DealType {
    SELL = 'SELL',
    RENT = 'RENT'
}

export enum CategoryType {
    HOUSE = 'HOUSE',
    ROOM = 'ROOM',
    FLAT = 'FLAT',
    LAND = 'LAND'
}

export enum RoomsEnum {
    STUDIO = 'STUDIO',
    ROOM_1 = 'ROOM_1',
    ROOM_2 = 'ROOM_2',
    ROOM_3 = 'ROOM_3',
    ROOM_4 = 'ROOM_4',
    ROOM_5_AND_MORE = 'ROOM_5_AND_MORE',
    OPEN_PLAN = 'OPEN_PLAN'
}

export enum NewBuilding {
    NEW = 'NEW',
    SECOND = 'SECOND'
}

export enum CeilingHeightEnum {
    FROM_2_5 = 'FROM_2_5',
    FROM_2_7 = 'FROM_2_7',
    FROM_3 = 'FROM_3',
    FROM_4 = 'FROM_4'
}

export enum Bathroom {
    UNKNOWN = 'UNKNOWN',
    COMBINED = 'COMBINED',
    PARTED = 'PARTED',
    SEVERAL = 'SEVERAL',
}

export enum Renovation {
    EURO = 'EURO',
    COSMETIC = 'COSMETIC',
    DESIGNED = 'DESIGNED',
    NEED = 'NEED',
    NONE = 'NONE'
}

export enum PriceUnitEnum {
    ALL = 'ALL',
    METER = 'METER',
    ARE = 'ARE'
}

export enum NewBuildingTypeEnum {
    RESIDENTIAL_COMPLEX = 'RESIDENTIAL_COMPLEX',
    RESIDENTIAL_AREA = 'RESIDENTIAL_AREA',
    APART_COMPLEX = 'APART_COMPLEX',
    APART_HOTEL = 'APART_HOTEL',
    QUARTER = 'QUARTER',
    LOFT = 'LOFT',
    MULTI_FUNCTIONAL_COMPLEX = 'MULTI_FUNCTIONAL_COMPLEX',
    RESIDENCE = 'RESIDENCE',
    MICRO_DISTRICT = 'MICRO_DISTRICT',
    HOUSE = 'HOUSE',
    CLUB_HOUSE = 'CLUB_HOUSE'
}

export enum SortEnum {
    DATE = 'date',
    DATE_DESC = 'date_desc',
    PRICE = 'price',
    PRICE_DESC = 'price_desc'
}

export enum NewBuildingSortingEnum {
    UNKNOWN = 'UNKNOWN',
    RELEVANCE = 'RELEVANCE',
    PRICE = 'PRICE',
    DATE_OF_COMMISSIONING = 'DATE_OF_COMMISSIONING',
}

export enum BuildingStatusEnum {
    IN_PROGRESS = 'IN_PROGRESS',
    COMMISSIONED = 'COMMISSIONED',
    FROZEN = 'FROZEN',

}

export enum QuarterEnum {
    FIRST = 'FIRST',
    SECOND = 'SECOND',
    THIRD = 'THIRD',
    FOURTH = 'FOURTH'
}

export enum NewBuildingClassEnum {
    ECONOMY = 'ECONOMY',
    COMFORT = 'COMFORT',
    COMFORT_PLUS = 'COMFORT_PLUS',
    BUSINESS = 'BUSINESS',
    ELITE = 'ELITE'
}

// Материал стен дома;
export enum WallsTypeEnum {
    MONOLITH = 'MONOLITH',
    BRICK = 'BRICK',
    BRICK_MONOLITH = 'BRICK_MONOLITH',
    PANEL = 'PANEL',
    BLOCK = 'BLOCK',
    // WOOD = 'WOOD',
}

export enum GeoKindEnum {
    UNKNOWN,
    HOUSE,
    STREET,
    METRO,
    DISTRICT,
    LOCALITY,
    AREA,
    PROVINCE,
    COUNTRY,
    HYDRO,
    VEGETATION,
    AIRPORT,
    OTHER,
    RAILWAY_STATION,
    ROUTE,
    STATION,
    COMPOSITE_OBJECT,
    STREET_ADDRESS,
    APARTMENT
}

export enum ImageAttributesTypeEnum {
    UNKNOWN = 'UNKNOWN',
    LAYOUT = 'LAYOUT',
}
