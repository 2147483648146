import React, { useCallback } from 'react';
import { WallsType } from '@search/filter-enums/enums';
import { WallsTypeFilter } from '../../../../models/Filter';
import MobileFilter from '../../../filter/MobileFilter';
import '../MobileCommon.css';
import { MultiSelect, MultiSelectOption } from '../../common/MultiSelect';

interface IMobileWallsFilterViewProps {
    withWood?: boolean;
    filter: WallsTypeFilter;
    handleChange: (filter: WallsTypeFilter) => void;
}

const selectOptions: MultiSelectOption[] = [ {
    value: WallsType.MONOLITH,
    label: 'Монолит',
    className: 'col-xs-12 filter-item-gap'
}, {
    value: WallsType.PANEL,
    label: 'Панель',
    className: 'col-xs-12 filter-item-gap'
}, {
    value: WallsType.BRICK,
    label: 'Кирпич',
    className: 'col-xs-12 filter-item-gap'
}, {
    value: WallsType.BRICK_MONOLITH,
    label: 'Кирпич-монолит',
    className: 'col-xs-12 filter-item-gap'
}, {
    value: WallsType.BLOCK,
    label: 'Блок',
    className: 'col-xs-12 filter-item-gap'
} ];

const selectOptionsWithWood: MultiSelectOption[] = [ ...selectOptions, {
    value: WallsType.WOOD,
    label: 'Дерево',
    className: 'col-xs-12 filter-item-gap'
} ];

export const MobileWallsFilterView: React.FC<IMobileWallsFilterViewProps> = ({
    withWood = true,
    filter,
    handleChange
}) => {
    const onChange = useCallback(value => {
        handleChange(new WallsTypeFilter(value));
    }, [ handleChange ]);

    return (
        <MobileFilter
            filterTitle='Материал стен'
        >
            <MultiSelect
                name='walls'
                className='container container__clamp'
                options={withWood ? selectOptionsWithWood : selectOptions}
                value={filter.walls}
                handleChange={onChange}
            />
        </MobileFilter>
    );
};
