import React, { useCallback } from 'react';

import { Checkbox } from '@vtblife/uikit';
import { Safety } from '@search/filter-enums/enums';

import { SafetyFilter } from '../../../../models/Filter';
import { Filter2 } from '../../../new_filter/Filter2';
import { CheckboxGroup2 } from '../../../common/CheckboxGroup2';

const ITEMS = [
    [ Safety.GUARDED_AREA, 'Охрана' ],
    [ Safety.CONCIERGE, 'Консьерж' ],
    [ Safety.CLOSED_AREA, 'Закр. территория' ],
];

interface IProps {
    filter: SafetyFilter;
    handleChange: (filter: SafetyFilter) => void;
}

export const DesktopSafetyFilterView: React.FC<IProps> = ({
    filter,
    handleChange
}) => {
    const onChange = useCallback((val: string | number | string[] | null) => {
        if (val === null) {
            return;
        }

        if (Array.isArray(val)) {
            handleChange(new SafetyFilter(val as Safety[]));
        }
    }, [ handleChange ]);

    return (
        <Filter2 filterTitle='Безопасность'>
            <CheckboxGroup2
                mode='check'
                name='safety'
                onChange={onChange}
                value={filter.safety}
                isFilter
            >
                {
                    ITEMS.map(([ value, label ]) => (
                        <Checkbox
                            variant='filter'
                            key={value}
                            name={value}
                            block
                        >
                            {label}
                        </Checkbox>
                    ))
                }
            </CheckboxGroup2>
        </Filter2>
    );
};
