import './styles.css';

import React from 'react';

import classname from '@search/classname/src';

import { Badge, IBadgeProps } from '..';

const cn = classname.bind(null, 'DefaultBadge');

interface IDefaultBadgeProps extends IBadgeProps {
    variant: 'transparent' | 'gray' | 'blue';
}

export const DefaultBadge = ({
    variant,
    children,
    ...props
}: IDefaultBadgeProps) => (
    <Badge
        size='xs'
        className={cn(null, { variant })}
        textClassName={cn('text', { variant })}
        {...props}
    >
        {children}
    </Badge>
);
