import {
    Rooms as RoomsEnum,
    AreaUnit as AreaUnitEnum,
    FloorConstraints as FloorConstraintsEnum,
    TimeLimit as TimeLimitEnum,
    TransportType as TransportTypeEnum,
    CeilingHeight as CeilingHeightEnum,
    Bathroom as BathroomEnum,
    BalconyOptions as BalconyOptionsEnum,
    ParkingType as ParkingTypeEnum,
    WallsType as WallsTypeEnum,
    IsApartmentsOptions as IsApartmentsEnum,
    Position as PositionEnum,
    BuildingClass as BuildingClassEnum,
    Safety as SafetyEnum,
    Accreditation as AccreditationEnum,
    NewBuildingDeal as NewBuildingDealEnum,
    PaymentType as PaymentTypeEnum,
    FlatsSpecialEvent as FlatsSpecialEventEnum, FlatsSpecialEventString,
    OtherSpecialEvent as OtherSpecialEventEnum, OtherSpecialEventString,
    MiscSpecialEvent as MiscSpecialEventEnum, MiscSpecialEventString
} from '@search/filter-enums/enums';

export const Area = {
    stringValue: {
        [AreaUnitEnum.METER]: 'METER'
    },
    enumValue: {
        METER: AreaUnitEnum.METER
    }
};

export const FloorConstraints = {
    stringValue: {
        [FloorConstraintsEnum.ONLY]: 'ONLY',
        [FloorConstraintsEnum.EXCLUDE]: 'EXCLUDE'
    },
    enumValue: {
        ONLY: FloorConstraintsEnum.ONLY,
        EXCLUDE: FloorConstraintsEnum.EXCLUDE
    }
};

export const Rooms = {
    stringValue: {
        [RoomsEnum.STUDIO]: 'STUDIO',
        [RoomsEnum.ROOM_1]: 'ROOM_1',
        [RoomsEnum.ROOM_2]: 'ROOM_2',
        [RoomsEnum.ROOM_3]: 'ROOM_3',
        [RoomsEnum.ROOM_4]: 'ROOM_4',
        [RoomsEnum.ROOM_5_AND_MORE]: 'ROOM_5_AND_MORE',
        [RoomsEnum.OPEN_PLAN]: 'OPEN_PLAN'
    },
    enumValue: {
        STUDIO: RoomsEnum.STUDIO,
        ROOM_1: RoomsEnum.ROOM_1,
        ROOM_2: RoomsEnum.ROOM_2,
        ROOM_3: RoomsEnum.ROOM_3,
        ROOM_4: RoomsEnum.ROOM_4,
        ROOM_5_AND_MORE: RoomsEnum.ROOM_5_AND_MORE,
        OPEN_PLAN: RoomsEnum.OPEN_PLAN
    }
};

export const TransportType = {
    stringValue: {
        [TransportTypeEnum.FOOT]: 'FOOT',
        [TransportTypeEnum.PUBLIC_TRANSPORT]: 'PUBLIC_TRANSPORT'
    },
    enumValue: {
        FOOT: TransportTypeEnum.FOOT,
        PUBLIC_TRANSPORT: TransportTypeEnum.PUBLIC_TRANSPORT
    }
};

export const TimeLimit = {
    stringValue: {
        [TimeLimitEnum.MAX_5_MINUTES]: 'MAX_5_MINUTES',
        [TimeLimitEnum.MAX_10_MINUTES]: 'MAX_10_MINUTES',
        [TimeLimitEnum.MAX_15_MINUTES]: 'MAX_15_MINUTES',
        [TimeLimitEnum.MAX_20_MINUTES]: 'MAX_20_MINUTES',
        [TimeLimitEnum.MAX_25_MINUTES]: 'MAX_25_MINUTES',
        [TimeLimitEnum.MAX_30_MINUTES]: 'MAX_30_MINUTES',
        [TimeLimitEnum.MAX_40_MINUTES]: 'MAX_40_MINUTES',
        [TimeLimitEnum.MAX_50_MINUTES]: 'MAX_50_MINUTES',
        [TimeLimitEnum.MAX_60_MINUTES]: 'MAX_60_MINUTES'
    },
    enumValue: {
        MAX_5_MINUTES: TimeLimitEnum.MAX_5_MINUTES,
        MAX_10_MINUTES: TimeLimitEnum.MAX_10_MINUTES,
        MAX_15_MINUTES: TimeLimitEnum.MAX_15_MINUTES,
        MAX_20_MINUTES: TimeLimitEnum.MAX_20_MINUTES,
        MAX_25_MINUTES: TimeLimitEnum.MAX_25_MINUTES,
        MAX_30_MINUTES: TimeLimitEnum.MAX_30_MINUTES,
        MAX_40_MINUTES: TimeLimitEnum.MAX_40_MINUTES,
        MAX_50_MINUTES: TimeLimitEnum.MAX_50_MINUTES,
        MAX_60_MINUTES: TimeLimitEnum.MAX_60_MINUTES
    }
};

export const CeilingHeight = {
    stringValue: {
        [CeilingHeightEnum.FROM_2_5]: 'FROM_2_5',
        [CeilingHeightEnum.FROM_2_7]: 'FROM_2_7',
        [CeilingHeightEnum.FROM_3]: 'FROM_3',
        [CeilingHeightEnum.FROM_4]: 'FROM_4'
    },
    enumValue: {
        FROM_2_5: CeilingHeightEnum.FROM_2_5,
        FROM_2_7: CeilingHeightEnum.FROM_2_7,
        FROM_3: CeilingHeightEnum.FROM_3,
        FROM_4: CeilingHeightEnum.FROM_4
    }
};

export const Bathroom = {
    stringValue: {
        [BathroomEnum.COMBINED]: 'COMBINED',
        [BathroomEnum.PARTED]: 'PARTED'
    },
    enumValue: {
        COMBINED: BathroomEnum.COMBINED,
        PARTED: BathroomEnum.PARTED
    }
};

export const BalconyOptions = {
    stringValue: {
        [BalconyOptionsEnum.HAS_ANY]: 'HAS_ANY'
    },
    enumValue: {
        HAS_ANY: BalconyOptionsEnum.HAS_ANY
    }
};

export const ParkingType = {
    stringValue: {
        [ParkingTypeEnum.CLOSED]: 'CLOSED',
        [ParkingTypeEnum.UNDERGROUND]: 'UNDERGROUND',
        [ParkingTypeEnum.OPEN]: 'OPEN'
    },
    enumValue: {
        CLOSED: ParkingTypeEnum.CLOSED,
        UNDERGROUND: ParkingTypeEnum.UNDERGROUND,
        OPEN: ParkingTypeEnum.OPEN
    }
};

export const WallsType = {
    stringValue: {
        [WallsTypeEnum.MONOLITH]: 'MONOLITH',
        [WallsTypeEnum.BRICK_MONOLITH]: 'BRICK_MONOLITH',
        [WallsTypeEnum.BRICK]: 'BRICK',
        [WallsTypeEnum.PANEL]: 'PANEL',
        [WallsTypeEnum.BLOCK]: 'BLOCK'
    },
    enumValue: {
        MONOLITH: WallsTypeEnum.MONOLITH,
        BRICK_MONOLITH: WallsTypeEnum.BRICK_MONOLITH,
        BRICK: WallsTypeEnum.BRICK,
        PANEL: WallsTypeEnum.PANEL,
        BLOCK: WallsTypeEnum.BLOCK
    }
};

export const IsApartments = {
    stringValue: {
        [IsApartmentsEnum.NO_APARTMENTS]: 'NO_APARTMENTS',
        [IsApartmentsEnum.APARTMENTS_ONLY]: 'APARTMENTS_ONLY'
    },
    enumValue: {
        NO_APARTMENTS: IsApartmentsEnum.NO_APARTMENTS,
        APARTMENTS_ONLY: IsApartmentsEnum.APARTMENTS_ONLY
    }
};

export const Position = {
    stringValue: {
        [PositionEnum.STANDALONE]: 'standalone'
    },
    enumValue: {
        standalone: PositionEnum.STANDALONE
    }
};

export const Safety = {
    stringValue: {
        [SafetyEnum.CONCIERGE]: 'concierge',
        [SafetyEnum.CLOSED_AREA]: 'closedArea',
        [SafetyEnum.GUARDED_AREA]: 'guardedArea'
    },
    enumValue: {
        concierge: SafetyEnum.CONCIERGE,
        closedArea: SafetyEnum.CLOSED_AREA,
        guardedArea: SafetyEnum.GUARDED_AREA
    }
};

export const BuildingClass = {
    stringValue: {
        [BuildingClassEnum.ECONOMY]: 'ECONOMY',
        [BuildingClassEnum.COMFORT]: 'COMFORT',
        [BuildingClassEnum.COMFORT_PLUS]: 'COMFORT_PLUS',
        [BuildingClassEnum.BUSINESS]: 'BUSINESS',
        [BuildingClassEnum.ELITE]: 'ELITE'
    },
    enumValue: {
        ECONOMY: BuildingClassEnum.ECONOMY,
        COMFORT: BuildingClassEnum.COMFORT,
        COMFORT_PLUS: BuildingClassEnum.COMFORT_PLUS,
        BUSINESS: BuildingClassEnum.BUSINESS,
        ELITE: BuildingClassEnum.ELITE
    }
};

export const NewBuildingDeal = {
    stringValue: {
        [NewBuildingDealEnum.LAW_214]: 'law214',
        [NewBuildingDealEnum.ESCROW]: 'escrow'
    },
    enumValue: {
        law214: NewBuildingDealEnum.LAW_214,
        escrow: NewBuildingDealEnum.ESCROW
    }
};

export const Accreditation = {
    stringValue: {
        [AccreditationEnum.ACCREDITED_BY_VTB]: 'accreditedByVTB'
    },
    enumValue: {
        accreditedByVTB: AccreditationEnum.ACCREDITED_BY_VTB
    }
};

export const PaymentType = {
    stringValue: {
        [PaymentTypeEnum.ARMY_MORTGAGE]: 'armyMortgage',
        [PaymentTypeEnum.INSTALLMENT]: 'installment',
        [PaymentTypeEnum.MOTHER_CAPITAL]: 'motherCapital'
    },
    enumValue: {
        armyMortgage: PaymentTypeEnum.ARMY_MORTGAGE,
        installment: PaymentTypeEnum.INSTALLMENT,
        motherCapital: PaymentTypeEnum.MOTHER_CAPITAL
    }
};

export const FlatsSpecialEvents = {
    stringValue: {
        [FlatsSpecialEventEnum.FLAT_DISCOUNT]: FlatsSpecialEventString.FLAT_DISCOUNT,
        [FlatsSpecialEventEnum.FLAT_INSTALLMENT_PLAN]: FlatsSpecialEventString.FLAT_INSTALLMENT_PLAN,
        [FlatsSpecialEventEnum.FLAT_SALES_START]: FlatsSpecialEventString.FLAT_SALES_START
    },
    enumValue: {
        [FlatsSpecialEventString.FLAT_DISCOUNT]: FlatsSpecialEventEnum.FLAT_DISCOUNT,
        [FlatsSpecialEventString.FLAT_INSTALLMENT_PLAN]: FlatsSpecialEventEnum.FLAT_INSTALLMENT_PLAN,
        [FlatsSpecialEventString.FLAT_SALES_START]: FlatsSpecialEventEnum.FLAT_SALES_START
    }
};

export const OtherSpecialEvents = {
    stringValue: {
        [OtherSpecialEventEnum.OTHER_DISCOUNT]: OtherSpecialEventString.OTHER_DISCOUNT,
        [OtherSpecialEventEnum.OTHER_INSTALLMENT_PLAN]: OtherSpecialEventString.OTHER_INSTALLMENT_PLAN,
        [OtherSpecialEventEnum.OTHER_SALES_START]: OtherSpecialEventString.OTHER_SALES_START
    },
    enumValue: {
        [OtherSpecialEventString.OTHER_DISCOUNT]: OtherSpecialEventEnum.OTHER_DISCOUNT,
        [OtherSpecialEventString.OTHER_INSTALLMENT_PLAN]: OtherSpecialEventEnum.OTHER_INSTALLMENT_PLAN,
        [OtherSpecialEventString.OTHER_SALES_START]: OtherSpecialEventEnum.OTHER_SALES_START
    }
};

export const MiscSpecialEvents = {
    stringValue: {
        [MiscSpecialEventEnum.GIFT]: MiscSpecialEventString.GIFT,
        [MiscSpecialEventEnum.PROMO]: MiscSpecialEventString.PROMO
    },
    enumValue: {
        [MiscSpecialEventString.GIFT]: MiscSpecialEventEnum.GIFT,
        [MiscSpecialEventString.PROMO]: MiscSpecialEventEnum.PROMO
    }
};
