export function getWindowSize() {
    return {
        top: 0,
        right: window.innerWidth,
        bottom: window.innerHeight,
        left: 0,
        width: window.innerWidth,
        height: window.innerHeight,
    };
}

export function getElementSize($element: HTMLElement) {
    const { top, right, bottom, left } = $element.getBoundingClientRect();

    return {
        top,
        right,
        bottom,
        left,
        width: right - left,
        height: bottom - top,
    };
}
