import React, { useCallback, useState } from 'react';
import { Typography } from '@vtblife/uikit';
import { Size } from '@vtblife/uikit/legacy';
import {
    TipTypeEnum,
    RootRegionsApplicationEnum,
    SuggestTypeEnum
} from '@search/graphql-typings';
import { SuggestItemTypeEnum } from '@search/graphql-gateway/typings';

import classname from '@search/classname/src';
import Input from '@search/vtbeco-ui/src/components/controls/Input';

import { voidFunction } from '@search/helpers/src/voidFunction';
import { regionsWithMetroEnumeration } from '@search/filter-enums/enums/Region';
import type { FilterCollection } from '@search/vtbeco-frontend-core/view/filters/models/FilterCollection';
import type { UseToggleChainResult } from '@search/vtbeco-frontend-core/view/common/components/Price/popper/useToggleChain';

import type { GeoBaseStore } from '../../../../../../domain/geo/GeoBase';
import type { AddQueryDisplayValue, ExtendedTip, QueryDisplayValue } from '../../../../../common/hooks/useQueryDisplayValues';
import { GeoSuggest, correctAndBroadenRegionId } from '../../../../../common/components/GeoSuggest/GeoSuggest';
import type { SuggestionType } from '../../../../../common/components/GeoSuggest/GeoSuggest';

import {
    Filters,
    GeoFilter,
    TransportLimitFilter,
    NewBuildingDataFilter,
    RegionFilter,
    DeveloperFilter
} from '../../../../models/Filter';
import type { IFilter } from '../../../../models/Filter';

import { GeoSelector, TabsEnum as GeoSelectorTabsEnum } from './GeoSelector';
import { FilterTagList } from './FilterTagList/FilterTagList';
import './DesktopGeoFiltersView.css';

const cn = classname.bind(null, 'DesktopGeoFiltersView');

export interface IDesktopGeoFilterViewProps {
    rootRegionsApp: RootRegionsApplicationEnum;
    filters: FilterCollection;
    onChange: (filters: IFilter[]) => void;
    onSubmit?: () => void;
    count: number;
    showNewBuildingsGroup?: boolean;
    startWithNewBuildings?: boolean;
    addQueryDisplayValue?: AddQueryDisplayValue;
    placeholder?: string;
    placeholderWithNewBuildings?: string;
    geoStore: GeoBaseStore;
    limit?: number;
    inputSize?: Size.Small | Size.Large;
    inputBorderSide?: 'square' | 'rounded_square' | 'square_rounded';
    isVertical?: boolean;
    filterTitle?: string;
    tips?: QueryDisplayValue[];
    removeTips?: (tips: ExtendedTip[]) => void;
    suggestTypes?: SuggestTypeEnum[];
    className?: string;
    spySuggest?: UseToggleChainResult;
}

export const DesktopGeoFiltersView: React.FC<IDesktopGeoFilterViewProps> = ({
    geoStore,
    filters,
    onChange,
    onSubmit = voidFunction,
    count,
    rootRegionsApp,
    suggestTypes,
    limit,
    showNewBuildingsGroup,
    startWithNewBuildings,
    addQueryDisplayValue,
    placeholder = 'Введите город, метро, район, адрес',
    placeholderWithNewBuildings = 'Введите город, метро, район, адрес, ЖК',
    inputSize = Size.Large,
    inputBorderSide,
    isVertical = false,
    filterTitle,
    tips,
    removeTips,
    className,
    spySuggest
}) => {
    const geoFilter = filters.get<GeoFilter>(Filters.geo);
    const transportLimitFilter = filters.get<TransportLimitFilter>(Filters.transportLimit);
    const regionId = filters.get<RegionFilter>(Filters.region).region;

    const { stations: metro } = geoStore;

    /** District в збс, новостройки не поддерживают byRegion */
    const districts = geoStore.districts.byRegion ? geoStore.districts.byRegion() : geoStore.districts;

    // eslint-disable-next-line complexity
    const handleChange = useCallback((suggestion: SuggestionType) => {
        const suggestionRegionId = suggestion?.__typename === 'SuggestItemDeveloper' ?
            undefined :
            suggestion?.data.regionId;

        const newRegionId = suggestionRegionId && regionId !== suggestionRegionId ? suggestionRegionId : undefined;

        const filterGeoIds: Set<number> = new Set<number>(newRegionId ? [] : geoFilter.geoIds);
        const filterMetroIds: number[] = newRegionId ? [] : [ ...geoFilter.metroIds ];
        const filterDistrictIds: number[] = newRegionId ? [] : [ ...geoFilter.districtIds ];
        const filterAddressIds: number[] = newRegionId ? [] : [ ...geoFilter.addressIds ];
        const filterNewBuildingIds: number[] = newRegionId ? [] : [ ...geoFilter.newBuildingIds ];
        let newBuildingData;
        let developerId;

        switch (suggestion.__typename) {
            case SuggestItemTypeEnum.NewBuilding: {
                const { id } = suggestion.data;

                if (! filterNewBuildingIds.includes(id)) {
                    filterNewBuildingIds.push(id);
                }

                const { newBuilding } = suggestion;

                if (newBuilding) {
                    const {
                        id: newBuildingId,
                        type: newBuildingType,
                        name: newBuildingName,
                        title: newBuildingTitle
                    } = newBuilding;

                    addQueryDisplayValue && addQueryDisplayValue('newBuildings', {
                        id: newBuildingId,
                        title: newBuildingTitle?.nominative,
                        type: TipTypeEnum.NewBuilding
                    });

                    newBuildingData = {
                        type: newBuildingType,
                        name: newBuildingName,
                        id: newBuildingId
                    };
                }

                break;
            }

            case SuggestItemTypeEnum.Developer: {
                const { developer } = suggestion;

                if (developer) {
                    const { id } = developer;

                    developerId = id;

                    addQueryDisplayValue && addQueryDisplayValue('developers', {
                        id,
                        title: developer.title,
                        type: TipTypeEnum.Developer
                    });
                }

                break;
            }

            case SuggestItemTypeEnum.Metro: {
                const {
                    label,
                    data: { id }
                } = suggestion;

                if (! filterMetroIds.includes(id)) {
                    filterMetroIds.push(id);
                    filterGeoIds.add(id);

                    addQueryDisplayValue && addQueryDisplayValue('metros', {
                        id,
                        title: label,
                        type: TipTypeEnum.Metro
                    });
                }

                break;
            }

            case SuggestItemTypeEnum.District: {
                const { district } = suggestion;
                const { id } = suggestion.data;

                if (! filterDistrictIds.includes(id)) {
                    filterDistrictIds.push(id);
                    filterGeoIds.add(id);

                    addQueryDisplayValue && addQueryDisplayValue('addresses', {
                        id: district.id,
                        kind: district.kind,
                        translit: district.translit,
                        title: district.displayName,
                        type: TipTypeEnum.District
                    });
                }

                break;
            }

            case SuggestItemTypeEnum.Address: {
                const {
                    address,
                    data: { id }
                } = suggestion;

                if (! filterAddressIds.includes(id)) {
                    filterAddressIds.push(id);
                    filterGeoIds.add(id);

                    addQueryDisplayValue && addQueryDisplayValue('addresses', {
                        id: address.id,
                        kind: address.kind,
                        translit: address.translit,
                        title: address.displayName,
                        type: TipTypeEnum.Address
                    });
                }

                break;
            }

            default:
                return;
        }

        const newFilters: IFilter[] = [
            new GeoFilter(
                filterGeoIds,
                filterMetroIds,
                filterDistrictIds,
                filterAddressIds,
                filterNewBuildingIds
            ),
            new NewBuildingDataFilter(newBuildingData)
        ];

        if (newRegionId) {
            newFilters.push(
                new RegionFilter(newRegionId)
            );
        }

        if (developerId) {
            newFilters.push(
                new DeveloperFilter(developerId)
            );
        }

        onChange(newFilters);
    }, [
        geoFilter.geoIds,
        geoFilter.districtIds,
        geoFilter.addressIds,
        geoFilter.metroIds,
        geoFilter.newBuildingIds,
        onChange,
        regionId,
        addQueryDisplayValue
    ]);

    const [ geoSelectorActiveTab, setGeoSelectorActiveTab ] = useState<GeoSelectorTabsEnum | null>(null);

    const handleGeoSelectorClose = useCallback(() => {
        setGeoSelectorActiveTab(null);
    }, [ setGeoSelectorActiveTab ]);

    const handleGeoSelectorSubmit = useCallback(() => {
        handleGeoSelectorClose();
        onSubmit();
    }, [ onSubmit, handleGeoSelectorClose ]);

    const handleMetroClick = useCallback(() => {
        setGeoSelectorActiveTab(GeoSelectorTabsEnum.metro);
    }, [ setGeoSelectorActiveTab ]);

    const handleDistrictClick = useCallback(() => {
        setGeoSelectorActiveTab(GeoSelectorTabsEnum.district);
    }, [ setGeoSelectorActiveTab ]);

    const handleGeoSelectorChange = useCallback(data => {
        const changedFilters = [];

        if (data.metroIds || data.districtIds) {
            const geoIds = new Set(geoFilter.geoIds);
            const metroIds = new Set(geoFilter.metroIds);
            const districtIds = new Set(geoFilter.districtIds);

            if (data.metroIds) {
                if (data.metroIds.diff.selected) {
                    data.metroIds.diff.selected.forEach((id: number) => {
                        metroIds.add(id);
                        geoIds.add(id);
                    });
                }

                if (data.metroIds.diff.deselected) {
                    data.metroIds.diff.deselected.forEach((id: number) => {
                        metroIds.delete(id);
                        geoIds.delete(id);
                    });
                }
            }

            if (data.districtIds) {
                if (data.districtIds.diff.selected) {
                    data.districtIds.diff.selected.forEach((id: number) => {
                        districtIds.add(id);
                        geoIds.add(id);
                    });
                }

                if (data.districtIds.diff.deselected) {
                    data.districtIds.diff.deselected.forEach((id: number) => {
                        districtIds.delete(id);
                        geoIds.delete(id);
                    });
                }
            }

            const metroIdList = Array.from(metroIds);
            const districtIdList = Array.from(districtIds);
            const ids: {
                type: SuggestItemTypeEnum;
                idList: number[];
            }[] = [
                {
                    type: SuggestItemTypeEnum.Metro,
                    idList: metroIdList
                },
                {
                    type: SuggestItemTypeEnum.District,
                    idList: districtIdList
                }
            ];

            let newRegionId: number | undefined;

            for (const { type, idList } of ids) {
                for (const id of idList) {
                    const broadenedRegionId = correctAndBroadenRegionId(id, type, regionId);

                    if (broadenedRegionId !== regionId) {
                        newRegionId = broadenedRegionId;

                        break;
                    }
                }

                if (newRegionId) {
                    changedFilters.push(new RegionFilter(newRegionId));

                    break;
                }
            }

            changedFilters.push(new GeoFilter(
                geoIds,
                metroIdList,
                districtIdList,
                geoFilter.addressIds,
                geoFilter.newBuildingIds
            ));
        }

        onChange(changedFilters);
    }, [ onChange, geoFilter, regionId ]);

    const handleTransportLimitChange = useCallback((tranpostLimitFilter: TransportLimitFilter) => {
        onChange([ tranpostLimitFilter ]);
    }, [ onChange ]);

    const hasMetro = regionsWithMetroEnumeration.has(regionId) && ! metro.isEmpty();
    const hasDistricts = districts.isEmpty ? ! districts.isEmpty() : districts?.ids?.length;

    return (
        <>
            {filterTitle && (
                <Typography
                    variant='secondary-alone'
                    color='secondary'
                    className={cn('filter-title')}
                >
                    {filterTitle}
                </Typography>
            )}
            <div className={cn(null, undefined, className)}>
                <GeoSuggest
                    regionId={regionId}
                    rootRegionsApp={rootRegionsApp}
                    suggestTypes={suggestTypes}
                    limit={limit}
                    showNewBuildingsGroup={showNewBuildingsGroup && ! geoFilter.newBuildingIds.length}
                    startWithNewBuildings={startWithNewBuildings}
                    onGeoChange={handleChange}
                    placeholder={placeholder}
                    placeholderWithNewBuildings={placeholderWithNewBuildings}
                    spySuggest={spySuggest}
                    renderInput={(props, state, handlers) => {
                        return (
                            <Input
                                size={inputSize}
                                borderSide={inputBorderSide}
                                width='max'
                                className={cn('input', { vertical: isVertical })}
                                name='filter-geo-input'
                                onBlur={handlers.handleBlur}
                                onFocus={handlers.handleFocus}
                                onChange={handlers.handleInputChange}
                                onKeyDown={handlers.handleInputKeyDown}
                                value={state.label}
                                placeholder={props.placeholder}
                                title={props.placeholder}
                                extraAfter={isVertical ? null : (
                                    <>
                                        {
                                            hasMetro ? (
                                                <span
                                                    className={cn('extra')}
                                                    onClick={handleMetroClick}
                                                    data-test='filter-geo-metro-popup'
                                                >
                                                    Метро
                                                </span>
                                            ) : null
                                        }
                                        {
                                            hasDistricts ? (
                                                <span
                                                    className={cn('extra')}
                                                    onClick={handleDistrictClick}
                                                    data-test='filter-geo-district-popup'
                                                >
                                                    Районы
                                                </span>
                                            ) : null
                                        }
                                    </>
                                )}
                            />
                        );
                    }}
                />
                {
                    geoSelectorActiveTab === null ? null : (
                        <GeoSelector
                            count={count}
                            regionId={regionId}
                            geoFilter={geoFilter}
                            metro={metro}
                            districts={districts}
                            activeTab={geoSelectorActiveTab}
                            onChange={handleGeoSelectorChange}
                            transportLimitFilter={transportLimitFilter}
                            onChangeTransportLimitChange={handleTransportLimitChange}
                            onTabChange={setGeoSelectorActiveTab}
                            onClose={handleGeoSelectorClose}
                            onSubmit={handleGeoSelectorSubmit}
                        />
                    )
                }
                {
                    isVertical ? (
                        <div className={cn('extra-links')}>
                            <span className={cn('extra-links-text')}>Выбрать: </span>
                            {hasMetro && (
                                <span
                                    className={cn('extra-link')}
                                    onClick={handleMetroClick}
                                    data-test='filter-geo-metro-popup'>
                                    метро{hasDistricts ? ', ' : ''}
                                </span>
                            )}
                            {hasDistricts && (
                                <span
                                    className={cn('extra-link')}
                                    onClick={handleDistrictClick}
                                    data-test='filter-geo-district-popup'
                                >
                                    районы
                                </span>
                            )}
                        </div>
                    ) : null
                }
                {
                    tips?.length && removeTips ? (
                        <FilterTagList
                            tips={tips}
                            filters={filters}
                            openMetroModal={handleMetroClick}
                            removeTips={removeTips}
                        />
                    ) : null
                }
            </div>
        </>
    );
};
