import './MortgageGovernmentDisclaimer.css';

import React from 'react';
import Icon from '@vtblife/uikit-icons';
import classname from '@search/classname/src';

const cn = classname.bind(null, 'MortgageGovernmentDisclaimer');

export function MortgageGovernmentDisclaimer({
    onClick,
    isAccent = false,
    isLongDescr,
    descriptionText,
    mainText,
    isImportant,
    splitOnRows
}: {
    isAccent?: boolean;
    isMobile?: boolean;
    isLongDescr?: boolean;
    onClick?(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
    descriptionText?: React.ReactNode;
    mainText?: string;
    isImportant?: boolean;
    splitOnRows?: boolean;
}) {
    return (<div className={cn(null, { isImportant, splitOnRows })}>
        {isLongDescr && (<div className={cn('icon-wrap')}><Icon name='exclamation-circle' size='s' /></div>)}
        <span className={cn('text', { isImportant, splitOnRows })}>{mainText ?? 'Одобрят ли вам ипотеку с\u00A0господдержкой?'}</span>
        <button
            className={cn('button', { accent: isAccent })}
            onClick={e => {
                e.preventDefault();
                onClick?.(e);
            }}
            data-test='disclamer-click'
        >{descriptionText ?? (isLongDescr ? 'Узнать условия кредитования' : 'Условия кредитования')}</button>
    </div>);
}
