/* eslint-disable @typescript-eslint/no-explicit-any */
import { AuthorTypeOptions, LayoutImageOptions, PhotoOptions } from '@search/filter-enums/enums';
import { IRange } from '@search/vtbeco-frontend-core/view/filters/types/IRange';
import { formatRange } from '@search/helpers/src/formatNumber';
import {
    ApartmentFilter,
    AreaFilter,
    AuthorFilter,
    CellHeightFilter,
    Filters,
    FloorFilter,
    FloorsTotalFilter,
    KitchenAreaFilter,
    LayoutImageOptionsFilter,
    LivingAreaFilter,
    PhotoOptionsFilter,
    TransportLimitFilter,
    YearBuildingFilter,
    SellerOrganizationNameFilter,
    MortgagePaymentFilter
} from '../filters/models/Filter';
import { priceByMortgagePayment } from '../filters/helpers/priceHelper';
import {
    apartmentsLabels,
    authorLabels,
    ceilingHeightLabels,
    floorConstraintsLabels,
    timeLimitLabel,
    transportTypeLabel
} from './labels';

export const extraFiltersIds: Filters[] = [
    Filters.bathroomLocation,
    Filters.heating,
    Filters.supplyOptions,
    Filters.landArea,
    Filters.houseArea,
    Filters.mapBounds,
    Filters.geo,
    Filters.totalArea,
    Filters.livingArea,
    Filters.kitchenArea,
    Filters.floor,
    Filters.floorsTotal,
    Filters.cellHeight,
    Filters.renovation,
    Filters.bathroom,
    Filters.balcony,
    Filters.developer,
    Filters.buildingClass,
    Filters.builtYear,
    Filters.parkings,
    Filters.walls,
    Filters.apartments,
    Filters.position,
    Filters.infrastructure,
    Filters.safety,
    Filters.accreditation,
    Filters.newBuildingDeal,
    Filters.paymentType,
    Filters.author,
    Filters.photoOptions,
    Filters.layoutImageOptions,
    Filters.livingFacility,
    Filters.sellType,
    Filters.furniture,
    Filters.livingTenantOptions,
    Filters.rentConditions,
    Filters.rentPrepayment,
    Filters.bankPledgeOptions,
    Filters.seoMortgageAllowed,
    Filters.closedSales,
    Filters.buildings,
    Filters.windowsDirection,
    Filters.transportLimit,
    Filters.flatsSpecialEvents,
    Filters.otherSpecialEvents,
    Filters.miscSpecialEvents,
    Filters.nearbyOptions,
    Filters.renovationProgram,
    Filters.sellerOrganizationName,
    Filters.buildEpoch,
    Filters.onlineView,
    Filters.mortgagePayment,
    Filters.finishingType,
    Filters.commissioning
];

export const buildRangeLabel = (
    value: IRange<number | null>,
    title: string,
    unit = ''
) => {
    if (value === null) {
        return null;
    }
    const spacedUnit = unit ? ` ${unit}` : unit;

    if (value.from && value.to) {
        return `${title}: от ${value.from}${spacedUnit} до ${value.to}${spacedUnit}`;
    } else if (value.from && ! value.to) {
        return `${title}: от ${value.from}${spacedUnit}`;
    } else if (! value.from && value.to) {
        return `${title}: до ${value.to}${spacedUnit}`;
    }

    return null;
};

const getTransportLimitTip = (filter: TransportLimitFilter) => {
    const { transportType, timeLimit } = filter;

    if (transportType && timeLimit) {
        return `До метро ${timeLimitLabel[timeLimit]} мин. ${transportTypeLabel[transportType]}`;
    }

    return null;
};

const getFloorLabelTip = (filter: FloorFilter) => {
    const { range, last, first } = filter;
    const labels = [ buildRangeLabel(range, 'Этаж в доме') ];

    if (last) {
        labels.push(`${floorConstraintsLabels[last]} последний`);
    }

    if (first) {
        labels.push(`${floorConstraintsLabels[first]} первый`);
    }

    return labels.join(', ');
};

const getMortgagePriceTip = (filter: MortgagePaymentFilter) => {
    if (filter.payment) {
        return `С таким платежом вы сможете купить квартиру ${formatRange(null, priceByMortgagePayment(filter.payment))}.`;
    }

    return null;
};
const getCeilingHeightLabelTip = (filter: CellHeightFilter) =>
    ceilingHeightLabels[filter.value];

const getAreaLabelTip = (filter: AreaFilter) =>
    buildRangeLabel(filter.value, 'Общая площадь', 'м²');

const getLivingLabelTip = (filter: LivingAreaFilter) =>
    buildRangeLabel(filter.value, 'Жилая площадь', 'м²');

const getKitchenLabelTip = (filter: KitchenAreaFilter) =>
    buildRangeLabel(filter.value, 'Площадь кухни', 'м²');

const getFloorsTotalLabelTip = (filter: FloorsTotalFilter) =>
    buildRangeLabel(filter.range, 'Этажей в доме');

const getYearBuildingLabelTip = (filter: YearBuildingFilter) =>
    buildRangeLabel(filter.value, 'Год постройки', 'г.');

const getApartmentsLabelTip = (filter: ApartmentFilter) =>
    apartmentsLabels[filter.isApartment];

const getAuthorTypeTip = (filter: AuthorFilter) => {
    const check = (v: number) => {
        return (! isNaN(v) && (v in AuthorTypeOptions));
    };
    const result = [];

    for (const i of filter.author) {
        if (check(Number(i))) {
            result.push(
                authorLabels[Number(i)]
            );
        }
    }
    return result.join(', ');
};

const getPhotoTypeTip = (filter: PhotoOptionsFilter) => filter.photoOption === PhotoOptions.WITH_PHOTO ?
    'Только с фото' :
    null;

const getLayoutImageTypeTip = (filter: LayoutImageOptionsFilter) =>
    filter.layoutImageOption.find(option => option === LayoutImageOptions.HAS_ANY) ?
        'С планировкой' : null;

const getSellerOrganizationName = (filter: SellerOrganizationNameFilter) => {
    return filter.sellerOrganizationName ? `${filter.sellerOrganizationName}` : null;
};

const getBankPledgeTip = () => 'В залоге';

const getNearbySchoolTip = () => 'Рядом хорошая школа';

const getRenovationProgramTip = () => 'Дом под реновацию';

const getClosedSalesTip = () => 'С закрытыми продажами';

const getOnlineViewTip = () => 'Онлайн-просмотр';

// eslint-disable-next-line complexity
export const serializeToTips = (id: Filters, filter: any) => {
    switch (id) {
        case Filters.photoOptions: {
            return getPhotoTypeTip(filter as PhotoOptionsFilter);
        }
        case Filters.layoutImageOptions: {
            return getLayoutImageTypeTip(filter as LayoutImageOptionsFilter);
        }
        case Filters.seoMortgageAllowed: {
            return 'В ипотеку';
        }
        case Filters.bankPledgeOptions: {
            return getBankPledgeTip();
        }
        case Filters.renovationProgram: {
            return getRenovationProgramTip();
        }
        case Filters.nearbyOptions: {
            return getNearbySchoolTip();
        }
        case Filters.author: {
            return getAuthorTypeTip(filter as AuthorFilter);
        }
        case Filters.transportLimit: {
            return getTransportLimitTip(filter as TransportLimitFilter);
        }
        case Filters.floor: {
            return getFloorLabelTip(filter as FloorFilter);
        }
        case Filters.cellHeight: {
            return getCeilingHeightLabelTip(filter as CellHeightFilter);
        }
        case Filters.totalArea: {
            return getAreaLabelTip(filter as AreaFilter);
        }
        case Filters.livingArea: {
            return getLivingLabelTip(filter as LivingAreaFilter);
        }
        case Filters.kitchenArea: {
            return getKitchenLabelTip(filter as KitchenAreaFilter);
        }
        case Filters.floorsTotal: {
            return getFloorsTotalLabelTip(filter as FloorsTotalFilter);
        }
        case Filters.builtYear: {
            return getYearBuildingLabelTip(filter as YearBuildingFilter);
        }
        case Filters.apartments: {
            return getApartmentsLabelTip(filter as ApartmentFilter);
        }
        case Filters.closedSales: {
            return getClosedSalesTip();
        }
        case Filters.sellerOrganizationName: {
            return getSellerOrganizationName(filter as SellerOrganizationNameFilter);
        }
        case Filters.onlineView: {
            return getOnlineViewTip();
        }
        case Filters.mortgagePayment: {
            return getMortgagePriceTip(filter as MortgagePaymentFilter);
        }
        default: {
            return null;
        }
    }
};
