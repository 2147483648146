export enum NewBuildingAnalytics {
    // Показать телефон на выдаче новостроек
    NewBuildingShowPhone = 'nb-snippet-phone',
    NewBuildingReplacePhone = 'ct-number-replace',
    NewBuildingReplacePhoneTry = 'ct-number-replace-try',
    NewBuildingReplacePhoneFail = 'ct-number-replace-fail',

    // Добавить новостройку в избранное
    NewBuildingAddToFavorites = 'new_building_add_to_favorites',
    // Добавить оффер в избранное
    OfferAddToFavorites = 'flat_add_to_favorites',

    // Загрузка выдачи
    NewBuildingSerpLoad = 'nb-serp-load',

    // Баннер про инвестиции
    InvestmentPromoClick = 'nb_investment_banner_click',

    // Отзыв о сайте
    CustomerFeedbackClick = 'nb_feedback_click'
}
