import './MobileFilter2.css';

import React from 'react';
import { Typography } from '@vtblife/uikit';

import classname from '@search/classname/src';

const cn = classname.bind(null, 'MobileFilter2');

interface IProps {
    filterTitle?: string | React.ReactNode;
    className?: string;
    childrenClassName?: string;
    dataTestId?: string;
    desktopTitleGap?: boolean;
}

export const MobileFilter2: React.FC<IProps> = ({
    filterTitle,
    children,
    className,
    desktopTitleGap,
    dataTestId = null,
    childrenClassName
}) => {
    return filterTitle ? (
        <div className={className} data-testid={dataTestId}>
            {filterTitle && (
                <Typography
                    variant='secondary-alone'
                    color='secondary'
                    className={cn('filter-title', { desktopTitleGap }, className)}
                >
                    {filterTitle}
                </Typography>
            )}
            <div className={childrenClassName}>
                {children}
            </div>
        </div>
    ) : (
        <div className={childrenClassName ?? className} data-testid={dataTestId}>
            {children}
        </div>
    );
};
