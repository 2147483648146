import { PopupDimensions, ViewportDimensions, Position } from './types';

/*
 * Вычисляет коэффициент пересечения доступной площади для открытия попапа и собственной площади попапа.
 *
 * @param popupPosition
 * @param viewportDimensions
 * @param popupDimensions
 * @param offsets
 */
export const getViewportFactor = (
    popupPosition: Position,
    viewportDimensions: ViewportDimensions,
    popupDimensions: PopupDimensions,
    offsets = {
        viewport: 0
    }
): number => {
    let viewportFactor = 0;
    const intersectionLeft = Math.max(popupPosition.left, viewportDimensions.left + offsets.viewport);
    const intersectionRight = Math.min(
        popupPosition.left + popupDimensions.width,
        viewportDimensions.right - offsets.viewport
    );
    const intersectionTop = Math.max(
        popupPosition.top,
        viewportDimensions.top + offsets.viewport
    );
    const intersectionBottom = Math.min(
        popupPosition.top + popupDimensions.height,
        viewportDimensions.bottom - offsets.viewport
    );

    if (intersectionLeft < intersectionRight && intersectionTop < intersectionBottom) {
        viewportFactor = (intersectionRight - intersectionLeft) *
        (intersectionBottom - intersectionTop) / popupDimensions.area;
    }

    return viewportFactor;
};
