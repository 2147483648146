import React from 'react';
import { withScope, captureException } from '@sentry/browser';

class GeoErrorBoundary extends React.Component<{}, { hasError: boolean }> {
    constructor(props: {} | Readonly<{}>) {
        super(props);

        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: unknown) {
        withScope(scope => {
            scope.setExtra('errors', error.name);
            scope.setExtra('errorInfo', errorInfo);

            captureException(error, {
                tags: {
                    section: 'GeoErrorBoundary'
                }
            });
        });
    }

    render() {
        if (this.state.hasError) {
            return <div>Ошибка загрузки карты</div>;
        }

        return this.props.children;
    }
}

export default GeoErrorBoundary;
