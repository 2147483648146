import React from 'react';

export const IconClose: React.FC = ({ width, height }: { width?: number; height?: number }) => (
    <svg
        width={width ?? 32}
        height={height ?? 32}
        viewBox='0 0 18 18'
    >
        <path
            stroke='currentColor'
            strokeOpacity='0.92'
            strokeWidth='1.5'
            d='M1 1.00001L17 17M17 1L1 17'
        />
    </svg>
);
