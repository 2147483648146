/* eslint-disable @typescript-eslint/naming-convention */
import { SeoMapper } from '@search/router/src';

export const sortOffersMapper = new SeoMapper('sortOffersMapper', {
    '': 'unknown',
    date: 'date',
    timeToMetro: 'timeToMetro',
    room: 'room',
    floor: 'floor',
    totalArea: 'totalArea',
    price: 'price',
    date_asc: 'date_asc',
    timeToMetro_desc: 'timeToMetro_desc',
    room_desc: 'room_desc',
    floor_desc: 'floor_desc',
    totalArea_desc: 'totalArea_desc',
    price_desc: 'price_desc',
    priceSqMeter: 'priceSqMeter',
    priceSqMeter_desc: 'priceSqMeter_desc',
    distanceToCityCenter: 'distanceToCityCenter',
    updateDate_desc: 'updateDate_desc',
    updateDate: 'updateDate'
} as const);

export type SortOffersType =
    'unknown'
    | 'date'
    | 'timeToMetro'
    | 'room' | 'floor'
    | 'totalArea'
    | 'price'
    | 'date_asc'
    | 'timeToMetro_desc'
    | 'room_desc'
    | 'floor_desc'
    | 'totalArea_desc'
    | 'price_desc'
    | 'priceSqMeter'
    | 'priceSqMeter_desc'
    | 'distanceToCityCenter'
    | 'updateDate_desc'
    | 'updateDate'
    | undefined
;
