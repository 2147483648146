import React, { useCallback, useEffect, useRef } from 'react';
import { Checkbox, Dropdown, useDropdown, NumberInput, Typography } from '@vtblife/uikit';
import Icon from '@vtblife/uikit-icons';

// @ts-ignore
import classname from '@search/classname/src';
// @ts-ignore
import { AreaUnit } from '@search/filter-enums/enums/AreaUnit';
// @ts-ignore
import { formatNumber, formatRange } from '@search/helpers/src/formatNumber';
import { PredefinedPrice } from '../../../../types/PredefinedPrice';
import { MortgagePaymentFilter, PriceFilter } from '../../../../models/Filter';
import { PriceInput } from '../../../common/PriceInput/PriceInput';
import { priceTypeShortMapping } from '../../../common/PriceInput/priceTypeMapping';
import { FilterSelectButton, FilterSelectButtonBorderSide } from '../../../common/FilterSelect';
import { MINIMAL_MORTGAGE_PAYMENT, minMortgagePaymentFormatted } from '../../../../helpers/contants';
import { INumberRange } from '../../../../types/IRange';

import './DesktopPriceFilterView.css';

const cn = classname.bind(null, 'DesktopPriceFilterView');

interface IMortgagePaymentInput {
    preset?: number[];
    onChange: (filter: PriceFilter | MortgagePaymentFilter) => void;
    filter: MortgagePaymentFilter;
    priceFilter?: PriceFilter;
    mobile?: boolean;
    handlePriceType?: (priceType: AreaUnit) => void;
    toggle?: (update?: boolean) => void;
}

interface IDesktopPriceFilterViewProps {
    filter: PriceFilter;
    mortgagePaymentFilter?: MortgagePaymentFilter;
    priceTypes?: AreaUnit[];
    pricePreset?: PredefinedPrice[] | number[];
    onChange: (filter: PriceFilter | MortgagePaymentFilter) => void;
    updateFewFilters?: (filters: (PriceFilter | MortgagePaymentFilter)[]) => void;
    isLarge?: boolean;
    inputBorderSide?: FilterSelectButtonBorderSide;
}

function getAreaUnitPlaceholder(filter: PriceFilter, priceTypes: AreaUnit[]) {
    const { from, to } = filter.value;
    const priceType = filter.priceType;

    if (priceTypes.length === 1 && priceTypes[0] === AreaUnit.UNKNOWN) {
        return '';
    }

    if (priceTypes.length > 1 && (from || to)) {
        // @ts-ignore
        return priceTypeShortMapping[priceType];
    }

    return '';
}

export const DesktopPriceFilterView : React.FC<IDesktopPriceFilterViewProps> = props => {
    return (
        <Dropdown>
            <DesktopPriceFilterViewWithoutDropdown {...props} />
        </Dropdown>
    );
};

const DesktopPriceFilterViewWithoutDropdown: React.FC<IDesktopPriceFilterViewProps> = ({
    filter,
    mortgagePaymentFilter,
    pricePreset = [],
    priceTypes = [ AreaUnit.UNKNOWN ],
    onChange,
    isLarge,
    inputBorderSide = 'square',
    updateFewFilters,
}) => {
    const { toggle, opened, registerControl } = useDropdown();
    const control = useRef<HTMLButtonElement | null>(null);

    useEffect(() => {
        registerControl(control.current as HTMLElement);
    }, [ registerControl ]);

    const handleChange = useCallback((value: INumberRange) => {
        if (mortgagePaymentFilter) {
            onChange(new MortgagePaymentFilter());
        }
        onChange(new PriceFilter(filter.priceType, value));
    }, [ filter.priceType, onChange, mortgagePaymentFilter ]);
    const handleChangeMortgagePayment = useCallback((newFilter: MortgagePaymentFilter | PriceFilter) => {
        if (newFilter instanceof MortgagePaymentFilter && newFilter.payment && updateFewFilters) {
            updateFewFilters([ new PriceFilter(filter.priceType, { from: null, to: null }), newFilter ]);
        } else {
            onChange(newFilter);
        }
    }, [ filter.priceType, onChange, updateFewFilters ]);

    const handleFrom = useCallback((value: INumberRange['from']) => {
        onChange(new PriceFilter(filter.priceType, { from: value, to: filter.value.to }));
    }, [ filter.priceType, onChange, filter.value.to ]);

    const handleTo = useCallback((value: INumberRange['to']) => {
        toggle();
        onChange(new PriceFilter(filter.priceType, { from: filter.value.from, to: value }));
    }, [ filter.priceType, onChange, filter.value.from, toggle ]);

    const handlePriceType = useCallback((value: AreaUnit) => {
        onChange(new PriceFilter(value, filter.value));
    }, [ onChange, filter ]);

    const handleReset = useCallback(() => {
        if (mortgagePaymentFilter) onChange(new MortgagePaymentFilter(null));
        onChange(new PriceFilter(filter.priceType, { from: null, to: null }));
        toggle();
    }, [ filter.priceType, onChange, toggle ]);

    const { from, to } = filter.value;

    const placeholder = formatRange(
        from,
        to
    );

    const areaUnitPlaceholder = getAreaUnitPlaceholder(filter, priceTypes);

    const mortgageInput = mortgagePaymentFilter ? (<MortgagePaymentInput
        filter={mortgagePaymentFilter}
        priceFilter={filter}
        preset={pricePreset as number[]}
        onChange={handleChangeMortgagePayment}
        handlePriceType={handlePriceType}
        toggle={toggle}
    />) : null;

    return (
        <div className={cn()}>
            <FilterSelectButton
                size={isLarge ? 'l' : 'm'}
                dataTest='price-trigger'
                side={inputBorderSide}
                ref={control}
                // @ts-ignore
                onClick={toggle}
                focused={opened}
                className={cn('trigger')}
                icon={<Icon name={opened ? 'chevron-up' : 'chevron-down'} size='s' />}
            >
                {filter.priceType === AreaUnit.MORTGAGE_PAYMENT && mortgagePaymentFilter ? <span style={{ marginRight: 4 }}>
                    {mortgagePaymentFilter.payment ? `Платёж до ${formatNumber(mortgagePaymentFilter.payment)}₽ / мес.` : 'Платёж'}
                </span> : (
                    <>
                        <span style={{ marginRight: 4 }}>{placeholder ? `${placeholder} ₽` : 'Цена'}</span>
                        <span>{areaUnitPlaceholder}</span>
                    </>
                )}
            </FilterSelectButton>
            <Dropdown.Content adaptivePlacement={false}>
                <PriceInput
                    isShowReset
                    handleReset={handleReset}
                    range={filter.value}
                    priceType={filter.priceType}
                    priceTypes={priceTypes}
                    // @ts-ignore
                    pricePreset={pricePreset}
                    handlePriceType={handlePriceType}
                    handleChange={handleChange}
                    handleFrom={handleFrom}
                    handleTo={handleTo}
                    mortgageInput={mortgageInput}
                />
            </Dropdown.Content>
        </div>
    );
};

export const MortgagePaymentInput: React.FC<IMortgagePaymentInput> = ({
    preset,
    filter,
    onChange,
    mobile = false,
    handlePriceType,
    toggle
}) => {
    const handleChange = useCallback((payment: number) => {
        if (isNaN(payment)) {
            onChange(new MortgagePaymentFilter());
            handlePriceType?.(AreaUnit.UNKNOWN);
        } else {
            onChange(new MortgagePaymentFilter(payment));
        }
    }, [ onChange, handlePriceType ]);

    return (
        <div className={cn('filter.payment')}>
            <NumberInput
                value={filter.payment ?? undefined}
                onChange={handleChange}
                error={Boolean(filter.payment && filter.payment < MINIMAL_MORTGAGE_PAYMENT)}
                size='s'
                placeholder='до'
                postfix='₽ / мес.'
            />
            {filter.payment && filter.payment < MINIMAL_MORTGAGE_PAYMENT ? (
                <Typography color='link-alt-bg-hover' variant='secondary-alone' className={cn('error')}>
                    Минимальный платёж по&nbsp;ипотеке в&nbsp;вашем регионе&nbsp;&mdash; {minMortgagePaymentFormatted}&nbsp;₽/мес.
                </Typography>) :
                null
            }
            {! mobile ? <div className={cn('presets')}>
                {preset?.map(value => (
                    <div key={value}>
                        <Checkbox
                            variant='filter'
                            value={filter.payment === value}
                            onChange={(v) => {
                                toggle?.();
                                if (v) handleChange(value);
                            }}
                            block
                        >
                            {formatNumber(value)}
                        </Checkbox>
                    </div>
                ))}
            </div> : null}
        </div>
    );
};
