import React from 'react';

import { ModalHeading } from '../../../../../../mobile/components/ModalHeading';

import { GeoTagList } from '../GeoTagList';
import { FilteredItem } from '../MobileGeoFilterView';

interface IFullGeoTagListProps {
    items: FilteredItem[];
    handleRemove: (item: FilteredItem) => void;
    fullGeoTagOff?: () => void;
    dataTest?: string;
}

export const FullGeoTagList: React.FC<IFullGeoTagListProps> = ({
    items,
    dataTest,
    handleRemove,
    fullGeoTagOff
}) => {
    return (
        <>
            <ModalHeading
                text='Адреса'
                dataTest={dataTest ? `${dataTest}-heading` : undefined}
                onClose={fullGeoTagOff}
            />
            <div>
                <GeoTagList
                    items={items}
                    handleRemove={handleRemove}
                />
            </div>
        </>
    );
};
