import { RegionIdEnum } from '@search/filter-enums/enums/Region';
import { CurrencyEnum, MortgagePurpose } from '@search/graphql-typings';
import { offerRegionToMortgage } from '../../../../../domain/offer/regionToBackoffice';
import { calculateOwnAmount } from '../MinimalBanksMortgageFields/useBankMortgageForm';

function getTotalPrice({
    regionId
}: {
    regionId?: number;
}) {
    const isMsk = regionId && [
        RegionIdEnum.MSK,
        RegionIdEnum.MSK_OBL,
        RegionIdEnum.MSK_AND_MSK_OBL
    ].includes(regionId);

    return {
        value: (isMsk ? 12.5 : 5.2) * 10e7,
        currency: CurrencyEnum.Rub
    };
}

export function mortgage2ParamsFromClasified(props: {
    purpose: MortgagePurpose;
    term?: number;
    regionId?: number;
    mortgageRegionId?: number;
    propertyCost?: number;
    ownAmount?: number;
    estimatePrice?: {
        // В копейках, центах и т.д.
        value: number;
        currency: CurrencyEnum;
    };
}) {
    const estimatedPriceByRegion = getTotalPrice({ regionId: props.regionId });
    const propertyCost = props.propertyCost ?? Math.min(
        (props.estimatePrice?.value ?? estimatedPriceByRegion.value) / 100,
        50e6
    );
    const ownAmount = props.ownAmount ?? calculateOwnAmount(propertyCost, 0.2);

    return {
        purpose: props.purpose,
        regionId: props.regionId ? offerRegionToMortgage(props.regionId) : props.mortgageRegionId!,
        term: props.term ?? 20,
        propertyCost,
        ownAmount,
        currency: estimatedPriceByRegion.currency
    };
}
