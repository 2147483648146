import './style.css';

import React from 'react';

import { useStaticPathConfig } from '@search/common-core/src/StaticPathContext';
import classname from '@search/classname/src';

import Image from '../Image';
import type { MinimalBanksMortgageOffersQueryResponseOffer } from '../MinimalBanksMortgage/MinimalBanksMortgageOffers/MinimalBanksMortgageOffers';

import iconMortgageFamily from './iconMortgageFamily.url.svg';
import iconMortgagePreferential from './iconMortgagePreferential.url.svg';

const cn = classname.bind(null, 'MortgageOfferIcon');

export function MortgageOfferIcon({ name, size = 's', pseudoCount, offer }: {
    name?: string;
    size?: 's' | 'm';
} & ({
    pseudoCount: number;
    offer?: undefined;
} | {
    offer: MinimalBanksMortgageOffersQueryResponseOffer;
    pseudoCount?: undefined;
})) {
    const staticPath = useStaticPathConfig();
    const mul = size === 's' ? 1 : 1.5;
    const width = Math.floor((offer?.type ? 56 : 24) * mul);
    const height = Math.floor(24 * mul);

    if (pseudoCount) {
        return (
            <div className={cn('pseudo')} style={{ width, height }}>+{pseudoCount}</div>
        );
    }

    let imgUrl = offer?.icon?.origin ?? '';

    if (offer?.type === 'FAMILY') imgUrl = `${staticPath}${iconMortgageFamily}`;

    if (offer?.type === 'PREFERENTIAL') imgUrl = `${staticPath}${iconMortgagePreferential}`;

    if (offer?.type === 'IT_MORTGAGE') imgUrl = `${staticPath}${iconMortgagePreferential}`;

    return (
        <Image
            width={width}
            height={height}
            loading='lazy'
            alt={name ?? offer?.name ?? ''}
            url={imgUrl}
        />
    );
}
