/* global navigator, window */
import React from 'react';

import { useClassifiedOfferDataLayerPush } from '../offer/analytics/ClassifiedOfferGtmContext';

export function useShareLinksNative(p: {
    title?: string;
    shareUrl?: string;
    gtmShare?: string;
} = {}) {
    const currentUrl = p.shareUrl ?? (typeof window === 'undefined' ? '' : window.location.href);
    const isNativeShare = typeof navigator === 'undefined' ? undefined : navigator.share;
    const dataLayerPush = useClassifiedOfferDataLayerPush();
    const analyticsPush = React.useCallback((id: string) => {
        const fullId = p.gtmShare;

        if (! fullId) return;
        dataLayerPush({ event: fullId });
    }, [ p.gtmShare, dataLayerPush ]);

    const nativeShare = React.useCallback(() => {
        if (! isNativeShare) return;

        analyticsPush('native');

        navigator.share({
            title: p.title,
            url: currentUrl
        });
    }, [ ]);

    return {
        nativeShare: isNativeShare ? nativeShare : undefined
    };
}
