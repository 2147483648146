import { SeoMapper } from '@search/router/src';

export const wallsMapper = new SeoMapper('wallsMapper', {
    UNKNOWN: 'UNKNOWN',
    MONOLITH: 'MONOLITH',
    BRICK_MONOLITH: 'BRICK_MONOLITH',
    BRICK: 'BRICK',
    PANEL: 'PANEL',
    BLOCK: 'BLOCK',
    WOOD: 'WOOD'
} as const);
