import './CoreSvgSpriteIcon.css';

import React from 'react';

import classname from '@search/classname/src';

const cn = classname.bind(null, 'CoreSvgSpriteIcon');

interface IProps<T extends string> extends React.SVGProps<SVGSVGElement> {
    name: T;
    height?: number;
    width?: number;
    color?: string;
    className?: string;
    viewBoxWidth?: number;
    viewBoxHeight?: number;
}

export const CoreSvgSpriteIcon = <T extends string>({
    name,
    width = 24,
    height = 24,
    viewBoxWidth = width,
    viewBoxHeight = height,
    color,
    className,
    ...otherProps
}: IProps<T>) => {
    return (
        <svg
            height={height}
            width={width}
            className={cn(null, undefined, className)}
            color={color}
            viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
            {...otherProps}
        >
            <use href={`#${name}`} />
        </svg>
    );
};
