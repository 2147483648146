import { GroupedOptions, isGroupedOption } from '@vtblife/uikit/legacy';
import type { OptionTypeRegionFilter } from '../../filters/components/filter_types/region/RenderRegionSelectUikitContent';

const filterFlatOptionsBySubstr = (options: OptionTypeRegionFilter[], substr: string): OptionTypeRegionFilter[] => {
    const needle = substr.toLowerCase().replace('ё', 'е');

    return options.filter(
        ({ fullName }) => fullName.toLowerCase().replace('ё', 'е').indexOf(needle) + 1
    );
};

export function getFlatOptions(
    options: OptionTypeRegionFilter[] | GroupedOptions<OptionTypeRegionFilter>[]
): OptionTypeRegionFilter[] {
    if (options.length && isGroupedOption(options[0])) {
        return (options as GroupedOptions<OptionTypeRegionFilter>[]).reduce(
            (res: OptionTypeRegionFilter[], option) => [ ...res, ...option.options ],
            []
        );
    }

    return options as OptionTypeRegionFilter[];
}

export function getFilteredFlatRegionSelectOptions(
    searchInputValue: string | undefined,
    options: OptionTypeRegionFilter[] | GroupedOptions<OptionTypeRegionFilter>[]
): OptionTypeRegionFilter[] {
    const flatOptions = getFlatOptions(options);

    return searchInputValue ? filterFlatOptionsBySubstr(flatOptions, searchInputValue) : flatOptions;
}

export function getSelectedOptionLabel(
    value: string,
    options: OptionTypeRegionFilter[] | GroupedOptions<OptionTypeRegionFilter>[]
): string {
    const flatOptions = getFlatOptions(options);

    return flatOptions.find(option => option.value === value)?.label || '';
}
