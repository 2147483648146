/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';

import { Modal, Size } from '@vtblife/uikit/legacy';
import { UseAuthClosedError, UseAuthError } from '@search/auth/src/useAuth';

export type BasicError = {
    __typename: 'AuthUserIdError';
    message?: string | null;
} | {
    __typename: 'InternalError';
    message?: string | null;
} | {
    __typename: '%other';
} | {
    __typename?: string;
};

const BasicErrorContext = React.createContext({
    setError(error?: BasicError | string | undefined | null) {}
});

export function useBasicError() {
    return React.useContext(BasicErrorContext);
}

export function BasicErrorProvider(props: {
    isMobile?: boolean;
    children?: React.ReactNode;
}) {
    const [ error, setError ] = React.useState<BasicError | string | undefined | null>();
    const closeModal = React.useCallback(() => {
        setError(undefined);
    }, [ setError ]);

    let modal: React.ReactNode | null = null;

    if (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        if (! UseAuthClosedError.is(error) && ! UseAuthError.is(error)) {
            modal = (<Modal
                title='Упс...'
                isOpen
                size={Size.Small}
                closeOnOverlayClick
                onClose={closeModal}
                dataTest='any-error-modal'
            >
                Что-то пошло не так
            </Modal>);
        }
    }

    const value = React.useMemo(() => ({ setError }), [ setError ]);

    return (<BasicErrorContext.Provider value={value}>
        {modal}
        {props.children}
    </BasicErrorContext.Provider>);
}

