/* eslint-disable max-len */
import { ScriptTagAttributes } from '../Scripts';

const today = new Date();
const currentMonthStart = new Date(Date.UTC(today.getFullYear(), today.getMonth(), 1));
const nextMonthEnd = new Date(Date.UTC(today.getFullYear(), today.getMonth() + 2, 0));

export function seoLdEventScript({
    url = '',
    actionName = '',
    actionNamePrefix = '🔔 ',
    imageUrl = undefined as string | undefined,
    description = '',
    startDate = currentMonthStart,
    endDate = nextMonthEnd,
    location = {
        mainName: '',
        latitude: undefined as number | undefined,
        longitude: undefined as number | undefined,
        region: ''
    },
    organizer = {
        name: 'M2',
        url: 'https://m2.ru'
    }
} = {}): ScriptTagAttributes {
    const obj = {
        '@context': 'http://schema.org',
        '@type': 'SaleEvent',
        name: `${actionNamePrefix}${actionName}`,
        description,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        url,
        location: ! location ? undefined : {
            '@type': 'Place',
            '@context': 'http://schema.org',
            name: location.mainName,
            address: {
                '@type': 'PostalAddress',
                '@context': 'http://schema.org',
                streetAddress: location.region,
                addressLocality: location.region,
                addressRegion: location.region
            },
            geo: ! location.latitude ? undefined : {
                '@type': 'GeoCoordinates',
                '@context': 'http://schema.org',
                latitude: location.latitude,
                longitude: location.longitude
            }
        },
        image: imageUrl,
        performer: { name: location.mainName, type: 'Thing' },
        organizer: {
            name: organizer.name,
            type: 'Thing',
            url: organizer.url
        }
    };

    return {
        content: JSON.stringify(obj),
        type: 'application/ld+json'
    };
}

