/* eslint-disable @typescript-eslint/naming-convention */
import React, { Fragment, useCallback } from 'react';

import { RootRegionsApplicationEnum, SuggestTypeEnum, TipTypeEnum } from '@search/graphql-typings';
import { SuggestItemTypeEnum } from '@search/graphql-gateway/typings';

import { Input, Size } from '@vtblife/uikit/legacy';

import { useModal } from '@search/vtbeco-ui/src/components/Modal';
import classname from '@search/classname/src';

import { GeoKind } from '@search/filter-enums/enums/GeoKind';
import { RegionIdEnum, regionsWithMetroEnumeration } from '@search/filter-enums/enums/Region';
import { FilterCollection } from '@search/vtbeco-frontend-core/view/filters/models/FilterCollection';

import { Address } from '@search/vtbeco-frontend-core/domain/geo/Address';
import { GeoBaseStore, GeoFilteredItem, GeoId } from '@search/vtbeco-frontend-core/domain/geo/GeoBase';
import { correctAndBroadenRegionId, SuggestionType } from '../../../../../common/components/GeoSuggest/GeoSuggest';
import {
    Filters,
    GeoFilter,
    NewBuildingDataFilter,
    IFilter,
    RegionFilter,
    DeveloperFilter
} from '../../../../models/Filter';
import {
    AddQueryDisplayValue,
    QueryDisplayValue,
    QueryDisplayValues
} from '../../../../../common/hooks/useQueryDisplayValues';
import { dataLayerPush } from '../../../../../../domain/google/dataLayerPush';

import MobileFilter from '../../../filter/MobileFilter';
import { TipItem } from '../../../../../tips/OffersSearchTips';
import { FilterTagList } from '../../desktop/geo/FilterTagList/FilterTagList';

import { MetroModal } from './MetroModal';
import { SuggestModal } from './SuggestModal';
import { DistrictModal } from './DistrictModal';
import { PreviewGeoTagList } from './taglist/PreviewGeoTagList';

import './MobileGeoFilterView.css';

type GeoDistrictsGroupItem = {
    id: string;
    __typename?: undefined;
    title: string;
    districtIds: number[];
    kind: 'districts-group';
}

export function isGeoDistrictsGroupItem(item: FilteredItem): item is GeoDistrictsGroupItem {
    return (item as GeoDistrictsGroupItem).kind === 'districts-group';
}

export type FilteredItem = GeoFilteredItem | QueryDisplayValue | GeoDistrictsGroupItem;

interface IMobileGeoFilterViewProps {
    geoStore: GeoBaseStore;
    filters: FilterCollection;
    rootRegionsApp: RootRegionsApplicationEnum;
    onChange: (filter: IFilter[]) => void;
    onInputToggleFocus?: (disabled: boolean) => void;
    showNewBuildingsGroup?: boolean;
    startWithNewBuildings?: boolean;
    queryDisplayValues?: QueryDisplayValues;
    addQueryDisplayValue?: AddQueryDisplayValue;
    title?: string;
    titleWithNewBuildings?: string;
    placeholder?: string;
    placeholderWithNewBuildings?: string;
    limit?: number;
    tips?: QueryDisplayValue[];
    removeTips?: (tips: TipItem[]) => void;
    suggestTypes?: SuggestTypeEnum[];
    oldFilters?: boolean;
    uikitModalZIndexEnabled?: boolean;
}

const cn = classname.bind(null, 'MobileGeoFilterView');

export function isQueryDisplayValue(
    item: FilteredItem
): item is QueryDisplayValue {
    return (item as QueryDisplayValue).type !== undefined;
}

export const MobileGeoFilterView: React.FC<IMobileGeoFilterViewProps> = ({
    rootRegionsApp,
    suggestTypes,
    limit,
    geoStore: geoStoreProp,
    filters,
    onChange,
    onInputToggleFocus,
    showNewBuildingsGroup,
    startWithNewBuildings,
    queryDisplayValues,
    addQueryDisplayValue,
    title = 'Город, метро, район, адрес',
    titleWithNewBuildings = 'ЖК, город, метро, район, адрес',
    placeholder = 'Введите город, метро, район, адрес',
    placeholderWithNewBuildings = 'Введите город, метро, район, адрес, ЖК',
    tips,
    removeTips,
    oldFilters,
    uikitModalZIndexEnabled
}) => {
    const filter = filters.get(Filters.geo) as GeoFilter;
    const regionId = (filters.get(Filters.region) as RegionFilter).region;
    const geoStore = geoStoreProp;
    const { geoIds } = filter;
    const { on: districtOn, off: districtOff, visible: isDistrictVisible } = useModal();
    const { on: metroOn, off: metroOff, visible: isMetroVisible } = useModal();
    const { on: suggestOn, off: suggestOff, visible: isSuggestVisible } = useModal();
    /** District в збс, новостройки не поддерживают byRegion */
    const districts = geoStore.districts.byRegion ? geoStore.districts.byRegion(regionId) : geoStore.districts;

    const splitIds = useCallback((newGeoIds: Set<number>) => {
        const tipIds = Array.from(newGeoIds);
        const districtIds = tipIds.filter(id => geoStore.districts.has(id));
        const metroIds = tipIds.filter(id => geoStore.stations.has(id));

        return { tipIds, districtIds, metroIds };
    }, [ geoStore.districts, geoStore.stations ]);

    const { districtIds, metroIds } = splitIds(geoIds);

    const handleMetroDistrictChange = useCallback((checkedDiff: GeoId[], uncheckedDiff: Set<GeoId>) => {
        const newGeoIds = new Set<number>(filter.geoIds);

        uncheckedDiff.forEach(id => newGeoIds.delete(id));
        for (const id of checkedDiff) newGeoIds.add(id);

        const splitted = splitIds(newGeoIds);
        const {
            metroIds: metroIdList,
            districtIds: districtIdList
        } = splitted;
        const ids: {
            type: SuggestItemTypeEnum;
            idList: number[];
        }[] = [
            {
                type: SuggestItemTypeEnum.Metro,
                idList: metroIdList
            },
            {
                type: SuggestItemTypeEnum.District,
                idList: districtIdList
            }
        ];

        let newRegionId: number | undefined;

        for (const { type, idList } of ids) {
            for (const id of idList) {
                const broadenedRegionId = correctAndBroadenRegionId(id, type, regionId);

                if (broadenedRegionId !== regionId) {
                    newRegionId = broadenedRegionId;

                    break;
                }
            }

            if (newRegionId) {
                break;
            }
        }

        const newGeoFilter = new GeoFilter(
            newGeoIds,
            metroIdList,
            districtIdList,
            filter.addressIds,
            filter.newBuildingIds
        );

        onChange(newRegionId ? [
            new RegionFilter(newRegionId),
            newGeoFilter
        ] : [
            newGeoFilter
        ]);
    }, [
        splitIds,
        onChange,
        filter.geoIds,
        filter.addressIds,
        filter.newBuildingIds,
        regionId
    ]);

    const onResetMetro = useCallback(() => {
        const newGeoIds = new Set<number>();

        filter.geoIds.forEach(id => {
            if (! geoStore.stations.has(id)) newGeoIds.add(id);
        });

        const splitted = splitIds(newGeoIds);

        onChange([
            new GeoFilter(
                newGeoIds,
                splitted.metroIds,
                splitted.districtIds,
                filter.addressIds,
                filter.newBuildingIds
            )
        ]);
        metroOff();
    }, [
        filter.geoIds,
        filter.addressIds,
        filter.newBuildingIds,
        splitIds,
        onChange,
        metroOff,
        geoStore.stations
    ]);

    const onResetDistrict = useCallback(() => {
        const newGeoIds = new Set<number>();

        filter.geoIds.forEach(id => {
            if (! geoStore.districts.has(id)) newGeoIds.add(id);
        });

        const splitted = splitIds(newGeoIds);

        onChange([
            new GeoFilter(
                newGeoIds,
                splitted.metroIds,
                splitted.districtIds,
                filter.addressIds,
                filter.newBuildingIds
            )
        ]);
        districtOff();
    }, [
        filter.geoIds,
        filter.addressIds,
        filter.newBuildingIds,
        splitIds,
        onChange,
        districtOff,
        geoStore.districts
    ]);

    const onMetroSubmit = useCallback(() => {
        metroOff();
    }, [ metroOff ]);

    const onDistrictSubmit = useCallback(() => {
        districtOff();
    }, [ districtOff ]);

    const handleRemoveItem = useCallback((item: FilteredItem) => {
        const id = item.id as Exclude<FilteredItem, GeoDistrictsGroupItem>['id']; // to Number type for ignore errors in cases with string id;
        const newGeoIds = new Set(filter.geoIds);
        const newDistrictIds = new Set(filter.districtIds);

        let newMetroIds = filter.metroIds;
        let newAddressIds = filter.addressIds;
        let newNewBuildingIds = filter.newBuildingIds;

        if (isGeoDistrictsGroupItem(item)) {
            item.districtIds.forEach(districtId => {
                newGeoIds.delete(districtId);
                newDistrictIds.delete(districtId);
            });
        } else if (isQueryDisplayValue(item)) {
            if (item.type === TipTypeEnum.NewBuilding) {
                newNewBuildingIds = newNewBuildingIds.filter(newBuildingId => newBuildingId !== id);
            }

            if (item.type === TipTypeEnum.Address) {
                newAddressIds = newAddressIds.filter(addressId => addressId !== id);
            }

            newGeoIds.delete(id);
        } else {
            newGeoIds.delete(id);

            if (item.kind === GeoKind.METRO) {
                newMetroIds = newMetroIds.filter(metroId => metroId !== id);
            } else if (item.kind === GeoKind.DISTRICT || item.kind === GeoKind.LOCALITY) {
                newDistrictIds.delete(id);
            } else {
                newAddressIds = newAddressIds.filter(addressId => addressId !== id);
            }
        }

        onChange([
            new GeoFilter(newGeoIds, newMetroIds, Array.from(newDistrictIds), newAddressIds, newNewBuildingIds)
        ]);
    }, [ filter, onChange ]);

    // eslint-disable-next-line complexity
    const onSuggestChange = useCallback((suggestion: SuggestionType) => {
        let newRegionId: RegionIdEnum | undefined;

        if (
            suggestion!.data &&
            suggestion.data.regionId &&
            regionId !== suggestion!.data.regionId
        ) {
            newRegionId = suggestion.data.regionId;
        }

        const filterGeoIds: Set<number> = new Set<number>(newRegionId ? [] : filter.geoIds);
        const filterMetroIds: number[] = newRegionId ? [] : [ ...filter.metroIds ];
        const filterDistrictIds: number[] = newRegionId ? [] : [ ...filter.districtIds ];
        const filterAddressIds: number[] = newRegionId ? [] : [ ...filter.addressIds ];
        const filterNewBuildingIds: number[] = newRegionId ? [] : [ ...filter.newBuildingIds ];
        let newBuildingData;
        let developerId;

        switch (suggestion.__typename) {
            case SuggestItemTypeEnum.NewBuilding: {
                const { id } = suggestion.data;

                if (! filterNewBuildingIds.includes(id)) {
                    filterNewBuildingIds.push(id);

                    const { newBuilding } = suggestion;

                    if (newBuilding) {
                        const {
                            id: newBuildingId,
                            type: newBuildingType,
                            name: newBuildingName,
                            title: newBuildingTitle
                        } = newBuilding;

                        addQueryDisplayValue && addQueryDisplayValue('newBuildings', {
                            id: newBuildingId,
                            title: newBuildingTitle?.nominative,
                            type: TipTypeEnum.NewBuilding
                        });

                        newBuildingData = {
                            type: newBuildingType,
                            name: newBuildingName,
                            id: newBuildingId
                        };
                    }
                }

                break;
            }

            case SuggestItemTypeEnum.Developer: {
                const { developer } = suggestion;

                if (developer) {
                    const { id } = developer;

                    developerId = id;

                    addQueryDisplayValue && addQueryDisplayValue('developers', {
                        id,
                        title: developer.title,
                        type: TipTypeEnum.Developer
                    });
                }

                break;
            }

            case SuggestItemTypeEnum.District: {
                const { id } = suggestion.data;

                if (! filterDistrictIds.includes(id)) {
                    filterDistrictIds.push(id);
                    filterGeoIds.add(id);
                }

                break;
            }

            case SuggestItemTypeEnum.Metro: {
                const { id } = suggestion.data;

                if (! filterMetroIds.includes(id)) {
                    filterMetroIds.push(id);
                    filterGeoIds.add(id);
                }

                break;
            }

            case SuggestItemTypeEnum.Address: {
                const { id } = suggestion.data;

                if (! filterAddressIds.includes(id)) {
                    filterAddressIds.push(id);
                    filterGeoIds.add(id);

                    if (
                        suggestion.address
                    ) {
                        // Для обратной совместимости с текущими tips, перейти потом на displayValues
                        geoStore.addresses.add({
                            ...suggestion.address,
                            kind: GeoKind.STREET_ADDRESS
                        } as Address);

                        addQueryDisplayValue && addQueryDisplayValue('addresses', {
                            id: suggestion.address.id,
                            kind: suggestion.address.kind,
                            translit: suggestion.address.translit,
                            title: suggestion.address.displayName,
                            type: TipTypeEnum.Address
                        });
                    }
                }

                break;
            }

            default:
                return;
        }

        const newFilters: IFilter[] = [
            new GeoFilter(
                filterGeoIds,
                filterMetroIds,
                filterDistrictIds,
                filterAddressIds,
                filterNewBuildingIds
            ),
            new NewBuildingDataFilter(newBuildingData)
        ];

        if (newRegionId) {
            newFilters.push(
                new RegionFilter(newRegionId)
            );
        }

        if (developerId) {
            newFilters.push(
                new DeveloperFilter(developerId)
            );
        }

        onChange(newFilters);
        suggestOff();
    }, [
        filter.geoIds,
        filter.districtIds,
        filter.addressIds,
        filter.metroIds,
        filter.newBuildingIds,
        onChange,
        regionId,
        geoStore,
        suggestOff,
        addQueryDisplayValue
    ]);

    const shouldHaveNewBuildings = showNewBuildingsGroup && ! filter.newBuildingIds.length;
    const hasMetro = regionsWithMetroEnumeration.has(regionId) && ! geoStore.stations.isEmpty();
    const hasDistricts = geoStore?.districts?.ids?.length;

    return (
        <MobileFilter
            filterTitle={shouldHaveNewBuildings ? titleWithNewBuildings : title}
            className={cn('', { oldFilters })}
        >
            <div className={'GeoSuggest'} onClick={() => suggestOn()}>
                <div className={'col-xs-12'} data-test='filter-geo-suggest'>
                    <Input
                        fullWidth
                        size={Size.Small}
                        name='filter-geo-input'
                        // Баг ui-kit, пустое значение превращается в 'undefined'
                        value=''
                        placeholder={
                            shouldHaveNewBuildings ?
                                placeholderWithNewBuildings :
                                placeholder
                        }
                    />
                </div>
            </div>
            <div className={`container ${cn('choose')}`}>
                {
                    (hasMetro || hasDistricts) ? (
                        <span
                            className={cn('choose-geo-item')}
                        >
                            Выбрать:&nbsp;
                        </span>
                    ) : null
                }
                {
                    hasMetro ? (
                        <Fragment>
                            <span
                                className={cn('geo-item')}
                                onClick={() => {
                                    metroOn();
                                    dataLayerPush({ event: 'classified_filter_metro' });
                                }}
                                data-test='filter-geo-metro-popup'
                            >
                                метро
                            </span>
                            { hasDistricts ? <span>, &nbsp;</span> : null }
                        </Fragment>
                    ) : null
                }
                {
                    hasDistricts ? (
                        <span
                            className={cn('geo-item')}
                            data-test='filter-geo-district-popup'
                            onClick={() => {
                                districtOn();
                                dataLayerPush({ event: 'classified_filter_district' });
                            }}
                        >
                            районы
                        </span>
                    ) : null
                }
            </div>
            {
                tips ? (
                    <FilterTagList
                        tips={tips}
                        filters={filters}
                        openMetroModal={metroOn}
                        removeTips={removeTips}
                    />
                ) : (
                    <PreviewGeoTagList
                        filter={filter}
                        store={geoStore}
                        regionId={regionId}
                        handleRemove={handleRemoveItem}
                        queryDisplayValues={queryDisplayValues}
                    />
                )
            }
            {
                isSuggestVisible ? (
                    <SuggestModal
                        regionId={regionId}
                        hide={suggestOff}
                        rootRegionsApp={rootRegionsApp}
                        limit={limit}
                        onInputToggleFocus={onInputToggleFocus}
                        handleChange={onSuggestChange}
                        showNewBuildingsGroup={shouldHaveNewBuildings}
                        startWithNewBuildings={startWithNewBuildings}
                        placeholder={placeholder}
                        placeholderWithNewBuildings={placeholderWithNewBuildings}
                        suggestTypes={suggestTypes}
                        uikitZIndexEnabled={uikitModalZIndexEnabled}
                    />
                ) : null
            }
            {
                isDistrictVisible ? (
                    <DistrictModal
                        regionId={regionId}
                        hide={districtOff}
                        checkedIds={districtIds}
                        districts={districts}
                        handleChange={handleMetroDistrictChange}
                        onReset={onResetDistrict}
                        onSubmit={onDistrictSubmit}
                        uikitZIndexEnabled={uikitModalZIndexEnabled}
                    />
                ) : null
            }
            {
                isMetroVisible ? (
                    <MetroModal
                        hide={metroOff}
                        checkedIds={metroIds}
                        store={geoStore.stations}
                        handleChange={handleMetroDistrictChange}
                        onReset={onResetMetro}
                        onSubmit={onMetroSubmit}
                        uikitZIndexEnabled={uikitModalZIndexEnabled}
                    />
                ) : null
            }
        </MobileFilter>
    );
};
