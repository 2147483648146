import React from 'react';

export type ImageSliderAnimateProps = {
    /**
     * Айтем или весь контейнер, если был задан containerCrossSwipeEnabled
     */
    el: HTMLElement;

    /**
     * True, если вертикальное размещение айтемов в свайпе (ландшафтная ориентация экрана)
     */
    isVertical: boolean;

    /**
     * Если isVertical true - смещение по x, иначе - по y
     */
    touchShift: number;
}

/**
 * Анимация кросс-свайпа.
 * Возвращает true, если считает, что на onTouchEnd надо выполнить экшен onCrossSwipeFwd или onCrossSwipeBack
 */
export type ImageSliderAnimate = (props: ImageSliderAnimateProps) => boolean;

export type ImageSliderBaseProps<Key extends React.Key> = {
    /**
     * True, если вертикальное размещение айтемов в свайпе (ландшафтная ориентация экрана)
     */
    isVertical?: boolean;

    /**
     * Действие на прямой (слева направо, сверху вниз) свайп, перпендикулярный скролл-свайпу
     * Если не заданно, прямой кросс-свайп не будет анимироваться.
     *
     * Если isVertical true - выполнится в конце свайпа вправо
     * Если isVertical false - выполнится в конце свайпа вниз
     */
    onCrossSwipeFwd?: (key: Key, e: React.TouchEvent<HTMLElement>) => void;

    /**
     * Действие на обратный (справа налево, снизу вверх) свайп, перпендикулярный скролл-свайпу
     * Если не заданно, обратный кросс-свайп не будет анимироваться.
     *
     * Если isVertical true - выполнится в конце свайпа влево
     * Если isVertical false - выполнится в конце свайпа вверх
     */
    onCrossSwipeBack?: (key: Key, e: React.TouchEvent<HTMLElement>) => void;

    /**
     * Анимация кросс-свайпа.
     * Возвращает true, если считает, что на onTouchEnd надо выполнить экшен onCrossSwipeFwd или onCrossSwipeBack
     */
    crossSwipeAnimate?: ImageSliderAnimate;
}

/**
 * Упрощает построение свайпов, где основной свайп скроллит, а перпендикулярный занят под альтернативные действия.
 */
export function useImageSliderCross<
    ContainerEl extends HTMLElement, ItemEl extends HTMLElement, Key extends React.Key
>({
    onCrossSwipeFwd,
    onCrossSwipeBack,
    containerRef,
    isVertical = false,
    itemRefs,
    crossSwipeAnimate = imageSliderCrossSwipeAnimateDefault
}: ImageSliderBaseProps<Key> & {
    itemRefs: React.RefObject<Map<Key, ItemEl>>;
    containerRef?: React.RefObject<ContainerEl>;
}) {
    const touchPosStartRef = React.useRef({ x: 0, y: 0 });
    const touchShiftEndRef = React.useRef(0);

    const getItemElement = React.useCallback((key: Key) => {
        return containerRef?.current ?? itemRefs.current?.get(key);
    }, [ itemRefs, containerRef ]);

    const onTouchStart = React.useCallback((e: React.TouchEvent<ItemEl>) => {
        const event = e.touches.length ? e.touches[0] : undefined;

        if (! event) return;

        touchShiftEndRef.current = 0;
        touchPosStartRef.current = { x: event.clientX, y: event.clientY };
    }, [ ]);

    const onTouchEnd = React.useCallback((key: Key, e: React.TouchEvent<ItemEl>) => {
        const touchShift = touchShiftEndRef.current;

        if (touchShift < 0 && onCrossSwipeBack) {
            return onCrossSwipeBack(key, e);
        }
        if (touchShift > 0 && onCrossSwipeFwd) {
            return onCrossSwipeFwd(key, e);
        }

        const el = getItemElement(key);

        if (! el) return;

        crossSwipeAnimate({
            el,
            isVertical,
            touchShift
        });
    }, [ getItemElement, onCrossSwipeFwd, onCrossSwipeBack, crossSwipeAnimate, isVertical ]);

    const onTouchMove = React.useCallback((key: Key, e: React.TouchEvent<ItemEl> & React.ChangeEvent<ItemEl>) => {
        const el = getItemElement(key);

        if (! el) return;

        const event = e.touches.length > 0 ? e.touches[0] : undefined;

        if (! event) return;

        const shift = {
            x: Math.floor(event.clientX - touchPosStartRef.current.x),
            y: Math.floor(event.clientY - touchPosStartRef.current.y)
        };

        // Если начали скролл-свайпить, то кросс-свайп не должен анимироваться
        if (! isVertical && Math.abs(shift.x) > 10) return;
        if (isVertical && Math.abs(shift.y) > 10) return;

        let touchShift = isVertical ? shift.x : shift.y;

        // Если не задали экшен на обратный кросс-свайп, то не анимируем его
        if (touchShift > 0 && onCrossSwipeBack) touchShift = 0;
        // Если не задали экшен на прямой кросс-свайп, то не анимируем его
        if (touchShift < 0 && onCrossSwipeFwd) touchShift = 0;

        const isActionTriggered = crossSwipeAnimate({
            el,
            isVertical,
            touchShift
        });

        touchShiftEndRef.current = isActionTriggered ? touchShift : 0;
    }, [ getItemElement, isVertical, crossSwipeAnimate, onCrossSwipeBack, onCrossSwipeFwd ]);

    return {
        onTouchStart,
        onTouchEnd,
        onTouchMove
    };
}

function imageSliderCrossSwipeAnimateDefault(
    {
        el,
        isVertical,
        touchShift
    }: ImageSliderAnimateProps
) {
    const shiftAbs = Math.abs(touchShift);

    if (shiftAbs < 20) touchShift = 0;

    const x = ! isVertical ? 0 : touchShift;
    const y = isVertical ? 0 : touchShift;

    const dims = el.getBoundingClientRect();
    const length = isVertical ? dims.right - dims.left : dims.bottom - dims.top;
    const opacity = Math.min(1, Math.max(0, 1 - 0.7 * (shiftAbs / length)));

    el.style.transform = `translate(${x}px, ${y}px)`;
    el.style.opacity = `${opacity.toFixed(3)}`;

    return shiftAbs > 30;
}
