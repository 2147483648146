import React, { useCallback } from 'react';
import { ParkingType } from '@search/filter-enums/enums';
import { ParkingFilter } from '../../../../models/Filter';
import MobileFilter from '../../../filter/MobileFilter';
import '../MobileCommon.css';
import { MultiSelect, MultiSelectOption } from '../../common/MultiSelect';

interface IMobileParkingFilterViewProps {
    filter: ParkingFilter;
    handleChange: (filter: ParkingFilter) => void;
}

const selectOptions: MultiSelectOption[] = [ {
    value: ParkingType.CLOSED,
    label: 'Закрытая',
    className: 'col-xs-12 filter-item-gap'
}, {
    value: ParkingType.UNDERGROUND,
    label: 'Подземная',
    className: 'col-xs-12 filter-item-gap'
}, {
    value: ParkingType.OPEN,
    label: 'Открытая',
    className: 'col-xs-12 filter-item-gap'
} ];

export const MobileParkingFilterView: React.FC<IMobileParkingFilterViewProps> = ({
    filter,
    handleChange
}) => {
    const onChange = useCallback(value => {
        handleChange(new ParkingFilter(value));
    }, [ handleChange ]);

    return (
        <MobileFilter
            filterTitle='Парковка'
        >
            <MultiSelect
                name='parkingEnumeration'
                className='container container__clamp'
                options={selectOptions}
                value={filter.parkings}
                handleChange={onChange}
            />
        </MobileFilter>
    );
};
