/* eslint-disable @typescript-eslint/naming-convention */
import { SeoMapper } from '@search/router/src/schema/SeoMapper';
import { BuildingClass } from '@search/filter-enums/enums/BuildingClass';
import { WallsTypeString } from '@search/filter-enums/enums/WallsType';
import { FloorConstraintsString } from '@search/filter-enums/enums/FloorConstraints';
import { FinishingEnum, FinishingTypeEnum, FinishingFlatEnum } from '@search/filter-enums/gql/schema-types-fix';
import { IsApartmentsOptionsString } from '@search/filter-enums/enums/IsApartmentsOptions';
import { ParkingTypeString } from '@search/filter-enums/enums/ParkingType';
import { CeilingHeightString } from '@search/filter-enums/enums/CeilingHeight';
import { FlatsSpecialEventString } from '@search/filter-enums/enums/SpecialEvents';

export const YES = 'YES';

export const roomMapper = new SeoMapper('roomsMapper', {
    studii: 'STUDIO',
    '1-komnatnye': 'ROOM_1',
    '2-komnatnye': 'ROOM_2',
    '3-komnatnye': 'ROOM_3',
    '4-komnatnye': 'ROOM_4',
    '5-komnatnye-i-bolee': 'ROOM_5_AND_MORE',
    'so-svobodnoy-planirovkoy': 'OPEN_PLAN'
} as const);

export const quarterMapper = new SeoMapper('quarterMapper', {
    UNKNOWN: 0,
    FIRST: 1,
    SECOND: 2,
    THIRD: 3,
    FOURTH: 4
} as const);

export const finishingFlatMapper = new SeoMapper('finishingFlatMapper', {
    '': FinishingFlatEnum.NotRelevant,
    YES: FinishingFlatEnum.Available,
    NO: FinishingFlatEnum.NotAvailable
} as const);

// eslint-disable-next-line max-len
export const sOtdelkoySeoValue = [ FinishingTypeEnum.Fine, FinishingTypeEnum.PreFinishing, FinishingTypeEnum.WithFurniture ];

export const finishingMapper = new SeoMapper('finishingMapper', {
    '': FinishingEnum.NotRelevant,
    's-otdelkoy': FinishingEnum.Available,
    'bez-otdelki': FinishingEnum.NotAvailable // оставляем, т.к. такое ЧПУ ещё существует и нам нужно учитывать этот ключ
} as const);

export const finishingTypeMapper = new SeoMapper('finishingTypeMapper', {
    chistovaya: FinishingTypeEnum.Fine,
    's-mebelyu': FinishingTypeEnum.WithFurniture,
    'pod-klyuch': FinishingTypeEnum.Fine, // заменяем на чистовую, т.к. такого фильтра больше нет, но ЧПУ может встречаться
    '': FinishingTypeEnum.PreFinishing,
    'bez-otdelki': FinishingTypeEnum.WithoutFinishing // для ts
} as const);

export const buildingClassMapper = new SeoMapper('buildingClassMapper', {
    biznes: BuildingClass.BUSINESS,
    komfort: BuildingClass.COMFORT,
    'komfort-plus': BuildingClass.COMFORT_PLUS,
    ekonom: BuildingClass.ECONOMY,
    elitnyy: BuildingClass.ELITE
});

export const wallsTypeMapper = new SeoMapper('wallsTypeMapper', {
    kirpichnye: WallsTypeString.BRICK,
    monolitnye: WallsTypeString.MONOLITH,
    'kirpichno-monolitnye': WallsTypeString.BRICK_MONOLITH,
    panelnye: WallsTypeString.PANEL,
    blochnye: WallsTypeString.BLOCK
});

export const floorLastMapper = new SeoMapper('floorLastMapper', {
    'na-poslednem-etazhe': FloorConstraintsString.ONLY,
    'ne-na-poslednem-etazhe': FloorConstraintsString.EXCLUDE
});

export const law214Mapper = new SeoMapper('law214Mapper', {
    '214-fz': YES
});

export const escrowMapper = new SeoMapper('escrowMapper', {
    'eskrou-scheta': YES
});

export const armyMortgageMapper = new SeoMapper('armyMortgageMapper', {
    'voennaya-ipoteka': YES
});

export const installmentMapper = new SeoMapper('installmentMapper', {
    'v-rassrochku': YES
});

export const commissioningMapper = new SeoMapper('commissioningMapper', {
    sdan: 'COMMISSIONED',
    stroitsya: 'IN_PROGRESS',
    lyuboy: 'UNKNOWN'
});

export const newBuildingTypes = [ 'zhk', 'zhiloi-raion', 'apart-kompleks', 'apart-otel', 'kvartal',
    'loft', 'mfk', 'rezidentsiya', 'mikroraion', 'dom', 'klubnii-dom' ];
export const newBuildingTypeMapper = new SeoMapper('newBuildingTypeMapper', {
    zhk: 'zhk',
    'zhiloi-raion': 'zhiloi-raion',
    'apart-kompleks': 'apart-kompleks',
    'apart-otel': 'apart-otel',
    kvartal: 'kvartal',
    loft: 'loft',
    mfk: 'mfk',
    rezidentsiya: 'rezidentsiya',
    mikroraion: 'mikroraion',
    dom: 'dom',
    'klubnii-dom': 'klubnii-dom'
} as const);

export const developerAppLandingMapper = new SeoMapper('developerAppLandingMapper', {
    'samolet-app': 'samolet',
} as const);

export const developerLandingMapper = new SeoMapper('developerLandingMapper', {
    samolet: 114,
} as const);

export const priceMaxMapper = new SeoMapper('priceMaxMapper', {
    'do-1-mln-rub': 1e6,
    'do-1,5-mln-rub': 1.5e6,
    'do-2-mln-rub': 2e6,
    'do-3-mln-rub': 3e6,
    'do-3,5-mln-rub': 3.5e6,
    'do-4-mln-rub': 4e6,
    'do-5-mln-rub': 5e6,
    'do-6-mln-rub': 6e6,
    'do-7-mln-rub': 7e6,
    'do-8-mln-rub': 8e6,
    'do-10-mln-rub': 10e6,
    'do-15-mln-rub': 15e6,
    'do-20-mln-rub': 20e6
});

export const pricePerMeterMaxMapper = new SeoMapper('pricePerMeterMaxMapper', {
    '100000-rub-za-m2': 100e3,
    '150000-rub-za-m2': 150e3,
    '200000-rub-za-m2': 200e3,
    '250000-rub-za-m2': 250e3
});

export const cheapMapper = new SeoMapper('cheapMapper', {
    deshevyye: 8e6
});

export const closeToMetroMapper = new SeoMapper('closeToMetroMapper', {
    'ryadom-s-metro': 'FOOT|MAX_20_MINUTES'
});

export const closedAreaMapper = new SeoMapper('closedAreaMapper', {
    's-zakrytoj-territoriej': YES
});

export const conciergeMapper = new SeoMapper('conciergeMapper', {
    's-konserzhem': YES
});

export const balconyMapper = new SeoMapper('balconyMapper', {
    's-balkonom': YES
});

export const motherCapitalMapper = new SeoMapper('motherCapitalMapper', {
    'materinskij-kapital': YES
});

export const parkingMapper = new SeoMapper('parkingMapper', {
    'parkovka-podzemnaya': ParkingTypeString.UNDERGROUND,
    's-parkovkoi': ParkingTypeString.AVAILABLE,
    'parkovka-zakrytaya': ParkingTypeString.CLOSED,
    'parkovka-otkrytaya': ParkingTypeString.OPEN
});

export const apartmentsMapper = new SeoMapper('apartmentsMapper', {
    apartamenty: IsApartmentsOptionsString.APARTMENTS_ONLY,
    'bez-apartamentov': IsApartmentsOptionsString.NO_APARTMENTS
});

export const ceilingHeightMapper = new SeoMapper('ceilingHeightMapper', {
    'vysokie-potolki': CeilingHeightString.FROM_3
});

export const kitchenAreaRangeMinMapper = new SeoMapper('kitchenAreaRangeMinMapper', {
    'bolshaya-kukhnya': 15
});

export const euroPlanningMapper = new SeoMapper('euroPlanningMapper', {
    evrodvushki: 'ROOM_1|17',
    evrotreshki: 'ROOM_2|17'
});

export const flatsSpecialEventMapper = new SeoMapper('flatsSpecialEventMapper', {
    'po-skidke': FlatsSpecialEventString.FLAT_DISCOUNT,
    'na-starte-prodazh': FlatsSpecialEventString.FLAT_SALES_START
});
