import { graphql } from '@search/gql-client/src';

const customerFeedbackMutation = graphql`
    mutation CustomerFeedbackMutation(
        $rating: Int!,
        $message: String!,
        $applicationId: ApplicationIdEnum!,
        $complexityOptions: [String!],
        $contact: String,
        $usability: CustomerFeedbackAnswer,
        $infoFullness: CustomerFeedbackAnswer,
        $extraFeatures: String,
        $searchSuccess: String
    ) {
        sendCustomerFeedback(
            rating: $rating,
            message: $message,
            applicationId: $applicationId,
            complexityOptions: $complexityOptions,
            contact: $contact,
            usability: $usability,
            infoFullness: $infoFullness,
            extraFeatures: $extraFeatures,
            searchSuccess: $searchSuccess
        ) {
            status
        }
    }
`;

export default customerFeedbackMutation;
