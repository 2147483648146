import React from 'react';
import capitalize from 'lodash/capitalize';
import { Select, Dropdown, useMultipleSelect } from '@vtblife/uikit';
import { DropdownContentProps, DropdownControlProps } from '@vtblife/uikit/dist/components/dropdown/types';

type UikitSlimMultiSelectProps<T extends string | number> =
    Pick<DropdownContentProps, 'height' | 'adaptiveHeight' | 'adaptivePlacement' | 'dataTest' | 'placement'> &
    Pick<DropdownControlProps, 'size' | 'disabled'> &
    {
        onChange: (value: T[]) => void;
        options: { value: T; label: string; disabled?: boolean }[];
        value?: T[];
        placeholder: string;

        forcePlaceholder?: boolean;
    }

export const SlimMultiSelect = <T extends number | string>({
    value,
    onChange,
    options,
    placeholder,
    forcePlaceholder,

    disabled,
    size,

    height,
    adaptiveHeight,
    adaptivePlacement,
    placement = 'local',
    dataTest,
}: UikitSlimMultiSelectProps<T>) => {
    const { is, handleChange, selectedText } = useMultipleSelect<T>(
        (v: T[]) => onChange(v),
        {
            texts: options.map(el => ({ ...el, text: el.label })),
            selected: value
        }
    );
    const title = (forcePlaceholder || ! selectedText.length) ? placeholder : capitalize(selectedText);

    return (<div>
        <Select
            onChange={handleChange}
            dataTest={dataTest}
            mode='multiple'
        >
            <Dropdown.Control
                disabled={disabled}
                arrow
                size={size}
                dataTest={dataTest && (dataTest + '-trigger')}
            >{title}</Dropdown.Control>
            <Select.Content
                height={height}
                adaptiveHeight={adaptiveHeight}
                adaptivePlacement={adaptivePlacement}
                placement={placement}
            >
                {options.map((item, key) => (
                    <Select.Option
                        value={item.value}
                        key={key}
                        selected={is(item.value)}
                        disabled={item.disabled}
                    >
                        {item.label}
                    </Select.Option>
                ))}
            </Select.Content>
        </Select>
    </div>);
};
