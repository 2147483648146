export enum ClassifiedAnalytics {
    // Затрекать показ сниппета
    SearchSaleViewOffer = 'classified_serp_sale_view_offer',
    SearchSaleViewPaidOffer = 'classified_serp_sale_view_paid_offer',
    SearchSaleViewHighlightOffer = 'classified_serp_sale_view_highlight_offer',
    SearchRentViewOffer = 'classified_serp_rent_view_offer',
    SearchRentViewPaidOffer = 'classified_serp_rent_view_paid_offer',
    SearchRentViewHighlightOffer = 'classified_serp_rent_view_highlight_offer',

    // Показать телефон на серпе вторичка
    SearchSaleShowTel = 'classified_serp_sale_show_tel',
    SearchSalePaidShowTel = 'classified_serp_sale_paid_show_tel',
    SearchRentShowTel = 'classified_serp_rent_show_tel',

    // Показать телефон на серпе новостройки
    SearchSaleNewShowTel = 'classified_serp_newsale_show_tel',
    SearchSaleFromDevShowTel = 'classified_serp_newsale_dev_show_tel',
    SearchSaleFromDevPaidShowTel = 'classified_newsale_paid_show_tel',
    SearchRentNewShowTel = 'classified_serp_newrent_show_tel',

    // Показать телефон на серпе: оффер c VAS - Highlight
    SearchSaleHighlightShowTel = 'classified_serp_sale_highlight_show_tel',

    // Баннеры
    CardSaleIpotekaBannerSend = 'classified_card_sale_ipoteka_banner_promo',
    CardSaleIpotekaBannerShow = 'classified_card_sale_view_ipoteka_banner_promo',
    SearchSaleIpotekaBannerSend = 'classified_serp_sale_ipoteka_banner_promo',
    SearchSaleIpotekaBannerShow = 'classified_serp_sale_view_ipoteka_banner_promo',
    SearchBanner = 'classified_serp_nb_promo_banner',

    // Промо-баннеры на карточке оффера
    PromoBannerCardTop = 'classified_card_sp_banner_top',
    PromoBannerCardBottom = 'classified_card_sp_banner_bottom',

    // Показать телефоны в оффере, вторичка, верх
    SearchCardSaleShowTelTop = 'classified_card_sale_show_tel_top',
    SearchCardSalePaidShowTelTop = 'classified_card_sale_paid_show_tel_top',
    SearchCardRentShowTelTop = 'classified_card_rent_show_tel_top',

    // Показать телефоны в оффере, вторичка, низ
    SearchCardSaleShowTelBottom = 'classified_card_sale_show_tel_bottom',
    SearchCardSalePaidShowTelBottom = 'classified_card_sale_paid_show_tel_bottom',
    SearchCardRentShowTelBottom = 'classified_card_rent_show_tel_bottom',

    // Показать телефоны в оффере, новостройки, верх
    SearchCardSaleNewShowTelTop = 'classified_new_card_sale_show_tel_top',
    SearchCardSaleFromDevShowTelTop = 'classified_new_card_sale_dev_show_tel_top',
    SearchCardRentNewShowTelTop = 'classified_new_card_rent_show_tel_top',

    // Показать телефоны в оффере, новостройки, низ
    SearchCardSaleNewShowTelBottom = 'classified_new_card_sale_show_tel_bottom',
    SearchCardSaleFromDevShowTelBottom = 'classified_new_card_sale_dev_show_tel_bottom',
    SearchCardRentNewShowTelBottom = 'classified_new_card_rent_show_tel_bottom',

    // Показать телефоны в оффере, новостройки, без разницы
    SearchCardSaleFromDevPaidShowTel = 'classified_newsale_paid_show_tel',

    // Добавить в избранное квартиру, продажа
    SearchCardSaleFlatAddFavorites = 'classified_serp_sale_flat_add_favorites',

    // Открыть из серпа оффер c VAS - Highlight
    SaleOpenHighlightOffer = 'classified_sale_open_highlight_offer',

    // Открыть оффер квартиры, новостройка
    SaleFlatOpenNewOffer = 'classified_sale_flat_open_new_offer',
    SaleFlatOpenNewDevOffer = 'classified_sale_flat_open_new_dev_offer',
    RentFlatOpenNewOffer = 'classified_rent_flat_open_new_offer',

    // Открыть оффер квартиры, вторичка
    SaleFlatOpenSecondOffer = 'classified_sale_flat_open_second_offer',
    RentFlatOpenSecondOffer = 'classified_rent_flat_open_second_offer',

    // Открыть васовый оффер, вторичка
    SaleOpenPaidOffer = 'classified_sale_open_paid_offer',

    // Открыть не васовый оффер, вторичка
    SaleOpenSecondOffer = 'classified_sale_open_second_offer',

    // @deprecated use SearchSaleIpotekaBanner via dataLayer
    IpotekaFromOffer = 'ipoteka_from_offer',
}

export enum AnalyticsFromTags {
    SpecProjectCardCL = 'from=SpecProjectcardCL'
}
