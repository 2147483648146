import React, { useState, useCallback, useRef, useEffect } from 'react';
import isEqual from 'lodash/isEqual';

import { Button, LinkButton } from '@vtblife/uikit';
import { LinkButtonProps } from '@vtblife/uikit/dist/components/link/types';

import { formatPhone } from '@search/helpers/src/formatPhone';
import { NewBuildingWhitelabelEnum } from '@search/graphql-typings';
import { useManualGql, graphql } from '@search/gql-client/src/useGql';

import { DATA_GTM } from '../../../libs/constants';
import { NewBuildingAnalytics } from '../../../analytics/NewBuildingAnalytics';
import { useCalltouchReplacement } from '../../../hooks/useCalltouchReplacement';
import { dataLayerPush } from '../../../../google/dataLayerPush';

import type {
    SnippetPhoneQuery$data as SnippetPhoneQueryResponse,
    SnippetPhoneQuery$variables as SnippetPhoneQueryVariables
} from './__generated__/SnippetPhoneQuery.graphql';

type PhoneParamsType = {
    newBuildingId: number;
    developerId: number;
    phoneNum: number;
    whitelabel?: NewBuildingWhitelabelEnum;
}

interface IPhoneProps {
    phoneParams: PhoneParamsType;
    isMobile?: boolean;
    render?(props: {
        href?: string;
        handleClick: Function;
        phone: string;
        loading: boolean;
    }): React.ReactElement;
    onPhoneEventPush?: () => void;
    onMap?: boolean;
    calltouchId?: string;
    fullWidth?: LinkButtonProps['fullWidth'];
    variant?: LinkButtonProps['variant'];
    size?: LinkButtonProps['size'];
    gtm?: LinkButtonProps['gtm'];
}

type ButtonDataType = {
    href?: string;
    text: string;
    loading: boolean;
};

const DEFAULT_BUTTON_TEXT = 'Показать телефон';

const SNIPPET_PHONE_QUERY = graphql`
    query SnippetPhoneQuery(
        $developerId: Int!,
        $newBuildingId: Int!,
        $phoneNum: Int!,
        $whitelabel: NewBuildingWhitelabelEnum
    ) {
        newBuildingPhone(
            phoneNum: $phoneNum
            newBuildingId: $newBuildingId
            developerId: $developerId
            whiteLabel: $whitelabel
        ) {
            formattedNumber
            isReplaceable
        }
    }
`;

const SnippetPhone = ({
    onPhoneEventPush,
    phoneParams,
    render,
    onMap,
    isMobile,
    calltouchId,
    ...buttonProps
}: IPhoneProps) => {
    const [ phoneFromGql, setPhoneFromGql ] = useState<{
        formattedNumber: string;
        isReplaceable: boolean;
    }>({
        formattedNumber: '',
        isReplaceable: false
    });
    const [ originalPhone, setOriginalPhone ] = useState<string>('');
    const [ buttonData, setButtonData ] = useState<ButtonDataType>({
        href: undefined,
        text: DEFAULT_BUTTON_TEXT,
        loading: false
    });

    const phoneParamsRef = useRef<PhoneParamsType>();

    useEffect(() => {
        if (! isEqual(phoneParamsRef.current, phoneParams)) {
            phoneParamsRef.current = phoneParams;
        }
    }, [ phoneParams ]);

    const { load } = useManualGql<SnippetPhoneQueryVariables, SnippetPhoneQueryResponse>(SNIPPET_PHONE_QUERY);

    const makeCall = useCallback((phoneURL?: string) => {
        const href = phoneURL || buttonData.href;

        href && window.open(href, '_self');
    }, [ buttonData.href ]);

    const handleClick = useCallback(() => {
        if (! phoneFromGql.formattedNumber) {
            setButtonData(prevState => ({
                ...prevState,
                loading: true
            }));

            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            load(phoneParams).then(data => {
                if (data?.newBuildingPhone) {
                    setPhoneFromGql(data?.newBuildingPhone);
                }
            });

            onPhoneEventPush && onPhoneEventPush();

            return;
        }

        isMobile && makeCall();
    }, [ onPhoneEventPush, phoneFromGql.formattedNumber, isMobile, makeCall, load, phoneParams ]);

    useEffect(() => {
        if (phoneFromGql.formattedNumber) {
            let original = phoneFromGql.formattedNumber.replace(/\s/g, '');

            original = original.substring(original.length - 10);

            setOriginalPhone(original);
        }
    }, [ calltouchId, phoneFromGql.formattedNumber ]);

    const { calltouchPhone, hasTriedReplace, replaceError } = useCalltouchReplacement({
        originalPhone,
        ctId: calltouchId
    });

    useEffect(() => {
        if ((hasTriedReplace || ! calltouchId) && originalPhone && buttonData.text === DEFAULT_BUTTON_TEXT) {
            const phone = calltouchPhone || originalPhone;
            const href = `tel:+7${phone}`;

            setButtonData(prevState => ({
                ...prevState,
                href,
                text: formatPhone(`8${phone}`, { skipSymbols: true, separateLast: true }),
                loading: false
            }));

            isMobile && makeCall(href);
        }
    }, [
        isMobile, calltouchId, calltouchPhone, originalPhone, buttonData.text, hasTriedReplace, makeCall
    ]);

    useEffect(() => {
        if (calltouchPhone) {
            dataLayerPush({
                event: NewBuildingAnalytics.NewBuildingReplacePhone,
                'phone-origin': originalPhone,
                'phone-replace': calltouchPhone
            });
        }
    }, [ calltouchPhone, originalPhone ]);

    useEffect(() => {
        if (replaceError !== 'NONE') {
            dataLayerPush({
                event: NewBuildingAnalytics.NewBuildingReplacePhoneFail,
                'phone-origin': originalPhone
            });
        }
    }, [ replaceError, originalPhone ]);

    useEffect(() => {
        if (hasTriedReplace) {
            dataLayerPush({
                event: NewBuildingAnalytics.NewBuildingReplacePhoneTry,
                replaceable: phoneFromGql.isReplaceable,
                replaced: Boolean(calltouchPhone),
                'replace-error': replaceError,
                'phone-origin': originalPhone,
                ...(calltouchPhone ? {
                    'phone-replace': calltouchPhone
                } : {})
            });
        }
    }, [ hasTriedReplace, calltouchPhone, phoneFromGql.isReplaceable, originalPhone ]);

    if (render) {
        return render({
            handleClick,
            href: buttonData.href,
            phone: buttonData.text,
            loading: buttonData.loading
        });
    }

    const commonButtonProps = {
        size: buttonProps.size ?? 's',
        onClick: handleClick,
        href: buttonData.href,
        gtm: onPhoneEventPush || phoneFromGql.formattedNumber ? undefined : (buttonProps.gtm ?? DATA_GTM.SNIPPET_PHONE),
        fullWidth: buttonProps.fullWidth,
        variant: buttonProps.variant ?? 'primary',
        loading: buttonData.loading
    };

    return isMobile ? (
        <Button
            {...commonButtonProps}
            variant={buttonProps.variant ?? 'primary'}
        >
            Позвонить
        </Button>
    ) : (
        <LinkButton
            {...commonButtonProps}
            href={buttonData.href}
        >
            {buttonData.text}
        </LinkButton>
    );
};

export default SnippetPhone;
