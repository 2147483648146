import React from 'react';

import classname from '@search/classname/src';

import { Badge, IBadgeProps } from '..';

import './styles.css';

const cn = classname.bind(null, 'GalleryBadge');

export const GalleryBadge = ({
    children,
    ...props
}: IBadgeProps) => (
    <Badge
        size='m'
        level='primary-alone'
        className={cn()}
        bold
        {...props}
    >
        {children}
    </Badge>
);
