import type { ApplicationIdEnum } from '@search/graphql-typings';

import type { CustomerFeedbackMutation$variables } from '../../../__generated__/CustomerFeedbackMutation.graphql';

import {
    EXTRA_FEATURES_OPTIONS,
    FeedbackOption,
    INFO_FULLNESS_DETAILS_OPTIONS,
    INFO_FULLNESS_OPTIONS,
    InfoFullnessDetail,
    SEARCH_SUCCESS_OPTIONS,
    USABILITY_DETAILS_OPTIONS,
    USABILITY_OPTIONS,
    UsabilityDetail
} from './dict';
import type { IFeedbackFormState } from './state';

export const buildCustomerFeedbackVars = (
    params: IFeedbackFormState,
    applicationId: ApplicationIdEnum
): CustomerFeedbackMutation$variables => {
    const usabilityText = ! params.usability.value ? '' : (
        params.usability.value === FeedbackOption.YES ? USABILITY_OPTIONS[FeedbackOption.YES] : `${
            USABILITY_OPTIONS[FeedbackOption.NO]
        }${
            params.usability.details.length ? `: ${
                params.usability.details
                    .filter(item => item !== UsabilityDetail.OTHER)
                    .map(item => USABILITY_DETAILS_OPTIONS[item])
                    .join(', ')
            }` : ''
        }${
            params.usability.details.includes(UsabilityDetail.OTHER) ? `${
                params.usability.details.length > 1 ? ', ' : ''
            }${
                USABILITY_DETAILS_OPTIONS[UsabilityDetail.OTHER]
            }${
                params.usability.customText ? `: ${params.usability.customText}` : ''
            }` : ''
        }`
    );

    const infoFullnessText = ! params.infoFullness.value ? '' : (
        params.infoFullness.value === FeedbackOption.YES ? INFO_FULLNESS_OPTIONS[FeedbackOption.YES] : `${
            INFO_FULLNESS_OPTIONS[FeedbackOption.NO]
        }${
            params.infoFullness.details.length ? `: ${
                params.infoFullness.details
                    .filter(item => item !== InfoFullnessDetail.OTHER)
                    .map(item => INFO_FULLNESS_DETAILS_OPTIONS[item])
                    .join(', ')
            }` : ''
        }${
            params.infoFullness.details.includes(InfoFullnessDetail.OTHER) ? `${
                params.infoFullness.details.length > 1 ? ', ' : ''
            }${
                INFO_FULLNESS_DETAILS_OPTIONS[InfoFullnessDetail.OTHER]
            }${
                params.infoFullness.customText ? `: ${params.infoFullness.customText}` : ''
            }` : ''
        }`
    );

    const extraFeatures = params.extraFeatures
        .map(item => EXTRA_FEATURES_OPTIONS[item])
        .join(', ');

    const searchSuccess = params.searchSuccess ? SEARCH_SUCCESS_OPTIONS[params.searchSuccess] : '';

    return {
        rating: 0,
        applicationId,
        usability: {
            value: params.usability.value && params.usability.value === FeedbackOption.YES,
            text: usabilityText
        },
        infoFullness: {
            value: params.infoFullness.value && params.infoFullness.value === FeedbackOption.YES,
            text: infoFullnessText
        },
        extraFeatures,
        searchSuccess,
        message: params.comment,
        contact: params.contact
    };
};
