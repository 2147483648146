import React from 'react';

import {
    MinimalBanksMortgageOfferWithDiscount, useMinimalBanksMortgageOffersLoader
} from '../MinimalBanksMortgageOffers/MinimalBanksMortgageOffers';
import { mortgage2ParamsFromClasified } from './mortgage2ParamsFromClasified';

export function mortgage2BestOfferFromOffers(
    minimalBanksMortgageOffers?: readonly MinimalBanksMortgageOfferWithDiscount[] | null
) {
    const bestMortgageOffer = minimalBanksMortgageOffers?.reduce((acc, item) => {
        const minimalPaymentItem = item.minimalPaymentDiscount ?? item.minimalPayment ?? 0;
        const minimalPaymentAcc = acc?.minimalPaymentDiscount ?? acc?.minimalPayment ?? 0;

        if (
            minimalPaymentItem && (
                ! minimalPaymentAcc ||
                minimalPaymentItem < minimalPaymentAcc
            )
        ) return item;

        return acc;
    }, undefined as MinimalBanksMortgageOfferWithDiscount | undefined);

    return bestMortgageOffer;
}

export function useMortgage2BestOfferLoader() {
    const minimalBanksMortgageOffersLoad = useMinimalBanksMortgageOffersLoader();

    return React.useCallback((props: ReturnType<typeof mortgage2ParamsFromClasified>) => {
        const mortgageInfo = minimalBanksMortgageOffersLoad(props);

        const bestMortgageOffer = mortgage2BestOfferFromOffers(mortgageInfo?.data?.minimalBanksMortgageOffers);

        return {
            isLoading: mortgageInfo.loading,
            error: mortgageInfo.errors?.length ? mortgageInfo.errors[0] : undefined,
            selectedMortgageOffer: bestMortgageOffer,
            mortgageOffers: mortgageInfo.data?.minimalBanksMortgageOffers
        };
    }, [ minimalBanksMortgageOffersLoad ]);
}
