import { s } from '@search/router/src/schema';
import { Tokens } from '@search/router/src/RouterInterfaces';
import { numToStr, strToNum } from '@search/router/src/schema/strToNum';

import type { Writeable } from '../types';

import { NewBuildingRoute } from './NewBuildingRoute';

export const DeveloperSearchType = s.rec({
    region: s.nullOpt(s.num),
    pageNumber: s.nullOpt(s.num)
});

export type IDeveloperSearchType = ReturnType<typeof DeveloperSearchType>

export const DeveloperSearchSeoType = s.rec({
    origin: s.nullOpt(s.str),
    region: s.nullOpt(s.str),
    pageNumber: s.nullOpt(s.str)
});

export type IDeveloperSearchSeoType = ReturnType<typeof DeveloperSearchSeoType>

export const developerSearchDefaults = {} as const;

export class DeveloperSearchRoute extends NewBuildingRoute<
    IDeveloperSearchSeoType,
    IDeveloperSearchType,
    typeof developerSearchDefaults
> {
    displayName = 'DeveloperSearchRoute';

    protected metadata = DeveloperSearchSeoType.config;

    protected pattern(params: Tokens<IDeveloperSearchSeoType>) {
        return `${this.patternHost()}(/${params.region})/novostroyki/zastroyshiki/`;
    }

    defaults() {
        return developerSearchDefaults;
    }

    protected fromQuery(seoParams: IDeveloperSearchSeoType) {
        seoParams = DeveloperSearchSeoType(seoParams);

        const region = this.regionId(seoParams.region);

        return DeveloperSearchType({
            region: region === this.defaultRegion().id ? undefined : region,
            pageNumber: strToNum(seoParams.pageNumber)
        });
    }

    protected toQuery(params: IDeveloperSearchType) {
        const parsedParams = DeveloperSearchType(params);

        const result = this.regionParams(parsedParams.region);
        const region = result?.region;

        return DeveloperSearchSeoType({
            ...result,
            region: region === this.defaultRegion().translit ? undefined : region,
            pageNumber: numToStr(parsedParams.pageNumber)
        });
    }
}

export class WLDeveloperSearchRoute extends DeveloperSearchRoute {
    displayName = 'WLDeveloperSearchRoute';

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected pattern(params: Tokens<IDeveloperSearchSeoType>) {
        return '/zastroyshiki/';
    }

    protected fromQuery(seoParams: IDeveloperSearchSeoType) {
        const ret: Partial<Writeable<IDeveloperSearchType>> = super.fromQuery(seoParams);

        ret.region = strToNum(seoParams.region);

        return ret;
    }

    protected toQuery(params: IDeveloperSearchType) {
        const ret: Partial<Writeable<IDeveloperSearchSeoType>> = super.toQuery(params);

        ret.region = numToStr(params.region);
        delete ret.origin;

        return ret;
    }
}
