import React, { ReactNode } from 'react';
// @ts-ignore
import Button from '@search/vtbeco-ui/src/components/controls/Button';
// @ts-ignore
import IconClose from '@search/vtbeco-ui/src/components/controls/IconClose';
// @ts-ignore
import classname from '@search/classname/src';

import './styles.css';

export interface IFiltersFormTip {
    children: ReactNode;

    /**
     * Обработчик удаления подсказки
     * @param name
     */
    onRemove: () => void;
    enabled: boolean;
    onWhiteBackground?: boolean;
    mortgageTip?: boolean;
}

const cn = classname.bind(null, 'FiltersFormTip');

const FiltersFormTip: React.FC<IFiltersFormTip> = ({
    children,
    onRemove,
    mortgageTip = false,
    onWhiteBackground: onWhite = true
}) => {
    const handleClickRemove = () => {
        onRemove();
    };

    return (
        <div className={cn(null, { onWhite, mortgageTip })}>
            <span className={cn('label')}>
                {children}
            </span>
            <Button
                view='clear'
                onClick={handleClickRemove}
                iconLeft={<IconClose />}
                className={cn('remove')}
            />
        </div>
    );
};

export default FiltersFormTip;
