import { GeoKind } from '@search/filter-enums/enums/GeoKind';

import { CopyObject, Flavor } from '../domainHelpers';
import { AddressStore } from './AddressStore';
import { District, DistrictStore } from './District';
import { GeoObject2 } from './GeoObject2';
import { RouteStore } from './MetroRoute';
import { MetroStation, MetroStore, MetroRouteGroup } from './MetroStation';
import { geoBaseStoreFromGql } from './geoBaseStoreFromGql';
import { Address } from './Address';

export class GeoBase extends CopyObject {
    loading: boolean = true;
    error?: Error = undefined;
    store = new GeoBaseStore();
    public regionId: GeoId | null;

    constructor(regionId: GeoId | null = 0) {
        super();
        this.regionId = regionId;
    }
}

export type GeoId = Flavor<number, 'GeoId'>;

export type GeoFilteredItem = MetroStation | District | Address | MetroRouteGroup;

export class GeoBaseStore extends CopyObject {
    readonly districts = new DistrictStore();
    readonly routes = new RouteStore();
    readonly stations = new MetroStore(this.routes);
    readonly addresses = new AddressStore();

    static fromGql(geo: Parameters<typeof geoBaseStoreFromGql>[0]) {
        return geoBaseStoreFromGql(geo);
    }

    add(geo: GeoObject2) {
        this.districts.add(geo);
        this.stations.add(geo);
        this.routes.add(geo);

        const kind = geo.kind;

        if (
            kind === GeoKind.HOUSE ||
            kind === GeoKind.STREET ||
            kind === GeoKind.LOCALITY ||
            kind === GeoKind.AREA ||
            kind === GeoKind.PROVINCE ||
            kind === GeoKind.COUNTRY ||
            kind === GeoKind.HYDRO ||
            kind === GeoKind.VEGETATION ||
            kind === GeoKind.AIRPORT ||
            kind === GeoKind.OTHER ||
            kind === GeoKind.RAILWAY_STATION ||
            kind === GeoKind.STATION ||
            kind === GeoKind.ENTRANCE ||
            kind === GeoKind.COMPOSITE_OBJECT ||
            kind === GeoKind.STREET_ADDRESS ||
            kind === GeoKind.APARTMENT
        ) {
            // @ts-ignore
            this.addresses.add(geo);
        }
    }

    getById(id: GeoId): GeoFilteredItem | undefined {
        const { districts, stations, addresses } = this;
        const district = districts.get(id);

        if (district) return district;

        const station = stations.get(id);

        if (station) return station;

        const address = addresses.get(id);

        if (address) return address;

        return undefined;
    }

    getByIds(ids: readonly GeoId[]): GeoFilteredItem[] {
        const result: GeoFilteredItem[] = [];

        for (const id of ids) {
            const item = this.getById(id);

            if (item) result.push(item);
        }

        return result;
    }
}
