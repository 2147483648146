import React from 'react';

type SVGProps = {
    color: string;
    opacity?: number;
};

const icons = {
    FOOT: ({ color, opacity }: SVGProps) => (
        <svg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'>
            {/* eslint-disable-next-line max-len */}
            <path d='M5.739 11.118l1.166 1.867-.9 2.449a.878.878 0 01-1.116.516.87.87 0 01-.516-1.116l1.366-3.716zM3.14 6.82l.966-1.416c.267-.383.65-.683 1.083-.85 1.766-.683 2-.666 2.166-.666l1.25.083c.35.017.616.117 1.932 1.783a.86.86 0 00.483.3l1.3.283a.75.75 0 01.583.9.759.759 0 01-.9.583l-1.3-.283c-.533-.117-1-.417-1.349-.834a1.77 1.77 0 00-.15-.183l-1.2 3.499 2.9 4.648c.25.417.133.95-.284 1.2a.943.943 0 01-.466.133c-.3 0-.583-.15-.733-.416L5.822 9.802A2.509 2.509 0 015.54 7.82l.583-2c-.117.05-.25.1-.4.15a.963.963 0 00-.383.3l-.966 1.416a.754.754 0 01-.617.334c-.15 0-.3-.05-.433-.134-.333-.25-.433-.716-.183-1.066zM8.06 3.49A1.75 1.75 0 118.44.01a1.75 1.75 0 01-.38 3.48z' fill={color} fillOpacity={opacity} />
        </svg>
    ),
    PUBLIC_TRANSPORT: ({ color, opacity }: SVGProps) => (
        <svg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'>
            {/* eslint-disable-next-line max-len */}
            <path fillRule='evenodd' clipRule='evenodd' d='M14.43 9.237l.01.241.059 1.835a2.343 2.343 0 01-.499 1.52V14.5a.5.5 0 01-.5.5H12a.5.5 0 01-.5-.5v-.768h-7v.768a.5.5 0 01-.5.5H2.5a.5.5 0 01-.5-.5v-1.666a2.343 2.343 0 01-.499-1.52l.06-1.834.008-.244.28-7.135A1.28 1.28 0 013.118 1h9.766c.637 0 1.177.47 1.268 1.101l.28 7.136zM2.633 10.91a1.095 1.095 0 101.093-1.096c-.604 0-1.093.49-1.093 1.096zm8.549 0a1.095 1.095 0 102.188 0 1.095 1.095 0 10-2.188 0zM3.245 8.615c3.022.503 6.206.463 9.494-.017a.787.787 0 00.672-.802c-.04-1.356-.112-4.218-.112-4.218a.499.499 0 00-.488-.432c-1.363-.264-8.134-.244-9.621 0a.499.499 0 00-.489.432s-.073 2.888-.113 4.238a.785.785 0 00.657.8zM7 1.5a.5.5 0 000 1h2a.5.5 0 000-1H7z' fill={color} fillOpacity={opacity} />
        </svg>
    )
};

export type IconTransportProps = {
    transportType: 'FOOT' | 'PUBLIC_TRANSPORT';
} & SVGProps;

const IconTransport: React.FunctionComponent<IconTransportProps> = ({
    transportType,
    color,
    opacity = 1
}) => {
    const Icon = icons[transportType];

    return (
        <Icon
            color={color}
            opacity={opacity}
        />
    );
};

export default IconTransport;
