import React, { useCallback } from 'react';
import { Size } from '@vtblife/uikit/legacy';

import { IFilterAvailableProps } from '../../new_filter/Filter2';
import { NumberInputRange } from '../../common/NewNumberInputRange/NumberInputRange';
import { FloorsTotalFilter } from '../../../models/Filter';
import { INumberRange } from '../../../types/IRange';
import { MobileFilter2 } from '../../filter/MobileFilter2';

interface IFloorsTotalFilterViewProps extends IFilterAvailableProps {
    filter: FloorsTotalFilter;
    handleChange: (value: FloorsTotalFilter) => void;
    isMobile?: boolean;
}

export const FloorsTotalFilterView: React.FC<IFloorsTotalFilterViewProps> = ({
    filter,
    handleChange,
    withoutTitle,
    isMobile
}) => {
    const onChange = useCallback((value: INumberRange) => {
        handleChange(new FloorsTotalFilter(value));
    }, [ handleChange ]);

    return (
        <MobileFilter2 desktopTitleGap={! isMobile} filterTitle={withoutTitle ? undefined : 'Этажей в доме'}>
            <NumberInputRange
                size={Size.Small}
                fromLabel='от'
                toLabel='до'
                value={filter.range}
                onChange={onChange}
                max={1_000}
                nameFrom='floorsTotalFrom'
                nameTo='floorsTotalTo'
            ></NumberInputRange>
        </MobileFilter2>
    );
};
