/* eslint-env browser */
import { RefObject } from 'react';
import { AnchorDimensions, PopupDimensions, ViewportDimensions, Position, TailDimensions } from './types';

/*
 * Вычисляет размеры анкора.
 *
 * @param anchorRef Ссылка на DOM элемент анкора.
 */
export const getAnchorDimensions = (anchorRef?: RefObject<HTMLElement>): AnchorDimensions => {
    const dimensions = {
        left: 0,
        top: 0,
        height: 0,
        width: 0
    };

    if (anchorRef !== undefined && anchorRef.current !== null) {
        const { offsetWidth, offsetHeight } = anchorRef.current;
        const { left, top } = anchorRef.current.getBoundingClientRect();

        dimensions.left = left + window.pageXOffset;
        dimensions.top = top + window.pageYOffset;
        dimensions.height = offsetHeight;
        dimensions.width = offsetWidth;
    }

    return dimensions;
};

/*
 * Вычисляет размеры попапа.
 * @param popupRef Ссылка на DOM элемент попапа.
 */
export const getPopupDimensions = (popupRef?: RefObject<HTMLElement | undefined>): PopupDimensions => {
    const dimensions = {
        area: 0,
        height: 0,
        width: 0,
        popupHeight: 0,
        popupWidth: 0
    };

    if (popupRef !== undefined && popupRef.current !== null && popupRef.current !== undefined) {
        const { clientWidth, clientHeight } = popupRef.current;
        const { width, height } = popupRef.current.getBoundingClientRect();

        dimensions.area = clientWidth * clientHeight;
        // Погрешность расчета при рендеринге ie11
        dimensions.height = Math.round(height);
        // Погрешность при расчете offsetWidth при рендеринге в chrome
        dimensions.width = Math.round(width);
        dimensions.popupHeight = clientHeight;
        dimensions.popupWidth = clientWidth;
    }

    return dimensions;
};

/*
 * Вычисляет размеры окна.
 */
export const getViewportDimensions = (): ViewportDimensions => {
    return {
        top: window.pageYOffset,
        left: window.pageXOffset,
        bottom: window.pageYOffset + window.innerHeight,
        right: window.pageXOffset + window.innerWidth
    };
};

/*
 * Вычисляет размеры области.
 *
 * @param scopeRef Ссылка на DOM элемент области.
 */
export const getScopeDimensions = (scopeRef?: RefObject<HTMLElement>): Position => {
    const dimensions = { top: 0, left: 0 };

    if (scopeRef !== undefined && scopeRef.current !== null) {
        const { left, top } = scopeRef.current.getBoundingClientRect();

        dimensions.top = top; // - (scopeRef.current.scrollTop + top);
        dimensions.left = left; // - (scopeRef.current.scrollLeft + left);
    }

    return dimensions;
};

/*
 * Вычисляет размеры хвостика.
 *
 * tailRef Ссылка на DOM элемент хвостика.
 */
export const getTailDimensions = (tailRef?: RefObject<HTMLElement>): TailDimensions => {
    const dimensions = { size: 0 };

    if (tailRef && tailRef.current) {
        dimensions.size = tailRef.current.offsetWidth;
    }

    return dimensions;
};
