import { SourceValue, useMortgageAppSource } from './MortgageAppSourceContext';
import { MortgagePurposeEnum } from './MortgagePurposeEnum';
import { MinimalBanksMortgageOffersQueryVariables } from './MinimalBanksMortgageOffers/MinimalBanksMortgageOffers';

type MinimalBanksMortgageUrlCreateProps = Partial<MinimalBanksMortgageOffersQueryVariables> & {
    isAuthenticated?: boolean;
    from?: string;
    email?: string;
    mobilePhone?: string;
    fio?: string;
    source?: SourceValue;
};

export function minimalBanksMortgageUrlCreate({
    isAuthenticated,
    source: initialSource,
    ...props
}: MinimalBanksMortgageUrlCreateProps) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const source = initialSource ?? useMortgageAppSource();
    const baseUrl = isAuthenticated ? '/ipoteka/demand' : '/ipoteka';
    const keys = Object.keys(props) as (keyof typeof props)[];
    const query = keys.filter(key => props[key] !== '').map(key => `${key}=${normalize(key, props[key])}`);

    source && query.push(`source=${source}`);
    const hash = isAuthenticated ? '' : '#mortgage-calculator';

    return {
        baseUrl,
        fullUrl: `${baseUrl}?${query.join('&')}${hash}`
    };
}

function normalize(
    key: keyof MinimalBanksMortgageUrlCreateProps,
    value: MinimalBanksMortgageUrlCreateProps[keyof MinimalBanksMortgageUrlCreateProps]
) {
    switch (key) {
        case 'fio': return encodeURIComponent(value as string);
        case 'purpose': return String(MortgagePurposeEnum[value as MortgagePurposeEnum]);
        default: return String(value);
    }
}
