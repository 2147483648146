import React, { useMemo } from 'react';
import { formatLocaleNumber, parseAndFormatNumber } from '@search/helpers/src/formatNumber';
import { DiscountCalcEnum, ApplicationIdEnum } from '@search/graphql-typings';
import { MinimalBanksMortgageOffer, MinimalBanksMortgageOfferWithDiscount } from '../MinimalBanksMortgageOffers/MinimalBanksMortgageOffers';
import { useGovernmentMortgageType, governmentMinPercent } from '../useGovernmentMortgage';

const MAX_MORTGAGE_DISCOUNT = 0.3;

export const VTB_ID = '3';
export const maxMortgageDiscountFormatted = formatLocaleNumber(MAX_MORTGAGE_DISCOUNT);

export function useChangeDiscount({
    term,
    propertyCost,
    ownAmount,
    isMobile,
    offersFull,
    applicationId,
    showPreferential,
    withDiscount = showPreferential,
    isPreferentialNbCondition,
    isGovernmentDebtExceeds,
    isGovernmentPercentPoor,
    governmentMaxDebt
}: {
    isMobile?: boolean;
    withDiscount?: boolean;
    term: number;
    propertyCost: number;
    ownAmount: number;
    offersFull: readonly MinimalBanksMortgageOfferWithDiscount[];
    applicationId: ApplicationIdEnum;
    isPreferentialNbCondition?: boolean;
} & useGovernmentMortgageType) {
    // change, if default value options changed (!!!)
    const [ activeDiscountTypes, setActiveDiscountTypes ] = React.useState(
        new Set([ DiscountCalcEnum.SbrElreg ])
    );
    const debt = propertyCost - ownAmount;

    // block with controls - hidden
    // const prev = React.useRef({ term, debt });
    // React.useEffect(() => {
    //     if (! showPreferential) return;
    //     if (prev.current.term === term && prev.current.debt === debt) return;
    //     prev.current = { term, debt };

    //     // setActiveDiscountTypes(new Set()); // block with controls - hidden
    // }, [ showPreferential, term, debt ]);

    const discount = 0.0 +
        (activeDiscountTypes.has(DiscountCalcEnum.SbrElreg) ? 0.3 : 0) +
        (activeDiscountTypes.has(DiscountCalcEnum.SalaryProject) ? 0.3 : 0);

    const offers: Array<MortgageOfferDiscountifyType> = React.useMemo(
        () => {
            if (! offersFull.length) return [];

            const offers = offersFull
                // Убираем офферы госпрограмм
                .filter(offer => ! offer.type)
                .map(offer => mortgageOfferDiscountify({ offer, discount, debt, term }))
                .sort(mortgageOfferSort);

            if (! isPreferentialNbCondition) return offers;

            let familyOffer = offersFull
                .filter(offer => offer.type === 'FAMILY')
                .map(offer => mortgageOfferDiscountify({ offer, discount, debt, term }));
            let itOffer = offersFull
                .filter(offer => offer.type === 'IT_MORTGAGE')
                .map(offer => mortgageOfferDiscountify({ offer, discount, debt, term }));

            if (isGovernmentPercentPoor) {
                // @ts-ignore
                familyOffer = [ { ...emptyOffer(), type: 'FAMILY', description: `Первоначальный взнос не менее ${governmentMinPercent}%`, disabled: true } ];
                // @ts-ignore
                itOffer = [ { ...emptyOffer(), type: 'IT_MORTGAGE', description: `Первоначальный взнос не менее ${governmentMinPercent}%`, disabled: true } ];
            }

            if (isGovernmentDebtExceeds) {
                // @ts-ignore
                familyOffer = [ { ...emptyOffer(), type: 'FAMILY', description: `Сумма кредита превышает ${Math.floor(governmentMaxDebt / 1e6)} млн ₽`, disabled: true } ];
                // @ts-ignore
                itOffer = [ { ...emptyOffer(), type: 'IT_MORTGAGE', description: `Сумма кредита превышает ${Math.floor(governmentMaxDebt / 1e6)} млн ₽`, disabled: true } ];
            }

            return [
                familyOffer[0],
                itOffer[0],
                ...offers
            ].filter(Boolean);
        },
        [ offersFull, discount, debt, term, governmentMaxDebt, isMobile, isGovernmentDebtExceeds, isGovernmentPercentPoor ]
    );

    // views discount only VTB if it is selected/best offer
    const shouldVTBDiscountLogic = (
        applicationId === ApplicationIdEnum.ClassifiedDesktop ||
        applicationId === ApplicationIdEnum.ClassifiedTouch
    );

    // views VTB discount options block
    const isShowAdditionalDiscounts = useMemo(() => {
        return ! showPreferential && shouldVTBDiscountLogic && offersFull.some(offer => offer.bankId === VTB_ID);
    }, [ shouldVTBDiscountLogic, showPreferential, offersFull ]);

    const onChangeDiscount = React.useCallback((enabled: boolean, discountType: DiscountCalcEnum) => {
        setActiveDiscountTypes(() => {
            if (enabled) return new Set([ discountType ]);
            return new Set();
        });
    }, [ ]);

    return {
        offers,
        discount,
        onChangeDiscount,
        shouldVTBDiscountLogic,
        isShowAdditionalDiscounts,
        discountTypes: Array.from(activeDiscountTypes)
    };
}

function emptyOffer() {
    return {
        minimalPaymentDiscount: undefined,
        minimalPaymentDiscountFormatted: undefined,
        minimalPercentDiscount: undefined,
        minimalPercentDiscountFormatted: undefined,
        bankId: '',
        description: '',
        disabled: true,
        icon: null,
        minimalPayment: null,
        minimalPaymentFormatted: null,
        minimalPercent: null,
        minimalPercentFormatted: null,
        name: null,
        type: undefined as undefined | string,
    };
}

function calculatePayment({ percent, term, debt }: {
    percent: number;
    term: number;
    debt: number;
}) {
    const monthPercent = percent / (100 * 12);
    const denominator = (1 - Math.pow(1 + monthPercent, (-1) * term * 12));

    return Math.floor((monthPercent * debt) / denominator);
}

export type MortgageOfferDiscountifyType = MinimalBanksMortgageOfferWithDiscount & { description?: string };

function mortgageOfferDiscountify({ offer, discount, debt, term }: {
    debt: number;
    term: number;
    offer: MinimalBanksMortgageOffer;
    discount: number;
}): MortgageOfferDiscountifyType {
    // ВТБ присылает нам процент с учётом ВСЕХ скидок,
    // поэтому в зачеркнутой цене нам нужно показывать без них
    const minimalPercent = offer.bankId === VTB_ID && ! offer.type ?
        Number(((offer.minimalPercent ?? 10) + MAX_MORTGAGE_DISCOUNT).toFixed(2)) : undefined;

    // минимальный процент от банка, меняющийся в зависимости от выбранных скидок
    const discountPercent = minimalPercent === undefined ? undefined : minimalPercent - discount;

    const minimalPercentFormatted = minimalPercent === undefined ? undefined :
        `${formatLocaleNumber(minimalPercent, 1)}%`;

    const minimalPercentDiscount = discountPercent === undefined ? undefined : Number(discountPercent.toFixed(1));
    const minimalPercentDiscountFormatted = minimalPercentDiscount === undefined ?
        undefined :
        `${formatLocaleNumber(minimalPercentDiscount)}%`;

    const minimalPaymentDiscount = discountPercent === undefined ? undefined :
        calculatePayment({ percent: discountPercent, term, debt });

    const minimalPaymentDiscountFormatted = minimalPaymentDiscount === undefined ? undefined :
        `${parseAndFormatNumber(minimalPaymentDiscount)} ₽/мес.`;

    return {
        ...offer,
        disabled: false,
        description: '',
        minimalPercent: minimalPercent ?? offer.minimalPercent,
        minimalPercentFormatted: minimalPercentFormatted ?? offer.minimalPercentFormatted,
        minimalPercentDiscount,
        minimalPercentDiscountFormatted,
        minimalPaymentDiscount,
        minimalPaymentDiscountFormatted
    };
}

function mortgageOfferSort(
    a: MortgageOfferDiscountifyType,
    b: MortgageOfferDiscountifyType
) {
    if (a.type && ! b.type) return -1;
    if (! a.type && b.type) return 1;

    if (a.minimalPercentDiscount) return a.minimalPercentDiscount - (b.minimalPercent ?? 1);
    if (b.minimalPercentDiscount) return (a.minimalPercent ?? 1) - b.minimalPercentDiscount;

    // family mortgage always first
    if (a.type === 'FAMILY' || b.type === 'FAMILY') return 1;

    return (a.minimalPercent ?? 1) - (b.minimalPercent ?? 1);
}
