import { SeoMapper } from '@search/router/src';
import { Rooms } from '@search/filter-enums/enums/Rooms';

export const roomMapper = new SeoMapper('roomMapper', {
    unknown: Rooms.UNKNOWN,
    studiya: Rooms.STUDIO,
    '1-komnata': Rooms.ROOM_1,
    '2-komnaty': Rooms.ROOM_2,
    '3-komnaty': Rooms.ROOM_3,
    '4-komnaty': Rooms.ROOM_4,
    '5-komnat_i_bolee': Rooms.ROOM_5_AND_MORE,
    'svobodnaya-planirovka': Rooms.OPEN_PLAN
    // 'hz-planirovka': 8
} as const);
