import React from 'react';
import { Typography } from '@vtblife/uikit';
import { TypographyProps } from '@vtblife/uikit/dist/components/typography/types';

import classname from '@search/classname/src';

import { MetroStationBase } from '../../../../../domain/geo/MetroStation';

import './styles.css';

const cnSubwayStationMark = classname.bind(null, 'SubwayStationMark');

export const SubwayStationMark: React.FunctionComponent<{
    className?: string;
    colors: readonly string[];
}> = ({ colors, className }) => {
    return (
        <span className={cnSubwayStationMark(null, {}, className)}>
            {
                colors.slice(0).reverse().map((color, index) => (
                    <span
                        key={index}
                        style={{ backgroundColor: color }}
                        className={cnSubwayStationMark('line', { type: 'mark' })}
                    />
                ))
            }
        </span>
    );
};

const cnSubwayStation = classname.bind(null, 'SubwayStation');

export const SubwayStation: React.FunctionComponent<{
    textVariant?: TypographyProps['variant'];
    textColor?: TypographyProps['color'];
    textBold?: TypographyProps['bold'];
    className?: string;
    station: MetroStationBase;
    url?: string;
    noClickable?: boolean;
}> = React.memo(({ noClickable, station, className, textVariant, textColor, textBold, url, children }) => {
    return (
        <span className={cnSubwayStation(null, undefined, className)}>
            <SubwayStationMark
                colors={station.allRoutesColorsList}
            />
            <Typography
                tag='span'
                variant={textVariant}
                color='primary'
                className={cnSubwayStation('title')}
                bold={textBold}
            >
                {
                    (url !== undefined) ? (
                        <a
                            href={url}
                            onClick={e => {
                                if (noClickable) e.preventDefault();
                            }}
                            data-noclick={noClickable ? '1' : undefined}
                            data-test='subway-link'
                            className={cnSubwayStation('link', { noClickable })}
                        >
                            { station.title }
                        </a>
                    ) : (<>{ station.title }</>)
                }
            </Typography>
            {children}
        </span>
    );
});
