import React from 'react';
import { FunctionComponent, RefObject, useCallback, useEffect, useRef } from 'react';

import throttle from 'lodash/throttle';

import classname from '@search/classname/src';

import './styles.css';

const cn = classname.bind(null, 'StickyPhoneWrapper');

export const StickyPhoneWrapper: FunctionComponent<{
    className?: string;
    bottomOffset?: number;
    safeAreaBottom?: number;
    anchor?: RefObject<HTMLElement>;
    hasBackground?: boolean;
    showBeforeScrolling?: boolean;
}> = ({
    children,
    className,
    anchor,
    bottomOffset = 16,
    safeAreaBottom = 12,
    hasBackground = false,
    showBeforeScrolling = false
}) => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const ref = useRef<HTMLDivElement>(null);

    const handleScroll = useCallback(() => {
        const anchorRect = anchor?.current?.getBoundingClientRect();

        const viewPortHeight = window.innerHeight;

        if (ref.current && anchorRect && wrapperRef.current) {
            const currentRect = ref.current.getBoundingClientRect();

            const isHideAnchor = (anchorRect.bottom - viewPortHeight - anchorRect.height) <= 0;

            if (! isHideAnchor) {
                ref.current.style.position = 'fixed';

                if (hasBackground) {
                    ref.current.style.paddingBottom = `calc(${bottomOffset}px + env(safe-area-inset-bottom, ${safeAreaBottom}px))`;
                    ref.current.style.bottom = '0';
                } else {
                    ref.current.style.bottom = `calc(${bottomOffset}px + env(safe-area-inset-bottom, ${safeAreaBottom}px))`;
                }
            } else {
                ref.current.style.bottom = `-${currentRect.height}px`;
            }
        }
    }, [ anchor, bottomOffset, safeAreaBottom, hasBackground ]);

    useEffect(() => {
        if (anchor) {
            if (showBeforeScrolling) {
                handleScroll();
            }

            const throttledHandleScroll = throttle(handleScroll, 100);

            window.addEventListener('scroll', throttledHandleScroll);

            return () => {
                window.removeEventListener('scroll', throttledHandleScroll);
            };
        }
    }, [ anchor, handleScroll ]);

    return (
        <div ref={wrapperRef}>
            <div
                ref={ref}
                className={cn(null, { fixed: ! anchor, hasBackground }, className)}
            >
                {children}
            </div>
        </div>
    );
};
