import React, { FunctionComponent } from 'react';

import { Button, Link, Typography } from '@vtblife/uikit';
import { List, ListItem } from '@search/vtbeco-frontend-core/domain/newbuilding/components/common/List';

import Paper from '@search/vtbeco-ui/src/components/Paper';

import { formatRange } from '@search/helpers/src/formatNumber';

import { useGql } from '@search/gql-client/src';
import { useGtmOnVisible } from '../../../../domain/google/useGtmOnVisible';
import type { DeveloperPromoEventResult } from '../../../../domain/newbuilding/developer-promo/analytics';
import { useClassifiedOfferDataLayerPush } from '../../../../domain/offer/analytics/ClassifiedOfferGtmContext';

import { getNewBuildingLink } from '../../../selectors/get_new_building_link';
import {
    cn, query, IProps,
    NewBuildingPromoBannerQueryVariables, NewBuildingPromoBannerQueryResponse
} from '../../../common/components/NewBuildingPromoBanner';
import Image from '../../../common/components/Image';

import './styles.css';

const NewBuildingPromoBanner: FunctionComponent<IProps & {
    gtmClickData?: { event: string } | DeveloperPromoEventResult;
    gtmShowData?: { event: string } | DeveloperPromoEventResult;
    erid: string;
}> = ({
    view,
    id,
    developerName,
    disclaimerList,
    developerLogo,
    dataGTM = {},
    gtmClickData,
    gtmShowData,
    erid
}) => {
    const res = useGql<NewBuildingPromoBannerQueryVariables, NewBuildingPromoBannerQueryResponse>(query, { id });
    const dataLayerPush = useClassifiedOfferDataLayerPush();

    const containerRef = React.useRef<HTMLDivElement>(null);

    const onVisible = React.useCallback(() => {
        gtmShowData && dataLayerPush(gtmShowData);
    }, [ gtmShowData, dataLayerPush ]);

    useGtmOnVisible({ containerRef, onVisible });

    const newBuilding = res.data?.newBuilding;

    const mainImg = newBuilding?.gallery?.main?.medium;
    const firstImg = newBuilding?.gallery?.images?.[0]?.medium;

    const { priceMin, routes } = { ...newBuilding };

    const metroName = routes && routes[0]?.station?.name;

    const formattedPriceMin = priceMin ? `${formatRange(priceMin, null, {
        billionsAsString: true
    })} ₽` : null;

    const formattedMetroName = (metroName && metroName.length > 0) ? `метро «${metroName}»` : null;

    const listItems = [ formattedPriceMin, formattedMetroName ].filter(Boolean);

    const link = newBuilding ? getNewBuildingLink({
        id: newBuilding.routeParams.id,
        type: newBuilding.routeParams.type,
        name: newBuilding.routeParams.name,
        regionId: newBuilding.routeParams.regionId,
        region: newBuilding.routeParams.region,
        subRegion: newBuilding.routeParams.subRegion
    }) : '';

    return (
        <Paper
            role='button'
            dataTest='new-building-promo-banner-click'
            className={cn(null, { view })}
            onClick={() => gtmClickData && dataLayerPush(gtmClickData)}
            ref={containerRef}
        >
            <div className={cn('badgeWrapper')}>
                <Typography
                    variant='small-alone'
                    color='secondary'
                >
                    Реклама
                </Typography>
            </div>
            <div className={cn('gallery')}>
                {mainImg && (<div
                    className={cn('image')}
                    style={{ background: `url(${mainImg}` }}
                />)}
                {firstImg && (<div
                    key={firstImg}
                    className={cn('image')}
                    style={{ background: `url(${firstImg}` }}
                />)}
            </div>
            <div className={cn('main')}>
                <Typography
                    variant='h3'
                    color={res.loading ? 'minor' : undefined}
                >
                    {newBuilding?.title.nominative ?? '___'}
                </Typography>
                { listItems.length ? (
                    <List
                        className={cn('info')}
                        separator={
                            <Typography
                                tag='span'
                                color='blue'
                                bold
                            >
                                •
                            </Typography>
                        }
                    >
                        {listItems.map((item, index) => (
                            <ListItem key={index}>
                                <Typography
                                    color='blue'
                                    tag='span'
                                    bold
                                >
                                    {item}
                                </Typography>
                            </ListItem>
                        ))}
                    </List>
                ) : null }
                <div className={cn('extra')}>
                    <div className={cn('button')}>
                        <Button
                            variant='secondary'
                            fullWidth
                            size='s'
                        >
                            Смотреть квартиры
                        </Button>
                    </div>
                    <div className={cn('disclaimer')}>
                        {
                            disclaimerList.map((disclaimer, idx) => (
                                <Typography
                                    key={idx}
                                    variant='tiny-alone'
                                    color='minor'
                                >
                                    <div dangerouslySetInnerHTML={{
                                        __html: disclaimer
                                    }} />
                                </Typography>
                            ))
                        }
                    </div>
                </div>
            </div>
            <Image
                className={cn('developerLogo', {
                    [developerName]: true
                })}
                url={developerLogo}
            />
            <Link
                dataTest='new-building-promo-banner-link-click'
                href={! newBuilding ? '#' : `${link}?erid=${erid}`}
                gtm={dataGTM.click}
                target='blank'
            />
        </Paper>
    );
};

export default NewBuildingPromoBanner;
