import React, { useCallback } from 'react';

import { Checkbox } from '@vtblife/uikit';
import { NewBuildingDeal } from '@search/filter-enums/enums';

import { NewBuildingDealFilter } from '../../../../models/Filter';
import { Filter2 } from '../../../new_filter/Filter2';
import { CheckboxGroup2 } from '../../../common/CheckboxGroup2';

const ITEMS = [
    [ NewBuildingDeal.LAW_214, '214 ФЗ' ],
    [ NewBuildingDeal.ESCROW, 'Эскроу-счета' ]
];

interface IProps {
    filter: NewBuildingDealFilter;
    handleChange: (filter: NewBuildingDealFilter) => void;
}

export const DesktopNewBuildingDealFilterView: React.FC<IProps> = ({
    filter,
    handleChange
}) => {
    const onChange = useCallback(val => {
        if (val === null) {
            return;
        }

        if (Array.isArray(val)) {
            handleChange(new NewBuildingDealFilter(val));
        }
    }, [ handleChange ]);

    return (
        <Filter2 filterTitle='Сделка'>
            <CheckboxGroup2
                mode='check'
                name='newBuildingDeal'
                onChange={onChange}
                value={filter.deal}
                isFilter
            >
                {
                    ITEMS.map(([ value, label ]) => (
                        <Checkbox
                            variant='filter'
                            key={value}
                            name={value}
                            block
                        >
                            {label}
                        </Checkbox>
                    ))
                }
            </CheckboxGroup2>
        </Filter2>
    );
};
