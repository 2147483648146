import './DistrictModal.css';

import React, { useMemo } from 'react';
import { Button } from '@vtblife/uikit';

import classname from '@search/classname/src';
import { Modal, ModalProps, ModalSize } from '@search/vtbeco-ui/src/components/Modal';
import { BorderRadius } from '@search/vtbeco-ui/src/components/Paper';

import { ModalHeading } from '../../../../../mobile/components/ModalHeading';
import type { DistrictStore } from '../../../../../../domain/geo/District';
import { getDistrictCount, groupDistricts } from '../../../../helpers/districtHelper';

import { Limit, DistrictSelect } from './DistrictSelect/DistrictSelect';

const cn = classname.bind(null, 'DistrictModal');

interface IDistrictModalProps extends Pick<ModalProps, 'uikitZIndexEnabled'> {
    hide: () => void;
    checkedIds: number[];
    districts: DistrictStore;
    handleChange(checkedDiff: number[], uncheckedDiff: Set<number>): void;
    onReset: () => void;
    onSubmit: () => void;
    regionId: number;
}

export const DistrictModal: React.FC<IDistrictModalProps> = ({
    hide,
    checkedIds,
    districts,
    handleChange,
    onReset,
    onSubmit,
    regionId,
    uikitZIndexEnabled
}) => {
    const districtGroups = useMemo(() => groupDistricts({
        districts: districts.items,
        regionId
    }), [ districts, regionId ]);

    const districtCount = useMemo(
        () => getDistrictCount(checkedIds, districtGroups),
        [ checkedIds, districtGroups ]
    );

    return (
        <Modal
            size={ModalSize.FULL_L}
            borderRadius={BorderRadius.none}
            uikitZIndexEnabled={uikitZIndexEnabled}
            fullScreen
        >
            <ModalHeading
                text='Район'
                activeCount={districtCount}
                onClose={hide}
            />
            <DistrictSelect
                regionId={regionId}
                groups={districtGroups}
                checked={checkedIds}
                limit={Limit.noLimit}
                onChange={handleChange}
            />
            <div className={cn('clear')}>
                <Button
                    fullWidth
                    size='s'
                    variant='secondary'
                    onClick={onReset}
                >
                    Сбросить районы
                </Button>
            </div>
            <div className={cn('submit')}>
                <Button
                    fullWidth
                    size='s'
                    onClick={onSubmit}
                >
                    Сохранить
                </Button>
            </div>
        </Modal>

    );
};
