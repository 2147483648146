import React, { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Modal } from '@vtblife/uikit';

import { useM2AuthContext } from '@search/auth/src/M2AuthProvider';
import classname from '@search/classname/src';

import './CustomerFeedbackAnketologModal.css';

const cn = classname.bind(null, 'CustomerFeedbackAnketologModal');

type Survey = {
    link: string;
}

function buildSurveyLink({
    userIdQuery,
    yMetricaId
}: {
    userIdQuery: string;
    yMetricaId: string;
}) {
    return `https://anketolog.ru/s/640716/GWwkMkKd?${userIdQuery}${yMetricaId}`;
}

export const CustomerFeedbackAnketologModal = ({
    modalIsOpen,
    handleCloseModal,
    disableFeedbackReappearance,
}: {
    modalIsOpen: boolean;
    handleCloseModal: () => void;
    disableFeedbackReappearance: () => void;
}) => {
    const { auth } = useM2AuthContext();
    const userIdQuery = auth.user?.userId ? `ap_user_id=${auth.user.userId}&` : '';
    const [ cookies ] = useCookies([ '_ym_uid' ]);
    // eslint-disable-next-line dot-notation
    const yMetricaId = cookies['_ym_uid'] ? `ap_yMetrica=${cookies['_ym_uid']}&` : '';

    const [ survey, setSurvey ] = useState<Survey>({
        link: buildSurveyLink({ userIdQuery, yMetricaId })
    });

    const handleClose = useCallback(() => {
        disableFeedbackReappearance();
        handleCloseModal();
    }, [ disableFeedbackReappearance, handleCloseModal ]);

    useEffect(() => {
        setSurvey(survey => ({
            ...survey,
            // https://help.anketolog.ru/p/dopolnitelnye-parametry - как передавать пользовательские данные в опрос
            link: buildSurveyLink({ userIdQuery, yMetricaId }),
        }));
    }, [ userIdQuery, yMetricaId ]);

    return (
        <Modal opened={modalIsOpen} size='l' onClose={handleClose} dataTest='feedback-survey'>
            <iframe className={cn('iframe')} name='survey' src={survey.link} data-scroll-lock-ignore />
        </Modal>
    );
};
