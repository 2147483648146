import React from 'react';

import { IconList, IconYMap } from '@search/ymap/src/Icon';

import { useClassifiedOfferDataLayerPush } from '../../../../offer/analytics/ClassifiedOfferGtmContext';
import { OfferYMapDesktopSwitchContainer } from './Container';
import { OfferYMapDesktopSwitchLink } from './Link';

type OfferYMapDesktopLinkId = 'map' | 'list';

export function OfferYMapDesktopSwitch({
    active,
    className,
    onClick,
    listUrl,
    mapUrl,
    dataTest = 'ymap-switch',
    dark = false
}: {
    dataTest?: string;
    className?: string;
    listUrl: string;
    mapUrl: string;
    dark?: boolean;
    onClick: (id: OfferYMapDesktopLinkId) => void;
    active: OfferYMapDesktopLinkId;
}) {
    const dataLayerPush = useClassifiedOfferDataLayerPush();

    return (
        <OfferYMapDesktopSwitchContainer dark={dark} className={className}>
            <OfferYMapDesktopSwitchLink
                dark={dark}
                dataTest={`${dataTest}-list`}
                href={listUrl}
                active={active === 'list'}
                onClick={() => {
                    onClick('list');
                    dataLayerPush({ event: 'classified_map_click_to_serp' });
                }}
                icon={<IconList />}
            >
                Список
            </OfferYMapDesktopSwitchLink>
            <OfferYMapDesktopSwitchLink
                dark={dark}
                dataTest={`${dataTest}-map`}
                href={mapUrl}
                active={active === 'map'}
                onClick={() => {
                    onClick('map');
                    dataLayerPush({ event: 'classified_serp_click_to_map' });
                }}
                icon={<IconYMap />}
            >
                На карте
            </OfferYMapDesktopSwitchLink>
        </OfferYMapDesktopSwitchContainer>
    );
}
