import { SeoMapper } from '@search/router/src';
import { SellType } from '@search/filter-enums/enums/SellType';

export const sellTypeMapper = new SeoMapper('sellTypeMapper', {
    unknown: SellType.UNKNOWN,
    secondary_primary: SellType.SECONDARY_PRIMARY,
    secondary: SellType.SECONDARY,
    primary_reassignment: SellType.PRIMARY_REASSIGNMENT,
    primary: SellType.PRIMARY,
    alternative: SellType.ALTERNATIVE
} as const);
