import React, { useCallback } from 'react';

import { Checkbox } from '@vtblife/uikit';
import { PaymentType } from '@search/filter-enums/enums';

import { PaymentTypeFilter } from '../../../../models/Filter';
import { Filter2 } from '../../../new_filter/Filter2';
import { CheckboxGroup2 } from '../../../common/CheckboxGroup2';

const ITEMS = [
    [ PaymentType.ARMY_MORTGAGE, 'Военная ипотека' ],
    [ PaymentType.INSTALLMENT, 'Рассрочка' ],
    [ PaymentType.MOTHER_CAPITAL, 'Материнский капитал' ]
];

interface IProps {
    filter: PaymentTypeFilter;
    handleChange: (filter: PaymentTypeFilter) => void;
}

export const DesktopPaymentTypeFilterView: React.FC<IProps> = ({
    filter,
    handleChange
}) => {
    const onChange = useCallback(val => {
        if (val === null) {
            return;
        }

        if (Array.isArray(val)) {
            handleChange(new PaymentTypeFilter(val));
        }
    }, [ handleChange ]);

    return (
        <Filter2 filterTitle='Покупка'>
            <CheckboxGroup2
                mode='check'
                name='paymentType'
                onChange={onChange}
                value={filter.type}
                colRestriction={2}
                isFilter
            >
                {
                    ITEMS.map(([ value, label ]) => (
                        <Checkbox
                            variant='filter'
                            key={value}
                            name={value}
                            block
                        >
                            {label}
                        </Checkbox>
                    ))
                }
            </CheckboxGroup2>
        </Filter2>
    );
};
