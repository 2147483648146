/* eslint-disable @typescript-eslint/no-floating-promises */
import React from 'react';
import { downloadOfferPdf } from '@search/vtbeco-frontend-core/domain/share/SharePrintButton';
import { isProfessional } from '@search/auth/src/helpers/isProfessional';
import { useM2AuthContext } from '@search/auth/src/M2AuthProvider';

import { useClassifiedOfferDataLayerPush } from '../../../../domain/offer/analytics/ClassifiedOfferGtmContext';
import { favoritedIds } from '../../../../domain/offer/ymap/page/useOfferYMapPageState2';
import { useFavoritesModal } from './FavoritesModal';
import { useFavoritesMutationSafe } from './FavoritesMutation';
import { useHiddenMutationSafe } from './HiddenMutation';

type Setters<State> = {
    [K in keyof State]: (args: State[K]) => void
}

function useRefState<State extends Object>(initialState: State, deps?: unknown[]) {
    const [ , setCount ] = React.useState(0);
    const refresh = React.useCallback(() => setCount(p => p + 1), []);

    return React.useMemo(() => {
        const get = { ...initialState };
        const set = {} as Setters<State>;
        const setAll = (newState: State) => Object.assign(get, newState);

        Object.keys(get).forEach((key: any) => {
            set[key as keyof State] = (v: State[keyof State]) => {
                get[key as keyof State] = v;
                refresh();
            };
        });

        return { set, get, setAll };
    }, deps ?? []);
}

export function useFavoritesButtonState({
    isFavorite: isFavoriteInitial,
    noteTextInitial,
    id: offerId,
    eventAddId,
    organizationName,
    forceFavoriteUpdate
} : {
    forceFavoriteUpdate?(): void;
    noteTextInitial?: string | null;
    isFavorite: boolean;
    id: string;
    eventAddId?: string | null;
    organizationName?: string | null;
}) {
    const { auth } = useM2AuthContext();
    const isProf = isProfessional(auth.user?.currentRoleType);

    const dataLayerPush = useClassifiedOfferDataLayerPush();
    const favoritesMutationSafe = useFavoritesMutationSafe();

    const { setError } = useFavoritesModal();

    const [ isSaving, setIsSaving ] = React.useState(false);

    const pendingArgs = favoritesMutationSafe.argsById(offerId);

    const favorite = useRefState({
        isFavorite: pendingArgs?.isFavorite ?? isFavoriteInitial,
        // Если null - запендили удаление текста, если undefined, значит ничего не запендено, показываем noteInitial
        noteText: pendingArgs?.noteText === null ? '' : (pendingArgs?.noteText ?? noteTextInitial ?? ''),
    }, [ offerId ]);

    React.useEffect(() => {
        const favoriteState = favorite.get.isFavorite;
        const favoriteString = favoriteState.toString();

        if (sessionStorage?.getItem(`favorite-${offerId}`) !== favoriteString) {
            sessionStorage?.setItem(`favorite-${offerId}`, `${favoriteString}`);
        }
    }, []);

    const depsObj = {
        offerId,
        favoritesMutationSafe: favoritesMutationSafe.run,
        setError,
        eventAddId,
        organizationName
    };
    const depsRef = React.useRef(depsObj);

    depsRef.current = depsObj;

    const updateFavorite = React.useCallback(async (next: Partial<typeof favorite.get>) => {
        const deps = depsRef.current;

        if (deps.eventAddId && next.isFavorite) {
            dataLayerPush({
                event: deps.eventAddId,
                offerId: deps.offerId,
                organizationName: deps.organizationName
            });
        }

        const noteText = next.isFavorite === false ? '' : (next.noteText ?? favorite.get.noteText);

        const req = { id: deps.offerId, ...favorite.get, ...next, offerId: deps.offerId, noteText };

        try {
            setIsSaving(true);
            const res = await deps.favoritesMutationSafe(req);
            const error = res?.data?.editFavoritePersonal2.firstError;

            if (error) throw error;
            deps.setError(undefined);
            favorite.setAll(req);
            if (req.isFavorite) favoritedIds.add(req.id);
            else favoritedIds.delete(req.id);
            forceFavoriteUpdate?.();
        } catch (e) {
            deps.setError(e);
            sessionStorage?.setItem(`favorite-${offerId}`, 'false');
        } finally {
            setIsSaving(false);
        }
    }, [ favorite, forceFavoriteUpdate ]);

    const favoriteToggle = React.useCallback((e: React.MouseEvent<Element, MouseEvent>) => {
        e.stopPropagation();
        const isFavorite = ! favorite.get.isFavorite;

        sessionStorage?.setItem(`favorite-${offerId}`, `${isFavorite}`);

        updateFavorite({ isFavorite });
    }, [ updateFavorite ]);

    const [ isNoteEditing, setNoteEditing ] = React.useState(false);
    const noteEditOpen = React.useCallback(() => setNoteEditing(true), [ ]);
    const noteEditClose = React.useCallback(() => setNoteEditing(false), [ ]);
    const handleDownloadPdf = React.useCallback(() => {
        downloadOfferPdf(offerId);
    }, [ ]);

    const noteSave = React.useCallback((
        { note }: { note?: string }
    ) => {
        dataLayerPush({ event: 'classified_save_note_click' });
        updateFavorite({ isFavorite: true, noteText: note }).finally(noteEditClose);
    }, [ updateFavorite ]);

    const noteDelete = React.useCallback(() => {
        updateFavorite({ noteText: '' }).finally(noteEditClose);
    }, [ updateFavorite ]);

    React.useEffect(() => {
        if (sessionStorage?.getItem(`favorite-${offerId}`) === 'true') favorite?.set.isFavorite(true);
        if (sessionStorage?.getItem(`favorite-${offerId}`) === 'false') favorite?.set.isFavorite(false);
    }, [ typeof window !== 'undefined' ? sessionStorage?.getItem(`favorite-${offerId}`) : undefined ]);

    return {
        isProfessional: isProf,
        isFavorite: favorite.get.isFavorite,
        isSaving,
        setIsFavorite: favorite.set.isFavorite,
        favoriteToggle,

        noteText: favorite.get.noteText,
        isNoteEditing,
        updateFavorite,
        noteSave,
        noteDelete,
        noteEditOpen,
        noteEditClose,
        handleDownloadPdf,
    };
}

export type UseFavoritesButtonStateType = ReturnType<typeof useFavoritesButtonState>;

export function useHiddenButtonState({
    isHidden: isHiddenInitial,
    id: offerId
} : {
    isHidden: boolean;
    id: string;
}) {
    const hiddenMutationSafe = useHiddenMutationSafe();

    const [ isSaving, setIsSaving ] = React.useState(false);

    const pendingArgs = hiddenMutationSafe.argsById(offerId);

    const hidden = useRefState({
        isHidden: pendingArgs?.isHidden ?? isHiddenInitial,
    }, [ offerId ]);

    const depsObj = {
        offerId,
        hiddenMutationSafe: hiddenMutationSafe.run,
    };

    const depsRef = React.useRef(depsObj);

    depsRef.current = depsObj;

    const updateHidden = React.useCallback(async (next: Partial<typeof hidden.get>) => {
        const deps = depsRef.current;

        const req = { id: deps.offerId, ...hidden.get, ...next, offerId: deps.offerId };

        try {
            setIsSaving(true);
            const res = await deps.hiddenMutationSafe(req);
            const error = res?.data?.editHiddenPersonal.firstError;

            if (error) throw error;
            hidden.setAll(req);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
        } finally {
            setIsSaving(false);
        }
    }, [ hidden ]);

    const hiddenToggle = React.useCallback((e: React.MouseEvent<Element, MouseEvent>) => {
        e.stopPropagation();
        const isHidden = ! hidden.get.isHidden;

        updateHidden({ isHidden });
    }, [ updateHidden ]);

    return {
        isHidden: hidden.get.isHidden,
        hiddenToggle,
        isSaving
    };
}
