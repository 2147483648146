import { RegionIdEnum } from '@search/filter-enums/enums/Region';

import { SeoMapper } from '@search/router/src';
import { isSpbWide, isMskWide } from '@search/helpers/src/isRegion';
import { mskDistrictMapper, mskMetroMapper } from './msk';
import { spbDistrictMapper, spbMetroMapper } from './spb';
import { mskCityMapper } from './msk/mskCityMapper';
import { spbCityMapper } from './spb/spbCityMapper';
import { regionMetroSlugMapper } from './regionMetroSlugMapper';

export const regionMapper = new SeoMapper('regionMapper', {
    moskva: RegionIdEnum.MSK,
    'moskva-i-oblast': RegionIdEnum.MSK_AND_MSK_OBL,
    'sankt-peterburg-i-oblast': RegionIdEnum.SPB_AND_LEN_OBL,
    'sankt-peterburg': RegionIdEnum.SPB,
    'moskovskaya-oblast': RegionIdEnum.MSK_OBL,
    'leningradskaya-oblast': RegionIdEnum.LEN_OBL
} as const);

export function getMetroMapperByRegion(region: number) {
    if (isMskWide(region)) return mskMetroMapper;
    if (isSpbWide(region)) return spbMetroMapper;

    return regionMetroSlugMapper(region);
}

export function getDistrictMapperByRegion(
    region: ReturnType<typeof regionMapper.Type>
) {
    if (isMskWide(region)) return mskDistrictMapper;
    if (isSpbWide(region)) return spbDistrictMapper;
}

export function getCityMapperByRegion(
    region: number
) {
    if (isMskWide(region)) return mskCityMapper;
    if (isSpbWide(region)) return spbCityMapper;
}
