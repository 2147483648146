import React, { useCallback } from 'react';

import { Checkbox } from '@vtblife/uikit';
import { BuildingClass } from '@search/filter-enums/enums';

import { BuildingClassFilter } from '../../../../models/Filter';
import { Filter2 } from '../../../new_filter/Filter2';
import { CheckboxGroup2 } from '../../../common/CheckboxGroup2';

const ITEMS = [
    [ BuildingClass.ECONOMY, 'Эконом' ],
    [ BuildingClass.COMFORT, 'Комфорт' ],
    [ BuildingClass.COMFORT_PLUS, 'Комфорт+' ],
    [ BuildingClass.BUSINESS, 'Бизнес' ],
    [ BuildingClass.ELITE, 'Элитный' ]
];

interface IProps {
    filter: BuildingClassFilter;
    handleChange: (filter: BuildingClassFilter) => void;
}

export const DesktopBuildingClassFilterView: React.FC<IProps> = ({
    filter,
    handleChange
}) => {
    const onChange = useCallback(val => {
        if (val === null) {
            return;
        }

        if (Array.isArray(val)) {
            handleChange(new BuildingClassFilter(val));
        }
    }, [ handleChange ]);

    return (
        <Filter2 filterTitle='Класс ЖК'>
            <CheckboxGroup2
                mode='check'
                name='buildingClass'
                onChange={onChange}
                value={filter.buildingClass}
                colRestriction={3}
                isFilter
            >
                {
                    ITEMS.map(([ value, label ]) => (
                        <Checkbox
                            variant='filter'
                            key={value}
                            name={value}
                            block
                        >
                            {label}
                        </Checkbox>
                    ))
                }
            </CheckboxGroup2>
        </Filter2>
    );
};
