import React from 'react';

interface IHighlightProps {
  text: string;
  highlight?: string;
}

function escapeRegExp(str: string) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export const Highlight: React.FC<IHighlightProps> = ({
    text, highlight = ''
}) => {
    const tokens = highlight.toLowerCase().split(' ');

    let result = '';

    for (let i = 0; i < tokens.length; i++) {
        const token = tokens[i];

        result += `(${token})${(i === tokens.length - 1) ? '' : '|'}`;
    }

    const sanitizedRegexp = escapeRegExp(result);

    const parts = text.split(new RegExp(sanitizedRegexp, 'gi')).filter(part => part !== undefined && part !== '');

    return (<span> { parts.map((part, i) =>
        // eslint-disable-next-line @typescript-eslint/prefer-includes
        <span key={i} style={tokens.indexOf(part.toLowerCase()) !== -1 ? { fontWeight: 'bold' } : {}}>
            { part }
        </span>)
    } </span>);
};
