export type ExtractId<T> = T extends { id: infer Id } ? Id : never;

export function isPresent<T>(t: T | undefined | null | void): t is T {
    return t !== undefined && t !== null;
}

export function arrayToMap<Item extends { id: any }>(array: readonly Item[]) {
    const map = new Map<ExtractId<Item>, Item>();

    for (const item of array) map.set(item.id, item);
    return map;
}

interface Flavoring<FlavorT> {
    _type?: FlavorT;
  }
export type Flavor<T, FlavorT> = T & Flavoring<FlavorT>

export type NonMethods<O extends {}> = {
    [P in keyof O]: O[P] extends (...args: any[]) => any ? never : O[P];
};

export function titleSorter<T extends { title: string }>(a: T, b: T): number {
    if (a.title > b.title) return 1;
    if (a.title < b.title) return -1;
    return 0;
}

export class CopyObject {
    copy(params: Partial<NonMethods<this>>): this {
        let changed = false;
        const next = Object.assign(new (this.constructor as any)(), this);

        for (const key in params) {
            if (params[key as keyof this] !== this[key as keyof this]) {
                changed = true;
                next[key] = params[key];
            }
        }

        return changed ? next : this;
    }
}
