import React, { useCallback, useState } from 'react';
import { Typography } from '@vtblife/uikit';
import { Size } from '@vtblife/uikit/legacy';
import {
    SuggestTypeEnum,
    SuggestItemDeveloper
} from '@search/graphql-typings';
import {
    graphql,
    useGqlContext
} from '@search/gql-client/src';

import classname from '@search/classname/src';
import { Highlight } from '@search/vtbeco-ui/src/components/controls/Highlight/Highlight';
import Suggest from '@search/vtbeco-ui/src/components/controls/Suggest';
import { Modal, ModalProps, ModalSize } from '@search/vtbeco-ui/src/components/Modal';
import { BorderRadius } from '@search/vtbeco-ui/src/components/Paper';

import { ModalHeading } from '../../../../../mobile/components/ModalHeading';

import useDebouncedCallback from '../../../../../common/hooks/useDebouncedCallback';

import type {
    MobileDeveloperSuggestModalQuery$data as MobileDeveloperSuggestModalQueryResponse,
    MobileDeveloperSuggestModalQuery$variables as MobileDeveloperSuggestModalQueryVariables
} from './__generated__/MobileDeveloperSuggestModalQuery.graphql';

import './MobileDeveloperSuggestModal.css';

const cn = classname.bind(null, 'MobileDeveloperSuggestModal');

const QUERY = graphql`
    query MobileDeveloperSuggestModalQuery($text: String! $suggestTypes: [SuggestTypeEnum!]) {
        suggest(text: $text suggestTypes: $suggestTypes) {
            __typename

            ... on SuggestItemDeveloper {
                label
                data {
                    id
                    scope
                }
                developer {
                    title
                    id
                }
            }
        }
    }
`;

interface IMobileDeveloperSuggestModalProps extends Pick<ModalProps, 'uikitZIndexEnabled'> {
    hide: () => void;
    onChange: (value?: SuggestItemDeveloper) => void;
    value?: SuggestItemDeveloper;
    placeholder: string;
}

const MobileDeveloperSuggestModal: React.FC<IMobileDeveloperSuggestModalProps> = ({
    hide,
    onChange,
    value,
    placeholder,
    uikitZIndexEnabled
}) => {
    const gql = useGqlContext();

    const handleSelect = useCallback((val?: SuggestItemDeveloper) => {
        onChange(val);
        val && hide();
    }, [ hide, onChange ]);

    const handleClear = useCallback(() => {
        onChange();
    }, [ onChange ]);

    const [ suggestions, setSuggestions ] = useState<SuggestItemDeveloper[]>([]);

    const [ debouncedSuggest ] = useDebouncedCallback(
        (text: string) => {
            gql.execute<MobileDeveloperSuggestModalQueryVariables, MobileDeveloperSuggestModalQueryResponse>(
                QUERY, {
                    text,
                    suggestTypes: [ SuggestTypeEnum.Developer ]
                })
                .then(data => {
                    if (data.data) {
                        setSuggestions(data?.data?.suggest || []);
                    } else {
                        setSuggestions([]);
                    }
                }).catch(() => setSuggestions([]));
        },
        300
    );

    const fetchSuggestions = (text: string) => {
        debouncedSuggest(text);
    };

    return (
        <Modal
            size={ModalSize.FULL_L}
            borderRadius={BorderRadius.none}
            uikitZIndexEnabled={uikitZIndexEnabled}
            fullScreen
        >
            <ModalHeading
                text='Застройщик'
                onClose={hide}
            />
            <div className={cn('select')}>
                <Suggest
                    autoFocus
                    clear
                    width='max'
                    size='xxl'
                    inputSize={Size.Small}
                    onClear={handleClear}
                    borderOut={false}
                    placeholder={placeholder}
                    suggestion={value}
                    suggestions={suggestions}
                    fetchSuggestions={fetchSuggestions}
                    renderList={(props, { focusedIndex, label }, handlers) => (
                        <div className={cn('suggest')}>
                            {
                                suggestions.map((suggestion, i) => (
                                    <div
                                        key={i}
                                        className={cn('suggestion', {
                                            highlighted: i === focusedIndex
                                        })}
                                        onMouseDown={e => handlers.handleItemMouseDown(e, i)}
                                        onMouseEnter={e => handlers.handleItemMouseDown(e, i)}
                                    >
                                        <div>
                                            <Highlight
                                                text={suggestion.label || ''}
                                                highlight={label || ''}
                                            />
                                            {
                                                suggestion?.data?.scope ? (
                                                    <Typography
                                                        variant='small-alone'
                                                        color='secondary'
                                                    >
                                                        {suggestion.data.scope}
                                                    </Typography>
                                                ) : null
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    )}
                    renderEmptyList={({ emptyText }) => (
                        <div className={cn('empty')}>
                            {emptyText}
                        </div>
                    )}
                    onSelect={handleSelect}
                    clearOnSelect={false}
                />
            </div>
        </Modal>
    );
};

export default MobileDeveloperSuggestModal;
