import { s } from '@search/router/src/schema/schema';

import {
    authorTypeMapper, bankPledgeMapper, bathroomMapper, categoryTypeMapper, dealTypeMapper,
    furnitureMapper, layoutImageMapper, livingFacilityMapper, newBuildingMapper,
    renovationMapper, roomMapper, sellTypeMapper, wallsMapper, buildEpochMapper, finishingMapper
} from '../mappers';
import { livingTenantMapper } from '../mappers/livingTenantMapper';
import { newBuildingTypeMapper } from '../mappers/newBuilding/mappers';
import { parkingsMapper } from '../mappers/parkingsMapper';
import { sortOffersMapper } from '../mappers/sortOffersMapper';
import { windowsDirectionMapper } from '../mappers/windowsDirectionMapper';
import { mskMetroBranchMapper } from '../mappers/regions/msk';

export const SearchType = s.rec({
    segments: s.opt(s.rec({
        isGetEmpty: s.bool,
        infrastructure: s.nullOpt(s.list(s.str)),
        balcony: s.nullOpt(s.str),
        hasLift: s.nullOpt(s.str),
        kitchenAreaMin: s.nullOpt(s.num),
        tagCount: s.num,
        rooms: s.list(roomMapper.Type),
        addresses: s.list(s.num),
        districtIds: s.list(s.num),
        nearMetroSegment: s.opt(s.str),
        metroIds: s.list(s.num),
        metroBranch: s.nullOpt(mskMetroBranchMapper.Type),
        floorMax: s.nullOpt(s.num),
        floorMin: s.nullOpt(s.num),
        floorsTotalMin: s.nullOpt(s.num),
        floorsTotalMax: s.nullOpt(s.num),
        cellHeight: s.nullOpt(s.str),
        totalAreaMax: s.nullOpt(s.num),
        renovation: s.list(renovationMapper.Type),
        last: s.nullOpt(s.str),
        nearbyOptions: s.nullOpt(s.str),
        renovationProgram: s.nullOpt(s.str),
        newBuilding: s.list(newBuildingMapper.Type),
        bankPledgeOptions: s.nullOpt(bankPledgeMapper.Type),
        apartments: s.nullOpt(s.str),
        bathroom: s.list(bathroomMapper.Type),
        furniture: s.list(furnitureMapper.Type),
        parkings: s.list(parkingsMapper.Type),
        author: s.list(authorTypeMapper.Type),
        seoMortgageAllowed: s.opt(s.bool),
        houseCategory: s.list(s.str),
        landStatus: s.list(s.str),
        bathroomLocation: s.nullOpt(s.list(s.str)),
        rentPrepayment: s.nullOpt(s.str),
        heating: s.nullOpt(s.list(s.str)),
        gas: s.nullOpt(s.list(s.str)),
        water: s.nullOpt(s.list(s.str)),
        electricity: s.nullOpt(s.list(s.str)),
        sewerage: s.nullOpt(s.list(s.str)),
        communications: s.nullOpt(s.str),
        priceMax: s.opt(s.num),
        priceMin: s.opt(s.num),
        transport: s.opt(s.str),
        transportTimeLimit: s.opt(s.str),
        landAreaMin: s.nullOpt(s.num),
        landAreaMax: s.nullOpt(s.num),
        builtYearMin: s.opt(s.num),
        builtYearMax: s.opt(s.num)
    })),
    geoSlugs: s.opt(s.list(s.str)),
    seoMortgageAllowed: s.opt(s.bool),
    region: s.num,
    rooms: s.list(roomMapper.Type),
    roomsInOffer: s.list(s.num),
    renovation: s.list(renovationMapper.Type),
    finishingType: s.list(finishingMapper.Type),
    commissioning: s.nullOpt(s.str),

    addresses: s.list(s.num),
    metroIds: s.list(s.num),
    metroBranch: s.nullOpt(mskMetroBranchMapper.Type),
    districtIds: s.list(s.num),
    newBuildingIds: s.list(s.num),

    newBuildingType: s.opt(newBuildingTypeMapper.Type),
    newBuildingName: s.opt(s.str),

    dealType: dealTypeMapper.Type,
    categoryType: categoryTypeMapper.Type,

    totalAreaMin: s.nullOpt(s.num),
    totalAreaMax: s.nullOpt(s.num),

    landAreaMin: s.nullOpt(s.num),
    landAreaMax: s.nullOpt(s.num),

    houseCategory: s.list(s.str),
    houseAreaMin: s.nullOpt(s.num),
    houseAreaMax: s.nullOpt(s.num),
    bathroomLocation: s.nullOpt(s.list(s.str)),

    priceMin: s.nullOpt(s.num),
    priceMax: s.nullOpt(s.num),
    mortgagePayment: s.nullOpt(s.num),
    priceUnit: s.nullOpt(s.str),

    livingAreaMin: s.nullOpt(s.num),
    livingAreaMax: s.nullOpt(s.num),

    kitchenAreaMin: s.nullOpt(s.num),
    kitchenAreaMax: s.nullOpt(s.num),

    builtYearMin: s.nullOpt(s.num),
    builtYearMax: s.nullOpt(s.num),

    floorMin: s.nullOpt(s.num),
    floorMax: s.nullOpt(s.num),

    last: s.nullOpt(s.str),
    first: s.nullOpt(s.str),

    floorsTotalMin: s.nullOpt(s.num),
    floorsTotalMax: s.nullOpt(s.num),

    cellHeight: s.nullOpt(s.str),

    newBuilding: s.list(newBuildingMapper.Type),
    bathroom: s.list(bathroomMapper.Type),

    landStatus: s.list(s.str),

    heating: s.list(s.str),
    gas: s.list(s.str),
    water: s.list(s.str),
    electricity: s.list(s.str),
    sewerage: s.list(s.str),

    // @deprecated use transport & transportTimeLimit
    transportType: s.nullOpt(s.str),
    // @deprecated use transport & transportTimeLimit
    timeLimit: s.nullOpt(s.str),

    transport: s.nullOpt(s.str),
    transportTimeLimit: s.nullOpt(s.str),

    livingFacility: s.list(livingFacilityMapper.Type),
    furniture: s.list(furnitureMapper.Type),
    livingTenantOptions: s.list(livingTenantMapper.Type),
    utilitiesIncluded: s.nullOpt(s.bool),
    depositType: s.nullOpt(s.str),
    commisionType: s.nullOpt(s.str),
    rentPrepayment: s.nullOpt(s.str),
    walls: s.list(wallsMapper.Type),
    parkings: s.list(parkingsMapper.Type),
    author: s.list(authorTypeMapper.Type),
    userId: s.nullOpt(s.str),
    sellType: s.list(sellTypeMapper.Type),
    balcony: s.nullOpt(s.str),
    photoOptions: s.nullOpt(s.str),
    renovationProgram: s.nullOpt(s.str),
    nearbyOptions: s.nullOpt(s.str),
    sellerOrganizationName: s.nullOpt(s.str),
    layoutImageOptions: s.list(layoutImageMapper.Type),
    bankPledgeOptions: s.nullOpt(bankPledgeMapper.Type),
    apartments: s.nullOpt(s.str),
    infrastructure: s.nullOpt(s.list(s.str)),
    hasLift: s.nullOpt(s.str),

    windowsDirection: s.list(windowsDirectionMapper.Type),

    buildEpoch: s.list(buildEpochMapper.Type),

    onlineView: s.nullOpt(s.str),

    pageNumber: s.num,
    pageSize: s.num,
    sort: s.opt(sortOffersMapper.Type),

    tile: s.opt(s.rec({
        x: s.num,
        y: s.num,
        z: s.num
    })),
    selectedOffers: s.opt(s.list(s.str)),
    mapBounds: s.opt(s.rec({
        geohash: s.opt(s.str),
        bounds: s.opt(s.rec({
            min: s.rec({
                x: s.num,
                y: s.num
            }),
            max: s.rec({
                x: s.num,
                y: s.num
            })
        })),
        polygon: s.opt(s.list(s.rec({
            x: s.num,
            y: s.num
        })))
    })),
    utm_source: s.opt(s.str),
    utm_medium: s.opt(s.str),
    utm_campaign: s.opt(s.str),
    utm_content: s.opt(s.str),
    utm_term: s.opt(s.str),
});
