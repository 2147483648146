import React, { useCallback } from 'react';

import { Accreditation } from '@search/filter-enums/enums';
import { AccreditationFilter } from '../../../../models/Filter';
import MobileFilter from '../../../filter/MobileFilter';
import { MultiSelect, MultiSelectOption } from '../../common/MultiSelect';

const selectOptions: MultiSelectOption[] = [ {
    value: Accreditation.ACCREDITED_BY_VTB,
    label: 'Аккредитовано ВТБ',
    className: 'col-xs-12 filter-item-gap'
} ];

interface IProps {
    filter: AccreditationFilter;
    handleChange: (filter: AccreditationFilter) => void;
}

export const MobileAccreditationFilterView: React.FC<IProps> = ({
    filter,
    handleChange
}) => {
    const onChange = useCallback(value => {
        handleChange(new AccreditationFilter(value));
    }, [ handleChange ]);

    return (
        <MobileFilter
            filterTitle='Аккредитация'
        >
            <MultiSelect
                name='accreditation'
                className='container container__clamp'
                options={selectOptions}
                value={filter.accreditation}
                handleChange={onChange}
            />
        </MobileFilter>
    );
};
