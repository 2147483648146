import './DistrictSelectGroup.css';

import React from 'react';
import { Typography } from '@vtblife/uikit';
import Icon from '@vtblife/uikit-icons';

import classname from '@search/classname/src';
interface DistrictSelectGroupProps {
    title: string;
    checked: boolean;
    onCheck: (checked: boolean) => void;
}

const cnStation = classname.bind(null, 'DistrictSelect__Item');

export const DistrictSelectGroup: React.FC<DistrictSelectGroupProps> = ({
    title,
    checked,
    onCheck
}) => {
    return (
        <div
            role='button'
            className={cnStation('group', { checked })}
            onClick={() => onCheck(! checked)}
        >
            <Typography variant='promo-h4' responsive bold>
                {title}
            </Typography>
            {checked ? <Icon name='check' /> : null}
        </div>
    );
};
