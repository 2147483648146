/* eslint-disable complexity */
import React, { useState, useCallback, ReactNode, Fragment } from 'react';
import groupBy from 'lodash/groupBy';

import { Typography } from '@vtblife/uikit';

import { RootRegionsApplicationEnum, SuggestTypeEnum } from '@search/graphql-typings';
import { SuggestItemTypeEnum } from '@search/graphql-gateway/typings';
import { graphql, useGqlContext } from '@search/gql-client/src';

import classname from '@search/classname/src';
import { IUseControlReturnedHandlers } from '@search/hooks/src/useControl';

import { Size } from '@search/vtbeco-ui/src/types';
import { Highlight } from '@search/vtbeco-ui/src/components/controls/Highlight/Highlight';
import Suggest, { IInitialSuggestState, ISuggestion, ISuggestProps } from '@search/vtbeco-ui/src/components/controls/Suggest';

import { mskOblMetroStation, mskMetroStation } from '@search/offer-search-routes/src/mappers/regions/msk/mskMetroMapper';
import { mskOblDistrict, mskDistrict } from '@search/offer-search-routes/src/mappers/regions/msk/mskDistrictMapper';
import { mskOblCity, mskCity } from '@search/offer-search-routes/src/mappers/regions/msk/mskCityMapper';
import { mskOblRayon, mskRayon } from '@search/offer-search-routes/src/mappers/regions/msk/mskRayonMapper';

import { lenOblMetroStation, spbMetroStation } from '@search/offer-search-routes/src/mappers/regions/spb/spbMetroMapper';
import { lenOblDistrict, spbDistrict } from '@search/offer-search-routes/src/mappers/regions/spb/spbDistrictMapper';
import { lenOblCity, spbCity } from '@search/offer-search-routes/src/mappers/regions/spb/spbCityMapper';

import type { UseToggleChainResult } from '@search/vtbeco-frontend-core/view/common/components/Price/popper/useToggleChain';

import { RegionIdEnum } from '../../../../enums';
import useDebouncedCallback from '../../../../view/common/hooks/useDebouncedCallback';
import { ArrayElement } from '../../../../types';

import { SuggestPopup } from './SuggestPopup';
import { MetroMark } from './MetroMark';
import './GeoSuggest.css';
import type {
    GeoSuggestQuery$data as GeoSuggestQueryResponse,
    GeoSuggestQuery$variables as GeoSuggestQueryVariables
} from './__generated__/GeoSuggestQuery.graphql';

type ExtractSuggestItem<A> = A extends { __typename: '%other' } ? never : A;

export type SuggestionType = ExtractSuggestItem<
    NonNullable<
        ArrayElement<
            NonNullable<GeoSuggestQueryResponse['suggest']>
        >
    >
>;
export type SuggestionTypenameType = Exclude<SuggestionType['__typename'], '%other'>;
type SuggestionsByGroupType = Record<Partial<SuggestionTypenameType>, Array<SuggestionType>>;

export interface GeoSuggestProps {
    regionId: number;
    rootRegionsApp: RootRegionsApplicationEnum;
    onGeoChange: (ids: SuggestionType) => void;
    renderInput?: (
        props: {
            borderSide?: 'square' | 'rounded_square' | 'square_rounded';
            inputSize?: Size;
            placeholder?: string;
            disabled?: boolean;
            borderOut?: boolean;
        },
        state: IInitialSuggestState<ISuggestion>,
        handlers: IUseControlReturnedHandlers & {
            handleInputChange: (text: string) => void;
            handleInputKeyDown: (e: React.KeyboardEvent) => void;
        }
    ) => ReactNode;
    onInputToggleFocus?: (disabled: boolean) => void;
    autoFocus?: boolean;
    listBorderOut?: boolean;
    clear?: boolean;
    borderOut?: boolean;
    showNewBuildingsGroup?: boolean;
    startWithNewBuildings?: boolean;
    placeholder: string;
    placeholderWithNewBuildings?: string;
    limit?: number;
    suggestTypes?: SuggestTypeEnum[];
    spySuggest?: UseToggleChainResult;
    selected?: Set<number>;
}

const cn = classname.bind(null, 'GeoSuggest');

const TYPE_MAP: Record<SuggestionTypenameType, string> = {
    SuggestItemMetro: 'Метро',
    SuggestItemAddress: 'Адрес',
    SuggestItemDistrict: 'Районы',
    SuggestItemNewBuilding: 'Новостройки',
    SuggestItemDeveloper: 'Застройщики'
};

const QUERY = graphql`
    query GeoSuggestQuery(
        $text: String!
        $rootRegionsApp: RootRegionsApplicationEnum
        $suggestTypes: [SuggestTypeEnum!]
        $userRegionId: Int
        $limit: Int
    ) {
        suggest(
            text: $text
            rootRegionsApp: $rootRegionsApp
            suggestTypes: $suggestTypes
            userRegionId: $userRegionId
            limit: $limit
        ) {
            __typename

            ... on SuggestItemNewBuilding {
                label
                data {
                    id
                    scope
                    regionId
                }
                newBuilding {
                    id
                    type
                    name
                    title {
                        nominative
                    }
                }
            }

            ...on SuggestItemDeveloper {
                label
                data {
                    id
                    scope
                }
                developer {
                    id
                    title
                }
            }

            ... on SuggestItemDistrict {
                label
                data {
                    id
                    scope
                    regionId
                }
                district {
                    id
                    translit
                    parentId
                    mainName
                    displayName
                    displayNameWithContext
                    kind
                    locative
                }
            }

            ... on SuggestItemMetro {
                label
                data {
                    id
                    scope
                    regionId
                }
                metroStation {
                    id
                    allRoutesColorsList
                    interchangedStations
                    lines
                }
            }

            ... on SuggestItemAddress {
                label
                data {
                    id
                    scope
                    regionId
                }
                address {
                    id
                    translit
                    kind
                    parentId
                    mainName
                    displayName
                    displayNameWithContext
                    locative
                }
            }
        }
    }
`;

interface GeoSuggestState {
    suggestionsByGroup: SuggestionsByGroupType;
    suggestions: SuggestionType[];
}

const renderSuggestItem = (item: SuggestionType, label: string) => {
    const scope = item?.data?.scope ? (
        <Typography
            variant='small-alone'
            color='secondary'
        >
            {item.data.scope}
        </Typography>
    ) : null;

    switch (item.__typename) {
        case SuggestItemTypeEnum.Metro: {
            return (
                <Fragment>
                    <div className={cn('list-item-suggest', { type: 'metro' })}>
                        {
                            item?.metroStation?.lines ? <MetroMark colors={item.metroStation.lines} /> : null
                        }
                        <Typography variant='primary'>
                            <Highlight text={item.label || ''} highlight={label} />
                        </Typography>
                    </div>
                    {scope}
                </Fragment>
            );
        }

        default:
            return (
                <Typography variant='primary'>
                    <Highlight text={item?.label || ''} highlight={label} />
                    {scope}
                </Typography>
            );
    }
};

const mskGeoIds = new Set(Object.values({
    ...mskMetroStation,
    ...mskRayon,
    ...mskDistrict,
    ...mskCity
}));

const mskOblGeoIds = new Set(Object.values({
    ...mskOblMetroStation,
    ...mskOblRayon,
    ...mskOblDistrict,
    ...mskOblCity
}));

const spbGeoIds = new Set(Object.values({
    ...spbMetroStation,
    ...spbDistrict,
    ...spbCity
}));

const lenOblGeoIds = new Set<number>(Object.values({
    ...lenOblMetroStation,
    ...lenOblDistrict,
    ...lenOblCity
}));

export const correctAndBroadenRegionId = (
    id: number,
    type: SuggestItemTypeEnum,
    currentRegionId: number
): number => {
    if (type !== SuggestItemTypeEnum.District && type !== SuggestItemTypeEnum.Metro) {
        return currentRegionId;
    }

    if (currentRegionId === RegionIdEnum.MSK && mskOblGeoIds.has(id)) {
        return RegionIdEnum.MSK_AND_MSK_OBL;
    }

    if (currentRegionId === RegionIdEnum.MSK_OBL && mskGeoIds.has(id)) {
        return RegionIdEnum.MSK_AND_MSK_OBL;
    }

    if (currentRegionId === RegionIdEnum.SPB && lenOblGeoIds.has(id)) {
        return RegionIdEnum.SPB_AND_LEN_OBL;
    }

    if (currentRegionId === RegionIdEnum.LEN_OBL && spbGeoIds.has(id)) {
        return RegionIdEnum.SPB_AND_LEN_OBL;
    }

    return currentRegionId;
};

export const correctSuggestion = (
    suggestion: SuggestionType,
    regionId: number
): [
    SuggestionType,
    number
] => {
    const id = suggestion?.data?.id;

    let newSuggestion = suggestion;

    if (suggestion?.__typename === SuggestItemTypeEnum.Address && Object.values({
        ...mskRayon,
        ...mskDistrict,
        ...mskCity,
        ...mskOblRayon,
        ...mskOblDistrict,
        ...mskOblCity,
        ...spbDistrict,
        ...spbCity,
        ...lenOblDistrict,
        ...lenOblCity
    }).includes(id)) {
        newSuggestion = {
            ...suggestion,
            __typename: SuggestItemTypeEnum.District,
            district: suggestion.address,
            address: undefined
        };
    }

    return [
        newSuggestion,
        correctAndBroadenRegionId(id, newSuggestion?.__typename, regionId)
    ];
};

export const GeoSuggest: React.FC<GeoSuggestProps> = ({
    regionId,
    rootRegionsApp = RootRegionsApplicationEnum.Unknown,
    onGeoChange,
    renderInput,
    onInputToggleFocus,
    autoFocus = false,
    clear = false,
    borderOut = true,
    listBorderOut = false,
    showNewBuildingsGroup,
    startWithNewBuildings = false,
    placeholder,
    placeholderWithNewBuildings,
    limit,
    suggestTypes,
    spySuggest,
    selected
}) => {
    const gql = useGqlContext();
    const [ suggestions, setSuggestions ] = useState<GeoSuggestState>({
        suggestionsByGroup: {
            SuggestItemMetro: [],
            SuggestItemAddress: [],
            SuggestItemDistrict: [],
            SuggestItemNewBuilding: [],
            SuggestItemDeveloper: []
        },
        suggestions: []
    });

    const [ debouncedSuggest ] = useDebouncedCallback((text: string) => {
        if (! text) return;

        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        gql.execute<GeoSuggestQueryVariables, GeoSuggestQueryResponse>(QUERY, {
            text,
            rootRegionsApp,
            userRegionId: regionId,
            limit,
            suggestTypes
        })
            .then(data => {
                let items = data?.data?.suggest
                    ?.filter(item => item && item.__typename !== '%other' && ! selected?.has(item.data.id)) ?? [];

                // Пока умеем искать только один ЖК
                if (! showNewBuildingsGroup) {
                    items = items.filter(item => item && item.__typename !== SuggestItemTypeEnum.NewBuilding);
                }

                let suggestionsByGroup = groupBy(items, '__typename') as SuggestionsByGroupType;

                // Делаем правильный порядок
                suggestionsByGroup = {
                    SuggestItemMetro: suggestionsByGroup.SuggestItemMetro ?? [],
                    SuggestItemAddress: suggestionsByGroup.SuggestItemAddress ?? [],
                    SuggestItemDistrict: suggestionsByGroup.SuggestItemDistrict ?? [],
                    SuggestItemNewBuilding: suggestionsByGroup.SuggestItemNewBuilding ?? [],
                    SuggestItemDeveloper: suggestionsByGroup.SuggestItemDeveloper ?? []
                };

                if (startWithNewBuildings) {
                    const { SuggestItemNewBuilding, SuggestItemDeveloper } = suggestionsByGroup;

                    suggestionsByGroup = {
                        // @ts-ignore
                        SuggestItemNewBuilding,
                        // @ts-ignore
                        SuggestItemDeveloper,
                        ...suggestionsByGroup
                    };
                }

                setSuggestions({
                    suggestionsByGroup,
                    suggestions: Object
                        .values(suggestionsByGroup)
                        .reduce<SuggestionType[]>((ret, groupedItems) =>
                            ret.concat(groupedItems as []), [])
                });
            });
    }, 300);

    const fetchSuggestions = (text: string) => {
        debouncedSuggest(text);
    };

    const setSuggestion = useCallback((suggestion: SuggestionType) => {
        const [ newSuggestion, newRegionId ] = correctSuggestion(suggestion, regionId);

        onGeoChange(newRegionId && regionId !== newRegionId ? {
            ...newSuggestion,
            data: {
                ...newSuggestion.data,
                regionId: newRegionId
            }
        } : newSuggestion);
    }, [ onGeoChange, regionId ]);

    const renderList = useCallback<NonNullable<ISuggestProps<SuggestionType>['renderList']>>(
        (props, { focusedIndex, label = '' }, handlers) => {
            let realIndex = 0;

            return (
                <div className={cn('list-container', { borderOut: listBorderOut })}>
                    <div
                        data-test='filter-geo-suggest'
                        className={cn('list', { borderOut: listBorderOut })}>
                        {
                            Object
                                .entries(suggestions.suggestionsByGroup)
                                .filter(([ , items ]) => Array.isArray(items) && items.length)
                                .map((ent, index, { length }) => {
                                    // eslint-disable-next-line max-len
                                    const [ group, items ] = ent as [ SuggestionTypenameType, SuggestionType[] ];

                                    return (
                                        <div
                                            key={group}
                                            data-test={`filter-geo-suggest-${group}`}
                                            className={cn('list-group', { borderOut: listBorderOut })}
                                        >
                                            <Typography
                                                variant='small-alone'
                                                className={cn('list-group-header', { borderOut: listBorderOut })}
                                            >
                                                {TYPE_MAP[group] || ''}
                                            </Typography>
                                            {
                                                items.map(item => {
                                                    const currentIndex = realIndex++;

                                                    const onMouseDown = onInputToggleFocus ? (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                                                        if (onInputToggleFocus) {
                                                            onInputToggleFocus(false);
                                                        }
                                                        handlers.handleItemMouseDown(e, currentIndex);
                                                    } : (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => handlers.handleItemMouseDown(e, currentIndex);

                                                    return (
                                                        <div
                                                            data-test='filter-geo-suggest-item'
                                                            id={`filter-geo-suggest-item-${item?.data?.id ?? currentIndex}`}
                                                            key={`${label}-${currentIndex}`}
                                                            className={cn('list-item', {
                                                                borderOut: listBorderOut,
                                                                highlight: currentIndex === focusedIndex
                                                            })}
                                                            onMouseEnter={e =>
                                                                handlers.handleItemMouseEnter(e, currentIndex)
                                                            }
                                                            onMouseDown={onMouseDown}
                                                        >
                                                            {item && renderSuggestItem(item, label)}
                                                        </div>
                                                    );
                                                })
                                            }
                                            {index < length - 1 && (
                                                <div className={cn('group-divider', {
                                                    borderOut: listBorderOut
                                                })} />
                                            )}
                                        </div>
                                    );
                                })
                        }
                    </div>
                </div>
            );
        },
        [ listBorderOut, onInputToggleFocus, suggestions.suggestionsByGroup ]
    );

    return (
        <div className={cn()}>
            <Suggest
                <SuggestionType>
                width='max'
                size='xxl'
                inputSize='s'
                clear={clear}
                borderOut={borderOut}
                autoFocus={autoFocus}
                suggestions={suggestions.suggestions}
                onClick={spySuggest?.show}
                renderList={
                    (props, suggestionState, handlers) => spySuggest ? (
                        <SuggestPopup hide={spySuggest?.hide} triggerElement={spySuggest?.triggerElement}>
                            {renderList(props, suggestionState, handlers)}
                        </SuggestPopup>
                    ) :
                        renderList(props, suggestionState, handlers)
                }
                renderInput={renderInput}
                onInputToggleFocus={onInputToggleFocus}
                renderEmptyList={
                    ({ emptyText }) => spySuggest ? (
                        <SuggestPopup hide={spySuggest?.hide} triggerElement={spySuggest?.triggerElement}>
                            <div className={cn('empty', { borderOut: listBorderOut })}>
                                {emptyText}
                            </div>
                        </SuggestPopup>
                    ) : (
                        <div className={cn('empty', { borderOut: listBorderOut })}>
                            {emptyText}
                        </div>
                    )
                }
                onSelect={setSuggestion}
                clearOnSelect
                placeholder={
                    showNewBuildingsGroup && placeholderWithNewBuildings ?
                        placeholderWithNewBuildings :
                        placeholder
                }
                fetchSuggestions={fetchSuggestions} />
        </div>
    );
};
