import React, {
    useRef,
    RefObject,
    HTMLAttributes,
    FunctionComponent,
    MutableRefObject
} from 'react';
import classname from '@search/classname/src';
import mergeRefs from '../../../lib/mergeRefs';
import {
    Size,
    View,
    Tone,
    SizeEnum,
    ToneEnum,
    ViewEnum
} from '../../../types';

import { PopupTail } from '../Popup/PopupTail';

import {
    Direction
} from '../Popup/types';

import './InlinePopup.css';

export const cnPopup = classname.bind(null, 'InlinePopup');

export interface InlinePopupProps extends HTMLAttributes<HTMLDivElement> {
    /**
     * Элемент, относительно которого необходимо позиционировать попап.
     */
    anchor: RefObject<HTMLElement>;

    /**
     * Ссылка на DOM элемент попапа.
     */
    innerRef?: MutableRefObject<HTMLDivElement | undefined> | undefined;

    /**
     * Видимость попапа.
     */
    visible?: boolean;

    /**
     * Наличие хвостика у попапа.
     */
    hasTail?: boolean;

    /**
     * Направления раскрытия блока.
     * Пока реализовано только bottom-center'
     *
     * @default 'bottom-center'
     */
    direction?: Direction;

    /**
     * Цвет попапа
     */
    tone?: Tone;

    /**
     * Вид попапа
     */
    view?: View;

    /**
     * Рамер попапа
     */
    size?: Size;

    /**
     * Отступ попапа относительно основного направления.
     *
     * @default 0
     */
    mainOffset?: number;

    /**
     * Отступ попапа относительно второстепенного направления.
     *
     * @default 0
     */
    secondaryOffset?: number;

    /**
     * Отступ хвостика от края попапа.
     *
     * @default 0
     */
    tailOffset?: number;

    /**
     * Отступ от края окна браузера.
     *
     * @default 0
     */
    viewportOffset?: number;
}

const InlinePopup: FunctionComponent<InlinePopupProps> = ({
    anchor,
    className,
    visible = true,
    hasTail = false,
    size = SizeEnum.M,
    tone = ToneEnum.LIGHT,
    view = ViewEnum.PRIMARY,
    direction = 'bottom-center',
    innerRef,
    ...props
}) => {
    /**
     * Контейнер с ссылкой на DOM элемент попапа
     */
    const popupRef = useRef<HTMLDivElement>();

    /**
     * Контейнер с ссылкой на DOM элемент хвостика
     */
    const tailRef = useRef<HTMLDivElement>(null);

    if (! visible) {
        return null;
    }

    return (
        <div
            style={props.style}
            ref={mergeRefs(popupRef, innerRef)}
            className={cnPopup(null, { visible, view, tone, direction, size }, className)}>
            {props.children}
            {
                hasTail ? <PopupTail innerRef={tailRef} /> : null
            }
        </div>
    );
};

export default InlinePopup;
