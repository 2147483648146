import './MetroSelect.css';

import React from 'react';
import { Input, Collapse, Tabs, Typography } from '@vtblife/uikit';
import { Size } from '@vtblife/uikit/legacy';
import Icon from '@vtblife/uikit-icons';

import classname from '@search/classname/src';

import { MetroMark2, MetroMark2Item } from '../MetroMark2/MetroMark2';
import {
    MetroAlphabetGroup,
    MetroRouteGroup,
    MetroStation,
    MetroStore
} from '../../../../../../../domain/geo/MetroStation';

import {
    useMetroSelect,
    UseMetroSelectProps,
    useMetroSelectRouteGroup
} from './useMetroSelect';

const cn = classname.bind(null, 'MetroSelect');

interface MetroSelectProps extends UseMetroSelectProps {
    /** Станции метро */
    metros: MetroStore;

    className?: string;
}

export const MetroSelect: React.FC<MetroSelectProps> = props => {
    const {
        groupByAlphabet,
        groupByLine,
        isGrouppedByLine,
        checkStations,
        isStationChecked
    } = useMetroSelect(props);

    const [ search, setSearchRaw ] = React.useState('');
    const setSearch = React.useCallback((next: string) => {
        setSearchRaw(next);
    }, []);
    const resetSearch = React.useCallback(() => setSearch(''), []);

    const groupedByAlphabet = isGrouppedByLine ? [] : props.metros.groupedByAlphabet.map(grp => {
        const stations = grp.stations.filter(station => station.title.toLocaleUpperCase().includes(search.toLocaleUpperCase()));

        return { ...grp, stations };
    }).filter(item => item.stations.length !== 0);

    const groupedByRoute = ! isGrouppedByLine ? [] : props.metros.groupedByRoute.map(grp => {
        const stations = grp.stations.filter(station => station.title.toLocaleUpperCase().includes(search.toLocaleUpperCase()));

        return { ...grp, stations };
    }).filter(item => item.stations.length !== 0);

    return (
        <div className={cn()}>
            <div className={cn('filter')}>
                <Input
                    size={Size.Small}
                    value={search}
                    onChange={setSearch}
                    onReset={resetSearch}
                    type='search'
                    placeholder='Название станции'
                >
                    <Input.Content placement='start'>
                        <Icon name='magnifier' />
                    </Input.Content>
                </Input>
                <Tabs size='s' variant='text' onChange={v => v === 'by-alpha' ? groupByAlphabet() : groupByLine()} justified>
                    <Tabs.Option value='by-alpha' active={! isGrouppedByLine}>По алфавиту</Tabs.Option>
                    <Tabs.Option value='by-line' active={isGrouppedByLine}>По линиям</Tabs.Option>
                </Tabs>
            </div>
            {groupedByRoute.length > 0 && ! search && (<Collapse variant='line'>
                {groupedByRoute.map(group => (
                    <RouteGroup
                        key={group.route.id}
                        group={group}
                        isStationChecked={isStationChecked}
                        checkStations={checkStations}
                    />
                ))}
            </Collapse>)}
            {groupedByRoute.length > 0 && search && (<div className={cn('groups')}>
                {groupedByRoute.map(group => (
                    <AlphaGroup
                        key={group.route.id}
                        group={group}
                        isStationChecked={isStationChecked}
                        checkStations={checkStations}
                    />
                ))}
            </div>)}
            {groupedByAlphabet.length > 0 && (<div className={cn('groups')}>
                {groupedByAlphabet.map(group => (
                    <AlphaGroup
                        key={group.letter}
                        group={group}
                        isStationChecked={isStationChecked}
                        checkStations={checkStations}
                    />
                ))}
            </div>)}
        </div>
    );
};

function RouteGroup({
    group,
    isStationChecked,
    checkStations
}: {
    group: MetroRouteGroup;
    checkStations: (station: readonly MetroStation[], checked: boolean) => void;
    isStationChecked: (station: MetroStation) => boolean;
}) {
    const { allChecked, checkAllInGroup } = useMetroSelectRouteGroup(
        group.stations,
        checkStations,
        isStationChecked
    );

    const checkedInGroup = group.stations.filter(station => isStationChecked(station)).length;

    return (
        <Collapse.Item key={group.id}>
            <Collapse.Title>
                <div className={cn('route-title')}>
                    <MetroMark2Item color={group.route.color} />{group.route.title
                        .replace(/ (линия|диаметр)$/, '')
                    }
                    {checkedInGroup > 0 && (
                        <div className={cn('route-count')}></div>
                    )}
                </div>
            </Collapse.Title>
            <div className={cn('route')}>
                <Item
                    onClick={checkAllInGroup}
                    checked={allChecked}
                ><div className={cn('all-stations')}>Все станции</div></Item>
                {group.stations.map((station, index) => (
                    <Item
                        key={station.id}
                        checked={isStationChecked(station)}
                        onClick={(next) => checkStations([ station ], next)}
                    >
                        {/* <RouteIcon
                            color={group.route.color}
                            isFirst={index === 0}
                            isLast={index === group.stations.length - 1}
                        /> */}
                        <div className={cn('all-stations')}>
                            <Typography variant='primary'>{station.title}</Typography>
                            <MetroMark2
                                colors={station.allRoutesColorsList.filter(color => color !== group.route.color)}
                            />
                        </div>
                    </Item>
                ))}
            </div>
        </Collapse.Item>
    );
}

function AlphaGroup({
    group,
    isStationChecked,
    checkStations
}: {
    group: MetroAlphabetGroup;
    checkStations: (station: readonly MetroStation[], checked: boolean) => void;
    isStationChecked: (station: MetroStation) => boolean;
}) {
    return (
        <div className={cn('alpha')}>
            {group.letter && (<div className={cn('alpha-title')}>{group.letter}</div>)}
            <div className={cn('items')}>
                {group.stations.map(station => (
                    <Item
                        key={station.id}
                        checked={isStationChecked(station)}
                        onClick={checked => checkStations([ station ], checked)}
                    >
                        <Typography variant='primary'>{station.title}</Typography>
                        <MetroMark2 colors={station.allRoutesColorsList} />
                    </Item>
                ))}
            </div>
        </div>
    );
}

function Item({ children, checked, onClick }: {
    children?: React.ReactNode;
    checked?: boolean;
    onClick?(checked: boolean, e: React.MouseEvent): void;
}) {
    return (
        <div
            role='button'
            className={cn('station', { checked })}
            onClick={onClick?.bind(null, ! checked)}
        >
            {children}
            {checked && (
                <div className={cn('check')}>
                    <Icon name='check' />
                </div>
            )}
        </div>
    );
}

function RouteIcon({ color, isFirst, isLast }: {
    color: string;
    isLast?: boolean;
    isFirst?: boolean;
}) {
    return (
        <div style={{ color, lineHeight: 0, width: '20px' }}>
            <svg width='12' height='52' viewBox='0 0 12 52' fill='none' xmlns='http://www.w3.org/2000/svg'>
                {! isFirst && (<path
                    clipRule='evenodd'
                    fill='currentColor'
                    fillRule='evenodd'
                    strokeWidth={0.73}
                    d='m 4.5,22.610739 2e-5,-22.615407 H 7.5 l -2e-5,22.615407 z'
                />)}
                {! isLast && (<path
                    clipRule='evenodd'
                    fill='currentColor'
                    fillRule='evenodd'
                    strokeWidth={0.73}
                    d='m 4.5136962,51.995639 2e-5,-22.681184 h 2.99998 l -2e-5,22.681184 z'
                />)}
                <circle
                    fill='white'
                    stroke='currentColor'
                    strokeWidth={3}
                    r='4.5'
                    cy='26'
                    cx='6'
                />
            </svg>
        </div>
    );
}

