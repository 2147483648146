import { MortgagePurpose } from '@search/graphql-typings';
import { useCallback, useMemo, useState } from 'react';

import { formatNumber } from '@search/helpers/src/formatNumber';
import {
    calculateFactoredRange, getRangedValue, OWN_AMOUNT_STEP, PROPERTY_COST_MAX, PROPERTY_COST_MIN,
    PROPERTY_COST_STEP, TERM_MAX, TERM_MIN
} from './constants';

export interface UseBankMortgageFormProps {
    /* Срок займа */
    term: number;

    isPreferentialNbCondition?: boolean;

    /* Стоимость недвижимости */
    propertyCost: number;

    /* Сумма собственных средств */
    ownAmount: number;

    /* Тип ипотеки (Вторичка, Новостройка, Рефинансирование) */
    purpose: typeof MortgagePurpose[keyof typeof MortgagePurpose];

    /* id Региона (отличается от id в классифайде) */
    regionId: number;

    onChange: (data: Omit<UseBankMortgageFormProps, 'onChange'>) => void;

    ownAmountFromPropertyCost?: boolean;
}

export const calculateOwnAmount = (value: number, factor: number = 0.2) => calculateFactoredRange(value, factor)[0];

export const useBankMortgageForm = ({
    onChange,
    ownAmountFromPropertyCost = true,
    ...initialState
}: UseBankMortgageFormProps) => {
    const [ formState, setFormState ] = useState(initialState);

    /**
     * @see https://gitlab.cloud.vtblife.ru/vtblife/frontend/mortgage/-/blob/7a482a47/src/client/components/realty-form/form-component.tsx
     */
    const calculatedFormValues = useMemo(() => {
        const [ ownAmountMin, ownAmountMax ] = calculateFactoredRange(formState.propertyCost);
        const percent = getRangedValue(Math.round((formState.ownAmount / formState.propertyCost) * 100), 0, 100);

        return {
            propertyCost: {
                min: PROPERTY_COST_MIN,
                max: PROPERTY_COST_MAX,
                step: PROPERTY_COST_STEP
            },
            ownAmount: {
                min: ownAmountMin,
                max: ownAmountMax,
                step: OWN_AMOUNT_STEP,
                percent: `${percent ? `${percent}%` : ''}`,
                labelFrom: `от ${formatNumber(ownAmountMin)} ₽`,
                labelTo: `до ${formatNumber(ownAmountMax)} ₽`
            },
            term: {
                min: TERM_MIN,
                max: TERM_MAX
            }
        };
    }, [ formState.ownAmount, formState.propertyCost ]);

    const handleFieldChange = useCallback((
        name: keyof typeof formState,
        value: number | string
    ) => {
        setFormState(prevState => {
            let newState = prevState;

            let ownAmount = prevState.ownAmount;

            //@ts-ignore
            value = value ? Number(value) : (Number(calculatedFormValues[name].min) ?? 0);
            //@ts-ignore
            if (Number.isNaN(value)) value = (Number(calculatedFormValues[name].min) ?? 0);

            if (name === 'propertyCost' && ownAmountFromPropertyCost) {
                const [ ownAmountMin, ownAmountMax ] = calculateFactoredRange(value);

                ownAmount = getRangedValue(prevState.ownAmount, ownAmountMin, ownAmountMax);
            }

            if (
                prevState[name] !== value ||
                ownAmount !== prevState.ownAmount
            ) {
                newState = {
                    ...newState,
                    ownAmount,
                    [name]: value
                };
            }

            if (onChange && newState !== prevState) onChange(newState);

            return newState;
        });
    }, [ onChange ]);

    return {
        formState,
        formErrors: {
            propertyCost: formState.propertyCost < calculatedFormValues.propertyCost.min ||
                formState.propertyCost > calculatedFormValues.propertyCost.max,
            ownAmount: formState.ownAmount < calculatedFormValues.ownAmount.min ||
                formState.ownAmount > calculatedFormValues.ownAmount.max
        },
        handleFieldChange,
        calculatedFormValues
    };
};
